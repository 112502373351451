import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpClientModule } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { AbsUtils } from '@abs/utils';
import { EmailContent } from './email.model';
import { environment } from 'environments/environment';
import { AuthenticationService } from '../authentication/_services';

const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })
};

const httpTextOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/text'
    })
};

@Injectable()
export class EmailContentService implements Resolve<any>
{
    onEmailChanged: BehaviorSubject<any>;
    onSelectedEmailChanged: BehaviorSubject<any>;
    onSearchTextChanged: Subject<any>;
    onFilterChanged: Subject<any>;

    emails: EmailContent[];
    user: any;
    selectedEmails: string[] = [];
    searchText: string;
    filterBy: string;

    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient,private _authService: AuthenticationService
    ) {
        // Set the defaults
        this.onEmailChanged = new BehaviorSubject([]);
        this.onSelectedEmailChanged = new BehaviorSubject([]);
        this.onSearchTextChanged = new Subject();
        this.onFilterChanged = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        return new Promise((resolve, reject) => {

            Promise.all([
                this.getEmails(),
            ]).then(
                ([files]) => {
                    this.onSearchTextChanged.subscribe(searchText => {
                        this.searchText = searchText;
                        this.getEmails();
                    });
                    this.onFilterChanged.subscribe(filter => {
                        this.filterBy = filter;
                        this.getEmails();
                    });
                    resolve();
                },
                reject
            );
        });
    }

    /**
     * Get emails
     *
     * @returns {Promise<any>}
     */
    getEmails(): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(`${environment.apiUrl}/email/content/` + JSON.parse(localStorage.getItem("currentUser")).companyId, httpOptions)
                .subscribe((response: any) => {
                    this.emails = response;
                    if (this.searchText && this.searchText !== '') {
                        this.emails = AbsUtils.filterArrayByString(this.emails, this.searchText);
                    }
                    this.emails = this.emails.map(email => {
                        return new EmailContent(email);
                    });
                    this.onEmailChanged.next(this.emails);
                    resolve(this.emails);
                }, reject);

        }
        );
    }

    getEmailById(emailId): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(`${environment.apiUrl}/email/` + emailId, httpOptions)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);

        }
        );
    }


    getEmailBulkInvite(userType,groups): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(`${environment.apiUrl}/email/bulk/invite?pid=${this._authService.currentUserValue.profileId}&cid=${this._authService.currentUserValue.companyId}&ul=${userType}&groups=${groups}`, {responseType: 'text'})
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);

        }
        );
    }





    /**
     * Insert Email
     *
     * @param email
     * @returns {Promise<any>}
     */
    insertEmail(email): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(`${environment.apiUrl}/email/content`, { ...email }, httpOptions)
                .subscribe(response => {
                    this.getEmails();
                    resolve(response);
                });
        });
    }

    /**
     * Update email 
     *
     * @param email
     * @returns {Promise<any>}
     */
    updateEmail(email): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(`${environment.apiUrl}/email/content/update`, { ...email }, httpOptions)
                .subscribe(response => {
                    this.getEmails();
                    resolve(response);
                });
        });
    }


    triggerEmail(email, phone): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(`${environment.apiUrl}/email/trigger/all?email=${email}&phone=${phone}`, httpOptions)
                .subscribe(response => {
                    resolve(response);
                });
        });
    }


    /**
   *
     * Delete email
     *
     * @param email
     */
    deleteEmail(email): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.delete(`${environment.apiUrl}/email/content/` + email.emailId, httpOptions)
                .subscribe(response => {
                    this.getEmails();
                    resolve(response);
                });
        });
    }
}
