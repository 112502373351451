import { Component, OnInit, Input } from '@angular/core';
import { AuthenticationService } from '../../../../authentication/_services';

@Component({
  selector: 'my-calendar-course-student-item',
  templateUrl: './my-calendar-course-student-item.component.html',
  styleUrls: ['./my-calendar-course-student-item.component.scss']
})
export class MyCalendarCourseStudentItemComponent implements OnInit {


  @Input()
  course: any;
  
  constructor(public authService: AuthenticationService) { }

  ngOnInit() {
  }

}
