import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AbsConfigService } from '@abs/services/config.service';
import { absAnimations } from '@abs/animations';
import { AuthenticationService, CompnayService } from '../_services';
import { ActivatedRoute, Router } from '@angular/router';
import { first, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AbsProgressBarService } from '@abs/components/progress-bar/progress-bar.service';
import { IndexTiles } from '../../landing-page/tile.model';
import { of } from 'rxjs';
import { Platform } from '@angular/cdk/platform';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AbsUtils } from '@abs/utils';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { REPLServer } from 'repl';
import { Profile } from '../../user/profile/profile';
import { RegistrationService } from '../_services/registration.service';
import { AccountProfile } from '../_models/accountprofile';
import { UsersService } from '../../user/user.service';
import { UserService } from '../../service/user.service';


@Component({
  selector: 'app-sso',
  templateUrl: './sso.component.html',
  styleUrls: ['./sso.component.scss'],
  animations: absAnimations

})
export class SsoComponent implements OnInit {

  firstName: any;
  lastName: any;
  username: any;
  providerId: any;
  companyId: any;
  policyId: any;
  organizationForm: FormGroup;
  createOrganization = false;
  handalizedOrganization = "";
  show = true;
  found = false;
  company: any;
  constructor(
    private _formBuilder: FormBuilder,
    private _companyService: CompnayService,
    private route: ActivatedRoute,
    private router: Router,
    private _registrationService: RegistrationService,
    private authenticationService: AuthenticationService,
    private _progressBarService: AbsProgressBarService,
    private _platform: Platform,
    private _snackBar: MatSnackBar,
    private _absConfigService: AbsConfigService,
    private _userService: UserService
  ) {

    this.organizationForm = this._formBuilder.group({
      organization: ['', Validators.required],
    });
    // Configure the layout
    this._absConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },
        toolbar: {
          hidden: true
        },
        footer: {
          hidden: true
        },
        sidepanel: {
          hidden: true
        }
      }
    };


    this.route.queryParams.subscribe(params => {
      console.log("params", params)
      var travelFlow = false;
      const checkoutToken = params['token'];
      const quizToken = params['quiz-token'];
      var firstName = params['fn'];
      var lastName = params['ln'];
      const username = params['user'];
      var policyId = params['pID'];
      let providerId = params['prID'];
      const companyId = params['cID'];
      var providerHash = params['hID'];
      const product = params['product'];
      const groups = params['groups'];
      const portal = params['loginPortal'];
      const company = params['company'];
      const pid = params['pid'];
      const sid = params['sid'];
      const attending = params['attending'];
      const detachp = params['detachp'];

      if(detachp && (detachp == 1 || detachp == '1')) {
        providerId = "0";
        providerHash = "0";
      }

      if (pid && pid.length > 0 && sid && sid.length > 0 && attending && attending.length > 0) {
        this.router.navigate(['rsvp'], { queryParams: { pid: pid, sid: sid, attending: attending } });
      }


      const keywords = params['keywords'];
      var keys = Object.keys(params)
      console.log(location);

      if (location.href.indexOf("product.jsp") != -1) {
        this.navigateToOldSite("/itrain/product.jsp" + location.search)
      }

      if (location.href.indexOf("zSearch.jsp") != -1) {
        this.navigateToOldSite("/zSearch.jsp" + location.search)
      }

      if (location.href.indexOf("badges.jsp") != -1) {
        this.navigateToOldSite("/itrain/badges.jsp" + location.search)
      }

      if (location.href.indexOf("xLaunchPad.jsp") != -1) {
        this.navigateToOldSite("/itrain/xLaunchPad.jsp" + location.search)
      }

      if (location.href.indexOf("go.jsp") != -1 && keys.length == 0) {
        this.navigateToOldSite("/itrain/go.jsp" + location.search)
      }

      if (quizToken && quizToken.length > 0) {
        const courseId = params['pid'];
        const classId = params['cid'];
        this.authenticationService.validateToken(quizToken, 'QUIZ').subscribe(res => {
          if (res) {
            this.authenticationService.ssoLogin(res.username).subscribe(res => {
              if (res) {
                this.router.navigate(['training/classroom-quiz'], { queryParams: { pid: courseId, cid: classId } });
              }
            });
          }
        });
      }

      if (checkoutToken && checkoutToken.length > 0) {

        this.authenticationService.validateToken(checkoutToken, 'PUBLIC').subscribe(res => {
          if (res) {
            this.authenticationService.publicLogin(res.username).subscribe(res => {
              if (res) {
                this.router.navigate(['shopping/checkout']);
              }
            });
          }
        });
      }

      var key = keys[0];
      if (keys.length == 1 && (key == "hID" || key == "company")) {

        if (key == "hID") {
          this.authenticationService.checkIfCompanyConverted(providerHash).subscribe(value => {
            if (value == 0) {
              this.navigateToOldSite("/itrain/go.jsp" + location.search);
            } else {
              this.router.navigate(['/authentication/login?username=' + username]);
            }
          })
        } else {
          this.authenticationService.checkIfCompanyConverted(company).subscribe(value => {
            if (value == 0) {
              this.navigateToOldSite("/itrain/go.jsp" + location.search);
            } else {
              this.router.navigate(['/authentication/login']);
            }
          })
        }
      }


      if ((providerHash && username && providerHash.length > 0) || (providerId && providerId.length > 0)) {

        if (portal && portal.length > 0) {
          policyId = groups;
        }

        if (providerId && providerId.length > 0) {
          providerHash = providerId;
        }

        if (providerHash == '042d21' && username.indexOf("travelers") != -1) {
          travelFlow = true;
          console.log("travelFlow", travelFlow)
        }

        if (!travelFlow) {
          if (!firstName) {
            firstName = "";
          }

          if (!lastName) {
            lastName = "";
          }

          this._registrationService.findManager(username, "email").subscribe(profileIds => {
            if (profileIds && profileIds.length > 0) {
              this.authenticationService.checkIfUserMigrated(profileIds[0].profileId).subscribe(result => {
                if (result == 1) {
                  this.authenticationService.ssoLogin(profileIds[0].profileId).subscribe(res => {
                    if (res) {
                      this.policyId = policyId;
                      this.updateInformation(profileIds[0].profileId);
                      this.router.navigate(['/home']);
                    } else {
                      this.router.navigateByUrl('/authentication/login?username=' + username + '&fn=' + firstName + '&ln=' + lastName + '&providerHash=' + providerHash+'&policyId='+policyId);
                    }
                  });
                } else {
                  this.router.navigateByUrl('/authentication/login?username=' + username + '&fn=' + firstName + '&ln=' + lastName + '&providerHash=' + providerHash+'&policyId='+policyId);
                }
              })
            } else {
              this.router.navigateByUrl('/authentication/login?username=' + username + '&fn=' + firstName + '&ln=' + lastName + '&providerHash=' + providerHash+'&policyId='+policyId);
            }
          })
        }
        // } else {
        //  this.createCompanyUser(username, policyId, providerHash, firstName, lastName);

        //}

      }
      if (travelFlow) {

        if ((companyId || providerId) && firstName && lastName && username && firstName.length > 0 && lastName.length > 0 && username.length > 0) {

          this.firstName = firstName;
          this.lastName = lastName;
          this.username = username;
          this.providerId = providerId;
          this.policyId = policyId;
          this.companyId = companyId;
          //this.createUser(firstName, lastName, username, policyId, providerId, companyId, null, null);
          if (username.indexOf("@") != -1 && username.indexOf(".") != -1) {
            var domain = username.substring(username.lastIndexOf("@") + 1);
            this._companyService.checkIfFreeDomain(domain, username).subscribe(result => {
              if (result == 1) {
                this.createOrganization = true;
                this.organizationForm.controls.organization.setValue(domain);
                this.handalizedOrganization = domain;
              } else {
                this.createUser(firstName, lastName, username, policyId, providerId, companyId, null, null);
              }
            })
          }
        }
      }
    });
  }


  createUser(firstName, lastName, username, policyId, providerId, companyId, domain, handlizedDoamin) {
    this._progressBarService.show();

    this.authenticationService.createSSOUser(firstName, lastName, username, policyId, providerId, companyId, domain, handlizedDoamin).subscribe(res => {
      this._progressBarService.hide();
      if (res && res > 0) {
        this.authenticationService.ssoLogin(res).subscribe(res => {
          if (res) {
            this.router.navigate(['/home']);
          } else {
            this.router.navigateByUrl('/authentication/login?username=' + username);
          }
        });
      } else if (res && res == -10) {
        this._snackBar.open("Missing required company or Provider information.", 'Ok', {
          panelClass: 'red-bg',
          horizontalPosition: 'center',
          verticalPosition: 'top',
          politeness: 'polite',
        });
      } else if (res && res == -9) {
        this._snackBar.open("Invalid Provider.", 'Ok', {
          panelClass: 'red-bg',
          horizontalPosition: 'center',
          verticalPosition: 'top',
          politeness: 'polite',
        });
      }
      else if (res && res == -8) {
        this._snackBar.open("SSO is disabled for this company.", 'Ok', {
          panelClass: 'red-bg',
          horizontalPosition: 'center',
          verticalPosition: 'top',
          politeness: 'polite',
        });
      }
      else if (res && res == -7) {
        this._snackBar.open("SSO is disabled for this provider.", 'Ok', {
          panelClass: 'red-bg',
          horizontalPosition: 'center',
          verticalPosition: 'top',
          politeness: 'polite',
        });
      }
    });
  }


  createCompanyUser(username, policyId, providerHash, fn, ln) {
    this._progressBarService.show();

    this.authenticationService.createSSOCompanyUser(username, policyId, providerHash, fn, ln).subscribe(res => {
      this._progressBarService.hide();
      if (res && res > 0) {
        this._snackBar.dismiss();
        this.authenticationService.checkIfUserMigrated(res).subscribe(result => {
          if (result == 1) {
            this.authenticationService.ssoLogin(res).subscribe(res => {
              if (res) {
                this.router.navigate(['/home']);
              } else {
                this.router.navigateByUrl('/authentication/login?username=' + username);
              }
            });
          } else {
            this.router.navigateByUrl('/authentication/login?username=' + username);
          }
        })
      } else {
        this.navigateToOldSite("/itrain/go.jsp" + location.search);
      }
    });
  }

  navigateToOldSite(url) {
    var defaults = JSON.parse(localStorage.getItem("system_defaults"));
    console.log("defaults", defaults);
    if (defaults) {
      let LEGACY_WEBSITE_URL = AbsUtils.filterArrayByString(defaults, "LEGACY_WEBSITE_URL");
      if (LEGACY_WEBSITE_URL && LEGACY_WEBSITE_URL.length > 0) {
        window.location.href = LEGACY_WEBSITE_URL[0].value + url;
      } else {
        window.location.href = 'https://itrain.itrainstation.com/itrain/go.jsp' + location.search;;
      }
    } else {
      window.location.href = 'https://itrain.itrainstation.com/itrain/go.jsp' + location.search;;
    }
  }

  organizationSubmit() {

    this.createUser(this.firstName, this.lastName, this.username, this.policyId, this.providerId, this.companyId, this.handalizedOrganization, this.handalizedOrganization);
  }


  checkIfOrganizationAlreadyExist() {
    if (this.organizationForm.valid) {
      this.createOrganization = true;
      var inputValue = this.organizationForm.controls.organization.value;
      var domain = inputValue;
      this.handalizedOrganization = domain;
      this.show = false;
      this._progressBarService.show();
      this._companyService.getCompanyByDomain(domain).subscribe(res => {
        this._progressBarService.hide();
        this.show = true;
        if (res) {
          this.found = true;
          this.company = res;
        } else {
          this.found = false;
        }
      })
    }
  }
  /**
   * On init
   */
  ngOnInit(): void {
    this.organizationForm.controls['organization'].valueChanges
      .pipe(
        debounceTime(400),
        distinctUntilChanged()
      )
      .subscribe(e => {
        this.checkIfOrganizationAlreadyExist();
      }
      );
  }


  updateInformation(profileId) {
    this._progressBarService.show();

    var accountProfile = new AccountProfile({});

    accountProfile.profileId = profileId;
    accountProfile.policyId = this.policyId;

    this._userService.updateAccountProfileMigration(accountProfile).subscribe(response => {
    });;
}
}

