import { Component, OnInit, AfterViewInit, Input, Output, EventEmitter } from '@angular/core';
import { environment } from 'environments/environment';
import { ActivityLogService } from '../../service/activity-log.service';
import { TrainingService } from '../../training/_services/training.service';
import { ActionBarService } from '../../../layout/components/action-bar/action-bar.service';
import { ActivatedRoute } from '@angular/router';
import { AbsProgressBarService } from '@abs/components/progress-bar/progress-bar.service';
import { AbsConfigService } from '@abs/services/config.service';
declare var flowplayer: any;
declare var jQuery: any;
import * as Stomp from 'stompjs';
import * as SockJS from 'sockjs-client';
import { QuizComponent } from '../../content/quiz/quiz.component';
import { KioskPlayerQuizConfirmDialogComponent } from '../kiosk-player-quiz-confirm-dialog/kiosk-player-quiz-confirm-dialog.component';
import { KioskPlayerNextVideoDialogComponent } from '../kiosk-player-next-video-dialog/kiosk-player-next-video-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { VideoLog } from '../../content/video-log.model';
import { Content } from '../../training/_models/playlist';
import { DocumentViewerDialogComponent } from '../../document-viewer-dialog/document-viewer-dialog.component';
@Component({
  selector: 'app-kiosk-player',
  templateUrl: './kiosk-player.component.html',
  styleUrls: ['./kiosk-player.component.scss']
})
export class KioskPlayerComponent implements OnInit, AfterViewInit {
  private serverUrl = `${environment.apiUrl}/socket`;
  private stompClient;

  rewatch = false;
  showPlayer = true;

  currentProductId: any;
  lastPlayedProductId: any;

  currentPlaylist = [];
  disclaimerDir = "Disclaimer-1003";
  environment: any;
  flPlayer: any;

  quizConfirmationDialogRef: any;

  quizDialogRef: any;
  nextVideoDialogRef: any;
  documentDialogRef: any;

  spanish = false;
  videoLogId: any;

  allCourses = [];

  @Input() videos: any;
  @Input() profileId: any;
  @Input() providerHash: any;
  @Output() onTrainingExit = new EventEmitter<any>();
  @Output() triggerIdleTimer = new EventEmitter<boolean>();


  constructor(
    private quizConfirmationDialog: MatDialog,
    private quizDialog: MatDialog,
    private nextVideoDialog: MatDialog,

    public activityLog: ActivityLogService,
    public trainingService: TrainingService,
    private actionBarService: ActionBarService,
    private route: ActivatedRoute,
    private progressBarService: AbsProgressBarService,
    private absConfig: AbsConfigService,
    public _docMatDialog: MatDialog
  ) {
    this.environment = environment;
  }
  ngOnInit() {
    //this.initializeWebSocketConnection();
    var self = this;
    var addToPlaylist = false;
    let playlist = [];

    this.videos.forEach(function (video, idx, array) {
      console.log("video", video)

      var disclaimerObject = {
        src: self.environment.cdnUrl + "/preroll/" + self.disclaimerDir + "/master.m3u8", type: "application/x-mpegURL",
        title: video.title,
        pid: video.id,
      }

      var presentedBy = {
        autoplay: false,
        src: self.environment.cdnUrl + "/preroll/PresentedBy-1003/master.m3u8", type: "application/x-mpegURL",
        title: video.title,
        pid: video.id,
      }

      var endVideo = {
        src: self.environment.cdnUrl + "/postroll/Out-1003/master.m3u8", type: "application/x-mpegURL",
        title: video.title,
        pid: video.id,
        showPopup: true
      };

      var mainVideoObject = {
        src: self.environment.cdnUrl + "/video/V_" + video.itemCode + "/master.m3u8", type: "application/x-mpegURL",
        //src : 'https://demo.unified-streaming.com/k8s/features/stable/video/tears-of-steel/tears-of-steel.mp4/.m3u8', type: "application/x-mpegURL",
        title: video.title,
        pid: video.id,
        mainVideo: true,
        content: video,
        isDocument: video.prodType != 'VIDEO'
      };


    //  disclaimerObject.src = "https://demo.unified-streaming.com/k8s/features/stable/video/tears-of-steel/tears-of-steel.mp4/.m3u8";
      //presentedBy.src = "https://demo.unified-streaming.com/k8s/features/stable/video/tears-of-steel/tears-of-steel.mp4/.m3u8";
      //  if(video.prodType == 'VIDEO'){
      //mainVideoObject.src = "https://demo.unified-streaming.com/k8s/features/stable/video/tears-of-steel/tears-of-steel.mp4/.m3u8"
      //  }else{
      //    mainVideoObject.src = "https://demo.unified-streaming.com/k8sasd/features/stable/video/tears-of-steel/tears-of-steel.mp4/.m3u8"

      //  }
      //endVideo.src = "https://demo.unified-streaming.com/k8s/features/stable/video/tears-of-steel/tears-of-steel.mp4/.m3u8"

      if (video.prodType == 'VIDEO') {
        self.currentPlaylist.push(disclaimerObject);
        self.currentPlaylist.push(presentedBy);
      }
      self.currentPlaylist.push(mainVideoObject);
      if (video.prodType == 'VIDEO') {
        self.currentPlaylist.push(endVideo);
      }

    });

    let v2 = {

      src: "https://demo.unified-streaming.com/k8s/features/stable/video/tears-of-steel/tears-of-steel.ism/.m3u8", type: "application/x-mpegURL",
      title: "test 2",
      pid: 20028,
      mainVideo: true,
      content: this.videos[0],
      last: false,
    }

    let v3 = {
      src: "https://multiplatform-f.akamaihd.net/i/multi/will/bunny/big_buck_bunny_,640x360_400,640x360_700,640x360_1000,950x540_1500,.f4v.csmil/master.m3u8", type: "application/x-mpegURL",
      title: "test 3",
      pid: 20028,
      showPopup: true
    }

    let v22 = {

      src: "https://demo.unified-streaming.com/k8s/features/stable/video/tears-of-steel/tears-of-steel.mp4/.m3u8", type: "application/x-mpegURL",
      title: "course 2 test 2",
      pid: 20029,
      mainVideo: true,
      last: false,
    }

    let v33 = {
      src: "https://cdn.itrainstation.com/content/fact+sheets/iTrainstation_OSHA_BloodbornePathogensExposureIncidents_FactSheet_2.pdf", type: "application/x-mpegURL",
      title: "cousrse 2 test 3",
      pid: 20030,
      mainVideo: true,
      isDocument: true
    }

    //self.currentPlaylist.push(v2);
    //self.currentPlaylist.push(v3);
    // self.currentPlaylist.push(v22);
    //self.currentPlaylist.push(v33);

    this.initVideo();
  }

  initVideo() {
    const self = this;
    var doneCourses = 0;

    var totalTimeViewed = 0;
    var lastSecond = null;
    var secondsToCallFunction = 1;
    var viewDeducted = false;

    this.flPlayer = flowplayer.playlist('.flowplayer', {
      auto_orient: true,
      ratio: "16:9",
      playlist: self.currentPlaylist,
      advance: false,
      qsel: {},
      plugins: ['hls', 'qsel'],
      token: "eyJraWQiOiJGaGVJc1JQM0c5NUMiLCJ0eXAiOiJKV1QiLCJhbGciOiJFUzI1NiJ9.eyJjIjoie1wiYWNsXCI6NixcImlkXCI6XCJGaGVJc1JQM0c5NUNcIn0iLCJpc3MiOiJGbG93cGxheWVyIn0.JsuBrKMzkCyXqmwl9YevhbzdYnLdmbZNrGGO2tRHbozNKOU3HA8K3TtqN2oJXPs6EEILPAfVJv5JH0yeolO9lQ",
    }).on("loadstart", function (e) {
      console.log("load start", e)
      var index = self.flPlayer.playlist.queue.idx
      let isDocument = self.flPlayer.playlist.queue.members[index].isDocument;
      let nextMainVideoIndex = index + 1
      let isSecondNextisDocument = index + 2
      if (isDocument && index == 0) {
        self.flPlayer.toggleFullScreen(false)
      } else {
        self.flPlayer.toggleFullScreen(true)
      }

      self.currentProductId = self.flPlayer.playlist.queue.members[index].pid;
      self.triggerIdleTimer.next(true)

      var isLast = self.flPlayer.playlist.queue.last_idx == self.flPlayer.playlist.queue.idx


      console.log("queue", self.flPlayer.playlist.queue)
      console.log("isDocument", isDocument)

      if (self.flPlayer.playlist.queue.members[index].mainVideo) {
        viewDeducted = false;
        var videoLog = new VideoLog({
          profileId: self.profileId,
          productId: self.currentProductId,
          itemName: self.flPlayer.playlist.queue.members[index].content.title,
          comments: "KIOSK"
        });
        self.trainingService.insertVideoLog(videoLog).then(res => {
          self.videoLogId = res;
          console.log("videolog", res);
        });
        if (isDocument && index == 0) {
          doneCourses++
          let document = self.flPlayer.playlist.queue.members[index].content
           document.index = index+1
           self.viewDocument(document,doneCourses)
        }

      }

    }).on("timeupdate", function (e) {

      var index = self.flPlayer.playlist.queue.idx;
      if (self.flPlayer.playlist.queue.members[index].mainVideo) {
        var seconds = Math.floor(e.target.currentTime);
        var time25Perc = Math.floor(e.target.duration) * 0.25;
        if (seconds % secondsToCallFunction == 0 && lastSecond !== seconds) {
          lastSecond = seconds
          var videoLog = new VideoLog({
            id: self.videoLogId,
            totalViewed: seconds,
            videoDuration: e.target.duration,
          });
          self.trainingService.updateVideoLog(videoLog).then(res => {
            console.log(res);
          })
          if (seconds >= time25Perc && !viewDeducted) {
            viewDeducted = true;
            if (self.providerHash == '042d21') {
              var request = {
                profileId: JSON.parse(localStorage.getItem("currentUser")).profileId,
                providerId: JSON.parse(localStorage.getItem("currentUser")).providerId,
                productId: self.currentProductId,
                classId: -1,
                companyId: JSON.parse(localStorage.getItem("currentUser")).companyId,
                videoLogId: self.videoLogId
              };
              self.trainingService.deductClassroomView(request).then(res => {
                viewDeducted = true;
              });
            } else {
              var request = {
                profileId: self.profileId,
                providerId: JSON.parse(localStorage.getItem("currentUser")).providerId,
                productId: self.currentProductId,
                classId: -1,
                companyId: JSON.parse(localStorage.getItem("currentUser")).companyId,
                videoLogId: self.videoLogId
              };
              self.trainingService.deductView(request).then(res => {
                viewDeducted = true;
              });
            }
          }
        }
      }
    }).on("pause", function (e) {
      self.triggerIdleTimer.next(true);
    })
      .on("playing", function (e) {
        self.triggerIdleTimer.next(false);
      }).on("ended", function (e) {
        let isDocument = false;
        let document = null
        let hasNextCourse = false
        var index = self.flPlayer.playlist.queue.idx;
        let lastIndex = self.flPlayer.playlist.queue.last_idx
        let nextMainVideoIndex = index + 1


        var pid = 0;
        var title = "";
        if (self.flPlayer.playlist.queue.members[index].showPopup) {
          console.log("lastIndex", lastIndex)
          console.log("nextMainVideoIndex", nextMainVideoIndex)

          if (lastIndex >= nextMainVideoIndex) {
            isDocument = self.flPlayer.playlist.queue.members[nextMainVideoIndex].isDocument
            if(isDocument){
            document = self.flPlayer.playlist.queue.members[nextMainVideoIndex].content
            document.index = nextMainVideoIndex
            hasNextCourse = nextMainVideoIndex < lastIndex
            console.log("content", isDocument)
            console.log("document", document)
            }
          }
          self.flPlayer.toggleFullScreen(false);
          self.triggerIdleTimer.next(true);
          if (!self.rewatch) {
            doneCourses++;
          }
          title = self.flPlayer.playlist.queue.members[index].title;
          pid = self.flPlayer.playlist.queue.members[index].pid;

          if (localStorage.getItem("quizMode") == "Y") {
            self.quizConfirmationDialogRef = self.quizConfirmationDialog.open(KioskPlayerQuizConfirmDialogComponent, {
              backdropClass: 'previewBackdrop',
              width: '400px',
              disableClose: true,
            });

            self.quizConfirmationDialogRef.afterClosed().subscribe(result => {
              if (result) {
                self.quizDialogRef = self.quizDialog.open(QuizComponent, {
                  id: 'quiz',
                  backdropClass: 'previewBackdrop',
                  width: '1165px',
                  disableClose: true,
                  data: {
                    content: new Content({ id: pid, videoLogId: self.videoLogId, title: title }),
                    spanishQuiz: self.spanish,
                    parent: 'kiosk',
                    profileId: self.profileId,
                    doneCourses: doneCourses,
                    totalCourses: self.videos.length
                  }
                });

                self.quizDialogRef.afterClosed().subscribe(result => {
                  if (result) {
                    if (result == 'rewatch') {
                      self.rewatch = true;
                      self.flPlayer.playlist.play(index - 1);
                    } else {
                      self.rewatch = false;
                      self.nextVideoDialogRef = self.nextVideoDialog.open(KioskPlayerNextVideoDialogComponent, {
                        backdropClass: 'previewBackdrop',
                        width: '400px',
                        disableClose: true,
                        data: {
                          doneCourses: doneCourses,
                          totalCourses: self.videos.length
                        }
                      });
                      self.nextVideoDialogRef.afterClosed().subscribe(result => {
                        if (result) {
                          if (isDocument) {
                            self.flPlayer.toggleFullScreen(false)
                            doneCourses++
                            self.viewDocument(document,doneCourses)
                          } else {
                            self.flPlayer.playlist.next();
                          }
                        } else {
                          self.onTrainingExit.emit("yes");
                        }
                      })
                    }
                  }
                });
              }

            });
          } else {
            self.nextVideoDialogRef = self.nextVideoDialog.open(KioskPlayerNextVideoDialogComponent, {
              backdropClass: 'previewBackdrop',
              width: '400px',
              disableClose: true,
              data: {
                doneCourses: doneCourses,
                //totalCourses: 1,
                totalCourses: self.videos.length
              }
            });
            self.nextVideoDialogRef.afterClosed().subscribe(result => {
              if (result) {
                if (isDocument) {
                  self.flPlayer.toggleFullScreen(false)
                  doneCourses++
                  self.viewDocument(document,doneCourses)
                } else {
                  self.flPlayer.playlist.next();
                }
              } else {
                self.onTrainingExit.emit("yes");
              }
            })
          }
        } else {
          if (isDocument) {
            self.flPlayer.toggleFullScreen(false)
            doneCourses++
            self.viewDocument(document,doneCourses)
          } else {
            self.flPlayer.playlist.next();
          }
        }

      }).on(Object.values(flowplayer.playlist.events), function (e) {
        console.info(e)
      }).on(flowplayer.playlist.events.PLAYLIST_READY, function (e) {
        console.log("playlist ready", e);
      }).on(flowplayer.playlist.events.PLAYLIST_NEXT, function (e) {
        console.log("next", e);
      }).on(flowplayer.playlist.events.PLAYLIST_ENDED, function (e) {
        console.log("playlist ended", e);
        //self.flPlayer.toggleFullScreen(false)
      });
  }


  initializeWebSocketConnection() {
    let ws = new SockJS(this.serverUrl);
    this.stompClient = Stomp.over(ws);
    let that = this;
    this.stompClient.connect({}, function (frame) {
    });
  }



  ngAfterViewInit() {
  }

  viewDocument(playlist,doneCourses) {
    console.log("flplayer", this.flPlayer)
    console.log("playlist", playlist)


    var url = "";
    if (playlist.private) {
      var fileExt = playlist.fileName.split('.').pop();
      var directory = playlist.itemCode.split('.')[0];
      url = `${environment.cdnUrl}/assets/contents/${directory + "/V_" + playlist.itemCode + "." + fileExt}`;
    } else {
      url = `${environment.cdnUrl}/content/${playlist.prodType.toLowerCase().replace('_', '+')}s/${playlist.fileName}`
    }

    this.documentDialogRef = this._docMatDialog.open(DocumentViewerDialogComponent, {
      backdropClass: 'previewBackdropFull',
      width: '1165px',
      height: '800px',
      data: {
        url: url,
        title: playlist.title,
        content: playlist,
        parent: 'kiosk',
        profileId: this.profileId,
        doneCourses: doneCourses,
        totalCourses: this.videos.length
      }
    });

    this.documentDialogRef.afterClosed().subscribe(result => {
      let document = null;
      let isDocument = false
      let lastIndex = this.flPlayer.playlist.queue.last_idx
      let nextMainVideoIndex = playlist.index
      let hasNextCourse = nextMainVideoIndex + 1 <= lastIndex

      console.log("queue", this.flPlayer.playlist.queue)
      console.log("hasNextCourse", hasNextCourse)

      if (hasNextCourse) {
          isDocument = this.flPlayer.playlist.queue.members[nextMainVideoIndex + 1].isDocument
        if(isDocument){
          document = this.flPlayer.playlist.queue.members[nextMainVideoIndex + 1].content
        }
        console.log("content", isDocument)
        console.log("document", document)
      }

      if (hasNextCourse) {
        if (isDocument) {
          console.log("isDocument",isDocument)
          doneCourses++
          this.viewDocument(document,doneCourses)
        } else {
          console.log("not document",isDocument)
          this.flPlayer.playlist.play(nextMainVideoIndex + 1);
        }
      } else {
        this.onTrainingExit.emit("yes");
      }
    })
  }
}