import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-score-bar',
  templateUrl: './score-bar.component.html',
  styleUrls: ['./score-bar.component.scss'],
  encapsulation: ViewEncapsulation.None

})
export class ScoreBarComponent implements OnInit {

  @Input() value: number;

  @Input() type: any;

  @Input() passingScore: number;

  cssClass: any;

  constructor() { }

  ngOnInit() {
    if (this.type == 'C') {
      if (this.value >= 0 && this.value <= 40) {
        this.cssClass = "s0-40-progress";

      } else if (this.value >= 41 && this.value <= 70) {
        this.cssClass = "s41-70-progress";

      }
      else if (this.value >= 71 && this.value <= 99) {
        this.cssClass = "s-blue-progress";

      }
      else if (this.value == 100) {
        this.cssClass = "s91-100-progress";

      }
    }else if (this.type == 'Q') {
      if (this.value >= 0 && this.value < this.passingScore) {
        this.cssClass = "s-red-progress";

      } else if (this.value >= this.passingScore && this.value <= 89) {
        this.cssClass = "s-blue-progress";
      }
      else if (this.value >= 90 && this.value <= 100) {
        this.cssClass = "s-green-progress";

      }
    
    }else if(this.type == 'R'){

      if (this.value >= 0 && this.value <= 40) {
        this.cssClass = "s0-40-progress";

      } else if (this.value >= 41 && this.value <= 70) {
        this.cssClass = "s41-70-progress";
      }
      else if (this.value >= 71 && this.value <= 90) {
        this.cssClass = "s71-90-progress";
      }
      else if (this.value >= 91 && this.value <= 100) {
        this.cssClass = "s91-100-progress";
      }
    }
  }

}
