import { AbsUtils } from '@abs/utils';

export class PlayList {
    id: number;
    name: string;
    profileID: number;
    companyId: number;
    startDate: Date;
    endDate: Date;
  
    location: string;
    locationLat: number;
    locationLong: number;
    format: string;
    status: string;
    assignedBy: string;
    daysRemaining: number;
    percentangeComplete: number;
    contents: Content[];
    duration: number;
    rating: number;
    views:number;
    passingScore: number;
    averageTimePerCourse: number;
    totalCompletedCourse: number;
    daysPassDue: number;
    

    /**
   * Constructor
   *
   * @param playlist
   */
  constructor(playlist) 
    {
      this.id = playlist.id;
      this.name = playlist.name || '';
      this.profileID = playlist.profileID
      this.startDate = playlist.startDate;
      this.endDate = playlist.endDate;
      this.location = playlist.location;
      this.format = playlist.format;
      this.status = playlist.status;
      this.assignedBy = playlist.assignedBy;
      this.percentangeComplete = playlist.percentangeComplete;
      this.contents = playlist.contents || [];
      this.daysRemaining = playlist.daysRemaining;
      this.duration = playlist.duration;
      this.rating = playlist.rating;
      this.views = playlist.views;
      this.passingScore = playlist.passingScore;
      this.averageTimePerCourse = playlist.averageTimePerCourse;
      this.totalCompletedCourse = playlist.totalCompletedCourse;
      this.locationLat = playlist.locationLat;
      this.locationLong = playlist.locationLong;
      this.daysPassDue = playlist.daysPassDue;
      this.companyId = playlist.companyId;
    }
  
  }
  
  export class Content {
    id: string;
    title: string;
    image: string;
    type: string;
    lastAttempt: Date;
    itemCode:string;
    percentage: number;
    pass: boolean;
    quizScore: number;
    percentageViewed: number;
    attempts: number;
    percentile: number;
    certificate: boolean;
    duration: number;
    dueDate: Date;
    daysRemaining: number;
    prerequisite: string;
    aspectRatio: number;
    videoLogId: number;
    fastForward: boolean;
     vendorID: number;
     producerID: number;
    sku: string;
    canStartCourse: boolean;
    canRewatchVideo: boolean;
    canRetakeQuiz: boolean;
    attemptsToday: number;
    spanish: boolean;
    prodType: string;
    private: boolean
    thumbnailCustom : string;

     /**
   * Constructor
   *
   * @param content
   */
  constructor(content) 
  {
    this.id = content.id;
    this.title = content.title || '';
    this.image = content.image;
    this.type = content.type;
    this.lastAttempt = content.lastAttempt;
    this.pass = content.pass;
    this.quizScore = content.quizScore;
    this.percentageViewed = content.percentageViewed;
    this.attempts = content.attempts;
    this.percentile = content.percentile;
    this.certificate = content.certificate;
    this.duration = content.duration;
    this.dueDate = content.dueDate;
    this.daysRemaining = content.daysRemaining;
    this.prerequisite = content.prerequisite;
    this.itemCode = content.itemCode;
    this.aspectRatio = content.aspectRatio;
    this.fastForward = content.fastForward;
    this.vendorID = content.vendorID;
    this.producerID = content.producerID;
    this.sku = content.sku;
    this.canRetakeQuiz = content.canRetakeQuiz;
    this.canRewatchVideo = content.canRewatchVideo;
    this.canStartCourse = content.canStartCourse;
    this.attemptsToday = content.attemptsToday;
    this.spanish = content.spanish;
    this.prodType = content.prodType;
    this.videoLogId  = content.videoLogId;
    this.private = content.isPrivate;
    this.thumbnailCustom = content.thumbnailCustom;

  }
  
  }