import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { AuthenticationService } from '../../authentication/_services';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AbsProgressBarService } from '@abs/components/progress-bar/progress-bar.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-admin-credentials-dialog',
  templateUrl: './admin-credentials-dialog.component.html',
  styleUrls: ['./admin-credentials-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AdminCredentialsDialogComponent implements OnInit {

  form: FormGroup;
  showPassword = false;
  ssoUser = false;



  /**
   * Constructor
   *
   * @param {MatDialogRef<GroupFormComponent>} matDialogRef
   * @param _data
   * @param {FormBuilder} _formBuilder
   */
  constructor(public matDialogRef: MatDialogRef<AdminCredentialsDialogComponent>,
    private _formBuilder: FormBuilder,
    public authenticationService: AuthenticationService,
    private snackBar: MatSnackBar,
  private progressBarService: AbsProgressBarService) {

    this.form = this.createAddUserForm();

  }

  ngOnInit() { 

    this.ssoUser = this.authenticationService.currentUserValue.signUpType == 'SSO';

    if(this.ssoUser){
      this.form.controls.username.disable();
      this.showPassword = true;
    }else{
      this.form.controls.username.enable();
    }

    this.form.controls['username'].valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged()
      )
      .subscribe(searchText => {
        console.log(this.form)
        console.log(this.form.controls.username);
        if (!this.form.controls.username.errors) {
          this.showPassword = true;
        }else{
          this.showPassword = false;
        }
      });
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Create add user form
   *
   * @returns {FormGroup}
   */
  createAddUserForm(): FormGroup {
    return this._formBuilder.group({
      username: ['',[Validators.required,Validators.email]],
      password: ['',Validators.required],
      profileId: [this.authenticationService.currentUserValue.profileId],
    });
  }

submit(){
  this.validateUser();
}


validateUser() {
    this.progressBarService.show();

    var type = this.ssoUser ? 'sso' : 'self';

    this.authenticationService.validatePassword(this.form.controls.username.value,this.form.controls.password.value,this.form.controls.profileId.value,type).subscribe(res => {
      this.progressBarService.hide();
      if(res){
        this.matDialogRef.close('done');
      }else{
        this.snackBar.open("Invalid Credentials.", '', {
          panelClass: 'red-bg',
          duration: 3000,
          horizontalPosition: 'right',
          verticalPosition: 'top',
          politeness: 'polite',
        });
      }
    });
  }


}
