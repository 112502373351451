import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { merge, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AbsNavigationService } from '@abs/components/navigation/navigation.service';
import { MatDialog } from '@angular/material/dialog';
import { AutoTrainingComponent } from 'app/main/auto-training/auto-training.component';
import { ShoppingCartService } from 'app/main/auto-training/cart.service';
import { Router } from '@angular/router';

@Component({
  selector: 'abs-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  encapsulation  : ViewEncapsulation.None,
  //changeDetection: ChangeDetectionStrategy.OnPush
})
export class AbsNavigationComponent implements OnInit
{
  @Input()
  layout = 'vertical';

  @Input()
  navigation: any;
  // Private
  private _unsubscribeAll: Subject<any>;
  


  /**
   *
   * @param {ChangeDetectorRef} _changeDetectorRef
   * @param {AbsNavigationService} _abseNavigationService
   */
  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _abseNavigationService: AbsNavigationService,
    private router: Router,)
  {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void
  {
    // Load the navigation either from the input or from the service
    this.navigation = this.navigation || this._abseNavigationService.getCurrentNavigation();

    // Subscribe to the current navigation changes
    this._abseNavigationService.onNavigationChanged
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(() => {

        // Load the navigation
        this.navigation = this._abseNavigationService.getCurrentNavigation();

        // Mark for check
        //this._changeDetectorRef.markForCheck();
      });

    // Subscribe to navigation item
    merge(
      this._abseNavigationService.onNavigationItemAdded,
      this._abseNavigationService.onNavigationItemUpdated,
      this._abseNavigationService.onNavigationItemRemoved
    ).pipe(takeUntil(this._unsubscribeAll))
      .subscribe(() => {

        // Mark for check
        //this._changeDetectorRef.markForCheck();
      });
  }
  public isActive(base: string): boolean {
   // console.log(this.router);
   // console.log(base);
    //this._changeDetectorRef.markForCheck();

    return this.router.url.includes(base);
    
 }
 
}
