import { Option } from './option';

export class Question {
    questionNo: number;
    question: string;
    hint:string;
    questionTypeId: number;
    answers: Option[];
    answered: boolean;

    constructor(data: any) {
        console.log("question data",data)

        data = data || {};
        this.questionNo = data.questionNo;
        this.question = data.question;
        this.hint = data.hint;
        if(data.questionTypeId){
            this.questionTypeId = data.questionTypeId;
        }
        this.answers = [];
        if (data.answers) {
            data.answers.forEach(o => {
                this.answers.push(new Option(o));
            });
        }
    }
}
