import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ShoppingCartService, CartItem } from '../cart.service';

@Component({
  selector: 'app-checkout-dialog',
  templateUrl: './checkout-dialog.component.html',
  styleUrls: ['./checkout-dialog.component.scss']
})
export class CheckoutDialogComponent implements OnInit {

  state = this.shoppingCartService.state$.pipe();
  constructor( public shoppingCartService: ShoppingCartService,public dialogRef: MatDialogRef<CheckoutDialogComponent>) { }
  ngOnInit() {
  }

  remove(item: CartItem): void {
    this.shoppingCartService.removeCartItem(item);
  }
}
