import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-learner-next-course-dialog',
  templateUrl: './learner-next-course-dialog.component.html',
  styleUrls: ['./learner-next-course-dialog.component.scss']
})
export class LearnerNextCourseDialogComponent implements OnInit {

  courseTitle: any;

  constructor(public matDialogRef: MatDialogRef<LearnerNextCourseDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any) {
      this.courseTitle = _data.courseTitle;
  }

  ngOnInit() {
  }

}
