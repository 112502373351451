import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { AbsUtils } from '@abs/utils';

import { Device } from 'app/main/user/devices/devices.model';

const httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json'
    })
  };

@Injectable()
export class DevicesService implements Resolve<any>
{
    onDevicesChanged: BehaviorSubject<any>;
    onSelectedDevicesChanged: BehaviorSubject<any>;
    onUserDataChanged: BehaviorSubject<any>;
    onSearchTextChanged: Subject<any>;
    onFilterChanged: Subject<any>;

    devices: Device[];
    user: any;
    selectedDevices: string[] = [];

    searchText: string;
    filterBy: string;

    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient
    )
    {
        // Set the defaults
        this.onDevicesChanged = new BehaviorSubject([]);
        this.onSelectedDevicesChanged = new BehaviorSubject([]);
        this.onUserDataChanged = new BehaviorSubject([]);
        this.onSearchTextChanged = new Subject();
        this.onFilterChanged = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        return new Promise((resolve, reject) => {

            Promise.all([
                this.getDevices(),
                this.getUserData()
            ]).then(
                ([files]) => {

                    this.onSearchTextChanged.subscribe(searchText => {
                        this.searchText = searchText;
                        this.getDevices();
                    });

                    this.onFilterChanged.subscribe(filter => {
                        this.filterBy = filter;
                        this.getDevices();
                    });

                    resolve();

                },
                reject
            );
        });
    }

    /**
     * Get devices
     *
     * @returns {Promise<any>}
     */
    getDevices(): Promise<any>
    {
        return new Promise((resolve, reject) => {
            this._httpClient.get(`${environment.apiUrl}/user/`+JSON.parse(localStorage.getItem("currentUser")).profileId+`/devices`,httpOptions)
            .subscribe((response: any) => {
                
                this.devices = response;

                if ( this.searchText && this.searchText !== '' )
                {
                    this.devices = AbsUtils.filterArrayByString(this.devices, this.searchText);
                }

                this.devices = this.devices.map(device => {
                    return new Device(device);
                });

                this.onDevicesChanged.next(this.devices);
                resolve(this.devices);
            }, reject);
           
            }
        );
    }

    /**
     * Get user data
     *
     * @returns {Promise<any>}
     */
    getUserData(): Promise<any>
    {
        return new Promise((resolve, reject) => {
                this._httpClient.get('api/devices-user/5725a6802d10e277a0f35724')
                    .subscribe((response: any) => {
                        this.user = response;
                        this.onUserDataChanged.next(this.user);
                        resolve(this.user);
                    }, reject);
            }
        );
    }

    /**
     * Toggle selected device by id
     *
     * @param id
     */
    toggleSelectedDevice(id): void
    {
        // First, check if we already have that device as selected...
        if ( this.selectedDevices.length > 0 )
        {
            const index = this.selectedDevices.indexOf(id);

            if ( index !== -1 )
            {
                this.selectedDevices.splice(index, 1);

                // Trigger the next event
                this.onSelectedDevicesChanged.next(this.selectedDevices);

                // Return
                return;
            }
        }

        // If we don't have it, push as selected
        this.selectedDevices.push(id);

        // Trigger the next event
        this.onSelectedDevicesChanged.next(this.selectedDevices);
    }

    /**
     * Toggle select all
     */
    toggleSelectAll(): void
    {
        if ( this.selectedDevices.length > 0 )
        {
            this.deselectDevices();
        }
        else
        {
            this.selectDevices();
        }
    }

    /**
     * Select devices
     *
     * @param filterParameter
     * @param filterValue
     */
    selectDevices(filterParameter?, filterValue?): void
    {
        this.selectedDevices = [];

        // If there is no filter, select all devices
        if ( filterParameter === undefined || filterValue === undefined )
        {
            this.selectedDevices = [];
            this.devices.map(device => {
                this.selectedDevices.push(device.id);
            });
        }

        // Trigger the next event
        this.onSelectedDevicesChanged.next(this.selectedDevices);
    }

    /**
     * Update device
     *
     * @param device
     * @returns {Promise<any>}
     */
    updateDevice(device): Promise<any>
    {
        return new Promise((resolve, reject) => {
            this._httpClient.post(`${environment.apiUrl}/user/device`, {...device},httpOptions)
                .subscribe(response => {
                    this.getDevices();
                    resolve(response);
                });
        });
    }

    /**
     * Update user data
     *
     * @param userData
     * @returns {Promise<any>}
     */
    updateUserData(userData): Promise<any>
    {
        return new Promise((resolve, reject) => {
            this._httpClient.post('api/devices-user/' + this.user.id, {...userData})
                .subscribe(response => {
                    this.getUserData();
                    this.getDevices();
                    resolve(response);
                });
        });
    }

    /**
     * Deselect devices
     */
    deselectDevices(): void
    {
        this.selectedDevices = [];

        // Trigger the next event
        this.onSelectedDevicesChanged.next(this.selectedDevices);
    }

    /**
     * Delete device
     *
     * @param device
     */
    deleteDevice(device): void
    {
        const deviceIndex = this.devices.indexOf(device);
        this.devices.splice(deviceIndex, 1);
        this.onDevicesChanged.next(this.devices);
    }

    /**
     * Delete selected devices
     */
    deleteSelectedDevices(): void
    {
        for ( const id of this.selectedDevices )
        {
            const device = this.devices.find(_device => {
                return _device.id === id;
            });
            const deviceIndex = this.devices.indexOf(device);
            this.devices.splice(deviceIndex, 1);
        }
        this.onDevicesChanged.next(this.devices);
        this.deselectDevices();
    }

}
