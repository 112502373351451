import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {Subject} from 'rxjs';
import {InsightService} from './insight.service';
import {takeUntil} from 'rxjs/operators';
import {animate, state, style, transition, trigger} from '@angular/animations';

export interface InsightItem {
  id: string;
  course: string;
  location: string;
  updated: string;
  date: string;
  students: string;
  rsvpd: string;
  attending: string;
  declined: string;

}

export interface InsightItemUsers {
  id: string;
  studentsName: string;
  checkedIn: string;
  lastQuizAttempt: string;
  attempts: string;
  lastScore: string;
  result: string;
  questions: InsightItemUsersQA[];
}

export interface InsightItemUsersQA {
  question: string;
  chosenAnswer: string;
  correctAnswer: string;
  comparison: string;
  questionStatus: string;
}

@Component({
  selector: 'app-insights',
  templateUrl: './insights.component.html',
  styleUrls: ['./insights.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class InsightsComponent implements OnInit, OnDestroy {
  panelOpenState = false;

  dataSource = ELEMENT_DATA;
  columnsToDisplay = ['studentsName', 'checkedIn', 'lastQuizAttempt', 'attempts', 'lastScore', 'result', 'buttons'];
  expandedElement: InsightItemUsers | null;

  iclassroom: InsightItem[] = [
    {
      id: '1',
      course: 'Warehouse People',
      location: 'John Henry Library',
      date: '2/3/2020',
      attending: '50',
      rsvpd: '75',
      declined: '3',
      students: '12',
      updated: 'asdasdasdasdasd'
    },
    {
      id: '2',
      course: 'Clean Room Dept',
      location: 'Bloodborne Pathogens',
      date: '2/3/2020',
      attending: '10',
      rsvpd: '85',
      declined: '3',
      students: '12',
      updated: 'asdasdasdasdasd'
    },
    {
      id: '3',
      course: 'Outdoor Maint',
      location: 'Grounds Keeping',
      date: '2/3/2020',
      attending: '10',
      rsvpd: '35',
      declined: '3',
      students: '120',
      updated: 'asdasdasdasdasd'
    },
    {
      id: '4',
      course: 'Hospitality',
      location: 'Bed Bug Awareness',
      date: '2/3/2020',
      attending: '40',
      rsvpd: '65',
      declined: '3',
      students: '150',
      updated: 'asdasdasdasdasd'
    },
    {
      id: '5',
      course: 'Clean Room Dept',
      location: 'Bloodborne Pathogens',
      date: '2/3/2020',
      attending: '10',
      rsvpd: '85',
      declined: '3',
      students: '12',
      updated: 'asdasdasdasdasd'
    },
    {
      id: '6',
      course: 'Outdoor Maint',
      location: 'Grounds Keeping',
      date: '2/3/2020',
      attending: '10',
      rsvpd: '35',
      declined: '3',
      students: '120',
      updated: 'asdasdasdasdasd'
    },
    {
      id: '7',
      course: 'Warehouse People',
      location: 'John Henry Library',
      date: '2/3/2020',
      attending: '50',
      rsvpd: '75',
      declined: '3',
      students: '12',
      updated: 'asdasdasdasdasd'
    },
    {
      id: '8',
      course: 'Clean Room Dept',
      location: 'Bloodborne Pathogens',
      date: '2/3/2020',
      attending: '10',
      rsvpd: '85',
      declined: '3',
      students: '12',
      updated: 'asdasdasdasdasd'
    },
    {
      id: '9',
      course: 'Outdoor Maint',
      location: 'Grounds Keeping',
      date: '2/3/2020',
      attending: '10',
      rsvpd: '35',
      declined: '3',
      students: '120',
      updated: 'asdasdasdasdasd'
    },
  ];

  selectedChat: any;

  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {InsightService} _chatService
   */
  constructor(private _chatService: InsightService) {

    // Set the private defaults
    this._unsubscribeAll = new Subject();

  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this._chatService.onChatSelected
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(chatData => {
        this.selectedChat = chatData;
      });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}

const ELEMENT_DATA: InsightItemUsers[] = [
  {
    id: '1',
    studentsName: 'Faizan Haider',
    checkedIn: '2/4/2019 12:00 AM',
    lastQuizAttempt: '2/4/2019 12:00 PM',
    attempts: '2',
    lastScore: '85',
    result: 'pass',
    questions: [
      {
        question: 'What is center of gravity?',
        chosenAnswer: 'The Ground',
        correctAnswer: '12 High',
        comparison: '5% Got this wrong',
        questionStatus: 'correct'
      },
      {
        question: 'What is center of oatmeal?',
        chosenAnswer: 'The Milk',
        correctAnswer: 'Do not know',
        comparison: '7% Got this wrong',
        questionStatus: 'correct'
      },
      {
        question: 'What is center of earth?',
        chosenAnswer: 'The Core',
        correctAnswer: 'China',
        comparison: '50% got this right',
        questionStatus: 'incorrect'
      },
      {
        question: 'What is center of space?',
        chosenAnswer: 'The Sky',
        correctAnswer: 'Her Brain',
        comparison: '95% Got this right',
        questionStatus: 'correct'
      },
      {
        question: 'What is center of eggs?',
        chosenAnswer: 'The Shell',
        correctAnswer: 'Yolk',
        comparison: '2% got this wrong',
        questionStatus: 'incorrect'
      }
    ]
  },
  {
    id: '2',
    studentsName: 'Faizan Haider',
    checkedIn: '2/4/2019 12:00 AM',
    lastQuizAttempt: '2/4/2019 12:00 PM',
    attempts: '2',
    lastScore: '25',
    result: 'fail',
    questions: [
      {
        question: 'What is center of space?',
        chosenAnswer: 'The Sky',
        correctAnswer: 'Her Brain',
        comparison: '50%',
        questionStatus: 'incorrect'
      },
      {
        question: 'What is center of eggs?',
        chosenAnswer: 'The Shell',
        correctAnswer: 'Yolk',
        comparison: '20%',
        questionStatus: 'correct'
      },
      {
        question: 'What is center of oatmeal?',
        chosenAnswer: 'The Milk',
        correctAnswer: 'Do not know',
        comparison: '80%',
        questionStatus: 'incorrect'
      },
      {
        question: 'What is center of gravity?',
        chosenAnswer: 'The Ground',
        correctAnswer: '12 High',
        comparison: '90%',
        questionStatus: 'correct'
      },
      {
        question: 'What is center of earth?',
        chosenAnswer: 'The Core',
        correctAnswer: 'China',
        comparison: '97%',
        questionStatus: 'incorrect'
      }
    ]
  },
  {
    id: '3',
    studentsName: 'Faizan Haider',
    checkedIn: '2/4/2019 12:00 AM',
    lastQuizAttempt: '2/4/2019 12:00 PM',
    attempts: '2',
    lastScore: '25',
    result: 'pass',
    questions: [
      {
        question: 'What is center of space?',
        chosenAnswer: 'The Sky',
        correctAnswer: 'Her Brain',
        comparison: '90%',
        questionStatus: 'correct'
      },
      {
        question: 'What is center of eggs?',
        chosenAnswer: 'The Shell',
        correctAnswer: 'Yolk',
        comparison: '20%',
        questionStatus: 'correct'
      },
      {
        question: 'What is center of oatmeal?',
        chosenAnswer: 'The Milk',
        correctAnswer: 'Do not know',
        comparison: '80%',
        questionStatus: 'correct'
      },
      {
        question: 'What is center of gravity?',
        chosenAnswer: 'The Ground',
        correctAnswer: '12 High',
        comparison: '90%',
        questionStatus: 'correct'
      },
      {
        question: 'What is center of earth?',
        chosenAnswer: 'The Core',
        correctAnswer: 'China',
        comparison: '97%',
        questionStatus: 'incorrect'
      }
    ]
  },
  {
    id: '4',
    studentsName: 'Faizan Haider',
    checkedIn: '2/4/2019 12:00 AM',
    lastQuizAttempt: '2/4/2019 12:00 PM',
    attempts: '2',
    lastScore: '25',
    result: 'fail',
    questions: [
      {
        question: 'What is center of space?',
        chosenAnswer: 'The Sky',
        correctAnswer: 'Her Brain',
        comparison: '50%',
        questionStatus: 'incorrect'
      },
      {
        question: 'What is center of eggs?',
        chosenAnswer: 'The Shell',
        correctAnswer: 'Yolk',
        comparison: '20%',
        questionStatus: 'incorrect'
      },
      {
        question: 'What is center of oatmeal?',
        chosenAnswer: 'The Milk',
        correctAnswer: 'Do not know',
        comparison: '80%',
        questionStatus: 'incorrect'
      },
      {
        question: 'What is center of gravity?',
        chosenAnswer: 'The Ground',
        correctAnswer: '12 High',
        comparison: '90%',
        questionStatus: 'correct'
      },
      {
        question: 'What is center of earth?',
        chosenAnswer: 'The Core',
        correctAnswer: 'China',
        comparison: '97%',
        questionStatus: 'incorrect'
      }
    ]
  },
  {
    id: '5',
    studentsName: 'Faizan Haider',
    checkedIn: '2/4/2019 12:00 AM',
    lastQuizAttempt: '2/4/2019 12:00 PM',
    attempts: '2',
    lastScore: '25',
    result: 'fail',
    questions: [
      {
        question: 'What is center of space?',
        chosenAnswer: 'The Sky',
        correctAnswer: 'Her Brain',
        comparison: '50%',
        questionStatus: 'incorrect'
      },
      {
        question: 'What is center of eggs?',
        chosenAnswer: 'The Shell',
        correctAnswer: 'Yolk',
        comparison: '20%',
        questionStatus: 'correct'
      },
      {
        question: 'What is center of oatmeal?',
        chosenAnswer: 'The Milk',
        correctAnswer: 'Do not know',
        comparison: '80%',
        questionStatus: 'incorrect'
      },
      {
        question: 'What is center of gravity?',
        chosenAnswer: 'The Ground',
        correctAnswer: '12 High',
        comparison: '90%',
        questionStatus: 'correct'
      },
      {
        question: 'What is center of earth?',
        chosenAnswer: 'The Core',
        correctAnswer: 'China',
        comparison: '97%',
        questionStatus: 'incorrect'
      }
    ]
  },
  {
    id: '6',
    studentsName: 'Faizan Haider',
    checkedIn: '2/4/2019 12:00 AM',
    lastQuizAttempt: '2/4/2019 12:00 PM',
    attempts: '2',
    lastScore: '25',
    result: 'fail',
    questions: [
      {
        question: 'What is center of space?',
        chosenAnswer: 'The Sky',
        correctAnswer: 'Her Brain',
        comparison: '50%',
        questionStatus: 'incorrect'
      },
      {
        question: 'What is center of eggs?',
        chosenAnswer: 'The Shell',
        correctAnswer: 'Yolk',
        comparison: '20%',
        questionStatus: 'correct'
      },
      {
        question: 'What is center of oatmeal?',
        chosenAnswer: 'The Milk',
        correctAnswer: 'Do not know',
        comparison: '80%',
        questionStatus: 'incorrect'
      },
      {
        question: 'What is center of gravity?',
        chosenAnswer: 'The Ground',
        correctAnswer: '12 High',
        comparison: '90%',
        questionStatus: 'correct'
      },
      {
        question: 'What is center of earth?',
        chosenAnswer: 'The Core',
        correctAnswer: 'China',
        comparison: '97%',
        questionStatus: 'incorrect'
      }
    ]
  },
  {
    id: '7',
    studentsName: 'Faizan Haider',
    checkedIn: '2/4/2019 12:00 AM',
    lastQuizAttempt: '2/4/2019 12:00 PM',
    attempts: '2',
    lastScore: '25',
    result: 'fail',
    questions: [
      {
        question: 'What is center of space?',
        chosenAnswer: 'The Sky',
        correctAnswer: 'Her Brain',
        comparison: '50%',
        questionStatus: 'incorrect'
      },
      {
        question: 'What is center of eggs?',
        chosenAnswer: 'The Shell',
        correctAnswer: 'Yolk',
        comparison: '20%',
        questionStatus: 'correct'
      },
      {
        question: 'What is center of oatmeal?',
        chosenAnswer: 'The Milk',
        correctAnswer: 'Do not know',
        comparison: '80%',
        questionStatus: 'incorrect'
      },
      {
        question: 'What is center of gravity?',
        chosenAnswer: 'The Ground',
        correctAnswer: '12 High',
        comparison: '90%',
        questionStatus: 'correct'
      },
      {
        question: 'What is center of earth?',
        chosenAnswer: 'The Core',
        correctAnswer: 'China',
        comparison: '97%',
        questionStatus: 'incorrect'
      }
    ]
  },
  {
    id: '8',
    studentsName: 'Faizan Haider',
    checkedIn: '2/4/2019 12:00 AM',
    lastQuizAttempt: '2/4/2019 12:00 PM',
    attempts: '2',
    lastScore: '25',
    result: 'pass',
    questions: [
      {
        question: 'What is center of space?',
        chosenAnswer: 'The Sky',
        correctAnswer: 'Her Brain',
        comparison: '50%',
        questionStatus: 'correct'
      },
      {
        question: 'What is center of eggs?',
        chosenAnswer: 'The Shell',
        correctAnswer: 'Yolk',
        comparison: '20%',
        questionStatus: 'correct'
      },
      {
        question: 'What is center of oatmeal?',
        chosenAnswer: 'The Milk',
        correctAnswer: 'Do not know',
        comparison: '80%',
        questionStatus: 'correct'
      },
      {
        question: 'What is center of gravity?',
        chosenAnswer: 'The Ground',
        correctAnswer: '12 High',
        comparison: '90%',
        questionStatus: 'correct'
      },
      {
        question: 'What is center of earth?',
        chosenAnswer: 'The Core',
        correctAnswer: 'China',
        comparison: '97%',
        questionStatus: 'correct'
      }
    ]
  },
  {
    id: '9',
    studentsName: 'Faizan Haider',
    checkedIn: '2/4/2019 12:00 AM',
    lastQuizAttempt: '2/4/2019 12:00 PM',
    attempts: '2',
    lastScore: '25',
    result: 'fail',
    questions: [
      {
        question: 'What is center of space?',
        chosenAnswer: 'The Sky',
        correctAnswer: 'Her Brain',
        comparison: '50%',
        questionStatus: 'incorrect'
      },
      {
        question: 'What is center of eggs?',
        chosenAnswer: 'The Shell',
        correctAnswer: 'Yolk',
        comparison: '20%',
        questionStatus: 'correct'
      },
      {
        question: 'What is center of oatmeal?',
        chosenAnswer: 'The Milk',
        correctAnswer: 'Do not know',
        comparison: '80%',
        questionStatus: 'incorrect'
      },
      {
        question: 'What is center of gravity?',
        chosenAnswer: 'The Ground',
        correctAnswer: '12 High',
        comparison: '90%',
        questionStatus: 'correct'
      },
      {
        question: 'What is center of earth?',
        chosenAnswer: 'The Core',
        correctAnswer: 'China',
        comparison: '97%',
        questionStatus: 'incorrect'
      }
    ]
  },
  {
    id: '10',
    studentsName: 'Faizan Haider',
    checkedIn: '2/4/2019 12:00 AM',
    lastQuizAttempt: '2/4/2019 12:00 PM',
    attempts: '2',
    lastScore: '25',
    result: 'fail',
    questions: [
      {
        question: 'What is center of space?',
        chosenAnswer: 'The Sky',
        correctAnswer: 'Her Brain',
        comparison: '50%',
        questionStatus: 'incorrect'
      },
      {
        question: 'What is center of eggs?',
        chosenAnswer: 'The Shell',
        correctAnswer: 'Yolk',
        comparison: '20%',
        questionStatus: 'correct'
      },
      {
        question: 'What is center of oatmeal?',
        chosenAnswer: 'The Milk',
        correctAnswer: 'Do not know',
        comparison: '80%',
        questionStatus: 'incorrect'
      },
      {
        question: 'What is center of gravity?',
        chosenAnswer: 'The Ground',
        correctAnswer: '12 High',
        comparison: '90%',
        questionStatus: 'correct'
      },
      {
        question: 'What is center of earth?',
        chosenAnswer: 'The Core',
        correctAnswer: 'China',
        comparison: '97%',
        questionStatus: 'incorrect'
      }
    ]
  },
  {
    id: '11',
    studentsName: 'Faizan Haider',
    checkedIn: '2/4/2019 12:00 AM',
    lastQuizAttempt: '2/4/2019 12:00 PM',
    attempts: '2',
    lastScore: '25',
    result: 'fail',
    questions: [
      {
        question: 'What is center of space?',
        chosenAnswer: 'The Sky',
        correctAnswer: 'Her Brain',
        comparison: '50%',
        questionStatus: 'incorrect'
      },
      {
        question: 'What is center of eggs?',
        chosenAnswer: 'The Shell',
        correctAnswer: 'Yolk',
        comparison: '20%',
        questionStatus: 'correct'
      },
      {
        question: 'What is center of oatmeal?',
        chosenAnswer: 'The Milk',
        correctAnswer: 'Do not know',
        comparison: '80%',
        questionStatus: 'incorrect'
      },
      {
        question: 'What is center of gravity?',
        chosenAnswer: 'The Ground',
        correctAnswer: '12 High',
        comparison: '90%',
        questionStatus: 'correct'
      },
      {
        question: 'What is center of earth?',
        chosenAnswer: 'The Core',
        correctAnswer: 'China',
        comparison: '97%',
        questionStatus: 'incorrect'
      }
    ]
  },
  {
    id: '12',
    studentsName: 'Faizan Haider',
    checkedIn: '2/4/2019 12:00 AM',
    lastQuizAttempt: '2/4/2019 12:00 PM',
    attempts: '2',
    lastScore: '25',
    result: 'fail',
    questions: [
      {
        question: 'What is center of space?',
        chosenAnswer: 'The Sky',
        correctAnswer: 'Her Brain',
        comparison: '50%',
        questionStatus: 'incorrect'
      },
      {
        question: 'What is center of eggs?',
        chosenAnswer: 'The Shell',
        correctAnswer: 'Yolk',
        comparison: '20%',
        questionStatus: 'correct'
      },
      {
        question: 'What is center of oatmeal?',
        chosenAnswer: 'The Milk',
        correctAnswer: 'Do not know',
        comparison: '80%',
        questionStatus: 'incorrect'
      },
      {
        question: 'What is center of gravity?',
        chosenAnswer: 'The Ground',
        correctAnswer: '12 High',
        comparison: '90%',
        questionStatus: 'correct'
      },
      {
        question: 'What is center of earth?',
        chosenAnswer: 'The Core',
        correctAnswer: 'China',
        comparison: '97%',
        questionStatus: 'incorrect'
      }
    ]
  },
  {
    id: '13',
    studentsName: 'Faizan Haider',
    checkedIn: '2/4/2019 12:00 AM',
    lastQuizAttempt: '2/4/2019 12:00 PM',
    attempts: '2',
    lastScore: '25',
    result: 'pass',
    questions: [
      {
        question: 'What is center of space?',
        chosenAnswer: 'The Sky',
        correctAnswer: 'Her Brain',
        comparison: '50%',
        questionStatus: 'correct'
      },
      {
        question: 'What is center of eggs?',
        chosenAnswer: 'The Shell',
        correctAnswer: 'Yolk',
        comparison: '20%',
        questionStatus: 'correct'
      },
      {
        question: 'What is center of oatmeal?',
        chosenAnswer: 'The Milk',
        correctAnswer: 'Do not know',
        comparison: '80%',
        questionStatus: 'correct'
      },
      {
        question: 'What is center of gravity?',
        chosenAnswer: 'The Ground',
        correctAnswer: '12 High',
        comparison: '90%',
        questionStatus: 'correct'
      },
      {
        question: 'What is center of earth?',
        chosenAnswer: 'The Core',
        correctAnswer: 'China',
        comparison: '97%',
        questionStatus: 'correct'
      }
    ]
  },
  {
    id: '14',
    studentsName: 'Faizan Haider',
    checkedIn: '2/4/2019 12:00 AM',
    lastQuizAttempt: '2/4/2019 12:00 PM',
    attempts: '2',
    lastScore: '25',
    result: 'pass',
    questions: [
      {
        question: 'What is center of space?',
        chosenAnswer: 'The Sky',
        correctAnswer: 'Her Brain',
        comparison: '50%',
        questionStatus: 'correct'
      },
      {
        question: 'What is center of eggs?',
        chosenAnswer: 'The Shell',
        correctAnswer: 'Yolk',
        comparison: '20%',
        questionStatus: 'correct'
      },
      {
        question: 'What is center of oatmeal?',
        chosenAnswer: 'The Milk',
        correctAnswer: 'Do not know',
        comparison: '80%',
        questionStatus: 'incorrect'
      },
      {
        question: 'What is center of gravity?',
        chosenAnswer: 'The Ground',
        correctAnswer: '12 High',
        comparison: '90%',
        questionStatus: 'correct'
      },
      {
        question: 'What is center of earth?',
        chosenAnswer: 'The Core',
        correctAnswer: 'China',
        comparison: '97%',
        questionStatus: 'incorrect'
      }
    ]
  },
  {
    id: '15',
    studentsName: 'Faizan Haider',
    checkedIn: '2/4/2019 12:00 AM',
    lastQuizAttempt: '2/4/2019 12:00 PM',
    attempts: '2',
    lastScore: '25',
    result: 'pass',
    questions: [
      {
        question: 'What is center of space?',
        chosenAnswer: 'The Sky',
        correctAnswer: 'Her Brain',
        comparison: '50%',
        questionStatus: 'correct'
      },
      {
        question: 'What is center of eggs?',
        chosenAnswer: 'The Shell',
        correctAnswer: 'Yolk',
        comparison: '20%',
        questionStatus: 'correct'
      },
      {
        question: 'What is center of oatmeal?',
        chosenAnswer: 'The Milk',
        correctAnswer: 'Do not know',
        comparison: '80%',
        questionStatus: 'correct'
      },
      {
        question: 'What is center of gravity?',
        chosenAnswer: 'The Ground',
        correctAnswer: '12 High',
        comparison: '90%',
        questionStatus: 'correct'
      },
      {
        question: 'What is center of earth?',
        chosenAnswer: 'The Core',
        correctAnswer: 'China',
        comparison: '97%',
        questionStatus: 'incorrect'
      }
    ]
  }];
