import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { AuthenticationService } from '../../authentication/_services';
import { QuizService } from '../quiz/services/quiz.service';
import { QuizQuitDialogComponent } from './quiz-quit-dialog/quiz-quit-dialog.component';
import { AbsProgressBarService } from '@abs/components/progress-bar/progress-bar.service';

@Component({
  selector: 'app-quiz-editor-dialog',
  templateUrl: './quiz-editor-dialog.component.html',
  styleUrls: ['./quiz-editor-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class QuizEditorComponentDialog implements OnInit {

  contentId
  done = false
  productType

  constructor(public matDialogRef: MatDialogRef<QuizEditorComponentDialog>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private _auth: AuthenticationService,
    private _quizService: QuizService,
    private quitDialog: MatDialog,
    private absProg: AbsProgressBarService) {
    this.contentId = _data.content.id
    this.productType = _data.content.prodType
  }

  ngOnInit(): void {
  }

  onQuizEditorDone(event) {
    console.log("hello", event)

    this.absProg.show();
    let quiz = event.value;
    quiz.companyID = this._auth.currentUserValue.companyId;
    quiz.profileID = this._auth.currentUserValue.profileId;
    quiz.contentID = this.contentId;
    quiz.custom = true;
    this._quizService.saveQuiz(quiz).subscribe(res => {
      this.absProg.hide();
      if (res) {
        this.done = true;
      }
    })
  }

  handleBack($event) {

  
  }

  onQuizEditorSelection(e){
    console.log(e)
  }

  showQuitDialog() {
    let ref = this.quitDialog.open(QuizQuitDialogComponent, {
      id: 'quit-quiz',
      backdropClass: 'previewBackdropQuiz',
      width: '500px',
      height: '400px',


    });

    ref.afterClosed()
      .subscribe((result) => {
        console.log("result", result)
        if (result == 'true') {
          this.matDialogRef.close();
        }
      });

  }

}
