import { Component, OnInit, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { ActionBarService } from './action-bar.service';
import { environment } from 'environments/environment';
import * as Stomp from 'stompjs';
import * as SockJS from 'sockjs-client';
import { MatDialog } from '@angular/material/dialog';
import { LearnerNextCourseDialogComponent } from './learner-next-course-dialog/learner-next-course-dialog.component';

@Component({
  selector: 'app-action-bar',
  templateUrl: './action-bar.component.html',
  styleUrls: ['./action-bar.component.scss'],
  encapsulation: ViewEncapsulation.None

})
export class ActionBarComponent implements OnInit {

  private currentClassId;
  private stompClient;

  showDisable = true;
  enableUserButton = false;
  enableContentButton = false;
  enableClassButton = false;
  enableGroupButton = false;
  enableScheduleItButton = false;
  lastCourse = false;
  enableCloseSessionButton = false;
  kioskModeEnabled = false;

  showResumeButton = false;
  showKioskModeButton = false;

  router: string;
  private serverUrl = `${environment.apiUrl}/socket`;

  constructor(public _router: Router, public _actionBarService: ActionBarService, public dialog: MatDialog,
  ) {
    this.router = _router.url;
  }
  ngOnInit() {

    this._actionBarService.onUserStatusChanged.subscribe(active => {
      if (active) {
        this.showDisable = true;
      } else {
        this.showDisable = false;
      }
    })

    this._actionBarService.onUserSelected.subscribe(selected => {
      if (selected) {
        this.enableUserButton = true;
      } else {
        this.enableUserButton = false;
      }
    })

    this._actionBarService.onContentSelected.subscribe(selected => {
      if (selected) {
        this.enableContentButton = true;
      } else {
        this.enableContentButton = false;
      }
    })

    this._actionBarService.onStartCourseClick.subscribe(selected => {
      console.log("acs", selected);
      if (selected) {
        this.enableCloseSessionButton = true;
        this.enableClassButton = true;
      } else {
        this.enableClassButton = false;
      }
    })

    this._actionBarService.onScheduleItClick.subscribe(selected => {
      if (selected) {
        this.enableScheduleItButton = true;
      } else {
        this.enableScheduleItButton = false;
      }
    })

    this._actionBarService.onClassLoaded.subscribe(value => {
      console.log("acs", value);
      if (value == 'resume') {
        this.showResumeButton = true;
      } else {
        this.showResumeButton = false;
      }
    })

    this._actionBarService.onGroupSelected.subscribe(selected => {
      console.log("hello", selected);
      if (selected) {
        this.enableGroupButton = true;
      } else {
        this.enableGroupButton = false;
      }
    })

    this._actionBarService.showKioskMode.subscribe(selected => {
      console.log("asd",selected)
      if (selected) {
        this.showKioskModeButton = true;
        this.kioskModeEnabled = false;
      } else {
        
        this.showKioskModeButton = false;
      }
    })

    this._actionBarService.onClassCourseChanged.subscribe(value => {
      this.lastCourse = value;
    })
  }

  initializeWebSocketConnection() {
    let ws = new SockJS(this.serverUrl);
    this.stompClient = Stomp.over(ws);
    let that = this;
    this.stompClient.connect({}, function (frame) {
      that.stompClient.subscribe("/training/next", (message) => {
        console.log(message);
        if (message.body) {
          this.dialog.open(LearnerNextCourseDialogComponent, {
            id: 'nextCourseLearner',
            backdropClass: 'previewBackdrop',
            width: '400px',
            data: {
              courseTitle: message.body
            }
          });
        }
      });
    });
  }

  nextCourse() {
    this._actionBarService.onNextCourseClick.next("clicked");
  }

  scheduleIt() {
    this._actionBarService.onScheduleItClick.next("clicked");

  }

  addUserForm() {
    this._actionBarService.onAddUserClick.next("clicked");
  }

  sendLoginInfo() {
    this._actionBarService.onSendLoginInfoClick.next("clicked");
  }

  resetPassword() {
    this._actionBarService.onPasswordResetClick.next("clicked");
  }

  disableUser() {
    this._actionBarService.onDisableUserClick.next("clicked");
  }

  enableUser() {
    this._actionBarService.onEnableUserClick.next("clicked");
  }
  changePassword() {
    this._actionBarService.onChangePasswordClick.next("clicked");
  }
  startClass() {
    this._actionBarService.onStartClassClick.next("clicked");
  }
  resumeClass() {
    this._actionBarService.onResumeClassClick.next("clicked");
  }
  showWifiInfo(value) {
    if (value == 'projector') {
      this._actionBarService.onWifiClick.next("projector");
    }
    if (value == 'classroom') {
      this._actionBarService.onWifiClick.next("classroom");

    }

  }
  startCourse() {
    this._actionBarService.onStartCourseClick.next("clicked");
  }

  sendQuizLink() {
    this._actionBarService.onSendQuizLinkClick.next("clicked");

  }
  viewQuiz() {
    this._actionBarService.onViewQuizClick.next("clicked");

  }
  closeSession() {
    this._actionBarService.onCloseSessionClick.next("clicked");

  }

  addLocation() {
    this._actionBarService.onAddLocationClick.next("clicked");
  }

  export(value) {
    this._actionBarService.onExportButtonClick.next(value);
  }
  resetReport() {
    this._actionBarService.onResetReportClick.next("clicked");
  }

  startKioskMode() {
    this.kioskModeEnabled = true;
    this._actionBarService.onKioskModeClick.next("clicked");
  }
}
