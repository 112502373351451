import { Component, OnInit, Output, EventEmitter, ViewEncapsulation, Input } from '@angular/core';
import { absAnimations } from '@abs/animations';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
//import * as moment from 'moment';
import moment from 'moment-recur';

import { SelectionModel } from '@angular/cdk/collections';
import { Frequency, AutomatedSchedule } from 'app/main/training/assign-wizard/assign-wizard.component';
import { SharedData } from '../assign-wizard-reborn.component';
import { TrainingService } from '../../_services/training.service';

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'LL',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
@Component({
  selector: 'app-independent-training-wizard',
  templateUrl: './independent-training-wizard.component.html',
  styleUrls: ['./independent-training-wizard.component.scss'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true }},
    {
      provide: DateAdapter, useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
  animations: absAnimations,
  encapsulation: ViewEncapsulation.None,
})
export class IndependentTrainingWizardComponent implements OnInit {
  flip: any;
  length: any;
  ordinal = ['', 'First', 'Second', 'Third', 'Fourth', 'Last']

  @Input()
  step = 1;


  minDate: any;
  minEndDate: any;
  maxDate: any;
  frequencyList: any;
  frequency: any;
  autoRepeat: any;
  frequencySelction = new SelectionModel<Frequency>(false, []);
  automatedSchedule: any;
  occurance;
  @Input()
  selectedPlaylist: any;

  @Input()
  trainingType: any = "SCHEDULE";

  @Input()
  startDate: any;

  @Input()
  endDate: any;

  @Input()
  default: SharedData;

  @Output() onBackButton = new EventEmitter<any>();
  @Output() onDone = new EventEmitter<any>();

  constructor(private trainingService: TrainingService
  ) {

  }

  ngOnInit(): void {
    console.log("trainingType", this)

    console.log("trainingType", this.trainingType)
    this.minDate = moment();
    this.minEndDate = moment().add(1, 'days');
    this.maxDate = moment().add(1, 'days');
    if (this.default) {
      console.log("default", this.default);
      if (this.default.trainingType) {
        this.trainingType = this.default.trainingType;
      }
      if (this.default.startDate) {
        this.startDate = this.default.startDate;
        var date = this.startDate;
        var week = this.weekOfMonth(date);
        this.frequencyList = [
          { value: "D", text: "Daily" },
          { value: "W", text: "Weekly on " + date.format('dddd') },
          { value: "M", text: "Monthly on the " + week + " " + date.format('dddd') },
          { value: "Q", text: "Quarterly on the " + week + " " + date.format('dddd') },
          { value: "S", text: "Semi Annually on the " + week + " " + date.format('dddd') },
          { value: "A", text: "Annually on the " + week + " " + date.format('dddd') + " of " + date.format('MMMM') }
        ];
        this.frequency = this.default.frequency;


        this.frequencyList.forEach(element => {
            if(element.value == this.frequency.value){
              this.frequencySelction.select(element);
            }
        });
        this.automatedSchedule = [];
        var date = moment(this.startDate).format('YYYY-MM-DD');
        var endDate = moment(this.endDate).format('YYYY-MM-DD');
        var newDate = date;
        this.trainingService.getAutomatedSchedule(date, endDate, this.frequency.value, this.startDate.format('dddd').toUpperCase(), this.occurance, this.default.contents).then(result => {
          this.automatedSchedule = result;
        })
        console.log("freq selection", this.frequencySelction);
      }
      if (this.default.endDate) {

        this.endDate = this.default.endDate;
      }
      if (this.default.step) {
        this.step = this.default.step;
      }

      if (this.default.repeat) {
        this.autoRepeat = this.default.repeat;
      }


    }

  }
  onStartDateSelect(event) {
    this.startDate = event;
    this.minEndDate = this.startDate;
    this.endDate = moment(this.startDate).add(7, 'days')
    this.length = this.endDate.endOf('day').diff(this.startDate.startOf('day'), 'days');

    var date = event;

    var week = this.weekOfMonth(date);
    this.frequencyList = [
      { value: "D", text: "Daily" },
      { value: "W", text: "Weekly on " + date.format('dddd') },
      { value: "M", text: "Monthly on the " + week + " " + date.format('dddd') },
      { value: "Q", text: "Quarterly on the " + week + " " + date.format('dddd') },
      { value: "S", text: "Semi Annually on the " + week + " " + date.format('dddd') },
      { value: "A", text: "Annually on the " + week + " " + date.format('dddd') + " of " + date.format('MMMM') }
    ];

    console.log("data chnaged",this.frequencySelction);

  }

  weekOfMonth(input) {
    const firstDayOfMonth = input.clone().startOf('month');
    const firstDayOfWeek = firstDayOfMonth.clone().startOf('week');

    const offset = firstDayOfMonth.diff(firstDayOfWeek, 'days');
    this.occurance = Math.ceil((input.date()) / 7);
    return this.ordinal[this.occurance];
  }

  onEndDateSelect(event) {
    this.endDate = event;
    this.length = this.endDate.endOf('day').diff(this.startDate.startOf('day'), 'days');
    this.onDone.emit({ startDate: this.startDate, endDate: this.endDate, length: this.length });
  }

  onRecurringEndDateSelect(event) {
    this.endDate = event;
    this.length = this.endDate.endOf('day').diff(this.startDate.startOf('day'), 'days');
    this.step = 3;

  }

  handleBack() {
    this.onBackButton.emit('independent');
  }

  done(value) {
    this.autoRepeat = value;
    this.onDone.emit({
      startDate: this.startDate,
      endDate: this.endDate,
      automatedSchedule: this.automatedSchedule,
      length: this.length,
      frequency: this.frequency,
      freqDay: this.startDate.format('dddd').toUpperCase(),
      freqWeek: this.occurance,
      repeat: this.autoRepeat,
      trainingType: this.trainingType
    });
  }

  setFrequency(freq) {
    const self = this;
    const value = freq.value;
    this.frequencySelction.select(freq);
    this.automatedSchedule = [];
    this.frequency = freq;
    var date = moment(this.startDate).format('YYYY-MM-DD');
    var endDate = moment(this.endDate).format('YYYY-MM-DD');

    var newDate = date;
    console.log(" date", date);
    console.log("end date", endDate);

    this.trainingService.getAutomatedSchedule(date, endDate, value, this.startDate.format('dddd').toUpperCase(), this.occurance, this.default.contents).then(result => {
      this.automatedSchedule = result;
    })

    /* this.default.contents.forEach(function (content, index) {
      if (index == 0) {
        self.automatedSchedule.push({ date: moment(date), playlist: content })
      } else {
        if (value === 'D') {
          newDate = date.add(1, 'days')
        } else if (value === 'W') {
          date = date.clone().add(1, 'weeks')
        }
        else if (value === 'M') {
          newDate = date.add(1, 'months');
          date = nthDayOfMonth(newDate, self.startDate.day(),self.occurance)
        }
        else if (value === 'Q') {
          newDate = date.add(1, 'quarters');
          date = nthDayOfMonth(newDate, self.startDate.day(),self.occurance)
        }
        else if (value === 'S') {
          newDate = date.add(6, 'months');
          date = nthDayOfMonth(newDate, self.startDate.day(),self.occurance)
        }
        else if (value === 'A') {
          newDate = date.add(1, 'years')
          date = nthDayOfMonth(newDate, self.startDate.day(),self.occurance)
        }
        self.automatedSchedule.push({ date: moment(date), playlist: content })
      }
    }) */
    console.log(self.automatedSchedule)
    if (this.trainingType == 'FREQUENT') {
      this.step = 4;
    } else {
      this.done(true);
    }
  }
  descClicked(value) {
    this.flip = value;
  }
  getNthWeekday(startDate, occurance = 1, day = 0) {
    let startOfMonth = moment(startDate).utc().startOf("month").startOf("week");
    let finalDate = moment(startDate)
      .utc()
      .startOf("month")
      .startOf("week")
      .add(day, "d")
      .add(occurance, "w");
    if (finalDate.month() == startOfMonth.month()) {
      finalDate = finalDate.subtract(1, "w");
    }
    return finalDate;
  }

  nextStep() {
    this.step++;
  }
  setAutoRepeat(value) {
    this.done(value);
  }
}

const nthDayOfMonth = (monthMoment, day, weekNumber) => {
  let m = monthMoment.clone()
    .startOf('month')                     // go to the beginning of the month
    .day(day)

  //if (m.month() !== monthMoment.month()) m.add(7, 'd');
  return m.add(7 * (weekNumber - 1), 'd')
}
