import { Component, OnInit, ViewEncapsulation, Input, Inject, AfterViewInit, HostListener } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Platform } from '@angular/cdk/platform';
import { ActivityLogService, ActivityLog, ActivityType } from '../../../service/activity-log.service';
import { TrainingService } from '../../_services/training.service';
import { environment } from 'environments/environment';
import { CourseCompletedDialogComponent } from '../course-completed-dialog/course-completed-dialog.component';
import { AnyAaaaRecord } from 'dns';
import { NextCourseDialogComponent } from '../next-course-dialog/next-course-dialog.component';
declare var flowplayer: any;
declare var jQuery: any;
import * as Stomp from 'stompjs';
import * as SockJS from 'sockjs-client';
import { MatTableDataSource } from '@angular/material/table';
import { ClassroomCourseResultComponent } from '../classroom-course-result/classroom-course-result.component';
import { ActionBarService } from '../../../../layout/components/action-bar/action-bar.service';
import { IndependentTrainingInviteDialogComponent } from '../independent-training-invite-dialog/independent-training-invite-dialog.component';
import { Content } from '../../../content/content-list.model';
import { WifiInfoDialogComponent } from '../wifi-info-dialog/wifi-info-dialog.component';
import { TrainerQuizPreviewComponent } from '../../../content/trainer-quiz-preview/trainer-quiz-preview.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AbsProgressBarService } from '@abs/components/progress-bar/progress-bar.service';
import { AbsConfigService } from '@abs/services/config.service';
import { LastCourseCompleteDialogComponent } from '../last-course-complete-dialog/last-course-complete-dialog.component';
import { ActivatedRoute } from '@angular/router';
import { VideoLog } from '../../../content/video-log.model';


@Component({
  selector: 'app-player',
  templateUrl: './player.component.html',
  styleUrls: ['./player.component.scss'],
  encapsulation: ViewEncapsulation.None,

})
export class PlayerComponent implements OnInit, AfterViewInit {
  private serverUrl = `${environment.apiUrl}/socket`;
  private stompClient;

  showPlayer = true;

  classId: any;
  currentProductId: any;
  lastPlayedProductId: any;
  lastPlayedProductIndex: any;
  resumePlaylist = false;
  currentPlayerVideo: any;
  currentVideo: any = 0
  currentPlaylist = [];
  disclaimerDir = "Disclaimer-1003";
  customDisclaimer = false;
  showProducer = false;
  environment: any;
  flPlayer: any;
  matDialogRef: any;
  nextCourseMatDialogRef: any;
  lastCourseMatDialogRef: any;
  wifiMatDialogRef: any;

  resultMatDialogRef: any;
  inviteTrainingDialogRef: any;
  spanish = false;
  mainVideo = false;
  videos: any;
  className: any;
  constructor(private _platform: Platform,
    public inviteTrainingDialog: MatDialog,
    public matDialog: MatDialog,
    public wifiDialog: MatDialog,
    public nextCourseMatDialog: MatDialog,
    public courseResultMatDialog: MatDialog,
    public activityLog: ActivityLogService,
    public trainingService: TrainingService,
    private actionBarService: ActionBarService,
    private snackBar: MatSnackBar,
    private dialogQuiz: MatDialog,
    private dialogLastCourse: MatDialog,
    private route: ActivatedRoute,
    private progressBarService: AbsProgressBarService,
    private absConfig: AbsConfigService,
    private allDialogRef: MatDialog,

  ) {
    this.classId = localStorage.getItem("currentClassId");
    this.className = localStorage.getItem("currentClassTitle");
    this.lastPlayedProductId = localStorage.getItem("lastPlayedProductId");
    this.environment = environment;


    this.route.queryParams.subscribe(params => {
      const wifi = params['wifi'];

      if (wifi && wifi == 'y') {
        //this.showPlayer = false;
        this.showWifiInformation();
      }
    });


  }


  ngOnInit() {
    const self = this;

    this.initializeWebSocketConnection();
    this.actionBarService.onWifiClick.subscribe(res => {
      if (res) {
        this.showWifiInformation();
      }
    })
    this.actionBarService.onViewQuizClick.subscribe(res => {
      if (res) {
        this.onQuizPreview();
      }
    })

    this.actionBarService.onSendQuizLinkClick.subscribe(res => {
      if (res) {
        this.sendLinks();
      }
    })

    this.actionBarService.onNextCourseClick.subscribe(res => {
      if (res) {
        this.flPlayer.playlist.next();
      }
    })

    this.actionBarService.onCloseSessionClick.subscribe(res => {
      if (res) {
        self.trainingService.updateTrainingStatus(self.classId, "CLOSED", self.currentProductId);
        window.close();
      }
    })


    self.trainingService.getTrainingCourses(self.classId).then(res => {
      self.videos = res;
        console.log("last id", self.lastPlayedProductId);
      if (self.lastPlayedProductId != null && self.lastPlayedProductId != 0) {
        console.log("inside", self.resumePlaylist);
        self.resumePlaylist = true;
      }


      let v_1 = {

        src: "https://bitdash-a.akamaihd.net/content/MI201109210084_1/m3u8s/f08e80da-bf1d-4e3d-8899-f0f6155f6efa.m3u8", type: "application/x-mpegURL",
        title: "Warning",
        pid: this.videos[0].id,
        last: false,

      }

      let v_1_2 = {
        src: "https://bitdash-a.akamaihd.net/content/MI201109210084_1/m3u8s/f08e80da-bf1d-4e3d-8899-f0f6155f6efa.m3u8", type: "application/x-mpegURL",
        title: "presented by",
        pid: this.videos[0].id,
        content: this.videos[0],
      }

      let v_1_3 = {

        src: "https://bitdash-a.akamaihd.net/content/MI201109210084_1/m3u8s/f08e80da-bf1d-4e3d-8899-f0f6155f6efa.m3u8", type: "application/x-mpegURL",
        title: "Main VIdeo",
        pid: this.videos[0].id,
        mainVideo: true,
        content: this.videos[0],
      }

      let v_1_4 = {
        src: "https://bitdash-a.akamaihd.net/content/MI201109210084_1/m3u8s/f08e80da-bf1d-4e3d-8899-f0f6155f6efa.m3u8", type: "application/x-mpegURL",
        title: "End video",
        pid: this.videos[0].id,
        showPopup: true
      }

      let v_2 = {
        src: "https://bitdash-a.akamaihd.net/content/MI201109210084_1/m3u8s/f08e80da-bf1d-4e3d-8899-f0f6155f6efa.m3u8", type: "application/x-mpegURL",
        title: "Warning",
        pid: this.videos[1].id,
        last: false,
      }

      let v_2_2 = {
        src: "https://bitdash-a.akamaihd.net/content/MI201109210084_1/m3u8s/f08e80da-bf1d-4e3d-8899-f0f6155f6efa.m3u8", type: "application/x-mpegURL",
        title: "presented by",
        pid: this.videos[1].id,
        content: this.videos[1],
      }

      let v_2_3 = {
        src: "https://bitdash-a.akamaihd.net/content/MI201109210084_1/m3u8s/f08e80da-bf1d-4e3d-8899-f0f6155f6efa.m3u8", type: "application/x-mpegURL",
        title: "Main VIdeo",
        pid: this.videos[1].id,
        mainVideo: true,
        content: this.videos[1],
      }

      let v_2_4 = {
        src: "https://bitdash-a.akamaihd.net/content/MI201109210084_1/m3u8s/f08e80da-bf1d-4e3d-8899-f0f6155f6efa.m3u8", type: "application/x-mpegURL",
        title: "End video",
        pid: this.videos[1].id,
        showPopup: true
      }

      var addToPlaylist = false;

      this.videos.forEach(function (video, idx, array) {

        if (!addToPlaylist && self.resumePlaylist) {
          if (video.id == self.lastPlayedProductId) {
            addToPlaylist = true;
          }
        } else {
          addToPlaylist = true;
        }
        var disclaimerObject = {
          src: self.environment.cdnUrl + "/preroll/" + self.disclaimerDir + "/master.m3u8", type: "application/x-mpegURL",
          title: video.title,
          pid: video.id,
        }


        var presentedBy = {
          autoplay: false,
          src: self.environment.cdnUrl + "/preroll/PresentedBy-1003/master.m3u8", type: "application/x-mpegURL",
          title: video.title,
          pid: video.id,
        }

        var endVideo = {

          src: self.environment.cdnUrl + "/postroll/Out-1003/master.m3u8", type: "application/x-mpegURL",
          title: video.title,
          pid: video.id,
          showPopup: true

        };
        var mainVideoObject = {

          src: self.environment.cdnUrl + "/video/V_" + video.itemCode + "/master.m3u8", type: "application/x-mpegURL",
          title: video.title,
          pid: video.id,
          mainVideo: true,
          content: video,

        };

        if (addToPlaylist) {
          self.currentPlaylist.push(disclaimerObject);
          self.currentPlaylist.push(presentedBy);
          self.currentPlaylist.push(mainVideoObject);
          self.currentPlaylist.push(endVideo);
        }
      });
       // self.currentPlaylist.push(v_1);
       // self.currentPlaylist.push(v_1_2);
       // self.currentPlaylist.push(v_1_3);
       // self.currentPlaylist.push(v_1_4);


       // self.currentPlaylist.push(v_2);
       // self.currentPlaylist.push(v_2_2);
       // self.currentPlaylist.push(v_2_3);
       // self.currentPlaylist.push(v_2_4);

      //self.currentPlaylist.push(v33);
      console.log("playlist", self.currentPlaylist);
      if (this.showPlayer) {
        this.initVideo();
      }
    });
  }

  initVideo() {
    const self = this;
    var cuepoints = [{ start: 10, end: 15 }];
    var lastSecond = null;
    var secondsToCallFunction = 1;
    var viewDeducted = false;
    var time = 0;
    this.flPlayer = flowplayer.playlist('.flowplayer', {
      auto_orient: true,
      ratio: "16:9",
      cuepoints: cuepoints,
      playlist: self.currentPlaylist,
      advance: false, // Whether to auto-advance playlist,
      //  delay: 0, // 15 seconds between clips 
      qsel: {},
      plugins: ['hls', 'qsel'],
      token: "eyJraWQiOiJGaGVJc1JQM0c5NUMiLCJ0eXAiOiJKV1QiLCJhbGciOiJFUzI1NiJ9.eyJjIjoie1wiYWNsXCI6NixcImlkXCI6XCJGaGVJc1JQM0c5NUNcIn0iLCJpc3MiOiJGbG93cGxheWVyIn0.JsuBrKMzkCyXqmwl9YevhbzdYnLdmbZNrGGO2tRHbozNKOU3HA8K3TtqN2oJXPs6EEILPAfVJv5JH0yeolO9lQ",
    }).on("loadstart", function (e) {
      viewDeducted = false;
      var index = self.flPlayer.playlist.queue.idx;
      self.actionBarService.onStartCourseClick.next('clicked');
      self.currentProductId = self.flPlayer.playlist.queue.members[index].pid;
      self.trainingService.updateTrainingStatus(self.classId, "STARTED", self.currentProductId);
      localStorage.setItem("currentCourseId", self.currentProductId);

      if (self.flPlayer.playlist.queue.members[index].mainVideo) {
        var videoLog = new VideoLog({
          profileId: JSON.parse(localStorage.getItem("currentUser")).profileId,
          productId: self.currentProductId,
          itemName: self.flPlayer.playlist.queue.members[index].content.title,
          classId: self.classId,
          comments: 'en'
        });
        self.insertClassroomVideoLogs(videoLog);
      }
    }).on("timeupdate", function (e) {

      var index = self.flPlayer.playlist.queue.idx;
      if (self.flPlayer.playlist.queue.members[index].mainVideo) {
        var seconds = Math.floor(e.target.currentTime);
        var time25Perc = Math.floor(e.target.duration) * 0.25;
        // console.log("time25Perc",time25Perc);
        if (seconds % secondsToCallFunction == 0 && lastSecond !== seconds) {
          lastSecond = seconds
          if (Math.floor(e.target.currentTime) != Math.floor(e.target.duration)) {
            time++;
          }
          var videoLog = new VideoLog({
            productId: self.currentProductId,
            classId: self.classId,
            totalViewed: time,
            videoDuration: e.target.duration,
            comments: 'en'
          });
          self.updateClassroomVideoLogs(videoLog);

          if (seconds >= time25Perc && !viewDeducted) {
            viewDeducted = true;
            var request = {
              profileId: JSON.parse(localStorage.getItem("currentUser")).profileId,
              providerId: JSON.parse(localStorage.getItem("currentUser")).providerId,
              productId: self.currentProductId,
              classId: self.classId,
              companyId: JSON.parse(localStorage.getItem("currentUser")).companyId,
            };
            self.trainingService.deductClassroomView(request).then(res => {
              viewDeducted = true;
            });
          }
        }
      }
    })
      .on("progress", function (e) {


      }).on("ended", function (e) {
        time = 0;
        console.log("ended", e);
        var index = self.flPlayer.playlist.queue.idx;
        var isLast = self.flPlayer.playlist.queue.last_idx == self.flPlayer.playlist.queue.idx;
        var pid = 0;
        var title = "";
        self.actionBarService.onClassCourseChanged.next(isLast);
        if (self.flPlayer.playlist.queue.members[index].showPopup) {
          title = self.flPlayer.playlist.queue.members[index].title;
          pid = self.flPlayer.playlist.queue.members[index].pid;

          self.flPlayer.toggleFullScreen(false);
          self.sendNextCourseMessage(self.classId, pid, isLast);
          self.matDialogRef = self.matDialog.open(CourseCompletedDialogComponent, {
            backdropClass: 'previewBackdrop',
            width: '600px',
            disableClose: true,
            data: {
              pid: pid,
              title: title,
              classId: self.classId,
              last: isLast
            }
          });

          self.matDialogRef.afterClosed().subscribe(result => {
            if (result) {

              self.sendCourseCloseMessage(self.classId, pid, isLast);
              self.inviteTrainingDialogRef = self.inviteTrainingDialog.open(IndependentTrainingInviteDialogComponent, {
                backdropClass: 'previewBackdrop',
                width: '400px',
                disableClose: true,
                data: {
                  classId: self.classId,
                  pid: pid,
                }
              });

              self.inviteTrainingDialogRef.afterClosed().subscribe(result => {
                if (result) {
                  self.sendInviteTrainingCloseMessage(self.classId, pid, isLast);

                  if (isLast) {
                    console.log("inside");
                    self.lastCourseMatDialogRef = self.dialogLastCourse.open(LastCourseCompleteDialogComponent, {
                      backdropClass: 'previewBackdrop',
                      width: '1300px',
                      disableClose: true,
                      data: {
                        classId: self.classId,
                        classTitle: self.className
                      }
                    });
                    self.lastCourseMatDialogRef.afterClosed().subscribe(result => {
                      console.log("next close s");
                      if (result) {
                        console.log("next close");
                        self.sendNextCourseCloseMessage(self.classId, pid, isLast);
                      }
                    });

                  } else {

                    self.nextCourseMatDialogRef = self.nextCourseMatDialog.open(NextCourseDialogComponent, {
                      backdropClass: 'previewBackdrop',
                      width: '350px',
                      disableClose: true,
                      data: {
                        title: title,
                        classId: self.classId,
                        hasNextCourse: !isLast,
                        productId: pid
                      }
                    });
                    self.nextCourseMatDialogRef.afterClosed().subscribe(result => {
                      console.log("next close s");
                      if (result) {
                        console.log("next close");
                        self.flPlayer.playlist.next();
                        self.sendNextCourseCloseMessage(self.classId, pid, isLast);
                      }
                    });
                  }

                }
              });
            }
          });
        } else {
          self.flPlayer.playlist.next();
        }
      }).on(flowplayer.playlist.events.PLAYLIST_READY, function (e) {
        console.log("playlist ready", e);
      }).on(flowplayer.playlist.events.PLAYLIST_NEXT, function (e) {
        console.log("next", e);
      }).on(flowplayer.playlist.events.PLAYLIST_ENDED, function (e) {
        console.log("playlist ended", e);
        self.flPlayer.toggleFullScreen(false)
      }).on(flowplayer.events.CUEPOINT_START, function (e) {
        console.log("cue", e);
        if (self.flPlayer.playlist.queue.idx == 2) {
          // self.playerService.onCuePoint.next(e.data.cuepoint);
        }
      }).on(flowplayer.events.CUEPOINT_END, function (e) {
        // console.log('Cuepoint ended 1', e);
      }).on(flowplayer.events.CUEPOINTS, function (e) {
        console.log("cue attached", e);
      })
  }

  initializeWebSocketConnection() {
    let ws = new SockJS(this.serverUrl);
    this.stompClient = Stomp.over(ws);
    let that = this;
    this.stompClient.connect({}, function (frame) {
      that.stompClient.subscribe("/training/class/" + that.classId + "/course/next", (message) => {
        console.log("next course clicked", message.body);
        if (message.body) {
          var result = JSON.parse(message.body);
          that.flPlayer.playlist.next();
        }
      });
      that.stompClient.subscribe("/training/class/" + that.classId + "/course/next/close", (message) => {
        if (message.body) {
          var result = JSON.parse(message.body);
          that.nextCourseMatDialogRef.close(result)
        }
      });
      that.stompClient.subscribe("/training/class/" + that.classId + "/course/completed/close", (message) => {
        if (message.body) {
          var result = JSON.parse(message.body);
          that.matDialogRef.close(result)
        }
      });
      that.stompClient.subscribe("/training/class/" + that.classId + "/start", (message) => {
        if (message.body) {
          var result = JSON.parse(message.body);
          that.allDialogRef.closeAll();
        }
      });
      that.stompClient.subscribe("/training/class/" + that.classId + "/wifi", (message) => {
        if (message.body) {
          var result = JSON.parse(message.body);
          that.showWifiInformation();
        }
      });
      that.stompClient.subscribe("/training/class/" + that.classId + "/wifi/close", (message) => {
        if (message.body) {
          var result = JSON.parse(message.body);
          that.wifiMatDialogRef.close();
        }
      });
      that.stompClient.subscribe("/training/class/" + that.classId + "/course/invite/close", (message) => {
        if (message.body) {
          var result = JSON.parse(message.body);
          that.inviteTrainingDialogRef.close(result);
        }
      });

    });
  }


  sendNextCourseMessage(classId, id, last) {
    this.stompClient.send('/app/training/course/completed/open', {}, JSON.stringify({ classId: classId, courseId: id, last: last }));
  }

  sendLastCourseMessage(classId, id, last) {
    this.stompClient.send('/app/training/course/last/open', {}, JSON.stringify({ classId: classId, courseId: id, last: last }));
  }

  sendLastCourseCloseMessage(classId, id, last) {
    this.stompClient.send('/app/training/course/last/close', {}, JSON.stringify({ classId: classId, courseId: id, last: last }));
  }
  ngAfterViewInit() {
  }
  getNextVideoToPlay(self) {
    var currentPlayList = [];
    currentPlayList.push(self.currentPlaylist[self.currentVideo]);
    self.currentVideo++;
    console.log(currentPlayList);
    return currentPlayList;
  }

  sendCourseCloseMessage(classId, id, lastCourse) {
    this.stompClient.send('/app//training/course/completed/close', {}, JSON.stringify({ classId: classId, courseId: id, last: lastCourse }));
  }

  sendNextCourseCloseMessage(classId, id, lastCourse) {
    this.stompClient.send('/app//training/course/next/close', {}, JSON.stringify({ classId: classId, courseId: id, last: lastCourse }));
  }

  sendInviteTrainingCloseMessage(classId, id, lastCourse) {
    this.stompClient.send('/app//training/course/invite/close', {}, JSON.stringify({ classId: classId, courseId: id, last: lastCourse }));
  }
  showWifiInformation() {
    this.wifiMatDialogRef = this.wifiDialog.open(WifiInfoDialogComponent, {
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%',
      panelClass: 'full-screen-modal',
      //backdropClass: 'previewBackdrop',
      disableClose: true,
      // width: '500px',
      data: {
        classId: this.classId
      }
    });
  }

  onQuizPreview() {
    this.dialogQuiz.open(TrainerQuizPreviewComponent, {
      id: 'quiz-preview',
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%',
      panelClass: 'full-screen-modal',
      data: {
        content: new Content({ id: this.currentProductId })
      }
    });
  }

  showClassroomResults() {
    console.log("hello");
    this.courseResultMatDialog.open(ClassroomCourseResultComponent, {
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%',
      panelClass: 'full-screen-modal',
      data: {
        title: "Results",
        classId: this.classId,
        productId: this.currentProductId
      }
    });
  }

  sendLinks() {
    this.progressBarService.show();
    this.snackBar.open("Generating Quiz Links...", '', {
      panelClass: '',
      horizontalPosition: 'center',
      verticalPosition: 'top',
      politeness: 'polite',
    });
    this.trainingService.sendQuizLinks(this.classId, this.currentProductId).then(res => {
      this.snackBar.dismiss();
      this.progressBarService.hide();
      this.snackBar.open("Quiz Links has been sent.", '', {
        panelClass: 'green-bg',
        duration: 2000,
        horizontalPosition: 'right',
        verticalPosition: 'top',
        politeness: 'polite',
      });
      this.showClassroomResults();
    });
  }

  insertClassroomVideoLogs(videoLog) {
    this.trainingService.insertClassroomVideoLog(videoLog);
  }
  updateClassroomVideoLogs(videoLog) {
    this.trainingService.updateClassroomVideoLog(videoLog);
  }
}