import { Component, OnInit, Inject, ViewEncapsulation, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { SwiperConfigInterface, SwiperScrollbarInterface, SwiperPaginationInterface } from 'ngx-swiper-wrapper';
import { MatStepper } from '@angular/material/stepper';
import { StepperSelectionEvent, MAT_STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { CartItem, ShoppingCartService } from '../cart.service';
import { Content } from '../../content/content-list.model';
import { MatSnackBar } from '@angular/material/snack-bar';


@Component({
  selector: 'app-wizard-dialog',
  templateUrl: './wizard-dialog.component.html',
  styleUrls: ['./wizard-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [{
    provide: MAT_STEPPER_GLOBAL_OPTIONS, useValue: { displayDefaultIndicatorType: false }
  }]
})
export class WizardDialogComponent implements OnInit {

  playlist: Content[] = [
    
  ];

  public config: SwiperConfigInterface = {
    init: true,
    a11y: true,
    direction: 'horizontal',
    keyboard: true,
    mousewheel: false,
    slidesPerView: 5,
    scrollbar: false,
    navigation: true,
    pagination: false,
    spaceBetween: 12
  };

  public configPlaylist: SwiperConfigInterface = {
    init: true,
    a11y: true,
    direction: 'horizontal',
    slidesPerView: 5,
    keyboard: true,
    mousewheel: false,
    scrollbar: false,
    navigation: true,
    pagination: true,
    spaceBetween: 12

  };

  showClassroomViewEmptyMessage = false;
  wizard = true;
  customization = false;
  images: any;
  group: string;
  pickPlayList: FormGroup;
  secondFormGroup: FormGroup;
  scheduleFormGroup: FormGroup;
  quizFormGroup: FormGroup;
  isOptional = false;
  @ViewChild('stepper') stepper: MatStepper;
  cartState$ = this.shoppingCartService.state$;


  constructor(
    public matDialogRef: MatDialogRef<WizardDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    public _matDialog: MatDialog, 
    private _formBuilder: FormBuilder, 
    private shoppingCartService: ShoppingCartService, 
    public _matSnackBar: MatSnackBar) {
  }

  ngOnInit() {
    this.group = "classroom";
    this.pickPlayList = this._formBuilder.group({
      playList: ['', Validators.required]
    });
    this.secondFormGroup = this._formBuilder.group({
      secondCtrl: ''
    });
    this.quizFormGroup = this._formBuilder.group({

    });
    this.scheduleFormGroup = this._formBuilder.group({
      courseTitle: ['', Validators.required],
      prerequisite: ['', Validators.required],
      trainer: ['', Validators.required],
      location: ['', Validators.required],
      date: ['', Validators.required],
      time: ['', Validators.required],
      timeZone: ['', Validators.required],
      sendInvites: ['', Validators.required],
      tickets: ['', Validators.required],
      notes: ['', Validators.required]
    });
  }

  updateSteps(index) {
    if (index == 1) {
      this.group = 'learner';
    } else {
      this.group = 'classroom';

    }
  }
  public selectionChange($event?: StepperSelectionEvent): void {

    if ($event.selectedIndex == 3) {
      // this.matDialogRef.updateSize("700px", "auto");
    } else {
      this.matDialogRef.updateSize("1600px", "auto");
    }
  }


  addItemToCart(item: CartItem) {
    this.shoppingCartService.addCartItem(item);
  }

  remove(item: CartItem): void {
    this.shoppingCartService.removeCartItem(item);
  }
  wizardDone(): void {
    this.wizard = false;
    this.matDialogRef.updateSize("500px", "auto");
    this.customization = true;
  }

  validateClassroomViews() {
   // if(this.cartState$.cart.)
  }
}
