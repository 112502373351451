export const locale = {
    lang: 'sp',
    data: {
        'NAV': {
            'PROFILE': 'Profile',
            'PASSWORD'  : 'Password',
            'SETTINGS'    : 'Settings',
            'DEVICES'   : 'Devices',
            'USERS' : 'Users',
            'TRAINING': 'Training',
            'REPORTING':'Reporting',
            'ACCOUNT':'Account',
            'CONTENT':'Content',
            'TRAINING_ASSIGNED_TRAINING': 'Assigned Training',
            'TRAINING_ASSIGN': 'Assign Training',
            'TRAINING_CLASSROOM': 'Classroom',
            'TRAINING_LOCATION_MANAGEMENT': 'Location Management',
            'TRAINING_CALENDAR': 'Training Calandar',
            'CONTENT_COURSES':'Courses',
            'CONTENT_MY_CONTENT':'My Content',

            'CONTENT_PLAYLIST_MANAGER':'Playlist Manager',
            'USERS_USER_MANAGEMENT':'User Management',
            'USERS_GROUP_MANAGEMENT':'Group Management',
            'REPORTS_REPORTS':'Reports',

        }
    }
};
