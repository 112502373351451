import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { absAnimations } from '../../../../../@abs/animations';

@Component({
  selector: 'app-share-course',
  templateUrl: './share-course.component.html',
  styleUrls: ['./share-course.component.scss'],
  animations: absAnimations

})
export class ShareCourseComponent implements OnInit {

  flip:any;
  @Output() onBackButton = new EventEmitter<any>();
  @Output() onSelection = new EventEmitter<any>();
  @Output() onDone = new EventEmitter<any>();
  constructor() { }

  ngOnInit(): void {
  }
  descClicked(abc){
    
  }
  selectTrainingMethod(abc){

  }
  handleBack(){
    this.onBackButton.next("publish")
  }

  shareCourse(){
    this.onDone.next("publish")
  }
  skipSharing(){
    this.onDone.next("skip")

  }
}