import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { Location, LocationTech } from '../location.model';
import { LocationAddressComponent } from '../location-address/location-address.component';
import { HoursOfAvailabilityComponent } from '../hours-of-availability/hours-of-availability.component';
import { LocationsService } from '../locations.service';
import { MatListOption } from '@angular/material/list';

@Component({
  selector: 'app-location-form',
  templateUrl: './location-form.component.html',
  styleUrls: ['./location-form.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LocationFormComponent {

  action: string;
  location: Location;
  locationForm: FormGroup;
  dialogTitle: string;
  dialogRef: any;

  videoDevice = [];
  videoNOU = [];
  videoConnectionType = [];

  audioDevice = [];
  audioConnectionType = [];

  speakerDevice = [];
  selectedTechs = [];


  selectedVideoDevice = [];
  selectedVideoNOU = [];
  selectedVideoConnectionType = [];

  selectedAudioDevice = [];
  selectedAudioConnectionType = [];

  selectedSpeakerDevice = [];




  selected = [];

  workingHoursdialogRef: any;

  step = 0;
  /**
   * Constructor
   *
   * @param {MatDialogRef<DeviceFormComponent>} matDialogRef
   * @param _data
   * @param {FormBuilder} _formBuilder
   */
  constructor(
    public matDialogRef: MatDialogRef<LocationFormComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private _formBuilder: FormBuilder, public _matDialog: MatDialog, public _workingHourMatDialog: MatDialog,
    private locationService: LocationsService
  ) {

    // Set the defaults
    this.action = _data.action;

    if (this.action === 'edit') {
      this.dialogTitle = 'Edit Location';
      this.location = _data.location;
    }
    else {
      this.dialogTitle = 'New Location';
      this.location = new Location({});
    }

    this.locationForm = this.createLocationForm();


    this.locationService.getLocationTechs().then(res => {
      if (res) {
        this.videoDevice = res.filter(t => t.category == 'Video' && t.type == 'Device');
        this.videoNOU = res.filter(t => t.category == 'Video' && t.type == 'Number of Units');
        this.videoConnectionType = res.filter(t => t.category == 'Video' && t.type == 'Connection Type');

        this.audioDevice = res.filter(t => t.category == 'Audio' && t.type == 'Device');
        this.audioConnectionType = res.filter(t => t.category == 'Audio' && t.type == 'Connection Type');

        this.speakerDevice = res.filter(t => t.category == 'Trainer Accessories' && t.type == 'Device');

        if (this.action === 'edit') {
          this.selectedVideoDevice = this.filterTechByType('Device', 'Video', this.location.techList);
          this.selectedVideoNOU = this.filterTechByType('Number of Units', 'Video', this.location.techList);
          this.selectedVideoConnectionType = this.filterTechByType('Connection Type', 'Video', this.location.techList);
          this.selectedAudioConnectionType = this.filterTechByType('Connection Type', 'Audio', this.location.techList);
          this.selectedAudioDevice = this.filterTechByType('Device', 'Audio', this.location.techList);
          this.selectedSpeakerDevice = this.filterTechByType('Device', 'Trainer Accessories', this.location.techList);

          this.locationForm.patchValue({
            selectedVideoDevice: this.selectedVideoDevice,
            selectedVideoNOU: this.selectedVideoNOU,
            selectedVideoConnectionType: this.selectedVideoConnectionType,
            selectedAudioConnectionType: this.selectedAudioConnectionType,
            selectedAudioDevice: this.selectedAudioDevice,
            selectedTrainerDevice: this.selectedSpeakerDevice,
          });
        }
      }
    })


  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Create device form
   *
   * @returns {FormGroup}
   */
  createLocationForm(): FormGroup {

    let address = "";

    if (this.location.address1) {
      address = this.location.address1 + ", " + this.location.city + ", " + this.location.state + ", " + this.location.zip;
    }


    return this._formBuilder.group({
      id: [this.location.id],
      name: [this.location.name],
      address: [address],
      hoursOfAvailability: [this.location.hours],
      seatingCapacity: [this.location.maxAttend],
      heatingAirConditioning: [this.location.heatAir],
      wifiName: [this.location.wifiName],
      wifiPassword: [this.location.wifiPass],
      wifiQuality: [this.location.wifiQuality],
      laptopFriendly: [this.location.laptopFriendly == true ? 'yes' : 'no'],
      selectedVideoDevice: [this.selectedVideoDevice],
      selectedVideoConnectionType: [this.selectedVideoConnectionType],
      selectedVideoNOU: [this.selectedVideoNOU],
      selectedAudioDevice: [this.selectedAudioDevice],
      selectedAudioConnectionType: [this.selectedAudioConnectionType],
      selectedTrainerDevice: [this.selectedSpeakerDevice],
      notes: [this.location.notes],
      label: []
    });
  }

  ngOnInit() {

  }

  addLocation() {
    this.populateLocation();
    //  this.location.techList = this.selectedTechs.map(v => new LocationTech({ id: v }));

    console.log(this.location);
    this.matDialogRef.close(['save', this.location]);
  }
  editLocation() {
    this.populateLocation();
    //  this.location.techList = this.selectedTechs.map(v => new LocationTech({ id: v }));

    console.log(this.location);
    this.matDialogRef.close(['edit', this.location]);
  }

  populateLocation() {
    this.locationForm.controls['label'].setValue(this.locationForm.controls['name'].value);
    this.location.name = this.locationForm.controls['name'].value;
    this.location.label = this.locationForm.controls['name'].value;
    this.location.heatAir = this.locationForm.controls['heatingAirConditioning'].value;
    this.location.laptopFriendly = this.locationForm.controls['laptopFriendly'].value == 'yes' ? true : false;
    this.location.maxAttend = this.locationForm.controls['seatingCapacity'].value;
    this.location.notes = this.locationForm.controls['notes'].value;;
    this.location.wifiName = this.locationForm.controls['wifiName'].value;;
    this.location.wifiPass = this.locationForm.controls['wifiPassword'].value;
    this.location.wifiQuality = this.locationForm.controls['wifiQuality'].value;

    const selectedVideoDevice = this.locationForm.controls['selectedVideoDevice'].value || [];

    const selectedVideoNOU = this.locationForm.controls['selectedVideoNOU'].value || [];

    const selectedVideoConnectionType = this.locationForm.controls['selectedVideoConnectionType'].value || [];

    const selectedAudioDevice = this.locationForm.controls['selectedAudioDevice'].value || [];

    const selectedAudioConnectionType = this.locationForm.controls['selectedAudioConnectionType'].value || [];

    const selectedTrainerDevice = this.locationForm.controls['selectedTrainerDevice'].value || [];

    this.selectedTechs = [...selectedVideoConnectionType, ...selectedAudioDevice, ...selectedVideoDevice, ...selectedVideoNOU, ...selectedAudioConnectionType, ...selectedTrainerDevice];

    this.location.techList = [];
    for (let item of this.selectedTechs.values()) {
      this.location.techList.push(new LocationTech({ id: item }));
    }

  }

  onTechChange(options: MatListOption[]) {
    //options.map(o => this.selectedTechs.add(o.value))
    console.log(this.locationForm.controls['selectedTechs'].value);
  }

  onLocationNameChanged(e: any) { }

  openAddressDialog(): void {
    this.dialogRef = this._matDialog.open(LocationAddressComponent, {
      panelClass: 'address-form-dialog',
      width: '700px',
      data: {
        location: this.location
      }
    });
    this.dialogRef.afterClosed()
      .subscribe(response => {
        console.log("address in form", response);
        if (response && response.address1) {
          this.location.address1 = response.address1;
          this.location.address2 = response.address2;
          this.location.addressLat = response.addressLat;
          this.location.addressLong = response.addressLong;
          this.location.trainingLocationOnly = response.trainingLocationOnly;

          if (response.displayAddress) {
            this.location.displayAddress = response.displayAddress;
          }

          if (response.url) {
            this.location.url = response.url;
          }

          this.location.city = response.city;
          this.location.state = response.state;
          this.location.zip = response.zip;
          let address = this.location.address1 + ", " + this.location.city + ", " + this.location.state + ", " + this.location.zip;
          this.locationForm.controls['address'].setValue(address);
        }

      });
  }

  openWorkingHourDialog(): void {
    this.workingHoursdialogRef = this._workingHourMatDialog.open(HoursOfAvailabilityComponent, {
      panelClass: 'scrumboard-card-dialog',
      width: '800px',
      data: {
      }
    });
    this.workingHoursdialogRef.afterClosed()
      .subscribe(response => {

      });
  }
  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  filterTechByType(type, category, source) {
    return source.filter(t => t.category == category && t.type == type).map(tech => tech.id);
  }
}
