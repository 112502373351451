import {AbsConfig} from '@abs/types';

/**
 * Default Settings
 */
export const absConfig: AbsConfig = {
  colorTheme      : 'theme-default',
  customScrollbars: true,
  layout          : {
    style    : 'horizontal-layout-1',
    width    : 'fullwidth',
    navbar   : {
      primaryBackground  : 'abs-navy-900',
      secondaryBackground: 'abs-navy-700',
      folded             : false,
      hidden             : false,
      position           : 'left',
      variant            : 'vertical-style-1'
    },
    toolbar  : {
      background           : 'abs-white-500',
      customBackgroundColor: true,
      hidden               : false,
      position             : 'above'
    },
    footer   : {
      customBackgroundColor: true,
      background           : 'abs-navy-900',
      hidden               : false,
      position             : 'below-fixed'
    },
    sidepanel: {
      hidden  : true,
      position: 'right'
    }
  }
};
