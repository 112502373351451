import { Component, OnInit, AfterViewInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { Playlist } from '../../../../content/playlists/playlists.model';
import { Content } from '../../../../content/content-list.model';
import { FormControl } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { Subject } from 'rxjs';
import { AbsProgressBarService } from '@abs/components/progress-bar/progress-bar.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ContentService } from '../../../../content/content.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-assign-selected-content',
  templateUrl: './assign-selected-content.component.html',
  styleUrls: ['./assign-selected-content.component.scss'],
  encapsulation: ViewEncapsulation.None,

})
export class AssignSelectedContentComponent implements OnInit, AfterViewInit {
  @ViewChild(MatSort) sort: MatSort;
  isLoading = true;
  playlist: Playlist;
  height: number
  userFound = false;
  contents: Content[] = [];
  environment: any;
  selected = 'All Users';
  loginStatus = 'Last Login';
  searchInputContent: FormControl;
  //  displayedColumns: string[] = ['select', 'avatar', 'firstName', 'lastName', 'email', 'lastLogin'];
  displayedColumns: string[] = ['avatar', 'title', 'runningTime', 'rating', 'buttons'];

  dataSourceContent: MatTableDataSource<Content>;
  selection = new SelectionModel<Content>(true, []);
  private _unsubscribeAll: Subject<any>;

  constructor(private _progressBarService: AbsProgressBarService,
    public _snackBar: MatSnackBar, private contentService: ContentService) {
    this.searchInputContent = new FormControl('');
    this.environment = environment;
    //this._unsubscribeAll = new Subject();
  }

  ngOnInit() {

    this.contentService.onSelectedContentChanged
      .subscribe(contents => {
        if (contents) {
          this.contents = [];
          console.log("assign contents selected", contents)
          contents.map(row => {
            let ind = this.checkIfAlreadySelected(row);
            if (!ind) {
              this.contents.push(row);
            }
          });
          this.updateDataSource(this.contents);
        }
      });

    this.contentService.onContentChanged
      .subscribe(users => {
        this.updateDataSource(users);
      });

    this.searchInputContent.valueChanges
      .subscribe(searchText => {
        console.log("search", searchText);
        this.contentService.onSearchTextChanged.next(searchText);
      });
  }

  ngAfterViewInit() {
    // this.dataSourceUsers.sort = this.sort;
    console.log(this.sort);
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    // this._unsubscribeAll.next();
    //this._unsubscribeAll.complete();
  }
  updateDataSource(users) {
    this.dataSourceContent = new MatTableDataSource(users);
    this.dataSourceContent.sort = this.sort;
    this.isLoading = false;
  }

 

  removeUserFromGroup(user) {
    this.contentService.contentSelection.toggle(user);
    this.contentService.onSelectedContentChanged.next(this.contentService.contentSelection.selected);
  }

  checkIfAlreadySelected(newContent) {
    return this.contents.find(content => content.id === newContent.id);
  }
}