import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { AbsUtils } from '@abs/utils';

import {Location, LocationTech} from './location.model';
import { environment } from 'environments/environment';


const httpOptions = {
  headers: new HttpHeaders({
      'Content-Type': 'application/json'
  })
};


@Injectable({
  providedIn: 'root'
})
export class LocationsService implements Resolve<any> {

  onLocationsChanged: BehaviorSubject<any>;
  onSelectedLocationsChanged: BehaviorSubject<any>;
  onUserDataChanged: BehaviorSubject<any>;
  onSearchTextChanged: Subject<any>;
  onFilterChanged: Subject<any>;

  locations: Location[];
  locationTechs: LocationTech[];


  user: any;
  selectedLocations: string[] = [];

  searchText: string;
  filterBy: string;

  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(private _httpClient: HttpClient) {
    // Set the defaults
    this.onLocationsChanged = new BehaviorSubject([]);
    this.onSelectedLocationsChanged = new BehaviorSubject([]);
    this.onUserDataChanged = new BehaviorSubject([]);
    this.onSearchTextChanged = new Subject();
    this.onFilterChanged = new Subject();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Resolver
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Observable<any> | Promise<any> | any}
   */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
  {
    return new Promise((resolve, reject) => {

      Promise.all([
        this.getLocations(),
      ]).then(
        ([files]) => {

          this.onSearchTextChanged.subscribe(searchText => {
            this.searchText = searchText;
            this.getLocations();
          });

          this.onFilterChanged.subscribe(filter => {
            this.filterBy = filter;
            this.getLocations();
          });

          resolve();

        },
        reject
      );
    });
  }


/**
   * Get location tech
   * @returns {Promise<any>}
   */
  getLocationTechs(): Promise<any>
  {
    return new Promise((resolve, reject) => {
        this._httpClient.get(`${environment.apiUrl}/location/tech/visible`,httpOptions)
          .subscribe((response: any) => {
            this.locationTechs = response;
            this.locationTechs = this.locationTechs.map(tech => {
              return new LocationTech(tech);
            });
            resolve(this.locationTechs);
          }, reject);
      }
    );
  }

  /**
   * Get locations
   *
   * @returns {Promise<any>}
   */
  getLocations(): Promise<any>
  {
    return new Promise((resolve, reject) => {
        this._httpClient.get(`${environment.apiUrl}/location/company/` + JSON.parse(localStorage.getItem("currentUser")).companyId,httpOptions)
          .subscribe((response: any) => {
            this.locations = response;
            if ( this.filterBy === 'starred' )
            {
              this.locations = this.locations.filter(_device => {
                return this.user.starred.includes(_device.id);
              });

            }

            if ( this.filterBy === 'frequent' )
            {
              this.locations = this.locations.filter(_location => {
                return this.user.frequentDevices.includes(_location.id);
              });
            }

            if ( this.searchText && this.searchText !== '' )
            {
              this.locations = AbsUtils.filterArrayByString(this.locations, this.searchText);
            }

            this.locations = this.locations.map(location => {
              return new Location(location);
            });

            this.onLocationsChanged.next(this.locations);
            resolve(this.locations);
          }, reject);
      }
    );
  }

  /**
   * Get user data
   *
   * @returns {Promise<any>}
   */
  getUserData(): Promise<any>
  {
    return new Promise((resolve, reject) => {
        this._httpClient.get('api/locations-user/5725a6802d10e277a0f35724')
          .subscribe((response: any) => {
            this.user = response;
            this.onUserDataChanged.next(this.user);
            resolve(this.user);
          }, reject);
      }
    );
  }

  /**
   * Toggle selected device by id
   *
   * @param id
   */
  toggleSelectedLocation(id): void
  {
    // First, check if we already have that device as selected...
    if ( this.selectedLocations.length > 0 )
    {
      const index = this.selectedLocations.indexOf(id);

      if ( index !== -1 )
      {
        this.selectedLocations.splice(index, 1);

        // Trigger the next event
        this.onSelectedLocationsChanged.next(this.selectedLocations);

        // Return
        return;
      }
    }

    // If we don't have it, push as selected
    this.selectedLocations.push(id);

    // Trigger the next event
    this.onSelectedLocationsChanged.next(this.selectedLocations);
  }

  /**
   * Toggle select all
   */
  toggleSelectAll(): void
  {
    if ( this.selectedLocations.length > 0 )
    {
      this.deselectLocations();
    }
    else
    {
      this.selectLocations();
    }
  }

  /**
   * Select devices
   *
   * @param filterParameter
   * @param filterValue
   */
  selectLocations(filterParameter?, filterValue?): void
  {
    this.selectedLocations = [];

    // If there is no filter, select all devices
    if ( filterParameter === undefined || filterValue === undefined )
    {
      this.selectedLocations = [];
      this.locations.map(location => {
        this.selectedLocations.push(location.id);
      });
    }

    // Trigger the next event
    this.onSelectedLocationsChanged.next(this.selectedLocations);
  }

  /**
   * Update device
   *
   * @param location
   * @returns {Promise<any>}
   */
  updateLocation(location): Promise<any>
  {
    return new Promise((resolve, reject) => {

      location.profileID = JSON.parse(localStorage.getItem("currentUser")).profileId;
      location.companyID = JSON.parse(localStorage.getItem("currentUser")).companyId;
      console.log("location",location);
      let obj = JSON.stringify(location);
      this._httpClient.put(`${environment.apiUrl}/location`,location , httpOptions)
        .subscribe((response: any) => {
          this.getLocations();
          resolve(response);
        }, reject);
    });
  }

  addLocation(location) {
    return new Promise((resolve, reject) => {
      location.profileID = JSON.parse(localStorage.getItem("currentUser")).profileId;
      location.companyID = JSON.parse(localStorage.getItem("currentUser")).companyId;

      let obj = JSON.stringify(location);
      console.log("obj",obj);
      this._httpClient.post(`${environment.apiUrl}/location`,location , httpOptions)
        .subscribe((response: any) => {
          this.getLocations();
          resolve(response);
        }, reject);
    }
    );
  }
  /**
   * Update user data
   *
   * @param userData
   * @returns {Promise<any>}
   */
  updateUserData(userData): Promise<any>
  {
    return new Promise((resolve, reject) => {
      this._httpClient.post('api/locations-user/' + this.user.id, {...userData})
        .subscribe(response => {
          this.getUserData();
          this.getLocations();
          resolve(response);
        });
    });
  }

  /**
   * Deselect devices
   */
  deselectLocations(): void
  {
    this.selectedLocations = [];

    // Trigger the next event
    this.onSelectedLocationsChanged.next(this.selectedLocations);
  }

  /**
   * Delete location
   *
   * @param location
   */
  deleteLocation(location): void
  {
    const deviceIndex = this.locations.indexOf(location);
    this.locations.splice(deviceIndex, 1);
    this.onLocationsChanged.next(this.locations);
  }

  /**
   * Delete selected devices
   */
  deleteSelectedDevices(): void
  {
    for ( const id of this.selectedLocations )
    {
      const location = this.locations.find(_location => {
        return _location.id === id;
      });
      const locationIndex = this.locations.indexOf(location);
      this.locations.splice(locationIndex, 1);
    }
    this.onLocationsChanged.next(this.locations);
    this.deselectLocations();
  }
}
