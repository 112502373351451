import { Component, OnInit, Output, EventEmitter, ViewEncapsulation, Input } from '@angular/core';
import { absAnimations } from '@abs/animations';
import { TrainingStyle } from '../../_models/training';
import { TrainingOptions } from '../training-option-selector/training-option-selector.component';
import { CompnayService } from '../../../authentication/_services';
import { AuthenticationService } from 'app/main/authentication/_services/authentication.service';
import { AbsUtils } from '@abs/utils';

@Component({
  selector: 'app-save-and-activate',
  templateUrl: './save-and-activate.component.html',
  styleUrls: ['./save-and-activate.component.scss'],
  animations: absAnimations,
  encapsulation: ViewEncapsulation.None,
})
export class SaveAndActivateComponent implements OnInit {
  flip:any;
  title: any;
  view: any = "save"
  isDefaultOption = true;

  @Input()
  optionStage

  @Input()
  currentView="save";

  @Input()
  isTrainingScheduled = false;

  trainingOptions: TrainingOptions  = new TrainingOptions();
  companyDefaults: any;
  userSettings: any;

  @Input()
  selectedGroups: any;

  @Input()
  selectedPlaylist: any;

  @Input()
  selectedTrigger = 'N';

  @Input()
  trainingType: TrainingStyle

  @Input()
  timeToComplete

  @Output() onBackButton = new EventEmitter<any>();

  @Output() onDone = new EventEmitter<any>();

  @Output() dataChanged = new EventEmitter<any>();

  constructor(private _companyService: CompnayService,private _authService: AuthenticationService) { }

  ngOnInit(): void {
    
    this.view = this.currentView;
    if (this.selectedTrigger == 'NEW_HIRE' && this.selectedPlaylist && this.selectedPlaylist.length > 0) {
      this.title = "New Hire:" + this.selectedPlaylist[0].name
    } else {
      if (this.selectedGroups && this.selectedPlaylist && this.selectedPlaylist.length > 0) {
        this.title = this.selectedGroups[0].name + " - " + this.selectedPlaylist[0].name;
      }
    }

    this._companyService.getCompanySettingByCompanyId(this._authService.currentUserValue.companyId).subscribe(res => {
      this.companyDefaults = res;
      if (this._authService.currentUserValue.settings) {
        this.userSettings = this._authService.currentUserValue.settings;
        let passingScore = this.getValue(this.userSettings, 'DEFAULT_PASSING_SCORE');
        if (passingScore && passingScore.length > 0) {
          this.trainingOptions.passingScore = passingScore[0].value;
        } else {
          let passingScore = this.getValue(this.companyDefaults, 'DEFAULT_PASSING_SCORE');
          if (passingScore && passingScore.length > 0) {
            this.trainingOptions.passingScore = passingScore[0].value;
          } else {
            var defaults = JSON.parse(localStorage.getItem("system_defaults"));
            console.log("defaults", defaults);
            if (defaults) {
              let DEFAULT_PASSING_SCORE = AbsUtils.filterArrayByString(defaults, "DEFAULT_PASSING_SCORE");
              if (DEFAULT_PASSING_SCORE && DEFAULT_PASSING_SCORE.length > 0) {
                this.trainingOptions.passingScore = DEFAULT_PASSING_SCORE[0].value;
              } else {
                this.trainingOptions.passingScore = 80;
              }
            }
          }
        }

        let graceDays = this.getValue(this.userSettings, 'DEFAULT_GRACE_DAYS');
        if (graceDays && graceDays.length > 0) {
          this.trainingOptions.graceDays = graceDays[0].value;
        } else {
          let graceDays = this.getValue(this.companyDefaults, 'DEFAULT_GRACE_DAYS');
          if (graceDays && graceDays.length > 0) {
            this.trainingOptions.graceDays = graceDays[0].value;
          } else {
            var defaults = JSON.parse(localStorage.getItem("system_defaults"));
            if (defaults) {
              let DEFAULT_GRACE_DAYS = AbsUtils.filterArrayByString(defaults, "DEFAULT_GRACE_DAYS");
              if (DEFAULT_GRACE_DAYS && DEFAULT_GRACE_DAYS.length > 0) {
                this.trainingOptions.graceDays = DEFAULT_GRACE_DAYS[0].value;
              } else {
                this.trainingOptions.graceDays = 1;
              }
            }
          }
        }


        let timeToComplete = this.getValue(this.userSettings, 'DEFAULT_TIME_TO_COMPLETE');
        if (timeToComplete && timeToComplete.length > 0) {
          this.trainingOptions.timeToComplete = timeToComplete[0].value;
        } else {
          let timeToComplete = this.getValue(this.companyDefaults, 'DEFAULT_TIME_TO_COMPLETE');
          if (timeToComplete && timeToComplete.length > 0) {
            this.trainingOptions.timeToComplete = timeToComplete[0].value;
          } else {
            var defaults = JSON.parse(localStorage.getItem("system_defaults"));
            if (defaults) {
              let timeToComplete = AbsUtils.filterArrayByString(defaults, "DEFAULT_TIME_TO_COMPLETE");
              if (timeToComplete && timeToComplete.length > 0) {
                this.trainingOptions.timeToComplete = timeToComplete[0].value;
              } else {
                this.trainingOptions.timeToComplete = 7;
              }
            }
          }
        }

        let sendInvites = this.getValue(this.userSettings, 'DEFAULT_SEND_INVITES_DAYS');
        if (sendInvites && sendInvites.length > 0) {
          this.trainingOptions.sendInvites = sendInvites[0].value;
        } else {
          let sendInvites = this.getValue(this.companyDefaults, 'DEFAULT_SEND_INVITES_DAYS');
          console.log(sendInvites)
          if (sendInvites && sendInvites.length > 0) {
            this.trainingOptions.sendInvites = sendInvites[0].value;
          }
        }


        let sendReminder = this.getValue(this.userSettings, 'DEFAULT_SEND_REMINDER_DAYS');
        if (sendReminder && sendReminder.length > 0) {
          this.trainingOptions.sendReminders = sendReminder[0].value;
        } else {
          let sendReminder = this.getValue(this.companyDefaults, 'DEFAULT_SEND_REMINDER_DAYS');
          if (sendReminder && sendReminder.length > 0) {
            this.trainingOptions.sendReminders = sendReminder[0].value;
          }
        }

      } else {
        let passingScore = this.getValue(this.companyDefaults, 'DEFAULT_PASSING_SCORE');
        if (passingScore && passingScore.length > 0) {
          this.trainingOptions.passingScore = passingScore[0].value;
        } else {
          var defaults = JSON.parse(localStorage.getItem("system_defaults"));
          console.log("defaults", defaults);
          if (defaults) {
            let DEFAULT_PASSING_SCORE = AbsUtils.filterArrayByString(defaults, "DEFAULT_PASSING_SCORE");
            if (DEFAULT_PASSING_SCORE && DEFAULT_PASSING_SCORE.length > 0) {
              this.trainingOptions.passingScore = DEFAULT_PASSING_SCORE[0].value;
            } else {
              this.trainingOptions.passingScore = 80;
            }
          }
        }

        let graceDays = this.getValue(this.companyDefaults, 'DEFAULT_GRACE_DAYS');
        console.log(graceDays)
        if (graceDays && graceDays.length > 0) {
          this.trainingOptions.graceDays = graceDays[0].value;
        } else {
          var defaults = JSON.parse(localStorage.getItem("system_defaults"));
          console.log("defaults", defaults);
          if (defaults) {
            let DEFAULT_GRACE_DAYS = AbsUtils.filterArrayByString(defaults, "DEFAULT_GRACE_DAYS");
            if (DEFAULT_GRACE_DAYS && DEFAULT_GRACE_DAYS.length > 0) {
              this.trainingOptions.graceDays = DEFAULT_GRACE_DAYS[0].value;
            } else {
              this.trainingOptions.graceDays = 1;
            }
          }
        }

        let timeToComplete = this.getValue(this.companyDefaults, 'DEFAULT_TIME_TO_COMPLETE');
        if (timeToComplete && timeToComplete.length > 0) {
          this.trainingOptions.timeToComplete = timeToComplete[0].value;
        } else {
          var defaults = JSON.parse(localStorage.getItem("system_defaults"));
          if (defaults) {
            let timeToComplete = AbsUtils.filterArrayByString(defaults, "DEFAULT_TIME_TO_COMPLETE");
            if (timeToComplete && timeToComplete.length > 0) {
              this.trainingOptions.timeToComplete = timeToComplete[0].value;
            } else {
              this.trainingOptions.timeToComplete = 7;
            }
          }
        }
        let sendInvites = this.getValue(this.companyDefaults, 'DEFAULT_SEND_INVITES_DAYS');
        console.log(sendInvites)
        if (sendInvites && sendInvites.length > 0) {
          this.trainingOptions.sendInvites = sendInvites[0].value;
        }else{
          var defaults = JSON.parse(localStorage.getItem("system_defaults"));
          if (defaults) {
            let sendInvites = AbsUtils.filterArrayByString(defaults, "DEFAULT_SEND_INVITES_DAYS");
            if (sendInvites && sendInvites.length > 0) {
              this.trainingOptions.sendInvites = sendInvites[0].value;
            } else {
              this.trainingOptions.sendInvites = 7;
            }
          }
        }


        let sendReminder = this.getValue(this.companyDefaults, 'DEFAULT_SEND_REMINDER_DAYS');
        if (sendReminder && sendReminder.length > 0) {
          this.trainingOptions.sendReminders = sendReminder[0].value;
        }else{
          var defaults = JSON.parse(localStorage.getItem("system_defaults"));
          if (defaults) {
            let sendReminder = AbsUtils.filterArrayByString(defaults, "DEFAULT_SEND_REMINDER_DAYS");
            if (sendReminder && sendReminder.length > 0) {
              this.trainingOptions.sendReminders = sendReminder[0].value;
            } else {
              this.trainingOptions.sendReminders = 2;
            }
          }
        }

      }
      if(this.timeToComplete && this.timeToComplete > 0){
        this.trainingOptions.timeToComplete = this.timeToComplete;
      }

    })

  }
  scheduleTraining()
  {
    const title = this.title;
    this.title = "";
    this.selectedTrigger = null;
    this.onDone.emit({title: title})
  }
  handleBack() {
    this.onBackButton.emit('saveAndActivate');
  }


  

  showOptions(){
    this.view = "options";
    this.isDefaultOption = false;

  }
  getValue(source, key) {
    return source.filter(s => s.name == key);
  }
  onOptionChange(event){
    this.dataChanged.emit(event)
  }
  descClicked(value) {
    this.flip = value;
  }
}
