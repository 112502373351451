import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { absAnimations } from '@abs/animations';
import { AbsProgressBarService } from '@abs/components/progress-bar/progress-bar.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthenticationService } from '../../_services';
import { first, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { UsersService } from '../../../user/user.service';
import { UserService } from '../../../service/user.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
  animations: absAnimations,
  encapsulation: ViewEncapsulation.None,


})
export class ResetPasswordComponent implements OnInit {

  profileData:any;
  resetForm: FormGroup;
  resetOption = 'email';

  resetDisabled = true;
  passwordForm: FormGroup;
  hidePassword = true;
  ssoResetDialog = false;

  @Input()
  profileId: any;

  @Input()
  passwordScreen: boolean;

  @Input()
  invite: boolean;

  @Input()
  username: any;

  @Input()
  companyId: any;

  @Input()
  sso: boolean;

  constructor(private _snackBar: MatSnackBar,
    private _formBuilder: FormBuilder,
    private _progressBarService: AbsProgressBarService,
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private router: Router,

  ) {

    console.log("passwordScreen", this.passwordScreen);
  }


  get f() {
    return this.resetForm.controls;
  }

  ngOnInit() {

    this.passwordForm = this._formBuilder.group({
      password: ['', Validators.required],
      passwordConfirm: ['', [Validators.required, confirmPasswordValidator]]
    });
    this.resetForm = this._formBuilder.group({
      email: [this.username, [Validators.required, Validators.email]],
      phone: [{ disabled: true, value: '' }, Validators.required],
    });

    if (this.username != null && this.username.length > 0) {
      this.findProfile();
    }

    this.resetForm.controls['email'].valueChanges
      .pipe(
        debounceTime(1000),
        distinctUntilChanged()
      )
      .subscribe(searchText => {
        this.findProfile();
      })

    this.resetForm.controls['phone'].valueChanges
      .pipe(
        debounceTime(1000),
        distinctUntilChanged()
      )
      .subscribe(searchText => {
        this.findProfile();
      })
  }

  resetOptionChanged(event) {
    this.resetOption = event.value;
    if (this.resetOption === 'email') {
      this.resetForm.controls.email.enable();
      this.resetForm.controls.phone.disable();
    } else {
      this.resetForm.controls.email.disable();
      this.resetForm.controls.phone.enable();
    }
  }

  findProfile() {
    this.resetDisabled = true;
    var value = this.resetOption === 'email' ? this.f.email.value : this.f.phone.value;
    console.log("findProfile", value);
    if(value.length > 0){
    this._progressBarService.show();

      this.findProfileApi(value);

    
    }
  }


  findProfileApi(value){
    this.authenticationService.findByEmailOrPhone(value)
      .pipe(first())
      .subscribe(
        data => {
          this._progressBarService.hide();
          if (data && data.length == 0) {
            this._snackBar.open("No user exist against provided information", 'Ok', {
              panelClass: 'red-bg',
              duration: 2000,
              horizontalPosition: 'right',
              verticalPosition: 'top',
              politeness: 'polite',
            });
          } else if(data[0].signUpType == 'SELF'){
            this.resetDisabled = false;
            this.profileId = data[0].profileId;
          }else if(data[0].signUpType == 'SSO'){
            this.profileData = data[0];
            this.ssoResetDialog = true;
          }
        },
        error => {
          this._snackBar.open("No user exist against provided information", 'Ok', {
            panelClass: 'red-bg',
            duration: 2000,
            horizontalPosition: 'right',
            verticalPosition: 'top',
            politeness: 'polite',
          });
        });
  }


  ssoFindProfile(value){
    this.authenticationService.findByEmailOrPhoneIncludeSSO(value)
      .pipe(first())
      .subscribe(
        data => {
          this._progressBarService.hide();
          if (data && data.length == 0) {
            this._snackBar.open("No user exist against provided information", 'Ok', {
              panelClass: 'red-bg',
              duration: 2000,
              horizontalPosition: 'right',
              verticalPosition: 'top',
              politeness: 'polite',
            });
          } else {
            this.resetDisabled = false;
            this.profileId = data[0];
          }
        },
        error => {
          this._snackBar.open("No user exist against provided information", 'Ok', {
            panelClass: 'red-bg',
            duration: 2000,
            horizontalPosition: 'right',
            verticalPosition: 'top',
            politeness: 'polite',
          });
        });
  }

  changePassword() {
    this._progressBarService.show();
    console.log("profile", this.profileId);
    this.userService.updatePassword(this.passwordForm.controls.passwordConfirm.value, this.profileId).subscribe(response => {
      this._progressBarService.hide();
      if (this.invite) {
        this._snackBar.open("Your password has been created.", '', {
          panelClass: 'green-bg',
          duration: 2000,
          horizontalPosition: 'right',
          verticalPosition: 'top',
          politeness: 'polite',
        });
      } else {
        this._snackBar.open("Your password has been reset.", '', {
          panelClass: 'green-bg',
          duration: 2000,
          horizontalPosition: 'right',
          verticalPosition: 'top',
          politeness: 'polite',
        });
      }

      this.authenticationService.ssoLogin(this.profileId)
        .pipe(first())
        .subscribe(
          data => {
            this.router.navigate(['training/my-trainings']);
          },
          error => {

          });
    });;
  }

  onReset() {
    this.resetDisabled = true;
    this._progressBarService.show();
    var value = this.resetOption === 'email' ? this.f.email.value : this.f.phone.value;
    this.authenticationService.resetPassword(value, this.resetOption, this.profileId)
      .pipe(first())
      .subscribe(
        data => {
          this._progressBarService.hide();
          if (data == 0) {
            this.resetDisabled = false;
            this._snackBar.open("Error while reseting password", 'Ok', {
              panelClass: 'red-bg',
              duration: 4000,
              horizontalPosition: 'right',
              verticalPosition: 'top',
              politeness: 'polite',
            });
          } else {
            this._snackBar.open("A password reset link was sent to " + value, 'Ok', {
              panelClass: 'green-bg',
              duration: 4000,
              horizontalPosition: 'right',
              verticalPosition: 'top',
              politeness: 'polite',
            });
          }
        },
        error => {

          this._snackBar.open("Error while reseting password", 'Ok', {
            panelClass: 'red-bg',
            duration: 2000,
            horizontalPosition: 'right',
            verticalPosition: 'top',
            politeness: 'polite',
          });
        });
  }

  cancel() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['/authentication/login']);
}


}
/**
 * Confirm password validator
 *
 * @param {AbstractControl} control
 * @returns {ValidationErrors | null}
 */
export const confirmPasswordValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {

  if (!control.parent || !control) {
    return null;
  }

  const password = control.parent.get('password');
  const passwordConfirm = control.parent.get('passwordConfirm');

  if (!password || !passwordConfirm) {
    return null;
  }

  if (passwordConfirm.value === '') {
    return null;
  }

  if (password.value === passwordConfirm.value) {
    return null;
  }

  return { passwordsNotMatching: true };
};