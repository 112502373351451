import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-experience-dialog',
  templateUrl: './experience-dialog.component.html',
  styleUrls: ['./experience-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ExperienceDialogComponent implements OnInit {

  constructor(public matDialogRef: MatDialogRef<ExperienceDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    public _matDialog: MatDialog) { }

  ngOnInit() {
  }

}
