import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {ReviewList} from './review.model';

@Component({
  selector: 'app-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ReviewComponent implements OnInit {

  show: false;
  public buttonName: any = 'View Reply';

  reviews: ReviewList[] = [
    new ReviewList('Mourushi Mitran', 'a month ago', 'assets/images/avatars/Abbott.jpg', 'Good video to start.'),
    new ReviewList('Deepak Pimple', 'a month ago', 'assets/images/avatars/Arnold.jpg', 'Very well explained!!!'),
    new ReviewList('Andreas Hendrickx', 'a month ago', 'assets/images/avatars/Barrera.jpg', 'It was good.'),
    new ReviewList('Janeth Ohst', '4 months ago', 'assets/images/avatars/Blair.jpg', 'Very clear and to the point'),
    new ReviewList('Amaresh Narayan Tripathi', '5 months ago', 'assets/images/avatars/Boyle.jpg', 'Shiv Sir\'s command over subject is excellent!!!'),
    new ReviewList('Mourushi Mitran', 'a month ago', 'assets/images/avatars/Christy.jpg', 'Good video to start.'),
    new ReviewList('AjayJ jagdale', 'a month ago', 'assets/images/avatars/Copeland.jpg', 'When I need to understand the concepts and basics I choose courses.'),
    new ReviewList('Ramana Potluri', 'a month ago', 'assets/images/avatars/Harper.jpg', 'One of the best taught course on ITRAINSTATION'),
  ];

  constructor() { }

  ngOnInit() {
  }

  // Reply
  toggle(review) {
    review.show = !review.show;

    // CHANGE THE NAME OF THE BUTTON.
    if (review.show) {
      this.buttonName = 'Hide Reply';
    }
    else {
      this.buttonName = 'View Reply';
    }
  }
}
