import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { absAnimations } from '../../../../../@abs/animations';
import { FileManagerService } from '../../my-uploads/my-uploads.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ContentService } from '../../content.service';
import { AuthenticationService } from '../../../authentication/_services';
import { AbsProgressBarService } from '@abs/components/progress-bar/progress-bar.service';
import { DomSanitizer } from '@angular/platform-browser';
import { FileUploader } from 'ng2-file-upload';
import { FileData } from '../content-wizard.component';
import { FormGroup, FormBuilder } from '@angular/forms';
import { File } from '../../my-uploads/file.model';
import { environment } from 'environments/environment';
import { Content } from '../../content-list.model';

@Component({
  selector: 'app-content-publisher',
  templateUrl: './content-publisher.component.html',
  styleUrls: ['./content-publisher.component.scss'],
  animations: absAnimations

})
export class ContentPublisherComponent implements OnInit {

  flip:any;

  @Input()
  title: any;

  @Input()
  video:any;
  
  @Input()
  uploader: FileUploader;

  uploadedProduct:any;
  @Input()
  fileData: FileData;

  file: File;
  pageType: string;
  fileForm: FormGroup;
  enviorment
  latestFile: any;

  @Output() onBackButton = new EventEmitter<any>();
  @Output() onSelection = new EventEmitter<any>();
  @Output() onDone = new EventEmitter<any>();
  constructor(private _myUploadService: FileManagerService,
    private _formBuilder: FormBuilder,
    private _matSnackBar: MatSnackBar,
    private contentService: ContentService,
    private _authService: AuthenticationService,
    private _progressBar: AbsProgressBarService, private sanitizer: DomSanitizer) {
    this.file = new File();
    this.enviorment = environment;
  }

  ngOnInit(): void {
    console.log("fileData",this.fileData)
  }
  descClicked(abc){
    
  }
  selectTrainingMethod(abc){

  }
  handleBack(){
    this.onBackButton.next("publish")
  }

  publishToCatalog(){
    this.onDone.next("publish")
  }

  publishLater(){
    this.saveDocument();
  }

  draftDocument(){
    
  }
  onDoneClicked() {
    this.saveDocument();
  }

  saveDocument() {

    if(this.fileData && this.fileData.contentId && this.fileData.contentId.length > 0 ){
      this.onDone.next({ value: this.fileData.contentId, type: 'draft' });
    }else{
    this.latestFile = this.uploader.queue[0];
    this._matSnackBar.open("Saving...", '', {
      horizontalPosition: 'right',
      verticalPosition: 'top',
      politeness: 'polite',
    });
    var categoriesId = this.fileData.categories.map(x => {
      return x.id;
    });
    this._progressBar.show();
    var content = new Content({})
      content.title = this.fileData.title,
      content.description = this.fileData.description,
      content.companyID = this._authService.currentUserValue.companyId,
      content.providerID = this._authService.currentUserValue.providerId,
      content.profileID = this._authService.currentUserValue.profileId,
      content.sku = "",
      content.fileName = this.latestFile.file.name,
      content.categoryList = categoriesId,
      content.english = true,
      content.itemCode = "TEST",
      content.prodTypeID = this.latestFile.file.type.indexOf("video") != -1 ? 15 : 7,
      content.thumbnail = this.fileData.thumbUrl;
      content.visible = false,
      content.ownerType = 'provider',
      content.departmentID = this._authService.currentUserValue.department,
      content.tags = this.fileData.tags
      this.contentService.insertContent(content).subscribe(res => {
        console.log("result", res);
        this._progressBar.hide();
        if (res) {
          content.id = res;
          this._matSnackBar.dismiss();
          this.uploadedProduct = content;
          console.log("result uploadedProduct", this.uploadedProduct);
          this.onDone.next({ value: this.uploadedProduct, type: 'draft' });
        }
      })
  }
  }
}
