import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-reporting-dialog',
  templateUrl: './reporting-dialog.component.html',
  styleUrls: ['./reporting-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ReportingDialogComponent implements OnInit {

  reportForm: FormGroup;

  constructor(public matDialogRef: MatDialogRef<ReportingDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    public _matDialog: MatDialog,private _formBuilder: FormBuilder) { 

      this.reportForm = this.createReportForm();
    }

  ngOnInit() {
  }

  createReportForm(): FormGroup
  {
    return this._formBuilder.group({
      report    : [''],
      automateReport: [''],
      frequency: [],
      email: [''],
      subject: [''],
      date: [''],
    });
  }


}

