import { AbsUtils } from '@abs/utils';

export class Device {
  id: string;
  name: string;
  hashcode: string;
  lastIP: string;
  location: string;
  userAgent: string;
  lastSeen: string;
  addDate: string;
  deleted: boolean;
  isp: string;
  new: boolean;

  /**
   * Constructor
   *
   * @param device
   */
  constructor(device) {
    {
      this.id = device.id || AbsUtils.generateGUID();
      this.name = device.name || '';
      this.hashcode = device.hashcode || '';
      this.lastIP = device.lastIP || '';
      this.isp = device.isp || '';
      this.location = device.location || '';
      this.lastSeen = device.lastSeen || '';
      this.userAgent = device.userAgent || '';
      this.new = device.new || false;
    }
  }
}
