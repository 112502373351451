export class ReviewList {
  public name: string;
  public duration: string;
  public avatar: string;
  public comment: string;

  constructor(name: string, duration: string, avatar: string, comment: string ) {
    this.name = name;
    this.duration = duration;
    this.avatar = avatar;
    this.comment = comment;
  }

}


