import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Device } from '../../../user/devices/devices.model';
import { User } from '../../../models/user';
import { DevicesService } from '../../../account/devices/devices.service';
import { AbsProgressBarService } from '@abs/components/progress-bar/progress-bar.service';
import { absAnimations } from '@abs/animations';

@Component({
  selector: 'app-new-device-dialog',
  templateUrl: './new-device-dialog.component.html',
  styleUrls: ['./new-device-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: absAnimations
})
export class NewDeviceDialogComponent implements OnInit {

  addNew = false;
  device: Device;
  deviceForm : FormGroup;
  currentUser: User;
  constructor(public matDialogRef: MatDialogRef<NewDeviceDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private _formBuilder: FormBuilder,private _devicesService: DevicesService,    private _progressBarService: AbsProgressBarService,
  ) { 
      this.device = _data.device;
      this.currentUser = _data.user;
    }

  ngOnInit() {
    this.deviceForm = this.createDeviceForm();
  }

  saveDevice(){
    this._progressBarService.show();
    this.device.name = this.deviceForm.controls.name.value;
    this._devicesService.updateDevice(this.device).then(
      () => {console.log("Task Complete!")
      this._progressBarService.hide();
      },
      () => console.log("Task Errored!"),
    );;
  }

  createDeviceForm(): FormGroup
  {
    return this._formBuilder.group({
      name    : ['', Validators.required],
    });
  }

}
