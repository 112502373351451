import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { CompnayService, AuthenticationService } from '../../../../authentication/_services';
import { SelectionModel } from '@angular/cdk/collections';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-trainer-selector',
  templateUrl: './trainer-selector.component.html',
  styleUrls: ['./trainer-selector.component.scss']
})
export class TrainerSelectorComponent implements OnInit {

  flip: any;
  trainers: any;
  selection = new SelectionModel<any>(false, []);
  search = new FormControl();

  @Input()
  default: any;

  @Output() onBackButton = new EventEmitter<any>();
  @Output() onDone = new EventEmitter<any>();
  @Output() onSelection = new EventEmitter<any>();


  constructor(private _companyService: CompnayService, private _authService: AuthenticationService) { }

  ngOnInit(): void {
    this.getTrainers();
  }

  getTrainers() {
    this._companyService.getCompanyTrainers(this._authService.currentUserValue.companyId).subscribe(res => {
      this.trainers = res;
      if(this.default){
        this.selection.select(this.default)
      }else{
        this.selection.select(this._authService.currentUserValue.profileId)
      }
    })
  }

  onChange(user) {
    this.selection.toggle(user.profileId);
    if (this.selection.isSelected(user.profileId)) {
      this.onSelection.emit({ value: this.selection.selected[0], type: 'trainer' });
    }

  }

  handleBack() {
    this.onBackButton.emit('trainer');
  }

  selectionDone() {
    this.onDone.emit({ value: this.selection.selected[0], type: 'trainer' });
  }
  descClicked(value) {
    this.flip = value;
  }
}
