import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-purchase-view-dialog',
  templateUrl: './purchase-view-dialog.component.html',
  styleUrls: ['./purchase-view-dialog.component.scss']
})
export class PurchaseViewDialogComponent implements OnInit {

  flip:any;
  constructor(public matDialogRef: MatDialogRef<PurchaseViewDialogComponent>) { }

  ngOnInit(): void {
  }
  
  submitDialog(value)
  {
    this.matDialogRef.close(value)
  }
  descClicked(abc){
    
  }

}
