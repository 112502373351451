import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { Title } from '@angular/platform-browser';
import { absAnimations } from '../../../../../@abs/animations';
import { QuizAnDSharingData } from '../content-wizard.component';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-content-congratulation',
  templateUrl: './content-congratulation.component.html',
  styleUrls: ['./content-congratulation.component.scss'],
  animations: absAnimations

})
export class ContentCongratulationComponent implements OnInit {

  flip: any;
  enviorment: any;
  @Input()
  video: any;

  @Input()
  quizAndSharingData: QuizAnDSharingData;

  @Input()
  title

  @Output() onDone = new EventEmitter<any>();

  quizCreated = false;

  shared = false;
  locationCount = 0;
  groupCount =0;
  departmentCount =0;
  managerCount =0

  @Input()
  description
  constructor(    private route: ActivatedRoute,
    private router: Router,) {
    this.enviorment = environment;
  }

  ngOnInit(): void {

    console.log("init quizAndSharingData",this.quizAndSharingData)

    if (this.quizAndSharingData.sharedDepartments && this.quizAndSharingData.sharedDepartments.length > 0) {
      this.shared = true;
      this.departmentCount = this.quizAndSharingData.sharedDepartments.length;
    }
    if (this.quizAndSharingData.sharedGroups && this.quizAndSharingData.sharedGroups.length > 0) {
      this.shared = true;
      this.groupCount = this.quizAndSharingData.sharedGroups.length;

    }
    if (this.quizAndSharingData.sharedLocations && this.quizAndSharingData.sharedLocations.length > 0) {
      this.shared = true;
      this.locationCount = this.quizAndSharingData.sharedLocations.length;

    }
    if (this.quizAndSharingData.sharedManagers && this.quizAndSharingData.sharedManagers.length > 0) {
      this.shared = true;
      this.managerCount = this.quizAndSharingData.sharedManagers.length;
    }

    if (this.quizAndSharingData.totalQuestion && this.quizAndSharingData.totalQuestion > 0) {
      this.quizCreated = true;
    }
  }
  descClicked(Ad) { }
  onContinue() { 
   this.onDone.next("congratz")

  }
  handleBack() { }

  onImgError(event) {
    event.target.src = this.enviorment.cdnUrl + '/' + this.enviorment.thumbDir + '/T_.jpg';
  }
}
