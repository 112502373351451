import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { TrainingStyle } from '../../_models/training';

export class TrainingOptions {
  trainerId: number;
  sendInvites: number;
  passingScore: number;
  timeToComplete: number;
  timeToCompleteUnit: string;

  graceDays: number;
  sendReminders: number
  sendReminders2: number
  sendReminders3: number
  sendRemindersTotal: number
  sendReminderUnit: number

  videoPlayerControl: boolean
}

@Component({
  selector: 'app-training-option-selector',
  templateUrl: './training-option-selector.component.html',
  styleUrls: ['./training-option-selector.component.scss']
})
export class TrainingOptionSelectorComponent implements OnInit {

  flip:any;

  @Input()
  optionStage: ''

  @Input()
  trainingOption: TrainingOptions = new TrainingOptions();

  stage: any;

  @Input()
  trainingType: TrainingStyle
  readonly TrainingStyle = TrainingStyle;
  @Output() onBackButton = new EventEmitter<any>();
  @Output() onDone = new EventEmitter<any>();

  @Output() dataChanged = new EventEmitter<any>();


  constructor() { }

  ngOnInit(): void {
    if (this.trainingType == TrainingStyle.INDIVIDUAL) {
      this.stage = "time-to-complete";
    } else {
      this.stage = "trainer";
    }
    console.log("optionStage",this.optionStage);

    if(this.optionStage !=''){
      this.stage = this.optionStage;
    }
  }

  handleDone(event) {
    var value = event.type;
    if (this.trainingType == TrainingStyle.INDIVIDUAL) {
      if (value == 'time-to-complete') {
        this.trainingOption.timeToComplete = event.value.value;
        this.trainingOption.timeToCompleteUnit = event.value.unit;

        this.stage = 'grace-day'
      }
      if (value == 'grace-days') {
        this.trainingOption.graceDays = event.value;
        this.stage = 'send-reminders';
      }

      if (value == 'send-reminders') {
        this.trainingOption.sendReminders = event.value;
        this.trainingOption.sendRemindersTotal = event.valueTotal;
        this.trainingOption.sendReminders2 = event.value2;
        this.trainingOption.sendReminders3 = event.value3
        this.stage = 'video-player';
      }
      if (value == 'video-player') {
        this.trainingOption.videoPlayerControl = event.value;
        this.stage = 'passing-score';
      }
      if (value == 'passing-score') {
        this.trainingOption.passingScore = event.value;
        this.onDone.emit(this.trainingOption);
      }
    } else {
      if (value == 'trainer') {
        this.trainingOption.trainerId = event.value;
        this.stage = 'send-invites'
      }
      if (value == 'send-invites') {
        this.trainingOption.sendInvites = event.value;
        this.stage = 'passing-score';
      }
      if (value == 'passing-score') {
        this.trainingOption.passingScore = event.value;
        this.onDone.emit(this.trainingOption);
      }
    }
  }

  handleValueChange(event) {
    console.log("handle value changed",event);
    var type = event.type;
    if (this.trainingType == TrainingStyle.INDIVIDUAL) {
      if (type == 'time-to-complete') {
        this.trainingOption.timeToComplete = event.value.value;
        this.trainingOption.timeToCompleteUnit = event.value.unit;
      }
      if (type == 'grace-days') {
        this.trainingOption.graceDays = event.value;
      }
      if (type == 'send-reminders') {
        this.trainingOption.sendReminders = event.value;
        this.trainingOption.sendRemindersTotal = event.valueTotal;
        this.trainingOption.sendReminders2 = event.value2;
        this.trainingOption.sendReminders3 = event.value3
      }
      if (type == 'video-player') {
        this.trainingOption.videoPlayerControl = event.value;
      }
      if (type == 'passing-score') {
        this.trainingOption.passingScore = event.value;
      }
    } else {
      if (type == 'trainer') {
        this.trainingOption.trainerId = event.value;
      }
      if (type == 'send-invites') {
        this.trainingOption.sendInvites = event.value;
       // this.trainingOption.sendReminderUnit = 
      }
      if (type == 'passing-score') {
        this.trainingOption.passingScore = event.value;
      }
    }
    this.dataChanged.emit({value:this.trainingOption,type:'OPTIONS_CHANGED'})
  }

  handleBack(value) {
    console.log("value handle back "+value,this.trainingType == TrainingStyle.INDIVIDUAL)
    if (this.trainingType == TrainingStyle.INDIVIDUAL) {
      if (value == 'time-to-complete') {
        this.onBackButton.emit("back");
      }
      if (value == 'grace-days') {
        this.stage = 'time-to-complete';
      }
      if (value == 'send-reminders') {
        this.stage = 'grace-day'
      }
      if (value == 'video-player') {
        this.stage = 'send-reminders';
      }
      if (value == 'passing-score') {
        this.stage = 'video-player';
      }
    } else {
      if (value == 'trainer') {
        this.onBackButton.emit("back");
      }
      if (value == 'send-invites') {
        this.stage = 'trainer';
      }
      if (value == 'passing-score') {
        this.stage = 'send-invites'
      }
    }
  }
  descClicked(value) {
    this.flip = value;
  }
  ngOnChanges(changes: SimpleChanges) {
    console.log("changes options", changes);
    if (changes.options && changes.goTo.currentValue != "") {

      
    }
  }
}
