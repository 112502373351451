import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-video-length-error-dialog',
  templateUrl: './video-length-error-dialog.component.html',
  styleUrls: ['./video-length-error-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class VideoLengthErrorDialogComponent implements OnInit {

  constructor(public matDialogRef: MatDialogRef<VideoLengthErrorDialogComponent>) { }

  ngOnInit(): void {
  }

}
