import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { PlayList } from '../_models/playlist';
import { TrainingService } from '../_services/training.service';
import { DataSource } from '@angular/cdk/table';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { QuizComponent } from '../../content/quiz/quiz.component';
import {MatTableDataSource} from '@angular/material/table';
import { AbsProgressBarService } from '@abs/components/progress-bar/progress-bar.service';
import { AuthenticationService } from '../../authentication/_services';
import { UserLevel } from '../../models/user';
import { DOCUMENT } from '@angular/common';
import { AbsConfig } from '@abs/types';
import { Platform } from '@angular/cdk/platform';
import { AbsConfigService } from '@abs/services/config.service';
import { LocationMapComponent } from '../locations/location-map/location-map.component';

@Component({
  selector: 'app-my-trainings',
  templateUrl: './my-trainings.component.html',
  styleUrls: ['./my-trainings.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({ height: '0px', minHeight: '0', display: 'none' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      transition('expanded <=> void', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ])
  ],
})
export class MyTrainingsComponent implements OnInit {
  averageEstimatedTime = 0;
  showPlayer = false;
  notLearner: boolean = false;
  isLoading = true;
  dataSource: any = new MatTableDataSource([]);
  ;
  dialogRef: any;
  quizContent: any;
  quizClassId : any;
  quizLang :any;
  quizTraining: any;
  showQuiz: boolean = false;
  lang: any;
  elem:any;
  columnsToDisplay = ['buttons', 'playlist', 'location', 'assignedBy', 'percentComplete'];
  //columnsToDisplay = ['buttons', 'playlist', 'date', 'location', 'format', 'status', 'assignedBy', 'percentComplete', 'assignment'];

  expandedElement: any | null;
  // Private
  private _unsubscribeAll: Subject<any>;
  constructor(private trainingService: TrainingService, 
    private progressBarService: AbsProgressBarService,
    public authService: AuthenticationService,
    public dialog: MatDialog,
    private _platform: Platform,
    private _absConfigService: AbsConfigService,   
    private locationDialog: MatDialog, 
    @Inject(DOCUMENT) private document: any,
  ) {
      if (this.authService.currentUserValue && this.authService.currentUserValue.userLevel != UserLevel.LEARNER) {
        this.notLearner = true;
      }
  }

  ngOnInit() {
    //this.dataSource = new TrainingDataSource(this.trainingService);
    this.elem = document.documentElement;

    this.trainingService.getTrainings().then(trainings => {
      console.log("initial",trainings);
      if (trainings) {
        this.dataSource = new MatTableDataSource(trainings);
        this.isLoading = false;
      }
    })

    this.trainingService.onTrainingChanged.subscribe(trainings => {
      var expended = this.expandedElement;
      if (trainings && trainings.length > 0) {
        this.progressBarService.show();
        this.dataSource = new MatTableDataSource(trainings);
        this.expandedElement = expended;
        this.progressBarService.hide();
        this.isLoading = false;
      }
    })

  }

  launchQuiz(e){
    console.log("launch Quiz");
    this.showQuiz = true;
    this.showPlayer = false;
  }

  retakeQuiz(event){
    console.log("retake quiz",event);
    this.quizTraining = event.training;
    this.quizContent = event.video;
    this.quizClassId = event.training.id;
    this.quizLang = event.lang == 'en' ? false : true;
    this.lang = event.lang;
    this.showQuiz = true;
    this.showPlayer = false;
  }

  launchVideo(event){
    console.log("launch video",event);
    this.showQuiz = false;
    this.quizTraining = event.training;
    this.quizContent = event.video;
    this.quizClassId = event.training.id;
    this.quizLang = event.lang == 'en' ? false : true;
    this.lang = event.lang;
    this.showPlayer = true;
   // this.openFullscreen();
  }
  
  quizBackClicked(){
    const absConfig: AbsConfig = {
      colorTheme      : 'theme-default',
      customScrollbars: true,
      layout          : {
        style    : 'horizontal-layout-1',
        width    : 'fullwidth',
        navbar   : {
          primaryBackground  : 'abs-navy-900',
          secondaryBackground: 'abs-navy-700',
          folded             : false,
          hidden             : false,
          position           : 'left',
          variant            : 'vertical-style-1'
        },
        toolbar  : {
          background           : 'abs-white-500',
          customBackgroundColor: true,
          hidden               : false,
          position             : 'above'
        },
        footer   : {
          customBackgroundColor: true,
          background           : 'abs-navy-900',
          hidden               : false,
          position             : 'below-fixed'
        },
        sidepanel: {
          hidden  : true,
          position: 'right'
        }
      }
    };

    this.trainingService.getTrainings().then(res => {
      this.trainingService.onTrainingChanged.next(res);
    })
    this.showQuiz = false;
    this.showPlayer = false;

    if(this._platform.ANDROID || this._platform.ANDROID){
      this._absConfigService.setConfig(absConfig, {emitEvent: true}) ;
    }
  }


  openFullscreen() {
    if (this.elem.requestFullscreen) {
      this.elem.requestFullscreen();
    } else if (this.elem.mozRequestFullScreen) {
      /* Firefox */
      this.elem.mozRequestFullScreen();
    } else if (this.elem.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      this.elem.webkitRequestFullscreen();
    } else if (this.elem.msRequestFullscreen) {
      /* IE/Edge */
      this.elem.msRequestFullscreen();
    }
  }


  openMapDialog(training): void {
    this.locationDialog.open(LocationMapComponent, {
      panelClass: 'scrumboard-card-dialog',
      width: '400px',
      minHeight: '500px',
      data: {
        lat: training.locationLat,
        lng: training.locationLong
      }
    });
  }

}
