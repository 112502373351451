import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {BehaviorSubject, Observable, Subject} from 'rxjs';

import {AbsUtils} from '@abs/utils';
import { environment } from 'environments/environment';
import { User } from '../models/user';


@Injectable({
  providedIn: 'root'
})
export class UsersService implements Resolve<any> {

  onUsersChanged: BehaviorSubject<any>;
  onSelectedUsersChanged: BehaviorSubject<any>;
  onUserDataChanged: BehaviorSubject<any>;
  onSearchTextChanged: Subject<any>;
  onFilterChanged: Subject<any>;

  users: User[];
  user: any;
  selectedUsers: number[] = [];
  searchText: string;
  filterBy: string;

  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(
    private _httpClient: HttpClient
  ) {
    // Set the defaults
    this.onUsersChanged = new BehaviorSubject([]);
    this.onSelectedUsersChanged = new BehaviorSubject([]);
    this.onUserDataChanged = new BehaviorSubject([]);
    this.onSearchTextChanged = new Subject();
    this.onFilterChanged = new Subject();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Resolver
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Observable<any> | Promise<any> | any}
   */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return new Promise((resolve, reject) => {

      Promise.all([
        this.getUsers(),
      ]).then(
        ([files]) => {

          this.onSearchTextChanged.subscribe(searchText => {
            this.searchText = searchText;
            this.getUsers();
          });

          this.onFilterChanged.subscribe(filter => {
            this.filterBy = filter;
            this.getUsers();
          });

          resolve();

        },
        reject
      );
    });
  }

  /**
   * Get groups
   *
   * @returns {Promise<any>}
   */
  getUsers(): Promise<any> {
    return new Promise((resolve, reject) => {
        this._httpClient.get(`${environment.apiUrl}/users/company/`+JSON.parse(localStorage.getItem("currentUser")).companyId+``)
          .subscribe((response: any) => {

            this.users = response;

            if (this.filterBy === 'starred') {
              this.users = this.users.filter(user => {
                return this.user.starred.includes(user.profileId);
              });
            }

            if (this.filterBy === 'frequent') {
              this.users = this.users.filter(user => {
                return this.user.frequentGroups.includes(user.profileId);
              });
            }

            if (this.searchText && this.searchText !== '') {
              this.users = AbsUtils.filterArrayByString(this.users, this.searchText);
            }

            this.users = this.users.map(user => {
              return new User(user);
            });

            this.onUsersChanged.next(this.users);
            resolve(this.users);
          }, reject);
      }
    );
  }


  getAdminAndManagers(): Promise<any> {
    return new Promise((resolve, reject) => {
        this._httpClient.get(`${environment.apiUrl}/users/company/admin/`+JSON.parse(localStorage.getItem("currentUser")).companyId+``)
          .subscribe((response: any) => {
            let admins = response.map(user => {
              return new User(user);
            });
            resolve(admins);
          }, reject);
      }
    );
  }
 
  /**
   * Toggle selected group by id
   *
   * @param id
   */
  toggleSelectedUsers(id): void {
    // First, check if we already have that group as selected...
    if (this.selectedUsers.length > 0) {
      const index = this.selectedUsers.indexOf(id);

      if (index !== -1) {
        this.selectedUsers.splice(index, 1);

        // Trigger the next event
        this.onSelectedUsersChanged.next(this.selectedUsers);

        // Return
        return;
      }
    }

    // If we don't have it, push as selected
    this.selectedUsers.push(id);

    // Trigger the next event
    this.onSelectedUsersChanged.next(this.selectedUsers);
  }

  /**
   * Toggle select all
   */
  toggleSelectAll(): void {
    if (this.selectedUsers.length > 0) {
      this.deselectGroups();
    } else {
      this.selectGroups();
    }
  }

  /**
   * Select groups
   *
   * @param filterParameter
   * @param filterValue
   */
  selectGroups(filterParameter?, filterValue?): void {
    this.selectedUsers = [];

    // If there is no filter, select all groups
    if (filterParameter === undefined || filterValue === undefined) {
      this.selectedUsers = [];
      this.users.map(user => {
        this.selectedUsers.push(user.profileId);
      });
    }

    // Trigger the next event
    this.onSelectedUsersChanged.next(this.selectedUsers);
  }

  /**
   * Update group
   *
   * @param group
   * @returns {Promise<any>}
   */
  updateGroup(group): Promise<any> {
    return new Promise((resolve, reject) => {

      this._httpClient.post('api/groups-groups/' + group.id, {...group})
        .subscribe(response => {
          this.getUsers();
          resolve(response);
        });
    });
  }

  

  /**
   * Deselect groups
   */
  deselectGroups(): void {
    this.selectedUsers = [];

    // Trigger the next event
    this.onSelectedUsersChanged.next(this.selectedUsers);
  }

  /**
   * Delete group
   *
   * @param group
   */
  deleteGroup(group): void {
    const groupIndex = this.users.indexOf(group);
    this.users.splice(groupIndex, 1);
    this.onUsersChanged.next(this.users);
  }

  /**
   * Delete selected groups
   */
  deleteSelectedGroups(): void {
    for (const groupId of this.selectedUsers) {
      const group = this.users.find(_group => {
        return _group.profileId === groupId;
      });
      const groupIndex = this.users.indexOf(group);
      this.users.splice(groupIndex, 1);
    }
    this.onUsersChanged.next(this.users);
    this.deselectGroups();
  }
}
