import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {absAnimations} from '@abs/animations';
import {Playlist} from '../playlists.model';
import { environment } from 'environments/environment';
import { Content } from '../../content-list.model';

@Component({
  selector: 'app-playlist-items',
  templateUrl: './playlist-items.component.html',
  styleUrls: ['./playlist-items.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: absAnimations
})
export class PlaylistItemsComponent implements OnInit {

  @Input() playlist: Content;
  ratio: any;

  enviorment: any;
  constructor() { 
    this.enviorment = environment;
  }

  ngOnInit() {
    if (this.playlist && this.playlist.aspectRatio == 0.75) {
      this.ratio = "4:3";
    } else {
      this.ratio = "16:9";
    }
  }

}
