import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AbsProgressBarService } from '@abs/components/progress-bar/progress-bar.service';
import { TrainingService } from '../../_services/training.service';
import * as Stomp from 'stompjs';
import * as SockJS from 'sockjs-client';
import { environment } from 'environments/environment';



export interface AttendeeResult {

  firstName: String;
  lastName: String;
  passed: boolean;
  quizCompletedOn: Date;
  score: number;

}

export interface CourseResult {

  results: AttendeeResult[]
  passed: number;
  failed: number;
  noShow: number;
  inProgress: number;
  notStarted: number;
  totalAttendees: number;
};

@Component({
  selector: 'app-next-course-dialog',
  templateUrl: './next-course-dialog.component.html',
  styleUrls: ['./next-course-dialog.component.scss']
})



export class NextCourseDialogComponent implements OnInit {
  private serverUrl = `${environment.apiUrl}/socket`;
  private stompClient;

  result: CourseResult;
  courseTitle: any;
  classId: any;
  showNextCourseBtn = false;
  view: any;

  constructor(public matDialogRef: MatDialogRef<NextCourseDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private _progressBarService: AbsProgressBarService,
    private _trainingService: TrainingService) {
    this.courseTitle = _data.title;
    this.classId = _data.classId;
    this.showNextCourseBtn = _data.hasNextCourse;

    if (this.showNextCourseBtn) {
      this._trainingService.getTrainingCourseResult(this.classId, _data.productId).then(result => {
        this.result = result;
      });
    }
  }

  ngOnInit() {
    this.initializeWebSocketConnection();
  }

  nextCourse() {
    this.matDialogRef.close('next');
    this.stompClient.send('/app/training/course/next', {}, JSON.stringify({ classId: this.classId }));
  }

  initializeWebSocketConnection() {
    let ws = new SockJS(this.serverUrl);
    this.stompClient = Stomp.over(ws);
    let that = this;
    this.stompClient.connect({}, function (frame) {
      that.stompClient.subscribe("/training/class/" + that.classId + "/course/result", (message) => {
        console.log(message);
        if (message.body) {
          that.result = JSON.parse(message.body);
          console.log(that.result);
        }
      });

      /*   that.stompClient.subscribe("/training/class/" + that.classId + "/result", (message) => {
          console.log(message);
          if (message.body) {
            that.result = JSON.parse(message.body);
            console.log(that.result);
          }
        }); */

      that.stompClient.send('/training/next', {}, JSON.stringify(that.courseTitle)
      );
    });
  }

}
