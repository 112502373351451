import { ChangeDetectorRef, Component, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { merge, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {AbsNavigationItem} from '@abs/types';
import {AbsNavigationService} from '@abs/components/navigation/navigation.service';

@Component({
  selector: 'abs-nav-vertical-group',
  templateUrl: './group.component.html',
  styleUrls: ['./group.component.scss']
})
export class AbsNavVerticalGroupComponent implements OnInit, OnDestroy
{
  @HostBinding('class')
  classes = 'nav-group nav-item';

  @Input()
  item: AbsNavigationItem;

  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   */

  /**
   *
   * @param {ChangeDetectorRef} _changeDetectorRef
   * @param {AbsNavigationService} _absNavigationService
   */
  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _absNavigationService: AbsNavigationService
  )
  {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void
  {
    // Subscribe to navigation item
    merge(
      this._absNavigationService.onNavigationItemAdded,
      this._absNavigationService.onNavigationItemUpdated,
      this._absNavigationService.onNavigationItemRemoved
    ).pipe(takeUntil(this._unsubscribeAll))
      .subscribe(() => {

        // Mark for check
        this._changeDetectorRef.markForCheck();
      });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void
  {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

}
