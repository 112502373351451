import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {AbsSharedModule} from '@abs/shared.module';
import {Login2Component} from './login-2.component';

import {FormsModule} from '@angular/forms';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { NewDeviceDialogComponent } from './new-device-dialog/new-device-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatToolbarModule } from '@angular/material/toolbar';
import { DevicesService } from '../../account/devices/devices.service';
import { MatCardModule } from '@angular/material/card';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { MatRadioModule } from '@angular/material/radio';
import { Register2Component } from '../register-2/register-2.component';
import { Register2Module } from '../register-2/register-2.module';
import { UserAvatarModule } from '@abs/components/user-avatar/user-avatar.module';
import { MigrationWizardComponent } from './migration-wizard/migration-wizard.component';
import { MatDividerModule } from '@angular/material/divider';
import { MigrationRebornComponent } from './migration-reborn/migration-reborn.component';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatMenuModule } from '@angular/material/menu';

const routes = [
  {
    path: 'login',
    component: Login2Component
  }
];

@NgModule({
  declarations: [
    Login2Component,
    NewDeviceDialogComponent,
    ResetPasswordComponent,
    MigrationWizardComponent,
    MigrationRebornComponent
  ],
  imports: [
    RouterModule.forChild(routes),
    MatButtonModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatToolbarModule,
    MatDialogModule,
    MatSnackBarModule,
    AbsSharedModule,
    MatCardModule,
    FormsModule,
    MatRadioModule,
    Register2Module,
    UserAvatarModule,
    MatDividerModule,
    MatSelectModule,
    UserAvatarModule,
    MatListModule,
    MatProgressSpinnerModule,
    MatMenuModule,
    MatCardModule
  ],
  providers: [ DevicesService],
  entryComponents: [NewDeviceDialogComponent]
})
export class Login2Module {
}
