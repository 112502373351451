import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { AbsSharedModule } from '@abs/shared.module';
import { AbsConfirmDialogModule, AbsWidgetModule } from '@abs/components';
import { AgmCoreModule } from '@agm/core';
import { AmazingTimePickerModule } from 'amazing-time-picker';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ColorPickerModule } from 'ngx-color-picker';
import { CalendarModule as AngularCalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';

import {MatSliderModule} from '@angular/material/slider';

import { TrainingComponent } from './training.component';
import { ClassroomComponent } from './classroom/classroom.component';
import { LocationsComponent } from './locations/locations.component';
import { InsightsComponent } from './insights/insights.component';
import { AssignComponent } from './assign/assign.component';
import { AssignPreviewComponent } from './assign/assign-preview/assign-preview.component';
import { LocationsService } from './locations/locations.service';
import { LocationsListComponent } from './locations/locations-list/locations-list.component';
import { LocationFormComponent } from './locations/location-form/location-form.component';
import { ScheduledComponent } from './scheduled/scheduled.component';
import { InsightService } from './insights/insight.service';
import { CalendarComponent } from './calendar/calendar.component';
import { CalendarService } from './calendar/calendar.service';
import { CalendarEventFormDialogComponent } from './calendar/event-form/event-form.component';
import { EditLocationNameComponent } from './locations/edit-location-name/edit-location-name.component';
import { LocationMapComponent } from './locations/location-map/location-map.component';
import { AddLocationNameComponent } from './locations/add-location-name/add-location-name.component';
import { MatGoogleMapsAutocompleteModule } from '@angular-material-extensions/google-maps-autocomplete';
import { LocationAddressComponent } from './locations/location-address/location-address.component';
import { HoursOfAvailabilityComponent } from './locations/hours-of-availability/hours-of-availability.component';
import { MatChipsModule } from '@angular/material/chips';
import { ScheduleAsDialogComponent } from './scheduled/schedule-as-dialog/schedule-as-dialog.component';
import { MyTrainingsComponent } from './my-trainings/my-trainings.component';
import { MyTrainingContentItemComponent } from './my-trainings/my-training-content-item/my-training-content-item.component';
import { TrainingService } from './_services/training.service';
import { ContentService } from '../content/content.service';
import { GroupsService } from '../user/groups/groups.service';
import { MatTreeModule } from '@angular/material/tree';
import { AssignWizardComponent } from './assign-wizard/assign-wizard.component';
import { MatStepperModule } from '@angular/material/stepper';
import { StartCourseDialogComponent } from './my-trainings/start-course-dialog/start-course-dialog.component';
import { ContentModule } from '../content/content.module';
import { AssignGroupsComponent } from './assign/assign-groups/assign-groups.component';
import { AssignContentsComponent } from './assign/assign-contents/assign-contents.component';
import { MatSortModule } from '@angular/material/sort';
import { AbsDirectivesModule } from '@abs/directives/directives';
import { AssignSelectedUsersComponent } from './assign/assign-groups/assign-selected-users/assign-selected-users.component';
import { MatBadgeModule } from '@angular/material/badge';
import { AssignSelectedContentComponent } from './assign/assign-contents/assign-selected-content/assign-selected-content.component';
import { AbsPipesModule } from '@abs/pipes/pipes.module';
import { SwiperModule, SWIPER_CONFIG, SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { MaterialTimeControlModule } from '@abs/components/material-time-control.module';
import { MyCalendarComponent } from './my-calendar/my-calendar.component';
import { MyCalendarCourseItemComponent } from './my-calendar/my-calendar-course-item/my-calendar-course-item.component';
import { MyCalendarCourseStudentItemComponent } from './my-calendar/my-calendar-course-item/my-calendar-course-student-item/my-calendar-course-student-item.component';
import { MyCalendarService } from './_services/my-calendar.service';
import { AuthGuard } from '../authentication/_helpers';
import { UserLevel } from '../models/user';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { PlayerComponent } from './classroom/player/player.component';
import { CourseCompletedDialogComponent } from './classroom/course-completed-dialog/course-completed-dialog.component';
import { WindowComponent } from './classroom/window/window.component'
import { PortalModule } from '@angular/cdk/portal';
import { ClassQuizComponent } from './classroom/class-quiz/class-quiz.component';
import { NextCourseDialogComponent } from './classroom/next-course-dialog/next-course-dialog.component';
import { ClassroomCourseResultComponent } from './classroom/classroom-course-result/classroom-course-result.component';
import { WidgetsModule } from '../widgets/widgets.module';
import { ShoppingCartService } from '../auto-training/cart.service';
import { CheckoutViewsWidgetComponent } from '../widgets/checkout-views-widget/checkout-views-widget.component';
import { WifiInfoDialogComponent } from './classroom/wifi-info-dialog/wifi-info-dialog.component';
import { TrainingRouting } from './training.routing';
import { MyTrainingContentItemMobileComponent } from './my-trainings/my-training-content-item-mobile/my-training-content-item-mobile.component';
import { MyTrainingInilneQuizComponent } from './my-trainings/my-training-inilne-quiz/my-training-inilne-quiz.component';
import { ScoreBarModule } from '@abs/components/score-bar/score-bar.module';
import { IndependentTrainingInviteDialogComponent } from './classroom/independent-training-invite-dialog/independent-training-invite-dialog.component';
import { TrainerQuizPreviewComponent } from '../content/trainer-quiz-preview/trainer-quiz-preview.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { LastCourseCompleteDialogComponent } from './classroom/last-course-complete-dialog/last-course-complete-dialog.component';
import { AvatarModule } from 'ngx-avatar';
import { UserAvatarModule } from '@abs/components/user-avatar/user-avatar.module';
import { LandingPageModule } from '../landing-page/landing-page.module';
import { AutoTrainTriggersComponent } from './assign-wizard/auto-train-triggers/auto-train-triggers.component';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { DocumentViewerDialogModule } from '../document-viewer-dialog/document-viewer-dialog.module';
import { TrainingAgendaComponent } from './training-agenda/training-agenda.component';
import { AgendaItemDetailComponent } from './training-agenda/agenda-item-detail/agenda-item-detail.component';
import { AgendaItemCourseDetailComponent } from './training-agenda/agenda-item-course-detail/agenda-item-course-detail.component';
import { AgendaItemStudentDetailComponent } from './training-agenda/agenda-item-student-detail/agenda-item-student-detail.component';
import { AgendaItemCourseDetailStudentComponent } from './training-agenda/agenda-item-course-detail/agenda-item-course-detail-student/agenda-item-course-detail-student.component';
import { AgendaItemStudentDetailCourseComponent } from './training-agenda/agenda-item-student-detail/agenda-item-student-detail-course/agenda-item-student-detail-course.component';
import { AssignWizardRebornComponent } from './assign-wizard-reborn/assign-wizard-reborn.component';
import { LocationSelectorComponent } from './assign-wizard-reborn/location-selector/location-selector.component';
import { DepartmentSelectorComponent } from './assign-wizard-reborn/department-selector/department-selector.component';
import { GroupSelectorComponent } from './assign-wizard-reborn/group-selector/group-selector.component';
import { AutomatedTrainingTriggerSelectorComponent } from './assign-wizard-reborn/automated-training-trigger-selector/automated-training-trigger-selector.component';
import { GroupItemSelectorComponent } from './assign-wizard-reborn/group-item-selector/group-item-selector.component';
import { MyGroupSelectorComponent } from './assign-wizard-reborn/my-group-selector/my-group-selector.component';
import { SystemGroupSelectorComponent } from './assign-wizard-reborn/system-group-selector/system-group-selector.component';
import { FavoriteGroupSelectorComponent } from './assign-wizard-reborn/favorite-group-selector/favorite-group-selector.component';
import { OtherGroupSelectorComponent } from './assign-wizard-reborn/other-group-selector/other-group-selector.component';
import { PlaylistSelectorComponent } from './assign-wizard-reborn/playlist-selector/playlist-selector.component';
import { MyPlaylistSelectorComponent } from './assign-wizard-reborn/my-playlist-selector/my-playlist-selector.component';
import { FavoritePlaylistSelectorComponent } from './assign-wizard-reborn/favorite-playlist-selector/favorite-playlist-selector.component';
import { OthersPlaylistSelectorComponent } from './assign-wizard-reborn/others-playlist-selector/others-playlist-selector.component';
import { SystemPlaylistSelectorComponent } from './assign-wizard-reborn/system-playlist-selector/system-playlist-selector.component';
import { UsersSelectorComponent } from './assign-wizard-reborn/users-selector/users-selector.component';
import { CourseSelectorComponent } from './assign-wizard-reborn/course-selector/course-selector.component';
import { TrainingMethodSelectorComponent } from './assign-wizard-reborn/training-method-selector/training-method-selector.component';
import { IndependentTrainingWizardComponent } from './assign-wizard-reborn/independent-training-wizard/independent-training-wizard.component';
import { ClassroomTrainingWizardComponent } from './assign-wizard-reborn/classroom-training-wizard/classroom-training-wizard.component';
import { SaveAndActivateComponent } from './assign-wizard-reborn/save-and-activate/save-and-activate.component';
import { OnboardingDepartmentSelectorComponent } from './assign-wizard-reborn/onboarding-department-selector/onboarding-department-selector.component';
import { OnboardingLocationSelectorComponent } from './assign-wizard-reborn/onboarding-location-selector/onboarding-location-selector.component';
import { OnboardingGroupSelectorComponent } from './assign-wizard-reborn/onboarding-group-selector/onboarding-group-selector.component';
import { TrainingOptionSelectorComponent } from './assign-wizard-reborn/training-option-selector/training-option-selector.component';
import { TrainerSelectorComponent } from './assign-wizard-reborn/training-option-selector/trainer-selector/trainer-selector.component';
import { SendInvitesSelectorComponent } from './assign-wizard-reborn/training-option-selector/send-invites-selector/send-invites-selector.component';
import { TimeToCompleteSelectorComponent } from './assign-wizard-reborn/training-option-selector/time-to-complete-selector/time-to-complete-selector.component';
import { GraceDaySelectorComponent } from './assign-wizard-reborn/training-option-selector/grace-day-selector/grace-day-selector.component';
import { SendReminderSelectorComponent } from './assign-wizard-reborn/training-option-selector/send-reminder-selector/send-reminder-selector.component';
import { VideoPlayerSelectorComponent } from './assign-wizard-reborn/training-option-selector/video-player-selector/video-player-selector.component';
import { PassingScoreSelectorComponent } from './assign-wizard-reborn/training-option-selector/passing-score-selector/passing-score-selector.component';
import { FrequentAndRecurringSelectorComponent } from './assign-wizard-reborn/frequent-and-recurring-selector/frequent-and-recurring-selector.component';
import { PurchaseViewDialogComponent } from './assign-wizard/purchase-view-dialog/purchase-view-dialog.component';
import { CalendarDialogComponent } from './calendar-dialog/calendar-dialog.component';
import { TimePickerGridComponent } from './time-picker-grid/time-picker-grid.component';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { ClassroomStartCountdownComponent } from './classroom-start-countdown/classroom-start-countdown.component';
const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto'
};


@NgModule({
  declarations: [
    TrainingComponent,
    ClassroomComponent,
    LocationsComponent,
    InsightsComponent,
    AssignComponent,
    AssignPreviewComponent,
    LocationsListComponent,
    LocationFormComponent,
    ScheduledComponent,
    CalendarComponent,
    CalendarEventFormDialogComponent,
    EditLocationNameComponent,
    LocationMapComponent,
    AddLocationNameComponent,
    LocationAddressComponent,
    HoursOfAvailabilityComponent,
    ScheduleAsDialogComponent,
    MyTrainingsComponent,
    MyTrainingContentItemComponent,
    AssignWizardComponent,
    StartCourseDialogComponent,
    AssignGroupsComponent,
    AssignContentsComponent,
    AssignSelectedUsersComponent,
    AssignSelectedContentComponent,
    MyCalendarComponent,
    MyCalendarCourseItemComponent,
    MyCalendarCourseStudentItemComponent,
    PlayerComponent,
    CourseCompletedDialogComponent,
    WindowComponent,
    ClassQuizComponent,
    NextCourseDialogComponent,
    ClassroomCourseResultComponent,
    WifiInfoDialogComponent,
    MyTrainingContentItemMobileComponent,
    MyTrainingInilneQuizComponent,
    IndependentTrainingInviteDialogComponent,
    LastCourseCompleteDialogComponent,
    AutoTrainTriggersComponent,
    TrainingAgendaComponent,
    AgendaItemDetailComponent,
    AgendaItemCourseDetailComponent,
    AgendaItemStudentDetailComponent,
    AgendaItemCourseDetailStudentComponent,
    AgendaItemStudentDetailCourseComponent,
    AssignWizardRebornComponent,
    LocationSelectorComponent,
    DepartmentSelectorComponent,
    GroupSelectorComponent,
    AutomatedTrainingTriggerSelectorComponent,
    GroupItemSelectorComponent,
    MyGroupSelectorComponent,
    SystemGroupSelectorComponent,
    FavoriteGroupSelectorComponent,
    OtherGroupSelectorComponent,
    PlaylistSelectorComponent,
    MyPlaylistSelectorComponent,
    FavoritePlaylistSelectorComponent,
    OthersPlaylistSelectorComponent,
    SystemPlaylistSelectorComponent,
    UsersSelectorComponent,
    CourseSelectorComponent,
    TrainingMethodSelectorComponent,
    IndependentTrainingWizardComponent,
    ClassroomTrainingWizardComponent,
    SaveAndActivateComponent,
    OnboardingDepartmentSelectorComponent,
    OnboardingLocationSelectorComponent,
    OnboardingGroupSelectorComponent,
    TrainingOptionSelectorComponent,
    TrainerSelectorComponent,
    SendInvitesSelectorComponent,
    TimeToCompleteSelectorComponent,
    GraceDaySelectorComponent,
    SendReminderSelectorComponent,
    VideoPlayerSelectorComponent,
    PassingScoreSelectorComponent,
    FrequentAndRecurringSelectorComponent,
    PurchaseViewDialogComponent,
    CalendarDialogComponent,
    TimePickerGridComponent,
    ClassroomStartCountdownComponent
    
  ],
  imports: [
    CommonModule,
    TrainingRouting,    
    AbsSharedModule,
    AbsConfirmDialogModule,

    // Material2
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatRadioModule,
    MatTooltipModule,
    MatSelectModule,
    MatCheckboxModule,
    MatMenuModule,
    MatRippleModule,
    MatTableModule,
    MatToolbarModule,
    MatListModule,
    MatExpansionModule,
    MatDividerModule,
    MatSnackBarModule,
    MatTabsModule,
    MatButtonToggleModule,
    MatSidenavModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    MatGoogleMapsAutocompleteModule,
    MatChipsModule,
    MatDatepickerModule,
    MatTreeModule,
    MatStepperModule,
    AbsWidgetModule,
    ContentModule,
    MatSortModule,
    MatProgressSpinnerModule,
    AbsDirectivesModule,
    MatBadgeModule,
    AbsPipesModule,
    SwiperModule,
    MaterialTimeControlModule,
    MatSliderModule,
    ZXingScannerModule,
    PortalModule,
    WidgetsModule,
    MatAutocompleteModule,
    // TP
    ColorPickerModule,
    NgxDatatableModule,
    AmazingTimePickerModule,
    AngularCalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAxXFFzTkuVdOR65tMkE6Y-0G2-Rink0U8',
      libraries: ['places',]
    }),
    ScoreBarModule,
    UserAvatarModule,
    LandingPageModule,
    DocumentViewerDialogModule
  ],

  /* schemas: [
    NO_ERRORS_SCHEMA
  ], */
  providers: [
    {
    provide: SWIPER_CONFIG,
    useValue: DEFAULT_SWIPER_CONFIG
  }, CalendarService, LocationsService, InsightService, TrainingService, ContentService, GroupsService,ShoppingCartService],
  entryComponents: [LocationFormComponent, 
    CalendarEventFormDialogComponent, LocationMapComponent, 
    LocationAddressComponent, HoursOfAvailabilityComponent,
     ScheduleAsDialogComponent, StartCourseDialogComponent,
     CourseCompletedDialogComponent,PlayerComponent,NextCourseDialogComponent,ClassroomCourseResultComponent,WifiInfoDialogComponent,IndependentTrainingInviteDialogComponent,TrainerQuizPreviewComponent,LastCourseCompleteDialogComponent,PurchaseViewDialogComponent,CalendarDialogComponent,ClassroomStartCountdownComponent]
})
export class TrainingModule {
}
