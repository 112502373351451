import { Component, OnDestroy, OnInit, ViewEncapsulation, Input, EventEmitter, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators, FormControl, FormArray, AsyncValidatorFn } from '@angular/forms';
import { Subject, Observable } from 'rxjs';
import { takeUntil, first, debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

import { AbsConfigService } from '@abs/services/config.service';
import { absAnimations } from '@abs/animations';
import { RegistrationService } from '../_services/registration.service';
import { AbsProgressBarService } from '@abs/components/progress-bar/progress-bar.service';
import { User, UserLevel } from '../../models/user';
import { AbsUtils } from '@abs/utils';
import { Company } from '../_models';
import { SelectService } from '../../service/select.service';
import { Router } from '@angular/router';
import { AuthenticationService, CompnayService } from '../_services';
import { environment } from 'environments/environment';
import { SelectionModel } from '@angular/cdk/collections';
import { UserService } from '../../service/user.service';
import * as _ from 'lodash';
import { AccountProfile } from '../_models/accountprofile';
import { AbsPerfectScrollbarDirective } from '@abs/directives/abs-perfect-scrollbar/abs-perfect-scrollbar.directive';


export class Manager {
    userId: number;
    profileId: number;
    companyId; number;
    companyValidEmail: boolean;
    email: string;
    firstName: string;
    middleName: string;
    lastName: string;
    active: boolean;
    avatar: string;
    location: number;
}

export class RegistrationForm {
    profileId: number;
    companyName: string;
    companyWebsite: string;
    email: string;
    avatarImg: string;
    location: string;
    department; string;
    username: string;
    password: string;
    isStudent: boolean;
    managerEmail: string;
    managerProfileId: number;
    managerPhone: string;
    smsPhone: string;
    emailVerified: boolean;
    smsVerified: boolean;
    companyId: number;
    companyDomain: string;
    firstName: string;
    middleName: string;
    lastName: string;
    type: string;
    userLevel: string;
    groups: number[];
    departments: number[];
    managerEmails: string[];
    userAddedLocations: string[];
    companyDomainHandlized: string;
    providerId: number;
    providerHash: string;

    locationName: string;
    address: string;
    address1: string;
    city: string;
    state: string;
    zip: string;
    ssoUser: boolean;
    inviteType: string;
    invitedUsers: any[];
    policyId: any;

}

@Component({
    selector: 'register-2',
    templateUrl: './register-2.component.html',
    styleUrls: ['./register-2.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: absAnimations
})
export class Register2Component implements OnInit, OnDestroy {

    isManagerAdded = false;
    searchCtrlLocation = new FormControl();
    searchCtrlManager = new FormControl();
    searchCtrlMy = new FormControl();
    isSSOFlowNonSedgwick = false;
    allLocations: any;
    addLocation = false;
    searchManager = true;
    isFreeDomain = false;
    addDepartment = false;

    userDefinedCompanyDepartments = [];

    states: any;
    userType: any;
    locations: any;
    uploading = false;
    compDepartments: any;
    userCreated = false;
    currentUser = new RegistrationForm();
    imageFile: any;
    step = 1;
    alreadyExistUser = false;
    alreadyExistEmail = false;
    showEmail = false;
    isValidUserInformation = false;

    managerList: any;
    allManagerList: any;

    userAddedLocations = [];

    @Input()
    profileId: any;

    @Input()
    sso: boolean = false;

    @Input()
    migration: boolean = false;

    @Input()
    username: any;

    @Input()
    firstName: any;

    @Input()
    lastName: any;

    @Input()
    providerId: any;

    @Input()
    policyId:any;

    @Input()
    providerHash: any

    @Input()
    groups: any

    @Input()
    userLvl: any

    @Input()
    isManager: any


    @Input()
    existingUser: any;

    @Input()
    userId: any;

    @Output() valueChanges: EventEmitter<string> = new EventEmitter<string>();

    @Input()
    managerEmailValue: String;
    imagePath: any;
    userLevel: any;
    alreadyExistPhone = false;
    showPhone = false;
    departments: any;
    myDepartments = [];
    newlyAddedOrgDep = new SelectionModel<any>(true, []);

    allDepartments: any;
    allCompanyDepartments: any;

    selection = new SelectionModel<any>(false, []);
    selectionOrgDep = new SelectionModel<any>(true, []);
    selectedOrganizationDepartment = new SelectionModel<any>(true, []);

    selectionDep = new SelectionModel<any>(false, []);
    searchCtrlDepartments = new FormControl();
    searchCtrlOrg = new FormControl();
    showWelcomeCards = false;
    handalizedOrganization: string;
    createOrganization = false;
    found = false;
    webaddressfound = false;
    show = false;
    showWebAdd = false;
    company: any;
    isCustomCompany = false;
    showCompanyLocaitons = false;
    selectLocations = false;
    useEmplyeeId = false;
    useEmailAddress = true;
    customCompanyAdmins: User[];
    customCompanyManagers: User[];
    registerForm: FormGroup;
    managerForm: FormGroup;
    verificationForm: FormGroup;
    passwordForm: FormGroup;
    organizationForm: FormGroup;
    organizationLocationForm: FormGroup;
    addManagerForm: FormGroup;
    isManagerLoading = false;

    alreadyExist = false;
    invalidDomain = false;
    newUser = false;
    isStudent = false;
    isVerificationStep = false;
    manager: Manager;
    verificationCode: string;
    passwordScreen = false;
    showAccountInput = true;
    verificationEmailSent = false;
    managerNotFound = false;
    managerPhonePanel = false;
    managerEmailPanel = false;
    hidePassword = true;
    registrationDone = false;
    selectDepartment = false;
    verifyViaSMS = false;
    verificationSmsSent = false;
    isSMSVerified = false;
    isEmailVerified = false;
    environment: any;
    // Private
    private _unsubscribeAll: Subject<any>;

    constructor(
        private _userService: UserService,
        private _absConfigService: AbsConfigService,
        private _formBuilder: FormBuilder,
        private _registrationService: RegistrationService,
        private _progressBarService: AbsProgressBarService,
        private _selectService: SelectService,
        private authenticationService: AuthenticationService,
        private _companyService: CompnayService,
        private router: Router,
    ) {
        this.environment = environment;
        // Configure the layou
        this._absConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: false,
                    createAccount: false,
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };

        this._companyService.getCompanyConfigDepartments(0).subscribe(res => {
            // this.myDepartments = res
            this.allCompanyDepartments = res;
            this.compDepartments = res;

        });



        this._unsubscribeAll = new Subject();

    }

    get f() {
        return this.registerForm.controls;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------


    get managers() {
        return this.addManagerForm.controls["managers"] as FormArray;
    }
    /**
     * On init
     */
    ngOnInit(): void {

        if (this.sso && this.providerHash != '073b05') {
            this.isSSOFlowNonSedgwick = true;
        }

        if (this.sso || this.migration) {
            this.showWelcomeCards = true;
            this.step = 0;
        }

        if (this.migration) {
            this.authenticationService.getUserByProfileId(this.profileId).subscribe(value => {
                console.log("value", value);
                this.currentUser = value;
            })
        }

        this.addManagerForm = this._formBuilder.group({
            managers: this._formBuilder.array([])
        });
        this.addManger();
        this.addManger();
        this.addManger();

        this.addManagerForm.valueChanges.subscribe(val => {
            var hasValue = false;
            var length = this.managers.length;

            if (this.managers.value[length - 1].email != "") {
                this.addManger();
            }
            this.managers.value.forEach(element => {
                if (element.email != "") {
                    hasValue = true;
                }
            });
            if (hasValue) {
                this.isManagerAdded = true;
            } else {
                this.isManagerAdded = false;
            }

        })
        let isnum = this.username.match(/^[0-9]+$/) != null;

        this.registerForm = this._formBuilder.group({
            firstName: [this.firstName, [Validators.required]],
            lastName: [this.lastName, Validators.required],

            location: [{ disabled: true, value: '' }, Validators.required],
            department: [{ disabled: true, value: '' }, Validators.required],

            email: [{ disabled: true, value: isnum ? '' : this.username }, [Validators.required, Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]],
            username: [{ disabled: true, value: '' }, Validators.required],
            phone: [{ disabled: true, value: isnum ? this.username : '' }, [Validators.required, Validators.pattern("^[0-9]*$"),
            Validators.minLength(10), Validators.maxLength(10)]]
        });


        this.managerForm = this._formBuilder.group({
            managerEmail: [this.managerEmailValue, [Validators.required, Validators.email]],
            managerPhone: [{ disabled: true, value: '' }, [Validators.required]]
        });

        if (this.managerEmailValue != "") {
            //this.managerForm.controls.managerEmail.setValue();
        }

        this.verificationForm = this._formBuilder.group({
            verificationCode: ['', [Validators.required]],
            verificationPhone: [{ disabled: true, value: '' }, [Validators.required]]
        });

        this.passwordForm = this._formBuilder.group({
            password: ['', Validators.required],
            passwordConfirm: ['', [Validators.required, confirmPasswordValidator]]
        });

        this.organizationForm = this._formBuilder.group({
            organization: [this.currentUser.companyName, Validators.required],
            website: ['', [Validators.required, Validators.pattern('(https?://)?([\\da-zA-Z.-]+)\\.([a-zA-Z.]{2,6})[/\\w .-]*/?')]],
            organizationWebAdd: [''],

        });

        this.organizationLocationForm = this._formBuilder.group({

            location: ['', Validators.required],
            address: ['', Validators.required],
            address1: [''],
            city: ['', Validators.required],
            state: ['', Validators.required],
            zip: ['', Validators.required],

        });


        this.checkIfUserAlreadyExist();
        // Update the validity of the 'passwordConfirm' field
        // when the 'password' field changes
        this.passwordForm.get('password').valueChanges
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                this.passwordForm.get('passwordConfirm').updateValueAndValidity();
            });

        this.organizationForm.controls['organizationWebAdd'].valueChanges
            .pipe(
                debounceTime(1000),
                distinctUntilChanged()
            )
            .subscribe(e => {
                this.checkOrganization(e);
            });

        this.organizationForm.controls['website'].valueChanges
            .pipe(
                debounceTime(1000),
                distinctUntilChanged()
            )
            .subscribe(e => {
                if (!this.organizationForm.controls.website.invalid) {
                    console.log("valid");
                    this.checkOrganization(e);
                }
            });



        this.registerForm.controls['email'].valueChanges
            .pipe(
                debounceTime(1000),
                distinctUntilChanged()
            )
            .subscribe(e => {
                this.showEmail = false;
                this.alreadyExistEmail = false;
                if (e && e.length > 0 && !this.f.email.invalid && this.f.email.value !== this.username) {
                    this.checkEmailAlreadyExist()
                }

                this.checkIfValidInformation();
            });


        this.searchCtrlLocation.valueChanges.pipe(
            debounceTime(300),
            distinctUntilChanged()
        ).subscribe(value => {
            if (value.length > 0) {
                var result = AbsUtils.filterArrayByString(this.allLocations, value);
                this.locations = result;
            } else {
                this.locations = this.allLocations;

            }
        });

        this.searchCtrlManager.valueChanges.pipe(
            debounceTime(300),
            distinctUntilChanged()
        ).subscribe(value => {
            if (value.length > 0) {
                var result = this.allManagerList.filter(manager => manager.firstName.toLowerCase().indexOf(value.toLowerCase()) != -1);
                this.managerList = result;
            } else {
                this.managerList = this.allManagerList;

            }
        });



        this.registerForm.controls['phone'].valueChanges
            .pipe(
                debounceTime(200),
                distinctUntilChanged()
            )
            .subscribe(phone => {

                if (phone && phone.length > 0) {
                    {
                        if (this.f.phone.valid) {
                            this.checkIfPhoneAlreadyExist()
                        }
                    }
                } else {
                    this.f.phone.setValue(null);
                }
                this.checkIfValidInformation();
            });



        this._selectService.getStates().subscribe(result => {
            this.states = result;
        })

        this.searchCtrlMy.valueChanges.pipe(
            debounceTime(300),
            distinctUntilChanged()
        ).subscribe(value => {
            if (value.length > 0) {
                var result = AbsUtils.filterArrayByString(this.allDepartments, value);
                this.myDepartments = result;
            } else {
                this.myDepartments = this.allDepartments;

            }
        });

        this.searchCtrlOrg.valueChanges.pipe(
            debounceTime(300),
            distinctUntilChanged()
        ).subscribe(value => {
            if (value.length > 0) {
                var result = AbsUtils.filterArrayByString(this.allCompanyDepartments, value);
                this.compDepartments = result;
            } else {
                this.compDepartments = this.allCompanyDepartments;

            }
        });

        this.searchCtrlDepartments.valueChanges.pipe(
            debounceTime(300),
            distinctUntilChanged()
        ).subscribe(value => {
            if (value.length > 0) {
                var result = AbsUtils.filterArrayByString(this.allDepartments, value);
                console.log(result);
                this.departments = result;
            } else {
                this.departments = this.allDepartments;
            }
        });


    }


    setStep(step) {
        if (step == 10 && this.isSSOFlowNonSedgwick) {
            this.setUser('M')
            return;
        }

        this.step = step;
        if (step == 1) {

            this.f.firstName.enable();
            this.f.lastName.enable();

            this.f.email.disable();
            this.f.username.disable();
            this.f.phone.disable();


        } else if (step == 2) {

            this.currentUser.firstName = this.f.firstName.value;
            this.currentUser.lastName = this.f.lastName.value;

            this.f.firstName.disable();
            this.f.lastName.disable();

            this.f.email.enable();
            this.f.username.enable();
            this.f.phone.enable();

            this.verifyViaSMS = false;
            this.verificationEmailSent = false;
            this.verificationSmsSent = false;

        } else if (step == 3) {
            this._progressBarService.show();

            if (this.f.email.valid && this.f.email.value.length > 0) {

                this._registrationService.sendVerificationEmail(this.f.email.value, this.f.email.value, null).subscribe(resp => {
                    if (resp) {
                        this._progressBarService.hide();
                        this.verificationCode = resp;
                        this.verificationEmailSent = true;
                        // this.verificationForm.controls.email.enable();
                    }
                });
            } else {
                this._registrationService.sendVerificationSms(this.f.phone.value, 0).subscribe(resp => {
                    this._progressBarService.hide();
                    if (resp) {
                        this.verificationForm.controls.verificationPhone.setValue(this.f.phone.value);
                        this.verificationSmsSent = true;
                        this.verifyViaSMS = false;
                        this.verificationForm.controls.verificationCode.enable();
                        this.verificationForm.controls.verificationPhone.disable();
                    } else {
                        this.verificationForm.controls.verificationPhone.setErrors({ 'incorrect': true })
                        this.verificationForm.controls.verificationPhone.markAsTouched();
                    }
                });
            }
        } else if (step == 5) {
            if (this.userLevel == UserLevel.ADMIN) {
                this.setStep(6);
            }
            var domain = this.f.email.value.substring(this.f.email.value.lastIndexOf("@") + 1);
            this.organizationForm.controls.website.setValue(domain);
            this._companyService.checkIfFreeDomain(domain, "migrtion").subscribe(r => {
                this.initManagerForm();
                if (r == 1) {
                    this.searchManager = true;
                    this.isFreeDomain = true;
                } else {
                    this._registrationService.findManager(domain, 'domain').subscribe(resp => {
                        if (resp && resp.length > 0) {
                            this.managerList = resp;
                            this.allManagerList = resp;
                            this.searchManager = false;
                        } else {
                            this.searchManager = true;;
                        }
                    });
                }
                this.managerEmailPanel = true;
            })

        } else if (step == 6) {
            if (this.manager) {
                this._companyService.getCompanyDepartments(this.manager.companyId).subscribe(result => {
                    this.departments = result;
                    this.userDefinedCompanyDepartments = result;
                    this.allDepartments = result;
                    if (this.departments.length == 0) {
                        this.departments.push({ id: '83', department: 'Human Resources' }, { id: '147', department: 'Safety' })
                    } if (this.userDefinedCompanyDepartments.length == 0) {
                        this.userDefinedCompanyDepartments.push({ id: '83', department: 'Human Resources' }, { id: '147', department: 'Safety' })
                    }
                })
                this._companyService.getCompanyConfigDepartments(this.manager.companyId).subscribe(result => {
                    console.log("config", result);
                    this.compDepartments = result;
                    this.allCompanyDepartments = result;
                })

                this._selectService.getLocationsByCompanyId(this.manager.companyId).subscribe(results => {
                    this.locations = results;
                    if (!this.locations) {
                        this._selectService.getLocationsAll().subscribe(results => {
                            this.locations = results;
                        })
                    }
                    if (this.userAddedLocations && this.userAddedLocations.length > 0) {
                        this.locations.push(...this.userAddedLocations);
                    }
                })
            } else {

                if (this.company) {
                    this._companyService.getCompanyConfigDepartments(this.company.id).subscribe(result => {
                        this.compDepartments = result;
                        this.allCompanyDepartments = result;
                    })
                    this._companyService.getCompanyDepartments(this.company.id).subscribe(result => {
                        this.departments = result;
                        this.userDefinedCompanyDepartments = result;
                        this.allDepartments = result;
                        if (this.departments.length == 0) {
                            this.departments.push({ id: '83', department: 'Human Resources' }, { id: '147', department: 'Safety' })
                        }
                        if (this.userDefinedCompanyDepartments.length == 0) {
                            this.userDefinedCompanyDepartments.push({ id: '83', department: 'Human Resources' }, { id: '147', department: 'Safety' })
                        }
                    })

                    this._selectService.getLocationsByCompanyId(this.company.id).subscribe(results => {
                        this.locations = results;
                        if (this.userAddedLocations && this.userAddedLocations.length > 0) {
                            this.locations.push(...this.userAddedLocations);
                        }
                    })
                } else {
                    if (!this.departments || this.departments.length == 0) {
                        this.departments = [];
                        this.departments.push({ id: '83', department: 'Human Resources' }, { id: '147', department: 'Safety' })
                        this.userDefinedCompanyDepartments = [];
                        this.userDefinedCompanyDepartments.push({ id: '83', department: 'Human Resources' }, { id: '147', department: 'Safety' })

                    }

                }
            }

        }
        else if (step == 10) {


            this.managerPhonePanel = false;
            this.toggleManagerSearch(null, 'email')

        }
        else if (step == 12) {

            this.checkAndSuggest();
            //this.organizationForm.controls.organizationWebAdd.setValue(domain);
        }
        else if (step == 14) {

            if (this.userLevel == UserLevel.MANAGER) {
                if (this.company) {
                    this._companyService.getCompanyDepartments(this.company.id).subscribe(result => {
                        if (result) {

                            result.forEach(element => {
                                this.myDepartments.push({ key: element.id, value: element.department });
                            });
                        }
                        if (this.myDepartments.length == 0) {
                            this._companyService.getDepartments().subscribe(result => {
                                result.forEach(element => {
                                    this.myDepartments.push({ key: element.id, value: element.department });
                                });
                            })
                        }
                    })
                } else {
                    this._companyService.getDepartments().subscribe(result => {
                        if (result) {

                            result.forEach(element => {
                                this.myDepartments.push({ key: element.id, value: element.department });
                            });
                        }
                    })
                }
            }

            //this.organizationForm.controls.organizationWebAdd.setValue(domain);
        }
    }

    onDepartmentChange(department) {
        this.selectionDep.toggle(department.id);
    }

    onMyDepartmentChange(department) {
        this.selectionDep.toggle(department.id);
    }
    onDepartmentOrgChange(department) {
        if (!this.departments) {
            this.departments = [];
        }

        if (!this.myDepartments) {
            this.myDepartments = [];
        }
        this.selectionOrgDep.toggle(department.id);
        this.newlyAddedOrgDep.toggle(department.id);
        this.selectedOrganizationDepartment.toggle(department);

        console.log(this.selectedOrganizationDepartment);
        this.departments = [...this.userDefinedCompanyDepartments];
        this.myDepartments = [...this.userDefinedCompanyDepartments];

        console.log(this.departments);
        this.selectedOrganizationDepartment.selected.forEach(element => {
            this.departments.push(element);
            this.myDepartments.push(element);
        })

        this.departments.sort((a, b) => a.department.localeCompare(b.department));
        this.myDepartments.sort((a, b) => a.department.localeCompare(b.department));

    }

    /*  onDepartmentOrgChange(department) {
         this.selectionOrgDep.toggle(department.key);
     
         if(this.selectionOrgDep.isSelected(department.key)){
           this.newlyAddedOrgDep.select(department.key);
         }
         var selected = this.selectionOrgDep.selected.map(dept => {
           this.compDepartments.forEach(element => {
             if (element.key == dept) {
               if (!this.myDepartments || this.myDepartments.length == 0) {
                 this.myDepartments = [];
               }
               var newDept = { id: element.key, department: element.value }
               this.myDepartments.push(newDept);
             }
           });
         })
       } */

    onLocationChange(location) {
        this.selection.toggle(location.id);

    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    checkIfUserAlreadyExist() {
        if (this.registerForm.valid) {
            this.valueChanges.emit(this.f.email.value);
            this.newUser = true;

            if (this.f.email.value.indexOf("@") != -1 && this.f.email.value.indexOf(".") != -1) {
                var domain = this.f.email.value.substring(this.f.email.value.lastIndexOf("@") + 1);
                console.log(AbsUtils.handleize(domain.split(".")[0]))
                domain = AbsUtils.handleize(domain.split(".")[0]);
                this.organizationForm.controls.organization.setValue(domain);
                //this.handalizedOrganization = domain;
            }

            /*  this._progressBarService.show();
             this._registrationService.checkIfUserAlreadyExist(this.f.email.value).subscribe(resp => {
                 if (resp) {
                     this.alreadyExist = true;
                     console.log("going to emit");
                     this.registerForm.controls['email'].setErrors({ 'incorrect': true })
                     this.registerForm.controls['email'].markAsTouched();
                     this.newUser = false;
                     this._progressBarService.hide();
                 } else {
                     this.alreadyExist = false;
                     this.newUser = true;
                     this.registerForm.controls['email'].setErrors(null)
                     this.registerForm.controls['email'].markAsTouched();
                     this._progressBarService.hide();
                 }
             }); */

        }
    }

    toggleRegistrationNumber() {
        if (this.useEmailAddress) {
            this.useEmailAddress = false;
            this.registerForm.controls.email.disable();
            this.registerForm.controls.employeeNumber.enable();
        } else {
            this.useEmailAddress = true;
            this.registerForm.controls.email.enable();
            this.registerForm.controls.employeeNumber.disable();
        }
    }

    registrationFlow(e: any, isStudent) {
        this.step = 1;
        /* this.selectDepartment = false;
        this.selectLocations = false;
        this.isStudent = isStudent;
        if (this.isStudent) {
            this.managerEmailPanel = true;
            this.managerPhonePanel = false;
            this.managerForm.controls.managerEmail.enable();
            this.managerForm.controls.managerPhone.disable();
        } else {
            this.checkIfOrganizationAlreadyExist();
            this.managerPhonePanel = false;
            this.managerEmailPanel = false;
            this.managerForm.controls.managerEmail.disable();
            this.managerForm.controls.managerPhone.disable();
        }
        this.isVerificationStep = true;
        this.showAccountInput = false; */
    }

    validateEmail(e) {
        console.log(e);
        /*  this._registrationService.checkIfUserAlreadyExist(this.f.email.value).subscribe(res => {
             this._progressBarService.hide();
             this.showEmail = true;
             if (res && res.length > 0) {
                 this.alreadyExistEmail = true;
             } else {
                 this.alreadyExistEmail = false;
             }
             this.checkIfValidInformation();
 
         }) */

    }

    findManager(e: any, type: string) {
        if (this.managerForm.valid) {
            this._progressBarService.show();
            let value = "";
            if (type == 'sms') {
                value = this.managerForm.controls.managerPhone.value;
            } else {
                value = this.managerForm.controls.managerEmail.value;

            }
            this._registrationService.findManager(value, type).subscribe(resp => {
                if (resp && resp.length > 0) {
                    this.manager = resp[0];
                    this.registerForm.controls.location.setValue(this.manager.location);
                    this._progressBarService.hide();
                    this.managerNotFound = false;
                } else {
                    this.manager = null;
                    this._progressBarService.hide();
                    this.managerNotFound = true;

                }
            });
        }
    }

    toggleManagerSearch(e: any, type: string) {
        this.manager = null;
        this.managerForm.controls.managerEmail.setValue("");
        this.managerForm.controls.managerPhone.setValue("");
        if (type === 'email') {
            this.managerEmailPanel = true;
            this.managerPhonePanel = false;
            this.managerForm.controls.managerEmail.enable();
            this.managerForm.controls.managerPhone.disable();
        } else {
            this.managerEmailPanel = false;
            this.managerPhonePanel = true;
            this.managerForm.controls.managerEmail.disable();
            this.managerForm.controls.managerPhone.enable();
        }

    }

    sendVerificationEmail(e: any) {
        this.managerEmailPanel = false;
        this.managerPhonePanel = false;
        if (this.manager && this.manager.companyValidEmail && this.useEmailAddress) {
            this.isStudent = false;
            this._progressBarService.show();
            this._registrationService.sendVerificationEmail(this.f.email.value, this.f.email.value, this.manager.companyId).subscribe(resp => {
                if (resp) {
                    this._progressBarService.hide();
                    this.verificationCode = resp;
                    this.step = 3;
                }
            });
        } else {
            this.selectLocations = false;
            this.passwordScreen = true;
        }
    }

    sendVerificationSms(e: any) {
        this.managerEmailPanel = false;
        this.managerPhonePanel = false;
        this.isStudent = false;
        this._progressBarService.show();
        this._registrationService.sendVerificationSms(this.verificationForm.controls.verificationPhone.value, 0).subscribe(resp => {
            this._progressBarService.hide();
            if (resp) {
                this.verificationSmsSent = true;
                this.verifyViaSMS = false;
                this.verificationForm.controls.verificationCode.enable();
                this.verificationForm.controls.verificationPhone.disable();
            } else {
                this.verificationForm.controls.verificationPhone.setErrors({ 'incorrect': true })
                this.verificationForm.controls.verificationPhone.markAsTouched();
            }
        });
    }

    validateVerificationCode() {
        this.isStudent = false;
        this._progressBarService.show();
        this._registrationService.validateVerificationCode(this.verificationForm.controls.verificationCode.value, this.registerForm.controls.email.value).subscribe(resp => {
            this._progressBarService.hide();
            if (resp) {
                if (this.sso) {
                    if (this.isSSOFlowNonSedgwick) {
                        this.setUser('M');
                    } else {
                        this.setStep(10)
                    }
                } else {
                    this.step = 4;
                }
                this.isEmailVerified = true;
            } else {
                this.verificationForm.controls.verificationCode.setErrors({ 'incorrect': true })
                this.verificationForm.controls.verificationCode.markAsTouched();
                this.isEmailVerified = false;

            }
        });
    }

    validateVerificationCodeForSMS() {
        this.isStudent = false;
        this._progressBarService.show();
        this._registrationService.validateVerificationCodeForSMS(this.verificationForm.controls.verificationCode.value, this.verificationForm.controls.verificationPhone.value).subscribe(resp => {
            this._progressBarService.hide();
            if (resp) {
                this.selectDepartment = false;
                if (this.sso) {
                    this.setStep(10)
                } else {
                    this.step = 4;
                }
                this.verificationEmailSent = false;
                this.verificationSmsSent = false;
                this.verifyViaSMS = false;
                this.isSMSVerified = true;
            } else {
                this.verificationForm.controls.verificationCode.setErrors({ 'incorrect': true })
                this.verificationForm.controls.verificationCode.markAsTouched();
                this.isSMSVerified = false;

            }
        });
    }

    showPasswordScreen(e: any) {
        this.selectDepartment = false;
        this.passwordScreen = true;
        this.verificationEmailSent = false;
    }

    toggleShowHidePassword(e: any) {
        if (this.hidePassword) {
            this.hidePassword = false;
        } else {
            this.hidePassword = true;
        }
    }
    toggleShowLocations() {
        this.managerEmailPanel = false;
        this.managerPhonePanel = false;
        this.selectLocations = true;
        this.registerForm.controls.email.disable();
        this.registerForm.controls.employeeNumber.disable();
        this.registerForm.controls.department.disable();
        this.registerForm.controls.location.enable();
        this.showAccountInput = false;
        this.registerForm.controls.location.updateValueAndValidity();
    }
    toggleShowDepartments() {
        this.selectLocations = false;
        this.registerForm.controls.email.disable();
        this.registerForm.controls.employeeNumber.disable();
        this.registerForm.controls.location.disable();
        this.registerForm.controls.department.enable();
        this.selectDepartment = true;
    }

    toggleVerificationChannel() {
        this.verificationSmsSent = false;
        if (this.verificationEmailSent) {
            this.verificationEmailSent = false;
            this.verifyViaSMS = true;
            this.verificationForm.controls.verificationCode.disable();
            this.verificationForm.controls.verificationPhone.enable();
        } else {
            this.verificationEmailSent = true;
            this.verifyViaSMS = false;
            this.verificationForm.controls.verificationCode.enable();
            this.verificationForm.controls.verificationPhone.disable();
        }
    }

    signIn(email, password, company) {
        this._progressBarService.show();
        this.authenticationService.login(email, password, company)
            .pipe(first())
            .subscribe(
                data => {
                    this._progressBarService.hide();
                    this.router.navigate(['home']);
                },
                error => {

                });
    }


    setUser(value) {
        this.userType = value
        var domain = this.f.email.value.substring(this.f.email.value.lastIndexOf("@") + 1);
        if (value == 'A') {

            this._companyService.checkIfFreeDomain(domain, "migrtion").subscribe(r => {
                if (r == 1) {
                    this.setStep(11)
                } else {
                    this._companyService.getCompanyByWebsite(domain).subscribe(result => {
                        this.company = result;
                        if (this.company) {
                            this.setStep(6);
                        } else {
                            this.setStep(11)
                        }
                    })
                }
            })
            // this.setStep(11)
            this.userLevel = UserLevel.ADMIN;
        } else if (value == 'M') {
            this._companyService.checkIfFreeDomain(domain, "migrtion").subscribe(r => {
                if (r == 1) {
                    this.setStep(11)
                } else {
                    this._companyService.getCompanyByWebsite(domain).subscribe(result => {
                        this.company = result;
                        if (this.company) {
                            this.setStep(5);
                        } else {
                            this.setStep(11)
                        }
                    })
                }
            })
            //this.setStep(5)
            this.userLevel = UserLevel.MANAGER;
        }
        else if (value == 'L') {
            this.setStep(5)
            this.userLevel = UserLevel.LEARNER;

        }
    }

    organizationSubmit(event) {
        this.createOrganization = false;
        if (this.found) {
            console.log("in");
            this.registrationFlow("", true);
        } else {
            console.log("in else");

            this._progressBarService.show();
            this._registrationService.sendVerificationEmail(this.f.email.value, this.f.email.value, 0).subscribe(resp => {
                if (resp) {
                    this._progressBarService.hide();
                    this.verificationEmailSent = true;
                }
            });
            this.isVerificationStep = true;

        }

    }

    nonOfAbove() {
        this.searchManager = true;
        this.manager = null;
    }

    checkAndSuggest() {
        if (this.organizationForm.valid) {
            var domain = this.organizationForm.controls.website.value.toString();
            var splited = AbsUtils.handleize(domain.split("."));
            if (domain.indexOf("www") != -1) {
                domain = AbsUtils.handleize(domain.split(".")[1]);
            } else {
                domain = AbsUtils.handleize(domain.split(".")[0]);
            }
            this._progressBarService.show();
            var secondOption = AbsUtils.handleize(this.organizationForm.controls.organization.value);
            this._companyService.getCompanyByDomainAndSuggest(domain, secondOption).subscribe(res => {
                console.log("res", res);
                this._progressBarService.hide();
                this.organizationForm.controls.organizationWebAdd.setValue(res.website);
            })
        }
    }


    checkIfOrganizationAlreadyExist(value) {
        this.createOrganization = true;
        var domain = AbsUtils.handleize(value);
        this.handalizedOrganization = domain;
        this.showWebAdd = false;
        this._companyService.getCompanyByDomain(domain).subscribe(res => {
            this._progressBarService.hide();
            this.showWebAdd = true;
            if (res) {
                this.webaddressfound = true;
                this.company = res;
            } else {
                this.webaddressfound = false;
                this.company = null;

            }
        })
    }

    checkOrganization(value) {

        console.log("organization value", value);
        var domain = AbsUtils.getDomain(value, true);
        console.log("domain", domain)
        this.handalizedOrganization = domain;
        this._companyService.getCompanyByDomain(domain).subscribe(res => {
            if (res) {
                this.company = res;
                if (this.userLevel == UserLevel.ADMIN) {
                    this.setStep(6);
                } else {
                    this.setStep(5);
                }
            }
        })


        //this.checkIfOrganizationAlreadyExist(this.organizationForm.controls.organizationWebAdd.value);
    }
    cancel() {
        this.company = null;
        this.createOrganization = false;
        this.showAccountInput = true;
        this.selectLocations = false;
        this.selectDepartment = false;
        this.passwordScreen = false;
        this.managerEmailPanel = false;
        this.managerPhonePanel = false;
        this.verificationEmailSent = false;
    }

    onFileChanged(event) {
        //this._progressBarService.show();
        this.imageFile = event;
        this.preview(event.target.files);
    }

    uploadPicture(profileId) {
        this.uploading = true;
        const uploadImageData = new FormData();
        uploadImageData.append('picture', this.imageFile.target.files[0], this.imageFile.target.files[0].name);
        uploadImageData.append('profileId', profileId);
        this._userService.updateProfilePicture(uploadImageData).subscribe(response => {
            this.currentUser.avatarImg = this.imageFile.target.files[0].name;
            this.userCreated = true;
            this.uploading = false;
        });
    }

    preview(files) {
        if (files.length === 0)
            return;


        var reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = (_event) => {
            this.imagePath = reader.result;
        }
    }

    insertInformation(uploadPicture) {
        this._progressBarService.show();
        var formData = new RegistrationForm();
        formData.department = this.selectionDep.selected[0];
        if (this.userLevel != UserLevel.LEARNER) {
            formData.departments = this.newlyAddedOrgDep.selected;
        }
        formData.userLevel = this.userLevel;

        // formData.location = this.selection.selected[0];

        if (this.userAddedLocations && this.userAddedLocations.length > 0) {

            this.selection.selected.forEach(location => {
                this.userAddedLocations.forEach(userLocation => {
                    if (userLocation.id == location) {
                        userLocation.selected = true;
                    }
                    userLocation.id = -1;
                })
            })

            formData.userAddedLocations = this.userAddedLocations;
        } else {
            if (this.selection.selected && this.selection.selected.length > 0) {
                formData.location = this.selection.selected[0];
            }
        }

        formData.managerEmail = this.managerForm.controls.managerEmail.value;
        formData.managerPhone = this.managerForm.controls.managerPhone.value;
        //formData.smsPhone = this.verificationForm.controls.verificationPhone.value;
        formData.emailVerified = this.isEmailVerified;
        formData.smsVerified = this.isSMSVerified;
        //this.handalizedOrganization = this.f.email.value.substring(this.f.email.value.lastIndexOf("@") + 1);
        formData.companyDomain = this.handalizedOrganization;
        formData.companyDomainHandlized = this.handalizedOrganization;
        formData.companyName = this.organizationForm.controls.organization.value;
        formData.companyWebsite = this.handalizedOrganization;
        formData.providerId = this.providerId;
        formData.providerHash = this.providerHash;
        formData.firstName = _.startCase(_.toLower(this.f.firstName.value));
        formData.lastName = _.startCase(_.toLower(this.f.lastName.value));
        formData.username = this.f.email.valid ? this.f.email.value : this.f.phone.value;
        formData.email = this.f.email.value;
        formData.smsPhone = this.f.phone.value;
        formData.groups = this.groups;
        formData.policyId = this.policyId;

        /*  formData.locationName = this.organizationLocationForm.controls.location.value;
         formData.address = this.organizationLocationForm.controls.address.value;
         formData.address1 = this.organizationLocationForm.controls.address1.value;
         formData.city = this.organizationLocationForm.controls.city.value;
         formData.state = this.organizationLocationForm.controls.state.value;
         formData.zip = this.organizationLocationForm.controls.zip.value; */




        if (this.company) {
            formData.companyId = this.company.id;
        }
        if (this.manager) {
            formData.managerProfileId = this.manager.profileId
        }
        if (this.sso) {
            formData.password = 'temp/123';
        } else {
            formData.password = this.passwordForm.controls.passwordConfirm.value;

        }
        if (this.useEmailAddress) {
            //formData.username = this.registerForm.controls.email.value;
        } else {
            //formData.username = this.registerForm.controls.employeeNumber.value;
        }

        formData.ssoUser = this.sso;
        if (this.userLevel == UserLevel.ADMIN) {
            var managerEmails = [];
            this.managers.controls.forEach(c => {
                if (c.value.email != "") {
                    managerEmails.push(c.value.email);
                }
            })
            formData.managerEmails = managerEmails;
        }

        this._registrationService.signUp(formData).subscribe(resp => {
            this._progressBarService.hide();
            if (resp) {
                this.profileId = resp;
                if (uploadPicture) {
                    this.uploadPicture(resp);
                } else {
                    this.userCreated = true;
                    this.done();
                }
            } else {

            }
        });
    }


    profileDone(uploadPicture) {
        if (this.migration) {
            this.updateInformation(uploadPicture);
        } else {
            this.insertInformation(uploadPicture);
        }

    }

    done() {

        var username = this.f.email.valid ? this.f.email.value : this.f.phone.value;

        if (this.sso) {
            this.authenticationService.ssoLogin(this.profileId).subscribe(res => {
                if (res) {
                    this.router.navigate(['home']);
                }
            });
        } else {
            this.signIn(username, this.passwordForm.controls.passwordConfirm.value, this.profileId);
        }
    }


    checkUsernameAlreadyExist() {
        this.show = false;
        this._progressBarService.show();
        /* this._registrationService.checkIfUsernameAlreadyExist(this.f.username.value).subscribe(res => {
            this._progressBarService.hide();
            this.show = true;
            if (res) {
                this.alreadyExistUser = true;
            } else {
                this.alreadyExistUser = false;
            }
            this.checkIfValidInformation();
        }) */
    }

    checkEmailAlreadyExist() {
        this.alreadyExistEmail = false;
        this.showEmail = false;
        this._progressBarService.show();
        this._registrationService.checkIfUserAlreadyExist(this.f.email.value).subscribe(res => {
            this._progressBarService.hide();
            this.showEmail = true;
            if (res) {
                this.alreadyExistEmail = true;
            } else {
                this.alreadyExistEmail = false;
            }
            this.checkIfValidInformation();

        })
    }

    addLocationDone() {

        var location = {
            id: AbsUtils.generateGUID(),
            name: this.organizationLocationForm.controls.location.value,
            address1: this.organizationLocationForm.controls.address.value,
            address2: this.organizationLocationForm.controls.address1.value,
            city: this.organizationLocationForm.controls.city.value,
            state: this.organizationLocationForm.controls.state.value,
            zip: this.organizationLocationForm.controls.zip.value
        }
        this.organizationLocationForm.reset();

        this.userAddedLocations.push(location);
        this.locations.push(location)
        this.selection.select(location.id);
        this.addLocation = false;
    }

    checkIfPhoneAlreadyExist() {
        this.showPhone = false;
        this._progressBarService.show();
        this._registrationService.checkIfUserAlreadyExist(this.f.phone.value).subscribe(res => {
            this._progressBarService.hide();
            this.showPhone = true;
            if (res) {
                this.alreadyExistPhone = true;
            } else {
                this.alreadyExistPhone = false;
            }

            this.checkIfValidInformation();
        })
    }

    checkIfValidInformation() {
        this.isValidUserInformation = true;
        if ((this.f.email.value && this.f.email.invalid) || (this.f.phone.value && this.f.phone.invalid) || this.alreadyExistEmail || this.alreadyExistPhone) {
            this.isValidUserInformation = false;
        }
    }

    cancelRegistration() {
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate(['/authentication/login']);
    }


    createAddManger(): FormGroup {
        return this._formBuilder.group({
            email: ['',
                {
                    validators: [Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)],
                    asyncValidators: [managerAlreadyExist(this._registrationService)],
                    updateOn: 'blur',
                }
            ],

        });
    }



    addManger(): void {
        this.managers.push(this.createAddManger());
    }

    setManager(manager) {
        this.manager = manager;
        this.setStep(6);
    }

    setOrgLocation() {

        var location = {
            id: AbsUtils.generateGUID(),
            name: this.organizationLocationForm.controls.location.value,
            address1: this.organizationLocationForm.controls.address.value,
            address2: this.organizationLocationForm.controls.address1.value,
            city: this.organizationLocationForm.controls.city.value,
            state: this.organizationLocationForm.controls.state.value,
            zip: this.organizationLocationForm.controls.zip.value
        }
        this.organizationLocationForm.reset();


        if (!this.locations) {
            this.locations = [];
        }
        this.locations.push(location);
        this.selection.select(location.id);
        this.userAddedLocations.push(location);
        this.setStep(5);
    }

    updateInformation(uploadPicture) {
        this._progressBarService.show();

        var accountProfile = new AccountProfile({});

        accountProfile.department = this.selectionDep.selected[0];
        accountProfile.email = this.f.email.value;
        accountProfile.validEmail = true;
        accountProfile.firstName = _.startCase(_.toLower(this.f.firstName.value));
        accountProfile.lastName = _.startCase(_.toLower(this.f.lastName.value));
        accountProfile.phone = this.f.phone.value;
        accountProfile.updateMigrationStatus = true;
        accountProfile.department = this.selectionDep.selected[0];
        accountProfile.userLevel = this.userLevel;

        if (this.userLevel != UserLevel.LEARNER) {
            accountProfile.departments = this.newlyAddedOrgDep.selected;
        }

        if (this.sso) {
            accountProfile.password = "temp/123";
        } else {
            accountProfile.password = this.passwordForm.controls.password.value;

        }
        accountProfile.profileId = this.profileId;
        accountProfile.userId = this.userId;
        if (this.manager) {
            accountProfile.managerId = this.manager.profileId;
        }

        if (this.company) {
            accountProfile.companyId = this.company.id;
        } else {
            accountProfile.companyId = this.currentUser.companyId;

        }
        accountProfile.companyDomain = this.handalizedOrganization;
        accountProfile.companyDomainHandlized = this.handalizedOrganization;
        accountProfile.companyName = this.organizationForm.controls.organization.value;
        accountProfile.companyWebsite = this.handalizedOrganization;

        if (this.userAddedLocations && this.userAddedLocations.length > 0) {
            this.selection.selected.forEach(location => {
                this.userAddedLocations.forEach(userLocation => {
                    if (userLocation.id == location) {
                        userLocation.selected = true;
                    }
                    userLocation.id = -1;
                })
            })

            accountProfile.userAddedLocations = this.userAddedLocations;
        } else {
            if (this.selection.selected && this.selection.selected.length > 0) {
                accountProfile.location = this.selection.selected[0];
            }
        }


        var managerEmails = [];
        this.managers.controls.forEach(c => {
            if (c.value.email != "") {
                managerEmails.push(c.value.email);
            }
        })
        accountProfile.managerEmails = managerEmails;

        accountProfile.policyId = this.policyId;

        this._userService.updateAccountProfileMigration(accountProfile).subscribe(response => {
            this._progressBarService.hide();
            if (response) {
                if (this.sso) {

                    if (uploadPicture) {
                        this.uploadPicture(this.profileId);
                    } else {
                        this.done();
                    }

                } else {
                    this.signIn(accountProfile.email, accountProfile.password, this.profileId);
                }
            }
        });;
    }

    initManagerForm() {

        this.managerForm.controls['managerPhone'].valueChanges
            .pipe(
                debounceTime(1000),
                distinctUntilChanged()
            )
            .subscribe(e => {
                console.log("sms", e);
                if (this.managerForm.controls['managerPhone'].enabled) {
                    this.findManager("", 'sms')
                }
            });

        this.managerForm.controls['managerEmail'].valueChanges
            .pipe(
                debounceTime(1000),
                distinctUntilChanged()
            )
            .subscribe(e => {
                this.findManager("", 'email')
            });
    }



}



/**
 * Confirm password validator
 *
 * @param {AbstractControl} control
 * @returns {ValidationErrors | null}
 */
export const confirmPasswordValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {

    if (!control.parent || !control) {
        return null;
    }

    const password = control.parent.get('password');
    const passwordConfirm = control.parent.get('passwordConfirm');

    if (!password || !passwordConfirm) {
        return null;
    }

    if (passwordConfirm.value === '') {
        return null;
    }

    if (password.value === passwordConfirm.value) {
        return null;
    }

    return { passwordsNotMatching: true };
};
export function managerAlreadyExist(registerService: RegistrationService): AsyncValidatorFn {
    return (control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
        return registerService.checkIfUserAlreadyExist(control.value).pipe(map(
            (result) => {
                return (result) ? { "emailTaken": true } : null;
            }
        ));
    };
} 