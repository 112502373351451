import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import {ABS_CONFIG} from '@abs/services/config.service';


@NgModule()
export class AbsModule
{
  constructor(@Optional() @SkipSelf() parentModule: AbsModule)
  {
    if ( parentModule )
    {
      throw new Error('AbsModule is already loaded. Import it in the AppModule only!');
    }
  }

  static forRoot(config): ModuleWithProviders
  {
    return {
      ngModule : AbsModule,
      providers: [
        {
          provide : ABS_CONFIG,
          useValue: config
        }
      ]
    };
  }
}
