import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthenticationService } from '../_services';
import {Observable} from 'rxjs';
import { UserLevel } from '../../models/user';


@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        const currentUser = this.authenticationService.currentUserValue;
        if (currentUser) {
            // check if route is restricted by role
            if (route.data.roles && route.data.roles.indexOf(currentUser.userLevel) === -1) {
                // role not authorised so redirect to home page
                this.authenticationService.logout();
                this.router.navigate(['/authentication/login']);
                return false;
            }

            if(localStorage.getItem("kiosk") == "Y" && currentUser.userLevel != UserLevel.LEARNER){
                this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => this.router.navigate(['/home']));
            }

            // authorised so return true
            return true;

        }

        // not logged in so redirect to login page with the return url
       // this.router.navigate(['/authentication/login'], { queryParams: { returnUrl: state.url } });
       this.router.navigate(['/authentication/login']);

        return false;

    }
}
