import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { RegistrationService } from '../../_services/registration.service';
import { AbsProgressBarService } from '@abs/components/progress-bar/progress-bar.service';
import { SelectService } from '../../../service/select.service';
import { AuthenticationService, CompnayService } from '../../_services';
import { Router } from '@angular/router';
import { debounceTime, distinctUntilChanged, first } from 'rxjs/operators';
import { absAnimations } from '@abs/animations';
import { UserService } from '../../../service/user.service';
import { AccountProfile } from '../../_models/accountprofile';

@Component({
  selector: 'app-migration-wizard',
  templateUrl: './migration-wizard.component.html',
  styleUrls: ['./migration-wizard.component.scss'],
  animations: absAnimations

})
export class MigrationWizardComponent implements OnInit {

  @Input()
  profileId: any;

  @Input()
  userId: any;

  @Input()
  username: any;

  @Input()
  sso: boolean;


  domain: any;
  verificationForm: FormGroup;
  emailForm: FormGroup;
  companyForm: FormGroup;
  passwordForm: FormGroup;

  isUserFreeDomain = false;
  isManagerFreeDomain = false;

  managerForm: FormGroup;
  manager: any;
  companyId: any;

  verificationEmailSent = false;
  verificationCode = null;
  isEmailVerified = false;

  managerNotFound = false;
  managerPhonePanel = false;
  managerEmailPanel = false;

  showCompany = false;

  showPassword = false;
  hidePassword = true;

  step = 0;
  constructor(private _formBuilder: FormBuilder,
    private _registrationService: RegistrationService,
    private _userService: UserService,
    private _progressBarService: AbsProgressBarService,
    private _selectService: SelectService,
    private authenticationService: AuthenticationService,
    private _companyService: CompnayService,
    private router: Router, ) { }

  ngOnInit() {
    this.verificationForm = this._formBuilder.group({
      verificationCode: ['', [Validators.required]],
      verificationPhone: [{ disabled: true, value: '' }, [Validators.required]]
    });

    this.emailForm = this._formBuilder.group({
      email: [this.username, [Validators.required]],
    });

    this.companyForm = this._formBuilder.group({
      company: ['', [Validators.required]],
    });

    this.managerForm = this._formBuilder.group({
      managerEmail: ['', [Validators.required, Validators.email]],
    });


    this.passwordForm = this._formBuilder.group({
      password: ['', Validators.required],
      passwordConfirm: ['', [Validators.required, confirmPasswordValidator]]
    });

    this.managerForm.controls['managerEmail'].valueChanges
      .pipe(
        debounceTime(1000),
        distinctUntilChanged()
      )
      .subscribe(e => {
        this.findManager()
      });
  }

  sendVerificationEmail() {

    var username = this.emailForm.controls.email.value;

    if (username.indexOf("@") != -1 && username.indexOf(".") != -1) {
      var domain = username.substring(username.lastIndexOf("@") + 1);
      this._companyService.checkIfFreeDomain(domain, "migrtion").subscribe(r => {
        console.log(r);
        if (r == 1) {
          this.isUserFreeDomain = true;
        }
      })
    }
    this._progressBarService.show();
    this._registrationService.sendVerificationEmail(this.emailForm.controls.email.value, this.emailForm.controls.email.value, this.companyId).subscribe(resp => {
      if (resp) {
        this.step = 2;
        this._progressBarService.hide();
        this.verificationCode = resp;
        this.verificationEmailSent = true;
      }
    });

  }

  validateVerificationCode() {
    this._progressBarService.show();
    this._registrationService.validateVerificationCode(this.verificationForm.controls.verificationCode.value, this.emailForm.controls.email.value).subscribe(resp => {
      this._progressBarService.hide();
      if (resp) {
        this.isEmailVerified = true;
        this.step = 3;
      } else {
        this.verificationForm.controls.verificationCode.setErrors({ 'incorrect': true })
        this.verificationForm.controls.verificationCode.markAsTouched();
        this.isEmailVerified = false;
      }
    });
  }

  findManager() {
    if (this.managerForm.valid) {
      this._progressBarService.show();
      this._registrationService.findManager(this.managerForm.controls.managerEmail.value, 'email').subscribe(resp => {
        if (resp && resp.length > 0) {
          this.manager = resp[0];
          this._progressBarService.hide();
          this.managerNotFound = false;
        } else {
          this.manager = null;
          this._progressBarService.hide();
          this.managerNotFound = true;

        }
      });
    }
  }


  managerContinue() {

    var username = this.managerForm.controls.managerEmail.value

    if (username.indexOf("@") != -1 && username.indexOf(".") != -1) {
      var domain = username.substring(username.lastIndexOf("@") + 1);
      this._companyService.checkIfFreeDomain(domain, "migrtion").subscribe(r => {
        console.log(r);
        console.log(this.isUserFreeDomain);
        if (r == 1 && this.isUserFreeDomain) {
          this.showCompany = true;
          this.step = 4;
        } else {
          if (this.sso) {
            this.saveInformation();
          } else {
            this.showCompany = false;
            this.step = 5;
            this.showPassword = true;
          }
        }
      })
    }
  }

  companyContinue() {

    this.showPassword = true;
    this.step = 5;

    var value = this.companyForm.controls.company.value;

    if (value.indexOf("http") != -1) {
      this.domain = value.replace(/^https?\:\/\//i, "");
    } else {
      if (value.indexOf("@") != -1 && value.indexOf(".") != -1) {
        this.domain = value.substring(value.lastIndexOf("@") + 1);
      }
    }
  }

  toggleShowHidePassword(e: any) {
    if (this.hidePassword) {
      this.hidePassword = false;
    } else {
      this.hidePassword = true;
    }
  }

  finishClicked() {
    this.saveInformation();
  }

  saveInformation() {
    this._progressBarService.show();

    var accountProfile = new AccountProfile({});

    accountProfile.email = this.emailForm.controls.email.value;
    accountProfile.updateLoginId = true;
    if (this.sso) {
      accountProfile.password = "temp/123";
    } else {
      accountProfile.password = this.passwordForm.controls.password.value;

    }
    accountProfile.profileId = this.profileId;
    accountProfile.userId = this.userId;
    accountProfile.domain = this.domain;
    if (this.manager) {
      accountProfile.managerId = this.manager.profileId;
    }

    this._userService.updateAccountProfileMigration(accountProfile).subscribe(response => {
      this._progressBarService.hide();
      if (response) {
        if (this.sso) {
          this.authenticationService.ssoLogin(this.profileId).subscribe(res => {
            if (res) {
              this.router.navigate(['/home']);
            }
          });
        } else {
          this.signIn(accountProfile.email, accountProfile.password, this.profileId);
        }
      }
    });;
  }

  signIn(email, password, profileId) {
    this._progressBarService.show();
    this.authenticationService.login(email, password, profileId)
      .pipe(first())
      .subscribe(
        data => {
          this._progressBarService.hide();
          this.router.navigate(['account/profile']);
        },
        error => {

        });
  }

  checkIfFreeDomain(username): boolean {
    var result = false;

    console.log(result);

    return result;
  }

}

/**
 * Confirm password validator
 *
 * @param {AbstractControl} control
 * @returns {ValidationErrors | null}
 */
export const confirmPasswordValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {

  if (!control.parent || !control) {
    return null;
  }

  const password = control.parent.get('password');
  const passwordConfirm = control.parent.get('passwordConfirm');

  if (!password || !passwordConfirm) {
    return null;
  }

  if (passwordConfirm.value === '') {
    return null;
  }

  if (password.value === passwordConfirm.value) {
    return null;
  }

  return { passwordsNotMatching: true };
};
