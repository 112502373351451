import { Component, OnInit, ViewChild, ViewChildren, ViewEncapsulation, SimpleChanges, OnDestroy, QueryList } from '@angular/core';
import { AssignPreviewComponent } from '../assign/assign-preview/assign-preview.component';
import { Group } from '../../user/groups/groups-all/group.model';
import { User, UserLevel } from '../../models/user';
import { Playlist } from '../../content/playlists/playlists.model';
import { TrainingService } from '../_services/training.service';
import { environment } from 'environments/environment';
import { AbsProgressBarService } from '@abs/components/progress-bar/progress-bar.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as moment from 'moment';
import { GroupsService } from '../../user/groups/groups.service';
import { Content } from '../../content/content-list.model';
import { ContentService } from '../../content/content.service';
import { SwiperConfigInterface, SwiperComponent } from 'ngx-swiper-wrapper';
import { SelectService } from '../../service/select.service';
import { AbsUtils } from '@abs/utils';
import { absAnimations } from '@abs/animations';
import { Subject } from 'rxjs';
import { takeUntil, map } from 'rxjs/operators';
import { AuthenticationService, CompnayService } from '../../authentication/_services';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { CartItem, ShoppingCartService } from '../../auto-training/cart.service';
import { SelectionModel } from '@angular/cdk/collections';
import { ActionBarService } from '../../../layout/components/action-bar/action-bar.service';
import { TrainingStyle, TrainingTrigger } from '../_models/training';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { ActivityLog, ActivityType, ActivityLogService } from '../../service/activity-log.service';
import { MatAccordion } from '@angular/material/expansion';
import { AbsPerfectScrollbarDirective } from '@abs/directives/abs-perfect-scrollbar/abs-perfect-scrollbar.directive';
import { MatDialog } from '@angular/material/dialog';
import { ShoppingExperienceDialogComponent } from '../../widgets/shopping-experience-dialog/shopping-experience-dialog.component';
import { PurchaseViewDialogComponent } from './purchase-view-dialog/purchase-view-dialog.component';
import { CalendarDialogComponent } from '../calendar-dialog/calendar-dialog.component';

export class AutomatedSchedule {
  date: any;
  playlist: any;
}

export class Frequency {
  value: any;
  text: any;
}

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'LL',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
@Component({
  selector: 'app-assign-wizard',
  templateUrl: './assign-wizard.component.html',
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
  styleUrls: ['./assign-wizard.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: absAnimations

})
export class AssignWizardComponent implements OnInit, OnDestroy {

  @ViewChild('viewsAcc') viewsAccordion: MatAccordion;
  @ViewChild('swiper') swiper: SwiperComponent;
  @ViewChild('swiper3') swiperTrigger: SwiperComponent;
  @ViewChild('swiper1', { static: true }) swiperOptions: SwiperComponent;
  @ViewChild('swiperClass') swiperClassOptions: SwiperComponent;
  @ViewChild('swiperInd') swiperIndOptions: SwiperComponent;

  @ViewChildren('swipers') swippers: QueryList<SwiperComponent>;


  readonly TrainingStyle = TrainingStyle;
  readonly TrainingTrigger = TrainingTrigger;
  endTimeString = "";
  selectedCard = "";
  flip = false;
  isTrainingScheduled = false;
  unit: any;
  valueLabel: any;
  valueInDays: any;
  freq: any;
  public config: SwiperConfigInterface = {
    observer: true,
    //width: 400,
    init: true,
    direction: 'horizontal',
    spaceBetween: 0,
    slidesPerView: 1,
    centeredSlides: true,
    noSwiping: true,
    allowTouchMove: false,
    navigation: {
      nextEl: '.swiper-next',
      prevEl: '.swiper-prev',
    },
    breakpoints: {
      // when window width is <= 1440px
      1024: {
        slidesPerView: 1
      }
    }
  };

  automatedSchedule: AutomatedSchedule[];
  showSwiper = false;
  trainingTrigger: TrainingTrigger;
  selectedTrigger = 'N';
  length: any;
  sliderIndex = -1;
  cartState$ = this.shoppingCartService.state$;
  cartItems = new SelectionModel<CartItem>(true, []);
  frequencySelction = new SelectionModel<Frequency>(false, []);

  showEndDateInvalid = false;
  hideAcquireViewsSection = 'N';
  isValidFormInformation = false;
  isValidOptionInformation = false;
  ordinal = ['First', 'Second', 'Third', 'Fourth', 'Last']
  requiredViews: any;
  frequency: any;
  userSettings: any;
  companyDefaults: any;
  title: any;
  selectedDate: any;
  startDate: any;
  endDate: any;
  originalEndDate: any;

  startTime: any;
  endTime: any;

  sendInvites: any;
  graceDays: any;
  timeToComplete: any;
  timeToCompleteLabel: any;
  sendReminders: any = 2;
  location: any;
  trainer: any;
  fastForward: any;
  trainingStyle: any;

  locations: any;
  trainers: any;
  value = 0;

  showViewConsumtion = true;

  isWizard = false;
  content: Playlist[] = [];
  selectedGroups: Group[] = [];
  selectedUsers: User[] = [];
  selectedPlaylist: Playlist[] = [];
  selectedContent: Content[] = [];
  frequencyList: any;
  totalEstimatedTime: number = 0;
  averageTimePerCourse: number = 0;
  automatedItems: any;
  scheduleData: any = {};
  environment: any;
  schedulingDone = false;
  inviteSendDate: any;
  step = 1;
  minDate: any;
  minEndDate: any;
  autoRepeat: any;
  maxDate: any;
  assignMode: any = 'INIT';
  simpleTrainingMode: any = '';

  frequencyDay: any;
  frequencyWeek: any;

  firstReminder
  secondReminder
  thirdReminder


  private _unsubscribeAll: Subject<any>;


  constructor(public trainingService: TrainingService,
    private _progressBarService: AbsProgressBarService,
    private activityLog: ActivityLogService,
    private _groupService: GroupsService,
    private _contentSercie: ContentService,
    private _selectService: SelectService,
    private _companyService: CompnayService,
    private _authService: AuthenticationService,
    public _snackBar: MatSnackBar,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private shoppingDialog: MatDialog,
    private calendarDialog: MatDialog,
    private purchaseViewDialog: MatDialog,
    public shoppingCartService: ShoppingCartService,
    private actionBarService: ActionBarService) {

    this.environment = environment;
    this._unsubscribeAll = new Subject();

    this.activatedRoute.queryParams.subscribe(params => {
      const op = params['op'];
      if (op == 'group') {
      }
    });

    this.cartState$.pipe(map(item => {
      let total = 0;
      for (const i of item.cart) {
        this.cartItems.select(i);
      }
    }))

  }

  public formatMinute(unit): string {
    if (typeof unit == 'string') {
      if (unit.length == 1) {
        return '0' + String(unit);
      } else {
        return String(unit);
      }
    } else {
      if (unit === 0) {
        return '00';
      } else if (unit < 10) {
        return '0' + String(unit);
      } else {
        return String(unit);
      }
    }
  }

  ngOnInit() {
    if (this._authService.currentUserValue.providerId == 10085) {
      this.showViewConsumtion = false;
      this.hideAcquireViewsSection = 'Y';
    }

    this.getLocations();
    this.getTrainers();

    this.minDate = moment();
    this.minEndDate = moment().add(1, 'days');
    this.maxDate = moment().add(1, 'days');

    this._groupService.onSelectedGroupsChanged
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(groups => {
        if (groups) {
          console.log("GROUPS", groups);
          this.selectedUsers = [];
          this.selectedGroups = [];
          groups.map(row => {
            // if (row.userList) {
            let ind = this.checkIfGroupAlreadySelected(row);
            if (!ind) {
              this.selectedGroups.push(row);
            }
            //}

          });
        }
      });

    this._groupService.onSelectedUsersChanged
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(users => {
        this.selectedUsers = [];
        if (users) {

          users.map(user => {
            let ind = this.checkIfUserAlreadySelected(user);
            if (!ind) {
              this.selectedUsers.push(user);
            }
          })

          this.selectedGroups.forEach(function (group) {
            var count = 0;
            users.forEach(function (u) {
              if (u.selectedGroup == group.groupID) {
                count++;
              }
            })
            group.selectedUserCount = count;
          });


        }
      });

    this._contentSercie.onSelectedPlaylistChanged
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(playlists => {
        console.log("selected playlist assign", playlists);
        this.totalEstimatedTime = 0;
        this.averageTimePerCourse = 0;
        if (playlists) {
          this.selectedPlaylist = playlists;
          var totalCourseCount = 0;
          this.selectedPlaylist.map(playlist => {
            if (playlist.contentList) {
              totalCourseCount = totalCourseCount + playlist.contentList.length;
            }
            this.totalEstimatedTime = this.totalEstimatedTime + playlist.playlistTime;
          })
          this.averageTimePerCourse = this.totalEstimatedTime / totalCourseCount;
          if (this.selectedTrigger != 'N') {
            if (this.selectedTrigger == 'NEW_HIRE' && this.selectedPlaylist && this.selectedPlaylist.length > 0) {
              this.title = "New Hire:" + this.selectedPlaylist[0].name
            } else {
              if (this.selectedGroups && this.selectedPlaylist && this.selectedPlaylist.length > 0) {
                this.title = this.selectedGroups[0].name + " - " + this.selectedPlaylist[0].name;
              }
            }
          }
          this.step = 2;
        }
      });


    this._contentSercie.onSelectedContentChanged
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(contents => {
        console.log("contents changed", contents);
        if (contents) {
          this.selectedContent = [];
          this.totalEstimatedTime = 0;
          this.averageTimePerCourse = 0;
          this.selectedPlaylist.forEach(function (playlist) {
            var count = 0;
            contents.forEach(function (c) {
              if (playlist.id == c.playlistId) {
                count++;
              }
            })
            playlist.selectedContentCount = count;
          });

          contents.map(content => {
            console.log("content", content);
            let ind = this.checkIfAlreadySelected(content);
            console.log(ind);
            if (!ind) {
              this.selectedContent.push(content);
              this.totalEstimatedTime = this.totalEstimatedTime + content.runningTime;
            }
          })
          this.averageTimePerCourse = this.totalEstimatedTime / this.selectedContent.length;
        }
      });


    /*   this.trainingService.scheduleData.subscribe(data => {
        console.log("schdule", data)
        this.scheduleData = data;
        this.inviteSendDate = moment(this.scheduleData.startDate).subtract(this.scheduleData.sendInvites, 'd');
      }) */

    this._companyService.getCompanySettingByCompanyId(this._authService.currentUserValue.companyId).subscribe(res => {
      console.log(res);
      this.companyDefaults = res;

      if (this._authService.currentUserValue.settings) {

        this.userSettings = this._authService.currentUserValue.settings;
        let passingScore = this.getValue(this.userSettings, 'DEFAULT_PASSING_SCORE');
        if (passingScore && passingScore.length > 0) {
          this.value = passingScore[0].value;
        } else {
          let passingScore = this.getValue(this.companyDefaults, 'DEFAULT_PASSING_SCORE');
          if (passingScore && passingScore.length > 0) {
            this.value = passingScore[0].value;
          } else {
            var defaults = JSON.parse(localStorage.getItem("system_defaults"));
            console.log("defaults", defaults);
            if (defaults) {
              let DEFAULT_PASSING_SCORE = AbsUtils.filterArrayByString(defaults, "DEFAULT_PASSING_SCORE");
              if (DEFAULT_PASSING_SCORE && DEFAULT_PASSING_SCORE.length > 0) {
                this.value = DEFAULT_PASSING_SCORE[0].value;
              } else {
                this.value = 80;
              }
            }
          }
        }

        let graceDays = this.getValue(this.userSettings, 'DEFAULT_GRACE_DAYS');
        console.log("user", graceDays);
        if (graceDays && graceDays.length > 0) {
          this.graceDays = graceDays[0].value;
        } else {
          let graceDays = this.getValue(this.companyDefaults, 'DEFAULT_GRACE_DAYS');
          console.log(graceDays)
          if (graceDays && graceDays.length > 0) {
            this.graceDays = graceDays[0].value;
          } else {
            var defaults = JSON.parse(localStorage.getItem("system_defaults"));
            console.log("defaults", defaults);
            if (defaults) {
              let DEFAULT_GRACE_DAYS = AbsUtils.filterArrayByString(defaults, "DEFAULT_GRACE_DAYS");
              if (DEFAULT_GRACE_DAYS && DEFAULT_GRACE_DAYS.length > 0) {
                this.graceDays = DEFAULT_GRACE_DAYS[0].value;
              } else {
                this.graceDays = 1;
              }
            }
          }
        }


        let timeToComplete = this.getValue(this.userSettings, 'DEFAULT_TIME_TO_COMPLETE');
        if (timeToComplete && timeToComplete.length > 0) {
          this.timeToComplete = timeToComplete[0].value;
        } else {
          let timeToComplete = this.getValue(this.companyDefaults, 'DEFAULT_TIME_TO_COMPLETE');
          if (timeToComplete && timeToComplete.length > 0) {
            this.timeToComplete = timeToComplete[0].value;
          } else {
            var defaults = JSON.parse(localStorage.getItem("system_defaults"));
            if (defaults) {
              let timeToComplete = AbsUtils.filterArrayByString(defaults, "DEFAULT_TIME_TO_COMPLETE");
              if (timeToComplete && timeToComplete.length > 0) {
                this.timeToComplete = timeToComplete[0].value;
              } else {
                this.timeToComplete = 7;
              }
            }
          }
        }

        let sendInvites = this.getValue(this.userSettings, 'DEFAULT_SEND_INVITES_DAYS');
        console.log("user in", sendInvites);

        if (sendInvites && sendInvites.length > 0) {
          this.sendInvites = sendInvites[0].value;
        } else {
          let sendInvites = this.getValue(this.companyDefaults, 'DEFAULT_SEND_INVITES_DAYS');
          console.log(sendInvites)
          if (sendInvites && sendInvites.length > 0) {
            this.sendInvites = sendInvites[0].value;
          }
        }


        let sendReminders = this.getValue(this.userSettings, 'DEFAULT_SEND_REMINDER_DAYS');

        if (sendReminders && sendReminders.length > 0) {
          this.sendReminders = sendReminders[0].value;
        } else {
          let sendReminders = this.getValue(this.companyDefaults, 'DEFAULT_SEND_REMINDER_DAYS');
          if (sendReminders && sendReminders.length > 0) {
            this.sendReminders = sendReminders[0].value;
          }
        }


      } else {
        let passingScore = this.getValue(this.companyDefaults, 'DEFAULT_PASSING_SCORE');
        if (passingScore && passingScore.length > 0) {
          this.value = passingScore[0].value;
        } else {
          var defaults = JSON.parse(localStorage.getItem("system_defaults"));
          console.log("defaults", defaults);
          if (defaults) {
            let DEFAULT_PASSING_SCORE = AbsUtils.filterArrayByString(defaults, "DEFAULT_PASSING_SCORE");
            if (DEFAULT_PASSING_SCORE && DEFAULT_PASSING_SCORE.length > 0) {
              this.value = DEFAULT_PASSING_SCORE[0].value;
            } else {
              this.value = 80;
            }
          }
        }

        let graceDays = this.getValue(this.companyDefaults, 'DEFAULT_GRACE_DAYS');
        console.log(graceDays)
        if (graceDays && graceDays.length > 0) {
          this.graceDays = graceDays[0].value;
        } else {
          var defaults = JSON.parse(localStorage.getItem("system_defaults"));
          console.log("defaults", defaults);
          if (defaults) {
            let DEFAULT_GRACE_DAYS = AbsUtils.filterArrayByString(defaults, "DEFAULT_GRACE_DAYS");
            if (DEFAULT_GRACE_DAYS && DEFAULT_GRACE_DAYS.length > 0) {
              this.graceDays = DEFAULT_GRACE_DAYS[0].value;
            } else {
              this.graceDays = 1;
            }
          }
        }

        let timeToComplete = this.getValue(this.companyDefaults, 'DEFAULT_TIME_TO_COMPLETE');
        if (timeToComplete && timeToComplete.length > 0) {
          this.timeToComplete = timeToComplete[0].value;
        } else {
          var defaults = JSON.parse(localStorage.getItem("system_defaults"));
          if (defaults) {
            let timeToComplete = AbsUtils.filterArrayByString(defaults, "DEFAULT_TIME_TO_COMPLETE");
            if (timeToComplete && timeToComplete.length > 0) {
              this.timeToComplete = timeToComplete[0].value;
            } else {
              this.timeToComplete = 7;
            }
          }
        }
        this.timeToCompleteLabel = this.timeToComplete + " Days";

        console.log("time", this.timeToComplete);

        let sendInvites = this.getValue(this.companyDefaults, 'DEFAULT_SEND_INVITES_DAYS');
        console.log(sendInvites)
        if (sendInvites && sendInvites.length > 0) {
          this.sendInvites = sendInvites[0].value;
        } else {
          var defaults = JSON.parse(localStorage.getItem("system_defaults"));
          if (defaults) {
            let sendInvites = AbsUtils.filterArrayByString(defaults, "DEFAULT_SEND_INVITES_DAYS");
            if (sendInvites && sendInvites.length > 0) {
              this.sendInvites = sendInvites[0].value;
            } else {
              this.sendInvites = 3;
            }
          }
        }


        let sendReminders = this.getValue(this.companyDefaults, 'DEFAULT_SEND_REMINDER_DAYS');
        if (sendReminders && sendReminders.length > 0) {
          this.sendReminders = sendReminders[0].value;
        } else {
          var defaults = JSON.parse(localStorage.getItem("system_defaults"));
          if (defaults) {
            let sendReminders = AbsUtils.filterArrayByString(defaults, "DEFAULT_SEND_REMINDER_DAYS");
            if (sendReminders && sendReminders.length > 0) {
              this.sendReminders = sendReminders[0].value;
            } else {
              this.sendReminders = 3;
            }
          }
        }


        let hideAcquireViewsSection = this.getValue(this.companyDefaults, 'HIDE_ACQUIRE_VIEWS_SECTION');
        console.log("HIDE_ACQUIRE_VIEWS_SECTION", hideAcquireViewsSection)
        if (hideAcquireViewsSection && hideAcquireViewsSection.length > 0) {
          this.hideAcquireViewsSection = hideAcquireViewsSection[0].value;
        }

      }
    })

  }

  graceDaysChanged(e) {
    this.graceDays = e.value;
  }

  setAssignMode(mode) {
    this.assignMode = mode;
  }
  setSimpleTrainingMode(mode) {
    this.simpleTrainingMode = mode;
  }


  scheduleTraining() {
    this._progressBarService.show();
    console.log("this", this)

    if (this.frequency) {
      this.scheduleData.frequency = this.frequency.value;
      this.scheduleData.frequencyDay = this.frequencyDay;
      this.scheduleData.frequencyWeek = this.frequencyWeek;
      this.scheduleData.repeat = this.autoRepeat;
    }

    if (this.selectedTrigger == 'N') {

      let userList = this.selectedUsers.map(user => {
        return ({ userId: user.profileId, userType: 'profile' })
      })
      let contentList = [];
      this.scheduleData.type = this.trainingStyle;
      this.scheduleData.profileID = JSON.parse(localStorage.getItem("currentUser")).profileId;
      this.scheduleData.passingScore = this.value;


      if (this.trainingStyle == TrainingStyle.CLASSROOM) { //Classroom

        if (this.location && this.location.id) {
          this.scheduleData.locationID = this.location.id;
        }
        this.scheduleData.startDate = this.selectedDate.format("YYYY-MM-DD");
        if (this.startTime) {
          this.scheduleData.startTime = this.formatTime(this.startTime);
        }

        if (this.endTime) {
          this.scheduleData.endTime = this.formatTime(this.endTime);
        }
        this.scheduleData.trainerId = this.trainer.profileId;
        this.scheduleData.sendInvites = this.sendInvites;

      } else if (this.trainingStyle == TrainingStyle.INDIVIDUAL) { //INDIVIDUAL

        this.scheduleData.startDate = this.startDate.format("YYYY-MM-DD");
        if (this.endDate) {
          this.scheduleData.endDate = this.endDate.format("YYYY-MM-DD");
        }
        //this.scheduleData.sendInvites = this.sendInvites;
        this.scheduleData.graceDays = this.graceDays;
        this.scheduleData.fastForward = this.fastForward;

      }

      if (TrainingTrigger.AUTOMATED == this.trainingTrigger || TrainingTrigger.FREQUENT == this.trainingTrigger) {
        this.scheduleData.timeToComplete = this.timeToComplete;
        contentList = this.automatedSchedule.map(s => {
          return ({ trainId: s.playlist.id, trainType: 'product', courseStartDate: s.date,runningTime: s.playlist.runningTime })
        })
      } else {
        contentList = this.selectedContent.map(content => {
          return ({ trainId: content.id, trainType: 'product', coueseStartDate: this.scheduleData.startDate })
        })
      }

      this.scheduleData.title = this.title;
      this.scheduleData.notes = "hellow";
      this.scheduleData.users = userList
      this.scheduleData.courses = contentList;
      this.scheduleData.triggerType = this.trainingTrigger;
      this.scheduleData.trigger = this.selectedTrigger;

      if (this.trainingTrigger == TrainingTrigger.FREQUENT) {
        this.scheduleData.status = 'SCHEDULED';
      } else {
        this.scheduleData.status = 'STARTED';
      }

    } else {

      let contentList = [];
      this.scheduleData.type = this.trainingStyle
      this.scheduleData.profileID = JSON.parse(localStorage.getItem("currentUser")).profileId;
      this.scheduleData.passingScore = this.value;
      this.scheduleData.graceDays = this.graceDays;
      this.scheduleData.fastForward = this.fastForward;
      this.scheduleData.timeToComplete = this.timeToComplete;

      contentList = this.selectedContent.map(content => {
        return ({ trainId: content.id, trainType: 'product', coueseStartDate: this.scheduleData.startDate })
      })
      this.automatedItems = this.selectedGroups.map(g => {
        return ({ trainId: g.groupID, trainType: this.selectedTrigger })
      })

      this.scheduleData.title = this.title;
      this.scheduleData.notes = "OnBoarding:Trigger";
      this.scheduleData.users = [];
      this.scheduleData.courses = contentList;
      this.scheduleData.triggerType = this.trainingTrigger;
      this.scheduleData.trigger = this.selectedTrigger;
      this.scheduleData.automatedItems = this.automatedItems;
      this.scheduleData.triggerType = TrainingTrigger.AUTOMATED;
      

      if (this.trainingStyle == TrainingStyle.CLASSROOM) { //Classroom
        if(this.trainer){
          this.scheduleData.trainerId = this.trainer.profileId;
        }
        this.scheduleData.sendInvites = this.sendInvites;
        if (this.location && this.location.id) {
          this.scheduleData.locationID = this.location.id;
        }
      }


      if (this.trainingTrigger == TrainingTrigger.FREQUENT) {
        this.scheduleData.status = 'SCHEDULED';
      } else {
        this.scheduleData.status = 'STARTED';
      }
      if (this.startTime) {
        this.scheduleData.startTime = this.formatTime(this.startTime);
      }

      if (this.endTime) {
        this.scheduleData.endTime = this.formatTime(this.endTime);
      }

    }

    this.scheduleData.firstReminder = this.firstReminder
    this.scheduleData.secondReminder = this.secondReminder
    this.scheduleData.thirdReminder = this.thirdReminder

    this.trainingService.scheduleTraining(this.scheduleData).then(res => {
      this._progressBarService.hide();
      if (res == 1) {
        this.schedulingDone = true;
        if (!this.isWizard) {
          this.assignMode = 'INIT';
        }
        this._snackBar.open("Training has been scheduled for the selected users", 'Ok', {
          panelClass: 'green-bg',
          duration: 6000,
          horizontalPosition: 'right',
          verticalPosition: 'top',
          politeness: 'polite',
        });


        let activityLog = new ActivityLog();
        activityLog.profileID = JSON.parse(localStorage.getItem("currentUser")).profileId
        activityLog.type = "site";
        activityLog.source = "itrain";
        activityLog.activity = ActivityType.SCHEDULE;
        this.activityLog.saveActivityLog(activityLog).subscribe(res => { });

        this.resetValues();

      } else {
        this._snackBar.open("Something went wrong while scheduling training.", 'Ok', {
          panelClass: 'red-bg',
          duration: 3000,
          horizontalPosition: 'right',
          verticalPosition: 'top',
          politeness: 'polite',
        });

        let activityLog = new ActivityLog();
        activityLog.profileID = JSON.parse(localStorage.getItem("currentUser")).profileId
        activityLog.type = "site";
        activityLog.source = "itrain";
        activityLog.activity = ActivityType.SCHEDULE_ERROR;
        this.activityLog.saveActivityLog(activityLog).subscribe(res => { });
      }
    })
  }
  checkIfUserAlreadySelected(newUser) {
    return this.selectedUsers.find(user => user.profileId === newUser.profileId);
  }
  checkIfGroupAlreadySelected(newGroup) {
    return this.selectedGroups.find(group => group.groupID === newGroup.groupID);
  }

  findTrainer(trainers) {
    return trainers.find(t => t.profileId == this._authService.currentUserValue.profileId);
  }

  getTrainers() {
    this._companyService.getCompanyTrainers(this._authService.currentUserValue.companyId).subscribe(res => {
      console.log(res);
      this.trainers = res;
      this.trainer = this.findTrainer(res);
    })
  }
  getLocations() {
    this._selectService.getLocationsAll().subscribe(results => {
      this.locations = results;
    })
  }

  onDateSelect(event) {
    var date = event;
    var week = this.weekOfMonth(date);

    this.frequencyList = [
      { value: "D", text: "Daily" },
      { value: "W", text: "Weekly on " + date.format('dddd') },
      { value: "M", text: "Monthly on the " + week + " " + date.format('dddd') },
      { value: "Q", text: "Quarterly on the " + week + " " + date.format('dddd') },
      { value: "S", text: "Semi Annually on the " + week + " " + date.format('dddd') },
      { value: "A", text: "Annually on the " + week + " " + date.format('dddd') + " of June" }
    ];

    this.selectedDate = event;
    this.validateForm();
    this.slideNext();

  }

  onStartDateSelect(event) {

    var date = event;

    var week = this.weekOfMonth(date);
    this.frequencyList = [
      { value: "D", text: "Daily" },
      { value: "W", text: "Weekly on " + date.format('dddd') },
      { value: "M", text: "Monthly on the " + week + " " + date.format('dddd') },
      { value: "Q", text: "Quarterly on the " + week + " " + date.format('dddd') },
      { value: "S", text: "Semi Annually on the " + week + " " + date.format('dddd') },
      { value: "A", text: "Annually on the " + week + " " + date.format('dddd') + " of June" }
    ];

    this.startDate = event;
    this.validateForm();
    this.minEndDate = this.startDate;
    this.endDate = moment(this.startDate).add(7, 'days')
    // this.slideNext();
    // this.swiper.directiveRef.nextSlide()
  }

  onEndDateSelect(event) {
    console.log(event);
    this.endDate = event;
    this.validateForm();
    this.length = this.endDate.endOf('day').diff(this.startDate.startOf('day'), 'days');
    this.swiper.directiveRef.nextSlide()

  }
  startTimeChanged(event) {
    this.startTime = event;

    const guessHours = this.totalEstimatedTime + (this.selectedContent.length * 10);
    const hours = Math.floor(guessHours / 60);
    const minutesLeft = (Math.round((guessHours % 60) * 100) / 100).toFixed();
    const breakTimeInMinutes = hours >= 2 ? hours * 10 : 0;



    var estimatedEndTime = moment(this.formatTime(this.startTime), "hh:mm A").add(guessHours, 'minutes').format('hh:mm A')
    var estimatedHour = estimatedEndTime.split(":")[0];
    var estimatedMins = estimatedEndTime.split(":")[1].split(" ")[0];
    var meriden = estimatedEndTime.split(":")[1].split(" ")[1];
    if (estimatedMins.charAt(0) === '0') {
      estimatedMins = estimatedMins.substring(1);
    }

    var st = moment(this.formatTime(this.startTime), "hh:mm A");
    var et = moment(st, "hh:mm A").add(hours, 'hours').add(minutesLeft, 'minutes').add(breakTimeInMinutes, 'minutes');
    const remainder = 15 - (et.minute() % 15);
    et = moment(et, "hh:mm A").add(remainder, "minutes")
    this.endTimeString = et.format("hh:mm A");
    this.endTime = { hour: et.format('h'), minute: et.minutes() + "", meriden: (et.hour()) >= 12 ? 'PM' : 'AM', format: this.startTime.format }
    //    this.endTime = { hour: estimatedHour, minute: parseInt(estimatedMins), meriden: meriden, format: 12 }
    this.validateForm();

    var st = moment(this.formatTime(this.startTime), "hh:mm A");
    var et = moment(this.formatTime(this.endTime), "hh:mm A")

    var duration = moment.duration(et.diff(st));
    this.length = duration.asMinutes();
  }

  endTimeChanged(event) {
    this.endTime = event;
    console.log(this.endTime);
    //this.length = this.endDate.endOf('day').diff(this.startDate.startOf('day'), 'days')
    var st = moment(this.formatTime(this.startTime), "hh:mm A");
    var et = moment(this.formatTime(this.endTime), "hh:mm A")

    var duration = moment.duration(et.diff(st));
    this.length = duration.asMinutes();
    this.validateForm();
  }

  formatTime(time) {
    return `${this.formatMinute(time.hour)}:${this.formatMinute(time.minute)} ${time.meriden}`
  }
  checkIfAlreadySelected(newContent) {
    return this.selectedContent.find(content => content.id === newContent.id);
  }

  updateSelectedPlaylists(content) {
    console.log("updateSelectedPlaylists content", content)

    this.selectedPlaylist.forEach(function (playlist, idx) {
      var count = 0;
      console.log("updateSelectedPlaylists playlist", playlist)

      if (playlist.id == content.playlistId) {
        count++;
      }
      playlist.selectedContentCount = count;
    });
  }

  addItemToCart(item: CartItem) {

    item.quantity++;
    this.cartItems.select(item);
    this.shoppingCartService.addCartItem(item);

  }

  removeItem(type): void {
    if (type == 'L') {
      this.cartItems.selected.map(item => {
        if (item.viewType == 'LEARNER_VIEWS') {
          this.cartItems.deselect(item);
          item.quantity = 0;
          this.shoppingCartService.removeCartItem(item);
        }

      })
    }
    if (type == 'C') {
      this.cartItems.selected.map(item => {
        if (item.viewType == 'CLASSROOM_VIEWS') {
          this.cartItems.deselect(item);
          item.quantity = 0;
          this.shoppingCartService.removeCartItem(item);
        }

      })
    }
    if (type == 'Q') {
      this.cartItems.selected.map(item => {
        console.log("Q", item)
        if (item.viewType == 'QUIZ_VIEWS') {
          this.cartItems.deselect(item);
          item.quantity = 0;
          this.shoppingCartService.removeCartItem(item);
        }

      })
    }

  }

  setStep(step) {
    console.log(step);
    this.step = step;
    this.validateForm();
    if (this.step == 4) {

      if (this.trainingStyle == TrainingStyle.CLASSROOM) {
        this.swiperClassOptions.directiveRef.init();
        this.swiperClassOptions.directiveRef.update();
        this.swiperClassOptions.directiveRef.setIndex(0);
      } else {
        this.swiperIndOptions.directiveRef.init();
        this.swiperIndOptions.directiveRef.update();
      }


      // this.swiperOptions.directiveRef.setIndex(0);
    }
    if (this.step == 3) {
      if (this.selectedTrigger != 'N') {
        this.swiperTrigger.directiveRef.init();
        this.swiperTrigger.directiveRef.update();
      }
    }
  }
  validateForm() {
    console.log("sadasd");
    if (this.trainingStyle == TrainingStyle.CLASSROOM) { //Classroom

      if (!this.selectedDate || !this.startTime || !this.endTime || !this.location) {
        this.isValidFormInformation = false;
      } else {
        this.isValidFormInformation = true;
      }

      if (!this.trainer || !this.sendInvites || !this.value) {
        this.isValidOptionInformation = false;
      } else {
        this.isValidOptionInformation = true;

      }


    } else if (this.trainingStyle == TrainingStyle.INDIVIDUAL) { //Independent

      if (this.endDate < this.startDate) {
        this.isValidFormInformation = false;
      }

      if (!this.startDate || !this.endDate) {
        this.isValidFormInformation = false;
      } else {
        this.isValidFormInformation = true;
      }
      this.isValidOptionInformation = true;

      if (!this.graceDays || !this.fastForward || !this.value) {
        //this.isValidOptionInformation = false;
      } else {
        this.isValidOptionInformation = true;

      }

    }

    if (this.isValidOptionInformation && this.isValidFormInformation) {
      this.actionBarService.onScheduleItClick.next("selected");
    }
  }


  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
    this._groupService.onSelectedGroupsChanged.next(null);
    this._groupService.onSelectedUsersChanged.next(null);
    this._contentSercie.onSelectedContentChanged.next(null);
    this._contentSercie.onSelectedPlaylistChanged.next(null);


    this._groupService.userSelection.clear();
    this._groupService.groupSelection.clear();
    this._contentSercie.contentSelection.clear();
    this._contentSercie.playlistSelection.clear();

  }
  getValue(source, key) {
    return source.filter(s => s.name == key);
  }

  saveCart() {
    console.log("saved");
    this.actionBarService.onCartChanged.next(this.shoppingCartService.cartItems.selected.length);
    localStorage.setItem("cart", JSON.stringify(this.cartItems.selected));
    this._snackBar.open("Items are added to cart successfully.", 'Ok', {
      panelClass: 'green-bg',
      duration: 4000,
      horizontalPosition: 'right',
      verticalPosition: 'top',
      politeness: 'polite',
    });
  }
  onInputChange(e) {
    this.validateForm;
  }

  setTrainingTrigger(value) {
    this.trainingTrigger = value;
    this.sliderIndex = -2;
  }

  setTrainingStyle(value) {
    this.trainingStyle = value;
    this.sliderIndex = 0;
    this.showSwiper = true;
    var viewType = value == 'CLASSROOM' ? 'CLASSROOM_VIEWS' : 'LEARNER_VIEWS';

    var totalViewsNeeded = this.calculateTotalViewsNeeded();


    let viewModel = { companyId: this._authService.currentUserValue.companyId, type: viewType, requestedViews: totalViewsNeeded, profileId: this._authService.currentUserValue.profileId, providerId: this._authService.currentUserValue.providerId };

    this.trainingService.checkIfUserRequiredMoreView(viewModel).then(res => {
      /*  this.requiredViews = res;
       const dialogRef = this.purchaseViewDialog.open(PurchaseViewDialogComponent, {
         width: '500px',
         backdropClass: 'previewBackdrop',
         panelClass: 'helpPanel',
         disableClose: true
       });
       dialogRef.afterClosed()
         .subscribe((value) => {
           if(value =='S'){
             this.launchShoppingExperience();
           }
         });
  */

    })
    console.log(this.swippers);
  }

  launchShoppingExperience() {
    const dialogRef = this.shoppingDialog.open(ShoppingExperienceDialogComponent, {
      width: '95vw',
      height: '950vh',
      backdropClass: 'previewBackdrop',
      panelClass: 'helpPanel',
      disableClose: true
    });
    dialogRef.afterClosed()
      .subscribe(() => {

      });
  }
  endDateCard() {
    this.slideNext();
    this.swiper.directiveRef.nextSlide();
  }

  launchCalendar() {
    const dialogRef = this.shoppingDialog.open(CalendarDialogComponent, {
      width: '95vw',
      height: '950vh',
      backdropClass: 'previewBackdrop',
      panelClass: 'helpPanel',
      disableClose: true
    });
    dialogRef.afterClosed()
      .subscribe(() => {

      });
  }

  slideNext() {
    // this.swiper.directiveRef.nextSlide();
    this.sliderIndex++;
  }

  slidePrev() {
    //this.sliderIndex--;
    console.log("index", this.sliderIndex);
  }

  onFastForwardToggle(e) {
    this.validateForm();
  }
  resetValues() {
    this.step = 1;
    this.title = " ";
    this.selectedDate = null;
    this.startDate = null;
    this.endDate = null;
    this.startTime = null;
    this.endTime = null;
    this.trainingTrigger = null;
    this.autoRepeat = null;
    this.trainingStyle = null;
    this.selectedTrigger = 'N';
    this.location = null;
    this.frequency = null;
    this.autoRepeat = null;
    //this.value = 0;
    this.selectedGroups = [];
    this.selectedContent = [];
    this.selectedPlaylist = [];
    this.selectedUsers = [];

    this.isValidFormInformation = false;
    this.isValidOptionInformation = false;
    this.frequency = null;
    this.frequencySelction.clear();
    this._groupService.userSelection.clear();
    this._groupService.groupSelection.clear();
    this._contentSercie.contentSelection.clear();
    this._contentSercie.playlistSelection.clear();

    this.sliderIndex = -1;
    this.schedulingDone = false;
    //this.swiper.directiveRef.init();
    //this.swiper.directiveRef.update();
    //this.swiper.directiveRef.setIndex(0);
    this._groupService.onSelectedGroupsChanged.next(null);
    this._groupService.onSelectedUsersChanged.next([]);
    this._contentSercie.onSelectedContentChanged.next(null);
    this._contentSercie.onSelectedPlaylistChanged.next(null);
  }

  calculateTotalViewsNeeded() {
    var sum = 0

    return this.selectedUsers.length * this.selectedContent.length;

  }

  setAutoRepeat(value) {
    this.autoRepeat = value;
  }

  setFrequency(freq) {
    const self = this;
    const value = freq.value;
    this.frequencySelction.select(freq);
    this.automatedSchedule = [];
    this.frequency = freq;
    var date = moment();

    if (this.trainingStyle == TrainingStyle.CLASSROOM) { //Classroom
      date = moment(this.selectedDate);
    } else {
      date = moment(this.startDate);
    }

    date = moment(this.startDate);
    var newDate = date;
    this.selectedPlaylist.forEach(function (playlist, idx) {

      if (idx > 0) {
        if (value === 'D') {
          newDate = date.add(1, 'days')
        } else if (value === 'W') {
          newDate = date.add(1, 'weeks')
        }
        else if (value === 'M') {
          newDate = date.add(1, 'months');
        }
        else if (value === 'Q') {
          newDate = date.add(1, 'quarters');
        }
        else if (value === 'S') {
          newDate = date.add(6, 'months');
        }
        else if (value === 'A') {
          newDate = date.add(1, 'years').month(5);
        }
      }

      if (playlist.contentList && playlist.contentList.length > 0) {
          playlist.contentList.forEach(function (content, index) {
            if (index == 0) {
              self.automatedSchedule.push({ date: moment(date), playlist: content })
            } else {
              if (value === 'D') {
                newDate = date.add(1, 'days')
              } else if (value === 'W') {
                newDate = date.add(1, 'weeks')
              }
              else if (value === 'M') {
                newDate = date.add(1, 'months');
              }
              else if (value === 'Q') {
                newDate = date.add(1, 'quarters');
              }
              else if (value === 'S') {
                newDate = date.add(6, 'months');
              }
              else if (value === 'A') {
                newDate = date.add(1, 'years').month(5);
              }
              self.automatedSchedule.push({ date: moment(date), playlist: content })
            }
          })
      }
    })
  }

  handleUserSelectionCardClick() {
    if (this.assignMode == 'SIMPLE') {
      this.selectedCard = 'UserSelection'
    } else {

    }
  }

  handleContentSelectionCardClick() {
    if (this.assignMode == 'SIMPLE') {
      this.selectedCard = 'ContentSelection'
    } else {
      this.setStep(2);
    }
  }

  handleTrainingTypeClick() {
    if (this.assignMode == 'SIMPLE') {
      this.selectedCard = 'TrainingMethodSelection'
    } else {
      this.setStepAndCard(-1, 1)
    }
  }

  handleClassRoomDateClick() {
    if (this.assignMode == 'SIMPLE') {
      this.selectedCard = 'trainingdataClassroomDate'
    } else {
      this.setStepAndCard(3, 0)
    }
  }

  handleClassRoomStartTimeClick() {
    if (this.assignMode == 'SIMPLE') {
      this.selectedCard = 'trainingdataClassroomDate'
    } else {
      this.setStepAndCard(3, 1)
    }
  }

  handleClassRoomEndTimeClick() {
    if (this.assignMode == 'SIMPLE') {
      this.selectedCard = 'trainingdataClassroomDate'
    } else {
      this.setStepAndCard(3, 2)
    }
  }

  handleClassRoomLocationClick() {
    if (this.assignMode == 'SIMPLE') {
      this.selectedCard = 'trainingdataClassroomLocation'
    } else {
      this.setStepAndCard(3, 3)
    }
  }

  handleIndpendentStartDateClick() {
    if (this.assignMode == 'SIMPLE') {
      this.selectedCard = 'trainingdataIndpStartDate'
    } else {
      this.setStepAndCard(3, 0)
    }
  }
  handleIndpendentEndDateClick() {
    if (this.assignMode == 'SIMPLE') {
      this.selectedCard = 'trainingdataIndpEndDate'
    } else {
      this.setStepAndCard(3, 1)
    }
  }



  handleIndpendentRecFreStartDateClick() {
    if (this.assignMode == 'SIMPLE') {
      if (this.trainingTrigger == TrainingTrigger.FREQUENT) {
        this.selectedCard = 'trainingdataIndpFreqStartDate'
      } else {
        this.selectedCard = 'trainingdataIndpRecStartDate'

      }
    } else {
      this.setStepAndCard(3, 0)
    }
  }
  handleIndpendentRecFreClick() {
    if (this.assignMode == 'SIMPLE') {
      if (this.trainingTrigger == TrainingTrigger.FREQUENT) {
        this.selectedCard = 'trainingdataIndpFreq'
      } else {
        this.selectedCard = 'trainingdataIndpRecFreq'
      }
    } else {
      this.setStepAndCard(3, 2)
    }
  }


  handleOptionsTimeToClick() {
    if (this.assignMode == 'SIMPLE') {
      this.selectedCard = 'SaveAndActivateTimeToComplete'
    } else {
      this.setStepAndCard(4, 3)
    }
  }

  handleOptionsGraceDaysToClick() {
    if (this.assignMode == 'SIMPLE') {
      this.selectedCard = 'SaveAndActivateGraceDays'
    } else {
      this.setStepAndCard(4, 0)
    }
  }

  handleOptionsSendRemindersToClick() {
    if (this.assignMode == 'SIMPLE') {
      this.selectedCard = 'SaveAndActivateSendReminders'
    } else {
      this.setStepAndCard(4, 3)
    }
  }

  handleOptionsVideoPlayerToClick() {
    if (this.assignMode == 'SIMPLE') {
      this.selectedCard = 'SaveAndActivateVideoPlayer'
    } else {
      this.setStepAndCard(4, 1)
    }
  }

  handleOptionsPassingScoreToClick() {
    if (this.assignMode == 'SIMPLE') {
      this.selectedCard = 'SaveAndActivatePassingScore'
    } else {
      this.setStepAndCard(4, 2)
    }
  }
  handleOptionsTrainerToClick() {
    if (this.assignMode == 'SIMPLE') {
      this.selectedCard = 'SaveAndActivateTrainer'
    } else {
      this.setStepAndCard(4, 0)
    }
  }
  handleOptionsSendinvitesToClick() {
    if (this.assignMode == 'SIMPLE') {
      this.selectedCard = 'SaveAndActivateSendInvites'
    } else {
      this.setStepAndCard(4, 1)
    }
  }



  setStepAndCard(step, index) {
    console.log(step);

    if (this.assignMode == 'SIMPLE') {

    } else {
      this.step = step;
      if (step == -1) {
        this.step = 3;
        this.sliderIndex = -1;
        this.showSwiper = false;
      } else if (step == -2) {
        this.step = 3;
        this.sliderIndex = -2;
        this.showSwiper = false;
      } else {
        this.sliderIndex = 0;
        if (this.step == 4) {

          if (this.trainingStyle == TrainingStyle.CLASSROOM) {
            this.swiperClassOptions.directiveRef.init();
            this.swiperClassOptions.directiveRef.update();
            this.swiperClassOptions.directiveRef.setIndex(index);

          } else {
            this.swiperIndOptions.directiveRef.init();
            this.swiperIndOptions.directiveRef.update();
            this.swiperIndOptions.directiveRef.setIndex(index);

          }
        }
        if (this.step == 3) {
          // this.swiper.directiveRef.init();
          //this.swiper.directiveRef.update();
          this.swiper.directiveRef.setIndex(index);
        }
      }
    }
  }
  formatLabel(value: number) {
    if (value == 0) {
      return 'On';
    }

    return 'Off';
  }

  descClicked(value) {
    this.flip = value;
  }

  triggerChanged(value) {
    console.log("trigger value", value);
    this.selectedTrigger = value;
    this.trainingStyle = null;
    if (this.selectedTrigger != 'N') {
      //this.trainingStyle = TrainingStyle.INDIVIDUAL;
    }
  }

  onDataChange(event) {

    console.log("wizard data changed", event);

    if (event.type == 'TRIGGER_CHANGED') {
      this.triggerChanged(event.value);
    }
    if (event.type == 'TRAINING_MODE_CHANGED') {
      this.selectedTrigger  = 'N';
      this.trainingTrigger = event.value;
      this.selectedGroups = [];
      this.selectedContent =[];

    }
    if (event.type == 'TRAINING_STYLE_CHANGED') {
      this.trainingStyle = event.value
      this.step = 3;
    }
    if (event.type == 'INDEPENDENT_TRAINING_DATA_CHANGED') {

      this.startDate = event.startDate;
      this.endDate = event.endDate;
      this.length = event.length;
      this.originalEndDate = this.endDate;
      this.frequency = event.frequency;
      this.frequencyDay = event.freqDay;
      this.frequencyWeek = event.freqWeek;
      this.autoRepeat = event.repeat;

      if (event.automatedSchedule) {
        this.automatedSchedule = event.automatedSchedule;
      }
      if (this.length) {
        if (this.length == 1) {
          this.timeToCompleteLabel = this.length + " Day";
        } else {
          this.timeToCompleteLabel = this.length + " Days";
        }
      }
      this.trainingStyle = TrainingStyle.INDIVIDUAL;
    }
    if (event.type == 'CLASSROOM_TRAINING_DATA_CHANGED') {
      if (event.selectedDate) {
        this.selectedDate = event.selectedDate;
      }
      if (event.startTime) {
        this.startTime = event.startTime;
      }

      if (event.endTime) {
        this.endTime = event.endTime;
      }
      if (event.location) {
        this.location = event.location;
      }
      if (event.length) {
        this.length = event.length;
      }
      if (event.automatedSchedule) {
        this.automatedSchedule = event.automatedSchedule;
      }
      this.frequency = event.frequency;
      this.frequencyDay = event.freqDay;
      this.frequencyWeek = event.freqWeek;
      this.autoRepeat = event.repeat;
      if (this.length) {
        if (this.length == 1 && event.timeToComplete) {
          this.timeToCompleteLabel = event.timeToComplete + " Day";
        } else {
          this.timeToCompleteLabel = event.timeToComplete + " Days";
        }
      }

      this.trainingStyle = TrainingStyle.CLASSROOM;

    }
    if (event.type == 'OPTIONS_CHANGED') {
      this.step = 4;
      console.log("option changed", event);
      console.log("option changed", event.value.passingScore);
      if (event.value && event.value.timeToComplete) {
        this.timeToComplete = event.value.timeToComplete
        if (this.timeToComplete == 1) {
          this.timeToCompleteLabel = event.value.timeToComplete + " Day";
        } else {
          this.timeToCompleteLabel = event.value.timeToComplete + " Days";
        }

        if (this.endDate) {
          if (event.value.timeToCompleteUnit == 'days') {
            if (event.value.timeToComplete == 1) {
              this.endDate = moment(this.startDate);
            } else {
              this.endDate = moment(this.startDate).add(event.value.timeToComplete, 'days')

            }
          }
          /*  if (event.value.timeToCompleteUnit == 'weeks') {
             this.endDate.add(event.value.timeToComplete, 'week')
           }
           if (event.value.timeToCompleteUnit == 'months') {
             this.endDate.add(event.value.timeToComplete, 'month')
           } */
          this.length = this.endDate.endOf('day').diff(this.startDate.startOf('day'), 'days')
        }

      }

      if (event.value && event.value.graceDays) {
        this.graceDays = event.value.graceDays;
      }


      if (event.value && event.value.sendReminders) {
        this.sendReminders = event.value.sendReminders;

      }
      if (event.value && event.value.sendInvites) {
        this.sendInvites = event.value.sendInvites;
      }
      this.fastForward = event.value.videoPlayerControl;
      this.value = event.value.passingScore;

      if (event.value && event.value.sendReminders) {
        this.firstReminder = event.value.sendReminders
      }

      if (event.value && event.value.sendReminders2) {
        this.secondReminder = event.value.sendReminders2
      }

      if (event.value && event.value.sendReminders3) {
        this.thirdReminder = event.value.sendReminders3
      }
      if (event.value && event.value.trainerId) {
        this.trainer =  this.trainers.find(t => t.profileId == event.value.trainerId);
      }
    }
  }

  weekOfMonth(input) {
    const firstDayOfMonth = input.clone().startOf('month');
    const firstDayOfWeek = firstDayOfMonth.clone().startOf('week');

    const offset = firstDayOfMonth.diff(firstDayOfWeek, 'days');
    return this.ordinal[Math.ceil((input.date() + offset) / 7) - 1];
  }

  optionsNext() {
    this.swiperOptions.directiveRef.nextSlide();
  }

  optionsPrev() {
    this.swiperOptions.directiveRef.prevSlide();
  }

  onAssignWizardDone(title) {
    this.title = title;
    this.isWizard = true;
    this.scheduleTraining();
  }

  startOver() {
    this.resetValues();
    this.assignMode = 'INIT';
  }

  handleInputChange(input) {
    const value = this.unit
    if (value == 'days') {
      if (this.value == 1) {
        this.valueLabel = input + " Day";
      } else {
        this.valueLabel = input + " Days";
      }
      this.valueInDays = input;

    }
    if (value == 'weeks') {
      if (this.value == 1) {
        this.valueLabel = input + " Week";
      } else {
        this.valueLabel = input + " Weeks";
      }
      this.valueInDays = input * 7;

    }
    if (value == 'months') {
      if (this.value == 1) {
        this.valueLabel = input + " Month";
      } else {
        this.valueLabel = input + " Months";
      }
      this.valueInDays = input * 30;
    }
  }
}
