import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-kiosk-player-quiz-confirm-dialog',
  templateUrl: './kiosk-player-quiz-confirm-dialog.component.html',
  styleUrls: ['./kiosk-player-quiz-confirm-dialog.component.scss']
})
export class KioskPlayerQuizConfirmDialogComponent implements OnInit {

  constructor(public matDialogRef: MatDialogRef<KioskPlayerQuizConfirmDialogComponent>) { 
  }

  ngOnInit() {
  }

  onYesClick(){
    this.matDialogRef.close('yes');
  }

}
