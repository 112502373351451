import { Component, OnInit, Output, EventEmitter, Input, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { SelectionModel } from '@angular/cdk/collections';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AbsUtils } from '@abs/utils';
import { absAnimations } from '@abs/animations';
@Component({
  selector: 'app-video-player-selector',
  templateUrl: './video-player-selector.component.html',
  styleUrls: ['./video-player-selector.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: absAnimations,

})
export class VideoPlayerSelectorComponent implements OnInit {
  flip:any;

  @Input() default


  @Output() onBackButton = new EventEmitter<any>();
  @Output() onDone = new EventEmitter<any>();
  @Output() onSelection = new EventEmitter<any>();

  fastForward: any;
  constructor() { }

  ngOnInit(): void {
    this.fastForward = this.default ? 0 : 1;
  }


  onFastForwardToggle(user){
    console.log("fast forward",user);
    this.onSelection.emit({value:user.value == 0 ? true : false, type:'video-player'});
  }

  handleBack(){
    this.onBackButton.emit('video-player');
  }

  controlSelected(){
    console.log("fast forward",this.fastForward)
    this.onDone.emit({value:this.fastForward == 0 ? true : false, type:'video-player'});
  }
  descClicked(value) {
    this.flip = value;
  }

  formatLabel(value: number) {
    if (value == 0) {
      return 'On';
    }

    return 'Off';
  }
}
