import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EmailContentService } from '../../emails/email.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AbsProgressBarService } from '@abs/components/progress-bar/progress-bar.service';

@Component({
  selector: 'app-trigger-dialog',
  templateUrl: './trigger-dialog.component.html',
  styleUrls: ['./trigger-dialog.component.scss']
})
export class TriggerDialogComponent implements OnInit {

  form: FormGroup;
  firstName: string;
  lastName: string;



  /**
   * Constructor
   *
   * @param {MatDialogRef<GroupFormComponent>} matDialogRef
   * @param _data
   * @param {FormBuilder} _formBuilder
   */
  constructor(public matDialogRef: MatDialogRef<TriggerDialogComponent>,
    private _formBuilder: FormBuilder,
    private emailService: EmailContentService,
    private snackBar: MatSnackBar,
    private progressBarService: AbsProgressBarService) {

    this.form = this.createAddUserForm();

  }

  ngOnInit() {
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Create add user form
   *
   * @returns {FormGroup}
   */
  createAddUserForm(): FormGroup {
    return this._formBuilder.group({
      email: [''],
      phone: [''],

    });
  }

  submit() {
    this.trigger();
  }


  trigger() {
    //this.progressBarService.show();
    this.snackBar.open("Email trigger has been activated.", 'OK', {
      panelClass: 'green-bg',
      duration: 2000,
      horizontalPosition: 'right',
      verticalPosition: 'top',
      politeness: 'polite',
    });
    this.matDialogRef.close();

    this.emailService.triggerEmail(this.form.controls.email.value, this.form.controls.phone.value).then(res => {
     //this.progressBarService.hide();
     
    });
  }


}
