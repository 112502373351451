import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-wrong-file-type-selected',
  templateUrl: './wrong-file-type-selected.component.html',
  styleUrls: ['./wrong-file-type-selected.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class WrongFileTypeSelectedComponent implements OnInit {

  error: string;

  constructor(public matDialogRef: MatDialogRef<WrongFileTypeSelectedComponent>,@Inject(MAT_DIALOG_DATA) private _data: any) {
    this.error = _data.type;
   }

  ngOnInit(): void {
  }

}
