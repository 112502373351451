import { Component, OnInit, Inject } from '@angular/core';
import { environment } from 'environments/environment';
import { CourseResult } from '../next-course-dialog/next-course-dialog.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AbsProgressBarService } from '@abs/components/progress-bar/progress-bar.service';
import { TrainingService } from '../../_services/training.service';

@Component({
  selector: 'app-last-course-complete-dialog',
  templateUrl: './last-course-complete-dialog.component.html',
  styleUrls: ['./last-course-complete-dialog.component.scss']
})
export class LastCourseCompleteDialogComponent implements OnInit {


  private serverUrl = `${environment.apiUrl}/socket`;
  private stompClient;

  result: CourseResult;
  classId: any;
  allResults: any;
  courseTitle: any;

  constructor(public matDialogRef: MatDialogRef<LastCourseCompleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private _progressBarService: AbsProgressBarService,
    private _trainingService: TrainingService) {
    this.classId = _data.classId;
    this.courseTitle = _data.classTitle;
    this._trainingService.getTrainingCoursesResult(this.classId).then(result => {
      console.log("results");
      this.allResults = result;
    });

    this._trainingService.getTrainingClassResult(this.classId).then(result => {
      console.log("results",result);
      this.result = result;
    });
  }
  ngOnInit() {
    console.log("inint ");
  }

}
