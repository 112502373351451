import { Injectable } from '@angular/core';
import {IndexTiles} from './tile.model';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TileService {

  public tiles: IndexTiles[];

  constructor(private _httpClient: HttpClient) {
    this.getTiles();
  }

  getTiles() {
    this._httpClient.get('https://itrainstation.firebaseio.com/indexTiles.json')
      .pipe(map(responseData => {
        const indexTilesArray = [];
        for (const key in responseData) {
          if (responseData.hasOwnProperty(key)) {
            indexTilesArray.push({... responseData[key], id: key });
          }
        }
        return indexTilesArray;
      }))
      .subscribe(indexTiles => {
        console.log(indexTiles);
      });
  }
}
