import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { User, UserLevel } from '../../models/user';
import { IndexTiles } from '../../landing-page/tile.model';
import { ActivityLogService, ActivityLog, ActivityType } from '../../service/activity-log.service';
import { AbsNavigationService } from '@abs/components/navigation/navigation.service';
import { learnerNavigation, navigation } from '../../../navigation/navigation';
import * as moment from 'moment';

const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })
};


@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    token: string;
    public currentPublicUserSubject: BehaviorSubject<User>;
    public currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;
    public navigation: any;
    public currentUserLongDateFormat: any;
    public currentUserTimeZone: any;
    public currentUserTimeFormat: any;
    public currentUsetDateFormat: any;

    constructor(
        private router: Router,
        private http: HttpClient,
        private activtyLog: ActivityLogService,
        private _absNavigationService: AbsNavigationService) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentPublicUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentPublicUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): User {
        if (this.currentUserSubject.value) {
            this.currentUserLongDateFormat = this.currentUserSubject.value.longDateDisplayFormat + " @ " + this.currentUserSubject.value.timeFormat;
            if (this.currentUserSubject.value.timeZone) {
                let timeZone = this.currentUserSubject.value.timeZone;
                if (timeZone.indexOf("D") != -1) {
                    timeZone = timeZone.substring(1);
                }
                this.currentUserTimeZone = timeZone;
            }
        }
        return this.currentUserSubject.value;
    }

    public get currentPublicUserValue(): User {
        return this.currentPublicUserSubject.value;
    }


    validatePassword(username: string, password: string, profileId: number,type: string) {
        return this.http.post<any>(`${environment.apiUrl}/validate/password`, {type: type, 'username': username, 'password': password, 'profileId': profileId }).pipe(map(resp => {
            return resp;
        }));

    }

    login(username: string, password: string, organization: string) {
        return this.http.post<any>(`${environment.apiUrl}/login`, { 'username': username, 'password': password, 'profileId': organization })
            .pipe(map(user => {


                // login successful if there's a jwt token in the response
                if (user) {
                    console.log(user);

                    if (user.userLevel == UserLevel.LEARNER) {
                        this.navigation = learnerNavigation;
                    } else {
                        this.navigation = navigation;
                    }
                    // Register the navigation to the service
                    this._absNavigationService.unregister('main');
                    this._absNavigationService.register('main', this.navigation);
                    // Set the main navigation as our current navigation
                    this._absNavigationService.setCurrentNavigation('main');

                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('currentUser', JSON.stringify(user));
                    localStorage.setItem('help', "0");
                    this.currentUserSubject.next(user);

                    let activityLog = new ActivityLog();
                    activityLog.profileID = user.profileId;
                    activityLog.type = "site";
                    activityLog.source = "itrain";
                    activityLog.activity = ActivityType.LOGIN;
                    this.activtyLog.saveActivityLog(activityLog).subscribe(res => { });

                }

                return user;

            }));

    }

    publicLogin(profileId) {
        return this.http.post<any>(`${environment.apiUrl}/account/profile`, { 'profileId': profileId })
            .pipe(map(user => {
                // login successful if there's a jwt token in the response
                if (user) {
                    localStorage.setItem('currentPublicUser', JSON.stringify(user));
                    localStorage.setItem('help', "0");
                    this.currentPublicUserSubject.next(user);
                    let activityLog = new ActivityLog();
                    activityLog.profileID = user.profileId;
                    activityLog.type = "site";
                    activityLog.source = "itrain";
                    activityLog.activity = ActivityType.LOGIN_PUBLIC;
                    this.activtyLog.saveActivityLog(activityLog).subscribe(res => { });
                }
                return user;

            }));

    }



    ssoLogin(profileId) {
        return this.http.post<any>(`${environment.apiUrl}/account/profile`, { 'profileId': profileId })
            .pipe(map(user => {


                // login successful if there's a jwt token in the response
                if (user) {
                    console.log(user);
                    if (user.userLevel == UserLevel.LEARNER) {
                        this.navigation = learnerNavigation;
                    } else {
                        this.navigation = navigation;
                    }
                    // Register the navigation to the service
                    this._absNavigationService.unregister('main');
                    this._absNavigationService.register('main', this.navigation);
                    // Set the main navigation as our current navigation
                    this._absNavigationService.setCurrentNavigation('main');

                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('currentUser', JSON.stringify(user));
                    localStorage.setItem('help', "0");
                    this.currentUserSubject.next(user);

                    let activityLog = new ActivityLog();
                    activityLog.profileID = user.profileId;
                    activityLog.type = "site";
                    activityLog.source = "itrain";
                    activityLog.activity = ActivityType.LOGIN;
                    this.activtyLog.saveActivityLog(activityLog).subscribe(res => { });

                }
                return user;

            }));

    }

    getUserByProfileId(profileId) {
        return this.http.post<any>(`${environment.apiUrl}/account/profile`, { 'profileId': profileId })
            .pipe(map(user => {
                return user;
            }));
    }

    resetPassword(value: string, type: string, profileId) {
        return this.http.post<any>(`${environment.apiUrl}/reset/password`, { 'username': value, 'qureyString': type, 'profileId': profileId })
            .pipe(map(resp => {
                return resp;
            }));
    }

    identifyUser(username: string) {
        return this.http.post<any>(`${environment.apiUrl}/user/indentification`, { 'username': username })
            .pipe(map(resp => {
                return resp;
            }));
    }

    findByEmailOrPhone(value: string) {
        return this.http.post<any>(`${environment.apiUrl}/find/profile`, { 'username': value })
            .pipe(map(resp => {
                // login successful if there's a jwt token in the response
                if (resp) {
                    console.log("findByEmailOrPhone", resp)
                }
                return resp;
            }));
    }

    findByEmailOrPhoneIncludeSSO(value: string) {
        return this.http.post<any>(`${environment.apiUrl}/find/profile/sso`, { 'username': value })
            .pipe(map(resp => {
                // login successful if there's a jwt token in the response
                if (resp) {
                    console.log("findByEmailOrPhoneSSO", resp)
                }
                return resp;
            }));
    }

    getTiles() {
        return this.http.get<any[]>(`${environment.apiUrl}/tiles`, httpOptions).pipe(
            catchError(this.handleError)
        );;
    }

    getSystemDefaults() {
        return this.http.get<any[]>(`${environment.apiUrl}/system/defaults`, httpOptions).pipe(
            catchError(this.handleError)
        );;
    }

    logout() {

        let activityLog = new ActivityLog();
        activityLog.profileID = JSON.parse(localStorage.getItem("currentUser")).profileId
        activityLog.type = "site";
        activityLog.source = "itrain";
        activityLog.activity = ActivityType.LOGOUT;
        this.activtyLog.saveActivityLog(activityLog).subscribe(res => { });
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        localStorage.removeItem('help');
        localStorage.clear();
        this.currentUserSubject.next(null);
        this.router.navigate(['/authentication/login']);



        //this.router.
    }

    getValue(source, key) {
        return source.filter(s => s.name == key);
    }

    isAuthenticated() {
        return this.token != null;
    }

    validateToken(token: string, channel): Observable<any> {
        return this.http.post(`${environment.apiUrl}/validate/token`, { verificationCode: token, channel: channel }, httpOptions).pipe(catchError(this.handleError));
    }

    createSSOUser(firstName, lastName, username, policyId, providerId, companyId, companyDomain, domainHandlized) {
        return this.http.post(`${environment.apiUrl}/add/sso/user`, { firstName: firstName, lastName: lastName, username: username,email: username, policyId: policyId, companyDomain: companyDomain, companyDomainHandlized: domainHandlized, isStudent: false, providerHash: providerId, companyId: companyId }, httpOptions).pipe(catchError(this.handleError));
    }

    checkIfCompanyConverted(value) {
        return this.http.post(`${environment.apiUrl}/company/legacy`, { companyHash: value }, httpOptions).pipe(catchError(this.handleError));
    }

    checkIfUserMigrated(profileId) {
        return this.http.post(`${environment.apiUrl}/user/migrated`, { profileId: profileId }, httpOptions).pipe(catchError(this.handleError));
    }

    createSSOCompanyUser(username, policyId, providerHash, fn, ln) {
        return this.http.post(`${environment.apiUrl}/add/sso/company/user`, { email:username,username: username, policyId: policyId, isStudent: false, providerHash: providerHash, firstName: fn, lastName: ln }, httpOptions).pipe(catchError(this.handleError));
    }

    handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
                `Backend returned code ${error.status}, ` +
                `body was: ${error.error}`);
        }
        // return an observable with a user-facing error message
        return throwError(
            'Something bad happened; please try again later.');
    };
}
