import { Component, OnInit, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { absAnimations } from '@abs/animations';

@Component({
  selector: 'app-frequent-and-recurring-selector',
  templateUrl: './frequent-and-recurring-selector.component.html',
  styleUrls: ['./frequent-and-recurring-selector.component.scss'],
  animations: absAnimations,
  encapsulation: ViewEncapsulation.None,
})
export class FrequentAndRecurringSelectorComponent implements OnInit {

  flip:any;
  @Output() onSelection = new EventEmitter<any>();
  @Output() onBackButton = new EventEmitter<any>();


  constructor() { }

  ngOnInit(): void {
  }

  selectTrainingMethod(method){
    this.onSelection.emit(method);
  }
  handleBack(){
    this.onBackButton.emit('recurringMethod');
  }
  descClicked(value) {
    this.flip = value;
  }
}
