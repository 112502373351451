import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { Subject, Observable, of } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AbsConfigService } from '@abs/services/config.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatMenuTrigger } from '@angular/material/menu';
import { Content } from 'app/main/training/_models/playlist';
import { AbsUtils } from '../../utils';
import { environment } from 'environments/environment';
import { UsersService } from 'app/main/user/user.service';
import { User } from 'app/main/models/user';
import { ActivityLog, ActivityType, ActivityLogService } from 'app/main/service/activity-log.service';
import { AuthenticationService } from 'app/main/authentication/_services';
import { ContentService } from 'app/main/content/content.service';

@Component({
  selector: 'abs-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss']
})
export class AbsSearchBarComponent implements OnInit, OnDestroy {
  @ViewChild('menuTrigger') matMenuTrigger: MatMenuTrigger;
  @ViewChild('searchInput') searchEle: ElementRef;
  collapsed: boolean;
  absConfig: any;
  autoCompleteContent: Observable<SearchResult[]>;
  contents: Content[] = [];
  allUsers: User[];
  environment: any;
  @Output()
  input: EventEmitter<any>;

  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {AbsConfigService} _absConfigService
   */
  constructor(
    private _absConfigService: AbsConfigService,
    private route: ActivatedRoute,
    private router: Router,
    private usersService: UsersService,
    private activityLog: ActivityLogService,
    private authService: AuthenticationService,
    private contentService: ContentService
  ) {
    this.environment = environment;
    // Set the defaults
    this.input = new EventEmitter();
    this.collapsed = true;

    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {


    if (this.authService.currentUserValue) {

      this.contentService.getVideos(1, 1000,  this.authService.currentUserValue.companyId).subscribe(res => {
        this.contents = res;
      });

      this.usersService.getUsers().then(users => {
        this.allUsers = users;
      });
    }
    // Subscribe to config changes
    this._absConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(
        (config) => {
          this.absConfig = config;
        }
      );
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Collapse
   */
  collapse(): void {
    this.collapsed = true;
  }

  /**
   * Expand
   */
  expand(): void {
    this.collapsed = false;
  }

  /**
   * Search
   *
   * @param event
   */
  search(event): void {
    this.input.emit(event.target.value);
    if (event.target.value.length > 2) {
      console.log(event.target.value);

      let activityLog = new ActivityLog();
      activityLog.profileID = JSON.parse(localStorage.getItem("currentUser")).profileId;
      activityLog.type = "site";
      activityLog.source = "itrain";
      activityLog.activity = ActivityType.SEARCH;
      this.activityLog.saveActivityLog(activityLog).subscribe(res => { });

      var videos = AbsUtils.filterArrayByString(this.contents, event.target.value);
      var users = AbsUtils.filterArrayByString(this.allUsers, event.target.value);
      var allResults = [];
      videos = videos.map(content => {
        var url = this.environment.cdnUrl + "/" + this.environment.thumbDir + "/T_" + content.itemCode + ".jpg";

        if(content.prodType!='VIDEO'){
          url = "assets/icons/"+ content.image;
        }

        allResults.push(new SearchResult(content.id, 'video', url, content.title,"avatar square",this.environment.cdnUrl + '/' + this.environment.thumbDir + '/T_.jpg'));
      })
      users = users.map(content => {
        allResults.push(new SearchResult(content.profileId, 'user',content.avatarImg, content.firstName + " " + content.lastName,"avatar","https://cdn.itrainstation.com/assets/images/avatars/Tyson.jpg",content.firstName, content.lastName));
      })
      console.log("all", allResults);
      this.autoCompleteContent = of(allResults);
    } else {
      this.autoCompleteContent = new Observable<SearchResult[]>();
    }
  }

  menuOpened() {
    this.searchEle.nativeElement.focus();

  }
  onEnter(event) {
    this.matMenuTrigger.closeMenu();
    this.redirectTo(event);

  }

  onSearch(value) {
    this.matMenuTrigger.closeMenu();

    if (value.type == 'user') {
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
        this.router.navigate(['/user/admin', { id: value.id }]));
    } else {
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
        this.router.navigate(['/content/all', { id: value.id }]));
    }
  }
  redirectTo(event) {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate(['/content/all', { keywords: event.target.value }]));
  }

  reloadComponent(event) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    //this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['/content/videos', { keywords: event.target.value }]);
  }

  onImgError(e,content){
    e.target.src = content.defaultImg;
  }

}


export class SearchResult {
  id: number;
  avatarImg: String;
  title: String;
  type: String;
  claz: String;
  defaultImg: string;
  firstName: string;
  lastName: string;

  constructor(id, type, image, title,claz,defaultImg,firstName?,lastName?) {
    this.id = id;
    this.type = type;
    this.avatarImg = image;
    this.title = title;
    this.firstName = firstName;
    this.lastName = lastName;
    this.claz = claz;
    this.defaultImg = defaultImg;
  }
}