import { Component, OnInit, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { absAnimations } from '@abs/animations';
import { GroupsService } from '../../../user/groups/groups.service';
import { SelectionModel } from '@angular/cdk/collections';

@Component({
  selector: 'app-automated-training-trigger-selector',
  templateUrl: './automated-training-trigger-selector.component.html',
  styleUrls: ['./automated-training-trigger-selector.component.scss'],
  animations: absAnimations,
  encapsulation: ViewEncapsulation.None,
})
export class AutomatedTrainingTriggerSelectorComponent implements OnInit {
  flip:any;
  groups: any;
  triggerType: any = '';
  companyName: any = "Sample Company"
  selectedGroups: any;
  selectedGroupsList = new SelectionModel<any>(true, []);
  userSelection = new SelectionModel<any>(true, []);

  @Output() onGroupSelectionDone = new EventEmitter<any>();
  @Output() onTriggerSelect = new EventEmitter<any>();
  @Output() onBackButton = new EventEmitter<any>();

  constructor(private _groupsService: GroupsService) { }

 

  ngOnInit(): void {

    this._groupsService.getGroupedGroups().then(groups => {
     this.groups = groups;
    });
  }

  setTrigger(value){
    console.log("value",value);
    this.triggerType = value;
    this.onTriggerSelect.emit(value);
  }
  handleBack() {
    this.onBackButton.emit("pressed");
  }

  onGroupSelection(event) {
    console.log("on group selection", event);
    this.selectedGroupsList.clear();
    this.userSelection.clear();
    this._groupsService.toggleGroupSelection(this.selectedGroupsList);
    this._groupsService.toggleUserSelection(this.userSelection);
    
    event.forEach(element => {
        this.selectGroup(element);
      });
    
  }

  onGroupsFinalized(event) {
    console.log("group selected", event)
    this.selectedGroups = event;
    this.onGroupSelectionDone.emit(this.selectedGroups);

  }
  selectGroup(group) {
    this.selectedGroupsList.select(group);
    if (group.userList) {
      group.userList.map(user => {
        user.selectedGroup = group.groupID;
        this.userSelection.select(user);
      })
    }
    group.allUserSelected = true;
    if (group.userList) {
      group.selectedUserCount = group.userList.length;
    } else {
      group.selectedUserCount = 0;

    }
    this._groupsService.toggleGroupSelection(this.selectedGroupsList);
    this._groupsService.toggleUserSelection(this.userSelection);
    console.log("select Group", this.selectedGroupsList.selected);
    console.log("total users ", this.userSelection);
  }
  descClicked(value) {
    this.flip = value;
  }
}
