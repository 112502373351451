import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { SelectService } from 'app/main/service/select.service';
import { AuthenticationService } from '../../../authentication/_services';
import { FormControl } from '@angular/forms';
import { SelectionModel } from '@angular/cdk/collections';
import { absAnimations } from '@abs/animations';

@Component({
  selector: 'app-onboarding-location-selector',
  templateUrl: './onboarding-location-selector.component.html',
  styleUrls: ['./onboarding-location-selector.component.scss'],
  animations: absAnimations,
  encapsulation: ViewEncapsulation.None,
})
export class OnboardingLocationSelectorComponent implements OnInit {
  flip:any;
  searchGroup = new FormControl();
  @Input() data: any;
  locations: any;
  selection = new SelectionModel<any>(true, []);
  @Output() onBackButton = new EventEmitter<any>();
  @Output() onSelection = new EventEmitter<any>();
  @Output() onDone = new EventEmitter<any>();
  constructor(private _selectService: SelectService,private _authService: AuthenticationService) { }

  ngOnInit(): void {
      this.locations = this.data;
  }

  onGroupChange(group){
    this.selection.toggle(group);
    this.onSelection.emit(this.selection.selected);
  }
  handleBack(){
    this.onBackButton.emit('group');
  }
  groupSelected(){
    this.onDone.emit(this.selection.selected);
  }
  descClicked(value) {
    this.flip = value;
  }
}
