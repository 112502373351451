import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'duration'
})
export class DurationPipe implements PipeTransform {

  transform(minutes: number): string {
    const hours = Math.floor(minutes/60);
    const minutesLeft = (Math.round((minutes % 60) * 100) / 100).toFixed();
    if(hours >= 1){
      return `${hours} hours, ${minutesLeft } Minutes`
    }else{
      return `${minutesLeft} Minutes`
    }
}

}
