import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Company } from '../_models';
import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { User } from '../../models/user';
import { AuthenticationService } from '.';


const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

export class CompanyDepartment {

  companyID: number;
  departmentID: number;

  constructor(companyID, departmentID) {
    this.companyID = companyID;
    this.departmentID = departmentID;
  }

}

@Injectable({ providedIn: 'root' })
export class CompnayService {


  constructor(private http: HttpClient,private _authService: AuthenticationService) { }


  handleError(error: HttpErrorResponse) {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
  }


  getCompaniesByUsername(username: string): Observable<any> {
    return this.http.post<Company[]>(`${environment.apiUrl}/user/company`, { username: username }, httpOptions).pipe(catchError(this.handleError));
  }

  getCompanyByCustomDomain(domain: string): Observable<any> {
    const body = `?domain=${domain}`;
    return this.http.post<any>(`${environment.apiUrl}/company/detail` + body, httpOptions).pipe(catchError(this.handleError));
  }
  getCompanyByDomain(domain: string): Observable<any> {
    const body = `?domain=${domain}`;
    return this.http.post<any>(`${environment.apiUrl}/company/find` + body, httpOptions).pipe(catchError(this.handleError));
  }

  getCompanyByWebsite(website: string): Observable<any> {
    const body = `?website=${website}`;
    return this.http.post<any>(`${environment.apiUrl}/company/find/website` + body, httpOptions).pipe(catchError(this.handleError));
  }

  getCompanyByDomainAndSuggest(domain: string,org: string): Observable<any> {
    const body = `?domain=${domain}&org=${org}`;
    return this.http.post<any>(`${environment.apiUrl}/company/suggest` + body, httpOptions).pipe(catchError(this.handleError));
  }

  getKioskSettings(request) {
    return this.http.post<any>(`${environment.apiUrl}/kiosk/settings`, request, httpOptions).pipe(catchError(this.handleError));

  }
  getCompanyByName(name: string): Observable<any> {
    const body = `?name=${name}`;
    return this.http.post<any>(`${environment.apiUrl}/company/find` + name, httpOptions).pipe(catchError(this.handleError));
  }

  getCompanySettingByCompanyId(companyId): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/company/setting/` + companyId, httpOptions).pipe(catchError(this.handleError));
  }

  checkIfFreeDomain(domain, username): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/company/freedomain?domain=${domain}&username=${username}`, httpOptions).pipe(catchError(this.handleError));
  }
  getCompanyTrainers(companyId): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/company/${companyId}/trainer/`, httpOptions).pipe(catchError(this.handleError));
  }


  getDepartments(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/company/departments`, httpOptions).pipe(catchError(this.handleError));
  }
  getCompanyDepartments(companyId): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/company/admin/departments/${companyId}`, httpOptions).pipe(catchError(this.handleError));
  }

  getCompanyConfigDepartments(companyId): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/company/admin/config/departments/${companyId}`, httpOptions).pipe(catchError(this.handleError));
  }


  insertCompanyDepartment(departments): Promise<any> {

    var result = departments.map(d => {
      return new CompanyDepartment(this._authService.currentUserValue.companyId, d);
    })
    return new Promise((resolve, reject) => {
      this.http.post(`${environment.apiUrl}/company/admin/departments`, JSON.stringify(result), httpOptions)
        .subscribe(response => {
          resolve(response);
        });
    }); 
  }

  deleteCompanyDepartment(departments): Promise<any> {
    var result = departments.map(d => {
      return new CompanyDepartment(this._authService.currentUserValue.companyId, d);
    })
    return new Promise((resolve, reject) => {
      this.http.post(`${environment.apiUrl}/company/admin/departments/delete`, JSON.stringify(result), httpOptions)
        .subscribe(response => {
          resolve(response);
        });
    }); 
  }

  getRecentlyLoggedInUsersForCurrentMonth(companyId, profileId) {
    return this.http.get<any>(`${environment.apiUrl}/users/recent/loggedin/${companyId}/${profileId}`, httpOptions).pipe(catchError(this.handleError));
  }

  getCompanyProviderById(providerId) {
    return this.http.get<any>(`${environment.apiUrl}/provider/${providerId}`, httpOptions).pipe(catchError(this.handleError));
  }


  getSMS(id): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/training?sid=` + id, httpOptions).pipe(catchError(this.handleError));
  }


}
