import { Component, OnInit, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { absAnimations } from '@abs/animations';
import { FormControl } from '@angular/forms';
import { SelectionModel } from '@angular/cdk/collections';
import { AuthenticationService } from '../../../authentication/_services';
import { GroupsService } from '../../../user/groups/groups.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AbsUtils } from '@abs/utils';

@Component({
  selector: 'app-other-group-selector',
  templateUrl: './other-group-selector.component.html',
  styleUrls: ['./other-group-selector.component.scss'],
  animations: absAnimations,
  encapsulation: ViewEncapsulation.None,
})
export class OtherGroupSelectorComponent implements OnInit {
  flip:any;
  groups: any;
  allGroups: any;
  searchGroup = new FormControl();
  selection = new SelectionModel<any>(true, []);
  @Output() onBackButton = new EventEmitter<any>();
  @Output() onSelection = new EventEmitter<any>();
  @Output() onDone = new EventEmitter<any>();

  constructor(private _authService: AuthenticationService, private _groupService: GroupsService) { }

  ngOnInit(): void {

    this._groupService.getOtherPeopleGroups().then(result => {
      this.groups = result;
      this.allGroups = result;
    }

    )

    this.searchGroup.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe(value => {
      if (value.length > 0) {
        var result = AbsUtils.filterArrayByString(this.groups, value);
        this.groups = result;
      } else {
        this.groups = this.allGroups;

      }
    });
  }

  onGroupChange(group){
    if (group.userCount > 0) {

    this.selection.toggle(group);
    this.onSelection.emit(this.selection.selected);
    }

  }

  handleBack(){
    this.onBackButton.emit('other');
  }
  groupSelected(){
    this.onDone.emit(this.selection.selected);
  }
  descClicked(value) {
    this.flip = value;
  }
}