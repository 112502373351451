import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { absAnimations } from '@abs/animations';

@Component({
  selector: 'app-quiz-add-another-question',
  templateUrl: './quiz-add-another-question.component.html',
  styleUrls: ['./quiz-add-another-question.component.scss'],
  animations:absAnimations

})
export class QuizAddAnotherQuestionComponent implements OnInit {

  flip:any;
  @Output() onBackButton = new EventEmitter<any>();
  @Output() onSelection = new EventEmitter<any>();
  @Output() onDone = new EventEmitter<any>();
  constructor() { }

  ngOnInit(): void {
  }
  descClicked(abc){
    
  }
  selectTrainingMethod(abc){

  }
  handleBack(){
    this.onBackButton.next("add-question-back")
  }

  handleAction(type){
    this.onDone.next({value:'',type:type})
  }
  
}