import { Component, OnInit, Output, EventEmitter, Input, ViewEncapsulation } from '@angular/core';
import { absAnimations } from '@abs/animations';

@Component({
  selector: 'app-time-to-complete-selector',
  templateUrl: './time-to-complete-selector.component.html',
  styleUrls: ['./time-to-complete-selector.component.scss'],
  animations: absAnimations,
  encapsulation: ViewEncapsulation.None,
})
export class TimeToCompleteSelectorComponent implements OnInit {

  flip:any;
  value: any;
  valueLabel = "";
  unit:any = 'days';
  valueInDays: any;

  @Input()
  default: any;

  @Output() onBackButton = new EventEmitter<any>();
  @Output() onSelection = new EventEmitter<any>();
  @Output() onDone = new EventEmitter<any>();
  freq: any = 'days';
  max: number = 31;
  min: number = 1;
  constructor() {

   }

  ngOnInit(): void {
    this.value = this.default;
    this.valueLabel = this.value+ "Days";

    this.onSelection.emit({ value: { unit: 'days',value: this.default}, type: 'time-to-complete' });

  }

  onInputChange(event) {
    this.handleInputChange(event.value);
    this.onSelection.emit({ value: { unit: 'days',value: this.valueInDays}, type: 'time-to-complete' });

  }

  handleBack() {
    this.onBackButton.emit('time-to-complete');
  }
  controlSelected() {
    this.onDone.emit({ value: { unit: 'days',value: this.valueInDays}, type: 'time-to-complete' });
  }

  formatLabel(value: number) {
    if (value == 1) {
      return 'Days';
    }
    if (value == 2) {
      return 'Weeks';
    }
    if (value == 3) {
      return 'Months';
    }

    return value;
  }

  frequncyChanged(control) {
    const value = control.value;
    this.unit = value;
    this.value =1;
    
    if (value == 'days') {
      this.min = 1;
      this.max = 31;
      if (this.value == 1) {
        this.valueLabel = this.value + " Day";
      } else {
        this.valueLabel = this.value + " Days";
      }
      this.valueInDays = this.value;
    }
    if (value == 'weeks') {
      this.min = 1;
      this.max = 52;
      if (this.value == 1) {
        this.valueLabel = this.value + " Week";
      } else {
        this.valueLabel = this.value + " Weeks";
      }
      this.valueInDays= this.value * 7;

    }
    if (value == 'months') {
      this.min = 1;
      this.max = 12;
      if (this.value == 1) {
        this.valueLabel = this.value + " Month";
      } else {
        this.valueLabel = this.value + " Months";
      }
      this.valueInDays = this.value * 30;

    }
    

    this.onSelection.emit({ value: { unit: 'days',value: this.valueInDays}, type: 'time-to-complete' });
  }


  handleInputChange(input) {
    const value = this.unit
    if (value == 'days') {
      if (this.value == 1) {
        this.valueLabel = input + " Day";
      } else {
        this.valueLabel = input + " Days";
      }
      this.valueInDays = input;

    }
    if (value == 'weeks') {
      if (this.value == 1) {
        this.valueLabel = input + " Week";
      } else {
        this.valueLabel = input + " Weeks";
      }
      this.valueInDays = input *7;

    }
    if (value == 'months') {
      if (this.value == 1) {
        this.valueLabel = input + " Month";
      } else {
        this.valueLabel = input + " Months";
      }
      this.valueInDays = input * 30;
    }
  }
  descClicked(value) {
    this.flip = value;
  }
}
