import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {absAnimations} from '@abs/animations';
import {AbsNavigation} from '@abs/types';
import {Subject} from 'rxjs';
import {AbsNavigationService} from '@abs/components/navigation/navigation.service';
import {AbsSidebarService} from '@abs/components/sidebar/sidebar.service';
import {AbsConfigService} from '@abs/services/config.service';
import {Router} from '@angular/router';
import { AuthenticationService } from '../authentication/_services';
import { UserLevel } from '../models/user';

@Component({
  selector: 'app-training',
  templateUrl: './training.component.html',
  styleUrls: ['./training.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: absAnimations
})
export class TrainingComponent implements OnInit, OnDestroy {

    learnerNavigation: AbsNavigation[]  = [

    
      //Will be part of version 2
     /*  {
        id: 'insights',
        title: 'Insights',
        translate: 'NAV.APPLICATIONS',
        type: 'item',
        url: 'insights'
      },  */
      {
        id: 'my-trainings',
        title: 'Assigned Training',
        translate: 'NAV.APPLICATIONS',
        type: 'item',
        url: 'my-trainings'
      }];


  // Private
  private _unsubscribeAll: Subject<any>;

  constructor(public _router: Router,
              private _absNavigationService: AbsNavigationService,
              private _absSidebarService: AbsSidebarService,
              private _absConfigService: AbsConfigService,
              private _authService: AuthenticationService) {

    // Set the private defaults
    this._unsubscribeAll = new Subject();

    // Configure the layout
    this._absConfigService.config = {
      layout: {
        navbar: {
          hidden: false
        },
        toolbar: {
          hidden: false
        },
        footer: {
          hidden: true
        },
        sidepanel: {
          hidden: true
        }
      }
    };
    if(this._authService.currentUserValue.userLevel == UserLevel.LEARNER){
      //this.navigation = this.learnerNavigation;
    }
  }

  ngOnInit() {
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  /**
   * Toggle the sidebar
   *
   * @param name
   */
  toggleSidebar(name): void {
    this._absSidebarService.getSidebar(name).toggleOpen();
  }


  /**
   * Update boxed width
   *
   * @param router
   */
  getRoute(router: Router) {
    if (this._router.url.includes('/training/assign')) {
      return 'full-width';
    }

    if (this._router.url === '/training/calendar') {
      return 'full-width';
    }

    if (this._router.url === '/training/locations') {
      return 'full-width';
    }

    if (this._router.url === '/training/my-trainings') {
      return 'full-width';
    }

    if (this._router.url === '/training/my-calendar') {
      return 'full-width';
    }

    if (this._router.url === '/training/classroom') {
      return 'full-width';
    }

    if (this._router.url === '/training/insights') {
      return 'card-width';
    }

  }

}
