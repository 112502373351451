import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';

@Component({
  selector: 'playlist-picker-step1',
  templateUrl: './playlist-picker.component.html',
  styleUrls: ['./playlist-picker.component.scss']
})
export class PlaylistPickerComponent implements OnInit {

  public config: SwiperConfigInterface = {
    init: true,
    a11y: true,
    direction: 'horizontal',
    keyboard: true,
    mousewheel: false,
    scrollbar: false,
    navigation: true,
    pagination: false,
    spaceBetween: 12
  };

  public configPlaylist: SwiperConfigInterface = {
    init: true,
    a11y: true,
    direction: 'horizontal',
    keyboard: true,
    mousewheel: false,
    scrollbar: false,
    navigation: true,
    pagination: true

  };

  pickPlayList: FormGroup;

  constructor(
    private _formBuilder: FormBuilder) {
  }

  ngOnInit() {
    this.pickPlayList = this._formBuilder.group({
      playList: ['', Validators.required]
    });
}
}
