import { Component, OnInit, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { absAnimations } from '@abs/animations';
import { FormControl } from '@angular/forms';
import { SelectionModel } from '@angular/cdk/collections';
import { AuthenticationService } from '../../../authentication/_services';
import { GroupsService } from '../../../user/groups/groups.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AbsUtils } from '@abs/utils';
import { ContentService } from '../../content.service';
import { trigger, state, transition, style, animate } from '@angular/animations';
import { MatTableDataSource } from '@angular/material/table';
import { UserService } from '../../../service/user.service';
import { UsersService } from '../../../user/user.service';
@Component({
  selector: 'app-content-share-with',
  templateUrl: './content-share-with.component.html',
  styleUrls: ['./content-share-with.component.scss'],
  animations: absAnimations,
  encapsulation: ViewEncapsulation.None

})
export class ContentShareWithComponent implements OnInit {

  step = 1;
  selectedGroup: any = 'NONE';
  flip: any;
  selection = new SelectionModel<any>(true, []);

  selectedManagers = new SelectionModel<any>(true, []);
  selectedGroups = new SelectionModel<any>(true, []);
  selectedDepartments = new SelectionModel<any>(true, []);
  selectedLocations = new SelectionModel<any>(true, []);

  locations: any;
  departments: any;
  groups: any;
  managers: any;

  @Output() onBackButton = new EventEmitter<any>();
  @Output() onSelection = new EventEmitter<any>();
  @Output() onDone = new EventEmitter<any>();

  constructor(private userService: UsersService, private _groupsService: GroupsService, private _auth: AuthenticationService) { }

  ngOnInit(): void {
    this.userService.getAdminAndManagers().then(users => {
      this.managers = users;
    });
    this._groupsService.getGroupedGroups().then(groups => {
      this.initDataSource(groups);
    });
  }
  initDataSource(groups) {

    this.locations = groups.locationGroups;
    this.departments = groups.departmentGroups;
    this.groups = groups.allGroups;
  }

  onChange(cat) {
    this.selection.toggle(cat)
  }

  onContinue() {
    this.onDone.next({ data: {
       managers: this.selectedManagers.selected, 
      locations: this.selectedLocations.selected, 
      groups: this.selectedGroups.selected,
      departments: this.selectedDepartments.selected 
    }, type: 'SHARED' })
  }
  handleBack() {
    this.onBackButton.next("cateogry")
  }

  handleBackInternal() {
    this.selectedGroup = 'NONE';
  }
  descClicked(abc) {

  }


  setSelection(value) {
    this.selectedGroup = value;
  }

  onDepartmentDone(event) {
    console.log("department", event);
    if (event.data && event.data.length > 0) {
      this.selection.select('D');
      event.data.forEach(element => {
        this.selectedDepartments.select(element);
      });
    }
    this.selectedGroup = 'NONE';

  }

  onLocationDone(event) {
    console.log("location", event);
    if (event.data && event.data.length > 0) {
      this.selection.select('L');
      event.data.forEach(element => {
        this.selectedLocations.select(element);
      });

    }
    this.selectedGroup = 'NONE';

  }

  onGroupDone(event) {
    console.log("group", event);
    if (event.data && event.data.length > 0) {
      this.selection.select('G');
      event.data.forEach(element => {
        this.selectedGroups.select(element);
      });

    }
    this.selectedGroup = 'NONE';

  }

  onManagerDone(event) {
    console.log("manager", event);
    if (event.data && event.data.length > 0) {
      this.selection.select('M');
      event.data.forEach(element => {
        this.selectedManagers.select(element);
      });
    }
    this.selectedGroup = 'NONE';

  }

}
