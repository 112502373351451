import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { Content } from '../../training/_models/playlist';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AbsProgressBarService } from '@abs/components/progress-bar/progress-bar.service';
import { TrainingService } from '../../training/_services/training.service';
import { AuthenticationService } from '../../authentication/_services';
import { VideoPlayerService } from '../videos/video-player/video-player.service';
import { ThirdPartyStreamingVerificationComponent } from '../videos/preview/third-party-streaming-verification/third-party-streaming-verification.component';

@Component({
  selector: 'app-play-now-dialog',
  templateUrl: './play-now-dialog.component.html',
  styleUrls: ['./play-now-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PlayNowDialogComponent implements OnInit {
  videoId="djDsK-rvnxw";
  preview: boolean;
  video: Content;
  classId: any;
  canView: false
  lang: any;
  viewType: any;
  verificationMode = false;
  isThirdPartyVideo = false;

  constructor(public matDialogRef: MatDialogRef<PlayNowDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private _snackBar: MatSnackBar,
    private _progressBarService: AbsProgressBarService,
    private _trainingService: TrainingService,
    private _authService: AuthenticationService,
    private _verificationDialog: MatDialog,
    private playerService: VideoPlayerService) {
    this.video = _data.video;
    this.preview = _data.preview;
    this.lang = _data.lang;
    console.log("playnow course", _data);
  }

  ngOnInit() {  

    this.checkIfVerficationMode();

    if(!this.verificationMode){
      setTimeout(() => {
          this.preview = false;
      }, 1500);
    }
   
  }
  timeViewedChanged(event) {
    console.log(event);
  }

  checkIfVerficationMode() {
    if (this.video.producerID == 26 && this.video.vendorID == 15) {
      this.isThirdPartyVideo = true;
    }
    const alreadyVerified = localStorage.getItem("thirdPartyVerified");
    if (this.isThirdPartyVideo) {
      if (alreadyVerified && alreadyVerified === 'Y') {
        this.verificationMode = false;
        this.videoId = this.video.sku;

      } else {
        this.verificationMode = true;
      }
    }

    if (this.verificationMode) {
      this._verificationDialog.open(ThirdPartyStreamingVerificationComponent, {
        id: 'verification',
        backdropClass: 'previewBackdrop',
        width: '500px',
        disableClose: true,
        data: {
          noCodeFound: false
        }
      });
    }
  }
  handleCode(value) {
    if (value == '1340') {
      localStorage.setItem("thirdPartyVerified", "Y");
      this.verificationMode = false;
      this.videoId = this.video.sku;
    } else if (value == 'NO') {
      this._verificationDialog.open(ThirdPartyStreamingVerificationComponent, {
        id: 'verification',
        backdropClass: 'previewBackdrop',
        width: '500px',
        disableClose: true,
        data: {
          noCodeFound: true
        }
      });
    }
  }

}
