import { Component, OnInit, ViewEncapsulation, Output, EventEmitter, Input } from '@angular/core';
import { FileManagerService } from '../../my-uploads/my-uploads.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ContentService } from '../../content.service';
import { AuthenticationService } from '../../../authentication/_services';
import { AbsProgressBarService } from '@abs/components/progress-bar/progress-bar.service';
import { FileUploader } from 'ng2-file-upload';
import { Category, Content } from '../../content-list.model';
import { File } from '../../my-uploads/file.model';
import { absAnimations } from '@abs/animations';
import { FileData } from '../content-wizard.component';
import { environment } from 'environments/environment';
import * as Stomp from 'stompjs';
import * as SockJS from 'sockjs-client';
import { DomSanitizer } from '@angular/platform-browser';
import { TrainingService } from '../../../training/_services/training.service';
import { MatDialog } from '@angular/material/dialog';
import { ShoppingExperienceDialogComponent } from '../../../widgets/shopping-experience-dialog/shopping-experience-dialog.component';
import { ActivityLog, ActivityType, ActivityLogService } from '../../../service/activity-log.service';
import { AbsUtils } from '@abs/utils';
@Component({
  selector: 'app-content-publisher-confirmation',
  templateUrl: './content-publisher-confirmation.component.html',
  styleUrls: ['./content-publisher-confirmation.component.scss'],
  animations: absAnimations,
  encapsulation: ViewEncapsulation.None

})
export class ContentPublisherConfirmationComponent implements OnInit {
  private serverUrl = `${environment.apiUrl}/socket`;
  private stompClient;
  videoUrl;
  videoSizeError;
  uploadingToServer = false;
  uploadingToAmazon = false;
  videoDuration=0;
  videoHeight= 0;
  videoWidth= 0;
  flip: any;
  uploadingError = false;
  @Input()
  title: any;

  @Input()
  video: any;

  @Input()
  uploader: FileUploader;

  @Input()
  fileData: FileData;

  fileName: any;
  latestFile: any;
  uploadingValue = 0
  uploadingDone = false;
  response: string;
  thumbUrl: string = "";
  file: File;
  pageType: string;
  fileForm: FormGroup;
  folder: any;
  action: string;
  visible = true;
  selectable = true;
  removable = true;
  checked = false;
  enviorment: any;
  uploading = false;
  categories: string[] = [];
  allCategories: Category[] = [];
  done: boolean = false;
  viewType: any = 'VIDEO'
  publishMode = ''
  uploadedProduct = new Content({});

  videoPercentage = 100;
  videoBucket: any;

  nonVideoPercentage = 100;
  nonVideoBucket: any;

  storageAvailable = false

  @Output() onBackButton = new EventEmitter<any>();
  @Output() onSelection = new EventEmitter<any>();
  @Output() onDone = new EventEmitter<any>();

  constructor(private _myUploadService: FileManagerService,
    private _activityLog: ActivityLogService,
    private _formBuilder: FormBuilder,
    private _trainingService: TrainingService,
    private _matSnackBar: MatSnackBar,
    private shoppingDialog: MatDialog,
    private contentService: ContentService,
    private _authService: AuthenticationService,
    private _progressBar: AbsProgressBarService, private sanitizer: DomSanitizer) {
    this.file = new File();
    this.enviorment = environment;
  }

  ngOnInit(): void {
   

    this.getViews();

    console.log("uploader", this.uploader.queue[0]);
    console.log("upload", this.uploader)
    console.log("file data", this.fileData)
    console.log("this", this)
    console.log("thisasd", this.fileData && this.fileData.contentId)

    if (this.uploader.queue[0].file.type.indexOf("video") != -1) {
      this.viewType = 'VIDEO';
      this.readVideoUrl();
    } else {
      this.viewType = 'NON_VIDEO';
    }

    if(this.fileData && this.fileData.contentId){
      console.log("ifff");
      this.done = true;
      this.uploadingToAmazon = false;
      this.uploadingToAmazon = false;
      this.uploadingDone = true;
      this.publishMode = 'ALLOW';
    }else{
      console.log("else");

      let viewModel = { companyId: this._authService.currentUserValue.companyId, providerId: this._authService.currentUserValue.providerId, profileId: this._authService.currentUserValue.profileId, type: this.viewType, requestedViews: 1 };
      this._trainingService.checkIfUserCanViewVideo(viewModel).then(res => {
        console.log("publish mode",this.publishMode);

        if (res) {
          this.publishMode = 'ALLOW';
        } else {
          this.publishMode = 'NOT_ALLOWED';
        }
        console.log("publish mode",this.publishMode);

      })

    }

  }
  handleBack() {
    this.onBackButton.next("confirm")
  }
  descClicked(abc) {

  }
  onContinue() {
    this.saveDocument();
    this.readVideoUrl();
  }
  onDoneClicked() {
    this.onDone.next({ value: this.uploadedProduct, type: 'publish-conf' });
  }

  saveDocument() {
    this.latestFile = this.uploader.queue[0];
    this._matSnackBar.open("Saving...", '', {
      horizontalPosition: 'right',
      verticalPosition: 'top',
      politeness: 'polite',
    });
    var allCats = [];
    var categoriesId = this.fileData.categories.map(x => {
      return x.id;
    }); 
    allCats = categoriesId;
    
    if(this.fileData && this.fileData.subCategories){

      var subCategories = this.fileData.subCategories.map(x => {
        return x.id;
      }); 
      allCats = categoriesId.concat(subCategories);
    }
    this._progressBar.show();
    var content = new Content({})
    content.title = this.fileData.title,
      content.description = this.fileData.description,
      content.companyID = this._authService.currentUserValue.companyId,
      content.providerID = this._authService.currentUserValue.providerId,
      content.profileID = this._authService.currentUserValue.profileId,
      content.sku = "",
      content.fileName = AbsUtils.handleize(this.latestFile.file.name),
      content.categoryList = allCats,
      content.english = true,
      content.itemCode = "TEST",
      content.prodTypeID = this.latestFile.file.type.indexOf("video") != -1 ? 15 : 7,
      content.thumbnail = this.fileData.thumbUrl;
      content.visible = this.visible,
      content.ownerType = 'provider',
      content.departmentID = this._authService.currentUserValue.department,
      content.tags = this.fileData.tags
      content.runningTime = this.videoDuration
      content.videoHeight = this.videoHeight;
      content.videoWidth = this.videoWidth;
      this.contentService.insertContent(content).subscribe(res => {
        console.log("result", res);
        this._progressBar.hide();
        if (res) {
          content.id = res;
          this._matSnackBar.dismiss();
          this.uploading = true;
          this.uploadedProduct = content;
          this.fileData.contentId = content.id;
          this.uploadDocument(content.id);
        }else{
          this._matSnackBar.open("Error while uploading file.", '', {
            panelClass: 'red-bg',
            duration: 2000,
            horizontalPosition: 'right',
            verticalPosition: 'top',
            politeness: 'polite',
          });
        }
      })
  }

  get f() {
    return this.fileForm.controls;
  }

  createFileForm(): FormGroup {
    return this._formBuilder.group({
      id: [this.file.id],
      type: [this.file.type],
      title: [this.file.title],
      folder: [this.file.folder],
      source: [this.file.source],
      youtubeTitle: [this.file.youtubeTitle],
      friendlyTitle: [this.file.friendlyTitle],
      handle: [this.file.handle],
      description: [this.file.description],
      categories: [this.file.categories],
      tags: [this.file.tags],
      thumbnail: [this.file.thumbnail],
      dateCreated: [this.file.dateCreated],
      lastEdit: [this.file.lastEdit],
      createdBy: [this.file.createdBy],
      lastUsed: [this.file.lastUsed],
      visibility: [this.file.visibility],
    });
  }

  initializeWebSocketConnection() {
    let ws = new SockJS(this.serverUrl);
    this.stompClient = Stomp.over(ws);
    let that = this;
    this.stompClient.connect({}, function (frame) {
      that.stompClient.subscribe("/file/upload/" + that.f.id.value, (message) => {
        if (message.body) {
          console.log("value", message.body)
          that.uploadingValue = message.body;
          that.uploadingToAmazon = true;
          that.uploadingToServer = false;
        }
      });

    });
  }
  readVideoUrl() {
    console.log("fine", this.uploader.queue[0].file)
    console.log("uploader", this.uploader)
    const self = this;
    const file = this.uploader.queue[0]._file;

    const url = URL.createObjectURL(file);
    console.log("url", url);
    // Create a video element
    const video = document.createElement('video');

    // Set the video source to the file URL
    video.src = url;

    // Wait for the video to load
    video.addEventListener('loadeddata', () => {
      // Get the duration of the video
      const videoDuration = video.duration;
      self.videoDuration  =Math.ceil(videoDuration/60);
      self.videoHeight = video.videoHeight;
      self.videoWidth  =video.videoWidth;
      console.log(`Video duration: ${self.videoDuration} seconds`);
    });

    video.addEventListener('loadedmetadata', () => {
      // Get the duration of the video
      console.log("video",video);
      console.log("video heigh",video.videoHeight);
      console.log("video width",video.videoWidth);
      console.log("video width",video.title);
      console.log("quality",video.getVideoPlaybackQuality())


      const videoDuration = video.duration;
      self.videoDuration  = Math.ceil(videoDuration/60);
      self.videoHeight = video.videoHeight;
      self.videoWidth  =video.videoWidth;
      console.log(`Video duration loadedmetadata: ${self.videoDuration} seconds`);
    });

  }

  loadded(e) {
    console.log("duration video 123", e)

  }

  getDuration(e) {
    console.log("duration video", e)
    const duration = e.target.duration;
    console.log("duration video", duration)
    this.videoSizeError = duration > 35;

    if (false) {
      this._matSnackBar.open("Video length is greater then 30 minutes.", '', {
        panelClass: 'red-bg',
        duration: 2000,
        horizontalPosition: 'right',
        verticalPosition: 'top',
        politeness: 'polite',
      });
    }
  }

  launchShoppingExperience() {
    const dialogRef = this.shoppingDialog.open(ShoppingExperienceDialogComponent, {
      width: '95vw',
      height: '950vh',
      backdropClass: 'previewBackdrop',
      panelClass: 'helpPanel',
      disableClose: true
    });
    dialogRef.afterClosed()
      .subscribe(() => {
        this.getViews();
      });
  }

  getViews() {
    this._trainingService.getTotalVideoViews(this._authService.currentUserValue.profileId).then(result => {
      this.videoBucket = result;
      if (result) {
        this.videoPercentage = result.remaining / result.total * 100;
        this.validateStorage();
      }
    })

    this._trainingService.getTotalNonVideoViews(this._authService.currentUserValue.profileId).then(result => {
      this.nonVideoBucket = result;
      if (result) {
        this.nonVideoPercentage = result.remaining / result.total * 100;
        this.validateStorage();
      }
    })


  }

  validateStorage() {
    if (this.viewType == 'VIDEO' && this.videoBucket && this.videoBucket.remaining > 0) {
      this.storageAvailable = true;
    }

    if (this.viewType == 'NON_VIDEO' &&  this.nonVideoBucket && this.nonVideoBucket.remaining > 0) {
      this.storageAvailable = true;
    }
  }

  uploadDocument(contentId){
    this.onSelection.emit(contentId);
    this.uploadingToServer = true;
    this.uploader.onBuildItemForm = (fileItem: any, form: any) => {
      form.append('id', this.f.id.value);
      form.append('companyId', this._authService.currentUserValue.companyId);
      form.append('profileId', this._authService.currentUserValue.profileId);
      form.append('productId',contentId);
    };

    this.contentService.getProductCategories().subscribe(category => {
      this.allCategories = category;
    });

    this._myUploadService.onFilesChanged
      .subscribe(file => {
        if (file) {
          this.file = new File(file);
          this.pageType = 'edit';
        }
        else {
          this.pageType = 'new';
          this.file = new File();
        }
        this.fileForm = this.createFileForm();
        this.uploader.uploadAll();
        this.initializeWebSocketConnection();
       
        this.uploader.onProgressItem = (item, response) => {
          this.uploadingToServer = true;
          this.uploadingToAmazon = false;
          this.uploadingValue = response;
          console.log("progress item", item);
          console.log("progress response", response);

        }

        this.uploader.onSuccessItem = (item, response, status) => {
          const resp = JSON.parse(response);
          console.log("resp", resp)
          this.uploadingDone = resp;
          if (resp) {
            this.uploadingToServer = false;
            this.uploadingToAmazon = false;
            this.uploadingValue = 100
            this.done = true;
            this.onSelection.emit(contentId);
            this._matSnackBar.open("File has been uploaded.", '', {
              panelClass: 'green-bg',
              duration: 3000,
              horizontalPosition: 'right',
              verticalPosition: 'top',
              politeness: 'polite',
            });

            var request = {
              profileId: JSON.parse(localStorage.getItem("currentUser")).profileId,
              providerId: JSON.parse(localStorage.getItem("currentUser")).providerId,
              productId: contentId,
              companyId: JSON.parse(localStorage.getItem("currentUser")).companyId,
              type:this.viewType 
            };
  
            this._trainingService.deductContentView(request).then(res => {
              let activityLog = new ActivityLog();
              activityLog.profileID = JSON.parse(localStorage.getItem("currentUser")).profileId
              activityLog.type = "view";
              activityLog.source = "itrain";
              activityLog.activity = ActivityType.CONTENT_CONTENT;
              this._activityLog.saveActivityLog(activityLog).subscribe(res => { });
            });
  

          } else {
            this._matSnackBar.open("Error while uploading file.", '', {
              panelClass: 'red-bg',
              duration: 2000,
              horizontalPosition: 'right',
              verticalPosition: 'top',
              politeness: 'polite',
            });
          }
        }
        this.response = '';

        this.uploader.response.subscribe(res => {
          this.response = res
          this._matSnackBar.dismiss();
        });
      });
  }
}
