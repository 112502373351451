import { Component, OnInit, Input } from '@angular/core';
import { MyCalendarService } from '../../_services/my-calendar.service';

@Component({
  selector: 'app-agenda-item-detail',
  templateUrl: './agenda-item-detail.component.html',
  styleUrls: ['./agenda-item-detail.component.css']
})
export class AgendaItemDetailComponent implements OnInit {


  @Input() 
  schedulerId

  detail : any;

  constructor(private myCalendarService: MyCalendarService) { }

  ngOnInit(): void {
     this.getTrainingStats();
  }

  async getTrainingStats(){
    this.myCalendarService.getTrainingStatsById(this.schedulerId).then(result=>{
      this.detail = result;
    })
  }
}
