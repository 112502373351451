import { Component, OnInit, Output, EventEmitter, Input, ViewEncapsulation } from '@angular/core';
import { absAnimations } from '@abs/animations';
import { Quiz } from '../../quiz/models';
import { FormGroup } from '@angular/forms';
import { Question } from 'app/main/content/quiz/models/question';
import { QuestionData } from './quiz-question-summary/quiz-question-summary.component';
import { FileUploader } from 'ng2-file-upload';
import { QuizService } from '../../quiz/services/quiz.service';
import { even } from '@rxweb/reactive-form-validators';
import { productPage } from '../../../../app.module';

@Component({
  selector: 'app-quiz-editor',
  templateUrl: './quiz-editor.component.html',
  styleUrls: ['./quiz-editor.component.scss'],
  animations: absAnimations,
  encapsulation: ViewEncapsulation.None

})
export class QuizEditorComponent implements OnInit {


  flip: any;
  step = -1;
  questionType: any;
  editQuestionObj: QuestionData;
  editMode = false;

  @Input()
  uploader: FileUploader;

  @Input()
  exisingQuizEditMode = false;

  @Input()
  contentId;

  @Input()
  productType;


  fileType: any;
  @Output() onBackButton = new EventEmitter<any>();
  @Output() onSelection = new EventEmitter<any>();
  @Output() onDone = new EventEmitter<any>();

  quiz: Quiz;
  constructor(private quizService:QuizService) { }

  ngOnInit(): void {
    if(this.exisingQuizEditMode){
      this.loadQuiz();
    }else{
      this.step = 1;
      this.quiz = new Quiz(null);
      this.quiz.questions = [];

    }
    if (this.uploader) {
      if (this.uploader.queue[0].file.type.indexOf("video") != -1) {
        this.fileType = 'VIDEO';
      } else {
        this.fileType = 'NON_VIDEO';
      }
    }
  }
  descClicked(abc) {

  }
  selectTrainingMethod(abc) {

  }
  handleBack() {
    if(this.exisingQuizEditMode){
      this.loadQuiz();
      this.step=-1;
    }else{
    this.onBackButton.next("quiz-editor")
    }
  }
  handleBackInternal(value) {
    //this.editQuestionObj = null;

    if ("add-question-back" == value) {

      if (this.quiz && this.quiz.questions && this.quiz.questions.length > 0) {
        var q = new Question(this.quiz.questions[this.quiz.questions.length - 1]);
        var data = new QuestionData();
        data.type = 'Edit';
        data.question = q;
        data.index = this.quiz.questions.length - 1;
        this.editQuestionObj = data;
        this.editMode = true;

      }
    } else {
      this.editMode = false;
    }
    if (value == 'go-to-summary') {
      this.step = 4;
    } else {
      this.step--;
    }

  }

  publishToCatalog() {
    this.onDone.next("publish")
  }
  createNewQuiz() {
    this.step=1;
    this.quiz = new Quiz(null);
    this.quiz.questions = [];
  }
  modifyExisting() {
    this.step = 4;
  }
  onQuestionFormDone(event) {
    console.log("event", event);
    if (event.type == 'add') {
      this.quiz.questions.push(event.value)
      this.editQuestionObj = event.value;
    }else if (event.type == 'delete') {
     this.quiz.questions.splice(event.index, 1); // 2nd parameter means remove one item only
      this.step = 4
      return
    }
    else {
      this.quiz.questions[event.index] = new Question(event.value);
    }
    console.log("quiz", this.quiz);
    this.step++;

    this.onSelection.next(this.quiz)
  }
  onQuestionTypeSelected(type) {
    this.questionType = type;
    this.step++;
  }
  onAddAnotherDone(event) {
    if (event.type == 'Edit') {
      this.editQuestionObj = event.value;
      this.step = 2;
    } else {
      this.editQuestionObj = null;
      if (event.type == 'A') {
        this.step = 1;
      }
      if (event.type == 'R') {
        this.step = 4;
      }
      if (event.type == 'E') {
        this.step = 5;
      }
      if(event.type == 'MY'){
      this.step
      }
    }
  }

  onPassingScore(e){
    this.step = 6;
  }

  onPassingScoreDone(e){
    if(this.exisingQuizEditMode){
      this.quiz.passingScore = e.value
      this.onDone.next({ value: this.quiz })
    }else{
      this.onDone.next("exit")
    }
  }

  onSummaryDone(event) {
    if (event.type == 'Edit') {
      this.editQuestionObj = event;
      this.step = 2;
    }
    if (event.type == 'submit') {
      this.onDone.next({ value: this.quiz })
    }
  }


  loadQuiz(){
    this.quizService.getQuizDetail(this.contentId, false).subscribe(res => {
      if (res) {
        this.quiz = new Quiz(res);
        console.log(this.quiz);
      }
    });
  }
}