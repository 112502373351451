import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {AbsSharedModule} from '@abs/shared.module';
import {YouTubePlayerModule} from '@angular/youtube-player';


import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';

import {SWIPER_CONFIG, SwiperConfigInterface, SwiperModule} from 'ngx-swiper-wrapper';


import { MatProgressBarModule } from '@angular/material/progress-bar';

import {AbsConfirmDialogModule, AbsMaterialColorPickerModule} from '../../../@abs/components';
import { NO_ERRORS_SCHEMA } from '@angular/compiler';
import { MatDialogModule } from '@angular/material/dialog';
import { MatChipsModule } from '@angular/material/chips';
import { TourMatMenuModule } from 'ngx-tour-md-menu';
import { AutoTrainingComponent } from './auto-training.component';
import { WizardDialogComponent } from './wizard-dialog/wizard-dialog.component';
import { PlaylistPickerComponent } from './wizard-dialog/playlist-picker/playlist-picker.component';
import { MatStepperModule } from '@angular/material/stepper';
import { ShoppingCartService } from './cart.service';
import { CheckoutDialogComponent } from './checkout-dialog/checkout-dialog.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { NotificationDialogComponent } from './wizard-dialog/notification-dialog/notification-dialog.component';
import { ReportingDialogComponent } from './wizard-dialog/reporting-dialog/reporting-dialog.component';
import { ExperienceDialogComponent } from './wizard-dialog/experience-dialog/experience-dialog.component';
import { ProfileDialogComponent } from './wizard-dialog/profile-dialog/profile-dialog.component';
import { ConvenienceSettingComponent } from './wizard-dialog/convenience-setting/convenience-setting.component';
const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto'
};



@NgModule({
  declarations: [
   AutoTrainingComponent,
   WizardDialogComponent,
   PlaylistPickerComponent,
   CheckoutDialogComponent,
   NotificationDialogComponent,
   ReportingDialogComponent,
   ExperienceDialogComponent,
   ProfileDialogComponent,
   ConvenienceSettingComponent
  ],
  imports: [
    CommonModule,
    // Shared Modules
    AbsSharedModule,
    AbsConfirmDialogModule,

    // Material2
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatRadioModule,
    MatTooltipModule,
    MatSelectModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatMenuModule,
    MatRippleModule,
    MatTableModule,
    MatToolbarModule,
    MatListModule,
    MatExpansionModule,
    MatDividerModule,
    MatSnackBarModule,
    MatTabsModule,
    MatAutocompleteModule,
    MatDialogModule,
    MatChipsModule,
    AbsMaterialColorPickerModule,
    MatStepperModule,
    // Google
    YouTubePlayerModule,

    // TP
    SwiperModule,
    MatProgressBarModule,
    TourMatMenuModule,
    MatSlideToggleModule

  ]/* ,
  schemas: [
    NO_ERRORS_SCHEMA
  ] */,
  exports: [
    RouterModule,
    AutoTrainingComponent
  ],
  providers: [
    ShoppingCartService,
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG
    }  ],
  entryComponents: [ WizardDialogComponent,CheckoutDialogComponent,NotificationDialogComponent,ReportingDialogComponent,ProfileDialogComponent,ExperienceDialogComponent]
})
export class AutoTrainingModule {
}
