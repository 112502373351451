import { Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewEncapsulation, Output, EventEmitter, Input, SimpleChanges } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { absAnimations } from '@abs/animations';
import { FormGroup } from '@angular/forms';
import { DataSource, SelectionModel } from '@angular/cdk/collections';
import { AbsConfirmDialogComponent } from '@abs/components/confirm-dialog/confirm-dialog.component';
import { PlaylistManagerFormComponent } from '../playlist-manager-form/playlist-manager-form.component';
import { PlaylistManagerShareFormComponent } from '../playlist-manager-share-form/playlist-manager-share-form.component';
import { ContentService } from '../../../content.service';
import { Playlist } from '../../../playlists/playlists.model';
import { AbsProgressBarService } from '@abs/components/progress-bar/progress-bar.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { trigger, style, state, transition, animate } from '@angular/animations';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { MatTableDataSource } from '@angular/material/table';
import { ActionBarService } from '../../../../../layout/components/action-bar/action-bar.service';
import { AuthenticationService } from '../../../../authentication/_services';
import { TrainingService } from '../../../../training/_services/training.service';
import { PlayNowDialogComponent } from '../../../play-now-dialog/play-now-dialog.component';
import { ActivityLog, ActivityType, ActivityLogService } from '../../../../service/activity-log.service';
import { environment } from 'environments/environment';
import { MatAccordion } from '@angular/material/expansion';
import * as _ from 'lodash';
import { DocumentViewerDialogComponent } from '../../../../document-viewer-dialog/document-viewer-dialog.component';


export class Data {
  playlist: any;
  selection: boolean;
}

export class SearchResult {

  playlist: any;
  group: any;
  acc: any;
}

@Component({
  selector: 'app-playlist-manager-list',
  templateUrl: './playlist-manager-list.component.html',
  styleUrls: ['./playlist-manager-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class PlaylistManagerListComponent implements OnInit, OnDestroy {

  @ViewChild('favAcc') favAcc: MatAccordion;
  @ViewChild('myAcc') myAcc: MatAccordion;
  @ViewChild('otherAcc') otherAcc: MatAccordion;
  @ViewChild('sysAcc') sysAcc: MatAccordion;

  showPlayer = false;
  preview = true;
  lang: any;
  searchResults: SearchResult[] = [];
  currentVideo: any;
  isLoading = true;
  courseData: string;
  courseDataStatus = 'Course Data';
  playlists: any;
  lastSelected: any;
  dataSourceFavorites: any;
  dataSourceMyPlaylists: any;
  dataSourceSystem: any;
  dataSourceOthers: any;
  lastSelectedAccordion: any;
  displayedColumns = ['buttons', 'fav', 'name', 'newtocompany', 'location', 'department', 'group', 'calendar', 'recurring'];
  kioskDisplayColumns = ['buttons', 'fav', 'name', 'newtocompany', 'location', 'department', 'group', 'calendar', 'recurring', 'kiosk'];
  selectedPlaylists: any[];
  checkboxes: {};
  dialogRef: any;
  confirmDialogRef: MatDialogRef<AbsConfirmDialogComponent>;
  selection = new SelectionModel<Playlist>(false, []);
  currentUserProfileId 
  @Input() selected: SearchResult;

  @Output() playlistSearch = new EventEmitter<any>();

  @Output() playlistSelected = new EventEmitter<Data>();

  groupsExpanded: number;
  environment: any;
  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {GroupsService} _contentService
   * @param {MatDialog} _matDialog
   */
  constructor(private activityLog: ActivityLogService, private _progressBarService: AbsProgressBarService,
    public _snackBar: MatSnackBar, private _contentService: ContentService, private actionBarService: ActionBarService,
    public _matDialog: MatDialog, public playDialog: MatDialog,
    private _docMatDialog: MatDialog,
    private _authService: AuthenticationService, private trainingService: TrainingService) {

    // Set the private defaults
    this._unsubscribeAll = new Subject();
    this.groupsExpanded = 0;
    this.environment = environment;
  }



  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this.currentUserProfileId = this._authService.currentUserValue.profileId;
    if (localStorage.getItem("KIOSK_MODE_ENABLED") == "Y") {
      this.displayedColumns = this.kioskDisplayColumns;
    }


    this._contentService.onPlaylistSearchTextChanged.subscribe(playlistSearchText => {
      console.log(playlistSearchText);
      this.searchResults = [];
      var search = playlistSearchText;

      if (search && search.length > 0) {

        var favorites = _.filter(this.dataSourceFavorites.data, function (list) {
          return list.name.toLowerCase().includes(search.toLowerCase());
        });

        favorites.forEach(list => {
          this.searchResults.push({ playlist: list, group: 'Favorite Playlists', acc: 'fav' })
        })


        var my = _.filter(this.dataSourceMyPlaylists.data, function (list) {
          return list.name.toLowerCase().includes(search.toLowerCase());
        });

        my.forEach(list => {
          this.searchResults.push({ playlist: list, group: 'My Playlists', acc: 'my' })
        })

        var system = _.filter(this.dataSourceSystem.data, function (list) {
          return list.name.toLowerCase().includes(search.toLowerCase());
        });

        system.forEach(list => {
          this.searchResults.push({ playlist: list, group: 'System Playlists', acc: 'sys' })
        })


        var other = _.filter(this.dataSourceOthers.data, function (list) {
          return list.name.toLowerCase().includes(search.toLowerCase());
        });

        other.forEach(list => {
          this.searchResults.push({ playlist: list, group: 'Others Playlists', acc: 'other' })
        })

        this.playlistSearch.emit(this.searchResults);
      }

    });


    this.actionBarService.onContentSelected.next(false);
    this.loadPlaylistData();
    this._contentService.onGroupedPlaylistsChanged
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(playlists => {
        this.isLoading = true;
        this.initPlaylists(playlists);
        this.playlists = playlists;
        var selected = this.selection.selected;
        this.selection.clear();
        selected.map(element => {

          if (this.lastSelectedAccordion == 'fav') {
            this.dataSourceFavorites.data.map(row => {
              if (row.id === element.id) {
                this.selectPlaylist(row, false, 'fav');
              }
            });
          }
          else if (this.lastSelectedAccordion == 'my') {
            this.dataSourceMyPlaylists.data.map(row => {
              if (row.id === element.id) {
                this.selectPlaylist(row, false, 'my');
              }
            });
          }
          else if (this.lastSelectedAccordion == 'other') {
            this.dataSourceOthers.data.map(row => {
              if (row.id === element.id) {
                this.selectPlaylist(row, false, 'my');
              }
            });
          }
          else if (this.lastSelectedAccordion == 'sys') {
            this.dataSourceSystem.data.map(row => {
              if (row.id === element.id) {
                this.selectPlaylist(row, false, 'my');
              }
            });
          }
        });
      });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Edit group
   *
   * @param group
   */
  editGroup(group): void {
    this.dialogRef = this._matDialog.open(PlaylistManagerFormComponent, {
      panelClass: 'group-form-dialog',
      data: {
        group: group,
        action: 'edit'
      }
    });

    this.dialogRef.afterClosed()
      .subscribe(response => {
        if (!response) {
          return;
        }
        const actionType: string = response[0];
        const formData: FormGroup = response[1];
        switch (actionType) {
          /**
           * Save
           */
          case 'save':
            //this._groupsService.updateGroup();
            break;
          /**
           * Delete
           */
          case 'delete':
            this.deleteGroup(group);
            break;
        }
      });
  }

  /**
   * Delete Group
   */
  deleteGroup(group): void {
    this.confirmDialogRef = this._matDialog.open(AbsConfirmDialogComponent, {
      panelClass: 'confirm-form-dialog',
      disableClose: false
    });
    this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to delete?';
    this.confirmDialogRef.afterClosed().subscribe(result => {
      if (result) {
        //this.con.deleteGroup(group);
      }
      this.confirmDialogRef = null;
    });

  }
  removeContent(playlistId, contentId) {
    this._progressBarService.show();
    this._contentService.updatePlaylistUnassign(playlistId, contentId).then(res => {
      this._progressBarService.hide();
      if (res) {
        //this.groupsExpanded = this._contentService.playlists.filter(list => list.id == playlistId)[0].id;
        this._snackBar.open("Content has been removed from the playlist.", 'Ok', {
          panelClass: 'green-bg',
          duration: 3000,
          horizontalPosition: 'right',
          verticalPosition: 'top',
          politeness: 'polite',
        });
      }
    })
  }

  logActivity(id) {
    let activityLog = new ActivityLog();
    activityLog.profileID = JSON.parse(localStorage.getItem("currentUser")).profileId
    activityLog.type = "site";
    activityLog.source = "itrain";
    activityLog.activity = ActivityType.PRINT_QUIZ;
    activityLog.videoID = id;
    this.activityLog.saveActivityLog(activityLog).subscribe(res => { });
  }

  onKioskToggle($event, playlist) {
    this._progressBarService.show();

    this._contentService.updatePlaylistKiosk(playlist).then(res => {
      if (res) {
        this._snackBar.open("Playlist has been updated.", 'Ok', {
          panelClass: 'green-bg',
          duration: 3000,
          horizontalPosition: 'right',
          verticalPosition: 'top',
          politeness: 'polite',
        });
      } else {
        playlist.kiosk = !playlist.kiosk;
        this._snackBar.open("Error while updating playlist.", 'Ok', {
          panelClass: 'red-bg',
          duration: 3000,
          horizontalPosition: 'right',
          verticalPosition: 'top',
          politeness: 'polite',
        });

      }
      this._progressBarService.hide();
    });
  }
  onFavoriteToggle(playlist) {
    this._progressBarService.show();
    playlist.favorite = !playlist.favorite;
    this._contentService.updatePlaylistFavorite(playlist).then(res => {
      if (res) {
        this.loadPlaylistData();
        this._snackBar.open("Playlist has been updated.", 'Ok', {
          panelClass: 'green-bg',
          duration: 3000,
          horizontalPosition: 'right',
          verticalPosition: 'top',
          politeness: 'polite',
        });
      } else {
        playlist.kiosk = !playlist.kiosk;
        this._snackBar.open("Error while updating playlist.", 'Ok', {
          panelClass: 'red-bg',
          duration: 3000,
          horizontalPosition: 'right',
          verticalPosition: 'top',
          politeness: 'polite',
        });

      }
      this._progressBarService.hide();
    });
  }

  selectPlaylist(playlist, expand, acc) {
    //this.handleAccordions(acc);
    if (expand) {
      this.groupsExpanded = this.groupsExpanded === playlist.id ? null : playlist.id
    }
    this.selection.toggle(playlist);
    if (this.selection.isSelected(playlist)) {
      var selection = true;
      if (playlist.system) {
        selection = false;
      }
      this.lastSelected = playlist;
      this.playlistSelected.emit({ playlist: playlist, selection: selection });
      this.actionBarService.onContentSelected.next(true);
    } else {
      this.playlistSelected.emit({ playlist: playlist, selection: false });
      this.lastSelected = null;
      this.actionBarService.onContentSelected.next(false);
    }
    this._contentService.onSelectedPlaylistChanged.next(this.selection.selected);

    sessionStorage.setItem("playlistSelection", JSON.stringify({ playlist: playlist, selection: acc }));

  }

  initPlaylists(playlists) {
    this.playlists = playlists;
    this.dataSourceFavorites = new MatTableDataSource(playlists.favoritePlaylists);
    this.dataSourceMyPlaylists = new MatTableDataSource(playlists.myPlaylists);
    this.dataSourceSystem = new MatTableDataSource(playlists.systemPlaylists);
    this.dataSourceOthers = new MatTableDataSource(playlists.othersPlaylists);
    this.isLoading = false;
  }

  loadPlaylistData() {
    this._contentService.getPlaylistGroupedWithContents().then(content => {
      this.initPlaylists(content);
    })
  }



  viewDocument(playlist) {

    var url = "";
    if (playlist.private) {
      var fileExt = playlist.fileName.split('.').pop();
      var directory = playlist.itemCode.split('.')[0];
      url = `${environment.cdnUrl}/assets/contents/${directory+"/V_"+playlist.itemCode+"."+fileExt}`;
    } else {
      url = `${environment.cdnUrl}/content/${playlist.prodType.toLowerCase().replace('_', '+')}s/${playlist.fileName}`
    }

    this._docMatDialog.open(DocumentViewerDialogComponent, {
      backdropClass: 'previewBackdrop',
      width: '1165px',
      height: '800px',
      data: {
        url: url,
        title: playlist.title
      }
    });
  }


  playnow(video, lang) {

    let viewModel = { companyId: this._authService.currentUserValue.companyId, providerId: this._authService.currentUserValue.providerId, profileId: this._authService.currentUserValue.profileId, type: 'classroom', requestedViews: 1 };

    this.trainingService.checkIfUserCanViewVideo(viewModel).then(res => {

      if (res) {

        this.dialogRef = this.playDialog.open(PlayNowDialogComponent, {
          id: 'preview',
          panelClass: 'start-course-dialog',
          backdropClass: 'previewBackdrop',
          width: '1165px',
         // height: '800px',
          disableClose: true,
          data: {
            video: video,
            preview: true,
            lang: lang
          }
        });

      } else {
        this._snackBar.open(" You dont have enough Classroom views to watch this video. Please contact your manager.", '', {
          panelClass: 'red-bg',
          duration: 2000,
          horizontalPosition: 'right',
          verticalPosition: 'top',
          politeness: 'polite',
        });
      }
    })

  }


  ngOnChanges(changes: SimpleChanges) {
    console.log("changes", changes);
    if (changes.selected && changes.selected.currentValue) {
      this.selectPlaylist(changes.selected.currentValue.playlist, true, '')
      if (changes.selected.currentValue.acc == 'fav') {
        this.favAcc.openAll();
      }
      if (changes.selected.currentValue.acc == 'my') {
        this.myAcc.openAll();
      }
      if (changes.selected.currentValue.acc == 'other') {
        this.otherAcc.openAll();
      }
      if (changes.selected.currentValue.acc == 'sys') {
        this.sysAcc.openAll();
      }
      this.handleAccordions(changes.selected.currentValue.acc);
    }
  }


  handleAccordions(accordion) {
    this.lastSelectedAccordion = accordion;
    if (accordion == 'fav') {
      this.myAcc.closeAll();
      this.otherAcc.closeAll();
      this.sysAcc.closeAll();
    } else if (accordion == 'my') {
      this.favAcc.closeAll();
      this.otherAcc.closeAll();
      this.sysAcc.closeAll();

    } else if (accordion == 'other') {
      this.favAcc.closeAll();
      this.myAcc.closeAll();
      this.sysAcc.closeAll();
    } else if (accordion == 'sys') {
      this.favAcc.closeAll();
      this.myAcc.closeAll();
      this.otherAcc.closeAll();
    }
  }
}