import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class VideoPlayerService {
  onVideoTimeChanged: BehaviorSubject<any>;
  onCuePoint: BehaviorSubject<any>;

  /**
   * Constructor
   * @param {HttpClient} _httpClient
   */
  constructor(
    private _httpClient: HttpClient
  ) {
    this.onVideoTimeChanged = new BehaviorSubject(0);
    this.onCuePoint = new BehaviorSubject(0);

  }
}
