import { Component, OnInit, Output, EventEmitter, Input, ViewEncapsulation } from '@angular/core';
import { Quiz } from '../../../quiz/models';
import { absAnimations } from '@abs/animations';

@Component({
  selector: 'app-quiz-passing-score',
  templateUrl: './quiz-passing-score.component.html',
  styleUrls: ['./quiz-passing-score.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: absAnimations
})
export class QuizPassingScoreComponent implements OnInit {

  @Input()
  quiz: Quiz

  flip:any;
  @Output() onBackButton = new EventEmitter<any>();
  @Output() onSelection = new EventEmitter<any>();
  @Output() onDone = new EventEmitter<any>();
  constructor() { }

  ngOnInit(): void {
    console.log("quiz",this.quiz)
  }
  descClicked(abc){
    
  }
  selectTrainingMethod(abc){

  }
  handleBack(){
    this.onBackButton.next("quiz-passing")
  }

  handleAction(type){
    this.onDone.next({value:'',type:type})
  }
  

}
