import { Component, Input, OnInit, ViewEncapsulation, EventEmitter, Output } from '@angular/core';
import { absAnimations } from '@abs/animations';
import { environment } from 'environments/environment';
import { Content } from '../../_models/playlist';
import { AuthenticationService } from '../../../authentication/_services';


export class QuizData{
  video: Content;
  training: any;
  lang: string;
}

@Component({
  selector: 'app-my-training-content-item-mobile',
  templateUrl: './my-training-content-item-mobile.component.html',
  styleUrls: ['./my-training-content-item-mobile.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: absAnimations
})
export class MyTrainingContentItemMobileComponent implements OnInit {

  langOption = false;
  ratio: any;
  enviorment: any;
  certificateURL : any;
  @Input() video: Content;
  @Input() training: any;
  @Input() classId: any;
  @Output() onVideoPlay = new EventEmitter<QuizData>();
  @Output() onVideoEnded = new EventEmitter<QuizData>();
  @Output() onRetakeQuiz= new EventEmitter<QuizData>();

  preview = true;
  lang: any = "en";
  playing = false;
  constructor(private authService: AuthenticationService) { }

  ngOnInit() {
    this.certificateURL = `${environment.apiUrl}/training/certificate?videoLogId=${this.video.videoLogId}`;
    this.enviorment = environment;
    if (this.video.aspectRatio == 0.75) {
      this.ratio = "4:3";
    } else {
      this.ratio = "16:9";
    }
  }
  onImgError(event) {
    event.target.src = this.enviorment.cdnUrl + '/' + this.enviorment.thumbDir + '/T_.jpg';
  }

  playnow(lang) {
    this.preview = false;
    this.lang = lang;
    this.onVideoPlay.emit({video:this.video, training: this.training,lang:this.lang})
  }
  retakeQuiz() {
    this.onRetakeQuiz.emit({video:this.video, training: this.training,lang:this.lang})
  }
}
