import { Component, OnInit, ViewEncapsulation, Output, EventEmitter, Input } from '@angular/core';
import { absAnimations } from '@abs/animations';
import { FormControl } from '@angular/forms';
import { SelectionModel } from '@angular/cdk/collections';
import { AuthenticationService } from '../../../authentication/_services';
import { GroupsService } from '../../../user/groups/groups.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AbsUtils } from '@abs/utils';
import { ContentService } from '../../content.service';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { FileData } from '../content-wizard.component';


export interface Tag {
  tag: string;
  tagNoSpace: string;
  profileId: number;
  companyId;
}

@Component({
  selector: 'app-content-tag-selector',
  templateUrl: './content-tag-selector.component.html',
  styleUrls: ['./content-tag-selector.component.scss'],
  animations: absAnimations
})
export class ContentTagSelectorComponent implements OnInit {

  savingStatus = ''
  flip: any;
  tags: any;
  allTags: any;
  search = new FormControl();
  selection = new SelectionModel<any>(true, []);
  addTag: boolean = false;

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  newTags: Tag[] = [
  ];

  @Input()
  categories: any;

  @Input() fileData: FileData
  @Output() onBackButton = new EventEmitter<any>();
  @Output() onSelection = new EventEmitter<any>();
  @Output() onDone = new EventEmitter<any>();

  constructor(private contentService: ContentService,
    private authService: AuthenticationService,
  ) { }

  ngOnInit(): void {

    this.search.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe(value => {
      if (value.length > 0) {
        var result = AbsUtils.filterArrayByString(this.tags, value);
        this.tags = result;
      } else {
        this.tags = this.allTags;

      }
    });
    var allCats = [];
    var categoriesId = this.categories.map(x => {
      return x.id;
    });
    allCats = categoriesId;
    if (this.fileData && this.fileData.subCategories) {
      var subCategories = this.fileData.subCategories.map(x => {
        return x.id;
      });
      allCats = categoriesId.concat(subCategories);
    }

    if (this.categories) {
      var subCategories = this.categories.map(category => {
        if(category.subCategories){
        category.subCategories.map(x => {
          allCats.push(x.id)
        });
      }
      });
    }
    var search = {

      categories: allCats,
      providerId: this.authService.currentUserValue.providerId,
      companyId: this.authService.currentUserValue.companyId,
      profileId: this.authService.currentUserValue.profileId,
      departmentId: this.authService.currentUserValue.department

    };

    this.contentService.searchProductsByCateogories(search).subscribe(result => {
      if (result && result.length > 0) {
        var productids = result.map(x => {
          return x.id;
        });
        this.contentService.getTags(productids).subscribe(tags => {
          this.tags = tags;
          this.allTags = tags;
        });
      } else {
        //this.tags = [];
      }
    })


  }


  onContinue() {
    this.onDone.next({ data: this.selection.selected, type: 'TAGS' })
  }
  handleBack() {
    this.onBackButton.next("asd")
  }

  saveTags() {
    if (!this.tags) {
      this.tags = [];
    }
    if (this.newTags.length > 0) {

      this.newTags.forEach(tag => {
        var result = this.tags.filter(obj => {
          return obj.tag === tag.tag
        })
        console.log("result", result);
        if (result && result.length == 0) {
          this.tags.push(tag);
          this.selection.select(tag);
          this.addTag = false;
        }
      })

      this.savingStatus = 'saving';
      /* this.contentService.insertTags(this.newTags).then(response=>{
        this.savingStatus = '';
        this.addTag = false;
      }) */
    }
  }

  onChange(event) {
    this.selection.toggle(event);
  } descClicked(asd) { }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      this.newTags.push({ tag: value.trim(), tagNoSpace: value.trim(), profileId: this.authService.currentUserValue.profileId, companyId: this.authService.currentUserValue.companyId });
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  remove(tag: Tag): void {
    const index = this.newTags.indexOf(tag);

    if (index >= 0) {
      this.newTags.splice(index, 1);
    }
  }
}
