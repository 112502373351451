import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-agenda-item-course-detail-student',
  templateUrl: './agenda-item-course-detail-student.component.html',
  styleUrls: ['./agenda-item-course-detail-student.component.css']
})
export class AgendaItemCourseDetailStudentComponent implements OnInit {

 
  @Input()
  course: any;
  
  constructor() { 
    console.log("i am in student view cons")

  }

  ngOnInit() {
    console.log("i am in student view")
  }


}
