import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-third-party-streaming-verification',
  templateUrl: './third-party-streaming-verification.component.html',
  styleUrls: ['./third-party-streaming-verification.component.scss']
})
export class ThirdPartyStreamingVerificationComponent implements OnInit {


  noCodeFound = false;

  constructor( public matDialogRef: MatDialogRef<ThirdPartyStreamingVerificationComponent>,@Inject(MAT_DIALOG_DATA) private _data: any,) {

    this.noCodeFound = _data.noCodeFound;

   }

  ngOnInit(): void {
  }

}
