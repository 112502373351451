import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { QuizService } from '../../../content/quiz/services/quiz.service';
import { absAnimations } from '@abs/animations';
import { Quiz, QuizConfig, Question, Option } from '../../../content/quiz/models';
import { Content, PlayList } from '../../_models/playlist';
import { TrainingService } from '../../_services/training.service';
import { ActivityLogService, ActivityLog, ActivityType } from '../../../service/activity-log.service';
import { MatDialogRef } from '@angular/material/dialog';
import { VideoLog } from '../../../content/video-log.model';
import { ActivatedRoute } from '@angular/router';
import { ContentService } from '../../../content/content.service';
import { SelectionModel } from '@angular/cdk/collections';

@Component({
  selector: 'app-class-quiz',
  templateUrl: './class-quiz.component.html',
  styleUrls: ['./class-quiz.component.scss'],
  providers: [QuizService],
  encapsulation: ViewEncapsulation.None,
  animations: absAnimations
})
export class ClassQuizComponent implements OnInit {
  selection = new SelectionModel<Option>(false /* multiple */);

  videoLogId: any;
  spanishQuiz: boolean;
  classId: any;
  type: any;
  videoTime: any;
  quizes: any[];
  quiz: Quiz = new Quiz(null);
  mode = 'quiz';
  training: PlayList
  lang = false;
  content: Content;
  backClicked = true;

  config: QuizConfig = {
    'allowBack': true,
    'allowReview': true,
    'autoMove': true,  // if true, it will move to next question automatically when answered.
    'duration': 9000000000,  // indicates the time (in secs) in which quiz needs to be completed. 0 means unlimited.
    'pageSize': 1,
    'requiredAll': false,  // indicates if you must answer all the questions before submitting.
    'richText': false,
    'shuffleQuestions': false,
    'shuffleOptions': false,
    'showClock': false,
    'showPager': true,
    'theme': 'none'
  };

  pager = {
    index: 0,
    size: 1,
    count: 1
  };
  timer: any = null;
  startTime: Date;
  endTime: Date;
  ellapsedTime = '00:00';
  duration = '';

  constructor( private route: ActivatedRoute,
    private trainService: TrainingService,
    private activityLog: ActivityLogService, 
    private quizService: QuizService,
    private trainingService: TrainingService,
    private contentService: ContentService
   ) {
    this.route.queryParams.subscribe(params => {
      console.log("params abc",params)
      const pid = params['pid'];
      const cid = params['cid'];
      this.classId = cid;
      this.trainingService.getTraining(cid).then(training=>{
        this.training = training;
      })
     
      this.contentService.getProductById(pid).subscribe(res=>{
        this.content = res;      
        let activityLog = new ActivityLog();
        activityLog.profileID = JSON.parse(localStorage.getItem("currentUser")).profileId
        activityLog.type ="site";
        activityLog.source = "itrain";
        activityLog.videoID = this.content.id;
        activityLog.activity = ActivityType.QUIZ;
        this.activityLog.saveActivityLog(activityLog).subscribe(res=>{});
        this.loadQuiz(this.content.id);      
       
      })
    });
  }

  ngOnInit() {
    console.log("init",this.content);
   
  }

  loadQuiz(contentId) {
    this.quizService.getQuizDetail(contentId,this.spanishQuiz,this.classId).subscribe(res => {
      if (res) {
        this.quiz = new Quiz(res);
        console.log(this.quiz);
        this.pager.count = this.quiz.questions.length;
        this.startTime = new Date();
        this.ellapsedTime = '00:00';
        this.timer = setInterval(() => { this.tick(); }, 1000);
        this.duration = this.parseTime(this.config.duration);
        this.insertLog();

       
      }
    });
    this.mode = 'quiz';
  }

  tick() {
    const now = new Date();
    const diff = (now.getTime() - this.startTime.getTime()) / 1000;
    if (diff >= this.config.duration) {
      this.onSubmit();
    }
    this.ellapsedTime = this.parseTime(diff);
  }

  parseTime(totalSeconds: number) {
    let mins: string | number = Math.floor(totalSeconds / 60);
    let secs: string | number = Math.round(totalSeconds % 60);
    mins = (mins < 10 ? '0' : '') + mins;
    secs = (secs < 10 ? '0' : '') + secs;
    return `${mins}:${secs}`;
  }

  get filteredQuestions() {
    return (this.quiz.questions) ?
      this.quiz.questions.slice(this.pager.index, this.pager.index + this.pager.size) : [];
  }

  onSelect(question: Question, option: Option) {

    this.selection.select(option);
    option.selected = true;

    if (question.questionTypeId === 1) {
      question.answers.forEach((x) => { if (x.answerNo !== option.answerNo) x.selected = false; });
    }
    if (this.config.autoMove) {
      this.goTo(this.pager.index + 1);
    }
  }

  goTo(index: number) {
    if (index >= 0 && index < this.pager.count) {
      this.pager.index = index;
      this.mode = 'quiz';
    }
  }

  isAnswered(question: Question) {
    return question.answers.find(x => x.selected) ? 'Answered' : 'Not Answered';
  };

  findUserAnswer(question: Question) {
    return question.answers.find(x => x.selected);
  };

  isCorrect(question: Question) {

    let correctAnswers = question.answers.filter(x=> x.correct == true);
    let selectedAnswers = question.answers.filter(x=> x.selected == true);
    return correctAnswers.every(x => x.selected == x.correct && x.correct == true && x.selected == true) && correctAnswers.length == selectedAnswers.length ? 'correct' : 'wrong';
  };

  onSubmit() {
    let quizResponses = [];
    this.quiz.questions.forEach(x => {
      if (this.isAnswered(x) == 'Answered') {
        var quizResponse = {
          profileID: JSON.parse(localStorage.getItem("currentUser")).profileId,
          quizID: this.quiz.id,
          questionNo: x.questionNo,
          answerNo: this.findUserAnswer(x).answerNo,
          question: x.question,
          answer:this.findUserAnswer(x).answer,
          correct: this.isCorrect(x) == 'correct' ? true : false
        };
        quizResponses.push(quizResponse);
      }
    });

 
      var videoLog = new VideoLog({});
      videoLog.quizResult = this.getQuizScore(this.quiz);
      videoLog.quizResp = quizResponses;     
      videoLog.profileId = JSON.parse(localStorage.getItem("currentUser")).profileId
      //videoLog.comments = this.spanishQuiz ? 'es' : 'en';
      videoLog.type = "CLASSROOM";
      videoLog.productId = this.content.id;
      videoLog.classId = this.classId;
      videoLog.id = this.videoLogId;

      this.trainService.updateVideoLog(videoLog).then(res => {
      })
        this.mode = 'result';
  }

  getQuizScore(quiz): number {
    var totalCorrect = 0;
    quiz.questions.forEach(question => {
      if (this.isCorrect(question) == 'correct') {
        totalCorrect = totalCorrect + 1;
      }
    });
    var result = (totalCorrect / quiz.questions.length) * 100;
    return result;
  }

  getNextLetter(num): String {
    var code: number = "ABCDEFGHIJKLMNOPQRST".charCodeAt(num);
    return String.fromCharCode(code);
  }

  insertLog() {
    var videoLog = new VideoLog({
      profileId: JSON.parse(localStorage.getItem("currentUser")).profileId,
      productId: this.content.id,
      itemName: this.content.title,
      classId: this.classId,
      comments: "QUIZ_ENTRY"
    });
    this.trainService.insertVideoLog(videoLog).then(vid => {
      this.videoLogId = vid;
      this.trainingService.getTrainingCourseResult(this.classId,this.content.id).then(res=>{});
      var request = {
        profileId: JSON.parse(localStorage.getItem("currentUser")).profileId,
        providerId: JSON.parse(localStorage.getItem("currentUser")).providerId,
        productId: this.content.id,
        classId: this.classId,
        videoLogId: vid,
        companyId: JSON.parse(localStorage.getItem("currentUser")).companyId,
      };
      this.trainingService.deductClassroomQuizView(request).then(res=>{
      });
    });
  }

  goBack() {
    this.backClicked = true;
    this.goTo(this.pager.index - 1);
  }
  goNext() {
    //this.backClicked = false;
    this.goTo(this.pager.index + 1);
  }

}