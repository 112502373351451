import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {LandingPageComponent} from './landing-page.component';
import {AbsSharedModule} from '@abs/shared.module';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import {TileService} from './tile.service';
import { MatMenuModule } from '@angular/material/menu';
import { KioskComponent } from './kiosk/kiosk.component';
import { MatCardModule } from '@angular/material/card';
import { UserInfoDialogComponent } from './user-info-dialog/user-info-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatInputModule } from '@angular/material/input';
import { KioskPlayerComponent } from './kiosk-player/kiosk-player.component';
import { KioskPlayerQuizConfirmDialogComponent } from './kiosk-player-quiz-confirm-dialog/kiosk-player-quiz-confirm-dialog.component';
import { KioskPlayerNextVideoDialogComponent } from './kiosk-player-next-video-dialog/kiosk-player-next-video-dialog.component';
import { KioskQuizToggleDialogComponent } from './kiosk-quiz-toggle-dialog/kiosk-quiz-toggle-dialog.component';
import { QuizComponent } from '../content/quiz/quiz.component';
import { ContentModule } from '../content/content.module';
import { LandingPageRouting } from './landing-page.routing';
import { NgIdleModule } from '@ng-idle/core';
import { AdminCredentialsDialogComponent } from './admin-credentials-dialog/admin-credentials-dialog.component';
import { AvatarModule } from 'ngx-avatar';
import { UserAvatarModule } from '@abs/components/user-avatar/user-avatar.module';
import { CertificateActionComponent } from './certificate-action/certificate-action.component';
import { TriggerDialogComponent } from './trigger-dialog/trigger-dialog.component';
import { EmailContentService } from '../emails/email.service';
import { TourMatMenuModule } from 'ngx-tour-md-menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { MatBadgeModule } from '@angular/material/badge';
import { SecondaryPasswordDialogComponent } from './secondary-password-dialog/secondary-password-dialog.component';
import { SiteHelpDialogModule } from '../site-help-dialog/site-help-dialog.module';
import { SiteHelpDialogComponent } from '../site-help-dialog/site-help-dialog.component';
import { KioskPlaylistSelectorComponent } from './kiosk-playlist-selector/kiosk-playlist-selector.component';
import { AbsWidgetModule } from '@abs/components';
import { AbsDirectivesModule } from '@abs/directives/directives';
import { MatListModule } from '@angular/material/list';




@NgModule({
  declarations: [LandingPageComponent, KioskComponent, UserInfoDialogComponent, KioskPlayerComponent, KioskPlayerQuizConfirmDialogComponent, KioskPlayerNextVideoDialogComponent, KioskQuizToggleDialogComponent, AdminCredentialsDialogComponent, CertificateActionComponent, TriggerDialogComponent, SecondaryPasswordDialogComponent, KioskPlaylistSelectorComponent],
  imports: [
    CommonModule,
    LandingPageRouting,
    AbsSharedModule,
    MatButtonModule,
    MatIconModule,
    HttpClientModule,
    MatMenuModule,
    MatCardModule,
    MatDialogModule,
    MatFormFieldModule,
    MatToolbarModule,
    MatInputModule,
    ContentModule,
    UserAvatarModule,
    TourMatMenuModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatBadgeModule,
    NgIdleModule.forRoot(),
    SiteHelpDialogModule,
    AbsWidgetModule,
    AbsDirectivesModule,
    MatListModule

  ],
  exports:[LandingPageComponent,CertificateActionComponent],
  providers: [TileService,EmailContentService],
  entryComponents: [SiteHelpDialogComponent,UserInfoDialogComponent,KioskPlayerNextVideoDialogComponent,KioskPlayerQuizConfirmDialogComponent,KioskQuizToggleDialogComponent,QuizComponent,AdminCredentialsDialogComponent,CertificateActionComponent,TriggerDialogComponent,SecondaryPasswordDialogComponent,KioskPlaylistSelectorComponent]
})
export class LandingPageModule {
}
