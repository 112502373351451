import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { MyCalendarService } from '../../_services/my-calendar.service';
import { trigger, state, transition, style, animate } from '@angular/animations';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-agenda-item-course-detail',
  templateUrl: './agenda-item-course-detail.component.html',
  styleUrls: ['./agenda-item-course-detail.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*', marginTop: '12px', marginBottom: '12px' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class AgendaItemCourseDetailComponent implements OnInit {
  columnsToDisplay = ['buttons','avatar','stats','quizAttempts', 'name', 'averageScore', 'percentComplete'];

  @Input()
  training: any

  dataSource: any;
  loading = true;
  selectedRow: any;
  expandedElement: any;

  constructor(private myCalendarService: MyCalendarService) { }

  ngOnInit() {
    this.loadTraining();
  }

  async loadTraining() {
    this.myCalendarService.getTrainingByIdGroupByCourse(this.training.id).then(result => {
      this.dataSource = new MatTableDataSource(result.courses);
      this.loading = false;
    })
  }
}


