import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AbsProgressBarService } from '@abs/components/progress-bar/progress-bar.service';
import { Content } from '../../_models/playlist';
import { TrainingService } from '../../_services/training.service';
import { VideoLog } from '../../../content/video-log.model';
import { VideoPlayerService } from '../../../content/videos/video-player/video-player.service';
import { AuthenticationService } from '../../../authentication/_services';
import { UserLevel } from '../../../models/user';
import { ActivityLog, ActivityType, ActivityLogService } from '../../../service/activity-log.service';
import { ThirdPartyStreamingVerificationComponent } from '../../../content/videos/preview/third-party-streaming-verification/third-party-streaming-verification.component';

@Component({
  selector: 'app-start-course-dialog',
  templateUrl: './start-course-dialog.component.html',
  styleUrls: ['./start-course-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,

})
export class StartCourseDialogComponent implements OnInit {
  videoId="djDsK-rvnxw";
  preview: boolean;
  video: Content;
  classId: any;
  canView: false
  isLoading = true;
  lang: any;
  viewType: any;
  verificationMode = false;
  isThirdPartyVideo = false;

  constructor(public matDialogRef: MatDialogRef<StartCourseDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private _snackBar: MatSnackBar,
    private _progressBarService: AbsProgressBarService,
    private _trainingService: TrainingService,
    private _authService: AuthenticationService,
    private activityLog: ActivityLogService,
    private playerService: VideoPlayerService,
    private _verificationDialog: MatDialog,

  ) {
    this.video = _data.video;
    this.preview = _data.preview;
    this.classId = _data.classId;
    this.lang = _data.lang;
    this.viewType = _data.format == 'Online'? 'INDIVIDUAL': 'classroom';
    
    

  }



  

  ngOnInit() {
    this.checkIfVerficationMode();

   // let viewType = this._authService.currentUserValue.userLevel == UserLevel.LEARNER ? 'INDIVIDUAL' : 'classroom';

    let viewModel = {companyId:this._authService.currentUserValue.companyId, classId: this.classId, providerId: this._authService.currentUserValue.providerId, profileId:this._authService.currentUserValue.profileId, type: this.viewType, requestedViews: 1 };

    this._trainingService.checkIfUserCanViewVideo(viewModel).then(res => {
      this.isLoading = false;
      this.canView = res;

      if (this.canView) {


        let activityLog = new ActivityLog();
        activityLog.profileID = JSON.parse(localStorage.getItem("currentUser")).profileId
        activityLog.type = "site";
        activityLog.source = "itrain";
        activityLog.activity = ActivityType.STARTED_TRAINING;
        activityLog.videoID = this.video.id;
        
        this.activityLog.saveActivityLog(activityLog).subscribe(res => { });

        if(!this.verificationMode){
          setTimeout(() => {
              this.preview = false;
          }, 1500);
        }



        /* this.playerService.onCuePoint.subscribe(cuepoint => {
          console.log("cue",cuepoint);
          if (cuepoint.type) {
            var viewLog = {
              videoLogId: this.video.videoLogId,
              totalViewed: cuepoint.time,
              companyId: this._authService.currentUserValue.companyId,
              type: this.viewType
            };

            console.log("deduct", viewLog);
            this._trainingService.deductView(viewLog).then(res => {
              console.log(res);
            })
            //dedecut view
            console.log("deduct view")
          } else {
            var videoLog = new VideoLog({});
            videoLog.id = this.video.videoLogId;
            videoLog.totalViewed = cuepoint.start;
            console.log("videoLog", videoLog);
            this._trainingService.updateVideoLog(videoLog).then(res => {
              console.log(res);
            })
          }
        })
        var videoLog = new VideoLog({
          profileId: JSON.parse(localStorage.getItem("currentUser")).profileId,
          productId: this.video.id,
          itemName: this.video.title,
          classId: this.classId,
          comments: this.lang
        });
        this._trainingService.insertVideoLog(videoLog).then(res => {
          this.video.videoLogId = res;
        }); */

      }
    })
  }
  timeViewedChanged(event) {
    console.log(event);
  }

  checkIfVerficationMode() {
    if (this.video.producerID == 26 && this.video.vendorID == 15) {
      this.isThirdPartyVideo = true;
    }
    const alreadyVerified = localStorage.getItem("thirdPartyVerified");
    if (this.isThirdPartyVideo) {
      if (alreadyVerified && alreadyVerified === 'Y') {
        this.verificationMode = false;
        this.videoId = this.video.sku;

      } else {
        this.verificationMode = true;
      }
    }

    if (this.verificationMode) {
      this._verificationDialog.open(ThirdPartyStreamingVerificationComponent, {
        id: 'verification',
        backdropClass: 'previewBackdrop',
        width: '500px',
        disableClose: true,
        data: {
          noCodeFound: false
        }
      });
    }
  }
  handleCode(value) {
    if (value == '1340') {
      localStorage.setItem("thirdPartyVerified", "Y");
      this.verificationMode = false;
      this.videoId = this.video.sku;
    } else if (value == 'NO') {
      this._verificationDialog.open(ThirdPartyStreamingVerificationComponent, {
        id: 'verification',
        backdropClass: 'previewBackdrop',
        width: '500px',
        disableClose: true,
        data: {
          noCodeFound: true
        }
      });
    }
  }
}
