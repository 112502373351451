import { Component, OnInit, Input, SimpleChanges, Output, EventEmitter, ViewEncapsulation, Inject } from '@angular/core';
import { Platform } from '@angular/cdk/platform';
import { environment } from 'environments/environment';
import { QuizComponent } from '../../quiz/quiz.component';
import { MatDialog } from '@angular/material/dialog';
import { Content } from '../../../training/_models/playlist';
import { QuizService } from '../../quiz/services/quiz.service';
import { VideoPlayerService } from './video-player.service';
import { interval } from 'rxjs';
import { takeWhile, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { VideoLog } from '../../video-log.model';
import { TrainingService } from '../../../training/_services/training.service';
import { ActivityType, ActivityLogService, ActivityLog } from '../../../service/activity-log.service';
import { AbsConfigService } from '@abs/services/config.service';
import { DOCUMENT } from '@angular/common';
import { AbsConfig } from '@abs/types';
import { ContentService } from '../../content.service';

declare var jQuery: any;
declare var flowplayer: any;

@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss'],
  encapsulation: ViewEncapsulation.None,

})


export class VideoPlayerComponent implements OnInit {

  @Input() view: any;

  @Input() video: Content;

  @Input() preview: boolean = false;
  @Input() showQuiz: boolean = false;
  @Input() classId: any;
  @Input() lang: string = "en";
  @Output() timeViewed = new EventEmitter<string>();
  @Output() videoEnded = new EventEmitter<Content>();

  isBookmarked = false;
  elem: any;

  disabled: boolean = false;
  disclaimerDir = "Disclaimer-1003";
  customDisclaimer = false;
  showProducer = false;
  environment: any;
  flPlayer: any;
  matDialog: MatDialog;
  spanish = false;
  mainVideo = false;

  constructor(private _platform: Platform,
    public _dialog: MatDialog,
    @Inject(DOCUMENT) private document: any,
    public activityLog: ActivityLogService,
    public playerService: VideoPlayerService,
    public trainingService: TrainingService,
    private _absConfigService: AbsConfigService,
    private _contentService: ContentService
  ) {
    this.environment = environment;
    this.matDialog = _dialog;
  }
  ngOnInit() {

    if (this.lang === "es") {
      this.spanish = true;
    }
    if (this.classId) {
      this.trainingService.getTraining(this.classId).then(training => {
        this.disabled = !training.fastForward;
      })
    }

    const absConfig: AbsConfig = {
      colorTheme: 'theme-default',
      customScrollbars: true,
      layout: {
        style: 'horizontal-layout-1',
        width: 'fullwidth',
        navbar: {
          primaryBackground: 'abs-navy-900',
          secondaryBackground: 'abs-navy-700',
          folded: false,
          hidden: true,
          position: 'left',
          variant: 'vertical-style-1'
        },
        toolbar: {
          background: 'abs-white-500',
          customBackgroundColor: true,
          hidden: true,
          position: 'above'
        },
        footer: {
          customBackgroundColor: true,
          background: 'abs-navy-900',
          hidden: false,
          position: 'below-fixed'
        },
        sidepanel: {
          hidden: true,
          position: 'right'
        }
      }
    };

    this.initVideos();
    if (this._platform.ANDROID || this._platform.IOS) {
      this.elem = document.documentElement;
      this.openFullscreen();
      this._absConfigService.setConfig(absConfig, { emitEvent: true });
    }

    this._contentService.checkIfProductBookmarked(this.video.id).subscribe(result=>{
      this.isBookmarked = result;
    })

    var videoLog = new VideoLog({
      profileId: JSON.parse(localStorage.getItem("currentUser")).profileId,
      productId: this.video.id,
      itemName: this.video.title,
      classId: this.classId,
      comments: this.lang
    });
    this.trainingService.insertVideoLog(videoLog).then(res => {
      this.video.videoLogId = res;
    });

    let activityLog = new ActivityLog();
    activityLog.profileID = JSON.parse(localStorage.getItem("currentUser")).profileId
    activityLog.type = "site";
    activityLog.source = "itrain";
    activityLog.videoID = this.video.id;
    if (this.preview) {
      activityLog.activity = ActivityType.PREVIEW;
    } else {
      activityLog.activity = ActivityType.WATCH;

    }
    this.activityLog.saveActivityLog(activityLog).subscribe(res => { });
  }

  initVideos() {
    const self = this;

    let testVideo1 =
      {
        src: "https://devstreaming-cdn.apple.com/videos/streaming/examples/img_bipbop_adv_example_fmp4/master.m3u8", type: "application/x-mpegURL",
        title: '1'
      }


    let testVideo2 =
      {
        src: "https://multiplatform-f.akamaihd.net/i/multi/april11/sintel/sintel-hd_,512x288_450_b,640x360_700_b,768x432_1000_b,1024x576_1400_m,.mp4.csmil/master.m3u8", type: "application/x-mpegURL",
        title: '2',
      }

    let testVideo3 =
      {
        src: "https://multiplatform-f.akamaihd.net/i/multi/april11/sintel/sintel-hd_,512x288_450_b,640x360_700_b,768x432_1000_b,1024x576_1400_m,.mp4.csmil/master.m3u8", type: "application/x-mpegURL",
        title: '3',
        main: true
      }


    var testVideos = [testVideo1, testVideo2, testVideo3];

    if (this.preview) {
      var allVideos = [
        {
          src: this.environment.cdnUrl + "/video/V_" + this.video.itemCode + "/preview.m3u8", type: "application/x-mpegURL",
          title: this.video.title

        }]

      this.flPlayer = flowplayer.playlist('.flowplayer', {
        disabled: self.disabled,
        //disabled: false,
        auto_orient: true,
        playlist: allVideos,
        advance: true, // Whether to auto-advance playlist,
        delay: 0, // 0 seconds between clips 
        token: "eyJraWQiOiJGaGVJc1JQM0c5NUMiLCJ0eXAiOiJKV1QiLCJhbGciOiJFUzI1NiJ9.eyJjIjoie1wiYWNsXCI6NixcImlkXCI6XCJGaGVJc1JQM0c5NUNcIn0iLCJpc3MiOiJGbG93cGxheWVyIn0.JsuBrKMzkCyXqmwl9YevhbzdYnLdmbZNrGGO2tRHbozNKOU3HA8K3TtqN2oJXPs6EEILPAfVJv5JH0yeolO9lQ",
      })

    } else {

      let disclaimerVideo =
        {
          src: this.environment.cdnUrl + "/preroll/" + this.disclaimerDir + "/master.m3u8", type: "application/x-mpegURL",
          title: this.video.title,
        }
      let presentedBy =
        {
          src: this.environment.cdnUrl + "/preroll/PresentedBy-1003/master.m3u8", type: "application/x-mpegURL",
          title: this.video.title
        }
      let mainVideo =
        {
          src: this.environment.cdnUrl + "/video/V_" + this.video.itemCode + "/master.m3u8", type: "application/x-mpegURL",
          title: this.video.title,
          main: true
        }
      let mainVideoSpanish =
        {
          src: this.environment.cdnUrl + "/video/V_" + this.video.itemCode + "_S/master.m3u8", type: "application/x-mpegURL",
          title: this.video.title,
          main: true
        }
      let endVideo = {
        src: this.environment.cdnUrl + "/postroll/Out-1003/master.m3u8", type: "application/x-mpegURL",
        title: this.video.title
      }
      var allVideosTraining = [];

      allVideosTraining.push(disclaimerVideo);
      allVideosTraining.push(presentedBy);
      if (self.lang == 'en') {
        allVideosTraining.push(mainVideo);
      } else if (self.lang == 'es') {
        allVideosTraining.push(mainVideoSpanish);

      }
      allVideosTraining.push(endVideo);

      var totalTimeViewed = 0;
      var lastSecond = null;
      var secondsToCallFunction = 1;
      var viewDeducted = false;

      this.flPlayer = flowplayer.playlist('.flowplayer1', {
        disabled: self.disabled,
        // cuepoints: cuepoints,
        auto_orient: true,
        playlist: allVideosTraining,
        qsel: {},
        plugins: ['hls', 'qsel'],
        //playlist: testVideos,
        //draw_cuepoints: true,
        advance: true, // Whether to auto-advance playlist,
        delay: 0, // 0 seconds between clips 
        token: "eyJraWQiOiJGaGVJc1JQM0c5NUMiLCJ0eXAiOiJKV1QiLCJhbGciOiJFUzI1NiJ9.eyJjIjoie1wiYWNsXCI6NixcImlkXCI6XCJGaGVJc1JQM0c5NUNcIn0iLCJpc3MiOiJGbG93cGxheWVyIn0.JsuBrKMzkCyXqmwl9YevhbzdYnLdmbZNrGGO2tRHbozNKOU3HA8K3TtqN2oJXPs6EEILPAfVJv5JH0yeolO9lQ",
      }).on("loadstart", function (e) {
      }).on("timeupdate", function (e) {
        var index = self.flPlayer.playlist.queue.idx;
        if (self.flPlayer.playlist.queue.members[index].main) {
          var seconds = Math.floor(e.target.currentTime);
          var time25Perc = Math.floor(e.target.duration) * 0.25;

          if (seconds % secondsToCallFunction == 0 && lastSecond !== seconds) {
            lastSecond = seconds
            var videoLog = new VideoLog({
              id: self.video.videoLogId,
              totalViewed: seconds,
              videoDuration: e.target.duration,
            });
            self.trainingService.updateVideoLog(videoLog).then(res => {
              console.log(res);
            })
            if (seconds >= time25Perc && !viewDeducted) {
              viewDeducted = true;
              var request = {
                profileId: JSON.parse(localStorage.getItem("currentUser")).profileId,
                providerId: JSON.parse(localStorage.getItem("currentUser")).providerId,
                productId: self.video.id,
                classId: self.classId,
                companyId: JSON.parse(localStorage.getItem("currentUser")).companyId,
                videoLogId: self.video.videoLogId
              };
              if (self.view == 'CLASSROOM') {
                self.trainingService.deductClassroomView(request).then(res => {
                  let activityLog = new ActivityLog();
                  activityLog.profileID = JSON.parse(localStorage.getItem("currentUser")).profileId
                  activityLog.type = "view";
                  activityLog.source = "itrain";
                  activityLog.activity = ActivityType.CONSUME_CLASSROOM;
                  activityLog.videoID = self.video.id
                  self.activityLog.saveActivityLog(activityLog).subscribe(res => { });
                });
              } else {
                self.trainingService.deductView(request).then(res => {
                  let activityLog = new ActivityLog();
                  activityLog.profileID = JSON.parse(localStorage.getItem("currentUser")).profileId
                  activityLog.type = "view";
                  activityLog.source = "itrain";
                  activityLog.activity = ActivityType.CONSUME_LEARNER;
                  activityLog.videoID = self.video.id
                  self.activityLog.saveActivityLog(activityLog).subscribe(res => { });
                });
              }
            }
          }
        }
      })
        .on("progress", function (e) {
        }).on("ended", function (e) {
        }).on(flowplayer.playlist.events.PLAYLIST_READY, function (e) {
        }).on(flowplayer.playlist.events.PLAYLIST_NEXT, function (e) {
        }).on(flowplayer.playlist.events.PLAYLIST_ENDED, function (e) {
          console.log("playlist ended", e);
          self.flPlayer.toggleFullScreen(false)
          self.videoEnded.emit(self.video);
          if (self.showQuiz) {
            self._dialog.open(QuizComponent, {
              id: 'quiz',
              backdropClass: 'previewBackdrop',
              width: '1165px',
              data: {
                content: self.video,
                time: lastSecond,
                classId: self.classId,
                spanishQuiz: self.spanish
              }
            });
          }
        }).on(flowplayer.events.CUEPOINT_START, function (e) {

        }).on(flowplayer.events.CUEPOINT_END, function (e) {
          // console.log('Cuepoint ended 1', e);
        })
    }

  }


  ngOnChanges(changes: SimpleChanges) {
    if (this.flPlayer && changes.video) {
      this.changeMainVideo(changes.video.currentValue);
    }

    if (!this.preview) {
      jQuery('div.flowplayer').remove();
      if (this.flPlayer !== null && this.flPlayer !== undefined) {
        this.flPlayer.destroy();
        this.flPlayer = null;
        this.initVideos();
        this.flPlayer.toggleFullScreen(true)
      }
    }


  }


  fetchVideoDuration(url: string) {
    console.log("as", url);
    var vid = document.createElement('video');
    //vid.src = URL.createObjectURL(url);
    vid.src = url;
    console.log(vid);
    // wait for duration to change from NaN to the actual duration
    vid.onloadedmetadata = function (e) {
      console.log("vid", vid)
      console.log("vid e", e)
    };
  }

  changeMainVideo(video) {
    let mainVideo = {
      sources: [
        {
          src: this.environment.cdnUrl + "/video/V_" + video.itemCode + "/preview.m3u8",
          //src: "https://multiplatform-f.akamaihd.net/i/multi/april11/sintel/sintel-hd_,512x288_450_b,640x360_700_b,768x432_1000_b,1024x576_1400_m,.mp4.csmil/master.m3u8", 
          type: "application/x-mpegURL",
          title: video.title,
          main: true

        }
      ],
      noseek: true,
      callback: true,
      videoNum: 4
    };
    var newMainVideo = [mainVideo];
    this.flPlayer.setPlaylist(newMainVideo).play(0);;
  }

  bookmark(){
    this._contentService.bookmarkCourse(this.video.id).subscribe(result=>{
      if(result){
        this.isBookmarked = true;
      }
    });
  }

  openFullscreen() {
    if (this.elem.requestFullscreen) {
      this.elem.requestFullscreen();
    } else if (this.elem.mozRequestFullScreen) {
      /* Firefox */
      this.elem.mozRequestFullScreen();
    } else if (this.elem.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      this.elem.webkitRequestFullscreen();
    } else if (this.elem.msRequestFullscreen) {
      /* IE/Edge */
      this.elem.msRequestFullscreen();
    }
  }
}
