import {Component, ViewEncapsulation} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'abs-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AbsConfirmDialogComponent {

  public confirmMessage: string;

  /**
   * Constructor
   *
   * @param {MatDialogRef<AbsConfirmDialogComponent>} dialogRef
   */
  constructor(
    public dialogRef: MatDialogRef<AbsConfirmDialogComponent>
  )
  {
  }

}
