import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Company } from '../_models';
import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { toBoolean } from '@rxweb/reactive-form-validators';
import { RegistrationForm } from '../register-2/register-2.component';


const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable({ providedIn: 'root' })
export class RegistrationService {


  constructor(private http: HttpClient) { }


  handleError(error: HttpErrorResponse) {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
  }


  checkIfUsernameAlreadyExist(username:string): Observable<any>{
    return this.http.get(`${environment.apiUrl}/user/name/${username}`, httpOptions).pipe(catchError(this.handleError));
  }

  checkIfPhoneAlreadyExist(phone:string): Observable<any>{
    return this.http.get(`${environment.apiUrl}//user/profile/${phone}`, httpOptions).pipe(catchError(this.handleError));
  }

  checkIfUserAlreadyExist(username: string): Observable<any> {
    return this.http.post(`${environment.apiUrl}/validate/user`, {username: username}, httpOptions).pipe(catchError(this.handleError));
  }


  findUserByUsername(username:string): Observable<any> {
    return this.http.post(`${environment.apiUrl}/find/user-by-username`, {username: username}, httpOptions).pipe(catchError(this.handleError));
  }



  userInviteValidation(inviteList){
    var formData = JSON.stringify(inviteList)
    return this.http.post(`${environment.apiUrl}/user/invite/validate`, formData, httpOptions).pipe(catchError(this.handleError));

  }
  
  findManager(value: string, type: string): Observable<any> {
    const body = `?value=${value}&type=${type}`;
    return this.http.post(`${environment.apiUrl}/find/manager` + body, httpOptions).pipe(catchError(this.handleError));
  }

  signUp(form: RegistrationForm) {

    var formData = JSON.stringify(form)
    return this.http.post<any>(`${environment.apiUrl}/signup`, formData, httpOptions).pipe(catchError(this.handleError));

  }

  inviteUser(form: RegistrationForm) {

    var formData = JSON.stringify(form)
    return this.http.post<any>(`${environment.apiUrl}/user/invite`, formData, httpOptions).pipe(catchError(this.handleError));

  }
  resendInvite(form: RegistrationForm) {

    var formData = JSON.stringify(form)
    return this.http.post<any>(`${environment.apiUrl}/user/invite-again`, formData, httpOptions).pipe(catchError(this.handleError));

  }
  addKioskUser(form: RegistrationForm) {

    var formData = JSON.stringify(form)
    return this.http.post<any>(`${environment.apiUrl}/add/kiosk/user`, formData, httpOptions).pipe(catchError(this.handleError));

  }

  sendVerificationEmail(username:string, email: string, compId: number): Observable<any> {
    const body = `?username=${username}&email=${email}&compId=${compId}`;
    return this.http.post(`${environment.apiUrl}/email/verification`, {username: username, email: email, compId:compId}, httpOptions).pipe(catchError(this.handleError));
  }

  sendVerificationSms(number:string, uid: number): Observable<any> {
    const body = `?number=${number}&uid=${uid}`;
    return this.http.post(`${environment.apiUrl}/verify/sms` + body, httpOptions).pipe(catchError(this.handleError));
  }

  validateVerificationCode(code: string, username: String): Observable<any> {
    return this.http.post(`${environment.apiUrl}/verifyUser` ,{verificationCode:code, username:username}, httpOptions).pipe(catchError(this.handleError));
  }

  validateVerificationCodeForSMS(code: string, phone: String): Observable<any> {
    const body = `?code=${code}&number=${phone}&uid=0`;
    return this.http.post(`${environment.apiUrl}/validate/sms` + body, httpOptions).pipe(catchError(this.handleError));
  }
  


}
