import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, Validators, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { AbsConfigService } from '@abs/services/config.service';
import { absAnimations } from '@abs/animations';
import { AuthenticationService, CompnayService } from '../_services';
import { ActivatedRoute, Router } from '@angular/router';
import { first, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Company } from '../_models';
import { AbsProgressBarService } from '@abs/components/progress-bar/progress-bar.service';
import { User, UserLevel } from '../../models/user';
import { MatDialog } from '@angular/material/dialog';
import { NewDeviceDialogComponent } from './new-device-dialog/new-device-dialog.component';
import { AbsUtils } from '@abs/utils';
import { IndexTiles } from '../../landing-page/tile.model';
import { of } from 'rxjs';
import { RegistrationService } from '../_services/registration.service';
import { Platform } from '@angular/cdk/platform';
import { AbsNavigationService } from '@abs/components/navigation/navigation.service';
import { navigation } from 'app/navigation/navigation';
import { learnerNavigation } from 'app/navigation/navigation';
import { environment } from 'environments/environment';
import { ActionBarService } from '../../../layout/components/action-bar/action-bar.service';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'login-2',
  templateUrl: './login-2.component.html',
  styleUrls: ['./login-2.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: absAnimations
})
export class Login2Component implements OnInit {

  existingUser = true;
  isValidForm = false;
  invalidPassword = false;
  showLogin = true;
  showUserNotFound = false;
  isManager = false;
  showSubmitButton = true;

  vid = 0;
  startRegistration = false;
  managerEmail: any;
  providerHash: any;
  navigation: any;
  companyId: any;
  migrationFlow = false;
  emailValue = "";
  firstName: any = "";
  lastName: any = "";
  loginFlow = true;
  resetFlow = false;
  resetPassword = false;
  tiles: any;
  company: any;
  useEmplyeeId = false;
  customCompanyAdmins: User[];
  profileId: any;
  dialogRef: any;
  hide = true;
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  error = '';
  currentUser: User;
  newDevice = false;
  registerUserExist = false;
  companies: Company[] = [];
  providerId: any;
  userId: any;
  invite = false;
  sso: boolean = false;
  isSedgwickFlow = false;
  policyId:any;
  groups: any[] = [];
  userLevel: any;

  showAdminMessage = false;

  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);

  matcher = new MyErrorStateMatcher();

  /**
   * Constructor
   * @param {_snackBar} MatSnackBar
   * @param {AbsConfigService} _absConfigService
   * @param {FormBuilder} _formBuilder
   * @param {ActivatedRoute} route
   * @param {Router} router
   * @param {AuthenticationService} authenticationService
   * @param {CompnayService} companyService
   */
  constructor(
    private _snackBar: MatSnackBar,
    private _formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private registrationService: RegistrationService,
    private _absConfigService: AbsConfigService,
    private _navigationService: AbsNavigationService,
    private _actionBarService: ActionBarService,
    private companyService: CompnayService,
    private _progressBarService: AbsProgressBarService,
    public _matDialog: MatDialog,
    private _platform: Platform
  ) {

    this.authenticationService.currentUserSubject.next(null);

    this.route.queryParams.subscribe(params => {
      console.log("params", params);
      const managerPid = params['mpid'];
      const rURL = params['returnUrl'];
      const vid = params['v'];
      const token = params['token'];
      const quizToken = params['quiz-token'];
      const providerId = params['prid'];
      const employerId = params['eid'];
      const username = params['username'];
      const firstName = params['fn'];
      const lastName = params['ln'];
      const sedgwickProviderHash = params['providerHash'];
      const providerHash = params['h'];
      const groups = params['g'];
      const userLevel = params['ul'];
      const policyId = params['policyId'];

      if (providerId && providerId.length > 0) {
        this.providerId = providerId;
      } else {
        this.providerId = 0;
      }

      if (username && username.length > 0) {
        this.emailValue = username;
        if (firstName) {
          this.firstName = firstName;
        }
        if (lastName) {
          this.lastName = lastName;
        }
        if (policyId) {
          this.policyId = policyId;
        }
        this.providerHash = sedgwickProviderHash;
        if (sedgwickProviderHash && sedgwickProviderHash.length > 0) {
          this.isSedgwickFlow = true;
        }
        this.sso = true;
      }

      if (token && token.length > 0) {
        this.authenticationService.validateToken(token, 'E').subscribe(res => {
          if (res) {
            console.log(res);
            this.profileId = res.username;
            this.resetPassword = true;
            this.resetFlow = true;

            if (res.type == "INVITE_USER") {
              this.invite = true;
            }
          }
        });
      }

      if (managerPid && managerPid.length > 0) {
        if(!Array.isArray(groups)){
          this.groups.push(groups);
        }else{
          this.groups = groups;
        }
        console.log("groups",this.groups);

        this.loginFlow = false;
        this.managerEmail = managerPid;
        this.userLevel = userLevel;
      }

      if (quizToken && quizToken.length > 0) {
        const courseId = params['pid'];
        const classId = params['cid'];
        this.authenticationService.validateToken(quizToken, 'QUIZ').subscribe(res => {
          if (res) {
            this.authenticationService.ssoLogin(res.username).subscribe(res => {
              if (res) {
                this.router.navigate(['training/classroom-quiz'], { queryParams: { pid: courseId, cid: classId } });
              }
            });
          }
        });
      }
      if (employerId && employerId.length > 0) {
        console.log("here");
        this.emailValue = employerId;
        this.useEmplyeeId = true;
      }

      if (providerHash && providerHash.length > 0) {
        console.log("providerHash", providerHash);
        this.providerHash = providerHash;
      }
      console.log("location", location);
      if (rURL && rURL.length > 0 && vid && vid.length > 0) {
        this.vid = vid;
      } else {
        this.returnUrl = 'home';
      }

      console.log("returnUrl", this.returnUrl);

    });


    /*   this.authenticationService.getTiles().subscribe(res => {
        ; this.tiles = res;
      }); */

    // Configure the layout
    this._absConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },
        toolbar: {
          hidden: false,
          createAccount: true,
        },
        footer: {
          hidden: true
        },
        sidepanel: {
          hidden: true
        }
      }
    };

    this.company = JSON.parse(localStorage.getItem('currentCompany'));
    if (this.company && this.company.adminAndManagers) {
      this.customCompanyAdmins = AbsUtils.filterArrayByString(this.company.adminAndManagers, 'ADMIN');
      if (this.customCompanyAdmins && this.customCompanyAdmins.length > 0 && this.customCompanyAdmins[0].toggles) {
        let EMPLOYEE_ID_AS_LOGIN = AbsUtils.filterArrayByString(this.customCompanyAdmins[0].toggles, 'EMPLOYEE_ID_AS_LOGIN');
        if (EMPLOYEE_ID_AS_LOGIN) {
          this.useEmplyeeId = EMPLOYEE_ID_AS_LOGIN[0].value;
        }
      }
    }

    // redirect to home if already logged in
    /*  this.authenticationService.currentUser.subscribe((value) => {
       this.currentUser = value;
       
     });; */
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {


    this._actionBarService.onCreateUserClicked.subscribe(value => {
      if (value) {
        this.registrationFlow(null, false);
      }
    })

    this.loginForm = this._formBuilder.group({
      email: [''],
      organization: [''],
      password: ['']
    });

    this.loginForm.controls['password'].valueChanges
      .pipe(
        debounceTime(100),
        distinctUntilChanged()
      )
      .subscribe(username => {
        this.invalidPassword = false;
        this.checkIfValidFormInformation();
      });

    this.loginForm.controls['email'].valueChanges
      .pipe(
        debounceTime(100),
        distinctUntilChanged()
      )
      .subscribe(username => {
        this.checkIfValidFormInformation();


      });

    if (this.emailValue != "") {
      this.loginForm.controls.email.setValue(this.emailValue);
      this.companyService.getCompaniesByUsername(this.f.email.value).subscribe(resp => {
        this.companies = resp;
        this._progressBarService.hide();
        console.log(this.companies);
        if (this.companies.length == 0) {
          this.loginFlow = false;
          this.existingUser = false;

          /* this._snackBar.open("User " + this.f.email.value + " is not registered with us.", 'Ok', {
            panelClass: 'red-bg',
            duration: 2000,
            horizontalPosition: 'right',
            verticalPosition: 'top',
            politeness: 'polite',
          }); */
        } else if (this.companies.length == 1) {
          this.profileId = this.companies[0].profileId;
          this.userId = this.companies[0].userId;

          this.currentUser = new User(this.companies[0]);
          if (this.companies[0].showWizard) {
            this.migrationFlow = true;
            if (this.companies[0].firstLogin) {
              this.existingUser = false;
            }

          } else {
            this.f.organization.setValue(this.companies[0].profileId);
            this.currentUser = new User(this.companies[0]);
            console.log("current", this.currentUser);
          }
        }

      });
    }
    // get return url from route parameters or default to '/'

    /* this.loginForm.controls['email'].valueChanges
      .pipe(
        debounceTime(1000),
        distinctUntilChanged()
      )
      .subscribe(searchText => {
        this.companies = [];
        this.f.organization.setValue('');
        if (!this.f.email.errors) {
          this._progressBarService.show();
          this.authenticationService.identifyUser(this.f.email.value).subscribe(value => {
            this._progressBarService.hide();
            if (value == 1) {
              var defaults = JSON.parse(localStorage.getItem("system_defaults"));
              console.log("defaults", defaults);
              if (defaults) {
                let LEGACY_WEBSITE_URL = AbsUtils.filterArrayByString(defaults, "LEGACY_WEBSITE_URL");
                if (LEGACY_WEBSITE_URL && LEGACY_WEBSITE_URL.length > 0) {
                  window.location.href = LEGACY_WEBSITE_URL[0].value + '/itrain/go.jsp'
                } else {
                  window.location.href = 'https://itrain.itrainstation.com/itrain/go.jsp'
                }
              } else {
                window.location.href = 'https://itrain.itrainstation.com/itrain/go.jsp'
              }
            } else {
              this.getCompanies();
            }
          });

        }
      }); */
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.loginForm.controls;
  }


  proceed() {

    this.companies = [];
    this.f.organization.setValue('');
    if (!this.f.email.errors) {
      this._progressBarService.show();
      this.authenticationService.identifyUser(this.f.email.value).subscribe(value => {
        this._progressBarService.hide();
        if (value == 1) {
          var defaults = JSON.parse(localStorage.getItem("system_defaults"));
          console.log("defaults", defaults);
          if (defaults) {
            let LEGACY_WEBSITE_URL = AbsUtils.filterArrayByString(defaults, "LEGACY_WEBSITE_URL");
            if (LEGACY_WEBSITE_URL && LEGACY_WEBSITE_URL.length > 0) {
              window.location.href = LEGACY_WEBSITE_URL[0].value + '/itrain/go.jsp'
            } else {
              window.location.href = 'https://itrain.itrainstation.com/itrain/go.jsp'
            }
          } else {
            window.location.href = 'https://itrain.itrainstation.com/itrain/go.jsp'
          }
        } else {
          this.getCompanies();
        }
      });

    }

  }

  getCompanies() {
    this.loginFlow = true;
    this._progressBarService.show();
    this.companyService.getCompaniesByUsername(this.f.email.value).subscribe(resp => {
      this.companies = resp;
      this._progressBarService.hide();
      if (this.companies.length == 0) {
        //this.loginFlow = false;
        this.showLogin = false;
        this.invalidPassword = true;

      } else if (this.companies.length == 1) {
        this.profileId = this.companies[0].profileId;
        this.userId = this.companies[0].userId;
        // this.currentUser = new User(this.companies[0]);
        if (this.companies[0].showWizard) {
          this.migrationFlow = true;
        } else {
          this.f.organization.setValue(this.companies[0].profileId);
          this.onSignin();
        }
      }

    });
  }

  userExistCheck(email) {
    console.log(email);
    this.f.email.setValue(email);
  }

  cancelLogin() {

    this.currentUser = null;

    this.showLogin = true;
    this.showSubmitButton = true;

    this.showUserNotFound = false;
    this.invalidPassword = false;
    this.f.organization.setValue('');
    this.showAdminMessage = false;


  }

  onSignin() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;
    this._progressBarService.show();

    this.authenticationService.login(this.f.email.value, this.f.password.value, this.f.organization.value)
      .pipe(first())
      .subscribe(
        data => {
          this._progressBarService.hide();
          if (!data) {
            /* this._snackBar.open("Invalid password for user " + this.f.email.value, 'Ok', {
              panelClass: 'red-bg',
              duration: 2000,
              horizontalPosition: 'right',
              verticalPosition: 'top',
              politeness: 'polite',
            }); */
            this.showLogin = false;
            this.showUserNotFound = false;
            this.invalidPassword = true;
          } else {
            if (!data.active) {
              this._snackBar.open("Your account has been disabled.", 'Ok', {
                panelClass: 'red-bg',
                duration: 2000,
                horizontalPosition: 'right',
                verticalPosition: 'top',
                politeness: 'polite',
              });
            } else {
              if (data.classId != 0) {
                this.router.navigate(['training/classroom'], { queryParams: { cid: data.classId, name: 'Training' } });
              } else {
                if (this._platform.IOS || this._platform.ANDROID) {
                  if (this.authenticationService.currentUserValue.userLevel != UserLevel.LEARNER) {
                    this.showAdminMessage = true;
                  } else {
                    this.router.navigate([this.returnUrl]);
                  }
                } else {
                  if (this.vid != 0) {
                    this.router.navigate(['content/product'], { queryParams: { v: this.vid } });
                  } else {
                    this.router.navigate([this.returnUrl]);
                  }
                }
              }
            }
            //this.currentUser = data;
            /* if(this.currentUser.currentDevice && this.currentUser.currentDevice.new){
              this.newDevice = true; 
              this.dialogRef = this._matDialog.open(NewDeviceDialogComponent, {
                panelClass: 'contact-form-dialog',
                width:'500px',
                data      : {
                  device: this.currentUser.currentDevice,
                  user: this.currentUser
                }
              }); */
          }
        },
        error => {
          this.error = error;
          this.loading = false;
          this._snackBar.open(this.error, 'Ok', {
            panelClass: 'red-bg',
            duration: 2000,
            horizontalPosition: 'right',
            verticalPosition: 'top',
            politeness: 'polite',
          });
        });
  }

  goToLandingPage() {
   // this.router.navigate([this.returnUrl]);
   this.cancelLogin();
   this.authenticationService.logout();
  }

  registrationFlow(e: any, isManager) {
    this.loginFlow = false;
    this.startRegistration = true;
    this.isManager = isManager;
  }
  inputChange(event){
   this.checkIfValidFormInformation(); 
  }

  checkIfValidFormInformation() {

    if (this.f.email.value && this.f.email.value.length > 0 && this.f.password.value && this.f.password.value.length > 0) {
      this.isValidForm = true;
    } else {
      this.isValidForm = false;
    }
    console.log("isValidForm",this.isValidForm);
  }
}

