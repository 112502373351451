import { Component, OnInit, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';
import { QuizService } from '../../../content/quiz/services/quiz.service';
import { absAnimations } from '@abs/animations';
import { Quiz, QuizConfig, Question, Option } from '../../../content/quiz/models';
import { Content, PlayList } from '../../_models/playlist';
import { TrainingService } from '../../_services/training.service';
import { ActivityLogService, ActivityLog, ActivityType } from '../../../service/activity-log.service';
import { MatDialogRef } from '@angular/material/dialog';
import { VideoLog } from '../../../content/video-log.model';
import { ActivatedRoute } from '@angular/router';
import { ContentService } from '../../../content/content.service';
import { environment } from 'environments/environment';
import { AuthenticationService } from '../../../authentication/_services';

@Component({
  selector: 'app-my-training-inilne-quiz',
  templateUrl: './my-training-inilne-quiz.component.html',
  styleUrls: ['./my-training-inilne-quiz.component.scss'],
  providers: [QuizService],
  encapsulation: ViewEncapsulation.None,
  animations: absAnimations
})
export class MyTrainingInilneQuizComponent implements OnInit {
  certificateURL: any;
  quizDone = false;
  @Input() spanishQuiz: boolean;
  @Input() classroom: boolean;
  @Input() classId: any;
  @Input() training: PlayList;
  @Input() classVideoLogId: any;

  type: any;
  videoTime: any;
  quizes: any[];
  quiz: Quiz = new Quiz(null);
  mode = 'quiz';
  @Input() content: Content;
  @Output() onQuizCompleted = new EventEmitter<any>();
  @Output() onRewatchVideo = new EventEmitter<any>();


  config: QuizConfig = {
    'allowBack': true,
    'allowReview': true,
    'autoMove': false,  // if true, it will move to next question automatically when answered.
    'duration': 9000000000,  // indicates the time (in secs) in which quiz needs to be completed. 0 means unlimited.
    'pageSize': 1,
    'requiredAll': false,  // indicates if you must answer all the questions before submitting.
    'richText': false,
    'shuffleQuestions': false,
    'shuffleOptions': false,
    'showClock': false,
    'showPager': true,
    'theme': 'none'
  };

  pager = {
    index: 0,
    size: 1,
    count: 1
  };
  timer: any = null;
  startTime: Date;
  endTime: Date;
  ellapsedTime = '00:00';
  duration = '';
  passed = false;

  constructor(private route: ActivatedRoute,
    private trainService: TrainingService,
    private activityLogService: ActivityLogService,
    private quizService: QuizService,
    private contentService: ContentService,
    private authService: AuthenticationService
  ) {

  }

  ngOnInit() {
    console.log("init", this.content);

    if (!this.classroom) {
      var videoLog = new VideoLog({
        profileId: JSON.parse(localStorage.getItem("currentUser")).profileId,
        productId: this.content.id,
        itemName: this.content.title,
        classId: this.classId,
        comments: 'QUIZ_ENTRY',
        type: "INDIVIDUAL"
      });
      this.trainService.insertVideoLog(videoLog).then(res => {
        this.content.videoLogId = res;
      });
    }

    let activityLog = new ActivityLog();
    activityLog.profileID = JSON.parse(localStorage.getItem("currentUser")).profileId
    activityLog.type = "site";
    activityLog.source = "itrain";
    activityLog.videoID = this.content.id;
    activityLog.activity = ActivityType.QUIZ;
    this.activityLogService.saveActivityLog(activityLog).subscribe(res => { });

    console.log("content quiz", this.content);
    this.loadQuiz(this.content.id);

    //window.addEventListener('blur', () => alert('window blur'));

  }

  loadQuiz(contentId) {
    this.quizService.getQuizDetail(contentId, this.spanishQuiz,this.classId).subscribe(res => {
      if (res) {
        this.quiz = new Quiz(res);
        console.log(this.quiz);
        this.pager.count = this.quiz.questions.length;
        this.startTime = new Date();
        this.ellapsedTime = '00:00';
        this.timer = setInterval(() => { this.tick(); }, 1000);
        this.duration = this.parseTime(this.config.duration);
      }
    });
    this.mode = 'quiz';
  }

  tick() {
    const now = new Date();
    const diff = (now.getTime() - this.startTime.getTime()) / 1000;
    if (diff >= this.config.duration) {
      this.onSubmit();
    }
    this.ellapsedTime = this.parseTime(diff);
  }

  parseTime(totalSeconds: number) {
    let mins: string | number = Math.floor(totalSeconds / 60);
    let secs: string | number = Math.round(totalSeconds % 60);
    mins = (mins < 10 ? '0' : '') + mins;
    secs = (secs < 10 ? '0' : '') + secs;
    return `${mins}:${secs}`;
  }

  rewatchVideo() {
    this.onRewatchVideo.emit({ video: this.content, training: this.training, lang: this.spanishQuiz ? 'es' : 'en' })
  }

  get filteredQuestions() {
    return (this.quiz.questions) ?
      this.quiz.questions.slice(this.pager.index, this.pager.index + this.pager.size) : [];
  }

  onSelect(question: Question, option: Option) {
    if (question.questionTypeId === 1) {
      question.answers.forEach((x) => { if (x.answerNo !== option.answerNo) x.selected = false; });
    }
    if (this.config.autoMove) {
      this.goTo(this.pager.index + 1);
    }
  }

  goTo(index: number) {
    if (index >= 0 && index < this.pager.count) {
      this.pager.index = index;
      this.mode = 'quiz';
    }
  }

  isAnswered(question: Question) {
    return question.answers.find(x => x.selected) ? 'Answered' : 'Not Answered';
  };

  findUserAnswer(question: Question) {
    return question.answers.find(x => x.selected);
  };

  isCorrect(question: Question) {

    let correctAnswers = question.answers.filter(x => x.correct == true);
    let selectedAnswers = question.answers.filter(x => x.selected == true);
    return correctAnswers.every(x => x.selected == x.correct && x.correct == true && x.selected == true) && correctAnswers.length == selectedAnswers.length ? 'correct' : 'wrong';
  };

  onSubmit() {
    let quizResponses = [];
    this.quiz.questions.forEach(x => {
      if (this.isAnswered(x) == 'Answered') {
        var quizResponse = {
          videologID: this.content.videoLogId,
          profileID: JSON.parse(localStorage.getItem("currentUser")).profileId,
          quizID: this.quiz.id,
          questionNo: x.questionNo,
          answerNo: this.findUserAnswer(x).answerNo,
          question: x.question,
          answer: this.findUserAnswer(x).answer,
          correct: this.isCorrect(x) == 'correct' ? true : false
        };
        quizResponses.push(quizResponse);
      }
    });


    var videoLog = new VideoLog({});
    videoLog.quizResult = this.getQuizScore(this.quiz);
    videoLog.id = this.content.videoLogId;
    videoLog.itemName = this.content.title;
    videoLog.quizResp = quizResponses;
    videoLog.totalViewed = this.videoTime;
    videoLog.classId = this.classId;
    videoLog.profileId = JSON.parse(localStorage.getItem("currentUser")).profileId
    //videoLog.comments = this.spanishQuiz ? 'es' : 'en';
    videoLog.productId = this.content.id;
    if (this.classroom) {
      videoLog.type = "CLASSROOM";
      videoLog.id = this.classVideoLogId;
    } 
      this.trainService.updateVideoLog(videoLog).then(res => {
      })
    
    // Post your data to the server here. answers contains the questionId and the users' answer.
    this.mode = 'result';
    this.quizDone = true;
    if (videoLog.quizResult >= this.training.passingScore) {
      this.passed = true;
      this.certificateURL = `${environment.apiUrl}/training/certificate?videoLogId=${videoLog.id}`;
    }
  }

  retakeQuiz() {
    this.goTo(0);
    this.quizDone = false;
    this.passed = false;
  }

  getQuizScore(quiz): number {
    var totalCorrect = 0;
    quiz.questions.forEach(question => {
      if (this.isCorrect(question) == 'correct') {
        totalCorrect = totalCorrect + 1;
      }
    });
    console.log("totalCorrect", totalCorrect);
    var result = (totalCorrect / quiz.questions.length) * 100;
    return result;
  }

  getNextLetter(num): String {
    var code: number = "ABCDEFGHIJKLMNOPQRST".charCodeAt(num);
    return String.fromCharCode(code);
  }

  quizCompleted() {
    this.onQuizCompleted.emit('true');
  }

}