import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, EmailValidator, Validators } from '@angular/forms';
import { TrainingService } from '../../training/_services/training.service';
import { AbsProgressBarService } from '@abs/components/progress-bar/progress-bar.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'environments/environment';
import printJS from 'print-js';
@Component({
  selector: 'app-certificate-action',
  templateUrl: './certificate-action.component.html',
  styleUrls: ['./certificate-action.component.scss']
})
export class CertificateActionComponent implements OnInit {


  videoLogId: any;
  profileId: any;
  email: any;
  form: FormGroup;
  showEmail: boolean;
  certificateURL: any;
  constructor(public matDialogRef: MatDialogRef<CertificateActionComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private _formBuilder: FormBuilder,
    private trainingService: TrainingService,
    private progressBarService: AbsProgressBarService,
    private snackBar: MatSnackBar) {
    this.videoLogId = _data.videoLogId;
    this.profileId = _data.profileId;
  }

  ngOnInit() {
    this.form = this.createForm();
    this.certificateURL = `${environment.apiUrl}/training/certificate?videoLogId=${this.videoLogId}`;

  }

  printCertificate() {
    printJS({ printable: this.certificateURL, type: 'pdf', showModal: true })
  }


  emailCertificate() {
    this.showEmail = true;
  }

  createForm(): FormGroup {
    return this._formBuilder.group({
      email: ['', { validators: [Validators.required, Validators.email] }],
    });
  }

  onSubmit() {
    this.progressBarService.show();
    var request = { videoLogId: this.videoLogId, profileId: this.profileId, email: this.form.controls.email.value };
    this.trainingService.emailCertificate(request).then(res => {
      this.progressBarService.hide();
      if (res) {
        this.snackBar.open("Certificate sent...", 'Ok', {
          panelClass: 'green-bg',
          duration: 2000,
          horizontalPosition: 'right',
          verticalPosition: 'top',
          politeness: 'polite',
        });
        this.matDialogRef.close("sent");
      }
    })
  }

  onExistTrainingClick() {
    this.matDialogRef.close();
  }

}
