import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { SelectionModel } from '@angular/cdk/collections';
import { ContentService } from 'app/main/content/content.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AbsUtils } from '@abs/utils';
import { AuthenticationService } from '../../../../authentication/_services';
@Component({
  selector: 'app-content-share-location-selector',
  templateUrl: './content-share-location-selector.component.html',
  styleUrls: ['./content-share-location-selector.component.scss']
})
export class ContentShareLocationSelectorComponent implements OnInit {

  flip: any;

  @Input()
  locations: any;

  @Input()
  selected: any;

  allLocations: any;

  search = new FormControl();
  selection = new SelectionModel<any>(true, this.selected);


  @Output() onBackButton = new EventEmitter<any>();
  @Output() onSelection = new EventEmitter<any>();
  @Output() onDone = new EventEmitter<any>();

  constructor(private contentService: ContentService, private authService: AuthenticationService) { }

  ngOnInit(): void {
    
    if(this.selected && this.selected.length > 0){
      this.selected.forEach(id=>{
        this.selection.select(id);
      })
    }
    this.search.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe(value => {
      if (value.length > 0) {
        var result = AbsUtils.filterArrayByString(this.locations, value);
        this.locations = result;
      } else {
        this.locations = this.allLocations;

      }
    });
  }

  onContinue() {
    this.onDone.next({ data: this.selection.selected, type: 'locations' })
  }
  handleBack() {
    this.onBackButton.next("asd")
  }

  onChange(event) {
    this.selection.toggle(event);
  } descClicked(asd) { }

}
