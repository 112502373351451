import { Component, HostBinding, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {absAnimations } from '@abs/animations';
import {AbsConfigService} from '@abs/services/config.service';

@Component({
  selector: 'abs-nav-horizontal-collapsable',
  templateUrl: './collapsable.component.html',
  styleUrls: ['./collapsable.component.scss'],
  animations : absAnimations
})
export class AbsNavHorizontalCollapsableComponent implements OnInit, OnDestroy
{
  absConfig: any;
  isOpen = false;
  isOpenTab = false;

  @HostBinding('class')
  classes = 'nav-collapsable nav-item';

  @Input()
  item: any;

  // Private
  private _unsubscribeAll: Subject<any>;

  constructor(
    private _absConfigService: AbsConfigService
  )
  {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void
  {
    // Subscribe to config changes
    this._absConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(
        (config) => {
          this.absConfig = config;
        }
      );
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void
  {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  @HostListener('mouseenter')
  open(): void
  {
      this.isOpen = true;
  }

  @HostListener('click')
  openS(): void
  {
      this.isOpenTab = true;
  }

  /**
   * Close
   */
  @HostListener('mouseleave')
  close(): void
  {
    //this.isOpenTab = false;
      this.isOpen = false;   
  }
}
