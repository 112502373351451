import { Directive, Input, OnInit, HostListener, OnDestroy, HostBinding } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { MediaObserver } from '@angular/flex-layout';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AbsMatchMediaService } from '@abs/services/match-media.service';
import { AbsMatSidenavService } from '@abs/directives/abs-mat-sidenav/abs-mat-sidenav.service';

@Directive({
  selector: '[appAbsMatSidenav]'
})
export class AbsMatSidenavDirective implements OnInit, OnDestroy {

  @HostBinding('class.mat-is-locked-open')
  isLockedOpen: boolean;

  @Input()
  absMatSidenavHelper: string;

  @Input()
  matIsLockedOpen: string;

  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {AbsMatchMediaService} _absMatchMediaService
   * @param {AbsMatSidenavService} _absMatSidenavHelperService
   * @param {MatSidenav} _matSidenav
   * @param {ObservableMedia} _observableMedia
   */
  constructor(
    private _absMatchMediaService: AbsMatchMediaService,
    private _absMatSidenavHelperService: AbsMatSidenavService,
    private _matSidenav: MatSidenav,
    private _observableMedia: MediaObserver
  ) {
    // Set the defaults
    this.isLockedOpen = true;

    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */

  ngOnInit(): void {
// Register the sidenav to the service
    this._absMatSidenavHelperService.setSidenav(this.absMatSidenavHelper, this._matSidenav);

    if ( this._observableMedia.isActive(this.matIsLockedOpen) )
    {
      this.isLockedOpen = true;
      this._matSidenav.mode = 'side';
      this._matSidenav.toggle(true);
    }

    else
    {
      this.isLockedOpen = false;
      this._matSidenav.mode = 'over';
      this._matSidenav.toggle(false);
    }

    this._absMatchMediaService.onMediaChange
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(() => {
        if ( this._observableMedia.isActive(this.matIsLockedOpen) )
        {
          this.isLockedOpen = true;
          this._matSidenav.mode = 'side';
          this._matSidenav.toggle(true);
        }
        else
        {
          this.isLockedOpen = false;
          this._matSidenav.mode = 'over';
          this._matSidenav.toggle(false);
        }
      });

  }

  /**
   * On destroy
   */
  ngOnDestroy(): void
  {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

}

@Directive({
  selector: '[absMatSidenavToggler]'
})

export class AbsMatSidenavTogglerDirective
{
  @Input()
  absMatSidenavToggler: string;

  /**
   * Constructor
   *
   * @param {AbsMatSidenavService} _absMatSidenavHelperService
   */
  constructor(
    private _absMatSidenavHelperService: AbsMatSidenavService)
  {
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * On click
   */
  @HostListener('click')
  onClick(): void
  {
    this._absMatSidenavHelperService.getSidenav(this.absMatSidenavToggler).toggle();
  }
}
