import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject, of, timer, Subscription } from 'rxjs';
import { TrainingService } from '../_services/training.service';
import { AbsProgressBarService } from '@abs/components/progress-bar/progress-bar.service';
import { AuthenticationService } from '../../authentication/_services';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { PlayerComponent } from './player/player.component';
import { TrainingAttendee } from '../_models/training-attendee.model';
import { ActivatedRoute, Router } from '@angular/router';
import { ActionBarService } from '../../../layout/components/action-bar/action-bar.service';
import { WifiInfoDialogComponent } from './wifi-info-dialog/wifi-info-dialog.component';
import * as Stomp from 'stompjs';
import * as SockJS from 'sockjs-client';
import { environment } from 'environments/environment';
import { CourseCompletedDialogComponent } from './course-completed-dialog/course-completed-dialog.component';
import { IndependentTrainingInviteDialogComponent } from './independent-training-invite-dialog/independent-training-invite-dialog.component';
import { NextCourseDialogComponent } from './next-course-dialog/next-course-dialog.component';
import { TrainerQuizPreviewComponent } from '../../content/trainer-quiz-preview/trainer-quiz-preview.component';
import { Content } from '../_models/playlist';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UsersService } from '../../user/user.service';
import { FormControl } from '@angular/forms';
import { AbsUtils } from '@abs/utils';
import { LastCourseCompleteDialogComponent } from './last-course-complete-dialog/last-course-complete-dialog.component';
@Component({
  selector: 'app-classroom',
  templateUrl: './classroom.component.html',
  styleUrls: ['./classroom.component.scss'],
  encapsulation: ViewEncapsulation.None,


})
export class ClassroomComponent implements OnInit {
  searchCtrl = new FormControl();
  alreadyOpened = false;
  refresh = false;
  wifiInfo = false;
  private serverUrl = `${environment.apiUrl}/socket`;
  private stompClient;
  isLastCourse = false;
  currentCourseId = 0;
  start = false;
  location: any;
  title: any;
  currentCourseTitle = "";
  scanValue: any;
  classId: any;
  dataSource: any;
  dialogRef: any;
  lastCourseCompletedDialogRef: any;
  courseCompletedDialogRef: any;
  inviteTrainingDialogRef: any;
  nextCourseMatDialogRef: any;
  wifiMatDialogRef: any;
  projectorWindow: any;
  showPlayer: any = false;
  attendees: any;
  selected = "all";
  environment: any;
  users: any;
  allUsers: any;
  training: any;


  enableClassButton = false;
  enableGroupButton = false;
  enableScheduleItButton = false;
  lastCourse = false;
  enableCloseSessionButton = false;
  kioskModeEnabled = false;
  subscription:Subscription;
  showResumeButton = false;
  //columnsToDisplay = ['lastName', 'firstName', 'checkedIn'];
  columnsToDisplay = ['lastName', 'firstName', 'checkedIn', 'completed', 'score', 'result'];

  //columnsToDisplay = ['buttons', 'playlist', 'date', 'location', 'format', 'status', 'assignedBy', 'percentComplete', 'assignment'];

  // Private
  private _unsubscribeAll: Subject<any>;
  constructor(private trainingService: TrainingService,
    private progressBarService: AbsProgressBarService,
    public authService: AuthenticationService,
    public usersService: UsersService,
    public dialog: MatDialog,
    public activatedRouter: ActivatedRoute,
    private _router: Router,

    public actionBarService: ActionBarService,
    public courseCompletedDialog: MatDialog,
    public inviteTrainingDialog: MatDialog,
    private nextCourseMatDialog: MatDialog,
    private lastCourseMatDialog: MatDialog,

    private snackBar: MatSnackBar,
    private dialogQuiz: MatDialog) {
    this.activatedRouter.queryParams.subscribe(params => {
      const cid = params['cid'];
      const name = params['name'];
      this.classId = cid;
      this.title = name;
      localStorage.setItem("currentClassId", cid);
      localStorage.setItem("currentClassTitle", name);

      console.log(localStorage.getItem("currentClassId"));
    });

    this.environment = environment;
  }

  ngOnInit() {
    console.log("timzone", this.authService.currentUserTimeZone);

    this.trainingService.getTraining(this.classId).then(training => {
      this.training = training;
      if (this.training.status == 'STARTED') {
        this.actionBarService.onClassLoaded.next("resume");
        if(this.training.currentCourse && this.training.currentCourse > 0){
          this.showResumeButton = true;
        }
        localStorage.setItem("lastPlayedProductId", this.training.currentCourse);
      } else {
        localStorage.setItem("lastPlayedProductId", "0");

      }
    })

    this.subscription = timer(1000, 1000).subscribe(t => {
      console.log("going to get timer");
      this.trainingService.getTraining(this.classId).then(training => {
        this.training = training;
        if(this.training.status == 'CLOSED'){
          this.subscription.unsubscribe()
        }
      })
    });

    this.searchCtrl.valueChanges.subscribe(value => {
      var result = AbsUtils.filterArrayByString(this.allUsers, value)
      this.users = of(result);
    })

    this.loadClassroomAttendees();

    this.usersService.getUsers().then(users => {
      this.users = of(users);
      this.allUsers = users;

    });

    this.trainingService.onTrainingAttendeeChanged.subscribe(attendees => {
      this.progressBarService.show();
      if (attendees) {
        this.dataSource = new MatTableDataSource(attendees);
        this.progressBarService.hide();

      }
    })
    this.actionBarService.onWifiClick.subscribe(result => {
      if (result) {
     
      }
    })

    this.actionBarService.onSendQuizLinkClick.subscribe(res => {
      if (res) {
        this.sendLinks();
      }
    })

    this.actionBarService.onViewQuizClick.subscribe(res => {
      if (res) {
        this.onQuizPreview();
      }
    })

    this.actionBarService.onStartClassClick.subscribe(res => {
      if (res) {
        //this.wifiInfo = false;
        this.startClass();
      }
    })

    this.actionBarService.onResumeClassClick.subscribe(res => {
      if (res) {
        this.wifiInfo = false;
        this.startClass();
      }
    })

    this.actionBarService.onNextCourseClick.subscribe(res => {
      if (res) {
        
      }
    })
    this.actionBarService.onCloseSessionClick.subscribe(res => {
      console.log("close session clicked");
      if (res) {
       this.closeSession();
      }
    })
    this.initializeWebSocketConnection();

  }

  nextCourse(){
    this.advanceToNextCourse(this.classId, this.currentCourseId, this.isLastCourse)
  }

  onCodeResult(event) {
    console.log("scanned", event);
  }

  closeSession()
  {
    console.log("hello","closing")
    this.trainingService.updateTrainingStatus(this.classId, "CLOSED", this.currentCourseId);
    this._router.navigate(['training/my-trainings']);
  }

  loadClassroomAttendees() {
    this.trainingService.getTrainingAttendees(this.classId).then(attendees => {
      console.log("attendees", attendees);
      if (attendees) {
        this.dataSource = new MatTableDataSource(attendees);
        this.attendees = attendees;
      }
    })
  }

  showWifi(){
    this.trainingService.getTrainingCourses(this.classId).then(res => {
      if (res) {
        console.log("result",res);
        let viewModel = {companyId:this.authService.currentUserValue.companyId,  providerId: this.authService.currentUserValue.providerId,profileId:this.authService.currentUserValue.profileId, type: 'classroom', requestedViews: res.length };
        this.trainingService.checkIfUserCanViewVideo(viewModel).then(res => {
          console.log("res",res);
          if (res) {
            if(this.projectorWindow && !this.projectorWindow.closed){
              this.sendShowWifiMessage();
              this.showWifiInformation();
            }else{
              this.refresh = !this.refresh;
              this.wifiInfo = true;
              this.start = true;
            }
          } else {
            this.snackBar.open("You dont have enough Classroom views to start this class. Please contact your Admin.", 'Ok', {
              panelClass: 'red',
              horizontalPosition: 'right',
              verticalPosition: 'top',
              politeness: 'polite',
            });
          }
        })
      }
    })
  }

  onSearch(value) {
    this.searchCtrl.setValue("");
    this.progressBarService.show();
    var foundAttendee = this.attendees.find(a => a.profileID == value.profileId);
    if(foundAttendee){
      var attendee = new TrainingAttendee({ id: this.classId, profileID: foundAttendee.profileID });
    this.trainingService.updateTrainingAttendee(attendee).then(res => {
      if (res) {
        this.loadClassroomAttendees();
        this.progressBarService.hide();

      }
    });
    }else{

      var attendee = new TrainingAttendee({ id: this.classId, profileID: value.profileId });
      this.trainingService.insertTrainingAttendee(attendee).then(res => {
        if (res) {
          this.loadClassroomAttendees();
          this.progressBarService.hide();
        }
      });
    }
   
  }

  checkInUser(e) {
    console.log(e);
    var foundAttendee = this.attendees.find(a => a.email == this.scanValue);
    var attendee = new TrainingAttendee({ id: this.classId, profileID: foundAttendee.profileID });
    this.trainingService.updateTrainingAttendee(attendee).then(res => {
      if (res) {
        this.loadClassroomAttendees();
      }
    });
  }

  checkInBarCode(e) {
    this.progressBarService.show();
    var scanValue = e.target.value.split('~')[3];  // loginId 
    var attendee = { id: this.classId, companyId: this.authService.currentUserValue.companyId, loginId: scanValue };
    this.trainingService.checkInUser(attendee).then(res => {
      this.progressBarService.hide();
      if (res) {
        this.searchCtrl.setValue("");
        this.loadClassroomAttendees();
      }
    });
  }
  checkInUserByProfileId(id) {
    this.progressBarService.show();
    var foundAttendee = this.attendees.find(a => a.profileID == id);
    var attendee = new TrainingAttendee({ id: this.classId, profileID: foundAttendee.profileID });
    this.trainingService.updateTrainingAttendee(attendee).then(res => {
      this.progressBarService.hide();
      if (res) {
        this.loadClassroomAttendees();
      }
    });
  }

  onQuizPreview() {
    this.dialogQuiz.open(TrainerQuizPreviewComponent, {
      id: 'quiz-preview',
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%',
      panelClass: 'full-screen-modal',
      data: {
        content: new Content({ id: localStorage.getItem("currentCourseId") })
      }
    });
  }

  startClass() {
    //this.trainingService.updateTrainingStatus(this.classId, "STARTED", localStorage.getItem("lastPlayedProductId"));
    this.progressBarService.show();

    this.trainingService.getTrainingCourses(this.classId).then(res => {
      if (res) {
        console.log("result",res);
        let viewModel = {companyId:this.authService.currentUserValue.companyId,  providerId: this.authService.currentUserValue.providerId,profileId:this.authService.currentUserValue.profileId, type: 'classroom', requestedViews: res.length };
        this.trainingService.checkIfUserCanViewVideo(viewModel).then(res => {
          this.progressBarService.hide();
          if (res) {
            this.enableCloseSessionButton = true;
            this.enableClassButton = true;
            if(this.projectorWindow && !this.projectorWindow.closed){
              this.sendStartClassMessage();
            }else{
              this.wifiInfo = false;
              this.refresh = !this.refresh;
              this.start = true;
            }
            this.actionBarService.onStartCourseClick.next('clicked');
          } else {
            this.snackBar.open("You dont have enough Classroom views to start this class. Please contact your Admin.", 'Ok', {
              panelClass: 'red',
              horizontalPosition: 'right',
              verticalPosition: 'top',
              politeness: 'polite',
            });
          }
        })
      }
    })


    /*  this.dialog.open(PlayerComponent, {
       id: 'quiz',
       backdropClass: 'previewBackdrop',
       width: '1165px',
       data: {
         classId: 127
       }
     });  */

  }

  showWifiInformation() {
  this.wifiMatDialogRef =  this.dialog.open(WifiInfoDialogComponent, {
      id: 'wife-info-dialog',
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%',
      panelClass: 'full-screen-modal',
      data: {
        classId: this.classId
      }
    });
    this.wifiMatDialogRef.afterClosed().subscribe(r=>{
      this.closeWifiMessage();
    })
  }


  initializeWebSocketConnection() {
    let ws = new SockJS(this.serverUrl);
    this.stompClient = Stomp.over(ws);
    let that = this;
    this.stompClient.connect({}, function (frame) {
      that.stompClient.subscribe("/training/class/" + that.classId + "/course/result", (message) => {
        if (message.body) {
          var result = JSON.parse(message.body);
          that.dataSource.data.forEach(row => {
            var aResult = result.results.find(r => (r.profileId == row.profileID));
            row.quizStartedOn = aResult.quizStartedOn;
            row.score = aResult.score;
            row.passed = aResult.passed;
            row.status = aResult.status;
            row.quizCompletedOn = aResult.quizCompletedOn;
          });
        }
      });

      that.stompClient.subscribe("/training/class/" + that.classId + "/course/completed/open", (message) => {
        if (message.body) {
          var obj = JSON.parse(message.body);
          that.isLastCourse = obj.last;
          that.currentCourseId = obj.courseId;
          that.actionBarService.onClassCourseChanged.next(obj.last);
          that.courseCompletedDialogRef = that.courseCompletedDialog.open(CourseCompletedDialogComponent, {
            backdropClass: 'previewBackdrop',
            disableClose: true,
            width: '600px',
            data: {
              pid: obj.courseId,
              title: that.title,
              classId: that.classId,
              last: obj.last
            }
          });

          that.courseCompletedDialogRef.afterClosed().subscribe(result => {
            if (result) {
              that.sendCourseCloseMessage(obj.classId, obj.courseId, obj.last);
              that.inviteTrainingDialogRef = that.inviteTrainingDialog.open(IndependentTrainingInviteDialogComponent, {
                backdropClass: 'previewBackdrop',
                disableClose: true,
                width: '400px',
                data: {
                  classId: obj.classId,
                  pid: obj.courseId,
                }
              });
              that.inviteTrainingDialogRef.afterClosed().subscribe(result => {
                that.sendInviteTrainingCloseMessage(obj.classId, obj.courseId, obj.last);
                if (obj.last) {
                  that.lastCourseCompletedDialogRef = that.lastCourseMatDialog.open(LastCourseCompleteDialogComponent, {
                    backdropClass: 'previewBackdrop',
                    width: '1300px',
                    disableClose: true,
                    data: {
                      classId: obj.classId,
                      classTitle: that.title
                    }
                  });
                } else {
                  that.nextCourseMatDialogRef = that.nextCourseMatDialog.open(NextCourseDialogComponent, {
                    backdropClass: 'previewBackdrop',
                    width: '350px',
                    disableClose: true,
                    data: {
                      classId: obj.classId,
                      hasNextCourse: !obj.last,
                      productId: obj.courseId,
                    }
                  });
                  that.nextCourseMatDialogRef.afterClosed().subscribe(result => {
                    if (result) {
                      that.sendNextCourseCloseMessage(that.classId, that.currentCourseId, that.isLastCourse);
                      that.advanceToNextCourse(that.classId, that.currentCourseId, that.isLastCourse)
                    }
                  });
                }
              })
            }
          });
        }
      });
      that.stompClient.subscribe("/training/class/" + that.classId + "/course/completed/close", (message) => {
        if (message.body) {
          var result = JSON.parse(message.body);
          that.courseCompletedDialogRef.close(result)
        }
      });

      that.stompClient.subscribe("/training/class/" + that.classId + "/course/last/close", (message) => {
        if (message.body) {
          var result = JSON.parse(message.body);
          that.lastCourseCompletedDialogRef.close(result)
        }
      });
      that.stompClient.subscribe("/training/class/" + that.classId + "/course/next/close", (message) => {
        console.log("closeing 123")
        if (message.body) {
          var result = JSON.parse(message.body);
          console.log("closeing")
          that.nextCourseMatDialogRef.close(result)
        }
      });
      that.stompClient.subscribe("/training/class/" + that.classId + "/course/invite/close", (message) => {
        if (message.body) {
          var result = JSON.parse(message.body);
          that.inviteTrainingDialogRef.afterClosed().subscribe(obj => {
            console.log("close", obj);
            that.sendInviteTrainingCloseMessage(result.classId, result.courseId, result.last);
            if (result.last) {
              that.lastCourseCompletedDialogRef = that.lastCourseMatDialog.open(LastCourseCompleteDialogComponent, {
                backdropClass: 'previewBackdrop',
                width: '1300px',
                disableClose: true,
                data: {
                  classId: result.classId,
                }
              });
            } else {
              that.nextCourseMatDialogRef = that.nextCourseMatDialog.open(NextCourseDialogComponent, {
                backdropClass: 'previewBackdrop',
                width: '350px',
                disableClose: true,
                data: {
                  classId: result.classId,
                  hasNextCourse: !result.last,
                  productId: result.courseId,
                }
              });
              that.nextCourseMatDialogRef.afterClosed().subscribe(result => {
                console.log("Asdasd");
                    if (result) {
                      
                    }
                  });
            }
          })
          that.inviteTrainingDialogRef.close(result);
        }
      });
    });
  }
  doFilter(event) {
    this.progressBarService.show();
    let filtered = this.attendees;
    if (event.value == 'checkedin') {
      filtered = this.attendees.filter(list => {
        return list.attendDate != null;
      });
      console.log(filtered);
    }
    this.dataSource = new MatTableDataSource(filtered);
    this.progressBarService.hide();
  }

  sendLinks() {
    this.progressBarService.show();
    this.snackBar.open("Generating Quiz Links...", '', {
      panelClass: '',
      horizontalPosition: 'center',
      verticalPosition: 'top',
      politeness: 'polite',
    });
    this.trainingService.sendQuizLinks(this.classId, localStorage.getItem("currentCourseId")).then(res => {
      this.snackBar.dismiss();
      this.progressBarService.hide();
      this.snackBar.open("Quiz Links has been sent.", '', {
        panelClass: 'green-bg',
        duration: 2000,
        horizontalPosition: 'right',
        verticalPosition: 'top',
        politeness: 'polite',
      });

    });
  }

  advanceToNextCourse(classId, id, lastCourse) {
    this.stompClient.send('/app/training/course/next', {}, JSON.stringify({ classId: classId, courseId: id, last: lastCourse }));
  }

  sendCourseCloseMessage(classId, id, lastCourse) {
    this.stompClient.send('/app//training/course/completed/close', {}, JSON.stringify({ classId: classId, courseId: id, last: lastCourse }));
  }
  sendInviteTrainingCloseMessage(classId, id, lastCourse) {
    this.stompClient.send('/app//training/course/invite/close', {}, JSON.stringify({ classId: classId, courseId: id, last: lastCourse }));
  }
  sendNextCourseCloseMessage(classId, id, lastCourse) {
    this.stompClient.send('/app//training/course/next/close', {}, JSON.stringify({ classId: classId, courseId: id, last: lastCourse }));
  }

  sendStartClassMessage() {
    this.stompClient.send('/app//training/start/class', {}, JSON.stringify({ classId: this.classId }));
  }
  sendShowWifiMessage() {
    this.stompClient.send('/app//training/class/wifi', {}, JSON.stringify({ classId: this.classId }));
  }
  closeWifiMessage() {
    this.stompClient.send('/app//training/class/wifi/close', {}, JSON.stringify({ classId: this.classId }));
  }

  onProjectorLaunched($event){
    console.log($event);
    this.projectorWindow = $event;
  }
}
