import { AbsUtils } from '@abs/utils';

export class PlaylistManager {
  id: number;
  name: string;
  deleted: boolean;

  constructor(playlist) {
    {
      this.id = playlist.id;
      this.name = playlist.name || '';
      this.deleted = playlist.deleted;
    }
  }

}
