import { Component, OnInit, ViewEncapsulation, EventEmitter, Output, Input } from '@angular/core';
import { absAnimations } from '@abs/animations';
import { FormControl } from '@angular/forms';
import { SelectionModel } from '@angular/cdk/collections';
import { AuthenticationService } from '../../../authentication/_services';
import { GroupsService } from '../../../user/groups/groups.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AbsUtils } from '@abs/utils';
import { ContentService } from '../../../content/content.service';
import { UsersService } from '../../../user/user.service';
@Component({
  selector: 'app-users-selector',
  templateUrl: './users-selector.component.html',
  styleUrls: ['./users-selector.component.scss'],
  animations: absAnimations,
  encapsulation: ViewEncapsulation.None,
})
export class UsersSelectorComponent implements OnInit {

  flip: any;
  @Input()
  groups: any;

  searchCtrl = new FormControl();


  users = [];
  allUsers = [];


  selection = new SelectionModel<any>(true, []);
  @Output() onBackButton = new EventEmitter<any>();
  @Output() onUserSelectionDone = new EventEmitter<any>();


  constructor(private _groupService: GroupsService) {
  }

  onChange(user) {
    this.selection.toggle(user);
    this._groupService.userSelection.toggle(user);
    console.log("selection", this._groupService.userSelection);
    this._groupService.onSelectedUsersChanged.next(this._groupService.userSelection.selected);
  }


  ngOnInit(): void {
    console.log("groups", this.groups);
    this.groups.forEach(element => {
      element.userList.forEach(user => {
        let ind = this.checkIfUserAlreadySelected(user);
        if (!ind) {
          this.users.push(user);
          this.allUsers.push(user);
          this.selection.select(user);
        }
      })
    });
    console.log("user", this.users);

    this.searchCtrl.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe(value => {
      if (value.length > 0) {
        var result = AbsUtils.filterArrayByString(this.allUsers, value);
        this.users = result;
      } else {
        this.users = this.allUsers;

      }
    });
  }
  handleBack() {
    this.onBackButton.emit('users');
  }

  selectionDone() {
    this.onUserSelectionDone.emit({ users: this.selection.selected, groups: this.groups });
  }
  descClicked(value) {
    this.flip = value;
  }
  checkIfUserAlreadySelected(newUser) {
    return this.users.find(user => user.profileId === newUser.profileId);
  }
}
