import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NotificationDialogComponent } from '../notification-dialog/notification-dialog.component';
import { absAnimations } from '@abs/animations';
import { ReportingDialogComponent } from '../reporting-dialog/reporting-dialog.component';
import { ProfileDialogComponent } from '../profile-dialog/profile-dialog.component';
import { ExperienceDialogComponent } from '../experience-dialog/experience-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-convenience-setting',
  templateUrl: './convenience-setting.component.html',
  styleUrls: ['./convenience-setting.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: absAnimations
})
export class ConvenienceSettingComponent implements OnInit {

  notificationDialogRef: any;
  reportingDialogRef: any;
  experienceDialogRef: any;
  profileDialogRef: any;

  notification = true;
  reporting = false;
  experience = false;
  profile = false;
  snackBarRef: any;

  constructor(public _matNotificationDialog: MatDialog, public _matReportingDialog: MatDialog, public _matExperienceDialog: MatDialog, public _matProfileDialog: MatDialog, public _matSnackBar: MatSnackBar) { }

  ngOnInit() {



  }

  openNotificationDialog(): void {
    this.notificationDialogRef = this._matNotificationDialog.open(NotificationDialogComponent, {
      panelClass: 'contact-form-dialog',
      disableClose: true,
      width: '1600px',
      data: {
      }
    });
    this.notificationDialogRef.afterClosed()
      .subscribe(response => {
        if (response == 'done') {
          this.snackBarRef = this._matSnackBar.open('Your selections are saved in your profile. They can be change at any time by visiting your user profile and choosing Notifications Settings.', 'Continue', {
            verticalPosition: 'top',
          });
          this.snackBarRef.onAction().subscribe(() => {
            this.nextStep(1)
          });
        }
      });
  }

  openReportingDialog(): void {
    this.reportingDialogRef = this._matReportingDialog.open(ReportingDialogComponent, {
      panelClass: 'contact-form-dialog',
      disableClose: true,
      width: '800px',
      data: {
      }
    });
    this.reportingDialogRef.afterClosed()
      .subscribe(response => {
        if (response == 'done') {
          this.snackBarRef = this._matSnackBar.open('Custom reports can be created, saved and automated by visiting the Reporting section. There are many options and fields available to create very specific and unique reports. The report data can be exported or sent as a PDF.', 'Continue', {
            verticalPosition: 'top',
          });
          this.snackBarRef.onAction().subscribe(() => {
            this.nextStep(2)
          });
        }
      });
  }

  openExperienceDialog(): void {
    this.experienceDialogRef = this._matExperienceDialog.open(ExperienceDialogComponent, {
      panelClass: 'contact-form-dialog',
      disableClose: true,
      width: '800px',
      data: {
      }
    });
    this.experienceDialogRef.afterClosed()
      .subscribe(response => {
        if (response == 'done') {
          this.snackBarRef = this._matSnackBar.open('Many options within the Experience Settings can be changed to taylor how you interact with the system, including date and time, language and communication preferences.', 'Continue', {
            verticalPosition: 'top',
          });
          this.snackBarRef.onAction().subscribe(() => {
            this.nextStep(3)
          });
        }
      });
  }

  openProfileDialog(): void {
    this.profileDialogRef = this._matProfileDialog.open(ProfileDialogComponent, {
      panelClass: 'contact-form-dialog',
      //disableClose: true,
      width: '800px',
      data: {
      }
    });
    this.profileDialogRef.afterClosed()
      .subscribe(response => {
      });
  }



  nextStep(index) {
    if (index == 1) {
      this.notification = false;
      this.reporting = true;
    }
    if (index == 2) {
      this.reporting = false;
      this.experience = true;
    }
    if (index == 3) {
      this.experience = false;
      this.profile = true;
    }
  }



}
