import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { absAnimations } from '@abs/animations';
import { Playlist } from './playlists.model';
import { FormControl } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ContentService } from '../content.service';
import { Content } from '../content-list.model';
import { environment } from 'environments/environment';
import { AbsUtils } from '@abs/utils';
import { MatDialog } from '@angular/material/dialog';
import { PreviewComponent } from '../videos/preview/preview.component';


@Component({
  selector: 'app-playlists',
  templateUrl: './playlists.component.html',
  styleUrls: ['./playlists.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: absAnimations
})
export class PlaylistsComponent implements OnInit {
  playlist = new FormControl();
  playlists: Playlist[];
  selected = 'Favourite';
  view = 'horizontal';
  environment: any;
  playlistContent: Observable<Content[]>;
  allContnets: Content[] = [];

  constructor(public dialog: MatDialog, public contentService: ContentService) {
    this.environment = environment;
    this.playlist.valueChanges
      .pipe(
        startWith(''),
        map(playlist => this.getPlaylistContents(playlist.id))
      );
    this.contentService.getPlaylists(1, 1000).subscribe(res => {
      console.log("res", res);
      this.playlists = res;
      if (this.playlists) {
        this.getPlaylistContents(this.playlists[0].id);
        this.playlist.setValue(this.playlists[0].id);
      }
    })
  }

  ngOnInit() {
  }

  private getPlaylistContents(playlistId) {
    console.log("PlayList id", playlistId);
    this.playlistContent = this.contentService.getPlaylistContents(playlistId, 1, 1000);
    this.playlistContent.subscribe(content => { 
      console.log("content",content);
      this.allContnets = content 
    });
  }

  updateContent() {
    this.getPlaylistContents(this.playlist.value);
  }

  openDialog(videoEL) {
    this.dialog.open(PreviewComponent, {
      id: 'preview',
      backdropClass: 'previewBackdrop',
      width: '1165px',
      data: {
        video: videoEL,
        similarVideos: [],
        preview: true
      }
    });
  }
  generateFake(count: number): Array<number> {
    const indexes = [];
    for (let i = 0; i < count; i++) {
      indexes.push(i);
    }
    return indexes;
  }

  filterContent(value) {
    if (value === "all") {
      this.playlistContent = of(this.allContnets);
    } else {
      this.playlistContent = of(AbsUtils.filterArrayByString(this.allContnets, value));
    }
  }

  viewChanged(event) {
    this.view = event.value;
  }
}
