
import { FileManagerService } from '../../my-uploads.service';
import { MatDialogRef } from '@angular/material/dialog';



import { Component, Inject, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgForm, FormGroup, FormBuilder } from '@angular/forms';
import { Subject } from 'rxjs';

import { AbsConfirmDialogComponent } from '@abs/components/confirm-dialog/confirm-dialog.component';
import { AbsUtils } from '@abs/utils';
import { absAnimations } from '@abs/animations';
import { takeUntil } from 'rxjs/operators';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatSnackBar } from '@angular/material/snack-bar';
import { File } from '../../file.model';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { ElementRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-thumbnail-selector',
  templateUrl: './thumbnail-selector.component.html',
  styleUrls: ['./thumbnail-selector.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ThumbnailSelectorComponent implements OnInit {

  images: any;

  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];


  private allowFreeTextAddCat = false;
  categoryCtrl = new FormControl();
  filteredCategories: Observable<String[]>;
  categories: Category[] = [];
  allCategories: any[] = [];
  @ViewChild('categoryInput') categoryInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocompleteCategory: MatAutocomplete;


  tagCtrl = new FormControl();
  filteredTags: Observable<String[]>;
  tags: Tag[] = [];
  allTags: any[] = [];

  @ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto1') matAutocompleteTag: MatAutocomplete;

  constructor(private _myUploadService: FileManagerService, public matDialogRef: MatDialogRef<ThumbnailSelectorComponent>) {
  }


  ngOnInit() {

    this._myUploadService.getThumbnails().subscribe((response: any) => {
      this.images = response;
    });
    this.allCategories = this._myUploadService.getCategories().map(category => {
      return new Category(category);
    });

    console.log(this.allCategories);
    this.allTags = this._myUploadService.getTags().map(tag => {
      return new Tag(tag);
    });


    this.filteredCategories = this.categoryCtrl.valueChanges.pipe(
      startWith(null),
      map(name => this.filterOnValueChangeCategory(name))
    );

    this.filteredTags = this.tagCtrl.valueChanges.pipe(
      startWith(null),
      map(name => this.filterOnValueChangeTag(name))
    );
  }

  public addCategory(event: MatChipInputEvent): void {
    if (!this.allowFreeTextAddCat) {
      console.log('allowFreeTextAddEngineer is false');
      return;
    }
    if (this.matAutocompleteCategory.isOpen) {
      return;
    }

    // Add our engineer
    const value = event.value;
    if ((value || '').trim()) {
      this.selectCategoryByName(value.trim());
    }

    this.resetInputs();
  }

  public removeCategory(cat: Category): void {
    const index = this.categories.indexOf(cat);
    if (index >= 0) {
      this.categories.splice(index, 1);
      this.resetInputs();
    }
  }

  public categorySelected(event: MatAutocompleteSelectedEvent): void {
    this.selectCategoryByName(event.option.value);
    this.resetInputs();
  }

  private resetInputs() {
    this.categoryInput.nativeElement.value = '';
    this.categoryCtrl.setValue(null);
  }

  //
  // Compute a new autocomplete list each time control value changes
  //
  private filterOnValueChangeCategory(name: string | null): String[] {
    let result: String[] = [];
    let allEngineersLessSelected = this.allCategories.filter(engineer => this.categories.indexOf(engineer) < 0);
    if (name) {
      result = this.filterCategory(allEngineersLessSelected, name);
    } else {
      result = allEngineersLessSelected.map(engineer => engineer.name);
    }
    return result;
  }

  private filterCategory(engineerList: Category[], name: string): String[] {
    let filteredEngineerList: Category[] = [];
    const filterValue = name.toLowerCase();
    let engineersMatchingEngineerName = engineerList.filter(engineer => engineer.name.toLowerCase().indexOf(filterValue) === 0);
    if (engineersMatchingEngineerName.length || this.allowFreeTextAddCat) {
      filteredEngineerList = engineersMatchingEngineerName;
    } else {
      filteredEngineerList = engineerList;
    }
    return filteredEngineerList.map(engineer => engineer.name);
  }

  private selectCategoryByName(name) {
    let foundEngineer = this.allCategories.filter(engineer => engineer.name == name);
    if (foundEngineer.length) {
      this.categories.push(foundEngineer[0]);
    } else {

      this.categories.push({ id: '', name: name.trim() });
    }
  }

  onImageSelect(item) {
    this.matDialogRef.close(item.image);
  }



  public addTag(event: MatChipInputEvent): void {
    if (!this.allowFreeTextAddCat) {
      console.log('allowFreeTextAddEngineer is false');
      return;
    }
    if (this.matAutocompleteTag.isOpen) {
      return;
    }
    const value = event.value;
    if ((value || '').trim()) {
      this.selectTagByName(value.trim());
    }

    this.resetTagInputs();
  }

  public removeTag(cat: Tag): void {
    const index = this.tags.indexOf(cat);
    if (index >= 0) {
      this.tags.splice(index, 1);
      this.resetTagInputs();
    }
  }

  public tagSelected(event: MatAutocompleteSelectedEvent): void {
    this.selectTagByName(event.option.value);
    this.resetTagInputs();
  }

  private resetTagInputs() {
    this.tagInput.nativeElement.value = '';
    this.tagCtrl.setValue(null);
  }

  private filterOnValueChangeTag(name: string | null): String[] {
    let result: String[] = [];
    let allEngineersLessSelected = this.allTags.filter(engineer => this.tags.indexOf(engineer) < 0);
    if (name) {
      result = this.filterTag(allEngineersLessSelected, name);
    } else {
      result = allEngineersLessSelected.map(engineer => engineer.name);
    }
    return result;
  }

  private filterTag(engineerList: Tag[], name: string): String[] {
    let filteredEngineerList: Tag[] = [];
    const filterValue = name.toLowerCase();
    let engineersMatchingEngineerName = engineerList.filter(engineer => engineer.name.toLowerCase().indexOf(filterValue) === 0);
    if (engineersMatchingEngineerName.length || this.allowFreeTextAddCat) {
      filteredEngineerList = engineersMatchingEngineerName;
    } else {
      filteredEngineerList = engineerList;
    }
    return filteredEngineerList.map(engineer => engineer.name);
  }

  private selectTagByName(name) {
    let foundEngineer = this.allTags.filter(engineer => engineer.name == name);
    if (foundEngineer.length) {
      this.tags.push(foundEngineer[0]);
    } else {

      this.tags.push({ id: '', name: name.trim() });
    }
  }
}

export class Category {
  id: string;
  name: string;


  constructor(cat) {
    {
      this.id = cat.id || AbsUtils.generateGUID();
      this.name = cat.name || '';
    }
  }


}

export class Tag {
  id: string;
  name: string;

  constructor(tag) {
    {
      this.id = tag.id || AbsUtils.generateGUID();
      this.name = tag.name || '';
    }
  }

}