import { Component, OnInit, Injectable, Inject } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlattener, MatTreeFlatDataSource } from '@angular/material/tree';
import { SelectionModel } from '@angular/cdk/collections';
import { TrainingService } from '../../_services/training.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AbsProgressBarService } from '@abs/components/progress-bar/progress-bar.service';
import * as moment from 'moment';
import { MatSnackBar } from '@angular/material/snack-bar';

export class TodoItemNode {
  children: TodoItemNode[];
  item: string;
  profileId: number;
}

/** Flat to-do item node with expandable and level information */
export class TodoItemFlatNode {
  item: string;
  level: number;
  expandable: boolean;
  profileId: number;
}

/**
 * The Json object for to-do list data.
 */




@Component({
  selector: 'app-independent-training-invite-dialog',
  templateUrl: './independent-training-invite-dialog.component.html',
  styleUrls: ['./independent-training-invite-dialog.component.scss'],
})
export class IndependentTrainingInviteDialogComponent implements OnInit {

  TREE_DATA: TodoItemNode[] = [];

  classId: any;
  productId: any;

  /** Map from flat node to nested node. This helps us finding the nested node to be modified */
  flatNodeMap = new Map<TodoItemFlatNode, TodoItemNode>();

  /** Map from nested node to flattened node. This helps us to keep the same object for selection */
  nestedNodeMap = new Map<TodoItemNode, TodoItemFlatNode>();

  /** A selected parent node to be inserted */
  selectedParent: TodoItemFlatNode | null = null;

  /** The new item's name */
  newItemName = '';

  treeControl: FlatTreeControl<TodoItemFlatNode>;

  treeFlattener: MatTreeFlattener<TodoItemNode, TodoItemFlatNode>;

  dataSource: MatTreeFlatDataSource<TodoItemNode, TodoItemFlatNode>;

  /** The selection for checklist */
  checklistSelection = new SelectionModel<TodoItemFlatNode>(true /* multiple */);



  getLevel = (node: TodoItemFlatNode) => node.level;

  isExpandable = (node: TodoItemFlatNode) => node.expandable;

  getChildren = (node: TodoItemNode): TodoItemNode[] => node.children;

  hasChild = (_: number, _nodeData: TodoItemFlatNode) => _nodeData.expandable;

  hasNoContent = (_: number, _nodeData: TodoItemFlatNode) => _nodeData.item === '';

  /**
   * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
   */
  transformer = (node: TodoItemNode, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode = existingNode && existingNode.item === node.item
      ? existingNode
      : new TodoItemFlatNode();
    flatNode.item = node.item;
    flatNode.level = level;
    flatNode.profileId = node.profileId;
    flatNode.expandable = !!node.children && node.children.length > 0;
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  }

  /** Whether all the descendants of the node are selected. */
  descendantsAllSelected(node: TodoItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.length > 0 && descendants.every(child => {
      return this.checklistSelection.isSelected(child);
    });
    return descAllSelected;
  }

  /** Whether part of the descendants are selected */
  descendantsPartiallySelected(node: TodoItemFlatNode): boolean {
    console.log("node", node);
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some(child => this.checklistSelection.isSelected(child));
    return result && !this.descendantsAllSelected(node);
  }

  /** Toggle the to-do item selection. Select/deselect all the descendants node */
  todoItemSelectionToggle(node: TodoItemFlatNode): void {
    this.checklistSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node);
    this.checklistSelection.isSelected(node)
      ? this.checklistSelection.select(...descendants)
      : this.checklistSelection.deselect(...descendants);

    // Force update for the parent
    descendants.forEach(child => this.checklistSelection.isSelected(child));
    this.checkAllParentsSelection(node);
  }

  /** Toggle a leaf to-do item selection. Check all the parents to see if they changed */
  todoLeafItemSelectionToggle(node: TodoItemFlatNode): void {
    console.log(this.checklistSelection);
    this.checklistSelection.toggle(node);
    this.checkAllParentsSelection(node);
  }

  /* Checks all the parents when a leaf node is selected/unselected */
  checkAllParentsSelection(node: TodoItemFlatNode): void {
    let parent: TodoItemFlatNode | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }

  /** Check root node checked state and change it accordingly */
  checkRootNodeSelection(node: TodoItemFlatNode): void {
    const nodeSelected = this.checklistSelection.isSelected(node);
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.length > 0 && descendants.every(child => {
      return this.checklistSelection.isSelected(child);
    });
    if (nodeSelected && !descAllSelected) {
      this.checklistSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.checklistSelection.select(node);
    }
  }

  /* Get the parent node of a node */
  getParentNode(node: TodoItemFlatNode): TodoItemFlatNode | null {
    const currentLevel = this.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }

  constructor(public dialogRef: MatDialogRef<IndependentTrainingInviteDialogComponent>,
    private _trainingService: TrainingService,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private _progressBarService: AbsProgressBarService,
    private trainingService: TrainingService,
    private _snackBar: MatSnackBar) {

    this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel,
      this.isExpandable, this.getChildren);
    this.treeControl = new FlatTreeControl<TodoItemFlatNode>(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
    this.dataSource.data = this.TREE_DATA;

    this.classId = _data.classId;
    this.productId = _data.pid;
    this._trainingService.getTrainingCourseResult(this.classId, this.productId).then(result => {
      console.log("results", result);

      if (result) {

        const failed = result.results.filter(r => r.status == 'FAILED').map(r => {

          return { item: r.firstName + " " + r.lastName, children: [], profileId: r.profileId };
        });
        const noShow = result.results.filter(r => r.status == 'NO_SHOW').map(r => {

          return { item: r.firstName + " " + r.lastName, children: [], profileId: r.profileId };
        });
        const inProgess = result.results.filter(r => r.status == 'IN_PROGRESS').map(r => {

          return { item: r.firstName + " " + r.lastName, children: [], profileId: r.profileId };
        });

        const notStarted = result.results.filter(r => r.status == 'NOT_STARTED').map(r => {

          return { item: r.firstName + " " + r.lastName, children: [], profileId: r.profileId };
        });

        if (inProgess && inProgess.length > 0) {
          this.TREE_DATA.push({ item: "In Progress", children: inProgess, profileId: 0 })
        }
        if (failed && failed.length > 0) {

          this.TREE_DATA.push({ item: "Failed", children: failed, profileId: 0 })
        }
        if (noShow && noShow.length > 0) {

          this.TREE_DATA.push({ item: "No Show", children: noShow, profileId: 0 })
        }

        if (notStarted && notStarted.length > 0) {

          this.TREE_DATA.push({ item: "Not Started Yet", children: notStarted, profileId: 0 })
        }
        console.log(this.TREE_DATA);
        //let data = this.dataSource.data ;
        //this.dataSource.data = null;
        this.dataSource.data = this.TREE_DATA;
      }
    });


  }

  ngOnInit() {


  }

  skip(){
    this.dialogRef.close('close');
  }


  sendInvites() {
    console.log("data",this.checklistSelection);
    this._progressBarService.show();

    let userList = this.checklistSelection.selected.filter(u=>u.profileId!=0).map(user => {
        return ({ userId: user.profileId, userType: 'profile' })
    })
    let contentList = [{ trainId: this.productId, trainType: 'product' }];

    let scheduleData: any = {};
    scheduleData.users = userList
    scheduleData.courses = contentList;
    scheduleData.type = 'INDIVIDUAL';
    scheduleData.profileID = JSON.parse(localStorage.getItem("currentUser")).profileId;
    scheduleData.passingScore = 80;

    scheduleData.startDate = moment().format("YYYY-MM-DD");
    scheduleData.endDate = moment().add(5, 'days').format("YYYY-MM-DD");
    scheduleData.graceDays = 1;
    scheduleData.fastForward = true;
    scheduleData.title = "Make Up Trainig";
    scheduleData.notes = "Make Up";
    scheduleData.status = 'STARTED';
    scheduleData.triggerType = 'SCHEDULE';


    this.trainingService.scheduleTraining(scheduleData).then(res => {
      this._progressBarService.hide();
      if (res == 1) {
       // this.dialogRef.close('close');
        this._snackBar.open("Training has been scheduled for the selected users", 'Ok', {
          panelClass: 'green-bg',
          duration: 6000,
          horizontalPosition: 'right',
          verticalPosition: 'top',
          politeness: 'polite',
        });
        this.dialogRef.close('close');
      } else {
        this._snackBar.open("Something went wrong while scheduling training.", 'Ok', {
          panelClass: 'red-bg',
          duration: 3000,
          horizontalPosition: 'right',
          verticalPosition: 'top',
          politeness: 'polite',
        });
      }
    })
  }

}
