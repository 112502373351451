import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {AbsWidgetComponent} from './widget.component';
import {AbsWidgetToggleDirective} from './widget-toggle.directive';

@NgModule({
  declarations: [
    AbsWidgetComponent,
    AbsWidgetToggleDirective
  ],
  exports: [
    AbsWidgetComponent,
    AbsWidgetToggleDirective
  ]
})
export class AbsWidgetModule { }
