import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { SelectionModel } from '@angular/cdk/collections';
import { ContentService } from 'app/main/content/content.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AbsUtils } from '@abs/utils';
import { AuthenticationService } from '../../../../authentication/_services';
@Component({
  selector: 'app-content-share-group-selector',
  templateUrl: './content-share-group-selector.component.html',
  styleUrls: ['./content-share-group-selector.component.scss']
})
export class ContentShareGroupSelectorComponent implements OnInit {

  flip: any;

  @Input()
  groups: any;
  allGroups: any;

  @Input()
  selected: any;

  search = new FormControl();
  selection = new SelectionModel<any>(true, []);


  @Output() onBackButton = new EventEmitter<any>();
  @Output() onSelection = new EventEmitter<any>();
  @Output() onDone = new EventEmitter<any>();

  constructor(private contentService: ContentService, private authService: AuthenticationService) { }

  ngOnInit(): void {
    if(this.selected && this.selected.length > 0){
      this.selected.forEach(id=>{
        this.selection.select(id);
      })
    }

    this.search.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe(value => {
      if (value.length > 0) {
        var result = AbsUtils.filterArrayByString(this.groups, value);
        this.groups = result;
      } else {
        this.groups = this.allGroups;

      }
    });
  }

  onContinue() {
    this.onDone.next({ data: this.selection.selected, type: 'groups' })
  }
  handleBack() {
    this.onBackButton.next("asd")
  }

  onChange(event) {
    this.selection.toggle(event);
  } descClicked(asd) { }

}
