export class TilesFakeDb {


  public static tiles = [
    {
      'heading': 'The Most Feature Rich Solution Available',
      'URL': 'https://www.itrainstation.com/itrain/literature/basc/featurelist.pdf',
      'image': 'https://www.itrainstation.com/assets/images/tiles/icon/PlatformPlus.png',
      'body-text': 'iTrainStation has four unique solutions available, each with the ability to be uniquely customized. Don\'t settle for a one size fits all, gat a solution that\'s tailored to your needs and only pay for what you need or use.',
      'button-text': 'More Info',
      'idCategories'         : [
        '6540635g19ad3s5dc31412b2',
        '56027e4119ad3a5dc28b36cd'
      ],
      'idTags'         : [
        '6540635g19ad3s5dc31412b2',
        '56027e4119ad3a5dc28b36cd'
      ]
    }, {
      'heading': 'The Most Powerful, Most Flexible System Available',
      'URL': 'https://www.itrainstation.com/itrain/literature/basc/iTrainStation%20Platform%20Flyer.pdf',
      'image': 'https://www.itrainstation.com/assets/images/tiles/icon/iTrainStationFuture.png',
      'body-text': 'iTrainStation has been in development since 2006. With a wealth of features, that makes this the only platform available using future technologies to ensure compatibility.',
      'button-text': 'More Info',
      'idCategories'         : [
        '6540635g19ad3s5dc31412b2',
        '56027e4119ad3a5dc28b36cd'
      ],
      'idTags'         : [
        '6540635g19ad3s5dc31412b2',
        '56027e4119ad3a5dc28b36cd'
      ]
    }, {
      'heading': 'Substance Abuse Training Videos and Courses',
      'URL': 'https://www.itrainstation.com/zSearch.jsp?keywords=drugs',
      'image': 'https://www.itrainstation.com/assets/images/tiles/art/ImageSubstanceAbuse.png',
      'body-text': 'Find the right training videos that are perfect for your organization within our huge catalog of online videos. Each course comes equipped with a quiz, and most are available in Spanish.',
      'button-text': 'More Info',
      'idCategories'         : [
        '6540635g19ad3s5dc31412b2',
        '56027e4119ad3a5dc28b36cd'
      ],
      'idTags'         : [
        '6540635g19ad3s5dc31412b2',
        '56027e4119ad3a5dc28b36cd'
      ]
    }, {
      'heading': 'No Credit Card Needed To Try!',
      'URL': 'https://www.itrainstation.com/itrain/go.jsp?op=create',
      'image': 'https://www.itrainstation.com/assets/images/tiles/icon/TrialDemo.png',
      'body-text': 'The best way to experience the power and flexibility of iTrainStation is through a trial. Sign up now and get immediate, full access to LMS Lite. No credit card required.',
      'button-text': 'Start Trial',
      'idCategories'         : [
        '6540635g19ad3s5dc31412b2',
        '56027e4119ad3a5dc28b36cd'
      ],
      'idTags'         : [
        '6540635g19ad3s5dc31412b2',
        '56027e4119ad3a5dc28b36cd'
      ]
    }, {
      'heading': '500 Video Based Courses',
      'URL': 'https://www.itrainstation.com/zSearch.jsp',
      'image': 'https://www.itrainstation.com/assets/images/tiles/static/Catalog500.png',
      'body-text': 'It\'s time to leave the powerpoints behind!  Enjoy choosing from over 500 unique video on all the major subjects.  Many are available in Spanish! They all come with a quiz too!',
      'button-text': 'Full Catalog',
      'idCategories'         : [
        '6540635g19ad3s5dc31412b2',
        '56027e4119ad3a5dc28b36cd'
      ],
      'idTags'         : [
        '6540635g19ad3s5dc31412b2',
        '56027e4119ad3a5dc28b36cd'
      ]
    }, {
      'heading': 'The Most Feature Rich Solution Available',
      'URL': 'https://www.itrainstation.com/itrain/literature/basc/featurelist.pdf',
      'image': 'https://www.itrainstation.com/assets/images/tiles/icon/PlatformPlus.png',
      'body-text': 'iTrainStation has four unique solutions available, each with the ability to be uniquely customized. Don\'t settle for a one size fits all, gat a solution that\'s tailored to your needs and only pay for what you need or use.',
      'button-text': 'More Info',
      'idCategories'         : [
        '6540635g19ad3s5dc31412b2',
        '56027e4119ad3a5dc28b36cd'
      ],
      'idTags'         : [
        '6540635g19ad3s5dc31412b2',
        '56027e4119ad3a5dc28b36cd'
      ]
    }, {
      'heading': 'The Most Powerful, Most Flexible System Available',
      'URL': 'https://www.itrainstation.com/itrain/literature/basc/iTrainStation%20Platform%20Flyer.pdf',
      'image': 'https://www.itrainstation.com/assets/images/tiles/icon/iTrainStationFuture.png',
      'body-text': 'iTrainStation has been in development since 2006. With a wealth of features, that makes this the only platform available using future technologies to ensure compatibility.',
      'button-text': 'More Info',
      'idCategories'         : [
        '6540635g19ad3s5dc31412b2',
        '56027e4119ad3a5dc28b36cd'
      ],
      'idTags'         : [
        '6540635g19ad3s5dc31412b2',
        '56027e4119ad3a5dc28b36cd'
      ]
    }, {
      'heading': 'Substance Abuse Training Videos and Courses',
      'URL': 'https://www.itrainstation.com/zSearch.jsp?keywords=drugs',
      'image': 'https://www.itrainstation.com/assets/images/tiles/art/ImageSubstanceAbuse.png',
      'body-text': 'Find the right training videos that are perfect for your organization within our huge catalog of online videos. Each course comes equipped with a quiz, and most are available in Spanish.',
      'button-text': 'More Info',
      'idCategories'         : [
        '6540635g19ad3s5dc31412b2',
        '56027e4119ad3a5dc28b36cd'
      ],
      'idTags'         : [
        '6540635g19ad3s5dc31412b2',
        '56027e4119ad3a5dc28b36cd'
      ]
    }, {
      'heading': 'No Credit Card Needed To Try!',
      'URL': 'https://www.itrainstation.com/itrain/go.jsp?op=create',
      'image': 'https://www.itrainstation.com/assets/images/tiles/icon/TrialDemo.png',
      'body-text': 'The best way to experience the power and flexibility of iTrainStation is through a trial. Sign up now and get immediate, full access to LMS Lite. No credit card required.',
      'button-text': 'Start Trial',
      'idCategories'         : [
        '6540635g19ad3s5dc31412b2',
        '56027e4119ad3a5dc28b36cd'
      ],
      'idTags'         : [
        '6540635g19ad3s5dc31412b2',
        '56027e4119ad3a5dc28b36cd'
      ]
    }, {
      'heading': 'Quizzes Available in PDF or Online!',
      'URL': 'https://www.youtube.com/watch?v=vHrZ2DTLyOc&index=3&list=PLNTF2Mq4ZUS02x2yA2sBkSmPlG-x04kvc&t=0s',
      'image': 'https://www.itrainstation.com/assets/images/tiles/icon/LMSQuiz.png',
      'body-text': 'Stop fooling around with outdated, unsupported LMS shells. iTrainStation is a fully functional, fully supported, full blown LMS with many user requested enhancements built in.',
      'button-text': 'Watch Video',
      'idCategories'         : [
        '6540635g19ad3s5dc31412b2',
        '56027e4119ad3a5dc28b36cd'
      ],
      'idTags'         : [
        '6540635g19ad3s5dc31412b2',
        '56027e4119ad3a5dc28b36cd'
      ]
    }, {
      'heading': 'Construction Safety Training Videos and Courses',
      'URL': 'https://www.itrainstation.com/zSearch.jsp?keywords=construction',
      'image': 'https://www.itrainstation.com/assets/images/tiles/art/ImageBuilder.png',
      'body-text': 'Find the right training videos that are perfect for your organization within our huge catalog of online videos. Each course comes equipped with a quiz, and most are available in Spanish.',
      'button-text': 'More Info',
      'idCategories'         : [
        '6540635g19ad3s5dc31412b2',
        '56027e4119ad3a5dc28b36cd'
      ],
      'idTags'         : [
        '6540635g19ad3s5dc31412b2',
        '56027e4119ad3a5dc28b36cd'
      ]
    }, {
      'heading': '500 Video Based Courses',
      'URL': 'https://www.itrainstation.com/zSearch.jsp',
      'image': 'https://www.itrainstation.com/assets/images/tiles/static/Catalog500.png',
      'body-text': 'It\'s time to leave the powerpoints behind!  Enjoy choosing from over 500 unique video on all the major subjects.  Many are available in Spanish! They all come with a quiz too!',
      'button-text': 'Full Catalog',
      'idCategories'         : [
        '6540635g19ad3s5dc31412b2',
        '56027e4119ad3a5dc28b36cd'
      ],
      'idTags'         : [
        '6540635g19ad3s5dc31412b2',
        '56027e4119ad3a5dc28b36cd'
      ]
    }
  ];
}
