import { Component, OnInit, Output, EventEmitter, Input, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { SelectionModel } from '@angular/cdk/collections';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AbsUtils } from '@abs/utils';
import { absAnimations } from '@abs/animations';
@Component({
  selector: 'app-favorite-playlist-selector',
  templateUrl: './favorite-playlist-selector.component.html',
  styleUrls: ['./favorite-playlist-selector.component.scss'],
  animations: absAnimations,
  encapsulation: ViewEncapsulation.None,
})
export class FavoritePlaylistSelectorComponent implements OnInit {

  flip: any;
  @Input()
  playlists: any;

  allPlaylists: any;
  searchCtrlOrg = new FormControl();
  selection = new SelectionModel<any>(true, []);
  @Output() onSelection = new EventEmitter<any>();
  @Output() onDone = new EventEmitter<any>();
  @Output() onBackButton = new EventEmitter<any>();

  constructor() {
  }

  ngOnInit(): void {
    this.allPlaylists = this.playlists;

    console.log("fav playlist", this.playlists);
    this.searchCtrlOrg.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe(value => {
      if (value.length > 0) {
        var result = AbsUtils.filterArrayByString(this.allPlaylists, value);
        this.playlists = result;
      } else {
        this.playlists = this.allPlaylists;

      }
    });
  }

  onChange(group) {
    if (group.contentList && group.contentList.length > 0) {
      this.selection.toggle(group);
      this.onSelection.emit(this.selection.selected);
    }
  }

  handleBack() {
    this.onBackButton.emit('myfav');
  }
  playlistSelected() {
    this.onDone.emit(this.selection.selected);
  }
  descClicked(value) {
    this.flip = value;
  }
}
