
import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { PlayList } from '../_models/playlist';
import { TrainingService } from '../_services/training.service';
import { DataSource } from '@angular/cdk/table';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { MyCalendarService } from '../_services/my-calendar.service';
import { AuthenticationService } from '../../authentication/_services';
import { SelectionModel } from '@angular/cdk/collections';
import { ActionBarService } from '../../../layout/components/action-bar/action-bar.service';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { AbsProgressBarService } from '@abs/components/progress-bar/progress-bar.service';

@Component({
  selector: 'app-training-agenda',
  templateUrl: './training-agenda.component.html',
  styleUrls: ['./training-agenda.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*', marginTop: '12px', marginBottom: '12px' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class TrainingAgendaComponent implements OnInit {

  currentElement : any;


  viewList: any;
  statusList:any;
  assginedList: any;
  trainingType: any;

  loading = true;

  selection = new SelectionModel<any>(false, []);
  dataSource: any;
  dialogRef: any;
  courseDetail: any;
  // columnsToDisplay = ['buttons', 'date', 'playlist', 'courseCount', 'passed', 'failed', 'inProgress', 'noShow', 'averageQAttempts', 'averageScore', 'percentComplete'];
  columnsToDisplay = ['buttons', 'playlist', 'courseCount', 'passed', 'failed', 'inProgress', 'noShow', 'averageQAttempts', 'averageScore', 'percentComplete'];

  expandedCourse: any | null;
  expandedStudent: any | null;

  groupBy = '';
  // Private
  private _unsubscribeAll: Subject<any>;
  constructor(private myCalendarService: MyCalendarService,
    public dialog: MatDialog,
    public router: Router,
    public progressBarService: AbsProgressBarService,
    public _actionBarService: ActionBarService,
    public authService: AuthenticationService) {
  }

  ngOnInit() {

    this.viewList = [{ value: 'all', name: 'All', selected: true },
    { value: 'future', name: 'Future Training', selected: false },
    { value: 'current', name: 'Current Training', selected: false },
    { value: 'past', name: 'Past Training', selected: false }];


    this.statusList = [{ value: 'notstarted', name: 'Not Started', selected: false },
    { value: 'inprogress', name: 'In Progress', selected: false },
    { value: 'passed', name: 'Passed', selected: false },
    { value: 'failed', name: 'Failed', selected: false }];

    this.assginedList = [
    { value: 'AUTOMATED', name: 'On Boarding', selected: false },
    { value: 'SCHEDULE', name: 'Scheduled', selected: false },
    { value: 'RECURRING', name: 'Recurring', selected: false },
    { value: 'FREQUENT', name: 'Frequent', selected: false },

  ];

    this.trainingType = [{ value: 'CLASSROOM', name: 'Classroom', selected: false },
    { value: 'INDIVIDUAL', name: 'Independent', selected: false }];

    
    this.searchContent();

    this._actionBarService.onStartClassClick.subscribe(res => {
      this.router.navigate(['/training/classroom'], { queryParams: { cid: this.selection.selected[0].id, name: this.selection.selected[0].name } });
    });

  }

  showDetailStudentDetail(element) {
    this.currentElement = element.id;
    this.expandedCourse = null;
    this.expandedStudent = this.expandedStudent = this.expandedStudent === element ? null : element
  }

  showDetailStudentCourse(element) {
    this.currentElement = element.id;
    this.expandedStudent = null;
    this.expandedCourse = this.expandedCourse = this.expandedCourse === element ? null : element
  }


  contentTypeSelected($event, contentType): void {
    this.clearContentTypeSelection(contentType);
    contentType.selected = !contentType.selected;
    this.searchContent();

  }

  changeSelected($event, category): void {
    console.log("change main", category); 
    category.selected = !category.selected
    this.searchContent();
  }

  assignedTypeSelected($event, category){
    this.clearAssignTypeSelection(category);
    category.selected = !category.selected;
    this.searchContent();
  }

  trainingTypeSelected($event, category){
    this.clearTrainingTypeSelection(category);
    category.selected = !category.selected;
    this.searchContent();
  }

  clearContentTypeSelection(contentType) {
    this.viewList.forEach(x => {
      if (x.value != contentType.value) {
        x.selected = false
      }
    });
  }

  clearTrainingTypeSelection(contentType) {
    this.trainingType.forEach(x => {
      if (x.value != contentType.value) {
        x.selected = false
      }
    });
  }


  clearAssignTypeSelection(contentType) {
    this.assginedList.forEach(x => {
      if (x.value != contentType.value) {
        x.selected = false
      }
    });
  }

  searchContent() {

    this.progressBarService.show();
    console.log(this.viewList,this.viewList.filter(x => x.selected == true));

    var viewType = this.viewList.filter(x => x.selected == true).map(x => {
      return x.value;
    });

    var triggerType = this.assginedList.filter(x => x.selected == true).map(x => {
      return x.value;
    });

    var trainingType = this.trainingType.filter(x => x.selected == true).map(x => {
      return x.value;
    });

    console.log(viewType, this.viewList)
   
    var search = {
      viewType: viewType[0],
      triggerType: triggerType[0],
      trainingType: trainingType[0],
      providerId: this.authService.currentUserValue.providerId,
      companyId: this.authService.currentUserValue.companyId,
      profileId: this.authService.currentUserValue.profileId,
      departmentId: this.authService.currentUserValue.department

    };

    this.myCalendarService.getTrainingAgendaByProfile(search).then(results => {
      this.dataSource = results;
      this.loading = false;
      this.progressBarService.hide();

    })
  }
}


