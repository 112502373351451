import { Component, Input, OnInit, ViewEncapsulation, ViewChildren, QueryList } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';

import { convertToBoolProperty } from '../helpers';

import { AbsComponentSize } from '../component-size';
import { AbsComponentShape } from '../component-shape';
import { AbsComponentStatus } from '../component-status';
import { AuthenticationService } from '../../../app/main/authentication/_services';
import { first, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { User } from 'app/main/models/user';
import { UserService } from 'app/main/service/user.service';
import { environment } from 'environments/environment';
import { ShoppingCartService } from 'app/main/auto-training/cart.service';
import { ActionBarService } from 'app/layout/components/action-bar/action-bar.service';
import { MatRipple } from '@angular/material/core';

export interface UserNews {
  newsContent: string;
  newsLink: string;
  newsTime: Date;
}

export interface UserAssignments {
  trainingName: string;
  trainingType: string;
  trainingProgress: string;
  trainingLocation: string;
  trainingStartDate: Date;
  trainingEndDate: Date;
}

export interface UserResults {
  userName: string;
  userAvatar: string;
  userResultProgress: string;
  userResultByCorse: string;
  resultDate: Date;
}

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class AbsUserComponent implements OnInit {

  @ViewChildren(MatRipple) 
  ripple: QueryList<MatRipple>;

  currentUser: User;
  userFromApi: User;
  environment: any;
  results: UserResults[] = [
    {
      userName: 'Abbott Royalguard',
      userAvatar: 'assets/images/avatars/Abbott.jpg',
      resultDate: new Date('1/1/16'),
      userResultByCorse: 'Workplace Violance',
      userResultProgress: '50%'
    },
    {
      userName: 'Arnold Matlock',
      userAvatar: 'assets/images/avatars/Arnold.jpg',
      resultDate: new Date('1/1/16'),
      userResultByCorse: 'Sexual Harassment',
      userResultProgress: '75%'
    },
    {
      userName: 'Barrera Bradbury',
      userAvatar: 'assets/images/avatars/Barrera.jpg',
      resultDate: new Date('1/1/16'),
      userResultByCorse: 'Lifting Safety',
      userResultProgress: '45%'
    },
    {
      userName: 'Blair Strangeway',
      userAvatar: 'assets/images/avatars/Blair.jpg',
      resultDate: new Date('1/1/16'),
      userResultByCorse: 'Passenger Van Safety',
      userResultProgress: '76%'
    }
  ];

  assignment: UserAssignments[] = [
    {
      trainingName: 'Workplace Violence',
      trainingType: 'Classroom Training',
      trainingProgress: '5%',
      trainingLocation: 'Conference Room',
      trainingStartDate: new Date('1/1/16'),
      trainingEndDate: new Date('1/25/16')
    },
    {
      trainingName: 'Ergonomic',
      trainingType: 'Individual Training',
      trainingProgress: '50%',
      trainingLocation: 'Conference Room',
      trainingStartDate: new Date('2/2/16'),
      trainingEndDate: new Date('2/25/16')
    },
    {
      trainingName: 'Proper Lifting',
      trainingType: 'Individual Training',
      trainingProgress: '95%',
      trainingLocation: 'Conference Room',
      trainingStartDate: new Date('3/3/16'),
      trainingEndDate: new Date('3/25/16')
    }
  ];

  news: UserNews[] = [
    {
      newsContent: 'Live updates: Coronavirus defies travel curbs; markets stage cautious recovery on stimulus hopes - The Washington Post',
      newsTime: new Date('1/1/16'),
      newsLink: 'asdasdasd'
    },
    {
      newsContent: 'Trump\'s coronavirus strategy faces new scrutiny after second US death - CNN',
      newsTime: new Date('1/17/16'),
      newsLink: 'asdasdasd'
    },
    {
      newsContent: 'Gunman holding about 30 people hostage inside Philippines shopping mall - CNN',
      newsTime: new Date('1/28/16'),
      newsLink: 'asdasdasd'
    },
    {
      newsContent: 'Advisor to Iran\'s Supreme leader dies from coronavirus, as other top officials infected - Fox News',
      newsTime: new Date('1/28/16'),
      newsLink: 'asdasdasd'
    },
    {
      newsContent: 'Mike Bloomberg: why a troubling record on women could derail his 2020 bid - The Guardian',
      newsTime: new Date('1/28/16'),
      newsLink: 'asdasdasd'
    },
    {
      newsContent: 'North Korea fires two unidentified projectiles, South Korea says - CNN',
      newsTime: new Date('1/28/16'),
      newsLink: 'asdasdasd'
    },
    {
      newsContent: 'Live updates: Coronavirus defies travel curbs; markets stage cautious recovery on stimulus hopes - The Washington Post',
      newsTime: new Date('1/1/16'),
      newsLink: 'asdasdasd'
    },
    {
      newsContent: 'Trump\'s coronavirus strategy faces new scrutiny after second US death - CNN',
      newsTime: new Date('1/17/16'),
      newsLink: 'asdasdasd'
    },
    {
      newsContent: 'Gunman holding about 30 people hostage inside Philippines shopping mall - CNN',
      newsTime: new Date('1/28/16'),
      newsLink: 'asdasdasd'
    },
    {
      newsContent: 'Advisor to Iran\'s Supreme leader dies from coronavirus, as other top officials infected - Fox News',
      newsTime: new Date('1/28/16'),
      newsLink: 'asdasdasd'
    },
    {
      newsContent: 'Mike Bloomberg: why a troubling record on women could derail his 2020 bid - The Guardian',
      newsTime: new Date('1/28/16'),
      newsLink: 'asdasdasd'
    },
    {
      newsContent: 'North Korea fires two unidentified projectiles, South Korea says - CNN',
      newsTime: new Date('1/28/16'),
      newsLink: 'asdasdasd'
    }
  ];

  imageBackgroundStyle: SafeStyle;

  /**
   * Specifies a name to be shown on the right of a user picture
   * @type string
   */
  @Input() name: string = 'Anonymous';

  /**
   * Specifies a title to be shown under the **name**
   * @type string
   */
  @Input() title: string;

  /**
   * Absolute path to a user picture or base64 image.
   * User name initials will be shown if no picture specified (JD for John Doe).
   * @type string
   */
  @Input()
  set picture(value: string) {
    this.imageBackgroundStyle = value ? this.domSanitizer.bypassSecurityTrustStyle(`url(${value})`) : null;
  }

  /**
   * Color of the area shown when no picture specified
   * @type string
   */
  @Input() color: string;

  /**
   * Size of the component.
   * Possible values: `tiny`, `small`, `medium` (default), `large`, 'giant'.
   */
  @Input() size: AbsComponentSize = 'medium';

  /**
   * Shape of the picture box.
   * Possible values: `rectangle`, `semi-round`, `round`.
   */
  @Input() shape: AbsComponentShape = 'round';

  /**
   * Whether to show a user name or not
   */
  @Input()
  get showName(): boolean {
    return this._showName;
  }

  set showName(val: boolean) {
    this._showName = convertToBoolProperty(val);
  }

  private _showName: boolean = true;

  /**
   * Whether to show a user title or not
   * @type boolean
   */
  @Input()
  get showTitle(): boolean {
    return this._showTitle;
  }

  set showTitle(val: boolean) {
    this._showTitle = convertToBoolProperty(val);
  }

  private _showTitle: boolean = true;

  /**
   * Whether to show a user initials (if no picture specified) or not
   * @type boolean
   */
  @Input()
  get showInitials(): boolean {
    return this._showInitials;
  }

  set showInitials(val: boolean) {
    this._showInitials = convertToBoolProperty(val);
  }

  private _showInitials: boolean = true;

  /**
   * Whether to show only a picture or also show the name and title
   * @type boolean
   */
  @Input()
  get onlyPicture(): boolean {
    return !this.showName && !this.showTitle;
  }

  set onlyPicture(val: boolean) {
    this.showName = this.showTitle = !convertToBoolProperty(val);
  }

  /**
   * Badge text to display
   * @type string
   */
  @Input() badgeText: string;

  /**
   * Badge status (adds specific styles):
   * `primary`, `info`, `success`, `warning`, `danger`
   * @param {string} val
   */
  @Input() badgeStatus: AbsComponentStatus;

  /**
   * Badge position.
   * Can be set to any class or to one of predefined positions:
   * 'top left', 'top right', 'bottom left', 'bottom right',
   * 'top start', 'top end', 'bottom start', 'bottom end'
   * @type string
   */


cartCount =0;

  constructor(private router: Router,
    private userService: UserService,
    private authenticationService: AuthenticationService,
    private domSanitizer: DomSanitizer,
    private cartService: ShoppingCartService,private actionBarService: ActionBarService) {
    this.environment = environment;
  }

  getInitials(): string {
    if (this.name) {
      const names = this.name.split(' ');
      return names.map(n => n.charAt(0)).splice(0, 2).join('').toUpperCase();
    }

    return '';
  }

  triggerRipple() {
    this.ripple.forEach(_ => _.launch({centered: true}));
  }

  ngOnInit() {

    this.actionBarService.onCartChanged.subscribe(value=>{
       // this.cartCount = value;
        if(this.cartCount > 0){
         // this.triggerRipple();
        }
    })
    this.authenticationService.currentUser.subscribe(value => {
      this.currentUser = value;
    });
  }

  onLogout() {
    this.authenticationService.logout();
  }

  onImgError(event) {
    event.target.src = 'https://cdn.itrainstation.com/assets/images/avatars/Tyson.jpg';
  }

}
