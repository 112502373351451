import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-location-map',
  templateUrl: './location-map.component.html',
  styleUrls: ['./location-map.component.scss']
})
export class LocationMapComponent implements OnInit {

  lat = 38.882000;
  lng = -76.997200;
 // google maps zoom level
 zoom: number = 15;
  constructor(public matDialogRef: MatDialogRef<LocationMapComponent>, @Inject(MAT_DIALOG_DATA) private _data: any,) { 

    this.lat = _data.lat;
    this.lng = _data.lng;

  }

  ngOnInit() {
  }

}
