import { Component, Input, OnInit, ViewEncapsulation, Inject, ViewChild } from '@angular/core';
import { absAnimations } from '@abs/animations';
import { SwiperConfigInterface, SwiperScrollbarInterface } from 'ngx-swiper-wrapper';
import { Content } from '../../content-list.model';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ContentService } from '../../content.service';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PlayList } from '../../../training/_models/playlist';
import { MatMenuTrigger } from '@angular/material/menu';
import { AbsProgressBarService } from '@abs/components/progress-bar/progress-bar.service';
import { VideoPlayerComponent } from '../video-player/video-player.component';
import { PreviewLog } from '../../preview-log.model';
import { SelectionModel } from '@angular/cdk/collections';
import { QuizPreviewComponent } from '../../quiz-preview/quiz-preview.component';
import { environment } from 'environments/environment';
import { TrainerQuizPreviewComponent } from '../../trainer-quiz-preview/trainer-quiz-preview.component';
import { TrainingService } from '../../../training/_services/training.service';
import { AuthenticationService } from '../../../authentication/_services';
import { ActivityLog, ActivityType, ActivityLogService } from '../../../service/activity-log.service';
import { DocumentViewerDialogComponent } from '../../../document-viewer-dialog/document-viewer-dialog.component';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-video-page',
  templateUrl: './video-page.component.html',
  styleUrls: ['./video-page.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: absAnimations
})
export class VideoPageComponent implements OnInit {
  selectedTabIndex = 0;
  form: FormGroup;
  preview: boolean;
  video: Content;
  streamurl: string;
  similarVideos: Content[] = [];
  ratio: any;
  playlists: PlayList[] = [];
  selected: number[];
  selection = new SelectionModel<any>(true, []);
  showPlaylistInput = false;
  timeViewed: number;
  enviorment: any;
  lang: any = 'en';
  quizURL = "";
  quizURLSpanish = "";

  @ViewChild('nameInput', { static: false }) nameInputField;
  @ViewChild('menuTrigger') matMenuTrigger: MatMenuTrigger;


  public config: SwiperConfigInterface = {
    init: true,
    a11y: true,
    direction: 'horizontal',
    slidesPerView: 5,
    slidesPerColumn: 1,
    keyboard: true,
    mousewheel: false,
    scrollbar: false,
    navigation: true,
    pagination: false,
    spaceBetween: 12,
    width: 1130,
    centerInsufficientSlides: true
  };


  constructor(public contentService: ContentService,
    private _snackBar: MatSnackBar,
    private activityLog: ActivityLogService,
    private _progressBarService: AbsProgressBarService,
    public dialogQuiz: MatDialog,
    private _docMatDialog: MatDialog,
    private _trainingService: TrainingService,
    private _authService: AuthenticationService,
    private route: ActivatedRoute,
    private router: Router,

  ) {

    this.enviorment = environment;
    this.form = new FormGroup({
      playlistCtrl: new FormControl(this.selected),
    });

    this.route.queryParams.subscribe(params => {

      const v = params['v'];
     
      if (v && v.length > 0) {
       this.contentService.getProductById(v).subscribe(video=>{
         this.video = video;
         this.preview = true;
         if (this.video.aspectRatio == 0.75) {
          this.ratio = "4:3";
        } else {
          this.ratio = "16:9";
        }
        sessionStorage.setItem("contentSelection", JSON.stringify(this.video));
       })
      }
    });

    //this.video = _data.video;
    //this.similarVideos = _data.similarVideos;
    //this.preview = _data.preview;  
   
  }

  ngOnInit() {

    this.quizURL = `${environment.apiUrl}/quiz/pdf?contentId=${this.video.id}&spanish=false&pid=${this._authService.currentUserValue.profileId}`;
    this.quizURLSpanish = `${environment.apiUrl}/quiz/pdf?contentId=${this.video.id}&spanish=true&pid=${this._authService.currentUserValue.profileId}`;

    this.getCurrentUserPlaylists();

    sessionStorage.setItem("contentSelection", JSON.stringify(this.video));

    this.contentService.getContentPlaylistItems(this.video.id).subscribe(res => {
      res.map(o => {
        this.selection.select(o.playlistId);
        //this.selected.push(o.playlistId);
      });
      // this.selected =res.map(o=> { 
      //  return o.playlistId;
      //});
      // console.log("selected",this.selection.selected);
      // this.form.controls['playlistCtrl'].setValue(this.selected, { onlySelf: true });
    });
  }

  timeViewedChaged(event) {
    this.timeViewed = event;
  }

  OnSelectChanged(playlist) {
    this.selection.toggle(playlist.id);

    this._progressBarService.show();
    this.contentService.deleteAndInsertPlaylistContent(this.selection.selected, this.video.id).then(res => {
      this._progressBarService.hide();
      if (res == 1) {
        this.contentService.getPlaylists(1, 1000).subscribe(res => {
          if (res) {
            this.playlists = res.filter(playlist => !playlist.system);
          }
        });

        this._snackBar.open("Playlist has been updated", '', {
          panelClass: 'green-bg',
          duration: 2000,
          horizontalPosition: 'right',
          verticalPosition: 'top',
          politeness: 'polite',
        });
      }
    })
  }

  playnow(lang) {


    let viewModel = { companyId: this._authService.currentUserValue.companyId, providerId: this._authService.currentUserValue.providerId, profileId: this._authService.currentUserValue.profileId, type: 'classroom', requestedViews: 1 };

    this._trainingService.checkIfUserCanViewVideo(viewModel).then(res => {

      if (res) {

        setTimeout(() => {
          this.preview = false;
          this.lang = lang;
        }, 1500);

        /* this.playerService.onCuePoint.subscribe(cuepoint => {
          console.log("cue",cuepoint);
          if (cuepoint.type) {
            var viewLog = {
              videoLogId: this.video.videoLogId,
              totalViewed: cuepoint.time,
              companyId: this._authService.currentUserValue.companyId,
              type: this.viewType
            };

            console.log("deduct", viewLog);
            this._trainingService.deductView(viewLog).then(res => {
              console.log(res);
            })
            //dedecut view
            console.log("deduct view")
          } else {
            var videoLog = new VideoLog({});
            videoLog.id = this.video.videoLogId;
            videoLog.totalViewed = cuepoint.start;
            console.log("videoLog", videoLog);
            this._trainingService.updateVideoLog(videoLog).then(res => {
              console.log(res);
            })
          }
        })
        var videoLog = new VideoLog({
          profileId: JSON.parse(localStorage.getItem("currentUser")).profileId,
          productId: this.video.id,
          itemName: this.video.title,
          classId: this.classId,
          comments: this.lang
        });
        this._trainingService.insertVideoLog(videoLog).then(res => {
          this.video.videoLogId = res;
        }); */

      } else {
        this._snackBar.open(" You dont have enough Classroom views to watch this video. Please contact your manager.", '', {
          panelClass: 'red-bg',
          duration: 2000,
          horizontalPosition: 'right',
          verticalPosition: 'top',
          politeness: 'polite',
        });
      }
    })

  }

  viewDocument(playlist) {
    var url = "";
    if (playlist.private) {
      var fileExt = playlist.fileName.split('.').pop();
      var directory = playlist.itemCode.split('.')[0];
      url = `${environment.cdnUrl}/assets/contents/${directory+"/V_"+playlist.itemCode+"."+fileExt}`;
    } else {
      url = `${environment.cdnUrl}/content/${playlist.prodType.toLowerCase().replace('_', '+')}s/${playlist.fileName}`
    }
    this._docMatDialog.open(DocumentViewerDialogComponent, {
      backdropClass: 'previewBackdrop',
      width: '1165px',
      height: '800px',
      data: {
        url: url,
        title: playlist.title
      }
    });
  }

  savePlaylist(event): void {
    this._progressBarService.show();
    if (event.target.value) {
      // this.matMenuTrigger.closeMenu();
      this.contentService.insertPlaylist(event.target.value, JSON.parse(localStorage.getItem("currentUser")).profileId).then(res => {
        this._progressBarService.hide();
        this._snackBar.open("Playlist has been created.", '', {
          panelClass: 'green-bg',
          duration: 2000,
          horizontalPosition: 'right',
          verticalPosition: 'top',
          politeness: 'polite',
        });
        this.getCurrentUserPlaylists();
        // var playlist = new PlayList({ id: res, name: event.target.value, profileID: JSON.parse(localStorage.getItem("currentUser")).profileId });
        // this.playlists.push(playlist);
        this.nameInputField.nativeElement.value = "";
        this.showPlaylistInput = false;
      })
    }
  }

  onQuizPreview(lang) {

    this.dialogQuiz.open(TrainerQuizPreviewComponent, {
      id: 'quiz-preview',
      backdropClass: 'previewBackdrop',
      width: '1165px',
      height: '700px',
      data: {
        content: this.video,
        lang: lang
      }
    });

  }

  onRecommendedVideoClicked(video) {
    this.video = video;
  }

  logActivity() {
    let activityLog = new ActivityLog();
    activityLog.profileID = JSON.parse(localStorage.getItem("currentUser")).profileId
    activityLog.type = "site";
    activityLog.source = "itrain";
    activityLog.activity = ActivityType.PRINT_QUIZ;
    activityLog.videoID = this.video.id;
    this.activityLog.saveActivityLog(activityLog).subscribe(res => { });
  }

  getCurrentUserPlaylists() {
    this.contentService.getPlaylists(1, 1000).subscribe(res => {
      if (res) {
        console.log("playlists", res);
        this.playlists = res.filter(playlist => !playlist.system);
      }
    });
  }
}
