
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { PlayList } from '../_models/playlist';
import { TrainingService } from '../_services/training.service';
import { DataSource } from '@angular/cdk/table';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { MyCalendarService } from '../_services/my-calendar.service';
import { AuthenticationService } from '../../authentication/_services';
import { SelectionModel } from '@angular/cdk/collections';
import { ActionBarService } from '../../../layout/components/action-bar/action-bar.service';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-my-calendar',
  templateUrl: './my-calendar.component.html',
  styleUrls: ['./my-calendar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*', marginTop: '12px', marginBottom: '12px' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class MyCalendarComponent implements OnInit {

  selection = new SelectionModel<any>(false, []);

  dataSource: any;
  dialogRef: any;
 // columnsToDisplay = ['buttons', 'date', 'playlist', 'courseCount', 'passed', 'failed', 'inProgress', 'noShow', 'averageQAttempts', 'averageScore', 'percentComplete'];
  columnsToDisplay = ['buttons', 'playlist', 'courseCount', 'passed', 'failed', 'inProgress', 'noShow', 'averageQAttempts', 'averageScore', 'percentComplete'];

  expandedElement: PlayList | null;
  // Private
  private _unsubscribeAll: Subject<any>;
  constructor(private myCalendarService: MyCalendarService,
    public dialog: MatDialog,
    public router: Router,
    public _actionBarService: ActionBarService,
    public authService: AuthenticationService) {
  }

  ngOnInit() {

    this.myCalendarService.getTrainings().then(results=>{
      this.dataSource = new MatTableDataSource(results);

    })

    console.log("data", this.dataSource);
    this._actionBarService.onStartClassClick.subscribe(res => {
      this.router.navigate(['/training/classroom'], { queryParams: { cid: this.selection.selected[0].id, name: this.selection.selected[0].name } });
    });

  }
}


