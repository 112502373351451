import {Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {PlaylistManager} from '../playlist-manager.model';
import {FormBuilder, FormGroup} from '@angular/forms';
import { Playlist } from '../../../playlists/playlists.model';

@Component({
  selector: 'app-playlist-manager-form',
  templateUrl: './playlist-manager-form.component.html',
  styleUrls: ['./playlist-manager-form.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PlaylistManagerFormComponent {

  action: string;
  playlist: Playlist;
  playlistForm: FormGroup;
  dialogTitle: string;

  /**
   * Constructor
   *
   * @param {MatDialogRef<PlaylistManagerFormComponent>} matDialogRef
   * @param _data
   * @param {FormBuilder} _formBuilder
   */
  constructor(public matDialogRef: MatDialogRef<PlaylistManagerFormComponent>,
              @Inject(MAT_DIALOG_DATA) private _data: any,
              private _formBuilder: FormBuilder) {
                console.log(_data);
    // Set the defaults
    this.action = _data.action;

    if ( this.action === 'edit' )
    {
      this.dialogTitle = 'Edit Playlist';
      this.playlist = _data.playlist;
    }
    else
    {
      this.dialogTitle = 'New Playlist';
      this.playlist = new Playlist({});
    }

    this.playlistForm = this.createPlaylistForm();

  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Create group form
   *
   * @returns {FormGroup}
   */
  createPlaylistForm(): FormGroup
  {
    return this._formBuilder.group({
      id      : [this.playlist.id],
      name    : [this.playlist.name],
    });
  }
}
