import { Component, OnInit, AfterViewInit, ViewChild, ViewEncapsulation, Input } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { Group } from '../../../../user/groups/groups-all/group.model';
import { User } from '../../../../models/user';
import { FormControl } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { Subject } from 'rxjs';
import { AbsProgressBarService } from '@abs/components/progress-bar/progress-bar.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GroupsService } from '../../../../user/groups/groups.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-assign-selected-users',
  templateUrl: './assign-selected-users.component.html',
  styleUrls: ['./assign-selected-users.component.scss'],
  encapsulation: ViewEncapsulation.None,

})
export class AssignSelectedUsersComponent implements OnInit, AfterViewInit {
  
  @Input()
  trigger: any;
  
  @ViewChild(MatSort) sort: MatSort;
  isLoading = true;
  group: Group;
  height: number
  userFound = false;
  users: User[] = [];
  environment: any;
  selected = 'All Users';
  loginStatus = 'Last Login';
  searchInputUsers: FormControl;
  //  displayedColumns: string[] = ['select', 'avatar', 'firstName', 'lastName', 'email', 'lastLogin'];
  displayedColumns: string[] = ['avatar', 'firstName', 'lastName', 'email', 'buttons'];

  dataSourceUsers: MatTableDataSource<User>;
  selection = new SelectionModel<User>(true, []);
  private _unsubscribeAll: Subject<any>;

  constructor(private _progressBarService: AbsProgressBarService,
    public _snackBar: MatSnackBar, public _groupService: GroupsService) {
    this.searchInputUsers = new FormControl('');
    this.environment = environment;
    //this._unsubscribeAll = new Subject();
  }

  ngOnInit() {

    this._groupService.onSelectedUsersChanged
      .subscribe(users => {
        if (users) {
          this.users = [];
          console.log("users in assign", users)
          users.map(row => {
            let ind = this.checkIfUserAlreadySelected(row);
            if (!ind) {
              this.users.push(row);
            }
          });
          this.updateDataSource(this.users);
        }
      });

    this._groupService.onUserDataChanged
      .subscribe(users => {
       // this.updateDataSource(users);
      });

    this.searchInputUsers.valueChanges
      .subscribe(searchText => {
        console.log("search", searchText);
        this._groupService.onUserSearchTextChanged.next(searchText);
      });
  }

  ngAfterViewInit() {
    // this.dataSourceUsers.sort = this.sort;
    console.log(this.sort);
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    // this._unsubscribeAll.next();
    //this._unsubscribeAll.complete();
  }
  updateDataSource(users) {
    console.log("users",users);
    this.dataSourceUsers = new MatTableDataSource(users);
    this.dataSourceUsers.sort = this.sort;
    this.isLoading = false;
  }

  /* addUserToGroup(user) {
    var users: User[] = [];
    users.push(user);
    this._progressBarService.show();
    this._groupService.updateGroup(users, this.group).then(req => {
      this._progressBarService.hide();
      if (req == 1) {
        this.group.userList.push(user);
        this._snackBar.open(user.firstName + " " + user.lastName + " have been assigned to" + this.group.name, 'Ok', {
          panelClass: 'green-bg',
          duration: 3000,
          horizontalPosition: 'right',
          verticalPosition: 'top',
          politeness: 'polite',
        });
        user.showGroupActionBtn = true;
      }
    });
  } */

  removeUserFromGroup(user) {
    this._groupService.groupSelection.selected.map(x => 
      {
      if(x.groupID == user.selectedGroup){
        x.selectedUserCount--;
      };
    });
    
    this._groupService.userSelection.toggle(user);
    this._groupService.onSelectedUsersChanged.next(this._groupService.userSelection.selected);
  }

  checkIfUserAlreadySelected(newUser) {
    return this.users.find(user => user.profileId === newUser.profileId);
  }
}