import {AbsUtils} from '@abs/utils';

export class EmailContent {
  emailId: string;
  emailTo: string;
  emailFrom: string;
  subject: string;
  body: string;
  companyId: number;
  isActive:string;
  /**
   * Constructor
   *
   * @param email
   */
  constructor(email) {
    {
      this.emailId = email.emailId 
      this.emailTo = email.emailTo || '';
      this.emailFrom = email.emailFrom || '';
      this.subject = email.subject || '';
      this.body = email.body || '';
      this.companyId = email.companyId || 0;
      this.isActive = email.isActive || 'Y';
    }
  }
}
