import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { absAnimations } from '@abs/animations';
import { AbsUtils } from '@abs/utils';
import { FileManagerService } from '../../../../my-uploads.service';


@Component({
    selector     : 'file-label-selector',
    templateUrl  : './label-selector.component.html',
    styleUrls    : ['./label-selector.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : absAnimations
})

export class FileLabelSelectorComponent implements OnInit, OnDestroy
{
    @Input('file')
    file: any;

    @Output()
    fileLabelsChanged: EventEmitter<any>;

    folder: any;
    labelsMenuView: string;
    selectedLabel: any;
    newLabel: any;
    toggleInArray: any;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {FileManagerService} fileManagerService
     */
    constructor(
        private fileManagerService: FileManagerService
    )
    {
        // Set the defaults
        this.fileLabelsChanged = new EventEmitter();
        this.labelsMenuView = 'labels';
        this.newLabel = {
            'id'   : '',
            'name' : '',
            'color': 'blue-400'
        };
        this.toggleInArray = AbsUtils.toggleInArray;

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {

        let label1 = new Label();
        label1.id ="26022e4129ad3a5sc28b36cd";
        label1.name ="CDL";
        label1.color = "red-500";

        let label2 = new Label();
        label2.id ="56027e4119ad3a5dc28b36cd";
        label2.name ="Retail";
        label2.color = "orange-400";

        let label3 = new Label();
        label3.id ="5640635e19ad3a5dc21416b2";
        label3.name ="Warehouse";
        label3.color = "blue-600";

        let label4 = new Label();
        label4.id ="5640635e19ad3a5dc21416b7";
        label4.name ="Everyday Driver";
        label4.color = "blue-600";

        this.fileManagerService.onFilesChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(folder => {
                this.folder = folder;
                this.folder = new Folder();
                this.folder.labels = [label1,label2, label3];
            });

            this.folder = new Folder();
            this.folder.labels = [label1,label2, label3,label4];

            console.log(this.folder);
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Card labels changed
     */
    onCardLabelsChanged(): void
    {
        this.fileLabelsChanged.next();
    }

    /**
     * On label change
     */
    onLabelChange(): void
    {
       // this.fileManagerService.updateFolder();
    }

    /**
     * Add new label
     */
    addNewLabel(): void
    {
        this.newLabel.id = AbsUtils.generateGUID();
        console.log(this.newLabel);
        this.folder.labels.push(Object.assign({}, this.newLabel));
        this.newLabel.name = '';
        this.labelsMenuView = 'labels';
    }
}

export class File {
    id: number;
    title: string;
    type: string;
    idAttachmentCover: string;
    subscribed: boolean;
    idLabels: string[];
    idMembers: string[];
  }

 export class Folder{

    id:number;
    title: string;
    itemsCount: number;
    labels: Label[];
    members: Member[];

 } 

 export class Label{
    id    : string;
    name  : string;
    color: string;
}

export class Member{
    id    : string;
    name  : string;
    avatar: string;
}