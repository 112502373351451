import { Component, OnInit, Input, ViewEncapsulation, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { CartItem } from '../../auto-training/cart.service';

@Component({
  selector: 'app-pricing-card',
  templateUrl: './pricing-card.component.html',
  styleUrls: ['./pricing-card.component.scss'],
  encapsulation: ViewEncapsulation.None,

})
export class PricingCardComponent implements OnInit {

  @Input()
  item: CartItem

  @Input()
  refresh: boolean;
  
  badgeHidden: boolean = true;

  @Input()
  active: boolean = false;

  @Output()
  itemRemoved: EventEmitter<CartItem>;

  @Output()
  itemAdded: EventEmitter<CartItem>;
  
  constructor() { 
    this.itemRemoved = new EventEmitter<CartItem>();
    this.itemAdded = new EventEmitter<CartItem>(); 
  }

  checkQuantity(){
    console.log("check quantity",this.item);
    if(this.item.quantity && this.item.quantity > 0){
      this.badgeHidden = false;
      this.active = true;
    }else{
      this.badgeHidden = true;
    }
  }

  ngOnInit() {}

  removeQuantity(){
    this.itemRemoved.emit(this.item)
    this.checkQuantity();
  }

  addQuantity(){
    this.itemAdded.emit(this.item)

    //this.item.quantity++;
    this.checkQuantity();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.checkQuantity();
  }
}
