import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable()
export class FileManagerService implements Resolve<any>
{

    files: any[];
    onFilesChanged: BehaviorSubject<any>;
    onFileSelected: BehaviorSubject<any>;

    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient
    )
    {
        // Set the defaults
        this.onFilesChanged = new BehaviorSubject({});
        this.onFileSelected = new BehaviorSubject({});
    }

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        return new Promise((resolve, reject) => {

            Promise.all([
                
                this.getFiles()
            ]).then(
                ([files]) => {
                    resolve();
                },
                reject
            );
        });
    }

    /**
     * Get files
     *
     * @returns {Promise<any>}
     */
    getFiles(): Promise<any>
    {
        return new Promise((resolve, reject) => {
            this._httpClient.get('api/file-manager')
                .subscribe((response: any) => {
                    this.files = response;
                    this.onFilesChanged.next(this.files);
                    this.onFileSelected.next(this.files[0]);
                    resolve(this.files);
                }, reject);
        });
    }

    /**
     * Get files
     *
     * @returns {Promise<any>}
     */
    getThumbnails()
    {
        return this._httpClient.get('api/tilesabc');
    }

    getCategories()
    {
       // return this._httpClient.get('api/file-manager-categories');
     const  categories = [
        {
            'id'   : '26022e4129ad3a5sc28b36ccc',
            'name' : 'CDL',
            'color': 'red-500'
        },
        {
            'id'   : '26022e4129ad3a5sc28b36cbb',
            'name' : 'OSHA Compliance',
            'color': 'red-500'
        },
        {
            'id'   : '26022e4129ad3a5sc28b36cee',
            'name' : 'Everyday Driver',
            'color': 'red-500'
        },{
            'id'   : '26022e4129ad3a5sc28b36cdff',
            'name' : 'Professional Driver',
            'color': 'red-500'
        },{
            'id'   : '26022e4129ad3a5sc28b36cdgg',
            'name' : 'Video',
            'color': 'red-500'
        },{
            'id'   : '26022e4129ad3a5sc28b36cdhh',
            'name' : 'EAP',
            'color': 'red-500'
        },{
            'id'   : '26022e4129ad3a5sc28b36cdii',
            'name' : 'Contractor',
            'color': 'red-500'
        },{
            'id'   : '26022e4129ad3a5sc28b36cdjj',
            'name' : 'Behavioral',
            'color': 'red-500'
        },{
            'id'   : '26022e4129ad3a5sc28b36cdkk',
            'name' : 'Groudkeeping Safety',
            'color': 'red-500'
        }];
        return categories;
    }

    getTags()
    {
        //return this._httpClient.get('api/collection-tags');  

       const tags = [

            {
                'id'   : '26022e4129ad3a5sc28b36ca',
                'name' : '#semitrailer',
                'color': 'red-500'
            },
            {
                'id'   : '26022e4129ad3a5sc28b36cb',
                'name' : '#trailer',
                'color': 'red-500'
            },
            {
                'id'   : '26022e4129ad3a5sc28b36cc',
                'name' : '#backingandparking',
                'color': 'red-500'
            },{
                'id'   : '26022e4129ad3a5sc28b36cd',
                'name' : '#fleet',
                'color': 'red-500'
            },{
                'id'   : '26022e4129ad3a5sc28b36ce',
                'name' : '#maintainance',
                'color': 'red-500'
            },{
                'id'   : '26022e4129ad3a5sc28b36cf',
                'name' : '#truck',
                'color': 'red-500'
            },{
                'id'   : '26022e4129ad3a5sc28b36cg',
                'name' : '#towing',
                'color': 'red-500'
            },{
                'id'   : '26022e4129ad3a5sc28b36ch',
                'name' : '#heavyequipment',
                'color': 'red-500'
            },{
                'id'   : '26022e4129ad3a5sc28b36ci',
                'name' : '#cdl',
                'color': 'red-500'
            }
        ]
        return tags;
    }

    updateFolder(newFile): void
    {
        this.files.map((_file) => {
            if ( _file.id === newFile.id )
            {
                return newFile;
            }
        });
    }

    saveFile(file): Promise<any>
    {
        return new Promise((resolve, reject) => {
            this._httpClient.post('api/file-manager/' + file.id, file)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    /**
     * Add File
     *
     * @param file
     * @returns {Promise<any>}
     */
    addFile(file): Promise<any>
    {
        return new Promise((resolve, reject) => {
            this._httpClient.post('api/file-manager/', file)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }
}
