import { Component, OnInit, Output, EventEmitter, ViewEncapsulation, Input } from '@angular/core';
import { AuthenticationService } from '../../../authentication/_services';
import { ContentService } from '../../../content/content.service';
import { absAnimations } from '@abs/animations';
import { SelectionModel } from '@angular/cdk/collections';

@Component({
  selector: 'app-playlist-selector',
  templateUrl: './playlist-selector.component.html',
  styleUrls: ['./playlist-selector.component.scss'],
  animations: absAnimations,
  encapsulation: ViewEncapsulation.None,
})
export class PlaylistSelectorComponent implements OnInit {

  flip:any;
  selectedPlaylists: any;
  groupSelection: any = '';
  currentUser: any;

  @Input()
  groupType: any = 'none';

  showMainGroupSelector = true;
  favorites: any;
  myPlaylists: any;
  system: any;
  others: any;
  showCourseSelector = false;

  @Input()
  showContentList = false;

  @Input()
  default

  selectedPlaylistList = new SelectionModel<any>(true, []);
  selectedContent = new SelectionModel<any>(true, []);
  @Output() onSelection = new EventEmitter<any>();
  @Output() onCourseSelectionDone = new EventEmitter<any>();
  @Output() onBackButton = new EventEmitter<any>();

  constructor(private _authService: AuthenticationService, private _contentService: ContentService) {
    this.currentUser = this._authService.currentUserValue;
  }

  ngOnInit(): void {
    this.loadPlaylistData();
    if(this.showContentList){
      this.showMainGroupSelector = false;
      this.showCourseSelector = true;
      this.selectedPlaylists = this.default;
    }
  }

  initPlaylists(playlists) {
    this.favorites = playlists.favoritePlaylists;
    this.myPlaylists = playlists.myPlaylists;
    this.system = playlists.systemPlaylists;
    this.others = playlists.othersPlaylists;
  }

  loadPlaylistData() {
    this._contentService.getPlaylistGroupedWithContents().then(content => {
      this.initPlaylists(content);
    })
  }
  seletctGroup(value) {
    this.groupSelection = value;
    this.onSelection.emit({value:value,type:'playlist-group-selected'});
    this.handleGroupType(value);
  }

  back() {
    this.onBackButton.emit("pressed");
  }

  handleGroupType(type) {
    this.groupType = type;
    if (type != 'DL' && type != 'other') {
      this.showMainGroupSelector = false;
    } else {
      this.showMainGroupSelector = true;

    }
    console.log("main",this.showMainGroupSelector)
    console.log("main",this.showCourseSelector)
    console.log("mani",this.groupType);
    
  }

  backButtonClicked(value) {
    console.log("back",value);
    if (value == 'my') {
      this.groupSelection = '';
    } else if (value == 'myfav') {
      this.groupSelection = '';
    } else if (value == 'other') {
      this.groupSelection = 'other';
    }
    else if (value == 'system') {
      this.groupSelection = 'other';
    }
    this.showMainGroupSelector = true;
  }
  onPlaylistSelection(event) {
    console.log("on playlsit selection", event);
    this.selectedPlaylistList.clear();
    this.selectedContent.clear();
    this._contentService.togglePlaylistSelection(this.selectedPlaylistList);
    this._contentService.toggleContentSelection(this.selectedContent);

    event.forEach(element => {
      this.selectPlaylist(element);
    });
  }




  onPlaylistFinalized(event) {
    console.log("playlist selected", event)
    this.selectedPlaylists = event;
    this.showCourseSelector = true;
  }

  onCourseSelection(event) {
    this.onCourseSelectionDone.emit(event);
  }
  courseBackButtonClicked() {
    this.showCourseSelector = false;
    this.showMainGroupSelector = false;
  }

  selectPlaylist(playlist) {
    console.log("goging to select playlsit",playlist);
    this.selectedPlaylistList.select(playlist);
    if (playlist.contentList) {
      playlist.contentList.map(content => {
        content.playlistId = playlist.id;
        this.selectedContent.select(content);
      })
    }
    playlist.allContentSelected = true;
    playlist.selectedContentCount = playlist.contentList.length;
    console.log("selected playlsit",this.selectedPlaylistList);

    this._contentService.togglePlaylistSelection(this.selectedPlaylistList);
    this._contentService.toggleContentSelection(this.selectedContent);

  }
  descClicked(value) {
    this.flip = value;
  }
}
