import { Component, OnInit, Input, Output, EventEmitter, ViewChild, SimpleChanges } from '@angular/core';
import { GroupedGroup, Group } from '../../../user/groups/groups-all/group.model';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { GroupsService } from '../../../user/groups/groups.service';
import { MatAccordion } from '@angular/material/expansion';

@Component({
  selector: 'app-auto-train-triggers',
  templateUrl: './auto-train-triggers.component.html',
  styleUrls: ['./auto-train-triggers.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ])
  ],
})
export class AutoTrainTriggersComponent implements OnInit {

  @ViewChild('newHireAccordion') newHireAccordion: MatAccordion;
  @ViewChild('locationAccordion') locationAccordion: MatAccordion;
  @ViewChild('departmentAccordion') departmentAccordion: MatAccordion;
  @ViewChild('groupAccordion') groupAccordion: MatAccordion;

  @Output() triggerChanged = new EventEmitter<String>();

  @Input() selected: any;

  favoritesGroupDataSource: MatTableDataSource<Group>;
  myGroupDataSource: MatTableDataSource<Group>;
  othersGroupDataSource: MatTableDataSource<Group>;
  systemGroupDataSource: MatTableDataSource<Group>;
  departmentGroupDataSource: MatTableDataSource<Group>;
  locationGroups: MatTableDataSource<Group>;
  allGroups: MatTableDataSource<Group>;

  displayedColumns = ['expandButton', 'name', 'buttons'];
  groupSelection = new SelectionModel<any>(false, []);
  userSelection = new SelectionModel<any>(true, []);
  isLoading = false;

  groupsExpanded: number;
  constructor(private _groupsService: GroupsService) { }

  ngOnInit() {


    this._groupsService.getGroupedGroups().then(groups => {
      // this.dataSource.sort = this.sort;
      this.initDataSource(groups);
    });


  }

  initDataSource(groups) {
    this.allGroups = new MatTableDataSource(groups.allGroups);
    this.locationGroups = new MatTableDataSource(groups.locationGroups);
    this.departmentGroupDataSource = new MatTableDataSource(groups.departmentGroups);
  }

  selectGroup(group) {
    this.groupSelection.toggle(group);
    if (this.groupSelection.isSelected(group)) {
      if (group.userList) {
        group.userList.map(user => {
          user.selectedGroup = group.groupID;
          this.userSelection.select(user);
        })
        group.selectedUserCount = group.userList.length;

      }
      group.allUserSelected = true;
    } else {
      if (group.userList) {
        group.userList.map(user => {
          user.selectedGroup = -1;
          this.userSelection.deselect(user);
        })
      }
      group.allUserSelected = false;
      group.selectedUserCount = 0;
    }
    this._groupsService.toggleGroupSelection(this.groupSelection);
    //this._groupsService.toggleUserSelection(this.userSelection);

    console.log("select Group", group);
  }

  trrigerClicked(value) {

    if (value == 'NEW_HIRE') {
      this.locationAccordion.closeAll();
      this.departmentAccordion.closeAll();
      this.groupAccordion.closeAll();
    }

    if (value == 'LOCATION') {
      this.newHireAccordion.closeAll();
      this.departmentAccordion.closeAll();
      this.groupAccordion.closeAll();
    }

    if (value == 'DEPARTMENT') {
      this.newHireAccordion.closeAll();
      this.locationAccordion.closeAll();
      this.groupAccordion.closeAll();
    }

    if (value == 'GROUP') {
      this.newHireAccordion.closeAll();
      this.locationAccordion.closeAll();
      this.departmentAccordion.closeAll();
    }


    console.log("auto", value);
    this.selected = value;
    this.triggerChanged.next(value);
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log("change",changes);
    if (this.selected == 'N') {
      this.groupSelection.clear();
      this.newHireAccordion.closeAll();
      this.locationAccordion.closeAll();
      this.departmentAccordion.closeAll();
      this.groupAccordion.closeAll();
    }
  }

}
