import { Component, OnInit, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';
import { Content } from 'app/main/content/content-list.model';
import { AuthenticationService } from '../../authentication/_services';
import { environment } from '../../../../environments/environment';
import { Quiz } from '../quiz/models';
import { QuizService } from '../quiz/services/quiz.service';
import { ContentService } from '../content.service';

export class FileData {

  categories: any;
  subCategories: any;
  title: string;
  description: string;
  thumbUrl: string;
  tags: any;
  contentId: string
}
export class QuizAnDSharingData {

  totalQuestion: any;
  totalAnswers: any;
  quizTime: any;
  sharedManagers: any;
  sharedLocations: any;
  sharedGroups: any;
  sharedDepartments: any;
}

@Component({
  selector: 'app-content-wizard',
  templateUrl: './content-wizard.component.html',
  styleUrls: ['./content-wizard.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ContentWizardComponent implements OnInit {

  sharingSkipped = false;
  quizSkipped = false;
  fileData: FileData;
  enviorment: any;
  step = 1;
  content: Content;
  categories: any;
  subCategories: any;
  selectedSubCategories: any;
  company;
  selectedVideoThumb: any;

  sharedManagers: any;
  sharedLocations: any;
  sharedGroups: any;
  sharedDepartments: any;

  quiz: Quiz;
  totalQuestion: any;
  totalAnswers: any;
  quizTime: any;

  sharedData: QuizAnDSharingData;

  @Input()
  fileName: any;

  @Input()
  uploader: any;

  @Output() onDone = new EventEmitter<any>();


  constructor(public _auth: AuthenticationService, private _quizService: QuizService, private contentService: ContentService) {
    this.company = this._auth.currentUserValue.companyName;
    this.fileData = new FileData();
    this.enviorment = environment
  }
  ngOnInit(): void {
    this.content = new Content({});
  }
  onTitleDone(event) {
    this.content.title = event.data;
    this.fileData.title = event.data;
    this.nextStep();

  }
  onDescriptionDone(event) {
    this.content.description = event.data;
    this.fileData.description = event.data;

    this.nextStep();
  }
  onCategoryDone(event) {
    this.content.categoryList = event.data.categories;
    this.categories = event.data.categories;
    this.fileData.categories = event.data.categories;
    this.subCategories = event.data.subCategories;
    this.nextStep();
  }

  onCategorySelection(event) {
    this.content.categoryList = event.data.categories;
    this.categories = event.data.categories;
    this.fileData.categories = event.data.categories;
    this.subCategories = event.data.subCategories;
    this.fileData.subCategories = event.data.subCategories;

  }
  onSubCategoryDone(event) {
    this.content.categoryList = event.data;
    this.selectedSubCategories = event.data;
    this.nextStep();
  }
  onTagsDone(event) {
    this.fileData.tags = event.data;
    this.nextStep();
  }
  onThumbnailDone(event) {
    this.selectedVideoThumb = event.data;
    this.onImageSelect(this.selectedVideoThumb);
    this.nextStep();
  }
  onPublishDone(event) {
    if (event.type == 'draft') {
      this.onDone.next("done")
    } else {
      this.nextStep();

    }
  }
  onPublishConfirmDone(event) {
    console.log("onPublishConfirmDone", event);
    //this.content.id = event.value.id;
    //this.fileData.contentId = this.content.id;
    this.nextStep();
  }
  onPlaylistDone(event) {
    this.contentService.deleteAndInsertPlaylistContent(event.data, this.content.id).then(res => {
      this.nextStep();
    })
  }
  onCreateQuizDone(value) {
    if (value == 'skip') {
      //this.nextStep();
      this.step = 12;
      this.quizSkipped = true;
    } else {
      this.step = 13;
    }
  }
  onShareDone(value) {
    if (value == 'skip') {
      this.step = 12;
      this.sharingSkipped = true;
    } else {
      this.step = 11;
    }
  }
  onShareWithDone(event) {

    if (event.data && event.data.managers) {
      this.sharedManagers = event.data.managers;
    }

    if (event.data && event.data.locations) {
      this.sharedLocations = event.data.locations;
    }

    if (event.data && event.data.groups) {
      this.sharedGroups = event.data.groups;
    }
    if (event.data && event.data.departments) {
      this.sharedDepartments = event.data.departments;
    }

    this.sharedData = new QuizAnDSharingData();

    this.sharedData.quizTime = this.quizTime;
    this.sharedData.totalAnswers = this.totalAnswers;
    this.sharedData.totalQuestion = this.totalQuestion;
    this.sharedData.sharedDepartments = this.sharedDepartments;
    this.sharedData.sharedGroups = this.sharedGroups;
    this.sharedData.sharedLocations = this.sharedLocations;
    this.sharedData.sharedManagers = this.sharedManagers;


    this.nextStep();



  }
  nextStep() {
    this.step++;
  }
  pervStep() {
    this.step--;
  }

  handleBack(event) {
    if (event == 'quiz-editor') {
      this.step = 9;
    } else {
      this.pervStep();
    }
  }

  onDescriptionChanged(event){
    this.content.description = event.data;
    this.fileData.description = event.data;
  }
  onImageSelect(video) {
    if (video) {
      if (video.prodType == 'VIDEO') {
        this.fileData.thumbUrl = this.enviorment.cdnUrl + "/" + this.enviorment.thumbDir + "/T_" + video.itemCode + ".jpg"
      } else {
        this.fileData.thumbUrl = "assets/icons/" + video.image;

      }
    }
  }

  onQuizEditorDone(event) {
    console.log("event", event);
    this.quiz.companyID = this._auth.currentUserValue.companyId;
    this.quiz.profileID = this._auth.currentUserValue.profileId;
    this.quiz.contentID = this.content.id;
    this._quizService.saveQuiz(this.quiz).subscribe(res => {
      if (res) {
        this.step = 12;
      }
    })
  }
  onQuizEditorSelection(event) {
    this.quiz = event;
    this.totalAnswers = 0
    this.totalQuestion = this.quiz.questions.length;
    this.quiz.questions.forEach(element => {
      this.totalAnswers += element.answers.length;
    });
    this.quizTime = this.totalQuestion * 0.5;
    this.sharedData = new QuizAnDSharingData();
    this.sharedData.quizTime = this.quizTime;
    this.sharedData.totalAnswers = this.totalAnswers;
    this.sharedData.totalQuestion = this.totalQuestion;
  }
  onContentFinished(event) {
    this.onDone.next("done")
  }

  uploadDone(value) {
    this.content.id = value;
    this.fileData.contentId = value;
  }
}
