import {NgModule, ModuleWithProviders} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {AbsSharedModule} from '@abs/shared.module';
import {YouTubePlayerModule} from '@angular/youtube-player';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { FileSelectDirective, FileUploadModule } from 'ng2-file-upload';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';

import {SWIPER_CONFIG, SwiperConfigInterface, SwiperModule} from 'ngx-swiper-wrapper';

import {ContentComponent} from './content.component';
import {VideosComponent} from './videos/videos.component';
import {VideosListComponent} from './videos/videos-list/videos-list.component';
import {PreviewComponent} from './videos/preview/preview.component';
import {VideoPlayerComponent} from './videos/video-player/video-player.component';
import {PlaylistsComponent} from './playlists/playlists.component';
import {PlaylistItemsComponent} from './playlists/playlist-items/playlist-items.component';
import {ReviewComponent} from './videos/preview/review/review.component';
import {PlaylistManagerComponent} from './playlist-manager/playlist-manager.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';

import {PlaylistManagerAllComponent} from './playlist-manager/playlist-manager-all/playlist-manager-all.component';
import {PlaylistManagerListComponent} from './playlist-manager/playlist-manager-all/playlist-manager-list/playlist-manager-list.component';
import {PlaylistManagerFormComponent} from './playlist-manager/playlist-manager-all/playlist-manager-form/playlist-manager-form.component';
import {AbsConfirmDialogModule, AbsMaterialColorPickerModule, AbsSidebarModule, AbsWidgetModule} from '../../../@abs/components';
import { PlaylistUsersComponent } from './playlist-manager/playlist-users/playlist-users.component';
import { PlaylistManagerShareFormComponent } from './playlist-manager/playlist-manager-all/playlist-manager-share-form/playlist-manager-share-form.component';
import { MyUploadsComponent } from './my-uploads/my-uploads.component';
import { NO_ERRORS_SCHEMA } from '@angular/compiler';
import { FileManagerService } from './my-uploads/my-uploads.service';
import { FileManagerFileListComponent } from './my-uploads/file-list/file-list.component';
import { MyUploadsEditFolderNameComponent } from './my-uploads/edit-folder-name/edit-folder-name.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MyUplaodsFileDialogComponent } from './my-uploads/dialogs/item/item.component';
import { MatChipsModule } from '@angular/material/chips';
import { ThumbnailSelectorComponent } from './my-uploads/dialogs/thumbnail-selector/thumbnail-selector.component';
import { SupplementalsComponent } from './supplementals/supplementals.component';
import { SupplimentalPreviewComponent } from './supplementals/preview/supplimental-preview.component';
import { TourMatMenuModule } from 'ngx-tour-md-menu';
import { FileLabelSelectorComponent } from './my-uploads/dialogs/item/dialogs/label-selector/label-selector.component';
import { ContentService } from './content.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { PlaylistItemSkeletonComponent } from './playlists/playlist-item-skeleton/playlist-item-skeleton.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { QuizComponent } from './quiz/quiz.component';
import { QuizService } from './quiz/services/quiz.service';
import { AbsDirectivesModule } from '@abs/directives/directives';
import { YoutubeVideoPlayerComponent } from './videos/youtube-video-player/youtube-video-player.component';
import { AuthGuard } from '../authentication/_helpers';
import { UserLevel } from '../models/user';
import { QuizPreviewComponent } from './quiz-preview/quiz-preview.component';
import { TrainerQuizPreviewComponent } from './trainer-quiz-preview/trainer-quiz-preview.component';
import { ScoreBarModule } from '@abs/components/score-bar/score-bar.module';
import { AvatarModule } from 'ngx-avatar';
import { UserAvatarModule } from '@abs/components/user-avatar/user-avatar.module';
import { LandingPageModule } from '../landing-page/landing-page.module';
import { MatBadgeModule } from '@angular/material/badge';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { PlayNowDialogComponent } from './play-now-dialog/play-now-dialog.component';
import { MatSortModule } from '@angular/material/sort';
import { SiteHelpDialogModule } from '../site-help-dialog/site-help-dialog.module';
import { SiteHelpDialogComponent } from '../site-help-dialog/site-help-dialog.component';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { DocumentViewerDialogModule } from '../document-viewer-dialog/document-viewer-dialog.module';
import { DocumentViewerDialogComponent } from '../document-viewer-dialog/document-viewer-dialog.component';
import { WidgetsModule } from '../widgets/widgets.module';
import { ShoppingCartService } from '../auto-training/cart.service';
import { VideoPageComponent } from './videos/video-page/video-page.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { ContentWizardComponent } from './content-wizard/content-wizard.component';
import { ContentCourseNameComponent } from './content-wizard/content-course-name/content-course-name.component';
import { ContentCourseDescriptionComponent } from './content-wizard/content-course-description/content-course-description.component';
import { ContentCategorySelectorComponent } from './content-wizard/content-category-selector/content-category-selector.component';
import { ContentSubcategorySelectorComponent } from './content-wizard/content-subcategory-selector/content-subcategory-selector.component';
import { ContentTagSelectorComponent } from './content-wizard/content-tag-selector/content-tag-selector.component';
import { ContentThumbnailSelectorComponent } from './content-wizard/content-thumbnail-selector/content-thumbnail-selector.component';
import { ContentPublisherComponent } from './content-wizard/content-publisher/content-publisher.component';
import { ContentPublisherConfirmationComponent } from './content-wizard/content-publisher-confirmation/content-publisher-confirmation.component';
import { ContentPlayistSelectorComponent } from './content-wizard/content-playist-selector/content-playist-selector.component';
import { CreateQuizComponent } from './content-wizard/create-quiz/create-quiz.component';
import { ShareCourseComponent } from './content-wizard/share-course/share-course.component';
import { ContentCongratulationComponent } from './content-wizard/content-congratulation/content-congratulation.component';
import { ContentShareWithComponent } from './content-wizard/content-share-with/content-share-with.component';
import { ContentShareManagerSelectorComponent } from './content-wizard/content-share-with/content-share-manager-selector/content-share-manager-selector.component';
import { ContentShareDepartmentSelectorComponent } from './content-wizard/content-share-with/content-share-department-selector/content-share-department-selector.component';
import { ContentShareLocationSelectorComponent } from './content-wizard/content-share-with/content-share-location-selector/content-share-location-selector.component';
import { ContentShareGroupSelectorComponent } from './content-wizard/content-share-with/content-share-group-selector/content-share-group-selector.component';
import { QuizEditorComponent } from './content-wizard/quiz-editor/quiz-editor.component';
import { QuizQuestionTypeSelectorComponent } from './content-wizard/quiz-editor/quiz-question-type-selector/quiz-question-type-selector.component';
import { QuizQuestionFormComponent } from './content-wizard/quiz-editor/quiz-question-form/quiz-question-form.component';
import { QuizAddAnotherQuestionComponent } from './content-wizard/quiz-editor/quiz-add-another-question/quiz-add-another-question.component';
import { QuizQuestionSummaryComponent } from './content-wizard/quiz-editor/quiz-question-summary/quiz-question-summary.component';
import { ThirdPartyStreamingVerificationComponent } from './videos/preview/third-party-streaming-verification/third-party-streaming-verification.component';
import { VideoLengthErrorDialogComponent } from './content-wizard/video-length-error-dialog/video-length-error-dialog.component';
import { WrongFileTypeSelectedComponent } from './content-wizard/wrong-file-type-selected/wrong-file-type-selected.component';
import { QuizEditorComponentDialog } from './quiz-editor-dialog/quiz-editor-dialog.component';
import { QuizQuitDialogComponent } from './quiz-editor-dialog/quiz-quit-dialog/quiz-quit-dialog.component';
import { QuizPassingScoreComponent } from './content-wizard/quiz-editor/quiz-passing-score/quiz-passing-score.component';
import { QuizPassingScoreSliderComponent } from './content-wizard/quiz-editor/quiz-passing-score-slider/quiz-passing-score-slider.component';
import { MatSliderModule } from '@angular/material/slider';
const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto'
};

const routes = [
  {
    path: '',
    component: ContentComponent,
    children: [
      {
        path: '', 
        redirectTo: 'all', 
        pathMatch: 'full'
     },
      {
        path: 'all',
        component: VideosComponent,
        canActivate: [AuthGuard],
        data: { roles: [UserLevel.TRAINER,UserLevel.ADMIN,UserLevel.MANAGER] },
      },
      {
        path: 'product',
        component: VideoPageComponent,
        canActivate: [AuthGuard],
        data: { roles: [UserLevel.TRAINER,UserLevel.ADMIN,UserLevel.MANAGER] },
      },
      {
        path: 'playlists',
        component: PlaylistManagerComponent,
        canActivate: [AuthGuard],
        data: { roles: [UserLevel.TRAINER,UserLevel.ADMIN,UserLevel.MANAGER] },
      },
      {
        path: 'my-content',
        component: MyUploadsComponent,
        canActivate: [AuthGuard],
        data: { roles: [UserLevel.TRAINER,UserLevel.ADMIN,UserLevel.MANAGER] },
        resolve:{
          FileManagerService
        }
      },
      /* {
        path: 'playlist-manager',
        component: PlaylistManagerComponent,
        resolve: {
          ContentService
        }
      } */
    ]
  }
];

export const routing: ModuleWithProviders = RouterModule.forChild(routes);

@NgModule({
  declarations: [
    ContentComponent,
    VideosComponent,
    VideosListComponent,
    PreviewComponent,
    VideoPlayerComponent,
    PlaylistsComponent,
    PlaylistItemsComponent,
    ReviewComponent,
    PlaylistManagerComponent,
    PlaylistManagerAllComponent,
    PlaylistManagerListComponent,
    PlaylistManagerFormComponent,
    PlaylistUsersComponent,
    PlaylistManagerShareFormComponent,
    MyUploadsComponent,
    FileManagerFileListComponent,
    MyUploadsEditFolderNameComponent,
    MyUplaodsFileDialogComponent,
    FileLabelSelectorComponent,
    ThumbnailSelectorComponent,
    SupplementalsComponent,
    SupplimentalPreviewComponent,
    PlaylistItemSkeletonComponent,
    QuizComponent,
    YoutubeVideoPlayerComponent,
    QuizPreviewComponent,
    TrainerQuizPreviewComponent,
    PlayNowDialogComponent,
    VideoPageComponent,
    ContentWizardComponent,
    ContentCourseNameComponent,
    ContentCourseDescriptionComponent,
    ContentCategorySelectorComponent,
    ContentSubcategorySelectorComponent,
    ContentTagSelectorComponent,
    ContentThumbnailSelectorComponent,
    ContentPublisherComponent,
    ContentPublisherConfirmationComponent,
    ContentPlayistSelectorComponent,
    CreateQuizComponent,
    ShareCourseComponent,
    ContentCongratulationComponent,
    ContentShareWithComponent,
    ContentShareManagerSelectorComponent,
    ContentShareDepartmentSelectorComponent,
    ContentShareLocationSelectorComponent,
    ContentShareGroupSelectorComponent,
    QuizEditorComponent,
    QuizQuestionTypeSelectorComponent,
    QuizQuestionFormComponent,
    QuizAddAnotherQuestionComponent,
    QuizQuestionSummaryComponent,
    ThirdPartyStreamingVerificationComponent,
    VideoLengthErrorDialogComponent,
    WrongFileTypeSelectedComponent,
    QuizEditorComponentDialog,
    QuizQuitDialogComponent,
    QuizPassingScoreComponent,
    QuizPassingScoreSliderComponent
  ],
  imports: [
    CommonModule,
    routing,

    // Shared Modules
    AbsSharedModule,
    AbsConfirmDialogModule,
    InfiniteScrollModule,

    // Material2
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatRadioModule,
    MatTooltipModule,
    MatSelectModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatMenuModule,
    MatRippleModule,
    MatTableModule,
    MatToolbarModule,
    MatListModule,
    MatExpansionModule,
    MatDividerModule,
    MatSnackBarModule,
    MatTabsModule,
    MatAutocompleteModule,
    MatDialogModule,
    MatChipsModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    AbsMaterialColorPickerModule,
    AbsSidebarModule,
    AbsDirectivesModule,
    MatBadgeModule,
    TourMatMenuModule,
    // Google
    YouTubePlayerModule,
    MatSlideToggleModule,

    // TP
    SwiperModule,
    MatProgressBarModule,
    TourMatMenuModule,
    NgxSkeletonLoaderModule,
    ScoreBarModule,
    UserAvatarModule,
    MatSortModule,
    SiteHelpDialogModule,
    DocumentViewerDialogModule,
    WidgetsModule,
    AbsWidgetModule,
    FileUploadModule,
    ClipboardModule,
    MatSliderModule
  ] ,/*
  schemas: [
    NO_ERRORS_SCHEMA
  ], */
  exports: [
    RouterModule,VideoPlayerComponent,YoutubeVideoPlayerComponent,VideosListComponent
  ],
  providers: [
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG
    },
    FileManagerService,
    ContentService,
    QuizService,
    ShoppingCartService
  ],
  entryComponents: [SiteHelpDialogComponent, SupplimentalPreviewComponent,PreviewComponent, PlaylistManagerFormComponent, PlaylistManagerShareFormComponent,MyUplaodsFileDialogComponent,ThumbnailSelectorComponent,QuizComponent,QuizPreviewComponent,TrainerQuizPreviewComponent,PlayNowDialogComponent,DocumentViewerDialogComponent,ThirdPartyStreamingVerificationComponent,VideoLengthErrorDialogComponent,WrongFileTypeSelectedComponent,QuizEditorComponentDialog,QuizQuitDialogComponent]
})
export class ContentModule {
}
