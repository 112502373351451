import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-kiosk-quiz-toggle-dialog',
  templateUrl: './kiosk-quiz-toggle-dialog.component.html',
  styleUrls: ['./kiosk-quiz-toggle-dialog.component.scss']
})
export class KioskQuizToggleDialogComponent implements OnInit {

  constructor(public matDialogRef: MatDialogRef<KioskQuizToggleDialogComponent>) { }

  ngOnInit() {
  }

  onNoClick(){
    this.matDialogRef.close();
  }

  onYesClick(){
    this.matDialogRef.close("Y");
  }
}
