import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { Group } from '../../../user/groups/groups-all/group.model';
import { Playlist } from '../../../content/playlists/playlists.model';
import { GroupsService } from '../../../user/groups/groups.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { Content } from '../../../content/content-list.model';
import { ContentService } from '../../../content/content.service';
import { takeUntil, distinctUntilChanged } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { environment } from 'environments/environment';
import { ActivatedRoute } from '@angular/router';
import { AbsUtils } from '@abs/utils';

@Component({
  selector: 'app-assign-preview',
  templateUrl: './assign-preview.component.html',
  styleUrls: ['./assign-preview.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})


export class AssignPreviewComponent implements OnInit {
  searchInputUsers: FormControl;
  searchInputPlaylist: FormControl;

  @Input() selected: string;
  //@Input() selected2 = 'Groups and Users';
  playlistAndContent = ['select', 'playlistName', 'courseTime', 'rating', 'buttons'];
  groupsAndUsers = ['select', 'groupName', 'groupUserCount', 'buttons',];

  playlistAndContentExpandedElement2: Playlist | null;
  pacdataSource: any;
  selectionpac = new SelectionModel<Playlist>(true, []);
  playlists: any;

  groupsAndUsersExpandedElement2: Group | null;
  gaudataSource: any;
  selection = new SelectionModel<Group>(true, []);
  groups: any;
  environment: any;
  // Private
  private _unsubscribeAll: Subject<any>;
  constructor(public contentService: ContentService, public groupService: GroupsService, private activatedRoute: ActivatedRoute
  ) {

    this.searchInputUsers = new FormControl('');
    this.searchInputPlaylist = new FormControl('');
    this.environment = environment;
  }

  ngOnInit() {
      this.contentService.onPlaylistsChanged
        .subscribe(playlists => {
          this.playlists = playlists;
          this.pacdataSource = new MatTableDataSource(playlists);
        });

    this.gaudataSource = new GroupDataSource(this.groupService);
    this.groupService.onGroupsChanged
      .subscribe(groups => {
        this.groups = groups;
        this.gaudataSource = new MatTableDataSource(groups);
        this.activatedRoute.queryParams.subscribe(params => {
          const op = params['op'];
          if (op == 'group') {
            var selected = JSON.parse(sessionStorage.getItem("groupSelection"));
            selected.map(element => {
              this.gaudataSource.data.map(row => {
                if (row.groupID === element.groupID) {
                  this.selection.select(row);
                }
                if (row.userList && element.profileId) {
                  row.userList.map(user => {
                    if (user.profileId === element.profileId) {
                      this.selection.select(user);
                    }
                  })
                }
              });
            });
          }
        });
      });

  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.gaudataSource.data.length;
    return numSelected === numRows;
  }

  isAllSelectedPac() {
    const numSelected = this.selectionpac.selected.length;
    const numRows = this.pacdataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.gaudataSource.data.forEach(row => this.selection.select(row));
  }

  masterTogglePac() {
    this.isAllSelectedPac() ?
      this.selectionpac.clear() :
      this.pacdataSource.data.forEach(row => this.selectionpac.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: Group): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.name + 1}`;
  }

  checkboxLabelPac(row?: Playlist): string {
    if (!row) {
      return `${this.isAllSelectedPac() ? 'select' : 'deselect'} all`;
    }
    return `${this.selectionpac.isSelected(row) ? 'deselect' : 'select'} row ${row.name + 1}`;
  }

  applyFilterUsers(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.gaudataSource.filter = filterValue;
  }

  applyFilterPlaylist(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.pacdataSource.filter = filterValue;
  }
  someSelected(row):boolean{
    if (row.userList == null) {
      return false;
    }
    return row.userList.filter(u => this.selection.isSelected(u)).length > 0;
  }
}


export class GroupDataSource extends MatTableDataSource<Group> {
  /**
   * Constructor
   *
   * @param {GroupsService} _groupsService
   */
  constructor(
    private _groupsService: GroupsService,
  ) {
    super();
  }

  /**
   * Connect function called by the table to retrieve one stream containing the data to render.
   * @returns {Observable<any[]>}
   */
  connect(): BehaviorSubject<Group[]> {
    return this._groupsService.onGroupsChanged;
  }

  /**
   * Disconnect
   */
  disconnect(): void {
  }


}

export class ContentDataSource extends MatTableDataSource<Playlist> {
  /**
   * Constructor
   *
   * @param {ContentService} contentService
   */
  constructor(
    private contentService: ContentService
  ) {
    super();
  }

  /**
   * Connect function called by the table to retrieve one stream containing the data to render.
   * @returns {Observable<any[]>}
   */
  connect(): BehaviorSubject<Playlist[]> {
    return this.contentService.onPlaylistsChanged;
  }
  /**
   * Disconnect
   */
  disconnect(): void {
  }
}