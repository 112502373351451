import { Component, OnInit, ViewEncapsulation, Output, EventEmitter, Input } from '@angular/core';
import { AuthenticationService, CompnayService } from 'app/main/authentication/_services';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AbsUtils } from '@abs/utils';
import { SelectionModel } from '@angular/cdk/collections';
import { GroupsService } from '../../../user/groups/groups.service';
import { absAnimations } from '@abs/animations';

@Component({
  selector: 'app-onboarding-department-selector',
  templateUrl: './onboarding-department-selector.component.html',
  styleUrls: ['./onboarding-department-selector.component.scss'], 
  animations: absAnimations,
  encapsulation: ViewEncapsulation.None,
})
export class OnboardingDepartmentSelectorComponent implements OnInit {
  flip:any;
  searchGroup = new FormControl();

  @Input() data: any;
  departmentGroups: any;
  allDepartmentGroups: any;
  searchCtrlOrg = new FormControl();
  selectionOrgDep = new SelectionModel<any>(true, []);
  
  @Output() onBackButton = new EventEmitter<any>();
  @Output() onSelection = new EventEmitter<any>();
  @Output() onDone = new EventEmitter<any>();

  constructor(private _authService: AuthenticationService) { }

  ngOnInit(): void {

      this.departmentGroups = this.data;
      this.allDepartmentGroups = this.data;;
  

    this.searchCtrlOrg.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe(value => {
      if (value.length > 0) {
        var result = AbsUtils.filterArrayByString(this.allDepartmentGroups, value);
        this.departmentGroups = result;
      } else {
        this.allDepartmentGroups = this.allDepartmentGroups;

      }
    });
  }

  onDepartmentOrgChange(group){
    if (group.userCount > 0) {

    this.selectionOrgDep.toggle(group);
    this.onSelection.emit(this.selectionOrgDep.selected);
    }

  }

  handleBack(){
    this.onBackButton.emit('department');
  }
  groupSelected(){
    this.onDone.emit(this.selectionOrgDep.selected);
  }
  descClicked(value) {
    this.flip = value;
  }
}
