import {Component, Input, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {Router} from '@angular/router';

import {AbsConfigService} from '@abs/services/config.service';
import {navigation, learnerNavigation} from 'app/navigation/navigation';
import {AuthService} from '../../../main/authentication/auth.service';
import { UserLevel } from '../../../main/models/user';
import { AuthenticationService } from '../../../main/authentication/_services';

@Component({
  selector: 'horizontal-layout-1',
  templateUrl: './layout-1.component.html',
  styleUrls: ['./layout-1.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HorizontalLayout1Component implements OnInit, OnDestroy {
  selected = 'Account';
  router: string;
  absConfig: any;
  navigation: any;
  currentUser:any;
  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {AbsConfigService} _absConfigService
   * @param {AuthService} authService
   * @param {Router} _router
   */
  constructor(
    public _router: Router,
    private _absConfigService: AbsConfigService,
    private authService: AuthenticationService
  ) {

    this.authService.currentUser.subscribe(value => {
      this.currentUser = value;
      console.log("cccc",this.currentUser)
    });
    // Set the defaults
    if(this.authService.currentUserValue && this.authService.currentUserValue.userLevel == UserLevel.LEARNER){
      this.navigation = learnerNavigation;
    }else{
      this.navigation = navigation;
    }

    

    // Set the private defaults
    this._unsubscribeAll = new Subject();

    this.router = _router.url;

  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Subscribe to config changes
    this._absConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config) => {
        this.absConfig = config;
      });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
