import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-profile-dialog',
  templateUrl: './profile-dialog.component.html',
  styleUrls: ['./profile-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProfileDialogComponent implements OnInit {
  profileFormGroup: FormGroup;

  constructor(public matDialogRef: MatDialogRef<ProfileDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    public _matDialog: MatDialog, private _formBuilder: FormBuilder) {

      this.profileFormGroup = this._formBuilder.group( {
        firstName:  ['',{updateOn: "blur"}],
        lastName:   ['',{updateOn: "blur"}],
        middleName: ['',{updateOn: "blur"}],
        email: ['',{updateOn: "blur"}],
        altEmail:['',{updateOn: "blur"}],
        phoneNumber: ['',{updateOn: "blur"}],
        smsNumber:['',{updateOn: "blur"}],
        organization: ['',{updateOn: "blur"}],
        department: ['',{updateOn: "blur"}],
        position: ['',{updateOn: "blur"}],
        policyId: ['',{updateOn: "blur"}],
        employeeId: ['',{updateOn: "blur"}]
      });
  }

  ngOnInit() {
  }
}
