import { MatChipInputEvent } from '@angular/material/chips';
import { AbsUtils } from '@abs/utils';

export class File
{
    id: string;
    title:string;
    folder: string;
    source: string;
    youtubeTitle: string;
    friendlyTitle: string;
    handle: string;
    description: string;
    categories: string[];
    tags: string[];
    thumbnail: string;
    dateCreated: string;
    lastEdit: string;
    createdBy: number;
    lastUsed: string;
    visibility: boolean;
    type: string;

    /**
     * Constructor
     *
     * @param file
     */
    constructor(file?)
    {
        file = file || {};
        this.id = file.id || AbsUtils.generateGUID();
        this.title = file.title || '';
        this.folder = file.folder || '';
        this.source = file.source || '';
        this.youtubeTitle = file.youtubeTitle || '';
        this.friendlyTitle = file.friendlyTitle || '';
        this.tags = file.tags || [];
        this.categories = file.categories || [];
        this.thumbnail = file.thumbnail ||'';
        this.handle = AbsUtils.handleize(this.friendlyTitle);
        this.dateCreated = file.dateCreated || '';
        this.lastEdit = file.lastEdit || '';
        this.createdBy = file.createdBy || 0;
        this.lastUsed = file.lastUsed || '';
        this.visibility = file.visibility || false;
        this.type = file.type || '';
       
    }

    /**
     * Add category
     *
     * @param {MatChipInputEvent} event
     */
    addCategory(event: MatChipInputEvent): void
    {
        console.log("Add Category");
        const input = event.input;
        const value = event.value;

        // Add category
        if ( value )
        {
            this.categories.push(value);
        }

        // Reset the input value
        if ( input )
        {
            input.value = '';
        }
    }

    /**
     * Remove category
     *
     * @param category
     */
    removeCategory(category): void
    {
        
        const index = this.categories.indexOf(category);

        if ( index >= 0 )
        {
            this.categories.splice(index, 1);
        }
    }

    /**
     * Add tag
     *
     * @param {MatChipInputEvent} event
     */
    addTag(event: MatChipInputEvent): void
    {

        console.log("Add Tags");

        const input = event.input;
        const value = event.value;

        // Add tag
        if ( value )
        {
            this.tags.push(value);
        }

        // Reset the input value
        if ( input )
        {
            input.value = '';
        }
    }

    /**
     * Remove tag
     *
     * @param tag
     */
    removeTag(tag): void
    {
        const index = this.tags.indexOf(tag);

        if ( index >= 0 )
        {
            this.tags.splice(index, 1);
        }
    }
}
