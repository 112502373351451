import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AbsSnackBarComponent } from './abs-snack-bar.component';
import { MatIconModule } from '@angular/material/icon';
import { AbsSharedModule } from '../../shared.module';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [AbsSnackBarComponent],
  imports: [
    CommonModule,
    MatIconModule,
    AbsSharedModule,
    MatButtonModule
  ],
  exports: [
    AbsSnackBarComponent
  ],
  entryComponents: [
    AbsSnackBarComponent
  ]
})
export class AbsSnackBarModule { }
