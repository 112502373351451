import { AbsUtils } from '@abs/utils';


export class LocationTech {
  id: string;
  name: string;
  category: string;
  type: string;

  /**
  * Constructor
  *
  * @param tech
  */
  constructor(tech) {
    {
      this.id = tech.id;
      this.name = tech.name || '';
      this.category = tech.category;
      this.type = tech.type;

    }
  }
}

export class LocationUsage {
  date: string;
  participantCount: string;

  /**
  * Constructor
  *
  * @param usage
  */
  constructor(usage) {
    {
      this.date = usage.date;
      this.participantCount = usage.participantCount;
    }
  }
}

export class Location {
  id: string;
  name: string;
  address1: string;
  address2: string;

  displayAddress: string;
  addressLat: number;
  addressLong: number;
  city: string;
  state: string;
  zip: string;
  trainingLocationOnly: boolean;
  maxAttend: number;
  hours: string;
  notes: string;
  heatAir: string;
  wifiName: string;
  wifiPass: string;
  wifiQuality: string;
  laptopFriendly: boolean;
  techList: LocationTech[];
  usage: LocationUsage[];

  label: string;
  companyID: number;
  profileID: number;
  url: string;

  /**
   * Constructor
   *
   * @param location
   */
  constructor(location) {
    {
      this.id = location.id;
      this.name = location.name || '';
      this.address1 = location.address1;
      this.address2 = location.address2;
      this.displayAddress = location.displayAddress;
      this.city = location.city;
      this.state = location.state;
      this.zip = location.zip;
      this.hours = location.hours;
      this.maxAttend = location.maxAttend;
      this.notes = location.notes;
      this.heatAir = location.heatAir;
      this.wifiName = location.wifiName;
      this.wifiPass = location.wifiPass;
      this.wifiQuality = location.wifiQuality;
      this.laptopFriendly = location.laptopFrindly;
      this.techList = location.techList;
      this.usage = location.usage;
      this.label = location.label;
      this.companyID = location.companyID;
      this.profileID = location.profileID;
      this.addressLat = location.addressLat;
      this.addressLong = location.addressLong;
      this.trainingLocationOnly = location.trainingLocationOnly;
      this.url = location.url;
    }
  }
}
