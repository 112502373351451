import { Component, OnInit, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { absAnimations } from '@abs/animations';

@Component({
  selector: 'app-training-method-selector',
  templateUrl: './training-method-selector.component.html',
  styleUrls: ['./training-method-selector.component.scss'],
  animations: absAnimations,
  encapsulation: ViewEncapsulation.None,
})
export class TrainingMethodSelectorComponent implements OnInit {

  @Output() onSelection = new EventEmitter<any>();
  @Output() onBackButton = new EventEmitter<any>();

  flip:any;
  constructor() { }

  ngOnInit(): void {
  }

  selectTrainingMethod(method){
    this.onSelection.emit(method);
  }
  handleBack(){
    this.onBackButton.emit('trainingMethod');
  }
  descClicked(value) {
    this.flip = value;
  }
}
