import { Component, OnInit } from '@angular/core';
import { Content } from '../content-list.model';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { MatDialog } from '@angular/material/dialog';
import { SupplimentalPreviewComponent } from './preview/supplimental-preview.component';
import { ContentService } from '../content.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-supplementals',
  templateUrl: './supplementals.component.html',
  styleUrls: ['./supplementals.component.scss']
})
export class SupplementalsComponent implements OnInit {


  supplimentals: Observable<Content[]>;
  allSupplimentals: Content[];
  similiarContent: Content[];

  public config: SwiperConfigInterface = {
    init: true,
    a11y: true,
    direction: 'horizontal',
    slidesPerView: 5,
    keyboard: true,
    mousewheel: false,
    scrollbar: false,
    navigation: true,
    pagination: false,
    spaceBetween: 12,
    width: 1024
  };

  step = 0;

  constructor(public dialog: MatDialog, public contentService: ContentService) { }

  openDialog(content) {
    this.getFirst5SimilarVideos(content);
    this.dialog.open(SupplimentalPreviewComponent, {
      id: 'preview',
      backdropClass: 'previewBackdrop',
      width: '1165px',
      data: {
        content: content,
        similiarContent: this.similiarContent,
        preview: true
      }

    });
  }

  ngOnInit() {
    this.supplimentals = this.contentService.getSupplimentals(1, 1000);
    this.supplimentals.subscribe(res => {
      this.allSupplimentals = res
    });
  }

  setStep(index: number) {
    this.step = index;
  }

  generateFake(count: number): Array<number> {
    const indexes = [];
    for (let i = 0; i < count; i++) {
      indexes.push(i);
    }
    return indexes;
  }

  getFirst5SimilarVideos(video) {
    if (video.productCategories) {
      video.productCategories.map(category => {
        let videos = this.allSupplimentals.filter(v => {
          if (v.productCategories && v.id != video.id) {
            return v.productCategories.some(x => x.name === category.name);
          }
        });
        this.similiarContent = videos;
      });
    }
  }
}
