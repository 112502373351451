import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { AbsUtils } from '@abs/utils';
import { PlayList } from '../_models/playlist';
import { User } from '../../models/user';
import { environment } from 'environments/environment';
import { TrainingAttendee } from '../_models/training-attendee.model';
import { AuthenticationService } from '../../authentication/_services';
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};


const httpOptionsDownload = {
  headers: new HttpHeaders({
    'Content-Type': 'application/pdf',
    'Accept': 'application/pdf'
  })
};

@Injectable({
  providedIn: 'root'
})
export class TrainingService implements Resolve<any> {

  onTrainingChanged: BehaviorSubject<any>;
  onTrainingAttendeeChanged: BehaviorSubject<any>;
  onSelectedTrainingChanged: BehaviorSubject<any>;
  onUserDataChanged: BehaviorSubject<any>;
  onSearchTextChanged: Subject<any>;
  onFilterChanged: Subject<any>;
  onCourseCompleted: Subject<any>;
  

  trainings: PlayList[];

  user: any;
  selectedLocations: number[] = [];

  searchText: string;
  filterBy: string;

  onSelectedUsers: BehaviorSubject<any>;
  onSelectedContents: BehaviorSubject<any>;
  scheduleData: BehaviorSubject<any>;

  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(private _httpClient: HttpClient,private authService:AuthenticationService) {
    // Set the defaults
    this.onTrainingChanged = new BehaviorSubject([]);
    this.onTrainingAttendeeChanged = new BehaviorSubject([]);
    this.onSelectedTrainingChanged = new BehaviorSubject([]);
    this.onUserDataChanged = new BehaviorSubject([]);
    this.onSearchTextChanged = new Subject();
    this.onFilterChanged = new Subject();
    this.onCourseCompleted = new Subject();
    this.onSelectedContents = new BehaviorSubject([]);
    this.onSelectedUsers = new BehaviorSubject([]);
    this.scheduleData = new BehaviorSubject([]);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Resolver
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Observable<any> | Promise<any> | any}
   */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return new Promise((resolve, reject) => {

      Promise.all([
        this.getTrainings(),
      ]).then(
        ([files]) => {

          this.onSearchTextChanged.subscribe(searchText => {
            this.searchText = searchText;
            this.getTrainings();
          });

          this.onFilterChanged.subscribe(filter => {
            this.filterBy = filter;
            this.getTrainings();
          });

          resolve();

        },
        reject
      );
    });
  }

  /**
   * Get locations
   *
   * @returns {Promise<any>}
   */
  getTrainings(): Promise<any> {
    return new Promise((resolve, reject) => {
      var profileiD = JSON.parse(localStorage.getItem("currentUser")).profileId;
      this._httpClient.get(`${environment.apiUrl}/training/schedule?profileId=` + profileiD, httpOptions)
        .subscribe((response: any) => {
          this.trainings = response;
          if (this.searchText && this.searchText !== '') {
            this.trainings = AbsUtils.filterArrayByString(this.trainings, this.searchText);
          }
          this.trainings = this.trainings.map(playlist => {
            return new PlayList(playlist);
          });
          resolve(this.trainings);
        }, reject);
    }
    );
  }


  getTrainingAttendees(schedulerId): Promise<any> {
    var profileiD = JSON.parse(localStorage.getItem("currentUser")).profileId;
    return new Promise((resolve, reject) => {
      this._httpClient.get(`${environment.apiUrl}/training/${schedulerId}/attendee?pid=${profileiD}`, httpOptions)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    }
    );
  }

  checkIfClassroomIn15Minx(mins){
    
    var profileiD = JSON.parse(localStorage.getItem("currentUser")).profileId;
    return new Promise((resolve, reject) => {
      this._httpClient.get(`${environment.apiUrl}/training/classroom/status/now?profileId=${profileiD}&mins=${mins}`, httpOptions)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    }
    );
  }

  getTraining(schedulerId): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.get(`${environment.apiUrl}/training/${schedulerId}`, httpOptions)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    }
    );
  }

  getTrainingLocation(schedulerId): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.get(`${environment.apiUrl}/location/classroom/${schedulerId}`, httpOptions)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    }
    );
  }

  getTrainingCourses(schedulerId): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.get(`${environment.apiUrl}/training/${schedulerId}/course`, httpOptions)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    }
    );
  }
  getTrainingCourseResult(schedulerId,courseId): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.get(`${environment.apiUrl}/training/${schedulerId}/course/${courseId}/result`, httpOptions)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    }
    );
  }

  getTotalClassroomConsumedViews(): Promise<any> {
    return new Promise((resolve, reject) => {
      var profileId =  JSON.parse(localStorage.getItem("currentUser")).profileId;
      this._httpClient.get(`${environment.apiUrl}/video/classroom/consumed/${profileId}`, httpOptions)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    }
    );
  }

  getTotalViewsData(profileId): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.get(`${environment.apiUrl}/all/consumed/${profileId}`, httpOptions)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }
  getTotalClassroomViews(profileId): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.get(`${environment.apiUrl}/classroom/consumed/${profileId}`, httpOptions)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    }
    );
  }

  getTotalLearnerViews(profileId): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.get(`${environment.apiUrl}/learner/consumed/${profileId}`, httpOptions)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    }
    );
  }

  getTotalQuizViews(profileId): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.get(`${environment.apiUrl}/quiz/consumed/${profileId}`, httpOptions)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    }
    );
  }

  getTotalVideoViews(profileId): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.get(`${environment.apiUrl}/video/consumed/${profileId}`, httpOptions)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    }
    );
  }

  getTotalNonVideoViews(profileId): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.get(`${environment.apiUrl}/nonvideo/consumed/${profileId}`, httpOptions)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    }
    );
  }

  getTrainingClassResult(schedulerId): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.get(`${environment.apiUrl}/training/class/${schedulerId}/result`, httpOptions)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    }
    );
  }

  getTrainingCoursesResult(schedulerId): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.get(`${environment.apiUrl}/training/${schedulerId}/courses/result`, httpOptions)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    }
    );
  }
  /**
   * Select devices
   *
   * @param filterParameter
   * @param filterValue
   */
  selectLocations(filterParameter?, filterValue?): void {
    this.selectedLocations = [];

    // If there is no filter, select all devices
    if (filterParameter === undefined || filterValue === undefined) {
      this.selectedLocations = [];
      this.trainings.map(training => {
        this.selectedLocations.push(training.id);
      });
    }

    // Trigger the next event
    this.onSelectedTrainingChanged.next(this.selectedLocations);
  }

  scheduleTraining(training) {
    return new Promise((resolve, reject) => {
      let obj = JSON.stringify(training);
      console.log("obj",obj);
      this._httpClient.post(`${environment.apiUrl}/training`,obj , httpOptions)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    }
    );
  }

  sendQuizLinks(schedulerId,productId) {
    return new Promise((resolve, reject) => {
      this._httpClient.post(`${environment.apiUrl}/training/quiz/generate/links`,{schedulerId:schedulerId, productId: productId,companyId: this.authService.currentUserValue.companyId} , httpOptions)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    }
    );
  }

  sendEMail(schedulerId,productId) {
    return new Promise((resolve, reject) => {
      this._httpClient.post(`${environment.apiUrl}/training/quiz/classroom/email`,{schedulerId:schedulerId, productId: productId,profileId: this.authService.currentUserValue.profileId} , httpOptions)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    }
    );
  }

  insertVideoLog(videoLog): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.post(`${environment.apiUrl}/video/log`, JSON.stringify(videoLog), httpOptions)
        .subscribe(response => {
          resolve(response);
        });
    });
  }
  insertClassroomVideoLog(videoLog): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.post(`${environment.apiUrl}/video/log/classroom`, JSON.stringify(videoLog), httpOptions)
        .subscribe(response => {
          resolve(response);
        });
    });
  }

  updateClassroomVideoLog(videoLog): Promise<any> {
    videoLog.trainerID = JSON.parse(localStorage.getItem("currentUser")).profileId;
    return new Promise((resolve, reject) => {
      this._httpClient.put(`${environment.apiUrl}/video/log/classroom`, JSON.stringify(videoLog), httpOptions)
        .subscribe(response => {
          resolve(response);
        });
    });
  }

  updateQuizVideoLog(videoLog): Promise<any> {
    console.log("going to update quiz log", videoLog);
    return new Promise((resolve, reject) => {
      this._httpClient.put(`${environment.apiUrl}/video/log/quiz`, JSON.stringify(videoLog), httpOptions)
        .subscribe(response => {
          resolve(response);
        });
    });
  }

  


  

  updateVideoLog(videoLog): Promise<any> {
    console.log("going to update", videoLog);
    return new Promise((resolve, reject) => {
      this._httpClient.put(`${environment.apiUrl}/video/log/`+videoLog.id, JSON.stringify(videoLog), httpOptions)
        .subscribe(response => {
          resolve(response);
        });
    });
  }


  checkIfUserCanViewVideo(viewRequset): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.post(`${environment.apiUrl}/bucket/validate/view`, JSON.stringify(viewRequset), httpOptions)
        .subscribe(response => {
          resolve(response);
        });
    });
  }

  

  deductContentView(viewRequset): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.post(`${environment.apiUrl}/bucket/deduct/view/content`, JSON.stringify(viewRequset), httpOptions)
        .subscribe(response => {
          resolve(response);
        });
    });
  }

  deductView(viewRequset): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.post(`${environment.apiUrl}/bucket/deduct/view/learner`, JSON.stringify(viewRequset), httpOptions)
        .subscribe(response => {
          resolve(response);
        });
    });
  }

  deductClassroomView(viewRequset): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.post(`${environment.apiUrl}/bucket/deduct/view/classroom`, JSON.stringify(viewRequset), httpOptions)
        .subscribe(response => {
          resolve(response);
        });
    });
  }
  
  deductClassroomQuizView(viewRequset): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.post(`${environment.apiUrl}/bucket/deduct/view/classroom/quiz`, JSON.stringify(viewRequset), httpOptions)
        .subscribe(response => {
          resolve(response);
        });
    });
  }

  deductQuizView(viewRequset): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.post(`${environment.apiUrl}/bucket/deduct/view/learner/quiz`, JSON.stringify(viewRequset), httpOptions)
        .subscribe(response => {
          resolve(response);
        });
    });
  }

  downloadCertificate(certificateRequet):any {
      this._httpClient.get(`${environment.apiUrl}/training/certificate?videoLogId=${certificateRequet.videoLogId}&companyName=${certificateRequet.companyName}&companyId=${certificateRequet.companyId}`,httpOptionsDownload)
        .subscribe(res => {
        
        });

  }

  emailCertificate(emailCertificateRequest:any): Promise<any>{
    return new Promise((resolve, reject) => {
    this._httpClient.get(`${environment.apiUrl}/training/certificate/email?vid=${emailCertificateRequest.videoLogId}&pid=${emailCertificateRequest.profileId}&email=${emailCertificateRequest.email}`,httpOptions)
        .subscribe(res => {
          resolve(res);
        });
      });
  }

  updateTrainingAttendee(attendee:TrainingAttendee): Promise<any> {
    console.log("going to update attendee", attendee);
    attendee.trainerID = JSON.parse(localStorage.getItem("currentUser")).profileId;
    return new Promise((resolve, reject) => {
      this._httpClient.put(`${environment.apiUrl}/training/attendee`, JSON.stringify(attendee), httpOptions)
        .subscribe(response => {
          resolve(response);
        });
    });
  }

  updateTrainingAttendeeRSVP(attendee:TrainingAttendee): Promise<any> {
    console.log("going to update attendee rsvp", attendee);
    return new Promise((resolve, reject) => {
      this._httpClient.put(`${environment.apiUrl}/training/attendee/rsvp`, JSON.stringify(attendee), httpOptions)
        .subscribe(response => {
          resolve(response);
        });
    });
  }

  updateTrainingStatus(id,status, currentCourse): Promise<any> {
    console.log("going to update training",id,status,currentCourse);
    return new Promise((resolve, reject) => {
      this._httpClient.put(`${environment.apiUrl}/training/status`, JSON.stringify({id: id, status: status, currentCourse: currentCourse}), httpOptions)
        .subscribe(response => {
          resolve(response);
        });
    });
  }

  insertTrainingAttendee(attendee:TrainingAttendee): Promise<any> {
    console.log("going to insert attendee", attendee);
    attendee.trainerID = JSON.parse(localStorage.getItem("currentUser")).profileId;
    return new Promise((resolve, reject) => {
      this._httpClient.post(`${environment.apiUrl}/training/attendee`, JSON.stringify(attendee), httpOptions)
        .subscribe(response => {
          resolve(response);
        });
    });
  }



  checkInUser(attendee): Promise<any> {
    console.log("going to checking user", attendee);
    attendee.trainerID = JSON.parse(localStorage.getItem("currentUser")).profileId;
    return new Promise((resolve, reject) => {
      this._httpClient.post(`${environment.apiUrl}/training/checkin`, JSON.stringify(attendee), httpOptions)
        .subscribe(response => {
          resolve(response);
        });
    });
  }



  checkIfUserRequiredMoreView(request){
    return new Promise((resolve, reject) => {
      this._httpClient.post(`${environment.apiUrl}/bucket/view/required`, JSON.stringify(request), httpOptions)
        .subscribe(response => {
          resolve(response);
        });
    });
  }


  getAutomatedSchedule(startDate,endDate,frequncy,freqDay,freqWeek,contents) {
    return new Promise((resolve, reject) => {
      this._httpClient.post(`${environment.apiUrl}/training/automated/schedule`,{startDate:startDate, endDate: endDate,frequency:frequncy,frequencyDay:freqDay,frequencyWeek:freqWeek,contents:contents} , httpOptions)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    }
    );
  }

}
