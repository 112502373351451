import { Component, Inject, OnDestroy, OnInit, ViewChild, ViewEncapsulation, Input } from '@angular/core';
import { NgForm, FormGroup, FormBuilder } from '@angular/forms';
import { Subject, of } from 'rxjs';

import { AbsConfirmDialogComponent } from '@abs/components/confirm-dialog/confirm-dialog.component';
import { AbsUtils } from '@abs/utils';
import { absAnimations } from '@abs/animations';
import { takeUntil } from 'rxjs/operators';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { FileManagerService } from '../../my-uploads.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { File } from '../../file.model';
import { ThumbnailSelectorComponent } from '../thumbnail-selector/thumbnail-selector.component';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { ElementRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { FileUploader } from 'ng2-file-upload';
import { environment } from 'environments/environment';
import { ContentService } from '../../../content.service';
import { Category, Content, ContentType } from '../../../content-list.model';
import { AuthenticationService } from '../../../../authentication/_services';
import { SelectionModel } from '@angular/cdk/collections';
import { AbsProgressBarService } from '@abs/components/progress-bar/progress-bar.service';
import * as Stomp from 'stompjs';
import * as SockJS from 'sockjs-client';
@Component({
    selector: 'my-uploads-item-dialog',
    templateUrl: './item.component.html',
    styleUrls: ['./item.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: absAnimations

})
export class MyUplaodsFileDialogComponent implements OnInit, OnDestroy {
    formSumbitted = false;
    private serverUrl = `${environment.apiUrl}/socket`;
    private stompClient;


    @Input()
    uploader: FileUploader;
    latestFile : any;
    uploadingValue = 0
    videos: any;
    hasBaseDropZoneOver: boolean;
    hasAnotherDropZoneOver: boolean;
    response: string;
    thumbUrl: string = "";
    subCategories: Category[] = [];
    file: File;
    pageType: string;
    fileForm: FormGroup;
    folder: any;
    dialogRef: any;
    action: string;
    visible = true;
    selectable = true;
    removable = true;
    separatorKeysCodes: number[] = [ENTER, COMMA];
    selection = new SelectionModel<any>(true, []);
    subCatSelection = new SelectionModel<any>(true, []);
    contentTypeSelection = new SelectionModel<any>(false, ['nonvideo']);
    tagSelection = new SelectionModel<any>(true, []);
    visibilitySelection = new SelectionModel<any>(false, ['myself']);

    enviorment: any;
    uploading = false;
    categoryCtrl = new FormControl();
    filteredCategories: Observable<Category[]>;
    categories: string[] = [];
    allCategories: Category[] = [];


    visibiliyOptions: any[] = [
        { value: 'provider', name: 'Entire Country', selected: false },
        { value: 'company', name: 'Entire State', selected: false },
        { value: 'department', name: 'My Department', selected: false },
        { value: 'myself', name: 'Just Me', selected: true }
    ];
    contentTypes: ContentType[] = [
        { value: 'all', name: 'All Content Types', selected: false },
        { value: 'video', name: 'Video', selected: false },
        { value: 'nonvideo', name: 'Non Video', selected: true },
        { value: 'mystuff', name: 'My Content', selected: false }

    ];


    @ViewChild('categoryInput') categoryInput: ElementRef<HTMLInputElement>;
    @ViewChild('auto') matAutocompleteCategory: MatAutocomplete;


    tagCtrl = new FormControl();
    filteredTags: Observable<string[]>;
    tags: string[] = [];
    allTags: string[] = ['#properlifting', '#backpain', '#lowerback', '#keywords', '#tags', '#search', '#lowerback'];

    @ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement>;
    @ViewChild('auto1') matAutocompleteTag: MatAutocomplete;

    toggleInArray = AbsUtils.toggleInArray;
    confirmDialogRef: MatDialogRef<AbsConfirmDialogComponent>;


    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {MatDialogRef<MyUplaodsFileDialogComponent>} matDialogRef
     * @param _data
     * @param {MatDialog} _matDialog
     * @param {FileManagerService} _myUploadService
     */
    constructor(
        public matDialogRef: MatDialogRef<MyUplaodsFileDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _matDialog: MatDialog,
        private _myUploadService: FileManagerService,
        private _formBuilder: FormBuilder,
        private _matSnackBar: MatSnackBar,
        private contentService: ContentService,
        private _authService: AuthenticationService,
        private _progressBar: AbsProgressBarService,
    ) {



        this.enviorment = environment;
        this.action = 'add';
        this.uploader = _data.uploader;
        this.file = new File();
        // Set the private defaults
        this._unsubscribeAll = new Subject();

        const headers = [{ name: 'Accept', value: 'application/json' }];


        /* this.uploader = new FileUploader({
            url: 
            disableMultipart: true, // 'DisableMultipart' must be 'true' for formatDataFunction to be called.
            formatDataFunctionIsAsync: true,
            formatDataFunction: async (item) => {
                return new Promise((resolve, reject) => {
                    resolve({
                        name: item._file.name,
                        length: item._file.size,
                        contentType: item._file.type,
                        date: new Date(),
                        file: item
                    });
                });
            }
        }); */


    }

    get f() {
        return this.fileForm.controls;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {

        this.uploader.onBuildItemForm = (fileItem: any, form: any) => {
            form.append('id' , this.f.id.value);
                    console.log("form build",form);
                    console.log("form build",this.f.id.value);
           };

       this.latestFile = this.uploader.queue[0];
        this.contentService.getProductCategories().subscribe(category => {
            this.allCategories = category;
        });


        this._myUploadService.onFilesChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(file => {
                if (file) {
                    this.file = new File(file);
                    this.pageType = 'edit';
                }
                else {
                    this.pageType = 'new';
                    this.file = new File();
                }
                console.log("helloo");
                this.fileForm = this.createFileForm();
                this.initializeWebSocketConnection();
                this.uploader.uploadAll();
  
                console.log(this.f.id);
                console.log(this.f.id.value);

         
            
                this.uploader.onProgressItem = (item, response) => {
                    console.log("progress item", item);
                    console.log("progress response", response);
        
                }
        
                this.uploader.onSuccessItem = (item, response, status) => {
                    const resp = JSON.parse(response);
                    console.log("resp", resp)
                    if (resp) {
                        this._matSnackBar.open("File has been uploaded.", '', {
                            panelClass: 'green-bg',
                            duration: 3000,
                            horizontalPosition: 'right',
                            verticalPosition: 'top',
                            politeness: 'polite',
                          });
                    } else {
                        console.log("error");
                    }
                }
                this.response = '';
        
                this.uploader.response.subscribe(res => {
                    this.response = res
                    this._matSnackBar.dismiss();
                });
        

                if (this.latestFile) {
                    if (this.latestFile._file.type.indexOf("video") != -1) {
                        this.thumbUrl = "assets/images/tiles/default/thumbnail_default_video.png";
                    } else {
                        this.thumbUrl = "assets/images/tiles/default/thumbnail_default_file.png"
                    }
                    this.fileForm.controls.title.setValue(this.latestFile.file.name);
                }


            });


    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    /**
    * Create product form
    *
    * @returns {FormGroup}
    */
    createFileForm(): FormGroup {
        return this._formBuilder.group({
            id: [this.file.id],
            type: [this.file.type],
            title: [this.file.title],
            folder: [this.file.folder],
            source: [this.file.source],
            youtubeTitle: [this.file.youtubeTitle],
            friendlyTitle: [this.file.friendlyTitle],
            handle: [this.file.handle],
            description: [this.file.description],
            categories: [this.file.categories],
            tags: [this.file.tags],
            thumbnail: [this.file.thumbnail],
            dateCreated: [this.file.dateCreated],
            lastEdit: [this.file.lastEdit],
            createdBy: [this.file.createdBy],
            lastUsed: [this.file.lastUsed],
            visibility: [this.file.visibility],
        });
    }

    openThumbnailDialog(): void {
        this.dialogRef = this._matDialog.open(ThumbnailSelectorComponent, {
            panelClass: 'scrumboard-card-dialog',
            width: '100',
            data: {
            }
        });
        this.dialogRef.afterClosed()
            .subscribe(response => {
                this.thumbUrl = response;
            });
    }

    toggleContentType(contentType) {
        this.contentTypeSelection.toggle(contentType.value);
        contentType.selected = !contentType.selected
        this.searchContent();
    }

    toggleOwner(option){
        this.visibilitySelection.select(option.value);
        option.selected = !option.selected
    }

    saveDocument() {

        this._matSnackBar.open("Saving...", '', {
            horizontalPosition: 'right',
            verticalPosition: 'top',
            politeness: 'polite',
        });

        this._progressBar.show();
        this.formSumbitted = true;
        var content = new Content({})
        content.title = this.f.title.value,
        content.companyID = this._authService.currentUserValue.companyId,
        content.providerID = this._authService.currentUserValue.providerId,
        content.profileID = this._authService.currentUserValue.profileId,
        content.description = this.f.description.value,
        content.sku = "",
        content.fileName = this.latestFile.file.name,
        content.categoryList = this.selection.selected,
        content.english = true,
        content.itemCode = "TEST",
        content.prodTypeID = this.latestFile.file.type.indexOf("video") != -1 ? 15 : 7,
        content.thumbnail = this.thumbUrl,
        content.visible = this.visible,
        content.ownerType = this.visibilitySelection.selected[0],
        content.departmentID = this._authService.currentUserValue.department,
        this.contentService.insertContent(content).subscribe(res => {
            this._progressBar.hide();
            if (res) {
                this._matSnackBar.dismiss();
                this.matDialogRef.close();
                this.uploading = true;
            }
        })
    }

    toggleSelection(category) {
        category.selected = !category.selected;
        this.selection.toggle(category.id);
        if (category.selected) {
            if (category.subCategories) {
                category.subCategories.map(c => c.parent = category.id);
                this.subCategories = this.subCategories.concat(category.subCategories);
            }
        } else {
            let result = this.subCategories.filter(el => el.parent != category.id);
            this.subCategories = result;
        }
        console.log("sub", this.subCategories);
        this.searchContent();
    }

    subCategorySelected(category) {
        category.selected = !category.selected;
        this.selection.toggle(category.id);
        this.searchContent();
    }

    closeAndSave() {
        this.saveDocument();
    }

    searchContent() {

        this._progressBar.show();
        var search = {
            searchType: 'video',
            categories: this.selection.selected,
            tags: this.tagSelection.selected,
            providerId: this._authService.currentUserValue.providerId,
            companyId: this._authService.currentUserValue.companyId,
            profileId: this._authService.currentUserValue.profileId
        };

        this.contentService.searchProductsByCateogories(search).subscribe(result => {
            this._progressBar.hide();
            this.videos = of(result);
            if (result && result.length > 0) {
                var productids = result.map(x => {
                    return x.id;
                });
                this.contentService.getTags(productids).subscribe(tags => {
                    this.tags = tags;
                });
            } else {
                //this.tags = [];
            }
        })

    }

    onImageSelect(video) {
        if (video.prodType == 'VIDEO') {
            this.thumbUrl = this.enviorment.cdnUrl + "/" + this.enviorment.thumbDir + "/T_" + video.itemCode + ".jpg"
        } else {
            this.thumbUrl = "assets/icons/" + video.image;
        }
    }

    initializeWebSocketConnection() {
        let ws = new SockJS(this.serverUrl);
        this.stompClient = Stomp.over(ws);
        let that = this;
        this.stompClient.connect({}, function (frame) {
          that.stompClient.subscribe("/file/upload/"+that.f.id.value, (message) => {
            if (message.body) {
                console.log("value",message.body)
                that.uploadingValue = message.body;
            }
         });
    
        });
      }
}



