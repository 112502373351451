import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { absAnimations } from '@abs/animations';
import { Subject } from 'rxjs';
import { AbsNavigation } from '@abs/types';
import { AbsNavigationService } from '@abs/components/navigation/navigation.service';
import { AbsConfigService } from '@abs/services/config.service';
import { TourService } from 'ngx-tour-md-menu';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: absAnimations
})
export class ContentComponent implements OnInit {

  navigation: AbsNavigation[] = [
     {
      id: 'content',
      title: 'Courses',
      translate: 'NAV.APPLICATIONS',
      type: 'item',
      url: 'all'
    },
    /*{
      id: 'supplementals',
      title: 'Supplementals',
      translate: 'NAV.APPLICATIONS',
      type: 'item',
      url: 'supplementals'
    }, */
    {
      id: 'playlists',
      title: 'Playlist Manager',
      translate: 'NAV.APPLICATIONS',
      type: 'item',
      url: 'playlists'
    },
    /* {
      id: 'playlist-manager',
      title: 'Playlist Manager',
      translate: 'NAV.APPLICATIONS',
      type: 'item',
      url: 'playlist-manager'
    },  {
      id: 'my-uploads',
      title: 'My Uploads',
      translate: 'NAV.APPLICATIONS',
      type: 'item',
      url: 'my-uploads'
    }*/];

  constructor(private _absNavigationService: AbsNavigationService,
    private _absConfigService: AbsConfigService, private tourService: TourService) { }

  ngOnInit() {
   /* this.tourService.initialize(
      [
         {
          anchorId: 'videos',
          content: 'This is help information for videos',
          title: 'Videos',
          enableBackdrop: true,
          prevBtnTitle: 'Prev',
          nextBtnTitle: 'Next',

        },
        {
          anchorId: 'supplementals',
          content: 'This is help information for supplementals',
          title: 'Supplementals'
        }, 
        {
          anchorId: 'playlists',
          content: 'This is help information for playlist',
          title: 'Playlists',
          enableBackdrop: true,
          prevBtnTitle: 'Prev',
          nextBtnTitle: 'Next',

        } ,
        {
          anchorId: 'playlist-manager',
          content: 'This is a step from the eager loaded app module in the home component',
          title: 'Playlist Manager'
        } 
      ]
    );*/

    /* if (localStorage.getItem("help") === "0") {
      setTimeout(() => {
        console.log("hello i m in");
        this.tourService.start();
        localStorage.setItem("help", "1");
      }, 2000);
    } */
  }


}
