import {Component, OnInit, ViewEncapsulation, ViewChild, Output, EventEmitter} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import {absAnimations} from '@abs/animations';
import { AmazingTimePickerService } from 'amazing-time-picker';
import { AssignPreviewComponent } from './assign-preview/assign-preview.component';
import { ActivatedRoute, Router } from '@angular/router';
import { PlayList } from '../_models/playlist';
import { Group } from '../../user/groups/groups-all/group.model';
import { Playlist } from '../../content/playlists/playlists.model';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-assign',
  templateUrl: './assign.component.html',
  styleUrls: ['./assign.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: absAnimations
})
export class AssignComponent implements OnInit {

  @ViewChild("groups", { static: false }) groups: AssignPreviewComponent;
  @ViewChild("playlists", { static: false }) playlists: AssignPreviewComponent;

  @Output() selectedContents = new EventEmitter<Playlist[]>();
  @Output() selectedUsers = new EventEmitter<Group[]>();

  environment: any;
  playlistContent = true;
  step = 0;
  classroom: FormGroup;
  individual: FormGroup;

  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param { FormBuilder} _formBuilder
   * @param { AmazingTimePickerService} _atp
   */
  constructor(
    private _formBuilder: FormBuilder,
    private _atp: AmazingTimePickerService,
    private route: ActivatedRoute,
    private router: Router
  )
  {
    this.environment = environment;
    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit()
  {
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void
  {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }


  onClassRoomSubmit() {
    console.log(this.classroom.value);
  }

  onindIvidualSubmit() {
    console.log(this.individual.value);
  }

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  swap(){
    if(this.playlistContent){
      this.playlistContent = false;
    }else{
      this.playlistContent = true;
    }
   }

}
