import { Component, OnInit, Input } from '@angular/core';
import { timer } from 'rxjs';
import { map, takeWhile } from 'rxjs/operators';

@Component({
  selector: 'app-timer-countdown',
  templateUrl: './timer-countdown.component.html',
  styleUrls: ['./timer-countdown.component.css']
})
export class TimerCountdownComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  @Input() seconds = 300;

  timeRemaining$ = timer(0, 1000).pipe(
    map(n => (this.seconds - n) * 1000),
    takeWhile(n => n >= 0),
  );

}
