import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PricingCardComponent } from './pricing-card/pricing-card.component';
import { CheckoutViewsWidgetComponent } from './checkout-views-widget/checkout-views-widget.component';
import { SWIPER_CONFIG, SwiperConfigInterface, SwiperModule } from 'ngx-swiper-wrapper';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatMenuModule } from '@angular/material/menu';
import { MatRippleModule } from '@angular/material/core';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDividerModule } from '@angular/material/divider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatTreeModule } from '@angular/material/tree';
import { MatStepperModule } from '@angular/material/stepper';
import { AbsWidgetModule } from '@abs/components';
import { ContentModule } from '../../layout/components/content/content.module';
import { MatSortModule } from '@angular/material/sort';
import { AbsDirectivesModule } from '@abs/directives/directives';
import { MatBadgeModule } from '@angular/material/badge';
import { AbsPipesModule } from '@abs/pipes/pipes.module';
import { MaterialTimeControlModule } from '@abs/components/material-time-control.module';
import { MatSliderModule } from '@angular/material/slider';
import { ColorPickerModule } from 'ngx-color-picker';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { AmazingTimePickerModule } from 'amazing-time-picker';
import { FormsModule } from '@angular/forms';
import { AbsSharedModule } from '@abs/shared.module';
import { ShoppingExperienceDialogComponent } from './shopping-experience-dialog/shopping-experience-dialog.component';
import { TimerCountdownComponent } from './timer-countdown/timer-countdown.component';
const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto'
};
@NgModule({
  declarations: [PricingCardComponent, CheckoutViewsWidgetComponent, ShoppingExperienceDialogComponent, TimerCountdownComponent],
  imports: [
    CommonModule,
    AbsSharedModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatRadioModule,
    MatTooltipModule,
    MatSelectModule,
    MatCheckboxModule,
    MatMenuModule,
    MatRippleModule,
    MatTableModule,
    MatToolbarModule,
    MatListModule,
    MatExpansionModule,
    MatDividerModule,
    MatSnackBarModule,
    MatTabsModule,
    MatButtonToggleModule,
    MatSidenavModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    MatChipsModule,
    MatDatepickerModule,
    MatTreeModule,
    MatStepperModule,
    AbsWidgetModule,
    ContentModule,
    MatSortModule,
    MatProgressSpinnerModule,
    AbsDirectivesModule,
    MatBadgeModule,
    AbsPipesModule,
    SwiperModule,
    MaterialTimeControlModule,
    MatSliderModule,
    FormsModule,
    // TP
    ColorPickerModule,
    NgxDatatableModule,
    AmazingTimePickerModule,
  ],

  /* schemas: [
    NO_ERRORS_SCHEMA
  ], */
  providers: [{
    provide: SWIPER_CONFIG,
    useValue: DEFAULT_SWIPER_CONFIG
  }
  ],
  exports:[
    PricingCardComponent,CheckoutViewsWidgetComponent,ShoppingExperienceDialogComponent,TimerCountdownComponent
  ]
})
export class WidgetsModule { }
