import {Component, OnInit, Inject,ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { WizardDialogComponent } from './wizard-dialog/wizard-dialog.component';
import { CheckoutDialogComponent } from './checkout-dialog/checkout-dialog.component';
import { ShoppingCartService } from './cart.service';


@Component({
  selector: 'auto-training',
  templateUrl: './auto-training.component.html',
  styleUrls: ['./auto-training.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AutoTrainingComponent implements OnInit {

  images: any;
  playListForm: FormGroup;
  dialogRef:any;
  checkoutDialogRef:any;

  cartState$ = this.shoppingCartService.state$;
  constructor(
    private _formBuilder: FormBuilder, public _matDialog: MatDialog,public _matCheckoutDialog: MatDialog,private shoppingCartService: ShoppingCartService)
   {

    this.playListForm = this.createPlaylistForm();
  }

  createPlaylistForm(): FormGroup {
    return this._formBuilder.group({
    });
  }
  openAutoTraningDialog(): void
  {
      this.dialogRef = this._matDialog.open(WizardDialogComponent, {
          panelClass: 'contact-form-dialog',
        //  disableClose: true,
          width: '1600px',
          height : 'auto',
          data      : {
          }
      });
      this.dialogRef.afterClosed()
          .subscribe(response => {

          });
  }

  openCheckoutDialog(): void
  {
      this.checkoutDialogRef = this._matCheckoutDialog.open(CheckoutDialogComponent, {
          panelClass: 'contact-form-dialog',
         // disableClose: true,
          width: '1600px',
          //height : '700px',
          minHeight:'600px',
          data      : {
          }
      });
      this.checkoutDialogRef.afterClosed()
          .subscribe(response => {

          });
  }
  ngOnInit() {
  }
}
