import { Component, OnInit, Output, EventEmitter, Input, ViewEncapsulation, OnDestroy } from '@angular/core';
import { absAnimations } from '@abs/animations';
import { Quiz } from '../../../quiz/models';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { Question } from 'app/main/content/quiz/models/question';
import { QuestionData } from '../quiz-question-summary/quiz-question-summary.component';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-quiz-question-form',
  templateUrl: './quiz-question-form.component.html',
  styleUrls: ['./quiz-question-form.component.scss'],
  animations: absAnimations,
  encapsulation: ViewEncapsulation.None
})
export class QuizQuestionFormComponent implements OnInit, OnDestroy {
  triggerFormUpdates = false
  answerSelected = false;
  flip: any;
  answersForm: FormGroup;
  mask :string = "00:00"
  question: Question;
  editMode = false;
  type = 'text'
  @Input()
  fileType: any = 'NON_VIDEO'
  @Input()
  questionType: any;
  placesholder = "00:00"
  @Input()
  editQuestionObj: QuestionData;

  @Input()
  productType: any;

  @Output() onBackButton = new EventEmitter<any>();
  @Output() onSelection = new EventEmitter<any>();
  @Output() onDone = new EventEmitter<any>();
  private _unsubscribeAll: Subject<any>;

  constructor(private _formBuilder: FormBuilder,
  ) {
    this._unsubscribeAll = new Subject();

    this.answersForm = this._formBuilder.group({
      answers: this._formBuilder.array([])
    });
    this.question = new Question(null);
    
  }

  get answers() {
    return this.answersForm.controls["answers"] as FormArray;
  }
  ngOnInit(): void {
    this.answers.clear();

    if(this.productType == 'VIDEO'){
      this.type = 'text';
      this.mask = '00:00'
    }else{
      this.type = 'number';
      this.placesholder = ''
      this.mask = ''
    }

    console.log("mask",this.mask)

    this.answersForm.valueChanges
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(val => {
        console.log("value changes", val);
        if (this.triggerFormUpdates) {
          var length = this.answers.length;
          if (this.answers.value[length - 1].answer != "") {
            this.addAnswer('', false);
          }
        }
      })

    if (this.editQuestionObj && this.editQuestionObj.question) {
      console.log("this.editQuestionObj", this.editQuestionObj);
      this.question = new Question(this.editQuestionObj.question);
      this.question.answers = [];
      this.editQuestionObj.question.answers.forEach(element => {
        this.addAnswer(element.answer, element.correct);
      });
      this.editMode = true;
      this.answerSelected = true;
      // this.triggerFormUpdates = true;
    } else {


      if (this.questionType == 'M') {
        this.addAnswer('', false);
        this.addAnswer('', false);
        this.addAnswer('', false);
        this.addAnswer('', false);
      } else if (this.questionType == 'T') {
        this.addAnswer('True', false);
        this.addAnswer('False', false);
        this.addAnswer('', false);
      } else if (this.questionType == 'A') {
        this.addAnswer('I Agree and Understand', false);
        this.addAnswer('I Agree, But Have Questions', false);
        this.addAnswer('I Understand, But Disagree', false);
        this.addAnswer('', false);
      }
      this.triggerFormUpdates = true;
    }


  }
  descClicked(abc) {

  }
  onContinue() {
    this.question.answers = [];
    this.answers.value.forEach(element => {
      if (element.answer != "" && element.answer.length > 0) {
        this.question.answers.push(element)
      }
    });
    if (this.editMode) {
      this.onDone.next({ value: this.question, index: this.editQuestionObj.index, type: 'edit' })
    }
    else {
      this.onDone.next({ value: this.question, index: 0, type: 'add' })
    }
  }
  handleBack() {
    if(this.editMode){
      this.onBackButton.next("go-to-summary")
    }else{
    this.onBackButton.next("question-type")
    }
  }

  deleteQuestion(){
    this.onDone.next({ value: this.question, index: this.editQuestionObj.index, type: 'delete' })

  }


  addAnswer(value, correct): void {
    this.answers.push(this.createAddAnswer(value, correct));
  }
  createAddAnswer(value, correct): FormGroup {
    return this._formBuilder.group({
      answer: [value, { validators: [Validators.required] }],
      correct: [correct]

    });
  }
  handleCorrect(form: FormGroup) {
    this.answers.controls.forEach(value => { value.setValue({ answer: value.value.answer, correct: false }) });
    form.controls.correct.setValue(true);
    this.answerSelected = true;
    this.answersForm.updateValueAndValidity();
    this.answers.updateValueAndValidity();
  }
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}