import { Component, OnInit } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { GroupsService } from '../../../user/groups/groups.service';
import { AuthenticationService } from '../../../authentication/_services';

@Component({
  selector: 'app-group-item-selector',
  templateUrl: './group-item-selector.component.html',
  styleUrls: ['./group-item-selector.component.scss']
})
export class GroupItemSelectorComponent implements OnInit {

  selection = new SelectionModel<any>(true, []);
  groups: any;
  flip:any;
  constructor(private _groupService: GroupsService, 
    public _authService: AuthenticationService) { }

  ngOnInit(): void {
    this._groupService.getGroups().then(res => {
      if (res) {
        this.groups = res.filter(group => group.groupType != 'SYSTEM');
      }
    })
  }
  descClicked(value) {
    this.flip = value;
  }
}
