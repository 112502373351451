import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import * as moment from 'moment';
import { SelectService } from '../../../service/select.service';
import { SharedData } from '../assign-wizard-reborn.component';
import { SelectionModel } from '@angular/cdk/collections';
import { AutomatedSchedule, Frequency } from '../../assign-wizard/assign-wizard.component';
import { TrainingService } from '../../_services/training.service';
import { absAnimations } from '@abs/animations';

@Component({
  selector: 'app-classroom-training-wizard',
  templateUrl: './classroom-training-wizard.component.html',
  styleUrls: ['./classroom-training-wizard.component.scss'],
  animations: absAnimations
})
export class ClassroomTrainingWizardComponent implements OnInit {

  length: any;
  selectedDate: any;
  startTime: any;
  endTime: any;
  location: any;
  locations: any;

  @Input()
  step = 1;

  endTimeString = "";
  ordinal = ['', 'First', 'Second', 'Third', 'Fourth', 'Last']

  minDate: any;
  minEndDate: any;
  maxDate: any;
  frequencyList: any;
  frequency: any;
  autoRepeat: any;
  frequencySelction = new SelectionModel<Frequency>(false, []);
  automatedSchedule: any;
  occurance;
  @Input()
  trainingType: any = "SCHEDULE";

  @Input()
  selectedPlaylist: any;
  totalEstimatedTime: any = 0;
  flip: any;

  @Input()
  default: SharedData;

  @Output() onBackButton = new EventEmitter<any>();
  @Output() onDone = new EventEmitter<any>();
  @Output() onSelection = new EventEmitter<any>();

  constructor(private _selectService: SelectService, private trainingService: TrainingService) { }

  ngOnInit(): void {
    console.log("trainingType", this.trainingType);

    if (this.default) {
      this.startTime = this.default.startTime;
      this.endTime = this.default.endTime;
      this.location = this.default.location;
      if (this.default.step) {
        this.step = this.default.step;
      }
      if (this.default.contents) {
        var totalCourseCount = 0;
        this.default.contents.map(content => {

          this.totalEstimatedTime = this.totalEstimatedTime + content.runningTime;
        })
        this.totalEstimatedTime = this.totalEstimatedTime + (this.default.contents.length * 5);
      }
      console.log("default", this.default);
      if (this.default.trainingType) {
        this.trainingType = this.default.trainingType;
      }
      if (this.default.selectedDate) {
        this.selectedDate = this.default.selectedDate;

        var date = this.selectedDate;
        var week = this.weekOfMonth(date);
        this.frequencyList = [
          { value: "D", text: "Daily" },
          { value: "W", text: "Weekly on " + date.format('dddd') },
          { value: "M", text: "Monthly on the " + week + " " + date.format('dddd') },
          { value: "Q", text: "Quarterly on the " + week + " " + date.format('dddd') },
          { value: "S", text: "Semi Annually on the " + week + " " + date.format('dddd') },
          { value: "A", text: "Annually on the " + week + " " + date.format('dddd') + " of " + date.format('MMMM') }
        ];
        this.frequency = this.default.frequency;
        this.frequencyList.forEach(element => {
          if (element.value == this.frequency.value) {
            this.frequencySelction.select(element);
          }
        });
        this.automatedSchedule = [];
        var newDate = moment(this.selectedDate).format('YYYY-MM-DD');;
        this.trainingService.getAutomatedSchedule(newDate, newDate, this.frequency.value, this.selectedDate.format('dddd').toUpperCase(), this.occurance, this.default.contents).then(result => {
          this.automatedSchedule = result;
        })
      }
      if (this.default.repeat) {
        this.autoRepeat = this.default.repeat;
      }
    }
    this._selectService.getLocationsAll().subscribe(results => {
      this.locations = results;
    })
    this.minDate = moment();
    this.maxDate = moment().add(1, 'days');

  }
  startTimeChanged(event) {
    this.startTime = event;

    const hours = Math.floor(this.totalEstimatedTime / 60);
    const minutesLeft = (Math.round((this.totalEstimatedTime % 60) * 100) / 100).toFixed();
    const breakTimeInMinutes = hours >= 2 ? hours * 10 : 0;

    var st = moment(this.formatTime(this.startTime), "hh:mm A");

    var et = moment(st, "hh:mm A").add(hours, 'hours').add(minutesLeft, 'minutes').add(breakTimeInMinutes, 'minutes');
    const remainder = 15 - (et.minute() % 15);
    et = moment(et, "hh:mm A").add(remainder, "minutes")
    this.endTimeString = et.format("hh:mm A");
    this.endTime = { hour: et.format('h'), minute: et.minutes() + "", meriden: (et.hour()) >= 12 ? 'PM' : 'AM', format: this.startTime.format }

    var st1 = moment(this.formatTime(this.startTime), "hh:mm A");
    var et1 = moment(this.formatTime(this.endTime), "hh:mm A")
    var duration = moment.duration(et1.diff(st1));
    this.length = duration.asMinutes();
    this.onSelection.emit({ startTime: this.startTime, endTime: this.endTime, length: this.length });

  }

  endTimeChanged(event) {
    this.endTime = event;
    // this.step++;
    var st = moment(this.formatTime(this.startTime), "hh:mm A");
    var et = moment(this.formatTime(this.endTime), "hh:mm A")
    var duration = moment.duration(et.diff(st));
    this.length = duration.asMinutes();
    this.onSelection.emit({ endTime: this.endTime, length: this.length });

  }
  formatTime(time) {
    if (time) {
      return `${this.formatMinute(time.hour)}:${this.formatMinute(time.minute)} ${time.meriden}`
    } else {
      return "";
    }
  }

  handleBack() {
    this.onBackButton.emit('classroom');
  }
  trainingDone(value) {
    this.autoRepeat = value;
    this.onDone.emit({
      length: this.length,
      startTime: this.startTime,
      endTime: this.endTime,
      location: this.location,
      selectedDate: this.selectedDate,
      automatedSchedule: this.automatedSchedule,
      
      frequency: this.frequency,
      freqDay: this.selectedDate.format('dddd').toUpperCase(),
      freqWeek: this.occurance,
      repeat: this.autoRepeat,
      trainingType: this.trainingType
    });
  }

  onDateSelect(event) {
    this.selectedDate = event;
    var date = event;

    var week = this.weekOfMonth(date);
    this.frequencyList = [
      { value: "D", text: "Daily" },
      { value: "W", text: "Weekly on " + date.format('dddd') },
      { value: "M", text: "Monthly on the " + week + " " + date.format('dddd') },
      { value: "Q", text: "Quarterly on the " + week + " " + date.format('dddd') },
      { value: "S", text: "Semi Annually on the " + week + " " + date.format('dddd') },
      { value: "A", text: "Annually on the " + week + " " + date.format('dddd') + " of " + date.format('MMMM') }
    ];

    this.step++;
    this.onSelection.emit({ selectedDate: this.selectedDate });

  }
  weekOfMonth(input) {
    const firstDayOfMonth = input.clone().startOf('month');
    const firstDayOfWeek = firstDayOfMonth.clone().startOf('week');

    const offset = firstDayOfMonth.diff(firstDayOfWeek, 'days');
    this.occurance = Math.ceil((input.date()) / 7);
    return this.ordinal[this.occurance];
  }

  setFrequency(freq) {
    const self = this;
    const value = freq.value;
    this.frequencySelction.select(freq);
    this.automatedSchedule = [];
    this.frequency = freq;
    var date = moment(this.selectedDate);
    var newDate = date;
    this.trainingService.getAutomatedSchedule(date, date, value, this.selectedDate.format('dddd').toUpperCase(), this.occurance, this.default.contents).then(result => {
      this.automatedSchedule = result;
    })
    if (this.trainingType == 'FREQUENT') {
      this.step = 6;
    } else {
      this.step = 5;
    }
  }

  setAutoRepeat(value) {
    this.trainingDone(value);
  }

  formatMinute(unit): string {
    //console.log(unit);
    if (typeof unit == 'string') {
      console.log("string unit", unit);
      if (unit.length == 1) {
        return '0' + String(unit);
      } else {
        return String(unit);
      }
    } else {
      if (unit === 0) {
        return '00';
      } else if (unit < 10) {
        return '0' + String(unit);
      } else {
        return String(unit);
      }
    }
  }
  descClicked(value) {
    this.flip = value;
  }
}
const nthDayOfMonth = (monthMoment, day, weekNumber) => {
  let m = monthMoment.clone()
    .startOf('month')                     // go to the beginning of the month
    .day(day)

  //if (m.month() !== monthMoment.month()) m.add(7, 'd');
  return m.add(7 * (weekNumber - 1), 'd')
}