export class Category{
  id: number;
  name: string;
  selected: boolean;
  parent?: number
  subCategories: Category[];
  constructor(cat) {
    this.id = cat.id;
    this.name = cat.name;
    this.selected = cat.selected || true;
    this.subCategories = cat.subCategories;
    this.parent = cat.parent;
  }
}


export class ContentType{
  value:string;
  name: string;
  selected: boolean;
  constructor(value,name,selected) {
    this.value = value;
    this.name = name;
    this.selected = selected || true;
  }
}


export class Content {
  public newItemNumber: string;
  public  title: string;
  public url: string;
  public  image: string;
  public  thumbnail: string;
  public companyID: number;
  public providerID: number;
  public profileID: number;
  public  type: string;
  public likes: string;
  public runningTime: number;
  public duration: number;
  public description: string;
  public aspect: string;
  public aspectRatio: number;
  public views: number;
  public spanish: boolean;
  public english: boolean;
  public language: string;
  public itemCode:string;
  public id: string;
  public prodType: string;
  public prodTypeID: number;
  public fileName:string;
  public keywords: string;
  public rating: number;
  public vendorID: number;
  public producerID: number;
  public sku: string;
  public showActionBtn: boolean;
  public active: boolean;
  public categoryList: any[];
  public private : boolean;
  public visible: boolean;
  public ownerId: number;
  public ownerType: string;
  public departmentID: number;
  public tags: any[];
  public videoHeight: number;
  public videoWidth: number;



  public productCategories:Category[];

  constructor(content) {
    this.newItemNumber = content.newItemNumber;
    this.title = content.title;
    this.url = content.URL;
    this.image = content.image;
    this.type = content.type;
    this.likes = content.likes;
    this.runningTime = content.runningTime;
    this.description = content.description;
    this.aspect = content.aspect;
    this.views = content.views;
    this.language = content.language;
    this.itemCode = content.itemCode;
    this.id = content.id;
    this.aspectRatio = content.aspectRatio;
    this.prodType = content.prodType;
    this.fileName= content.fileName;
    this.productCategories = content.productCategories || [];
    this.keywords = content.keywords;
    this.rating = content.rating;
    this.english = content.english;
    this.spanish = content.spanish;
    this.vendorID = content.vendorID;
    this.producerID = content.producerID;
    this.sku = content.sku;
    this.showActionBtn = content.showActionBtn;
    this.duration = content.duration;
    this.thumbnail= content.thumbnail;
    this.companyID = content.companyID;
    this.providerID = content.providerID;
    this.profileID = content.profileID;
    this.private = content.isPrivate;
    this.active = content.active;
    this.tags = content.tags;
    this.videoHeight = content.videoHeight;
    this.videoWidth = content.videoWidth;
  }


  

}


