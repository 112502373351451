import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { QuizService } from '../quiz/services/quiz.service';
import { absAnimations } from '@abs/animations';
import { Quiz } from '../quiz/models';
import { Content } from '../content-list.model';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivityLog, ActivityType, ActivityLogService } from '../../service/activity-log.service';

@Component({
  selector: 'app-trainer-quiz-preview',
  templateUrl: './trainer-quiz-preview.component.html',
  styleUrls: ['./trainer-quiz-preview.component.scss'],
  providers: [QuizService],
  encapsulation: ViewEncapsulation.None,
  animations: absAnimations
})
export class TrainerQuizPreviewComponent implements OnInit {
  quiz: Quiz = new Quiz(null);
  mode = 'quiz';
  content: Content;
  spanishQuiz: any;
  initialScreen = true;
  showAnswer = false;
  lang = 'en'
  pager = {
    index: 0,
    size: 1,
    count: 1
  };

  constructor(private quizService: QuizService, 
    private activityLog: ActivityLogService,
    public matDialogRef: MatDialogRef<TrainerQuizPreviewComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any) {
    this.content = _data.content;
    this.lang = _data.lang;

    
  }

  ngOnInit() {
    console.log("content quiz", this.content);
    this.spanishQuiz = this.lang == 'es' ? true :false;
    this.loadQuiz(this.content.id);


    let activityLog = new ActivityLog();
    activityLog.profileID = JSON.parse(localStorage.getItem("currentUser")).profileId
    activityLog.type = "site";
    activityLog.source = "itrain";
    activityLog.activity = ActivityType.VIEW_QUIZ;
    activityLog.videoID = this.content.id;
    this.activityLog.saveActivityLog(activityLog).subscribe(res => { });
  }

  loadQuiz(contentId) {
    this.quizService.getQuizDetail(contentId,this.spanishQuiz).subscribe(res => {
      if (res) {
        this.quiz = new Quiz(res);
        console.log(this.quiz);
        this.pager.count = this.quiz.questions.length;
      }
    });
    this.mode = 'quiz';
  }
  get filteredQuestions() {
    return (this.quiz.questions) ?
      this.quiz.questions.slice(this.pager.index, this.pager.index + this.pager.size) : [];
  }
  getNextLetter(num): String {
    var code: number = "ABCDEFGHIJKLMNOPQRST".charCodeAt(num);
    return String.fromCharCode(code);
  }
  goTo(index: number) {
    this.showAnswer = false;
    if (index >= 0 && index < this.pager.count) {
      this.pager.index = index;
    }
  }
}



