import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { User } from '../models/user';
import { AccountProfile } from '../authentication/_models/accountprofile';


export class UISelect {
    key: number;
    value: string;
}

const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })
};

@Injectable({ providedIn: 'root' })
export class SelectService {
    constructor(private http: HttpClient) { }

    getDepartmentsAll() {
        return this.http.get<UISelect[]>(`${environment.apiUrl}/list/departments`).pipe(
            catchError(this.handleError)
        );;
    }

    getStates() {
        return this.http.get<UISelect[]>(`${environment.apiUrl}/list/states`).pipe(
            catchError(this.handleError)
        );;
    }
    getPositions() {
        return this.http.get<UISelect[]>(`${environment.apiUrl}/list/positions`).pipe(
            catchError(this.handleError)
        );;
    }


    getLocationsAll() {
        return this.http.get<any[]>(`${environment.apiUrl}/location/company/` + JSON.parse(localStorage.getItem("currentUser")).companyId).pipe(
            catchError(this.handleError)
        );;
    }

    getLocationsByCompanyId(companyId) {
        return this.http.get<any[]>(`${environment.apiUrl}/location/company/` + companyId).pipe(
            catchError(this.handleError)
        );;
    }

    getNonTrainingLocations() {
        return this.http.get<any[]>(`${environment.apiUrl}/location/nontraining/` + JSON.parse(localStorage.getItem("currentUser")).companyId).pipe(
            catchError(this.handleError)
        );;
    }

    handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
                `Backend returned code ${error.status}, ` +
                `body was: ${error.error}`);
        }
        // return an observable with a user-facing error message
        return throwError(
            'Something bad happened; please try again later.');
    };
}
