import { NgModule } from '@angular/core';

import { KeysPipe } from './keys.pipe';
import { GetByIdPipe } from './getById.pipe';
import { HtmlToPlaintextPipe } from './htmlToPlaintext.pipe';
import { FilterPipe } from './filter.pipe';
import { CamelCaseToDashPipe } from './camelCaseToDash.pipe';
import { PhonePipe } from './phone.pipe';
import { DurationPipe } from './duration.pipe';
import { SecondsToDurationPipe } from './seconds-to-duration.pipe';
import { ShortNumberPipe } from './short-number.pipe';

@NgModule({
    declarations: [
        KeysPipe,
        GetByIdPipe,
        HtmlToPlaintextPipe,
        FilterPipe,
        CamelCaseToDashPipe,
        PhonePipe,
        DurationPipe,
        SecondsToDurationPipe,
        ShortNumberPipe
    ],
    imports     : [],
    exports     : [
        KeysPipe,
        GetByIdPipe,
        HtmlToPlaintextPipe,
        FilterPipe,
        CamelCaseToDashPipe,
        PhonePipe,
        DurationPipe,
        SecondsToDurationPipe,
        ShortNumberPipe
    ]
})
export class AbsPipesModule
{
}
