import { Component, OnInit, Input, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { QuizComponent } from '../content/quiz/quiz.component';
import { Content } from '../content/content-list.model';
import { AuthenticationService } from '../authentication/_services';
import { UserLevel } from '../models/user';
import { TrainingService } from '../training/_services/training.service';
import { VideoLog } from '../content/video-log.model';
import { DOCUMENT } from '@angular/common';
declare var jQuery: any;

@Component({
  selector: 'app-document-viewer-dialog',
  templateUrl: './document-viewer-dialog.component.html',
  styleUrls: ['./document-viewer-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DocumentViewerDialogComponent implements OnInit {


  url: any;
  title: any;
  content:any;
  quizDialogRef: any;
  parent: any;
  profileId: any;
  videoLogId:any;
  learner = false
  doneCourses
  totalCourses
  timerId
  isPdf = false;
  isOffice = false;
  isImage = false;
  images= [];
  classId= 0
  

  constructor(public matDialogRef: MatDialogRef<DocumentViewerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,private quizDialog: MatDialog, private _authSevice:AuthenticationService,private trainingService: TrainingService,@Inject(DOCUMENT) _document: Document) {
    this.url = _data.url
    this.title = _data.title
    this.content = _data.content
    this.parent = _data.parent
    this.profileId = _data.profileId
    this.doneCourses = _data.doneCourses
    this.totalCourses = _data.totalCourses
    this.classId = _data.classId
    console.log(this.url);
     // this.url = "https://effigis.com/wp-content/uploads/2015/02/DigitalGlobe_WorldView2_50cm_8bit_Pansharpened_RGB_DRA_Rome_Italy_2009DEC10_8bits_sub_r_1.jpg";
    if(this.url.indexOf(".pdf")!=-1){
      this.isPdf = true;
    }

    if(this.url.indexOf(".doc")!=-1 || this.url.indexOf(".csv")!=-1 || this.url.indexOf(".docx")!=-1 || this.url.indexOf(".pptx")!=-1 ||  this.url.indexOf(".ppt")!=-1 || this.url.indexOf(".xls")!=-1 ||  this.url.indexOf(".xlsx")!=-1)
     {
      this.isOffice = true;
    }

    if(this.url.indexOf(".jpeg")!=-1 || this.url.indexOf(".tiff")!=-1 || this.url.indexOf(".tif")!=-1  || this.url.indexOf(".jpg")!=-1 || this.url.indexOf(".png")!=-1 || this.url.indexOf(".bmp")!=-1)

    {
      this.images.push(this.url);
      this.isImage = true;
    }
  }




  ngOnInit(): void {

   
  
    this.learner = this.profileId > 0 || this._authSevice.currentUserValue.userLevel == UserLevel.LEARNER
    if(this.profileId > 0 && this.parent != ''){


      var videoLog = new VideoLog({
        profileId: this.profileId,
        productId: this.content.id,
        itemName: this.content.title,
        comments: this.parent,
        totalViewed:100,
        classId: this.classId

      });
      this.trainingService.insertVideoLog(videoLog).then(res => {
        this.videoLogId = res;
        console.log("videolog", res);
      });

 
    }
   //this.timerId = setInterval(()=> { this.reloadIFrame() }, 4 * 1000);
     console.log("this.time",this.timerId)
  }
  reloadIFrame() {
    console.log("reloading");
  jQuery("#iframe").attr("src",jQuery("#iframe").attr("src"))
}

  conditionalPdfIFrameReloadTimeout() {
    console.log("hello",jQuery('#iframe'));
  
}

  onStartQuiz(){


    this.quizDialogRef = this.quizDialog.open(QuizComponent, {
      id: 'quiz',
      backdropClass: 'previewBackdrop',
      width: '1165px',
      disableClose: true,
      data: {
        content: new Content({ id: this.content.id, videoLogId: this.videoLogId, title: this.content.title }),
        spanishQuiz: this.content.spanish,
        parent: this.parent,
        profileId: this.profileId,
        doneCourses: this.doneCourses,
        totalCourses:this.totalCourses,
        classId: this.classId
     
      }
    });

    this.quizDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.matDialogRef.close(true)
      }
    });

  }

}
