import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { QuizComponent } from '../../quiz/quiz.component';
import { Content } from '../../../training/_models/playlist';
import { YouTubePlayer } from '@angular/youtube-player';
import { VideoLog } from '../../video-log.model';
import { TrainingService } from '../../../training/_services/training.service';
import { ActivityLog, ActivityType, ActivityLogService } from '../../../service/activity-log.service';

@Component({
  selector: 'app-youtube-video-player',
  templateUrl: './youtube-video-player.component.html',
  styleUrls: ['./youtube-video-player.component.scss']
})
export class YoutubeVideoPlayerComponent implements OnInit {

  @Input()
  videoId: any;

  @Input()
  classId: any;

  @Input()
  view: any;

  @Input()
  preview: boolean;

  @Input() video: Content;

  public timer;
  totalTimeViewed = 0;
  lastSecond = null;
  secondsToCallFunction = 1;
  viewDeducted = false;

  public showCode;
  public originalVideoId: any;
  public code: any;
  public videoCode = 1340;
  public error = false;
  public verifyCodeVideoId = "djDsK-rvnxw";
  //public player: any;
  constructor(public _dialog: MatDialog, private trainingService: TrainingService, private activityLog: ActivityLogService) { }



  ngOnInit() {

    //this.originalVideoId = this.videoId;
    //this.videoId = this.verifyCodeVideoId;
    // This code loads the IFrame Player API code asynchronously, according to the instructions at
    // https://developers.google.com/youtube/iframe_api_reference#Getting_Started
    const tag = document.createElement('script');

    tag.src = 'https://www.youtube.com/iframe_api';
    document.body.appendChild(tag);
    //this.showCode = true;

  }

  verifyCode() {

    var videoLog = new VideoLog({
      profileId: JSON.parse(localStorage.getItem("currentUser")).profileId,
      productId: this.video.id,
      itemName: this.video.title,
      classId: this.classId,
      comments: "YOUTUBE"
    });
    this.trainingService.insertVideoLog(videoLog).then(res => {
      this.video.videoLogId = res;
    });

    let activityLog = new ActivityLog();
    activityLog.profileID = JSON.parse(localStorage.getItem("currentUser")).profileId
    activityLog.type = "site";
    activityLog.source = "itrain";
    activityLog.videoID = this.video.id;
    if (this.preview) {
      activityLog.activity = ActivityType.PREVIEW;
    } else {
      activityLog.activity = ActivityType.WATCH;

    }
    this.activityLog.saveActivityLog(activityLog).subscribe(res => { });
  }

  ready(e) {
    e.target.playVideo();
  }



  playerChanged(event) {

    if (event.data === YT.PlayerState.PLAYING) {      // Started playing
      this.timer = setInterval(() => {
        this.record(event)
      }, 1000);

    } else {
      clearInterval(this.timer);
    }

    if (event.data === 0) {

      if (!this.preview) {
        this._dialog.open(QuizComponent, {
          id: 'quiz',
          backdropClass: 'previewBackdrop',
          width: '1165px',
          data: {
            content: this.video,
            classId: this.classId,
            spanishQuiz: false
          }
        });
      }
    }
  }

  record(e) {

    var seconds = Math.floor(e.target.getCurrentTime());
    var time25Perc = Math.floor(e.target.getDuration()) * 0.25;
    if (seconds % this.secondsToCallFunction == 0 && this.lastSecond !== seconds) {
      this.lastSecond = seconds
      var videoLog = new VideoLog({
        id: this.video.videoLogId,
        totalViewed: seconds,
        videoDuration: e.target.getDuration(),
      });
      this.trainingService.updateVideoLog(videoLog).then(res => {
      })
      if (seconds >= time25Perc && !this.viewDeducted) {
        var request = {
          profileId: JSON.parse(localStorage.getItem("currentUser")).profileId,
          providerId: JSON.parse(localStorage.getItem("currentUser")).providerId,
          productId: this.video.id,
          classId: this.classId,
          companyId: JSON.parse(localStorage.getItem("currentUser")).companyId,
          videoLogId: this.video.videoLogId
        };
        if (!this.preview) {
          if (this.view == 'CLASSROOM') {
            this.trainingService.deductClassroomView(request).then(res => {
              this.viewDeducted = true;
            });
          } else {
            this.trainingService.deductView(request).then(res => {
              this.viewDeducted = true;
            });
          }
        }
      }
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.videoId && changes.videoId.currentValue == this.video.sku) {
      console.log("changes inside",changes)

      this.verifyCode();
    }
    if (!this.preview) {
      this.fullScreen()
    }
  }
  fullScreen() {
    var e = document.getElementById("video-wrapper");
    if (e.requestFullscreen) {
        e.requestFullscreen();
    } 
}
}
