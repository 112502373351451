import { NgModule } from '@angular/core';

import { AbsIfOnDomDirective } from '@abs/directives/abs-if-on-dom/abs-if-on-dom.directive';
import { AbsInnerScrollDirective } from '@abs/directives/abs-inner-scroll/abs-inner-scroll.directive';
import { AbsPerfectScrollbarDirective } from '@abs/directives/abs-perfect-scrollbar/abs-perfect-scrollbar.directive';
import { AbsMatSidenavDirective, AbsMatSidenavTogglerDirective } from '@abs/directives/abs-mat-sidenav/abs-mat-sidenav.directive';
import { ProgressBarColor } from './progress-bar-color.directive';
import { ImagePreloadDirective } from './image-preload.directive';
import { ResizeColumnDirective } from './resize-column.directive';

@NgModule({
  declarations: [
    AbsIfOnDomDirective,
    AbsInnerScrollDirective,
    AbsMatSidenavDirective,
    AbsMatSidenavTogglerDirective,
    AbsPerfectScrollbarDirective,
    ProgressBarColor,
    ImagePreloadDirective,
    ResizeColumnDirective
  ],
  imports     : [],
  exports     : [
    AbsIfOnDomDirective,
    AbsInnerScrollDirective,
    AbsMatSidenavDirective,
    AbsMatSidenavTogglerDirective,
    AbsPerfectScrollbarDirective,
    ProgressBarColor,
    ImagePreloadDirective,
    ResizeColumnDirective
  ]
})
export class AbsDirectivesModule
{
}
