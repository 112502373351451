import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {MatRippleModule} from '@angular/material/core';
import {MatIconModule} from '@angular/material/icon';
import {MatTabsModule} from '@angular/material/tabs';
import {TranslateModule} from '@ngx-translate/core';

import {AbsNavigationComponent} from './navigation.component';

import {AbsNavVerticalCollapsableComponent} from './vertical/collapsable/collapsable.component';
import {AbsNavHorizontalCollapsableComponent} from './horizontal/collapsable/collapsable.component';
import {AbsNavHorizontalItemComponent} from './horizontal/item/item.component';
import {AbsNavVerticalGroupComponent} from './vertical/group/group.component';
import {AbsNavVerticalItemComponent} from './vertical/item/item.component';
import { MatDialogModule } from '@angular/material/dialog';
import { AutoTrainingModule } from 'app/main/auto-training/auto-training.module';
import { AutoTrainingComponent } from 'app/main/auto-training/auto-training.component';
import { ShoppingCartService } from 'app/main/auto-training/cart.service';


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MatIconModule,
    MatRippleModule,
    MatTabsModule,
    MatDialogModule,
    TranslateModule.forChild(),
    AutoTrainingModule
  ],
  exports: [
    AbsNavigationComponent
  ],
  declarations: [
    AbsNavigationComponent,
    AbsNavHorizontalCollapsableComponent,
    AbsNavHorizontalItemComponent,
    AbsNavVerticalCollapsableComponent,
    AbsNavVerticalGroupComponent,
    AbsNavVerticalItemComponent
  ],
  providers: [
    ShoppingCartService,
  ],
})
export class AbsNavigationModule {
}
