import { Component, OnInit, ViewEncapsulation, ViewChild, Input } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { style, animate, trigger, state, transition } from '@angular/animations';
import { FileManagerService } from './my-uploads.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AbsConfirmDialogComponent } from '@abs/components/confirm-dialog/confirm-dialog.component';
import { MyUplaodsFileDialogComponent } from './dialogs/item/item.component';
import { CartItem, ShoppingCartService } from '../../auto-training/cart.service';
import { SwiperComponent } from 'ngx-swiper-wrapper';
import { FileUploader } from 'ng2-file-upload';

@Component({
  selector: 'app-my-uploads',
  templateUrl: './my-uploads.component.html',
  styleUrls: ['./my-uploads.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class MyUploadsComponent implements OnInit {


  @Input()
  uploader: FileUploader;
  requiredViews = false;
  board: any;
  dialogRef: any;
  confirmDialogRef: MatDialogRef<AbsConfirmDialogComponent>;
  flip = false;
  cartState$ = this.shoppingCartService.state$;
  cartItems = new SelectionModel<CartItem>(true, []);


  private _unsubscribeAll: Subject<any>;

  constructor(public shoppingCartService: ShoppingCartService, private _fileManagerService: FileManagerService, private _matDialog: MatDialog) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit() {

  }


  /**
   * Open card dialog
   *
   * @param fileId
   */
  openFileDialog(fileId): void {
    this.dialogRef = this._matDialog.open(MyUplaodsFileDialogComponent, {
      width: '1165px',
      data: {
        fileId: fileId,
        action: 'add'
      }
    });
    this.dialogRef.afterClosed()
      .subscribe(response => {

      });
  }
  descClicked(value){
    this.flip  = value;
  }

  addItemToCart(item: CartItem) {
    item.quantity++;
    this.cartItems.select(item);
    this.shoppingCartService.addCartItem(item);

  }

  removeItem(type): void {
    if (type == 'NV') {
      this.cartItems.selected.map(item => {
        if (item.viewType == 'NON_VIDEO_VIEWS') {
          this.cartItems.deselect(item);
          item.quantity = 0;
          this.shoppingCartService.removeCartItem(item);
        }

      })
    }
    if (type == 'V') {
      this.cartItems.selected.map(item => {
        if (item.viewType == 'VIDEO_VIEWS') {
          this.cartItems.deselect(item);
          item.quantity = 0;
          this.shoppingCartService.removeCartItem(item);
        }

      })
    }
  }

}




