import { NgModule } from '@angular/core';
import { AbsCountdownComponent } from '@abs/components/countdown/countdown.component';

@NgModule({
  declarations: [
    AbsCountdownComponent
  ],
  exports: [
    AbsCountdownComponent
  ]
})
export class AbsCountdownModule { }
