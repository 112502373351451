import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatIconModule} from '@angular/material/icon';
import {AbsSharedModule} from '../../shared.module';

import {AbsConfirmDialogComponent} from '@abs/components/confirm-dialog/confirm-dialog.component';

@NgModule({
  declarations: [
    AbsConfirmDialogComponent
  ],
  imports: [
    MatDialogModule,
    MatButtonModule,
    MatToolbarModule,
    MatIconModule,
    AbsSharedModule
  ],
  entryComponents: [
    AbsConfirmDialogComponent
  ],
})
export class AbsConfirmDialogModule {
}
