import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {Login2Module} from './login-2/login-2.module';
import {Register2Module} from './register-2/register-2.module';
import {ResetPassword2Module} from './reset-password-2/reset-password-2.module';
import { SsoComponent } from './sso/sso.component';
import { RouterModule } from '@angular/router';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { CompnayService } from './_services';

const routes = [
  {
    path: 'sso',
    component: SsoComponent
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    ReactiveFormsModule,
    // Authentication
    Login2Module,
    Register2Module,
    ResetPassword2Module,
    MatProgressSpinnerModule
    
  ],
  providers: [ CompnayService],

  declarations: [SsoComponent]
})
export class AuthenticationModule { }
