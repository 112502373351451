export class PreviewLog {

  public  videoId: number;
  public  userId: number;
  public  deviceId: string;
  public  site: string;
  public  totalViewed: number;
 
  constructor(log) {
    this.videoId = log.videoId;
    this.userId = log.userId;
    this.deviceId = log.deviceId;
    this.site = log.site;
    this.totalViewed = log.totalViewed;
   
  }
}
