import { Component, OnInit, EventEmitter, Output, Input, ViewEncapsulation } from '@angular/core';
import { GroupsService } from '../../../user/groups/groups.service';
import { SelectionModel } from '@angular/cdk/collections';
import { FormControl } from '@angular/forms';
import { absAnimations } from '@abs/animations';

@Component({
  selector: 'app-onboarding-group-selector',
  templateUrl: './onboarding-group-selector.component.html',
  styleUrls: ['./onboarding-group-selector.component.scss'],
  animations: absAnimations,
  encapsulation: ViewEncapsulation.None,
})
export class OnboardingGroupSelectorComponent implements OnInit {
  flip:any;
  searchGroup = new FormControl();
  @Input() data: any;

  groups: any;
  allGroups: any;
  selection = new SelectionModel<any>(true, []);
  @Output() onBackButton = new EventEmitter<any>();
  @Output() onSelection = new EventEmitter<any>();
  @Output() onDone = new EventEmitter<any>();
  constructor(private _groupsService: GroupsService) { }

  ngOnInit(): void {

      this.groups = this.data;
      this.allGroups = this.data;
  
  }

  onGroupChange(group){
    if (group.userCount > 0) {

    this.selection.toggle(group);
    this.onSelection.emit(this.selection.selected);
    }
  }
  handleBack(){
    this.onBackButton.emit('group');
  }
  groupSelected(){
    this.onDone.emit(this.selection.selected);
  } descClicked(value) {
    this.flip = value;
  }

}
