import { NgModule } from '@angular/core';
import {AbsHighlightComponent } from '@abs/components/highlight/highlight.component';

@NgModule({
  declarations: [
    AbsHighlightComponent
  ],
  exports: [
    AbsHighlightComponent
  ]
})
export class AbsHighlightModule { }
