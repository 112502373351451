import { Component, OnInit, ViewEncapsulation, Output, EventEmitter, Input } from '@angular/core';
import { absAnimations } from '@abs/animations';
import { FormControl } from '@angular/forms';
import { SelectionModel } from '@angular/cdk/collections';
import { AuthenticationService } from '../../../authentication/_services';
import { GroupsService } from '../../../user/groups/groups.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AbsUtils } from '@abs/utils';
import { ContentService } from '../../content.service';
import { trigger, state, transition, style, animate } from '@angular/animations';
import { MatTableDataSource } from '@angular/material/table';
import { FileData } from '../content-wizard.component';
import sub from 'date-fns/esm';

@Component({
  selector: 'app-content-category-selector',
  templateUrl: './content-category-selector.component.html',
  styleUrls: ['./content-category-selector.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [absAnimations,
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*', marginTop: '12px', marginBottom: '12px' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
    trigger('detailExpandNested', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*', marginTop: '12px', marginBottom: '12px' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class ContentCategorySelectorComponent implements OnInit {
  flip: any;
  categories: any;
  allCategories: any;
  search = new FormControl();
  selection = new SelectionModel<any>(true, []);
  subSelection = new SelectionModel<any>(true, []);

  catExpanded: any;
  selectedDataSource: any;

  @Input() fileData: FileData


  @Output() onBackButton = new EventEmitter<any>();
  @Output() onSelection = new EventEmitter<any>();
  @Output() onDone = new EventEmitter<any>();
  displayedColumns = ['buttons', 'name', 'selection'];

  constructor(private contentService: ContentService) { }

  ngOnInit(): void {
    console.log("this.fileData.subCategories", this.fileData);
    this.search.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe(value => {
      if (value.length > 0) {
        console.log("value", value);
        var result = AbsUtils.filterArrayByString(this.categories, value);
        console.log("result", result);
        this.categories = result;
        this.selectedDataSource = new MatTableDataSource(this.categories);
      } else {
        this.categories = this.allCategories;
        this.selectedDataSource = new MatTableDataSource(this.categories);

      }
    });
    this.contentService.getProductCategories().subscribe(category => {
      this.categories = category;
      this.allCategories = this.categories;
      this.selectedDataSource = new MatTableDataSource(this.categories);

      if (this.fileData) {
        if (this.fileData.categories) {
          this.selectedDataSource.data.forEach(tableData => {
            this.fileData.categories.forEach(element => {
              if (tableData.id == element.id) {
                this.selection.select(tableData);
              }
            });
          });

        }
        if (this.fileData.subCategories) {
          this.selectedDataSource.data.forEach(tableData => {
            this.fileData.subCategories.forEach(element => {
              if (tableData && tableData.subCategories) {
                tableData.subCategories.forEach(sub => {
                  if (sub.id == element.id) {
                    this.subSelection.select(sub);
                  }
                });
              }
            });
          });
        }
        console.log(this.selection);
        console.log(this.subSelection);

      }
    });
  }

  onChange(cat) {
    this.selection.toggle(cat)
    if (this.selection.isSelected(cat)) {
      this.catExpanded = this.catExpanded === cat.id ? null : cat.id;
    }
    this.onSelection.next({ data: { categories: this.selection.selected, subCategories: this.subSelection.selected }, type: 'CATEGORIES' })

  }

  onContinue() {
    this.onDone.next({ data: { categories: this.selection.selected, subCategories: this.subSelection.selected }, type: 'CATEGORIES' })
  }
  handleBack() {
    this.onBackButton.next("cateogry")
  }
  descClicked(abc) {

  }
  subCategoryClicked(cat) {
    console.log(cat);
    this.subSelection.toggle(cat);
    this.onSelection.next({ data: { categories: this.selection.selected, subCategories: this.subSelection.selected }, type: 'CATEGORIES' })
  }

  isSelected() { }

}
