export class VideoLog {

  public id: number;
  public profileId: number;
  public userName: String;
  public productId: String;
  public startTime: Date;
  public endTime: Date;
  public quizResult: number;
  public passing: number;
  public totalViewed: number;
  public videoDuration: number;
  public bucketId: number;
  public classId: number;
  public doc: boolean;
  public comments: String;
  public ipAddress: String;
  public userAgent: String;
  public itemName: String;
  public cost: number;
  public quizResp: QuizResponse[];
  public type: String;

  constructor(log) {
    this.id = log.id;
    this.profileId = log.profileId
    this.userName = log.userName
    this.productId = log.productId;
    this.startTime = log.startTime;
    this.endTime = log.endTime;
    this.quizResult = log.quizResult;
    this.passing = log.passing;
    this.totalViewed = log.totalViewed;
    this.videoDuration = log.videoDuration;

    this.bucketId = log.bucketId;
    this.classId = log.classId;
    this.doc = log.doc;
    this.comments = log.comments;
    this.ipAddress = log.ipAddress;
    this.userAgent = log.userAgent;
    this.itemName = log.itemName;
    this.cost = log.cost;
    this.quizResp = log.quizResp;
    this.type = log.type;
  }
}


export class QuizResponse {

  public id: number;
  public videologID: number;
  public profileID: number;
  public quizID: number;
  public answerNo: number;
  public questionNo: number;
  public correct: boolean;
  
  constructor(log) {
    this.id = log.id;
    this.videologID = log.videologID
    this.profileID = log.profileID
    this.quizID = log.quizID;
    this.answerNo = log.answerNo;
    this.questionNo = log.questionNo;
    this.correct = log.correct;
  }
}


