import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Playlist } from '../../../content/playlists/playlists.model';
import { SelectionModel } from '@angular/cdk/collections';
import { Subject } from 'rxjs';
import { ContentService } from '../../../content/content.service';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'environments/environment';
import { MatTableDataSource } from '@angular/material/table';
import { Content } from '../../../content/content-list.model';
import { takeUntil, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { MatAccordion } from '@angular/material/expansion';
@Component({
  selector: 'app-assign-contents',
  templateUrl: './assign-contents.component.html',
  styleUrls: ['./assign-contents.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ])
  ],
})
export class AssignContentsComponent implements OnInit {
  @ViewChild('favAcc') favAcc: MatAccordion;
  @ViewChild('myAcc') myAcc: MatAccordion;
  @ViewChild('otherAcc') otherAcc: MatAccordion;
  @ViewChild('sysAcc') sysAcc: MatAccordion;


  searchInputPlaylist: FormControl;
  isLoading = true;
  //@Input() selected2 = 'Groups and Users';
  displayedColumns = ['buttons', 'name', 'courseTime', 'selection'];
  playlistExpanded: number;;
  pacdataSource: any;


  dataSourceFavorites: any;
  dataSourceMyPlaylists: any;
  dataSourceSystem: any;
  dataSourceOthers: any;

  selectionpac = new SelectionModel<Playlist>(true, []);
  selectionContent = new SelectionModel<Content>(true, []);
  playlists: any;
  environment: any;
  // Private
  private _unsubscribeAll: Subject<any>;
  constructor(public contentService: ContentService, private activatedRoute: ActivatedRoute
  ) {

    this.searchInputPlaylist = new FormControl('');
    this.environment = environment;
  }

  ngOnInit() {

    this.loadPlaylistData();

    /*   this.contentService.getPlaylistWithContents().then(playlists => {
        this.playlists = playlists;
        this.pacdataSource = new MatTableDataSource(playlists);
        this.isLoading = false;
      })
   */
  }


  loadPlaylistData() {
    this.contentService.getPlaylistGroupedWithContents().then(content => {
      this.dataSourceFavorites = new MatTableDataSource(content.favoritePlaylists);
      this.dataSourceMyPlaylists = new MatTableDataSource(content.myPlaylists);
      this.dataSourceSystem = new MatTableDataSource(content.systemPlaylists);
      this.dataSourceOthers = new MatTableDataSource(content.othersPlaylists);
      this.isLoading = false;


      this.activatedRoute.queryParams.subscribe(params => {
        const op = params['op'];
        if (op == 'playlist') {
          var playlistSelection = JSON.parse(sessionStorage.getItem("playlistSelection"));
          if (playlistSelection) {
            if (playlistSelection.selection == 'fav') {
              this.favAcc.openAll();
              this.dataSourceFavorites.data.map(playlist => {
                if (playlistSelection.playlist && playlistSelection.playlist.id == playlist.id) {
                  this.selectPlaylist(playlist);
                }
              })
            }
            if (playlistSelection.selection == 'my') {
              this.myAcc.openAll();

              this.dataSourceMyPlaylists.data.map(playlist => {
                if (playlistSelection.playlist && playlistSelection.playlist.id == playlist.id) {
                  this.selectPlaylist(playlist);
                }
              })
            }
            if (playlistSelection.selection == 'other') {
              this.otherAcc.openAll();
              this.dataSourceOthers.data.map(playlist => {
                if (playlistSelection.playlist && playlistSelection.playlist.id == playlist.id) {
                  this.selectPlaylist(playlist);
                }
              })
            }
            if (playlistSelection.selection == 'sys') {
              this.sysAcc.openAll();
              this.dataSourceSystem.data.map(playlist => {
                if (playlistSelection.playlist && playlistSelection.playlist.id == playlist.id) {
                  this.selectPlaylist(playlist);
                }
              })
            }
            this.handleAccordions(playlistSelection.selection);
          }
        }
        if (op == 'content') {

          this.dataSourceFavorites.data.map(playlist => {
            this.preSelectContentAndPlaylist(playlist);
          })

          this.dataSourceMyPlaylists.data.map(playlist => {
            this.preSelectContentAndPlaylist(playlist);
          })
          this.dataSourceOthers.data.map(playlist => {
            this.preSelectContentAndPlaylist(playlist);
          })
          this.dataSourceSystem.data.map(playlist => {
            this.preSelectContentAndPlaylist(playlist);
          })
        }
      });
    })
  }


  applyFilterPlaylist(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.pacdataSource.filter = filterValue;
  }

  selectPlaylist(playlist) {


    this.selectionpac.toggle(playlist);
    if (this.selectionpac.isSelected(playlist)) {
      if (playlist.contentList) {
        playlist.contentList.map(content => {
          content.playlistId = playlist.id;
          this.selectionContent.select(content);
        })
      }
      playlist.allContentSelected = true;
      playlist.selectedContentCount = playlist.contentList.length;
    } else {
      if (playlist.contentList) {
        playlist.contentList.map(user => {
          this.selectionContent.deselect(user);
        })
      }
      playlist.allContentSelected = false;
      playlist.selectedContentCount = 0;
    }
    this.contentService.togglePlaylistSelection(this.selectionpac);
    this.contentService.toggleContentSelection(this.selectionContent);

    console.log("selected assign content playlist", playlist);
  }
  selectContent(content, playlist) {

    console.log("content", content);
    console.log("playlist in content", playlist);

    var selectedContentCount = 0;
    this.selectionContent.toggle(content);


    if (!this.selectionpac.isSelected(playlist) && this.selectionContent.isSelected(content)) {
      // this.groupSelection.select(group);
    }

    playlist.allUserSelected = true;
    playlist.contentList.map(content => {
      var selected = this.selectionContent.isSelected(content);
      if (!selected) {
        playlist.allContentSelected = false;
      } else {
        selectedContentCount++;
      }
      console.log("selectedContentCount", selectedContentCount);
    })

    playlist.selectedContentCount = selectedContentCount;
    this.contentService.toggleContentSelection(this.selectionContent);
  }

  preSelectContentAndPlaylist(playlist: Playlist) {
    var contentSelection = JSON.parse(sessionStorage.getItem("contentSelection"));
    if (contentSelection && playlist.contentList) {
      playlist.contentList.map(element => {
        if (element.id == contentSelection.id) {
          this.selectContent(element, playlist);
        }
      });
    }
  }

  handleAccordions(accordion) {
    if (accordion == 'fav') {
      this.myAcc.closeAll();
      this.otherAcc.closeAll();
      this.sysAcc.closeAll();
    } else if (accordion == 'my') {
      this.favAcc.closeAll();
      this.otherAcc.closeAll();
      this.sysAcc.closeAll();

    } else if (accordion == 'other') {
      this.favAcc.closeAll();
      this.myAcc.closeAll();
      this.sysAcc.closeAll();
    } else if (accordion == 'sys') {
      this.favAcc.closeAll();
      this.myAcc.closeAll();
      this.otherAcc.closeAll();
    }
  }
}