import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {FormGroup} from '@angular/forms';

@Component({
  selector: 'app-playlist-manager-share-form',
  templateUrl: './playlist-manager-share-form.component.html',
  styleUrls: ['./playlist-manager-share-form.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PlaylistManagerShareFormComponent implements OnInit {

  sharePlaylistForm: FormGroup;

  constructor(public matDialogRef: MatDialogRef<PlaylistManagerShareFormComponent>) { }

  ngOnInit() {
  }

}
