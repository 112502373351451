import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AbsPipesModule } from '@abs/pipes/pipes.module';

import { AbsMaterialColorPickerComponent } from '@abs/components/material-color-picker/material-color-picker.component';

@NgModule({
  declarations: [
    AbsMaterialColorPickerComponent
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatTooltipModule,
    AbsPipesModule
  ],
  exports: [
    AbsMaterialColorPickerComponent
  ]
})
export class AbsMaterialColorPickerModule { }
