import { AbsUtils } from '@abs/utils';

export enum TrainingStyle {
  CLASSROOM = "CLASSROOM",
  INDIVIDUAL = "INDIVIDUAL"
}

export enum TrainingTrigger {
  SCHEDULE = "SCHEDULE",
  AUTOMATED = "AUTOMATED",
  RECURRING = "RECURRING",
  FREQUENT = "FREQUENT"
}