import { Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DataSource } from '@angular/cdk/collections';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { animate, state, style, transition, trigger } from '@angular/animations';

import { AbsConfirmDialogComponent } from '@abs/components/confirm-dialog/confirm-dialog.component';

import { LocationsService } from '../locations.service';
import { LocationFormComponent } from '../location-form/location-form.component';
import { LocationMapComponent } from '../location-map/location-map.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AbsProgressBarService } from '@abs/components/progress-bar/progress-bar.service';
import { ActionBarService } from '../../../../layout/components/action-bar/action-bar.service';
import { AuthenticationService } from '../../../authentication/_services';

@Component({
  selector: 'app-locations-list',
  templateUrl: './locations-list.component.html',
  styleUrls: ['./locations-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
      state('expanded', style({ height: '*', visibility: 'visible' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})

export class LocationsListComponent implements OnInit, OnDestroy {

  @ViewChild('dialogContent', { static: false })
  dialogContent: TemplateRef<any>;
  expandedElement: any;
  locations: any;
  user: any;

  dataSource: FilesDataSource | null;

  displayedColumns = ['xp-action', 'name', 'address', 'max', 'notes', 'buttons'];

  selectedLocations: any[];
  checkboxes: {};
  dialogRef: any;
  mapDialogRef: any;

  confirmDialogRef: MatDialogRef<AbsConfirmDialogComponent>;

  // Private
  private _unsubscribeAll: Subject<any>;

  isExpansionDetailRow = (i: number, row: Object) => row.hasOwnProperty('detailRow');

  /**
   * Constructor
   *
   * @param {LocationsService} _locationsService
   * @param {MatDialog} _matDialog
   */
  constructor(private _locationsService: LocationsService,
    private actionBarService: ActionBarService,
    public authService: AuthenticationService,
    public _matDialog: MatDialog, 
    public mapDialog: MatDialog, 
    private _snackBar: MatSnackBar, 
    private progressbar: AbsProgressBarService) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit() {
    this.dataSource = new FilesDataSource(this._locationsService);


    this.actionBarService.onAddLocationClick.subscribe(res=>{
      if(res){
        this.addLocation();
      }
    })

    this._locationsService.onLocationsChanged
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(locations => {
        this.locations = locations;
        console.log("locations", locations);

        this.checkboxes = {};
        locations.map(location => {
          this.checkboxes[locations.id] = false;
        });
      });

    this._locationsService.onSelectedLocationsChanged
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(selectedLocations => {
        for (const id in this.checkboxes) {
          if (!this.checkboxes.hasOwnProperty(id)) {
            continue;
          }

          this.checkboxes[id] = selectedLocations.includes(id);
        }
        this.selectedLocations = selectedLocations;
      });

    this._locationsService.onUserDataChanged
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(user => {
        this.user = user;
      });

    this._locationsService.onFilterChanged
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(() => {
        this._locationsService.deselectLocations();
      });

  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  openMapDialog(location): void {
    this.mapDialogRef = this.mapDialog.open(LocationMapComponent, {
      panelClass: 'scrumboard-card-dialog',
      width: '700px',
      minHeight: '500px',
      data: {
        lat: location.addressLat,
        lng: location.addressLong
      }
    });
    this.mapDialogRef.afterClosed()
      .subscribe(response => {

      });
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Edit contact
   *
   * @param location
   */

  editLocation(location): void {
    this.dialogRef = this._matDialog.open(LocationFormComponent, {
      panelClass: 'contact-form-dialog',
      width: '1000px',
      minWidth: '600px',
      data: {
        location: location,
        action: 'edit'
      }
    });

    this.dialogRef.afterClosed()
      .subscribe(response => {
        if (!response) {
          return;
        }
        const actionType: string = response[0];
        const formData: FormGroup = response[1];
        switch (actionType) {
          /**
           * Save
           */
          case 'edit':
          this.progressbar.show();
            this._locationsService.updateLocation(formData).then(res => {
              this.progressbar.hide();
              if (res == 1) {
                this._snackBar.open("Location has been updated.", 'Ok', {
                  panelClass: 'green-bg',
                  duration: 3000,
                  horizontalPosition: 'right',
                  verticalPosition: 'top',
                  politeness: 'polite',
                });
              } else {
                this._snackBar.open("Error while updating Location.", 'Ok', {
                  panelClass: 'red-bg',
                  duration: 3000,
                  horizontalPosition: 'right',
                  verticalPosition: 'top',
                  politeness: 'polite',
                });
              }


            });


            break;
          /**
           * Delete
           */
          case 'delete':

            this.deleteLocation(location);

            break;
        }
      });
  }


  addLocation(): void {
    this.dialogRef = this._matDialog.open(LocationFormComponent, {
      panelClass: 'contact-form-dialog',
      width: '1000px',
      minWidth: '600px',
      data: {
        action: 'add'
      }
    });

    this.dialogRef.afterClosed()
      .subscribe(response => {
        if (!response) {
          return;
        }
        const actionType: string = response[0];
        const formData: FormGroup = response[1];
        console.log("form", formData);
        switch (actionType) {
          /**
           * Save
           */
          case 'save':
            this.progressbar.show();
            this._locationsService.addLocation(formData).then(res => {
              this.progressbar.hide();
              if (res > 1) {
                this._snackBar.open("Location has been added.", 'Ok', {
                  panelClass: 'green-bg',
                  duration: 3000,
                  horizontalPosition: 'right',
                  verticalPosition: 'top',
                  politeness: 'polite',
                });
              } else {
                this._snackBar.open("Error while adding Location.", 'Ok', {
                  panelClass: 'red-bg',
                  duration: 3000,
                  horizontalPosition: 'right',
                  verticalPosition: 'top',
                  politeness: 'polite',
                });
              }


            });

            break;
          /**
           * Delete
           */
          case 'delete':

            this.deleteLocation(location);

            break;
        }
      });
  }


  /**
   * Delete Device
   */
  deleteLocation(location): void {
    this.confirmDialogRef = this._matDialog.open(AbsConfirmDialogComponent, {
      panelClass: 'confirm-form-dialog',
      disableClose: false
    });

    this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to delete?';

    this.confirmDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._locationsService.deleteLocation(location);
      }
      this.confirmDialogRef = null;
    });

  }

  /**
   * On selected change
   *
   * @param id
   */
  onSelectedChange(id): void {
    this._locationsService.toggleSelectedLocation(id);
  }

  /**
   * Toggle star
   *
   * @param id
   */
  toggleStar(id): void {
    if (this.user.starred.includes(id)) {
      this.user.starred.splice(this.user.starred.indexOf(id), 1);
    } else {
      this.user.starred.push(id);
    }

    this._locationsService.updateUserData(this.user);
  }

}

export class FilesDataSource extends DataSource<any> {
  /**
   * Constructor
   *
   * @param {LocationsService} _locationsService
   */
  constructor(
    private _locationsService: LocationsService
  ) {
    super();
  }

  /**
   * Connect function called by the table to retrieve one stream containing the data to render.
   * @returns {Observable<any[]>}
   */
  connect(): Observable<Element[]> {
    return this._locationsService.onLocationsChanged;
  }

  /**
   * Disconnect
   */
  disconnect(): void {
  }
}
