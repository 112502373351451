import {Component, OnInit, ViewEncapsulation, ViewChild} from '@angular/core';
import {absAnimations} from '@abs/animations';
import { PlaylistManagerAllComponent } from './playlist-manager-all/playlist-manager-all.component';
import { PlaylistUsersComponent } from './playlist-users/playlist-users.component';
import { AbsProgressBarService } from '@abs/components/progress-bar/progress-bar.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ContentService } from '../content.service';

@Component({
  selector: 'app-playlist-manager',
  templateUrl: './playlist-manager.component.html',
  styleUrls: ['./playlist-manager.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: absAnimations
})
export class PlaylistManagerComponent implements OnInit {

  @ViewChild("contents", { static: false }) contents: PlaylistUsersComponent;
  @ViewChild("playlists", { static: false }) playlists: PlaylistManagerAllComponent;


  constructor( private _progressBarService: AbsProgressBarService,
    public _snackBar: MatSnackBar,public contentService: ContentService) { }

  ngOnInit() {
  }

  assignContent() {
    this._progressBarService.show();
    this.contentService.updatePlaylist(this.playlists.contentList.selection.selected,this.contents.selection.selected).then(req => {
      this._progressBarService.hide();
      this.playlists.contentList.selection.clear();
      this.contents.selection.clear();
      if (req == 1) {
        this._snackBar.open("Selected contents have been assigned to the playlists", 'Ok', {
          panelClass: 'green-bg',
          duration: 3000,
          horizontalPosition: 'right',
          verticalPosition: 'top',
          politeness: 'polite',
        });
      }else{
        this._snackBar.open("Selected content(s) already assigned to the playlists", 'Ok', {
          panelClass: 'red-bg',
          duration: 3000,
          horizontalPosition: 'right',
          verticalPosition: 'top',
          politeness: 'polite',
        });
      }
    });
  }

}
