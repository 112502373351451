import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserAvatarComponent } from './user-avatar.component';
import { AvatarModule } from 'ngx-avatar';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [UserAvatarComponent],
  imports: [
    CommonModule,
    AvatarModule,
    MatIconModule
  ],
  exports: [
    UserAvatarComponent
  ]
})
export class UserAvatarModule { }
