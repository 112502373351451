import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { Content } from '../../_models/playlist';
import { environment } from 'environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { StartCourseDialogComponent } from '../start-course-dialog/start-course-dialog.component';
import { VideoPlayerService } from '../../../content/videos/video-player/video-player.service';
import { QuizComponent } from '../../../content/quiz/quiz.component';
import { TrainingService } from '../../_services/training.service';
import { AuthenticationService } from '../../../authentication/_services';
import { AbsProgressBarService } from '@abs/components/progress-bar/progress-bar.service';
import { ActivityLog, ActivityType, ActivityLogService } from '../../../service/activity-log.service';
import { DocumentViewerDialogComponent } from '../../../document-viewer-dialog/document-viewer-dialog.component';

@Component({
  selector: 'my-training-content-item',
  templateUrl: './my-training-content-item.component.html',
  styleUrls: ['./my-training-content-item.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MyTrainingContentItemComponent implements OnInit {

  @Input() playlist: Content;
  @Input() classId: any;
  @Input() passingScore: any;
  @Input() training: any;

  dialogRef: any;
  docDialogRef: any;

  dialogRefQuiz: any;

  environment: any;
  totalTimeViewed: number;
  certificateURL: string;
  userSettings: any = [];

  constructor(public dialog: MatDialog,
    public dialogQuiz: MatDialog,
    public docDialog: MatDialog,
    public playerService: VideoPlayerService,
    private trainingService: TrainingService,
    public authService: AuthenticationService,
    private activityLog: ActivityLogService,
    private progressBar: AbsProgressBarService) {
    this.environment = environment;
    this.playerService.onVideoTimeChanged.subscribe(time => {
      this.totalTimeViewed = time;
    })


  }
  ngOnInit() {
    var companyId = this.authService.currentUserValue.companyId;
    this.certificateURL = `${environment.apiUrl}/training/certificate?videoLogId=${this.playlist.videoLogId}`;
  }

  viewDocument(playlist){

    var url = "";
    if (playlist.private) {
      var fileExt = playlist.fileName.split('.').pop();
      var directory = playlist.itemCode.split('.')[0];
      url = `${environment.cdnUrl}/assets/contents/${directory+"/V_"+playlist.itemCode+"."+fileExt}`;
    } else {
      url = `${environment.cdnUrl}/content/${playlist.prodType.toLowerCase().replace('_', '+')}s/${playlist.fileName}`
    }

    this.docDialogRef = this.docDialog.open(DocumentViewerDialogComponent, {
      backdropClass: 'previewBackdrop',
      width: '1165px',
      height: '800px',
      data: {
        url:  url,
        title: playlist.title,
        profileId:this.authService.currentUserValue.profileId,
        content:playlist,
        parent: 'en',
        classId: this.classId,

      }
    });

    this.docDialogRef.afterClosed()
      .subscribe(res => {
        if (res) {
          this.progressBar.show();
          this.trainingService.getTrainings().then(res => {
            this.progressBar.hide();
            this.trainingService.onTrainingChanged.next(res);
          })
        }
      });
  }


  retakeQuiz(spanish) {


    let activityLog = new ActivityLog();
    activityLog.profileID = JSON.parse(localStorage.getItem("currentUser")).profileId
    activityLog.type = "site";
    activityLog.source = "itrain";
    activityLog.activity = ActivityType.RETAKE_QUIZ;
    activityLog.videoID = this.playlist.id;
    this.activityLog.saveActivityLog(activityLog).subscribe(res => { });

    this.dialogRefQuiz = this.dialogQuiz.open(QuizComponent, {
      id: 'quiz',
      backdropClass: 'previewBackdrop',
      width: '1165px',
      disableClose: true,
      data: {
        content: this.playlist,
        type: '',
        classId: this.classId,
        passingScore: this.passingScore,
        spanishQuiz: spanish
      }
    });
    this.dialogRefQuiz.afterClosed()
      .subscribe(res => {
        if (res) {
          this.progressBar.show();
          this.trainingService.getTrainings().then(res => {
            this.progressBar.hide();
            this.trainingService.onTrainingChanged.next(res);
          })
        }
      });
  }

  getValue(source, key) {
    return source.filter(s => s.name == key);
  }

  downloadCertificate() {
    this.trainingService.downloadCertificate({ videoLogId: this.playlist.videoLogId, companyName: "BASC Group", companyId: 10000 });
  }

  logActivity() {
    let activityLog = new ActivityLog();
    activityLog.profileID = JSON.parse(localStorage.getItem("currentUser")).profileId
    activityLog.type = "site";
    activityLog.source = "itrain";
    activityLog.activity = ActivityType.CERTIFICATE;
    activityLog.videoID = this.playlist.id;
    this.activityLog.saveActivityLog(activityLog).subscribe(res => { });
  }

  openDialog(lang) {

    let activityLog = new ActivityLog();
    activityLog.profileID = JSON.parse(localStorage.getItem("currentUser")).profileId
    activityLog.type = "site";
    activityLog.source = "itrain";
    activityLog.activity = ActivityType.RE_WATCH;
    activityLog.videoID = this.playlist.id;
    this.activityLog.saveActivityLog(activityLog).subscribe(res => { });

    this.dialogRef = this.dialog.open(StartCourseDialogComponent, {
      id: 'preview',
      panelClass: 'start-course-dialog',
      backdropClass: 'previewBackdrop',
      width: '80vw',
      height: '800px',
      disableClose: true,
      data: {
        video: this.playlist,
        preview: true,
        classId: this.classId,
        format: this.training.format,
        passingScore: this.passingScore,
        lang: lang
      }
    });

    this.dialogRef.afterClosed().subscribe(response => {
      this.progressBar.show();
      this.trainingService.getTrainings().then(res => {
        this.progressBar.hide();
        this.trainingService.onTrainingChanged.next(res);
      })
    });
  }

}
