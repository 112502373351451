import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { DOCUMENT, PlatformLocation, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as moment from 'moment';

import { AbsConfigService } from '@abs/services/config.service';
import { AbsNavigationService } from '@abs/components/navigation/navigation.service';
import { AbsSidebarService } from '@abs/components/sidebar/sidebar.service';
import { AbsSplashScreenService } from '@abs/services/splash-screen.service';
import { AbsTranslationLoaderService } from '../@abs/services/translation-loader.service';

import { navigation } from 'app/navigation/navigation';
import { learnerNavigation } from 'app/navigation/navigation';

import { locale as navigationEnglish } from 'app/navigation/i18n/en';
import { locale as navigationTurkish } from 'app/navigation/i18n/tr';
import { locale as navigationSpanish } from 'app/navigation/i18n/sp';

import * as firebase from 'firebase';
import { platform } from 'chart.js';
import { CompnayService, AuthenticationService } from './main/authentication/_services';
import { User, UserLevel } from './main/models/user';
import { AbsUtils } from '@abs/utils';
import { ContentService } from './main/content/content.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AbsSnackBarComponent } from '@abs/components/abs-snack-bar/abs-snack-bar.component';
import { TourService } from 'ngx-tour-md-menu';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  absConfig: any;
  navigation: any;

  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {DOCUMENT} document
   * @param {AbsConfigService} _absConfigService
   * @param {AbsNavigationService} _absNavigationService
   * @param {AbsSidebarService} _absSidebarService
   * @param {AbsSplashScreenService} _absSplashScreenService
   * @param {AbsTranslationLoaderService} _absTranslationLoaderService
   * @param {Platform} _platform
   * @param {TranslateService} _translateService
   */
  constructor(
    @Inject(DOCUMENT) private document: any,
    private _absConfigService: AbsConfigService,
    private _absNavigationService: AbsNavigationService,
    private _absSidebarService: AbsSidebarService,
    private _absSplashScreenService: AbsSplashScreenService,
    private _absTranslationLoaderService: AbsTranslationLoaderService,
    private _translateService: TranslateService,
    private _platform: Platform,
    private _companyService: CompnayService,
    private _contentSerivice: ContentService,
    private _authService: AuthenticationService,
    private _snackBar: MatSnackBar,
    private tourService: TourService,
    private route: ActivatedRoute,
    private router: Router,
    // private _router: Router

  ) {
    // Get default navigation

    if (this._authService.currentUserValue && this._authService.currentUserValue.userLevel == UserLevel.LEARNER) {
      this.navigation = learnerNavigation;
    } else {
      this.navigation = navigation;
    }

    // Register the navigation to the service
    this._absNavigationService.register('main', this.navigation);

    // Set the main navigation as our current navigation
    this._absNavigationService.setCurrentNavigation('main');

    // Add languages
    this._translateService.addLangs(['en', 'sp', 'tr']);

    // Set the default language
    this._translateService.setDefaultLang('en');

    // Set the navigation translations
    this._absTranslationLoaderService.loadTranslations(navigationEnglish, navigationTurkish, navigationSpanish);

    // Use a language
    this._translateService.use('en');

    /**
     * ----------------------------------------------------------------------------------------------------
     * ngxTranslate Fix Start
     * ----------------------------------------------------------------------------------------------------
     */

    /**
     * If you are using a language other than the default one, i.e. Turkish in this case,
     * you may encounter an issue where some of the components are not actually being
     * translated when your app first initialized.
     *
     * This is related to ngxTranslate module and below there is a temporary fix while we
     * are moving the multi language implementation over to the Angular's core language
     * service.
     **/

    // Set the default language to 'en' and then back to 'tr'.
    // '.use' cannot be used here as ngxTranslate won't switch to a language that's already
    // been selected and there is no way to force it, so we overcome the issue by switching
    // the default language back and forth.
    /**
     setTimeout(() => {
            this._translateService.setDefaultLang('en');
            this._translateService.setDefaultLang('tr');
         });
     */

    /**
     * ----------------------------------------------------------------------------------------------------
     * ngxTranslate Fix End
     * ----------------------------------------------------------------------------------------------------
     */

    // Add is-mobile class to the body if the platform is mobile
    if (this._platform.ANDROID || this._platform.IOS) {
      this.document.body.classList.add('is-mobile');
    }
   
    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    var from = new Date();
    var date = moment(from).format('ZZ'); //ZZ

    //alert(date);

    this._companyService.getCompanyByCustomDomain(location.protocol + "//" + location.host).subscribe(resp => {
      if (resp) {
        if (resp.settings) {
          let SHOW_SAMSUNG_WARNING = AbsUtils.filterArrayByString(resp.settings, "SHOW_SAMSUNG_WARNING");
          if (SHOW_SAMSUNG_WARNING && SHOW_SAMSUNG_WARNING[0].value == 'Y') {
            this.showSamsungWarning();
          }
        }
        localStorage.setItem('currentCompany', JSON.stringify(resp));
        
      } else {
        localStorage.setItem('currentCompany', null);
      }
    });

    this._authService.getSystemDefaults().subscribe(result => {
      localStorage.setItem('system_defaults', JSON.stringify(result));
    })




    // Subscribe to config changes
    this._absConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config) => {
        this.absConfig = config;
        console.log("config", config);
        //this.absConfig.colorTheme = "theme-yellow-light";
        //this.absConfig.layout.navbar.primaryBackground = "red-300";
        //this.absConfig.layout.navbar.secondaryBackground = "abs-white";
         //this.absConfig.layout.toolbar.backgroud = "pink";
        //  console.log("config updated", config);
        // Boxed
        if (this.absConfig.layout.width === 'boxed') {
          this.document.body.classList.add('boxed');
        }
        else {
          this.document.body.classList.remove('boxed');
        }

        // Color theme - Use normal for loop for IE11 compatibility
        for (let i = 0; i < this.document.body.classList.length; i++) {
          const className = this.document.body.classList[i];

          if (className.startsWith('theme-')) {
            this.document.body.classList.remove(className);
          }
        }

        this.document.body.classList.add(this.absConfig.colorTheme);
      });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  showSamsungWarning() {

    var alreadyShown = localStorage.getItem("samsung");
    if (!alreadyShown) {
      if (this._platform.ANDROID) {
        const userAgent = window.navigator.userAgent;
        if (userAgent && userAgent.indexOf("SM-") != -1) {
          this._snackBar.openFromComponent(AbsSnackBarComponent, {
            data: "",
            panelClass: 'primary-50',
            horizontalPosition: 'center',
            verticalPosition: 'top',
            politeness: 'polite',
          });
          localStorage.setItem("samsung", "Y");
        }
      }
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle sidebar open
   *
   * @param key
   */
  toggleSidebarOpen(key): void {
    this._absSidebarService.getSidebar(key).toggleOpen();
  }
}
