import { Injectable, Inject } from '@angular/core';
import { v4 as uuid } from 'uuid';
import { Observable, of, merge, Subject, BehaviorSubject } from 'rxjs';
import { scan, startWith, map, tap, combineLatest, switchMap, skipWhile, shareReplay, debounceTime, publish, refCount, share } from 'rxjs/operators';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { SelectionModel } from '@angular/cdk/collections';
import { ActionBarService } from '../../layout/components/action-bar/action-bar.service';
import { AuthenticationService } from '../authentication/_services';


const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

export interface Totals {
  grandTot: number;
}

export class CartItem {
  public constructor(init?: Partial<CartItem>) {
    Object.assign(this, init);
  }
  id: number;
  size: number;
  unitCost: number;
  name: string;
  details: string;
  period: string;
  type: string;
  viewType: string;
  uuid?: any;
  remove?: boolean;
  removeAll?: boolean;
  quantity?: number;
  recomended?: boolean;

}

export interface ViewLevelTotal {
  size: number;
  ppuCost: number;
  unitCost: number;
}

export interface StateTree {
  store: CartItem[];
  cart: CartItem[];
  learnerS: ViewLevelTotal,
  classroomS: ViewLevelTotal,
  quizS: ViewLevelTotal,
  nonVideoS: ViewLevelTotal,
  videoS: ViewLevelTotal,
  checkout: boolean;
};

@Injectable({ providedIn: 'root' })
export class ShoppingCartService {
  /**
   * Main Observables
   * 
   */
  private stateTree$ = new BehaviorSubject<StateTree>(null);
  private checkoutTrigger$ = new BehaviorSubject<boolean>(false);
  private cartAdd$ = new Subject<CartItem>();
  private cartRemove$ = new Subject<CartItem>();
  public cartItems = new SelectionModel<String>(true, []);
  private products: BehaviorSubject<CartItem[]> = new BehaviorSubject([]);
  _products: CartItem[] = [];
  public mode = 'AUTH';

  /**
   * Main application cart Observable
   * This could start with items from local storage or even an API call
   * We use scan peak at the items within the cart and add and remove
   */
  public get cart$(): Observable<CartItem[]> {
    return merge(this.cartAdd$, this.cartRemove$).pipe(
      startWith([]),
      scan((acc: CartItem[], item: CartItem) => {
        if (item) {
          if (item.remove) {
            this._products.forEach(element => {
              if (element.viewType == item.viewType) {
                element.quantity = 0;
              }
            });
            this.products.next(this._products);
            return [...acc.filter(i => i.viewType !== item.viewType)];
          }
          if (item.removeAll) {
            this._products.forEach(element => {
              element.quantity = 0;
            });
            return [];
          }
          const foundIndex = acc.findIndex(i => i.id == item.id);
          if (foundIndex == -1) {
            return [...acc, item];
          } else {
            acc[foundIndex] = item;
            return [...acc];
          }
        }
      })
    );
  }

  get productList() {
    return this.products.asObservable();
  }

  public get viewLevelTotal$(): number {
    var total = 0;
    this.state$.pipe(
      map(state => {
        let total = 0;
        if (state.learnerS.ppuCost) {
          total += state.learnerS.ppuCost;
        }
        if (state.classroomS.ppuCost) {
          total += state.classroomS.ppuCost;
        }
        if (state.quizS.ppuCost) {
          total += state.quizS.ppuCost;
        }
        if (state.nonVideoS.ppuCost) {
          total += state.nonVideoS.ppuCost;
        }
        if (state.videoS.ppuCost) {
          total += state.videoS.ppuCost;
        }
        return total;
      }),
      map(cost => ({
        grandTot: cost
      })
      )
    ).subscribe(res => {
      total = res.grandTot;
    });
    return total;
  }

  public totalByType(type): Observable<Totals> {
    return this.cart$.pipe(
      map(items => {
        let total = 0;
        for (const i of items) {
          if (i.type == type) {
            total += i.unitCost * i.quantity;
          }
        }
        return total;
      }),
      map(cost => ({
        grandTot: cost
      })
      )
    );
  }

  public getTotalViewsByType(type): Observable<ViewLevelTotal> {
    return this.cart$.pipe(
      map(items => {
        let cartItems = [];
        var viewLevelTotal: any = new Object();
        let totalView = 0;
        for (const i of items) {
          if (i.viewType == type) {
            totalView += i.size * i.quantity;
            if (i.quantity > 0) {
              cartItems.push(i);
            }
            var max = i;

            if (cartItems.length > 0) {
              max = cartItems.reduce(function (prev, current) {
                return (prev.size > current.size) ? prev : current
              })
            }

            viewLevelTotal.unitCost = max.unitCost;
            viewLevelTotal.size = totalView;
            viewLevelTotal.ppuCost = viewLevelTotal.unitCost * totalView;

          }
        }
        return viewLevelTotal;
      })
    );
  }

  /**
   * Main Shopping Cart StateTree
   * Combines all dependencies and maps then to the StateTree Object 
   */
  state$: Observable<StateTree> = this.stateTree$.pipe(
    switchMap(() => this.productList.pipe(
      combineLatest([this.cart$, this.getTotalViewsByType('LEARNER_VIEWS'), this.getTotalViewsByType('CLASSROOM_VIEWS'), this.getTotalViewsByType('QUIZ_VIEWS'), this.getTotalViewsByType('NON_VIDEO_VIEWS'), this.getTotalViewsByType('VIDEO_VIEWS'), this.checkoutTrigger$]),
      debounceTime(0),
    )),
    map(([store, cart, learnerS, classroomS, quizS, nonVideoS, videoS, checkout]: any) => ({ store, cart, learnerS, classroomS, quizS, nonVideoS, videoS, checkout })),
    tap(state => {
      console.log("state changed", state);
      if (state.checkout) {
        this.actionBarService.onCartChanged.next(0);
      }
    }),
    // make sure we share to the world! or just the entire app
    shareReplay(1)
  );

  constructor(private _httpClient: HttpClient, private actionBarService: ActionBarService, private auth: AuthenticationService) {
    this.getItems();
  }

  getItems() {
    console.log("get items")
    var ownerId = 0;
    var ownerType = '';

    if (this.auth.currentUserValue) {
      ownerId = this.auth.currentUserValue.providerId > 0 ? this.auth.currentUserValue.providerId : this.auth.currentUserValue.companyId;
      ownerType = this.auth.currentUserValue.providerId > 0 ? 'provider' : 'default';
    } else {
      if (this.auth.currentPublicUserValue) {
        ownerId = this.auth.currentPublicUserValue.providerId > 0 ? this.auth.currentPublicUserValue.providerId : this.auth.currentPublicUserValue.companyId;
        ownerType = this.auth.currentPublicUserValue.providerId > 0 ? 'provider' : 'default';
      }
    }
    let obj = JSON.stringify({ ownerID: ownerId, ownerType: ownerType });

    this._httpClient.post<CartItem[]>(`${environment.apiUrl}/bucket/user/price`, obj, httpOptions).subscribe(items => {
      this._products = items;
      this.products.next(this._products);
    });
  }


  processPayment(paymentRequest): Promise<any> {
    return new Promise((resolve, reject) => {
      let obj = JSON.stringify(paymentRequest);
      this._httpClient.post(`${environment.apiUrl}/process/payment`, obj, httpOptions)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    }
    );
  }

  // facade for next of cartAdd subject
  addCartItem(item: CartItem) {
    console.log("i", item);
    this.cartAdd$.next(item);
    this.cartItems.select(item.viewType);
    this.actionBarService.onCartChanged.next(this.cartItems.selected.length);
    /*  let items = JSON.parse(localStorage.getItem("cart"));
     if(items && items.length > 0){
       
     } */
  }
  // facade for next of cartRemove subject
  removeCartItem(viewType) {
    var item = new CartItem();
    item.viewType = viewType;
    item.remove = true;
    this.cartRemove$.next(item);
    this.cartItems.deselect(item.viewType);
    this.actionBarService.onCartChanged.next(this.cartItems.selected.length);
  }

  clearAll() {
    var item = new CartItem();
    item.removeAll = true;
    this.cartRemove$.next(item);
  }

  // not sure what else to do here so we don't do much
  // have a great day!
  checkout() {
    this.checkoutTrigger$.next(true);
  }
}