import { AbsUtils } from '@abs/utils';

export class TrainingAttendee {
  id: number;
  profileID: number;
  trainerID: number;

  firstName: string;
  lastName: string;
  email: string;
  smsPhone: string;
  attending: boolean;
  attended: boolean;
  attendDate: Date;
  walkIn: boolean;
  makeUp: boolean;
  replyDate: Date;


  /**
 * Constructor
 *
 * @param TrainingAttendee
 */
  constructor(attendee) {
    this.id = attendee.id;
    this.trainerID = attendee.trainerID;
    this.profileID  = attendee.profileID;
    this.firstName = attendee.firstName;
    this.lastName = attendee.lastName;
    this.email = attendee.email;
    this.smsPhone = attendee.smsPhone;
    this.attended = attendee.attended;
    this.attendDate = attendee.attendDate;
    this.attending = attendee.attending;
    this.replyDate = attendee.replyDate;
    this.makeUp = attendee.makeUp;
    this.walkIn = attendee.walkIn;

  }

}