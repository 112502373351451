import { Component, OnInit, Inject, ViewEncapsulation, Input } from '@angular/core';

import { QuizService } from './services/quiz.service';
import { HelperService } from './services/helper.service';
import { Option, Question, Quiz, QuizConfig } from './models/index';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ContentService } from '../content.service';
import { VideoLog } from '../video-log.model';
import { Content } from '../../training/_models/playlist';
import { TrainingService } from '../../training/_services/training.service';
import { ActivityLog, ActivityLogService, ActivityType } from '../../service/activity-log.service';
import { absAnimations } from '@abs/animations';
import { SelectionModel } from '@angular/cdk/collections';
import { CertificateActionComponent } from '../../landing-page/certificate-action/certificate-action.component';

@Component({
  selector: 'app-quiz',
  templateUrl: './quiz.component.html',
  styleUrls: ['./quiz.component.scss'],
  providers: [QuizService],
  encapsulation: ViewEncapsulation.None,
  animations: absAnimations
})
export class QuizComponent implements OnInit {
  videoLogId: any;
  profileId: any;
  parent: string;
  selection = new SelectionModel<Option>(false /* multiple */);
  backClicked = false;
  spanishQuiz: boolean;
  classId: any;
  type: any;
  videoTime: any;
  quizes: any[];
  quiz: Quiz = new Quiz(null);
  mode = 'quiz';
  passed = false;
  content: Content;
  config: QuizConfig = {
    'allowBack': true,
    'allowReview': false,
    'autoMove': true,  // if true, it will move to next question automatically when answered.
    'duration': 9000000000,  // indicates the time (in secs) in which quiz needs to be completed. 0 means unlimited.
    'pageSize': 1,
    'requiredAll': false,  // indicates if you must answer all the questions before submitting.
    'richText': false,
    'shuffleQuestions': false,
    'shuffleOptions': false,
    'showClock': false,
    'showPager': true,
    'theme': 'none'
  };

  pager = {
    index: 0,
    size: 1,
    count: 1
  };
  timer: any = null;
  startTime: Date;
  endTime: Date;
  ellapsedTime = '00:00';
  duration = '';
  doneCourses = 0;
  totalCourses = 0;

  certificateDialogRef: any;

  constructor(private trainService: TrainingService, private activityLog: ActivityLogService, private quizService: QuizService, public matDialogRef: MatDialogRef<QuizComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any, private dialog: MatDialog) {
    this.content = _data.content;
    this.videoTime = _data.time;
    this.classId = _data.classId;
    this.parent = _data.parent;
    if (_data.type) {
      this.type = _data.type;
    } else {
      this.type = "first";
    }
    this.spanishQuiz = _data.spanishQuiz;
    this.profileId = _data.profileId;

    this.doneCourses = _data.doneCourses;
    this.totalCourses = _data.totalCourses;

    console.log("quiz", this.content);
    console.log("quiz data", _data);


  }

  ngOnInit() {

    let activityLog = new ActivityLog();
    activityLog.profileID = JSON.parse(localStorage.getItem("currentUser")).profileId
    activityLog.type = "site";
    activityLog.source = "itrain";
    activityLog.videoID = this.content.id;
    activityLog.activity = ActivityType.QUIZ;
    this.activityLog.saveActivityLog(activityLog).subscribe(res => { });
    this.loadQuiz(this.content.id);
  }

  loadQuiz(contentId) {
    this.quizService.getQuizDetail(contentId, this.spanishQuiz,this.classId).subscribe(res => {
      if (res) {
        this.quiz = new Quiz(res);
        console.log(this.quiz);
        this.pager.count = this.quiz.questions.length;
        this.startTime = new Date();
        this.ellapsedTime = '00:00';
        this.timer = setInterval(() => { this.tick(); }, 1000);
        this.duration = this.parseTime(this.config.duration);

        this.insertLog();


      }
    });
    this.mode = 'quiz';
  }

  tick() {
    const now = new Date();
    const diff = (now.getTime() - this.startTime.getTime()) / 1000;
    if (diff >= this.config.duration) {
      this.onSubmit();
    }
    this.ellapsedTime = this.parseTime(diff);
  }

  goBack() {
    this.backClicked = true;
    this.goTo(this.pager.index - 1);
  }

  goNext() {
    this.backClicked = false;
    this.goTo(this.pager.index + 1);
  }

  parseTime(totalSeconds: number) {
    let mins: string | number = Math.floor(totalSeconds / 60);
    let secs: string | number = Math.round(totalSeconds % 60);
    mins = (mins < 10 ? '0' : '') + mins;
    secs = (secs < 10 ? '0' : '') + secs;
    return `${mins}:${secs}`;
  }

  get filteredQuestions() {
    return (this.quiz.questions) ?
      this.quiz.questions.slice(this.pager.index, this.pager.index + this.pager.size) : [];
  }

  onSelect(question: Question, option: Option) {

    this.selection.select(option);
    option.selected = true;

    if (question.questionTypeId === 1) {
      question.answers.forEach((x) => { if (x.answerNo !== option.answerNo) x.selected = false; });
    }
    if (this.config.autoMove) {
      this.goTo(this.pager.index + 1);
    }
  }

  goTo(index: number) {
    if (index >= 0 && index < this.pager.count) {
      this.pager.index = index;
      this.mode = 'quiz';
    }
  }

  isAnswered(question: Question) {
    return question.answers.find(x => x.selected) ? 'Answered' : 'Not Answered';
  };

  findUserAnswer(question: Question) {
    return question.answers.find(x => x.selected);
  };

  isCorrect(question: Question) {

    let correctAnswers = question.answers.filter(x => x.correct == true);
    let selectedAnswers = question.answers.filter(x => x.selected == true);
    return correctAnswers.every(x => x.selected == x.correct && x.correct == true && x.selected == true) && correctAnswers.length == selectedAnswers.length ? 'correct' : 'wrong';
  };

  onSubmit() {
    let quizResponses = [];
    this.quiz.questions.forEach(x => {
      if (this.isAnswered(x) == 'Answered') {
        var quizResponse = {
          videologID: this.content.videoLogId,
          profileID: JSON.parse(localStorage.getItem("currentUser")).profileId,
          quizID: this.quiz.id,
          questionNo: x.questionNo,
          answerNo: this.findUserAnswer(x).answerNo,
          question: x.question,
          answer: this.findUserAnswer(x).answer,
          correct: this.isCorrect(x) == 'correct' ? true : false
        };
        quizResponses.push(quizResponse);
      }
    });

    /* if (this.type == 'retake') {
      var videoLog = new VideoLog({});
      videoLog.quizResult = this.getQuizScore(this.quiz);

      if(this.parent == 'kiosk' && videoLog.quizResult>= this.quiz.passingScore){
        this.passed = true;
      }
      //videoLog.passing = this.quiz.passingScore;
      videoLog.itemName = this.content.title;
      videoLog.quizResp = quizResponses;
      videoLog.totalViewed = this.videoTime;
      videoLog.classId = this.classId;
      videoLog.profileId = JSON.parse(localStorage.getItem("currentUser")).profileId
      videoLog.comments = this.spanishQuiz ? 'es' : 'en';
      this.trainService.updateVideoLog(videoLog).then(res => {
        console.log(res);
        console.log("videolog objaect", videoLog);
      })
    } else { */
    var videoLog = new VideoLog({});
    videoLog.quizResult = this.getQuizScore(this.quiz);
    let quizPassingScore = this.quiz.passingScore == 0 ? 80: this.quiz.passingScore

    if (this.parent == 'kiosk' && videoLog.quizResult >= quizPassingScore) {
      this.passed = true;
    }
    //videoLog.passing = this.quiz.passingScore;
    videoLog.quizResp = quizResponses;
    videoLog.id = this.videoLogId;
    videoLog.totalViewed = this.videoTime;
    videoLog.classId = this.classId;
    if (this.parent == 'kiosk') {
      videoLog.profileId = this.profileId;
      videoLog.type = 'kiosk';
      videoLog.passing = quizPassingScore

    } else {
      videoLog.profileId = JSON.parse(localStorage.getItem("currentUser")).profileId;

    }
    videoLog.itemName = this.content.title;
    //videoLog.comments = this.spanishQuiz ? 'es' : 'en';
    this.trainService.updateVideoLog(videoLog).then(res => {
      console.log(res);
    })
    this.mode = 'result';
  }

  getQuizScore(quiz): number {
    var totalCorrect = 0;
    quiz.questions.forEach(question => {
      if (this.isCorrect(question) == 'correct') {
        totalCorrect = totalCorrect + 1;
      }
    });
    console.log("totalCorrect", totalCorrect);
    var result = (totalCorrect / quiz.questions.length) * 100;
    return result;
  }

  getNextLetter(num): String {
    var code: number = "ABCDEFGHIJKLMNOPQRST".charCodeAt(num);
    return String.fromCharCode(code);
  }

  retakeQuiz() {
    this.quiz.questions.forEach(x => {
      x.answers.forEach(a=>{
        a.selected = false;
      })
    });
    this.mode = 'quiz';
    this.pager.index = 0;
  }

  rewatchVideo() {
    this.matDialogRef.close('rewatch');
  }

  getCertificate() {

    this.certificateDialogRef = this.dialog.open(CertificateActionComponent, {
      backdropClass: 'previewBackdrop',
      width: '400px',
      disableClose: true,
      data: {
        videoLogId: this.videoLogId,
        profileId: this.profileId
      }
    });

    this.certificateDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.matDialogRef.close(result);
      }
    });

  }

  done() {
    this.matDialogRef.close('done');
  }

  nextCourse() {
    this.matDialogRef.close('next');
  }


  insertLog() {
    var videoLog = new VideoLog({
      profileId: JSON.parse(localStorage.getItem("currentUser")).profileId,
      productId: this.content.id,
      itemName: this.content.title,
      classId: this.classId,
      comments: "QUIZ_ENTRY"
    });
    this.trainService.insertVideoLog(videoLog).then(vid => {
      this.videoLogId = vid;

    /*   var request = {
        profileId: JSON.parse(localStorage.getItem("currentUser")).profileId,
        providerId: JSON.parse(localStorage.getItem("currentUser")).providerId,
        productId: this.content.id,
        classId: this.classId,
        videoLogId: vid,
        companyId: JSON.parse(localStorage.getItem("currentUser")).companyId,
      };
      this.trainService.deductQuizView(request).then(res => {
        console.log("done");
      }); */

    });
  }
}