import { Component, OnInit, Output, EventEmitter, Input, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { SelectionModel } from '@angular/cdk/collections';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AbsUtils } from '@abs/utils';
import { absAnimations } from '@abs/animations';
import { TrainingOptions } from '../training-option-selector.component';
@Component({
  selector: 'app-send-reminder-selector',
  templateUrl: './send-reminder-selector.component.html',
  styleUrls: ['./send-reminder-selector.component.scss'],
  animations: absAnimations,
  encapsulation: ViewEncapsulation.None
})
export class SendReminderSelectorComponent implements OnInit {
  flip: any;
  value: any = 1;
  value2: any = 1;
  value3: any = 1;

  valueTotal: any = 1;

  @Input()
  default: TrainingOptions;


  @Input()
  defaultTotal: any;

  @Output() onBackButton = new EventEmitter<any>();
  @Output() onSelection = new EventEmitter<any>();
  @Output() onDone = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
    console.log("default",this.default);
    if (this.default) {
      if (this.default.sendReminders) {
        this.value = this.default.sendReminders;
      }
      if (this.default.sendReminders2) {
        this.value2 = this.default.sendReminders2;
      }
      if (this.default.sendReminders3) {
        this.value3 = this.default.sendReminders3;
      }
      if (this.default.sendRemindersTotal) {
        this.valueTotal = this.default.sendRemindersTotal;
      }
    }

  }

  onInputChange(event) {
    this.onSelection.emit({
      value: event.value,
      value2: this.value2,
      value3: this.value3,
      valueTotal: this.valueTotal,
      type: 'send-reminders'
    });
  }
  onInputChange2(event) {
    this.onSelection.emit({
      value: this.value,
      value2: event.value,
      value3: this.value3,
      valueTotal: this.valueTotal,
      type: 'send-reminders'
    });
  }
  onInputChange3(event) {
    this.onSelection.emit({
      value: this.value,
      value2: this.value2,
      value3: event.value,
      valueTotal: this.valueTotal,
      type: 'send-reminders'
    });
  }
  onInputChangeTotal(event) {
    this.onSelection.emit({
      valueTotal: event.value,
      value: this.value,
      value2: this.value2,
      value3: this.value3,
      type: 'send-reminders'
    });
  }


  handleBack() {
    this.onBackButton.emit('send-reminders');
  }
  controlSelected() {
    this.onDone.emit({ 
      value: this.value, 
      value2: this.value2,
      value3: this.value3,
      valueTotal: this.valueTotal, 
      type: 'send-reminders' });
  }
  descClicked(value) {
    this.flip = value;
  }
}
