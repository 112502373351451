import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';

import { AbsSharedModule } from '@abs/shared.module';
import {Register2Component} from './register-2.component';
import { SelectService } from '../../service/select.service';
import { MatSelectModule } from '@angular/material/select';
import { AvatarModule } from 'ngx-avatar';
import { UserAvatarModule } from '@abs/components/user-avatar/user-avatar.module';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatMenuModule } from '@angular/material/menu';
import { MatCardModule } from '@angular/material/card';


const routes = [
    {
        path     : 'signup',
        component: Register2Component
    }
];

@NgModule({
    declarations: [
        Register2Component
    ],
    imports     : [
        RouterModule.forChild(routes),
        MatButtonModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        AbsSharedModule,
        MatSelectModule,
        AvatarModule,
        UserAvatarModule,
        MatListModule,
        MatProgressSpinnerModule,
        MatMenuModule,
        MatCardModule
    ],
    exports:[Register2Component],
    providers: [ SelectService],
})
export class Register2Module
{
}
