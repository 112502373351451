import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { absAnimations } from '@abs/animations';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { AbsProgressBarService } from '@abs/components/progress-bar/progress-bar.service';
import { TrainingService } from '../../_services/training.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TrainerQuizPreviewComponent } from '../../../content/trainer-quiz-preview/trainer-quiz-preview.component';
import { Content } from '../../../content/content-list.model';
import { environment } from 'environments/environment';
import { ClassroomCourseResultComponent } from '../classroom-course-result/classroom-course-result.component';
import { AuthenticationService } from '../../../authentication/_services';

@Component({
  selector: 'app-course-completed-dialog',
  templateUrl: './course-completed-dialog.component.html',
  styleUrls: ['./course-completed-dialog.component.scss'],
  //encapsulation: ViewEncapsulation.None,
  animations: absAnimations
})
export class CourseCompletedDialogComponent implements OnInit {

  classId: any;
  pid: any;
  trainingInviteDialogRef:any;
  isLastVideo: boolean = false;
  quizURL = "";

  constructor(public matDialogRef: MatDialogRef<CourseCompletedDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private quizDailog: MatDialog,
    private courseResultMatDialog: MatDialog,
    public trainingInviteDialog: MatDialog,
    private _progressBarService: AbsProgressBarService,
    private _trainingService: TrainingService,
    private snackBar: MatSnackBar,
    private trainingService: TrainingService,
    private _authService: AuthenticationService
  ) {
    console.log(_data);
    this.classId = _data.classId;
    this.pid = _data.pid;
    this.isLastVideo = _data.last;


  }

  ngOnInit() {
  this.quizURL = `${environment.apiUrl}/quiz/pdf?contentId=${this.pid}&spanish=false&pid=${this._authService.currentUserValue.profileId}`;;
  }


  onQuizPreview() {
    this.quizDailog.open(TrainerQuizPreviewComponent, {
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%',
      panelClass: 'full-screen-modal',
      data: {
        content: new Content({id: this.pid})
      }
    });
  }

  nextCourse(){
    this._trainingService.sendEMail(this.classId,this.pid).then(result=>{
      console.log("email sent");
    })
    this.matDialogRef.close({last:this.isLastVideo});
  }

  closeSession(){
    this.trainingService.updateTrainingStatus(this.classId, "CLOSED", this.pid);
    this.matDialogRef.close({last:this.isLastVideo});
  }

  showClassroomResults() {
    this.courseResultMatDialog.open(ClassroomCourseResultComponent, {
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%',
      panelClass: 'full-screen-modal',
      data: {
        title: "Results",
        classId:this.classId ,
        productId: this.pid
      }
    });
  }

  sendLinks() {   
    this._progressBarService.show();
    this.snackBar.open("Generating Quiz Links...", '', {
      panelClass: '',
      horizontalPosition: 'center',
      verticalPosition: 'top',
      politeness: 'polite',
    });
     this._trainingService.sendQuizLinks(this.classId, this.pid).then(res => {
      this.snackBar.dismiss();
      this._progressBarService.hide();
      this.snackBar.open("Quiz Links has been sent.", '', {
        panelClass: 'green-bg',
        duration: 2000,
        horizontalPosition: 'right',
        verticalPosition: 'top',
        politeness: 'polite',
      });
      this.showClassroomResults();

    });
  }

}