import { Component, OnInit, Output, EventEmitter, Input, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { SelectionModel } from '@angular/cdk/collections';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AbsUtils } from '@abs/utils';
import { absAnimations } from '@abs/animations';
@Component({
  selector: 'app-grace-day-selector',
  templateUrl: './grace-day-selector.component.html',
  styleUrls: ['./grace-day-selector.component.scss'],
  animations: absAnimations,
  encapsulation: ViewEncapsulation.None

})
export class GraceDaySelectorComponent implements OnInit {

  value: any;
  flip:any;

  @Input()
  default: any;



  @Output() onBackButton = new EventEmitter<any>();
  @Output() onSelection = new EventEmitter<any>();
  @Output() onDone = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
    this.value = this.default;
  }

  onInputChange(event){
    this.onSelection.emit({value:event.value, type:'grace-days'});
  }

  handleBack(){
    this.onBackButton.emit('grace-days');
  }
  controlSelected(){
    this.onDone.emit({value:this.value, type:'grace-days'});
  }
  descClicked(value) {
    this.flip = value;
  }
}
