import { Component, OnInit, ViewEncapsulation, ViewChild, AfterViewInit, OnDestroy, ElementRef } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';
import { absAnimations } from '@abs/animations';
import { Playlist } from '../../playlists/playlists.model';
import { ContentService } from '../../content.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { AbsProgressBarService } from '@abs/components/progress-bar/progress-bar.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormControl } from '@angular/forms';
import { environment } from 'environments/environment';
import { takeUntil, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Content, Category, ContentType } from '../../content-list.model';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';



@Component({
  selector: 'app-playlist-users',
  templateUrl: './playlist-users.component.html',
  styleUrls: ['./playlist-users.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: absAnimations
})
export class PlaylistUsersComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('searchInput') searchEle: ElementRef;

  categories: Category[] = [];
  contentTypes: ContentType[] = [
    { value: 'all', name: 'All Content Types', selected: false },
    { value: 'video', name: 'Video', selected: true },
    { value: 'nonvideo', name: 'Non Video', selected: false }
  ];
  playlist: any;
  subCategories: Category[] = [];
  searchInputContent: FormControl;
  isLoading = true;
  selected = 'all';
  loginStatus = 'Last Login';
  displayedColumns: string[] = ['avatar', 'title', 'duration', 'rating', 'showActionBtn'];
  dataSource: any;
  selection = new SelectionModel<Content>(true, []);
  environment: any;
  contents: any;
  private _unsubscribeAll: Subject<any>;

  constructor(private _progressBarService: AbsProgressBarService,
    public _snackBar: MatSnackBar, public contentService: ContentService) {
    this.searchInputContent = new FormControl('');
    this.environment = environment;
    this._unsubscribeAll = new Subject();
  }

  ngOnInit() {

    var companyId = JSON.parse(localStorage.getItem("currentUser")).companyId;
    this.contentService.getContents(1, 1000, companyId).then(contents => {
      this.contents = contents;
      this.updateDataSource(contents);
    });

    this.contentService.getProductCategories().subscribe(category => {
      this.categories = category;
    });

    this.contentService.onSelectedPlaylistChanged.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(playlists => {
        console.log("initial", playlists)
        if (playlists) {
          this.playlist = playlists[0];
          this.searchAndUpdateContents();
        }
      });

    this.contentService.onContentChanged
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(contents => {
        console.log("content changed", contents);
        this.contents = contents;
        this.updateDataSource(contents);
      });

    this.searchInputContent.valueChanges
      .pipe(
        takeUntil(this._unsubscribeAll),
        debounceTime(300),
        distinctUntilChanged()
      )
      .subscribe(searchText => {
        this.contentService.onSearchTextChanged.next(searchText);
      });
  }

  contentTypeSelected($event, contentType): void {
    this.clearContentTypeSelection(contentType);
    contentType.selected = !contentType.selected;
    this.searchContent();

  }

  menuOpened() {
    this.searchEle.nativeElement.focus();

  }

  changeSelected($event, category): void {
    this.clearCategorySelection(category);
    if (category.subCategories) {
      this.subCategories = category.subCategories;
    } else {
      this.subCategories = [];

    }
    category.selected = !category.selected
    this.searchContent();
  }

  doFilter(event) {
    this.contentService.onFilterChanged.next(event.value);
  }

  clearCategorySelection(category) {
    this.categories.forEach(x => {
      if (x.id != category.id) {
        x.selected = false
      }
    }
    );
  }

  clearContentTypeSelection(contentType) {
    this.contentTypes.forEach(x => {
      if (x.value != contentType.value) {
        x.selected = false
      }
    });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  searchContent() {

    this._progressBarService.show();

    var contentType = this.contentTypes.filter(x => x.selected == true).map(x => {
      return x.value;
    });

    var cats = this.categories.filter(x => x.selected == true).map(x => {
      return x.id;
    });

    var subCats = this.subCategories.filter(x => x.selected == true).map(x => {
      return x.id;
    });



    if (cats.length > 0 && subCats.length == 0) {
      var allSubCats = this.subCategories.map(x => {
        return x.id;
      });
      cats.push(...allSubCats);
    }

    if (subCats.length > 0) {
      cats = subCats;
    }

    var search = { searchType: contentType[0], categories: cats, tags: [] };

    this.contentService.searchProductsByCateogories(search).subscribe(result => {
      this._progressBarService.hide();
      this.contents = result;
      this.updateDataSource(result);
      this.searchAndUpdateContents();

    })

  }

  searchAndUpdateContents() {
    if (this.playlist && this.playlist.contentList && !this.playlist.system && this.contents) {
      var contents = this.contents.map(content => {
        var found = this.playlist.contentList.find(pContent => pContent.id == content.id);
        if (found) {
          content.showActionBtn = true;
        } else {
          content.showActionBtn = false;
        }
        return content;
      });
      this.updateDataSource(contents);
    } else {
      if (this.contents) {
        var contents = this.contents.map(content => {
          content.showActionBtn = false;
          return content;
        });
        this.updateDataSource(contents);
      }
    }
  }

  addContentToPlaylist(content) {
    var contetns: Content[] = [];
    contetns.push(content);
    this._progressBarService.show();
    this.contentService.updatePlaylist(this.playlist, contetns).then(req => {
      this._progressBarService.hide();
      if (req == 1) {
        //this.playlist.contentList.push(content);
        this._snackBar.open(content.title + " has been added to " + this.playlist.name, 'Ok', {
          panelClass: 'green-bg',
          duration: 3000,
          horizontalPosition: 'right',
          verticalPosition: 'top',
          politeness: 'polite',
        });
      }
    });
  }

  removeContentFromPlaylist(content) {
    this._progressBarService.show();
    this.contentService.updatePlaylistUnassign(this.playlist.id, content.id).then(res => {
      this._progressBarService.hide();
      if (res) {
        this._snackBar.open(content.title + " has been removed from " + this.playlist.name, 'Ok', {
          panelClass: 'green-bg',
          duration: 3000,
          horizontalPosition: 'right',
          verticalPosition: 'top',
          politeness: 'polite',
        });
      }
    })
  }

  updateDataSource(contents) {
    this.dataSource = new MatTableDataSource(contents);
    this.dataSource.sort = this.sort;

    console.log("updated", this.sort);

    if (this.sort) {
      const sortState: Sort = { active: 'showActionBtn', direction: 'desc' };
      this.sort.active = sortState.active;
      this.sort.direction = sortState.direction;
      this.sort.sortChange.emit(sortState);
    }
    this.isLoading = false;
  }

  ngAfterViewInit() {
    // this.dataSource.sort = this.sort;
    //console.log("adte",this.sort);
  }
}

