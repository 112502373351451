import {Component, OnDestroy, OnInit, ViewEncapsulation, ViewChild} from '@angular/core';
import {SelectionModel} from '@angular/cdk/collections';
import {MatTableDataSource} from '@angular/material/table';
import {absAnimations} from '@abs/animations';
import {FormControl, FormGroup} from '@angular/forms';
import {Subject, of} from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import {debounceTime, distinctUntilChanged, takeUntil} from 'rxjs/operators';
import {PlaylistManagerFormComponent} from './playlist-manager-form/playlist-manager-form.component';
import { ContentService } from '../../content.service';
import { PlaylistManagerListComponent } from './playlist-manager-list/playlist-manager-list.component';
import { AbsProgressBarService } from '@abs/components/progress-bar/progress-bar.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AbsConfirmDialogComponent } from '@abs/components/confirm-dialog/confirm-dialog.component';
import { PlaylistManager } from './playlist-manager.model';
import { Playlist } from '../../playlists/playlists.model';
import { environment } from 'environments/environment';
import { DocumentViewerDialogComponent } from '../../../document-viewer-dialog/document-viewer-dialog.component';

@Component({
  selector: 'app-playlist-manager-all',
  templateUrl: './playlist-manager-all.component.html',
  styleUrls: ['./playlist-manager-all.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: absAnimations
})
export class PlaylistManagerAllComponent implements OnInit, OnDestroy {

  @ViewChild("contentList", { static: false }) contentList: PlaylistManagerListComponent;

  searchResults: any;
  confirmDialogRef: any;
  dialogRef: any;
  allowActions: boolean;
  searchInputPlaylist: FormControl;
  currentPlaylist: Playlist;
  selectedPlaylistFromSearch: any;
  canAssignTraining : boolean;

  // Private
  private _unsubscribeAll: Subject<any>;

  selected = 'All Playlists';

  /**
   * Constructor
   *
   * @param {ContentService} contentService
   * @param {MatDialog} _matDialog
   */
  constructor(
    private contentService: ContentService,
    private _matDialog: MatDialog,

    private _progressBarService: AbsProgressBarService,
    private snackBar: MatSnackBar
  ) {

    // Set the defaults
    this.searchInputPlaylist = new FormControl('');

    // Set the private defaults
    this._unsubscribeAll = new Subject();

  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit() {
    
    this.searchInputPlaylist.valueChanges
      .pipe(
        takeUntil(this._unsubscribeAll),
        distinctUntilChanged()
      )
      .subscribe(searchText => {
        this.contentService.onPlaylistSearchTextChanged.next(searchText);
      });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void
  {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  doFilter(event){
    console.log(event);
    this.contentService.onPlaylistFilterChanged.next(event.value);
  }

  onSearch(value){
    this.selectedPlaylistFromSearch = value;
    console.log("serch valueu", this.selectedPlaylistFromSearch);
  }




  editPlaylist(): void {
    const self = this;
    console.log("current",self.currentPlaylist);
    this.dialogRef = this._matDialog.open(PlaylistManagerFormComponent, {
      panelClass: 'group-form-dialog',
      data: {
        playlist: self.currentPlaylist,
        action: 'edit'
      }
    });

    this.dialogRef.afterClosed()
      .subscribe(response => {
        if (!response) {
          return;
        }
        const actionType: string = response[0];
        const formData: FormGroup = response[1];
        console.log(formData);
        switch (actionType) {
          /**
           * Save
           */
          case 'save':
            this._progressBarService.show();
            var list = new PlaylistManager({});
            list.id = formData.value.id;
            list.name = formData.value.name;
            this.contentService.editPlaylist(list).then(res => {
              this._progressBarService.hide();
              if (res == 1) {
                
                this.snackBar.open("Playlist has been updated.", 'Ok', {
                  panelClass: 'green-bg',
                  duration: 3000,
                  horizontalPosition: 'right',
                  verticalPosition: 'top',
                  politeness: 'polite',
                });
              } else {
                this.snackBar.open("Error while updating Playlist.", 'Ok', {
                  panelClass: 'red-bg',
                  duration: 3000,
                  horizontalPosition: 'right',
                  verticalPosition: 'top',
                  politeness: 'polite',
                });
              }
            });
            break;
          case 'delete':
           // this.deletePlaylist(list);
            break;
        }
      });
  }


  addPlaylist(): void {
    const self = this;
    this.dialogRef = this._matDialog.open(PlaylistManagerFormComponent, {
      panelClass: 'group-form-dialog',
      data: {
        playlist: self.currentPlaylist,
        action: 'new'
      }
    });

    this.dialogRef.afterClosed()
      .subscribe(response => {
        if (!response) {
          return;
        }
        const actionType: string = response[0];
        const formData: FormGroup = response[1];
        console.log(formData);
        switch (actionType) {
          /**
           * Save
           */
          case 'new':
            this._progressBarService.show();
            this.savePlaylist(formData.value.name);
            break;
          case 'delete':
           // this.deletePlaylist(list);
            break;
        }
      });
  }

  /**
   * Delete Playlist
   */
  deletePlaylist(): void {
    this.confirmDialogRef = this._matDialog.open(AbsConfirmDialogComponent, {
      panelClass: 'confirm-form-dialog',
      disableClose: false
    });

    this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to delete?';

    this.confirmDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._progressBarService.show();
        var list = new PlaylistManager({});
        list.id = this.currentPlaylist.id;
        list.name = this.currentPlaylist.name;
        list.deleted = true;
        this.contentService.editPlaylist(list).then(res => {
          this._progressBarService.hide();
          if (res == 1) {
            this.snackBar.open("Playlist has been deleted.", 'Ok', {
              panelClass: 'green-bg',
              duration: 3000,
              horizontalPosition: 'right',
              verticalPosition: 'top',
              politeness: 'polite',
            });
          } else {
            this.snackBar.open("Error while deleting Playlist.", 'Ok', {
              panelClass: 'red-bg',
              duration: 3000,
              horizontalPosition: 'right',
              verticalPosition: 'top',
              politeness: 'polite',
            });
          }
        });
      }
      this.confirmDialogRef = null;
    });

  }

  /**
   * New contact
   */
  newPlaylistsManager(): void
  {
    this.dialogRef = this._matDialog.open(PlaylistManagerFormComponent, {
      panelClass: 'group-form-dialog',
      data      : {
        action: 'new'
      }
    });

    this.dialogRef.afterClosed()
      .subscribe((response: FormGroup) => {
        if ( !response )
        {
          return;
        }

       // this.contentService.updatePlaylistsManager(response.getRawValue());
      });
  }

  savePlaylist(value): void {
    this.contentService.playlistSearchText = "";
    this._progressBarService.show();
      this.contentService.insertPlaylist(value, JSON.parse(localStorage.getItem("currentUser")).profileId).then(res => {
        this._progressBarService.hide();
        this.loadPlaylistData();
        this.snackBar.open("Playlist has been created.", '', {
          panelClass: 'green-bg',
          duration: 2000,
          horizontalPosition: 'right',
          verticalPosition: 'top',
          politeness: 'polite',
        });
      })
    
  }

  onPlaylistSelected(data){
    console.log("data",data);
    this.allowActions = data.selection;
    this.currentPlaylist = data.playlist;
  }

  onPlaylistSearch(data){
    console.log("data",data);
    this.searchResults = of(data);
  }

  loadPlaylistData(){
    this.contentService.getPlaylistGroupedWithContents().then(content => {
      this.contentService.onGroupedPlaylistsChanged.next(content);
    })
  }

 

}
