import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ValidatorFn, AbstractControl, ValidationErrors, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { User } from '../../models/user';
import { Subject } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog';
import { AbsProgressBarService } from '@abs/components/progress-bar/progress-bar.service';
import { UserService } from '../../service/user.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthenticationService } from '../../authentication/_services';

@Component({
  selector: 'app-secondary-password-dialog',
  templateUrl: './secondary-password-dialog.component.html',
  styleUrls: ['./secondary-password-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,

})
export class SecondaryPasswordDialogComponent implements OnInit {

  passwordForm: FormGroup;
  private _unsubscribeAll: Subject<any>;
  constructor(public matDialogRef: MatDialogRef<SecondaryPasswordDialogComponent>,private _formBuilder: FormBuilder,
    private _progressBarService: AbsProgressBarService,
    private _userService: UserService,
    private _snackBar: MatSnackBar,
  private _authenticationService: AuthenticationService) { }

  ngOnInit() {
    this.passwordForm = this._formBuilder.group({
      password: ['', {
        validators: Validators.required
      }
      ],
      passwordConfirm: ['',
        {
          validators: [Validators.required, confirmPasswordValidator]
        }
      ]
    });

    // Update the validity of the 'passwordConfirm' field
    /*   // when the 'password' field changes
       this.passwordForm.get('password').valueChanges
         .pipe(takeUntil(this._unsubscribeAll))
         .subscribe(() => {
           this.passwordForm.get('passwordConfirm').updateValueAndValidity();
         });
   */ 
  }

  updatePassword(){
    if (!this.passwordForm.get('passwordConfirm').hasError('required') &&
    !this.passwordForm.get('passwordConfirm').hasError('passwordsNotMatching')) {
      this._progressBarService.show();
      this._userService.updateSecondaryPassword(this.passwordForm.controls.passwordConfirm.value, this._authenticationService.currentUserValue.profileId).subscribe(response => {
        this._progressBarService.hide();
        this._snackBar.open("Your password has been set for kisok", 'OK', {
          panelClass: 'green-bg',
          duration: 2000,
          horizontalPosition: 'right',
          verticalPosition: 'top',
          politeness: 'polite',
        });
        this.matDialogRef.close('done');
      });;
    }
  }

}
/**
 * Confirm password validator
 *
 * @param {AbstractControl} control
 * @returns {ValidationErrors | null}
 */
export const confirmPasswordValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {

  if (!control.parent || !control) {
    return null;
  }

  const password = control.parent.get('password');
  const passwordConfirm = control.parent.get('passwordConfirm');

  if (!password || !passwordConfirm) {
    return null;
  }

  if (passwordConfirm.value === '') {
    return null;
  }

  if (password.value === passwordConfirm.value) {
    return null;
  }

  return { 'passwordsNotMatching': true };
};