import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { throwError, Observable, BehaviorSubject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { Quiz } from '../models';
import { AuthenticationService } from '../../../authentication/_services';



const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })
};

@Injectable({ providedIn: 'root' })
export class QuizService {

    onVideoTimeChanged: BehaviorSubject<any>;


    constructor(private http: HttpClient, private _authService:AuthenticationService) {
        this.onVideoTimeChanged = new BehaviorSubject(0);
    }

    getQuizDetail(contentId, spanishQuiz,trainingId=0) {
        let url = `${environment.apiUrl}/quiz/detail/` + contentId+"?pid="+this._authService.currentUserValue.profileId+"&cid="+trainingId;
        if (spanishQuiz) {
            url = `${environment.apiUrl}/quiz/detail/spanish/` + contentId;
        }
        return this.http.get<Quiz>(url).pipe(
            catchError(this.handleError)
        );;
    }


    saveQuiz(quiz: Quiz) {
        return this.http.post<any>(`${environment.apiUrl}/save/quiz`, JSON.stringify(quiz), httpOptions).pipe(
            catchError(this.handleError)
        );

    }

    handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
                `Backend returned code ${error.status}, ` +
                `body was: ${error.error}`);
        }
        // return an observable with a user-facing error message
        return throwError(
            'Something bad happened; please try again later.');
    };
}
