import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { AbsUtils } from '@abs/utils';
import { PlayList } from '../_models/playlist';
import { User } from '../../models/user';
import { environment } from 'environments/environment';
import { AdminTraining } from '../_models/admin-training.model';
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class MyCalendarService implements Resolve<any> {

  onCalendarDataChanged: BehaviorSubject<any>;
  onSelectedCalendarChanged: BehaviorSubject<any>;
  
  onSearchTextChanged: Subject<any>;
  onFilterChanged: Subject<any>;

  trainings: AdminTraining[];
  
  searchText: string;
  filterBy: string;


  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(private _httpClient: HttpClient) {
    // Set the defaults
    this.onCalendarDataChanged = new BehaviorSubject([]);
    this.onSelectedCalendarChanged = new BehaviorSubject([]);
   
    this.onSearchTextChanged = new Subject();
    this.onFilterChanged = new Subject();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Resolver
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Observable<any> | Promise<any> | any}
   */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return new Promise((resolve, reject) => {

      Promise.all([
        this.getTrainings(),
      ]).then(
        ([files]) => {

          this.onSearchTextChanged.subscribe(searchText => {
            this.searchText = searchText;
            this.getTrainings();
          });

          this.onFilterChanged.subscribe(filter => {
            this.filterBy = filter;
            this.getTrainings();
          });

          resolve();

        },
        reject
      );
    });
  }

  /**
   * Get locations
   *
   * @returns {Promise<any>}
   */
  getTrainings(): Promise<any> {
    return new Promise((resolve, reject) => {
    
      var profileId = JSON.parse(localStorage.getItem("currentUser")).profileId;
      this._httpClient.get(`${environment.apiUrl}/training/calendar/`+profileId, httpOptions)
        .subscribe((response: any) => {
          this.trainings = response;
          if (this.searchText && this.searchText !== '') {
            this.trainings = AbsUtils.filterArrayByString(this.trainings, this.searchText);
          }
          this.trainings = this.trainings.map(playlist => {
            return new AdminTraining(playlist);
          });
          this.onCalendarDataChanged.next(this.trainings);
          resolve(this.trainings);
        }, reject);
    }
    );
  }


  getTrainingAgendaByProfile(search): Promise<any> {
    return new Promise((resolve, reject) => {
    
      this._httpClient.post(`${environment.apiUrl}/training/agenda`,JSON.stringify(search), httpOptions)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    }
    );
    
  }

  getTrainingById(id): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.get(`${environment.apiUrl}/training/calendar/scheduler/`+id, httpOptions)
        .subscribe((response: any) => {         
          resolve(response);
        }, reject);
    }
    );
  }

  getTrainingStatsById(id): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.get(`${environment.apiUrl}/training/calendar/scheduler/stats/`+id, httpOptions)
        .subscribe((response: any) => {         
          resolve(response);
        }, reject);
    }
    );
  }



  getTrainingByIdGroupByCourse(id): Promise<any> {
    return new Promise((resolve, reject) => {
    
      this._httpClient.get(`${environment.apiUrl}/training/calendar/scheduler/group/course/`+id, httpOptions)
        .subscribe((response: any) => {         
          resolve(response);
        }, reject);
    }
    );
  }

  getTrainingByIdGroupByStudent(id): Promise<any> {
    return new Promise((resolve, reject) => {
    
      this._httpClient.get(`${environment.apiUrl}/training/calendar/scheduler/group/student/`+id, httpOptions)
        .subscribe((response: any) => {         
          resolve(response);
        }, reject);
    }
    );
  }
  
  scheduleTraining(training) {
    return new Promise((resolve, reject) => {
      this._httpClient.post(`${environment.apiUrl}/training`, JSON.stringify(training), httpOptions)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    }
    );
  }

  insertVideoLog(videoLog): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.post(`${environment.apiUrl}/video/log`, JSON.stringify(videoLog), httpOptions)
        .subscribe(response => {
          resolve(response);
        });
    });
  }

  updateVideoLog(videoLog): Promise<any> {
    console.log("going to update", videoLog);
    return new Promise((resolve, reject) => {
      this._httpClient.put(`${environment.apiUrl}/video/log/`+videoLog.id, JSON.stringify(videoLog), httpOptions)
        .subscribe(response => {
          resolve(response);
        });
    });
  }

}
