import { Component, OnInit, Output, EventEmitter, Input, ViewEncapsulation } from '@angular/core';
import { absAnimations } from '@abs/animations';
import { Question } from '../../../quiz/models';
import { Quiz } from 'app/main/content/quiz/models/quiz';
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";

export class QuestionData {
  type: string;
  question: any;
  index: number;
}

@Component({
  selector: 'app-quiz-question-summary',
  templateUrl: './quiz-question-summary.component.html',
  styleUrls: ['./quiz-question-summary.component.scss'],
  animations: absAnimations,
  encapsulation: ViewEncapsulation.None
})
export class QuizQuestionSummaryComponent implements OnInit {



  flip: any;
  @Output() onBackButton = new EventEmitter<any>();
  @Output() onSelection = new EventEmitter<any>();
  @Output() onDone = new EventEmitter<QuestionData>();

  @Input()
  quiz: Quiz;

  constructor() { }

  ngOnInit(): void {
  }
  descClicked(abc) {

  }

  handleBack() {
    this.onBackButton.next("question-type")
  }
  editQuestion(question,index) {
    var q = new Question(question);
    var data = new QuestionData();
    data.type = 'Edit';
    data.question = q;
    data.index = index;
    this.onDone.next(data)
  }
  onContinue(){
    var data = new QuestionData();
    data.type = 'submit';
    this.onDone.next(data)

  }
  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.quiz.questions, event.previousIndex, event.currentIndex);
  }

}