import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { AbsUtils } from '@abs/utils';
import { Content } from './content-list.model';
import { environment } from 'environments/environment';
import { catchError } from 'rxjs/operators';
import { Playlist } from './playlists/playlists.model';
import { User } from 'firebase';
import { PlayList } from '../training/_models/playlist';
import { SelectionModel, ArrayDataSource } from '@angular/cdk/collections';
import { AuthenticationService } from '../authentication/_services';


const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })
};

const httpCORSOptions = {
    headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET',
        'Access-Control-Allow-Credentials': 'true'

    })
};

@Injectable({ providedIn: 'root' })
export class ContentService {
    onContentChanged: BehaviorSubject<any>;
    onSelectedContentChanged: Subject<any>;
    onSelectedPlaylistChanged: BehaviorSubject<any>;

    onSearchTextChanged: Subject<any>;

    onPlaylistsChanged: BehaviorSubject<any>;
    onGroupedPlaylistsChanged: BehaviorSubject<any>;

    onPlaylistSearchTextChanged: Subject<any>;

    onFilterChanged: Subject<any>;

    onPlaylistFilterChanged: Subject<any>;


    videos: Content[];
    playlists: Playlist[];


    allVideos: Content[];
    allPlaylists: Playlist[];

    selectedContent: number[] = [];
    selectedPlaylist: Playlist[] = [];

    searchText: string;
    playlistSearchText: string;
    filterBy: string;

    playlistSelection = new SelectionModel<Playlist>(true, []);
    contentSelection = new SelectionModel<Content>(true, []);

    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient,
        private _authService: AuthenticationService
    ) {
        // Set the defaults
        this.onContentChanged = new BehaviorSubject([]);
        this.onSelectedContentChanged = new Subject();
        this.onSearchTextChanged = new Subject();
        this.onFilterChanged = new Subject();

        this.onPlaylistsChanged = new BehaviorSubject([]);
        this.onGroupedPlaylistsChanged = new BehaviorSubject([]);

        this.onPlaylistSearchTextChanged = new Subject();
        this.onSelectedPlaylistChanged = new BehaviorSubject(null);
        this.onPlaylistFilterChanged = new BehaviorSubject(null);

    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------



    /**
     * Get devices
     *
     * @returns {Promise<any>}
     */
    getContents(pageNo: number, pageSize: number, companyId): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(`${environment.apiUrl}/product/active/${companyId}` + `?pageNo=` + pageNo + `&pageSize=` + pageSize, httpOptions)
                .subscribe((response: any) => {
                    this.videos = response;
                    this.allVideos = response;
                    if (this.searchText && this.searchText !== '') {
                        this.videos = AbsUtils.filterArrayByString(this.videos, this.searchText);
                    }
                    this.onSearchTextChanged.subscribe(searchText => {
                        this.searchText = searchText;
                        if (this.searchText && this.searchText !== '') {
                            this.videos = this.allVideos.filter(list => {
                                return list.title.toLowerCase().includes(this.searchText);
                            });
                        } else {
                            this.videos = this.allVideos;
                        }
                        this.videos = this.videos.map(content => {
                            return new Content(content);
                        });

                        this.onContentChanged.next(this.videos);
                    });

                    this.onFilterChanged.subscribe(value => {
                        if (value && value !== '') {
                            if (value == 'video') {
                                this.videos = this.allVideos.filter(list => {
                                    return list.prodType == 'VIDEO';
                                });
                            } else if (value == 'nonvideo') {
                                console.log("nonvideo")
                                this.videos = this.allVideos.filter(list => {
                                    return list.prodType != 'VIDEO';
                                });
                                console.log(this.videos);
                            } else {
                                this.videos = this.allVideos;
                            }
                        }
                        this.videos = this.videos.map(content => {
                            return new Content(content);
                        });
                        this.onContentChanged.next(this.videos);
                    });


                    this.videos = this.videos.map(content => {
                        return new Content(content);
                    });
                    resolve(this.videos);
                }, reject);

        }
        );
    }


    /**
        * Get devices
        *
        * @returns {Promise<any>}
        */
    getRecentlyAddedContents(companyId): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(`${environment.apiUrl}/product/recent/${companyId}`, httpOptions)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        }
        );
    }

    getUsedContents(profileId): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(`${environment.apiUrl}/product/used/${profileId}`, httpOptions)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        }
        );
    }

    getPlaylistWithContents(): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(`${environment.apiUrl}/playlist/` + this._authService.currentUserValue.companyId + `/` + this._authService.currentUserValue.profileId, httpOptions)
                .subscribe((response: any) => {
                    this.playlists = response;
                    this.allPlaylists = response;
                    if (this.playlistSearchText && this.playlistSearchText !== '') {
                        this.playlists = AbsUtils.filterArrayByString(this.playlists, this.playlistSearchText);
                    }
                    this.onPlaylistSearchTextChanged.subscribe(playlistSearchText => {
                        console.log("")
                        this.playlistSearchText = playlistSearchText;
                        if (this.playlistSearchText && this.playlistSearchText !== '') {
                            this.playlists = AbsUtils.filterArrayByString(this.allPlaylists, this.playlistSearchText);
                        } else {
                            this.playlists = this.allPlaylists;
                        }
                        this.playlists = this.playlists.map(content => {
                            return new Playlist(content);
                        });

                        this.onPlaylistsChanged.next(this.playlists);
                    });

                    this.onPlaylistFilterChanged.subscribe(value => {
                        if (value && value !== '') {
                            if (value == 'system') {
                                this.playlists = this.allPlaylists.filter(list => {
                                    return list.system;
                                });
                            } else if (value == 'myplaylist') {
                                this.playlists = this.allPlaylists.filter(list => {
                                    return list.profileID == JSON.parse(localStorage.getItem("currentUser")).profileId;
                                });
                            } else {
                                this.playlists = this.allPlaylists;
                            }
                        }
                        this.playlists = this.playlists.map(content => {
                            return new Playlist(content);
                        });
                        this.onPlaylistsChanged.next(this.playlists);
                    });

                    this.playlists = this.playlists.map(content => {
                        return new Playlist(content);
                    });
                    resolve(this.playlists);
                }, reject);

        }
        );
    }

    getPlaylistGroupedWithContents(): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(`${environment.apiUrl}/playlist/grouped/` + this._authService.currentUserValue.companyId + `/` + this._authService.currentUserValue.profileId, httpOptions)
                .subscribe((response: any) => {
                    this.playlists = response;
                    this.allPlaylists = response;
                    this.onGroupedPlaylistsChanged.next(this.playlists);
                    resolve(this.playlists);
                }, reject);

        }
        );
    }

    getPlaylists(pageNo: number, pageSize: number) {
        return this._httpClient.get<any>(`${environment.apiUrl}/playlist/active/` + JSON.parse(localStorage.getItem("currentUser")).profileId + `?pageNo=` + pageNo + `&pageSize=` + pageSize, httpOptions).pipe(
            catchError(this.handleError)
        );;
    }

    checkIfProductBookmarked(contentId) {
        var profileId = this._authService.currentUserValue.profileId;
        return this._httpClient.get<any>(`${environment.apiUrl}/content/bookmarked/${profileId}/${contentId}`, httpOptions).pipe(
            catchError(this.handleError)
        );
    }

    bookmarkCourse(contentId) {
        var profileId = this._authService.currentUserValue.profileId;
        return this._httpClient.post<any>(`${environment.apiUrl}/content/bookmarked/${profileId}/${contentId}`, httpOptions).pipe(
            catchError(this.handleError)
        );
    }

    getKioskEnabledPlaylists() {
        return this._httpClient.get<any>(`${environment.apiUrl}/playlist/kiosk/` + this._authService.currentUserValue.profileId, httpOptions).pipe(
            catchError(this.handleError)
        );;
    }

    getFavoritePlaylists() {
        return this._httpClient.get<any>(`${environment.apiUrl}/playlist/favorite/` + this._authService.currentUserValue.profileId, httpOptions).pipe(
            catchError(this.handleError)
        );;
    }

    getPlaylistContents(playlistId: number, pageNo: number, pageSize: number) {
        return this._httpClient.get<any>(`${environment.apiUrl}/playlist/content/` + playlistId + `?pageNo=` + pageNo + `&pageSize=` + pageSize, httpOptions).pipe(
            catchError(this.handleError)
        );;
    }


    getVideos(pageNo: number, pageSize: number, companyId) {
        return this._httpClient.get<any>(`${environment.apiUrl}/product/active/` + companyId + `?pageNo=` + pageNo + `&pageSize=` + pageSize, httpOptions).pipe(
            catchError(this.handleError)
        );;
    }

    searchProducts(keywords: string) {
        return this._httpClient.get<any>(`${environment.apiUrl}/product/search?keywords=` + keywords+"&pr="+this._authService.currentUserValue.providerId+"&compId="+this._authService.currentUserValue.companyId, httpOptions).pipe(
            catchError(this.handleError)
        );;
    }

    searchProductsByCateogories(search) {
        return this._httpClient.post<any>(`${environment.apiUrl}/product/search/categories`, JSON.stringify(search), httpOptions).pipe(
            catchError(this.handleError)
        );;
    }

    insertContent(content) {
        return this._httpClient.post<any>(`${environment.apiUrl}/product`, JSON.stringify(content), httpOptions).pipe(
            catchError(this.handleError)
        );;
    }

    getSupplimentals(pageNo: number, pageSize: number) {
        return this._httpClient.get<any>(`${environment.apiUrl}/supplimentals/active/` + JSON.parse(localStorage.getItem("currentUser")).companyId + `?pageNo=` + pageNo + `&pageSize=` + pageSize, httpOptions).pipe(
            catchError(this.handleError)
        );;
    }

    getStreamUrl() {
        return this._httpClient.get<any>(`${environment.apiUrl}/stream/getstreamurl`).pipe(
            catchError(this.handleError)
        );;
    }

    getProductRating(productId) {
        return this._httpClient.get<any>(`${environment.apiUrl}/product/rating/` + productId).pipe(
            catchError(this.handleError)
        );;
    }

    getProductCategories() {
        return this._httpClient.get<any>(`${environment.apiUrl}/product/categories`).pipe(
            catchError(this.handleError)
        );;
    }
    getProductById(productId) {
        return this._httpClient.get<any>(`${environment.apiUrl}/product/${productId}`).pipe(
            catchError(this.handleError)
        );;
    }


    getShortUrl(originalUrl) {
        return this._httpClient.get<any>(`${environment.apiUrl}/short/link?url=${originalUrl}`)

    }

    getTags(productIds) {
        return this._httpClient.post<any>(`${environment.apiUrl}/tags`, productIds, httpOptions).pipe(
            catchError(this.handleError)
        );;
    }

    getContentPlaylistItems(contentId) {
        return this._httpClient.get<any>(`${environment.apiUrl}/product/playlist/` + contentId).pipe(
            catchError(this.handleError)
        );;
    }


    insertPlaylist(name, profileId): Promise<any> {
        var playlist = new PlayList({ name: name, profileID: profileId, companyId: this._authService.currentUserValue.companyId });
        return new Promise((resolve, reject) => {
            this._httpClient.post(`${environment.apiUrl}/playlist`, JSON.stringify(playlist), httpOptions)
                .subscribe(response => {
                    this.getPlaylistWithContents();
                    resolve(response);
                });
        });
    }

    editPlaylist(playlist): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.put(`${environment.apiUrl}/playlist`, JSON.stringify(playlist), httpOptions)
                .subscribe(response => {
                    this.getPlaylistGroupedWithContents().then(res => {
                    });
                    resolve(response);
                });
        });
    }


    deleteAndInsertPlaylistContent(playlists: any[], contentId): Promise<any> {
        var result = playlists.map(playlist => {
            return new AssignContent(playlist, contentId, 'product');
        });
        var merged = [].concat.apply([], result);
        return new Promise((resolve, reject) => {
            this._httpClient.post(`${environment.apiUrl}/playlist/content/` + contentId + `/assign`, JSON.stringify(merged), httpOptions)
                .subscribe(response => {
                    resolve(response);
                });
        });
    }

    updatePlaylist(playlist, contents: Content[]): Promise<any> {
        var result = contents.map(content => {
            return new AssignContent(playlist.id, content.id, 'product');
        });
        return new Promise((resolve, reject) => {
            this._httpClient.post(`${environment.apiUrl}/playlist/content/assign`, JSON.stringify(result), httpOptions)
                .subscribe(response => {
                    this.getPlaylistGroupedWithContents().then(res => {
                    });
                    resolve(response);
                });
        });
    }
    updatePlaylistUnassign(playlistId, contentId): Promise<any> {
        var assignUser = new AssignContent(playlistId, contentId, 'product');
        return new Promise((resolve, reject) => {
            this._httpClient.post(`${environment.apiUrl}/playlist/content/unassign`, JSON.stringify(assignUser), httpOptions)
                .subscribe(response => {
                    this.getPlaylistGroupedWithContents().then(res => {
                    });
                    resolve(response);
                });
        });
    }

    insertPreviewLog(previewLog): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(`${environment.apiUrl}/video/preview/log`, JSON.stringify(previewLog), httpOptions)
                .subscribe(response => {
                    resolve(response);
                });
        });
    }

    insertVideoLog(videoLog): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(`${environment.apiUrl}/video/log`, JSON.stringify(videoLog), httpOptions)
                .subscribe(response => {
                    resolve(response);
                });
        });
    }

    updateVideoLog(videoLog): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.put(`${environment.apiUrl}/video/log/` + videoLog.id, JSON.stringify(videoLog), httpOptions)
                .subscribe(response => {
                    resolve(response);
                });
        });
    }

    updatePlaylistKiosk(playlist): Promise<any> {
        playlist.profileID = this._authService.currentUserValue.profileId;
        return new Promise((resolve, reject) => {
            this._httpClient.put(`${environment.apiUrl}/playlist/kiosk`, JSON.stringify(playlist), httpOptions)
                .subscribe(response => {
                    resolve(response);
                });
        });
    }

    updatePlaylistKioskBatch(playlists): Promise<any> {

        playlists = playlists.map(p => {
            p.profileID = this._authService.currentUserValue.profileId
            return p;
        }
        )

        console.log("selected", playlists)
        return new Promise((resolve, reject) => {
            this._httpClient.post(`${environment.apiUrl}/playlist/kiosk/bulk`, JSON.stringify(playlists), httpOptions)
                .subscribe(response => {
                    resolve(response);
                });
        });
    }

    updatePlaylistFavorite(playlist): Promise<any> {
        playlist.profileID = this._authService.currentUserValue.profileId;
        return new Promise((resolve, reject) => {
            this._httpClient.put(`${environment.apiUrl}/playlist/favorite`, JSON.stringify(playlist), httpOptions)
                .subscribe(response => {
                    resolve(response);
                });
        });
    }

    togglePlaylistSelection(selection): void {
        this.playlistSelection = selection;
        this.onSelectedPlaylistChanged.next(this.playlistSelection.selected);
    }

    toggleContentSelection(selection): void {
        //let unique = [...new Set(selection.selected)] as Content[];
        this.contentSelection = selection
        this.onSelectedContentChanged.next(this.contentSelection.selected);
    }


    insertTags(tags): Promise<any> {
      
        return new Promise((resolve, reject) => {
            this._httpClient.post(`${environment.apiUrl}/content/tags`, JSON.stringify(tags), httpOptions)
                .subscribe(response => {
                    resolve(response);
                });
        });
    }


    handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
                `Backend returned code ${error.status}, ` +
                `body was: ${error.error}`);
        }
        // return an observable with a user-facing error message
        return throwError(
            'Something bad happened; please try again later.');
    };


}

export class AssignContent {

    playlistId: number;
    linkedId: number;
    linkType: string;
    constructor(platlistId, linkedId, linkType) {
        this.playlistId = platlistId;
        this.linkedId = linkedId;
        this.linkType = linkType;
    }
}