export class FileManagerFakeDb
{


    public static filessss = [
        {
            'id'              : '1',
            'type'     : 'folder',
            'title'            : 'Excel sheet',
            'handle'          : 'excel-sheet',
            'description'     : 'Officia amet eiusmod eu sunt tempor voluptate laboris velit nisi amet enim proident et. Consequat laborum non eiusmod cillum eu exercitation. Qui adipisicing est fugiat eiusmod esse. Sint aliqua cupidatat pariatur mollit ad est proident reprehenderit. Eiusmod adipisicing laborum incididunt sit aliqua ullamco.',
            'categories'      : [
                'Dresses',
                'Women'
            ],
            'tags'            : [
                'dress',
                'printed'
            ],
            'thumbnail'          : "",
            'rating' : '10',
            'dateCreated': 'Monday',
            'lastEdit': 'Tuesday'
        },
        {
            'id'              : '2',
            'type'     : 'folder',
            'title'            : 'Power point',
            'handle'          : 'power-point',
            'description'     : 'Officia amet eiusmod eu sunt tempor voluptate laboris velit nisi amet enim proident et. Consequat laborum non eiusmod cillum eu exercitation. Qui adipisicing est fugiat eiusmod esse. Sint aliqua cupidatat pariatur mollit ad est proident reprehenderit. Eiusmod adipisicing laborum incididunt sit aliqua ullamco.',
            'categories'      : [
                'Dresses',
                'Women'
            ],
            'tags'            : [
                'dress',
                'printed'
            ],
            'thumbnail'          : "",
            'rating' : '90',
            'dateCreated': 'Monday',
            'lastEdit': 'Tuesday'
        }
    ];

    public static files = [
        {
            'name'     : 'Work Documents',
            'type'     : 'folder',
            'owner'    : 'me',
            'size'     : '',
            'modified' : 'July 8, 2017',
            'opened'   : 'July 8, 2017',
            'created'  : 'July 8, 2017',
            'extention': '',
            'rating' : '10',
            'offline'  : true
        },
        {
            'name'     : 'Public Documents',
            'type'     : 'folder',
            'owner'    : 'public',
            'size'     : '',
            'modified' : 'July 8, 2017',
            'opened'   : 'July 8, 2017',
            'created'  : 'July 8, 2017',
            'extention': '',
            'rating' : '80',
            'offline'  : true
        },
        {
            'name'     : 'Private Documents',
            'type'     : 'folder',
            'owner'    : 'me',
            'size'     : '',
            'modified' : 'July 8, 2017',
            'opened'   : 'July 8, 2017',
            'created'  : 'July 8, 2017',
            'extention': '',
            'rating' : '50',
            'offline'  : true
        },
        {
            'name'     : 'Ongoing projects',
            'type'     : 'document',
            'owner'    : 'Emily Bennett',
            'size'     : '1.2 Mb',
            'modified' : 'July 8, 2017',
            'opened'   : 'July 8, 2017',
            'created'  : 'July 8, 2017',
            'extention': '',
            'rating' : '99',
            'offline'  : true,
            'preview'  : 'assets/images/etc/sample-file-preview.jpg'
        },
        {
            'name'     : 'Shopping list',
            'type'     : 'document',
            'owner'    : 'Emily Bennett',
            'size'     : '980 Kb',
            'modified' : 'July 8, 2017',
            'opened'   : 'July 8, 2017',
            'created'  : 'July 8, 2017',
            'extention': '',
            'rating' : '92',
            'offline'  : true,
            'preview'  : 'assets/images/etc/sample-file-preview.jpg'
        },
        {
            'name'     : 'Invoices',
            'type'     : 'spreadsheet',
            'owner'    : 'Emily Bennett',
            'size'     : '750 Kb',
            'modified' : 'July 8, 2017',
            'opened'   : 'July 8, 2017',
            'created'  : 'July 8, 2017',
            'extention': '',
            'rating' : '85',
            'offline'  : true,
            'preview'  : 'assets/images/etc/sample-file-preview.jpg'
        },
        {
            'name'     : 'Crash logs',
            'type'     : 'document',
            'owner'    : 'Emily Bennett',
            'size'     : '980 Mb',
            'modified' : 'July 8, 2017',
            'opened'   : 'July 8, 2017',
            'created'  : 'July 8, 2017',
            'extention': '',
            'rating' : '60',
            'offline'  : true,
            'preview'  : 'assets/images/etc/sample-file-preview.jpg'
        },
        {
            'name'     : 'System logs',
            'type'     : 'document',
            'owner'    : 'Emily Bennett',
            'size'     : '52 Kb',
            'modified' : 'July 8, 2017',
            'opened'   : 'July 8, 2017',
            'created'  : 'July 8, 2017',
            'extention': '',
            'rating' : '70',
            'offline'  : true,
            'preview'  : 'assets/images/etc/sample-file-preview.jpg'
        },
        {
            'name'     : 'Prices',
            'type'     : 'spreadsheet',
            'owner'    : 'Emily Bennett',
            'size'     : '27 Mb',
            'modified' : 'July 8, 2017',
            'opened'   : 'July 8, 2017',
            'created'  : 'July 8, 2017',
            'extention': '',
            'rating' : '20',
            'offline'  : true,
            'preview'  : 'assets/images/etc/sample-file-preview.jpg'
        },
        {
            'name'     : 'Anabelle Manual',
            'type'     : 'document',
            'owner'    : 'Emily Bennett',
            'size'     : '1.1 Kb',
            'modified' : 'July 8, 2017',
            'opened'   : 'July 8, 2017',
            'created'  : 'July 8, 2017',
            'extention': '',
            'rating' : '5',
            'offline'  : true,
            'preview'  : 'assets/images/etc/sample-file-preview.jpg'
        },
        {
            'name'     : 'Steam summer sale budget',
            'type'     : 'spreadsheet',
            'owner'    : 'Emily Bennett',
            'size'     : '505 Kb',
            'modified' : 'July 8, 2017',
            'opened'   : 'July 8, 2017',
            'created'  : 'July 8, 2017',
            'extention': '',
            'rating' : '35',
            'offline'  : true,
            'preview'  : 'assets/images/etc/sample-file-preview.jpg'
        }
    ];

    public static categories: [
        {
            'id'   : '26022e4129ad3a5sc28b36ccc',
            'name' : 'CDL',
            'color': 'red-500'
        },
        {
            'id'   : '26022e4129ad3a5sc28b36cbb',
            'name' : 'OSHA Compliance',
            'color': 'red-500'
        },
        {
            'id'   : '26022e4129ad3a5sc28b36cee',
            'name' : 'Everyday Driver',
            'color': 'red-500'
        },{
            'id'   : '26022e4129ad3a5sc28b36cdff',
            'name' : 'Professional Driver',
            'color': 'red-500'
        },{
            'id'   : '26022e4129ad3a5sc28b36cdgg',
            'name' : 'Video',
            'color': 'red-500'
        },{
            'id'   : '26022e4129ad3a5sc28b36cdhh',
            'name' : 'EAP',
            'color': 'red-500'
        },{
            'id'   : '26022e4129ad3a5sc28b36cdii',
            'name' : 'Contractor',
            'color': 'red-500'
        },{
            'id'   : '26022e4129ad3a5sc28b36cdjj',
            'name' : 'Behavioral',
            'color': 'red-500'
        },{
            'id'   : '26022e4129ad3a5sc28b36cdkk',
            'name' : 'Groudkeeping Safety',
            'color': 'red-500'
        }
        
    ];


}
