import { FileManagerService } from '../../my-uploads/my-uploads.service';
import { Component, OnInit, ViewEncapsulation, Output, EventEmitter, Input } from '@angular/core';
import { absAnimations } from '@abs/animations';
import { FormControl } from '@angular/forms';
import { SelectionModel } from '@angular/cdk/collections';
import { AuthenticationService } from '../../../authentication/_services';
import { GroupsService } from '../../../user/groups/groups.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AbsUtils } from '@abs/utils';
import { ContentService } from '../../content.service';
import { of } from 'rxjs';
import { environment } from 'environments/environment';
import { FileData } from '../content-wizard.component';
@Component({
  selector: 'app-content-thumbnail-selector',
  templateUrl: './content-thumbnail-selector.component.html',
  styleUrls: ['./content-thumbnail-selector.component.scss'],
  animations: absAnimations,
  encapsulation: ViewEncapsulation.None
})
export class ContentThumbnailSelectorComponent implements OnInit {
  flip: any;
  videos: any;
  enviorment: any;

  @Input() fileData: FileData

  @Input()
  categories: any;

  selected: any;

  @Output() onBackButton = new EventEmitter<any>();
  @Output() onSelection = new EventEmitter<any>();
  @Output() onDone = new EventEmitter<any>();
  constructor(private contentService: ContentService, private _authService: AuthenticationService) {
    this.enviorment = environment
  }

  ngOnInit(): void {
    console.log("this.fileData.subCategories")
    console.log("this.fileData",this.fileData);
    var allCats = [];
    var categoriesId = this.categories.map(x => {
      return x.id;
    }); 
    allCats = categoriesId;
    
    if(this.fileData && this.fileData.subCategories){

      var subCategories = this.fileData.subCategories.map(x => {
        return x.id;
      }); 
      allCats = categoriesId.concat(subCategories);
    }
    if (this.categories) {
      var subCategories = this.categories.map(category => {
        if(category.subCategories){
        category.subCategories.map(x => {
          allCats.push(x.id)
        });
      }
      });
    }

    console.log("categoriesId",allCats);
    
    var search = {
      searchType: 'video',
      categories: allCats,
      providerId: this._authService.currentUserValue.providerId,
      companyId: this._authService.currentUserValue.companyId,
      profileId: this._authService.currentUserValue.profileId
    };

    this.contentService.searchProductsByCateogories(search).subscribe(result => {
      this.videos = of(result);

    })
  }



  onContinue() {
    this.onDone.next({ data: this.selected, type: 'THUMB' })
  }
  handleBack() {
    this.onBackButton.next("cateogry")
  }
  descClicked(abc) {

  }

  onImageSelect(video) {
    this.selected = video;

  }
  onImgError(event) {
    event.target.src = this.enviorment.cdnUrl + '/' + this.enviorment.thumbDir + '/T_.jpg';
  }

}
