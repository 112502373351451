import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AbsSearchBarModule, AbsShortcutsModule, AbsUserModule } from '@abs/components';
import { AbsSharedModule } from '@abs/shared.module';

import { ToolbarComponent } from 'app/layout/components/toolbar/toolbar.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { TourMatMenuModule } from 'ngx-tour-md-menu';
import { MatBadgeModule } from '@angular/material/badge';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { SiteHelpDialogModule } from '../../../main/site-help-dialog/site-help-dialog.module';
import { SiteHelpDialogComponent } from '../../../main/site-help-dialog/site-help-dialog.component';
import { ShoppingExperienceDialogComponent } from '../../../main/widgets/shopping-experience-dialog/shopping-experience-dialog.component';
import { WidgetsModule } from '../../../main/widgets/widgets.module';
import { ClassroomStartCountdownComponent } from '../../../main/training/classroom-start-countdown/classroom-start-countdown.component';
import { TrainingModule } from '../../../main/training/training.module';

@NgModule({
    declarations: [
        ToolbarComponent
    ],
    imports     : [
        RouterModule,
        MatButtonModule,
        MatIconModule,
        MatMenuModule,
        MatToolbarModule,
        MatTooltipModule,
        MatSlideToggleModule,
        MatProgressBarModule,
        AbsSharedModule,
        AbsSearchBarModule,
        AbsShortcutsModule,
        AbsUserModule,
        MatSnackBarModule,
        TourMatMenuModule,
        MatBadgeModule,
        SiteHelpDialogModule,
        WidgetsModule,
        TrainingModule
    ],
    exports     : [
        ToolbarComponent
    ],
    entryComponents:[SiteHelpDialogComponent,ShoppingExperienceDialogComponent,ClassroomStartCountdownComponent]
})
export class ToolbarModule
{
}
