import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import * as Stomp from 'stompjs';
import * as SockJS from 'sockjs-client';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TrainingService } from '../../_services/training.service';
import { environment } from 'environments/environment';
import { AuthenticationService } from '../../../authentication/_services';

export interface AttendeeResult {

  profileId: number;
  firstName: String;
  lastName: String;
  passed: boolean;
  quizCompletedOn: Date;
  quizStartedOn: Date;
  score: number;
  status: String;

}

export interface CourseResult {

  results: AttendeeResult[]
  passed: number;
  failed: number;
  noShow: number;
  notStarted: number;
  inProgress: number;
  totalAttendees: number;
  courseCompleted: boolean;

};

@Component({
  selector: 'app-classroom-course-result',
  templateUrl: './classroom-course-result.component.html',
  styleUrls: ['./classroom-course-result.component.scss'],
  encapsulation: ViewEncapsulation.None,

})
export class ClassroomCourseResultComponent implements OnInit {

  private serverUrl = `${environment.apiUrl}/socket`;
  private stompClient;
  result: CourseResult;

  dataSource: any;
  columnsToDisplay = ['lastName', 'firstName', 'completed', 'score', 'result'];

  title: any;
  classId: any;
  productId: any;

  constructor(public matDialogRef: MatDialogRef<ClassroomCourseResultComponent>, 
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private _trainingService: TrainingService,
  private _authService: AuthenticationService) { 
      console.log("result");
      this.title = _data.title;
      this.classId = _data.classId;
      this.productId = _data.productId;

    }

  ngOnInit() {
    this.initializeWebSocketConnection();
    this._trainingService.getTrainingCourseResult(this.classId,this.productId).then(res=>{
      this.result = res;
      this.dataSource = new MatTableDataSource(this.result.results);
    });
  }


  initializeWebSocketConnection() {
    let ws = new SockJS(this.serverUrl);
    this.stompClient = Stomp.over(ws);
    let that = this;
    this.stompClient.connect({}, function (frame) {
      that.stompClient.subscribe("/training/class/"+that.classId+"/course/result", (message) => {
        console.log(message);
        if (message.body) {
          that.result = JSON.parse(message.body);
          that.dataSource = new MatTableDataSource(that.result.results);
          if (that.result.courseCompleted) {
            //that.matDialogRef.close("completed");
          }
        }
      });
    });
  }
}
