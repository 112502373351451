import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Playlist } from '../../content/playlists/playlists.model';
import { ContentService } from '../../content/content.service';
import { SelectionModel } from '@angular/cdk/collections';
import { AbsProgressBarComponent } from '@abs/components/progress-bar/progress-bar.component';
import { AbsProgressBarService } from '@abs/components/progress-bar/progress-bar.service';
import { absAnimations } from '@abs/animations';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AbsUtils } from '@abs/utils';

@Component({
  selector: 'app-kiosk-playlist-selector',
  templateUrl: './kiosk-playlist-selector.component.html',
  styleUrls: ['./kiosk-playlist-selector.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: absAnimations
})
export class KioskPlaylistSelectorComponent implements OnInit {

  flip: any = false;

  playlists: Playlist[];
  all: Playlist[];
  selection = new SelectionModel<any>(true, []);
  selectionPlaylist = new SelectionModel<any>(true, []);
  searchCtrlPlaylist = new FormControl();

  constructor(public matDialogRef: MatDialogRef<KioskPlaylistSelectorComponent>,
    private contentService: ContentService,
    private progressBar: AbsProgressBarService,
    @Inject(MAT_DIALOG_DATA) private _data: any) {

  }

  onPlaylistSelectionChanged(playlist) {
    console.log("play", playlist);
    console.log("this.selectionPlaylist.selected", this.selectionPlaylist.selected)
    this.selection.toggle(playlist.id);
    if (this.selection.isSelected(playlist.id)) {
      this.selectionPlaylist.select(playlist);
    } else {
      this.selectionPlaylist.deselect(playlist);

    }
    console.log("this.selectionPlaylist.selected after", this.selectionPlaylist.selected)

  }

  onContinue() {
    this.progressBar.show();
    this.contentService.updatePlaylistKioskBatch(this.selectionPlaylist.selected).then(res => {
      this.progressBar.hide();
      this.matDialogRef.close('reload');
    })
  }

  descClicked(value) {
    this.flip = value;
  }

  ngOnInit(): void {
    this.contentService.getPlaylistWithContents().then(playlist => {
      this.playlists = playlist;
      this.all = playlist;
    })

    this.searchCtrlPlaylist.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged()
    ).subscribe(value => {
      if (value.length > 0) {
        var result = this.all.filter(p => p.name.toLocaleLowerCase().includes(value.toLocaleLowerCase()));
        this.playlists = result;
      } else {
        this.playlists = this.all;

      }
    });
  }



}
