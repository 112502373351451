import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { AbsUtils } from '@abs/utils';
import { Group, GroupedGroup } from './groups-all/group.model.js';
import { environment } from 'environments/environment';
import { User } from '../../models/user.js';
import { SelectionModel } from '@angular/cdk/collections';
import { AuthenticationService } from '../../authentication/_services/index.js';
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class GroupsService implements Resolve<any> {

  public onGroupsChanged: BehaviorSubject<any>;
  public onSelectedGroupsChanged: BehaviorSubject<any>;
  public onSelectedUsersChanged: BehaviorSubject<any>;
  public onUserDataChanged: BehaviorSubject<any>;
  public onSearchTextChanged: Subject<any>;
  public onUserSearchTextChanged: Subject<any>;
  public onFilterChanged: Subject<any>;
  public onSelectedUserGroupChanged: BehaviorSubject<any>;


  groups: Group[];
  users: User[];
  allGroups: Group[];
  allUsers: User[];
  selectedGroups: number[] = [];
  selectedUsers: number[] = [];

  searchText: string;
  userSearchtext: string;
  filterBy: string;

  groupSelection = new SelectionModel<Group>(true, []);
  userSelection = new SelectionModel<User>(true, []);

  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(
    private _httpClient: HttpClient,
    private _authService: AuthenticationService
  ) {
    // Set the defaults
    this.onGroupsChanged = new BehaviorSubject([]);
    this.onSelectedGroupsChanged = new BehaviorSubject(null);
    this.onUserDataChanged = new BehaviorSubject([]);
    this.onSearchTextChanged = new Subject();
    this.onFilterChanged = new Subject();
    this.onUserSearchTextChanged = new Subject();
    this.onSelectedUsersChanged = new BehaviorSubject([]);

  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Resolver
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Observable<any> | Promise<any> | any}
   */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return new Promise((resolve, reject) => {

      Promise.all([
        this.getGroups(),
        // this.getUsers()
      ]).then(
        ([files]) => {
          this.onSearchTextChanged.subscribe(searchText => {
            this.groups = AbsUtils.filterArrayByString(this.allGroups, searchText);
            this.onGroupsChanged.next(this.groups);
          });

          this.onUserSearchTextChanged.subscribe(userSearchtext => {
            this.users = AbsUtils.filterArrayByString(this.allUsers, userSearchtext);
            this.onUserDataChanged.next(this.users);

          });
          this.onFilterChanged.subscribe(filter => {
            this.filterBy = filter;
            this.getGroups();
          });
          resolve();
        },
        reject
      );
    });
  }

  /**
   * Get groups
   *
   * @returns {Promise<any>}
   */
  getGroups(): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.get(`${environment.apiUrl}/user/groups/` + JSON.parse(localStorage.getItem("currentUser")).companyId + ``, )
        .subscribe((response: any) => {
          this.groups = response;
          this.allGroups = response;
          if (this.searchText && this.searchText !== '') {
            this.groups = AbsUtils.filterArrayByString(this.groups, this.searchText);
          }
          this.onSearchTextChanged.subscribe(searchText => {
            this.groups = AbsUtils.filterArrayByString(this.allGroups, searchText);
            this.onGroupsChanged.next(this.groups);
          });

          this.groups = this.groups.map(group => {
            return new Group(group);
          });
          this.onGroupsChanged.next(this.groups);
          resolve(this.groups);
        }, reject);
    }
    );
  }

  /**
   * Get groups
   *
   * @returns {Promise<any>}
   */
  getGroupedGroups(): Promise<GroupedGroup> {
    return new Promise((resolve, reject) => {
      this._httpClient.get(`${environment.apiUrl}/user/groups/grouped/` + JSON.parse(localStorage.getItem("currentUser")).companyId + `/` +JSON.parse(localStorage.getItem("currentUser")).profileId, )
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    }
    );
  }

  updateGroupFavorite(group): Promise<any> {
    return new Promise((resolve, reject) => {
        this._httpClient.put(`${environment.apiUrl}/user/group/favorite`, JSON.stringify(group), httpOptions)
            .subscribe(response => {
              this.getGroupedGroups().then(groups=>{
                this.onGroupsChanged.next(groups);
              })
                resolve(response);
            });
    });
}

  getUsers(): Promise<any> {
    console.log("called");
    return new Promise((resolve, reject) => {
      this._httpClient.get(`${environment.apiUrl}/users/company/` + JSON.parse(localStorage.getItem("currentUser")).companyId + ``)
        .subscribe((response: any) => {
          this.users = response;
          this.allUsers = response;
          if (this.userSearchtext && this.userSearchtext !== '') {
            this.users = AbsUtils.filterArrayByString(this.users, this.userSearchtext);
          }
          this.onUserSearchTextChanged.subscribe(userSearchtext => {
            this.users = AbsUtils.filterArrayByString(this.allUsers, userSearchtext);
            this.onUserDataChanged.next(this.users);
          });
          this.users = this.users.map(user => {
            return new User(user);
          });
          this.onUserDataChanged.next(this.users);
          resolve(this.users);
        }, reject);
    }
    );
  }

  


  /**
     * Toggle selected group by id
     *
     * @param id
     */
  toggleGroupSelection(selection): void {

    console.log("toggleGroupSelection",selection);
    this.groupSelection = selection;
    console.log("toggleGroupSelection selected",this.groupSelection.selected);
    this.onSelectedGroupsChanged.next(this.groupSelection.selected);
  }

  toggleUserSelection(selection): void {
    this.userSelection = selection;
    this.onSelectedUsersChanged.next(this.userSelection.selected);
  }

  toggleSelecteduser(id): void {
    console.log(id);
    // First, check if we already have that group as selected...
    if (this.selectedUsers.length > 0) {
      const index = this.selectedUsers.indexOf(id);

      if (index !== -1) {
        this.selectedUsers.splice(index, 1);

        // Trigger the next event
        this.onSelectedUsersChanged.next(this.selectedUsers);

        // Return
        return;
      }
    }
    // If we don't have it, push as selected
    this.selectedUsers.push(id);
    this.onSelectedUsersChanged.next(this.selectedUsers);
  }

  
  /**
   * Update group
   *
   * @param group
   * @returns {Promise<any>}
   */
  updateGroup(users: User[], group): Promise<any> {
    var result = users.map(user => {
      return new AssignUser(user.profileId, group.groupID);
    })
    return new Promise((resolve, reject) => {
      this._httpClient.post(`${environment.apiUrl}/user/group/assign`, JSON.stringify(result), httpOptions)
        .subscribe(response => {  
          this.selectedGroups = [];
          this.getGroupedGroups().then(groups=>{
            this.onGroupsChanged.next(groups);
          })
          resolve(response);
        });
    });
  }

  updateUserProfileGruops(profileId, groups): Promise<any> {
    var result = groups.map(g => {
      return new AssignUser(profileId, g);
    })
    return new Promise((resolve, reject) => {
      this._httpClient.post(`${environment.apiUrl}/user/group/bulk`, JSON.stringify(result), httpOptions)
        .subscribe(response => {
          resolve(response);
        });
    });
  }

  updateGroupUnassign(profileId, groupId): Promise<any> {
    var assignUser = new AssignUser(profileId, groupId);
    return new Promise((resolve, reject) => {
      this._httpClient.post(`${environment.apiUrl}/user/group/unassign`, JSON.stringify(assignUser), httpOptions)
        .subscribe(response => {
          this.getGroupedGroups().then(groups=>{
            this.onGroupsChanged.next(groups);
          })
          resolve(response);
        });
    });
  }

  insertUserGroup(userGroup): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.post(`${environment.apiUrl}/user/group`, JSON.stringify(userGroup), httpOptions)
        .subscribe(response => {
          this.getGroupedGroups().then(groups=>{
            this.onGroupsChanged.next(groups);
          })
          resolve(response);
        });
    });
  }

  updateUserGroup(userGroup): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.put(`${environment.apiUrl}/user/group`, JSON.stringify(userGroup), httpOptions)
        .subscribe(response => {
          this.getGroupedGroups().then(groups=>{
            this.onGroupsChanged.next(groups);
          })
          resolve(response);
        });
    });
  }
  /**
   * Delete group
   *
   * @param group
   */
  deleteGroup(group): void {
    const groupIndex = this.groups.indexOf(group);
    this.groups.splice(groupIndex, 1);
    this.onGroupsChanged.next(this.groups);
  }

   /**
   * Get favorite
   *
   * @returns {Promise<any>}
   */
  getFavoriteGroups(): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.get(`${environment.apiUrl}/user/favorite/groups/`+this._authService.currentUserValue.profileId+ ``, )
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    }
    );
  }

  getSystemGroups(): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.get(`${environment.apiUrl}/user/system/groups/`+this._authService.currentUserValue.companyId+ `/`+this._authService.currentUserValue.profileId, )
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    }
    );
  }

  getOtherPeopleGroups(): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.get(`${environment.apiUrl}/user/others/groups/`+this._authService.currentUserValue.companyId+ `/`+this._authService.currentUserValue.profileId, )
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    }
    );
  }

  getMyGroups(): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.get(`${environment.apiUrl}/user/my/groups/`+this._authService.currentUserValue.profileId+ ``, )
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    }
    );
  }

  getDepartmentGroups(): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.get(`${environment.apiUrl}/user/department/groups/`+this._authService.currentUserValue.companyId+ ``, )
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    }
    );
  }
  getLocationGroups(): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.get(`${environment.apiUrl}/user/location/groups/`+this._authService.currentUserValue.companyId+ ``, )
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    }
    );
  }
}


export class AssignUser {

  profileId: number;
  groupId: number;

  constructor(profileId, groupId) {
    this.profileId = profileId;
    this.groupId = groupId;
  }

}