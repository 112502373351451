import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DocumentViewerDialogComponent } from './document-viewer-dialog.component';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { AbsSharedModule } from '@abs/shared.module';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatRippleModule } from '@angular/material/core';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ImageViewerModule } from 'ng2-image-viewer';



@NgModule({
  declarations: [DocumentViewerDialogComponent],
  imports: [
    CommonModule,
    AbsSharedModule,

  
    MatIconModule,
    MatButtonModule,

    MatTooltipModule,
  
    MatRippleModule,
    MatTableModule,
    MatToolbarModule,

    MatSnackBarModule,
  
   
    NgxDocViewerModule,
    PdfViewerModule,
    ImageViewerModule
  ],
  exports:[DocumentViewerDialogComponent],
  entryComponents: [DocumentViewerDialogComponent], 
   
})
export class DocumentViewerDialogModule { }
