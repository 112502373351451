import { Component, OnInit, Output, EventEmitter, Input, SimpleChanges } from '@angular/core';
import { absAnimations } from '@abs/animations';
import { TrainingStyle } from '../_models/training';


export class SharedData {

  startDate: any;
  endDate: any;
  selectedDate: any;
  startTime: any;
  endTime: any;
  step: any;
  location: any;
  contents: any;
  frequency: any;
  trainingType: any;
  repeat: any;
}

@Component({
  selector: 'app-assign-wizard-reborn',
  templateUrl: './assign-wizard-reborn.component.html',
  styleUrls: ['./assign-wizard-reborn.component.scss'],
  animations: absAnimations

})
export class AssignWizardRebornComponent implements OnInit {
  readonly TrainingStyle = TrainingStyle;
  flip: any;
  simpleTrainingMode = '';
  showUserList: boolean = false;
  stage = 'UserSelection';
  users: any;
  currentView = "save";
  currentStep = 1;
  optionStage = ''
  contents: any;
  selectedPlaylist: any;
  startDate: any;
  endDate: any;
  showContentList: boolean = false;
  selectedDate: any;
  startTime: any;
  endTime: any;
  location: any;
  trainingMethod: any;
  title: any;
  selectedGroups: any;
  automatedSchedule: any;
  triggerType: any = '';
  groupType:any;
  playlistGroupType:any;
  timeToComplete: any;
  trainingType: any;

  sharedDate: SharedData = new SharedData();

  @Input()
  goTo;

  @Input()
  schedulingDone = false;

  @Output() onBackButton = new EventEmitter<any>();
  @Output() dataChanged = new EventEmitter<any>();
  @Output() onDone = new EventEmitter<any>();

  constructor() {

  }

  ngOnInit(): void {
  }
  handleBack() {
    this.onBackButton.emit("assignBack");
  }

  setSimpleTrainingMode(mode) {
    this.simpleTrainingMode = mode;
    console.log("simple training mode", mode);
    if (this.simpleTrainingMode == 'AUTOMATED') {
      this.stage = 'TriggerSelection';
    }
    if (this.simpleTrainingMode == 'FREQ_RECURING') {
      this.stage = 'RecurringTrainingMethodSelection';
    }
    if (this.simpleTrainingMode == 'SCHEDULE') {
      this.stage = 'UserSelection';
    }

    this.dataChanged.emit({ value: this.simpleTrainingMode, type: 'TRAINING_MODE_CHANGED' })

  }

  onUserSelectionDone(value) {
    this.stage = "ContentSelection"
    this.users = value.users;
    this.selectedGroups = value.groups;
  }

  onCourseSelectionDone(value) {
    console.log("onCourseSelectionDone", value);
    /*  if (this.simpleTrainingMode == 'AUTOMATED') {
       this.stage = "SaveAndActivate"
     } else {
       this.stage = "TrainingMethodSelection"
 
     } */
    this.stage = "TrainingMethodSelection"
    this.contents = value.content;
    this.selectedPlaylist = value.playlists;
    this.sharedDate.contents = this.contents;

  }

  trainingMethodSelection(event) {
    this.trainingMethod = event;
    this.stage = "trainingdata";
    this.dataChanged.emit({ value: this.trainingMethod, type: 'TRAINING_STYLE_CHANGED' })
  }

  onTrainingIndependentDone(data) {
    this.startDate = data.startDate;
    this.endDate = data.endDate;
    this.stage = "SaveAndActivate"
    this.automatedSchedule = data.automatedSchedule;

    this.sharedDate.startDate = data.startDate;
    this.sharedDate.endDate = data.endDate;
    console.log("simpleTrainingMode", this.simpleTrainingMode)
    if (this.simpleTrainingMode == 'FREQ_RECURING') {
      if (data.trainingType == 'FREQUENT') {
        this.sharedDate.step = 5;
      } else {
        this.sharedDate.step = 3;
      }
    } else {
      this.sharedDate.step = 2;
    }
    this.sharedDate.frequency = data.frequency;
    this.sharedDate.trainingType = data.trainingType;
    this.sharedDate.repeat = data.repeat;
    this.timeToComplete = data.length;
    this.dataChanged.emit(
      {
        startDate: this.startDate,
        endDate: this.endDate,
        length: data.length,
        automatedSchedule: this.automatedSchedule,
        frequency: data.frequency,
        freqDay: data.freqDay,
        freqWeek: data.freqWeek,
        repeat: data.repeat,
        type: 'INDEPENDENT_TRAINING_DATA_CHANGED'
      })

  }

  onTrainingClassroomDone(data) {
    if (data.startTime) {
      this.startTime = data.startTime;
    }
    if (data.endTime) {
      this.endTime = data.endTime;
    }
    if (data.location) {
      this.location = data.location;
    }
    if (data.selectedDate) {
      this.selectedDate = data.selectedDate;
    }
    this.sharedDate.trainingType = data.trainingType;
    this.sharedDate.selectedDate = this.selectedDate
    this.sharedDate.startTime = this.startTime;
    this.sharedDate.endTime = this.endTime;
    this.sharedDate.location = this.location;
    if (this.simpleTrainingMode == 'FREQ_RECURING') {
      if (data.trainingType == 'FREQUENT') {
        this.sharedDate.step = 7;
      } else {
        this.sharedDate.step = 5;
      }
    } else {
      this.sharedDate.step = 4;
    } this.sharedDate.frequency = data.frequency;
    this.sharedDate.repeat = data.repeat;

    this.stage = "SaveAndActivate"

    this.automatedSchedule = data.automatedSchedule;
    this.dataChanged.emit({
      selectedDate: this.selectedDate,
      startTime: this.startTime,
      endTime: this.endTime,
      location: this.location,
      length: data.length, 
      automatedSchedule: this.automatedSchedule,
      frequency: data.frequency,
      freqDay: data.freqDay,
      freqWeek: data.freqWeek,
      repeat: data.repeat,
      type: 'CLASSROOM_TRAINING_DATA_CHANGED'
    })
  }

  handleClassroomSelection(data) {
    if (data.startTime) {
      this.startTime = data.startTime;
    }
    if (data.endTime) {
      this.endTime = data.endTime;
    }
    if (data.location) {
      this.location = data.location;
    }
    if (data.selectedDate) {
      this.selectedDate = data.selectedDate;
    }
    this.dataChanged.emit({ selectedDate: this.selectedDate, startTime: this.startTime, endTime: this.endTime, length: data.length, location: this.location, type: 'CLASSROOM_TRAINING_DATA_CHANGED' })
  }

  handleTriggerBack(value) {
    this.stage = 'UserSelection';
    this.simpleTrainingMode = '';
  }

  handleIndependentTraingBack() {
    this.stage = "TrainingMethodSelection";
  }

  handleClassroomTraingBack() {
    this.stage = "TrainingMethodSelection";
  }

  handlePlaylsitBack(event) {
    this.showUserList = true;
    this.stage = 'UserSelection';
  }

  handleSaveActivateBack() {
    this.showContentList = true;
    if (this.simpleTrainingMode == 'AUTOMATED') {
      this.stage = 'ContentSelection';
    } else {
      this.stage = 'trainingdata';
    }
    console.log("trainingMethod",this.trainingMethod);
    console.log("stage",this.stage);
  }
  saveAndActivateDone(event) {
    this.title = event.title;
    console.log("this", this);
    this.onDone.emit(this.title);
  }

  handleTrainingMethodBack(event) {
    this.showContentList = true;
    this.stage = "ContentSelection"
  }
  trainingRecurringMethodSelection(event) {
    this.trainingType = event;
    this.sharedDate.trainingType = this.trainingType;
    this.sharedDate.step = 1;
    this.stage = "UserSelection";
    this.dataChanged.emit({ value: this.trainingType, type: "TRAINING_MODE_CHANGED" })

  }

  handleRecurringTrainingMethodBack(event) {
    this.stage = "ContentSelection"
  }
  onTriggerSelected(value) {
    this.triggerType = value;
    if (this.triggerType == 'NEW_HIRE') {
      this.stage = 'ContentSelection';
    }
    this.dataChanged.emit({ value: this.triggerType, type: "TRIGGER_CHANGED" })
  }
  handleDataChange(event) {
    console.log("reborn value changed", event);
    if (event.type == "OPTIONS_CHANGED") {
      this.dataChanged.emit({ value: event.value, type: "OPTIONS_CHANGED" })
    }
  }

  handleOnboardingDone(event) {
    this.stage = 'ContentSelection';
  }

  handleOnboardingBack(event) {
    this.stage = 'TriggerSelection';
  }

  onOnboardingGroupSelection(event) {
    this.stage = 'ContentSelection';
    this.selectedGroups = event;

  }
  handleContentBack() {
    this.stage = 'TriggerSelection';
  }
  descClicked(value) {
    this.flip = value;
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log("changes", changes);
    if (changes.goTo && changes.goTo.currentValue != "") {

      if (changes.goTo.currentValue == 'UserSelection') {
        this.stage = changes.goTo.currentValue;
        this.showUserList = true;
      }
      if (changes.goTo.currentValue == 'ContentSelection') {
        this.stage = changes.goTo.currentValue;
        this.showContentList = true;
      }


      if (changes.goTo.currentValue == 'TrainingMethodSelection') {
        this.stage = changes.goTo.currentValue;
      }


      if (changes.goTo.currentValue == 'trainingdataClassroomDate') {
        this.stage = 'trainingdata';
        this.trainingMethod = 'CLASSROOM';
        this.currentStep = 1;
      }
      if (changes.goTo.currentValue == 'trainingdataClassroomStarttime') {
        this.stage = 'trainingdata';
        this.trainingMethod = 'CLASSROOM';
        this.currentStep = 2;
      }
      if (changes.goTo.currentValue == 'trainingdataClassroomEndtime') {
        this.stage = 'trainingdata';
        this.trainingMethod = 'CLASSROOM';
        this.currentStep = 3;
      }
      if (changes.goTo.currentValue == 'trainingdataClassroomLocation') {
        this.stage = 'trainingdata';
        this.trainingMethod = 'CLASSROOM';
        this.currentStep = 4;
      }

      if (changes.goTo.currentValue == 'trainingdataIndpStartDate') {
        this.stage = 'trainingdata';
        this.trainingMethod = 'INDIVIDUAL';
        this.currentStep = 1;
      }
      if (changes.goTo.currentValue == 'trainingdataIndpEndDate') {
        this.stage = 'trainingdata';
        this.trainingMethod = 'INDIVIDUAL';
        this.currentStep = 2;
      }

      if (changes.goTo.currentValue == 'trainingdataIndpFreq') {
        this.stage = 'trainingdata';
        this.trainingMethod = 'INDIVIDUAL';
        this.trainingType = 'FREQUENT'
        this.currentStep = 3;
      }
      if (changes.goTo.currentValue == 'trainingdataIndpFreqStartDate') {
        this.stage = 'trainingdata';
        this.trainingMethod = 'INDIVIDUAL';
        this.trainingType = 'FREQUENT'

        this.currentStep = 1;
      }

      if (changes.goTo.currentValue == 'trainingdataIndpRecStartDate') {
        this.stage = 'trainingdata';
        this.trainingMethod = 'INDIVIDUAL';
        this.trainingType = 'RECURRING'
        this.currentStep = 1;
      }
      if (changes.goTo.currentValue == 'trainingdataIndpRecFreq') {
        this.stage = 'trainingdata';
        this.trainingMethod = 'INDIVIDUAL';
        this.trainingType = 'RECURRING'
        this.currentStep = 3;
      }
      //options
      if (changes.goTo.currentValue == "SaveAndActivateGraceDays") {
        this.stage = 'SaveAndActivate';
        this.currentView = 'options';
        this.optionStage = 'grace-day';
        console.log("inside", this.currentView);
        console.log("inside", this.optionStage);

      }

      if (changes.goTo.currentValue == 'SaveAndActivateTimeToComplete') {
        this.stage = 'SaveAndActivate';
        this.currentView = 'options';
        this.optionStage = 'time-to-complete';
      }

      if (changes.goTo.currentValue == 'SaveAndActivateVideoPlayer') {
        this.stage = 'SaveAndActivate';
        this.currentView = 'options';
        this.optionStage = 'video-player';
      }

      if (changes.goTo.currentValue == 'SaveAndActivatePassingScore') {
        this.stage = 'SaveAndActivate';
        this.currentView = 'options';
        this.optionStage = 'passing-score';
      }

      if (changes.goTo.currentValue == 'SaveAndActivateTrainer') {
        this.stage = 'SaveAndActivate';
        this.currentView = 'options';
        this.optionStage = 'trainer';
      }

      if (changes.goTo.currentValue == 'SaveAndActivateSendInvites') {
        this.stage = 'SaveAndActivate';
        this.currentView = 'options';
        this.optionStage = 'send-reminders';
      }
    }
  }

  onGroupTypeSelection(event){
    this.groupType = event.value;;
  }
  onPlaylistGroupSelection(event){
    this.playlistGroupType = event.value;;
  }
}
