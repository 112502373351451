import { Component, OnInit, OnDestroy, ViewChild, ComponentFactoryResolver, ApplicationRef, Injector, AfterViewInit, Input, EventEmitter, Output } from '@angular/core';
import { CdkPortal, DomPortalHost } from '@angular/cdk/portal';
import { environment } from 'environments/environment';

@Component({
  selector: 'window',
  template: `
  <ng-container *cdkPortal>
    <ng-content></ng-content>
  </ng-container>
`,
  styleUrls: ['./window.component.scss']
})
export class WindowComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input() wifiInfo = false;

  @Input() refresh = false;
  // STEP 1: get a reference to the portal
  @ViewChild(CdkPortal) portal: CdkPortal;

  @Output() opened: EventEmitter<any>;

  // STEP 2: save a reference to the window so we can close it
  private externalWindow = null;

  // STEP 3: Inject all the required dependencies for a PortalHost
  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private applicationRef: ApplicationRef,
    private injector: Injector) { 
      this.opened = new EventEmitter();

    }


  ngOnInit() {
    // STEP 4: create an external window
    console.log('hello init');
  }

  ngAfterViewInit() {
    console.log('hello after init');


  }

  initWindow() {
    console.log('hello init window');

    var query = '';

    if (this.wifiInfo) {
      query = "?wifi=y";
    }

    this.externalWindow = window.open(`${environment.siteUrl}/itrain/training/projector` + query, (new Date()).toString(), 'type=fullWindow, fullscreen, scrollbars=yes');
    this.opened.emit(this.externalWindow);
    // STEP 5: create a PortalHost with the body of the new window document    
    const host = new DomPortalHost(
      this.externalWindow.document.body,
      this.componentFactoryResolver,
      this.applicationRef,
      this.injector
    );

    // STEP 6: Attach the portal
    host.attach(this.portal);
  }

  ngOnDestroy() {
    // STEP 7: close the window when this component destroyed
    this.externalWindow.close()
  }

  ngOnChanges() {
    console.log('hello n g changes window');

    this.initWindow();
  }
}