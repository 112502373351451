import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { absAnimations } from '@abs/animations';
import { Content } from '../../content-list.model';
import { environment } from 'environments/environment';
import { ContentService } from '../../content.service';

@Component({
  selector: 'app-videos-list',
  templateUrl: './videos-list.component.html',
  styleUrls: ['./videos-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: absAnimations
})
export class VideosListComponent implements OnInit {
  ratio:any;
  enviorment: any;
  @Input() video: Content;
  constructor(public contentService: ContentService) { }

  ngOnInit() {
    this.enviorment = environment;
    if (this.video.aspectRatio == 0.75) {
      this.ratio = "4:3";
    } else {
      this.ratio = "16:9";
    }
  }
  onImgError(event) {
    event.target.src = this.enviorment.cdnUrl + '/' + this.enviorment.thumbDir + '/T_.jpg';
  }
}
