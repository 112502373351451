import { Component, OnInit, Input } from '@angular/core';
import { AdminTraining } from '../../_models/admin-training.model';
import { trigger, state, transition, style, animate } from '@angular/animations';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';

@Component({
  selector: 'my-calendar-course-item',
  templateUrl: './my-calendar-course-item.component.html',
  styleUrls: ['./my-calendar-course-item.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class MyCalendarCourseItemComponent implements OnInit {

  columnsToDisplay = ['buttons', 'name', 'students', 'passed', 'failed', 'inProgress', 'noShow', 'averageQAttempts', 'averageScore', 'percentComplete'];

  @Input()
  training: AdminTraining

  dataSource: any;

  selection = new SelectionModel<any>(false, []);

  selectedRow: any;

  expandedElement: any;
  constructor() { }

  ngOnInit() {
    console.log("training", this.training);
    this.dataSource = new MatTableDataSource(this.training.courses);
  }

  selectRow(row) {
    this.selectedRow = row
    this.expandedElement === row.id ? this.expandedElement = null : this.expandedElement = row.id
  }

}
