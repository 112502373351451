import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { QuizService } from '../quiz/services/quiz.service';
import { absAnimations } from '@abs/animations';
import { Quiz, QuizConfig } from '../quiz/models';
import { Content } from '../content-list.model';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-quiz-preview',
  templateUrl: './quiz-preview.component.html',
  styleUrls: ['./quiz-preview.component.scss'],
  providers: [QuizService],
  encapsulation: ViewEncapsulation.None,
  animations: absAnimations
})
export class QuizPreviewComponent implements OnInit {
  classId: any;
  type: any;
  videoTime: any;
  quizes: any[];
  quiz: Quiz = new Quiz(null);
  mode = 'quiz';
  content: Content;
  spanishQuiz: any;


  constructor(private quizService: QuizService, public matDialogRef: MatDialogRef<QuizPreviewComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any) {
    this.content = _data.content;
  }

  ngOnInit() {
    console.log("content quiz", this.content);
    this.loadQuiz(this.content.id);
    //window.addEventListener('blur', () => alert('window blur'));

  }

  loadQuiz(contentId) {
    this.quizService.getQuizDetail(contentId,false).subscribe(res => {
      if (res) {
        this.quiz = new Quiz(res);
        console.log(this.quiz);
      }
    });
    this.mode = 'quiz';
  }
  getNextLetter(num): String {
    var code: number = "ABCDEFGHIJKLMNOPQRST".charCodeAt(num);
    return String.fromCharCode(code);
  }
}



