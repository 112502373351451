import { AbsUtils } from '@abs/utils';

export class AdminTraining {
  id: number;
  name: string;
  courseCount: number;
  startDate: Date;
  endDate: Date;

  location: string;
  format: string;
  status: string;


  students: number;
  passed: number;
  failed: number;
  inProgress: number;
  noShow: number;

  averageQAttempts: number;
  averageScore: number;

  percentangeComplete: number;
  courses: Course[];

  /**
 * Constructor
 *
 * @param adminTraining
 */
  constructor(training) {
    this.id = training.id;
    this.name = training.name || '';
    this.startDate = training.startDate;
    this.endDate = training.endDate;
    this.location = training.location;
    this.format = training.format;
    this.status = training.status;

    this.courseCount = training.courseCount;
    this.students = training.students;
    this.passed = training.passed;
    this.failed = training.failed;
    this.noShow = training.noShow;
    this.inProgress = training.inProgress;

    this.averageQAttempts = training.averageQAttempts;
    this.averageScore = training.averageScore;

    this.percentangeComplete = training.percentangeComplete;

    this.courses = training.courses || [];

  }

}

export class Course {
  id: string;
  name: string;
  startDate: Date;
  location: string;
  format: string;
  status: string;


  students: number;
  passed: number;
  failed: number;
  inProgress: number;
  noShow: number;

  averageQAttempts: number;
  averageScore: number;

  percentangeComplete: number;
  studentList: Course[];

  /**
* Constructor
*
* @param content
*/
  constructor(course) {
    this.id = course.id;
    this.name = course.name || '';
    this.startDate = course.startDate;
    this.location = course.location;
    this.format = course.format;
    this.status = course.status;

    this.students = course.students;
    this.passed = course.passed;
    this.failed = course.failed;
    this.noShow = course.noShow;
    this.inProgress = course.inProgress;

    this.averageQAttempts = course.averageQAttempts;
    this.averageScore = course.averageScore;

    this.percentangeComplete = course.percentangeComplete;
    this.studentList =  course.studentList || [];
  }


  
}

export class Student {
  profileId: number;
  firstName: string;
  lastName: string;
  lastQuizAttempt: Date;
  status: string;


  totalQuizAttempts: number;
  percentageViewed: number;
  lastScore: number;
  

  /**
* Constructor
*
* @param student
*/
  constructor(student) {
    this.profileId = student.profileId;
    this.firstName = student.firstName;
    this.lastName = student.lastName;
    this.lastQuizAttempt = student.lastQuizAttempt;
    this.status = student.status;
    this.totalQuizAttempts = student.totalQuizAttempts;
    this.percentageViewed = student.percentageViewed;
    this.lastScore = student.lastScore;
  }


  
}