import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'app-abs-snack-bar',
  templateUrl: './abs-snack-bar.component.html',
  styleUrls: ['./abs-snack-bar.component.scss'],
  encapsulation: ViewEncapsulation.None

})
export class AbsSnackBarComponent implements OnInit {

  constructor(
    public snackBarRef: MatSnackBarRef<AbsSnackBarComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: any
  ) { }

  ngOnInit() {
  }

}
