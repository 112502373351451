import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';

export type TimeFormat = 12 | 24;

export class ITime {

  hour: number;
  minute: number;
  meriden: 'PM' | 'AM';
  format: TimeFormat;
};

@Component({
  selector: 'app-time-picker-grid',
  templateUrl: './time-picker-grid.component.html',
  styleUrls: ['./time-picker-grid.component.scss']
})
export class TimePickerGridComponent implements OnInit {

  @Output() onSelection = new EventEmitter<any>();

  hourSelection = new SelectionModel<any>(false, []);
  minsSelection = new SelectionModel<any>(false, []);
  amSelection = new SelectionModel<any>(false, []);

  @Input()
  time: ITime = new ITime();

  hours = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  minutes = ["00", "15", "30", "45"];
  ampm = ["AM", "PM"];

  constructor() { }

  ngOnInit(): void {
    console.log("this.time",this.time);
    if (this.time) {
      this.hourSelection.toggle(parseInt(this.time.hour.toString()));
      this.minsSelection.toggle(this.time.minute);
      this.amSelection.toggle(this.time.meriden);
    }else{
      this.time = new ITime();
    }
  }

  changeTimeValue(value, type) {
    if (type == "h") {
      this.time.hour = value;
      this.hourSelection.toggle(value);
    }
    if (type == "m") {
      this.time.minute = value;
      this.minsSelection.toggle(value);
    }
    if (type == "am") {
      this.time.meriden = value;
      this.amSelection.toggle(value);

    }
    this.onSelection.next(this.time);
  }
}
