import { Component, OnInit, ViewEncapsulation, EventEmitter, Output, Input } from '@angular/core';
import { absAnimations } from '@abs/animations';
import { FormControl } from '@angular/forms';
import { SelectionModel } from '@angular/cdk/collections';
import { AuthenticationService } from '../../../authentication/_services';
import { GroupsService } from '../../../user/groups/groups.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AbsUtils } from '@abs/utils';
import { ContentService } from '../../../content/content.service';
@Component({
  selector: 'app-course-selector',
  templateUrl: './course-selector.component.html',
  styleUrls: ['./course-selector.component.scss'],
  animations: absAnimations,
  encapsulation: ViewEncapsulation.None,
})
export class CourseSelectorComponent implements OnInit {

  searchCtrl = new FormControl();
  flip: any;

  @Input()
  playlists: any;

  courses = [];
  allCourses = [];

  selection = new SelectionModel<any>(true, []);
  @Output() onBackButton = new EventEmitter<any>();
  @Output() onCourseSelectionDone = new EventEmitter<any>();


  constructor(private contentService: ContentService) {
  }

  onChange(course) {
    this.selection.toggle(course);
    this.contentService.contentSelection.toggle(course);
    this.contentService.onSelectedContentChanged.next(this.contentService.contentSelection.selected);
  }

  ngOnInit(): void {
    console.log("playlists", this.playlists);
    this.playlists.forEach(element => {
      element.contentList.forEach(course => {
        let ind = this.checkIfAlreadySelected(course);
        console.log(ind);
        if (!ind) {
          this.courses.push(course);
          this.allCourses.push(course);
          this.selection.select(course);
        }
      })
    });
    console.log("user", this.courses);

    this.searchCtrl.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe(value => {
      if (value.length > 0) {
        var result = AbsUtils.filterArrayByString(this.allCourses, value);
        this.courses = result;
      } else {
        this.courses = this.allCourses;

      }
    });
  }
  handleBack() {
    this.onBackButton.emit('courses');
  }

  selectionDone() {
    this.onCourseSelectionDone.emit({ content: this.selection.selected, playlists: this.playlists });
  }
  descClicked(value) {
    this.flip = value;
  }
  checkIfAlreadySelected(newContent) {
    return this.courses.find(content => content.id === newContent.id);
  }
}
