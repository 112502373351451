import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { SelectionModel } from '@angular/cdk/collections';
import { ContentService } from 'app/main/content/content.service';
import { AuthenticationService } from '../../../authentication/_services';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AbsUtils } from '@abs/utils';
import { absAnimations } from '../../../../../@abs/animations';

@Component({
  selector: 'app-content-playist-selector',
  templateUrl: './content-playist-selector.component.html',
  styleUrls: ['./content-playist-selector.component.scss'],
  animations: absAnimations

})
export class ContentPlayistSelectorComponent implements OnInit {
  flip: any;
  playlists: any;
  allPlaylists: any;
  search = new FormControl();
  selection = new SelectionModel<any>(true, []);


  @Output() onBackButton = new EventEmitter<any>();
  @Output() onSelection = new EventEmitter<any>();
  @Output() onDone = new EventEmitter<any>();

  constructor(private contentService: ContentService, private authService: AuthenticationService) { }

  ngOnInit(): void {

    this.search.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe(value => {
      if (value.length > 0) {
        var result = AbsUtils.filterArrayByString(this.playlists, value);
        this.playlists = result;
      } else {
        this.playlists = this.allPlaylists;

      }
    });



    this.contentService.getPlaylists(0, 1000).subscribe(result => {
      this.playlists = result.filter(playlist => !playlist.system);
      this.allPlaylists = this.playlists;
    })


  }


  onContinue() {
    this.onDone.next({ data: this.selection.selected, type: 'playlists' })
  }
  handleBack() {
    this.onBackButton.next("asd")
  }

  onChange(event) {
    if(!event.system){
      this.selection.toggle(event);
    }
  } descClicked(asd) { }

}
