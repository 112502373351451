import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Location, Appearance, GermanAddress } from '@angular-material-extensions/google-maps-autocomplete';
import PlaceResult = google.maps.places.PlaceResult;
import { Location as addressLocation } from '../location.model';

@Component({
  selector: 'app-location-address',
  templateUrl: './location-address.component.html',
  styleUrls: ['./location-address.component.scss'],
  encapsulation: ViewEncapsulation.None

})
export class LocationAddressComponent implements OnInit {

  constructor(public matDialogRef: MatDialogRef<LocationAddressComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any, ) {

    this.location = _data.location;
  }
  address: any;
  location: addressLocation;
 
  public trainingLocationOnly: any = false;
  public appearance = Appearance;
  public zoom: number;
  public latitude: number;
  public longitude: number;
  public selectedAddress: PlaceResult;

  ngOnInit() {
    this.zoom = 10;
    this.latitude = 41.850033;
    this.longitude = -87.6500523;

    this.setCurrentPosition();
    this.setCurrentLocationData();
  }

  private setCurrentPosition() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 12;
      });
    }
  }

  private setCurrentLocationData() {
    if (this.location && this.location.address1) {
      console.log("location",this.location);

      //this.location.address1 = address;
      //this.address2 = this.location.address2;
      this.latitude = this.location.addressLat;
      this.longitude = this.location.addressLong;
      //this.city = this.location.city;
      //this.state = this.location.state;
      //this.zip = this.location.zip;
      //this.trainingLocationOnly = this.location.trainingLocationOnly || false;
    }else{
      this.location = new addressLocation({});
      this.location.address1 = "";
    }
  }

  onAutocompleteSelected(result: PlaceResult) {
    console.log('onAutocompleteSelected: ', result);
  }

  onLocationSelected(location: Location) {
    console.log('onLocationSelected: ', location);
    this.latitude = location.latitude;
    this.longitude = location.longitude;
  }

  onGermanAddressMapped($event: GermanAddress) {
    console.log('onGermanAddressMapped', $event);

    this.address = $event;
    this.location.address1 = $event.name;
    if ($event.locality) {
      this.location.city = $event.locality.long;
    }
    if ($event.state) {
      this.location.state = $event.state.short;
    }
    if ($event.postalCode) {
      this.location.zip = $event.postalCode+"";
    }
    this.location.addressLat = $event.geoLocation.latitude;
    this.location.addressLong = $event.geoLocation.longitude;

    if ($event.url) {
      this.location.url = $event.url;
    }

    //this.address.trainingLocationOnly = this.trainingLocationOnly;
    //this.address.address2 = this.address2;
  }

  trainingLocationChanged(e) {
    this.trainingLocationOnly = e;
  }

  address2Changed() {
    console.log(this.trainingLocationOnly);
  }

}
