import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';

import { environment } from '../../../environments/environment';
import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { User } from '../models/user';
import { AccountProfile } from '../authentication/_models/accountprofile';

const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })
};

const httpOptoinsImage = {
    headers: new HttpHeaders({
        'Content-Type': 'multipart/form-data'
    })
}

@Injectable({ providedIn: 'root' })
export class UserService {
    constructor(private http: HttpClient) { }

    getAll() {
        return this.http.get<User[]>(`${environment.apiUrl}/users`);
    }

    getById(id: number) {
        return this.http.get<User>(`${environment.apiUrl}/users/${id}`);
    }

    addUser(accountProfile: AccountProfile): Observable<any> {
        const body = JSON.stringify(accountProfile);
        return this.http.put<any>(`${environment.apiUrl}/user/add`, body, httpOptions)
            .pipe(
                catchError(this.handleError)
            );
    }

    updateAccountProfile(accountProfile: AccountProfile): Observable<any> {
        const body = JSON.stringify(accountProfile);
        return this.http.put<any>(`${environment.apiUrl}/account/profile`, body, httpOptions)
            .pipe(
                catchError(this.handleError)
            );
    }

    updateAccountProfileMigration(accountProfile: AccountProfile): Observable<any> {
        const body = JSON.stringify(accountProfile);
        return this.http.put<any>(`${environment.apiUrl}/account/profile/migration`, body, httpOptions)
            .pipe(
                catchError(this.handleError)
            );
    }

    updateAccountProfileSettings(accountProfile: AccountProfile): Observable<any> {
        const body = JSON.stringify(accountProfile);
        return this.http.put<any>(`${environment.apiUrl}/account/profile/setting`, body, httpOptions)
            .pipe(
                catchError(this.handleError)
            );
    }


    disableAccountProfile(accountProfile: AccountProfile): Observable<any> {
        const body = JSON.stringify(accountProfile);
        return this.http.put<any>(`${environment.apiUrl}/account/profile/disable`, body, httpOptions)
            .pipe(
                catchError(this.handleError)
            );
    }

    updatePassword(password: string,profileId:number): Observable<any> {
        return this.http.put<any>(`${environment.apiUrl}/account/profile/password`, { 'profileId': profileId, 'password':password }, httpOptions)
            .pipe(
                catchError(this.handleError)
            );
    }

    updateSecondaryPassword(password: string,profileId:number): Observable<any> {
        return this.http.put<any>(`${environment.apiUrl}/account/profile/password/secondary`, { 'profileId': profileId, 'password':password }, httpOptions)
            .pipe(
                catchError(this.handleError)
            );
    }

    updateProfilePicture(data): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}/account/profile/picture`, data)
            .pipe(
                catchError(this.handleError)
            );
    }

    insertUserSetting(data): Observable<any> {
        console.log("data",data);
        return this.http.post<any>(`${environment.apiUrl}/account/profile/training/default`, data,httpOptions)
            .pipe(
                catchError(this.handleError)
            );
    }

    updateUserSetting(data): Observable<any> {
        const body = JSON.stringify(data);
        return this.http.put<any>(`${environment.apiUrl}/account/profile/training/default`, body, httpOptions)
            .pipe(
                catchError(this.handleError)
            );
    }

    handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
                `Backend returned code ${error.status}, ` +
                `body was: ${error.error}`);
        }
        // return an observable with a user-facing error message
        return throwError(
            'Something bad happened; please try again later.');
    };
}
