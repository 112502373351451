import { Component, OnInit, Output, EventEmitter, ViewEncapsulation, Input, ViewChild, ElementRef } from '@angular/core';
import { absAnimations } from '@abs/animations';
import { CompnayService } from '../../../authentication/_services';
import { AuthenticationService } from 'app/main/authentication/_services/authentication.service';
import { AbsUtils } from '@abs/utils';
@Component({
  selector: 'app-content-course-description',
  templateUrl: './content-course-description.component.html',
  styleUrls: ['./content-course-description.component.scss'],
  animations: absAnimations

})
export class ContentCourseDescriptionComponent implements OnInit {
  @ViewChild("textInput") set bannerNoteRef(ref: ElementRef) {
    if (!!ref) {
      ref.nativeElement.focus() ;
    }
  }
  flip:any;
  @Input()
  description: any = "";
  
  @Output() onBackButton = new EventEmitter<any>();

  @Output() onDone = new EventEmitter<any>();

  @Output() dataChanged = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }
  handleBack(){
    this.dataChanged.next({data: this.description, type: 'DESCRIPTION'});
    this.onBackButton.next("desc");
  }

  onContinue(){
    console.log("description",this.description);
    this.onDone.next({data: this.description, type: 'DESCRIPTION'})
  }

  descClicked(abc){
    
  }
 

}
