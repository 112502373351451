import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { CalendarEventAction } from 'angular-calendar';
import { CalendarEvent } from 'angular-calendar';
import { AbsConfirmDialogComponent } from '../../../../@abs/components/confirm-dialog/confirm-dialog.component';
import { Subject } from 'rxjs';
import {startOfDay, isSameDay, isSameMonth} from 'date-fns';

@Component({
  selector: 'app-calendar-dialog',
  templateUrl: './calendar-dialog.component.html',
  styleUrls: ['./calendar-dialog.component.scss']
})
export class CalendarDialogComponent implements OnInit {

  actions: CalendarEventAction[];
  activeDayIsOpen: boolean;
  confirmDialogRef: MatDialogRef<AbsConfirmDialogComponent>;
  refresh: Subject<any> = new Subject();
  selectedDay: any;
  view: string;
  viewDate: Date;

  constructor(public dialogRef: MatDialogRef<CalendarDialogComponent>) { 
    this.view = 'month';
    this.viewDate = new Date();
    this.activeDayIsOpen = true;
    this.selectedDay = {date: startOfDay(new Date())};
  }

  ngOnInit(): void {
  }

}
