export class Option {
    answerNo: number;
    questionNo: number;
    answer: string;
    correct: boolean;
    selected: boolean;

    constructor(data: any) {
        data = data || {};
        console.log("ans data",data);
        if(data.answerNo){
            this.answerNo = data.answerNo;
        }
        if(data.questionNo){
            this.questionNo = data.questionNo;
        }
        if(data.answer){
            this.answer = data.answer;
        }
        if(data.correct){
            this.correct = data.correct;
        }
        if(data.selected){
        this.selected = data.selected;
        }
    }
}
