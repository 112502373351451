import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Group } from '../../../user/groups/groups-all/group.model';
import { SelectionModel } from '@angular/cdk/collections';
import { Subject } from 'rxjs';
import { AbsProgressBarService } from '@abs/components/progress-bar/progress-bar.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GroupsService } from '../../../user/groups/groups.service';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'environments/environment';
import { takeUntil, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-assign-groups',
  templateUrl: './assign-groups.component.html',
  styleUrls: ['./assign-groups.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ])
  ],
})
export class AssignGroupsComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(MatSort) sort: MatSort;


  favoritesGroupDataSource: MatTableDataSource<Group>;
  myGroupDataSource: MatTableDataSource<Group>;
  othersGroupDataSource: MatTableDataSource<Group>;
  systemGroupDataSource: MatTableDataSource<Group>;
  departmentGroupDataSource: MatTableDataSource<Group>; 
  locationGroups: MatTableDataSource<Group>; 
  allGroups: MatTableDataSource<Group>; 
  groups: MatTableDataSource<Group>; 

  displayedColumns = ['expandButton', 'name', 'buttons'];
  groupSelection = new SelectionModel<any>(true, []);
  userSelection = new SelectionModel<any>(true, []);

  groupsExpanded: number;
  isLoading = true;
  environment: any;
  searchInput: FormControl;

  selectedTriggerValue: any;

  @Output() triggerSelected = new EventEmitter<String>();


  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {GroupsService} _groupsService
   * @param {MatDialog} _matDialog
   */
  constructor(private _progressBarService: AbsProgressBarService,
    public _snackBar: MatSnackBar,
    private _groupsService: GroupsService,
    public _matDialog: MatDialog,
    public activatedRoute: ActivatedRoute) {

    // Set the private defaults
    this._unsubscribeAll = new Subject();
    this.groupsExpanded = 0;
    this.environment = environment;
    this.searchInput = new FormControl('');


  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {

    

    this.searchInput.valueChanges
      .pipe(
        takeUntil(this._unsubscribeAll),
        debounceTime(300),
        distinctUntilChanged()
      )
      .subscribe(searchText => {
        this._groupsService.onSearchTextChanged.next(searchText);
      });

      this._groupsService.getGroupedGroups().then(groups => {
        this.initDataSource(groups);
         // this.dataSource.sort = this.sort;
          this.isLoading = false;
        });

    this._groupsService.onGroupsChanged
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(groups => {
        this.initDataSource(groups);
        var selected = this.groupSelection.selected;
        this.groupSelection.clear();
        console.log("on chnaged selected", selected);
        selected.map(element => {
         /*  this.favoritesGroupDataSource.data.map(row => {
            if (row.groupID === element.groupID) {
              this.selectGroup(row);
            }
          }); */
        });
        //redirect from group managment
        this.preSelectUserAndGroups();
      });

    this._groupsService.onSelectedUsersChanged
      .subscribe(users => {
        this.userSelection = this._groupsService.userSelection;
      });




    /*  this._groupsService.onFilterChanged
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(() => {
          this._groupsService.deselectGroups();
        }); */
  }


  ngAfterViewInit() {
    console.log(this.sort);
  }
  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------



  removeUser(groupObj, user) {
    this._progressBarService.show();
    this._groupsService.updateGroupUnassign(user.profileId, groupObj.groupID).then(res => {
      this._progressBarService.hide();
      if (res) {
        const index = groupObj.userList.indexOf(user);
        if (index > -1) {
          groupObj.userList.splice(index, 1);
        }
        console.log("group to be remove", groupObj);
        this._groupsService.onSelectedGroupsChanged.next(groupObj);
        this.groupsExpanded = this._groupsService.groups.filter(group => group.groupID == groupObj.groupID)[0].groupID;
        this._snackBar.open("User has been removed from the group.", 'Ok', {
          panelClass: 'green-bg',
          duration: 3000,
          horizontalPosition: 'right',
          verticalPosition: 'top',
          politeness: 'polite',
        });
      }
    })
  }
  selectGroup(group) {

    
    this.triggerSelected.next("N");
    this.selectedTriggerValue = "N";
    console.log("select Group", this.groupSelection.selected);

    this.groupSelection.toggle(group);
    if (this.groupSelection.isSelected(group)) {
      if (group.userList) {
        group.userList.map(user => {
          user.selectedGroup = group.groupID;
          this.userSelection.select(user);
        })
      }
      group.allUserSelected = true;
      if(group.userList){
      group.selectedUserCount = group.userList.length;
      }else{
        group.selectedUserCount = 0;

      }
    } else {
      if (group.userList) {
        group.userList.map(user => {
          user.selectedGroup = -1;
          this.userSelection.deselect(user);
        })
      }
      group.allUserSelected = false;
      group.selectedUserCount = 0;
    }
    this._groupsService.toggleGroupSelection(this.groupSelection);
    this._groupsService.toggleUserSelection(this.userSelection);
    console.log("select Group", this.groupSelection.selected);

    console.log("select Group", group);
  }
  selectUser(user, group) {

    console.log("user", user);
    console.log("group in user", group);

    var selectedUserCount = 0;

    this.userSelection.toggle(user);

    if (!this.groupSelection.isSelected(group) && this.userSelection.isSelected(user)) {
       //this.groupSelection.select(group);
    }

    group.allUserSelected = true;
    group.userList.map(user => {
      var selected = this.userSelection.isSelected(user);
      if (!selected) {
        group.allUserSelected = false;
      } else {
        selectedUserCount++;
      }
      console.log("selectedUserCount", selectedUserCount);
    })

    group.selectedUserCount = selectedUserCount;
    this._groupsService.toggleUserSelection(this.userSelection);
  }

  preSelectUserAndGroups(){
    this.activatedRoute.queryParams.subscribe(params => {
      const op = params['op'];
      if (op == 'group') {
        var selectedUsers = JSON.parse(sessionStorage.getItem("userSelection"));
        var selectedGroups = JSON.parse(sessionStorage.getItem("groupSelection"));
        console.log("hello in assign groups",selectedUsers);
        console.log("hello in assign groups",selectedGroups);

          selectedGroups.map(element => {
           this.selectGroup(element);
          });
      }
    });
  }

  initDataSource(groups){
    this.groups = groups;
    this.favoritesGroupDataSource = new MatTableDataSource(groups.favoriteGroups);
    this.myGroupDataSource = new MatTableDataSource(groups.myGroups);
    this.othersGroupDataSource = new MatTableDataSource(groups.otherGroups);
    this.systemGroupDataSource = new MatTableDataSource(groups.systemGroups);
    this.departmentGroupDataSource = new MatTableDataSource(groups.departmentGroups);
  }

  triggerChanged(data){
    console.log("data",data);

    this.userSelection.clear();
    this.groupSelection.clear();
    this._groupsService.toggleGroupSelection(this.groupSelection);
    this._groupsService.toggleUserSelection(this.userSelection);
    this.selectedTriggerValue = data;
    this.triggerSelected.next(data);
  }
}
