export class ProjectDashboardDb {
  public static projects = [
    {
      'name': 'My Reports'
    },
    {
      'name': 'Annual Reports'
    },
    {
      'name': 'Weekly Report'
    },
    {
      'name': 'Daily Report'
    }
  ];

  public static widgets = {
    'widget1': {
      'ranges': {
        'DY': 'Yesterday',
        'DT': 'Today',
        'DTM': 'Tomorrow'
      },
      'currentRange': 'DT',
      'data': {
        'label': 'Total Login ',
        'count': {
          'DY': 123,
          'DT': 125,
          'DTM': 19
        },
        'extra': {
          'label': 'Communication',
          'count': {
            'DY': 6,
            'DT': 7,
            'DTM': '-'
          }

        }
      },
      'detail': 'You can show some detailed information about this widget in here.'
    },
    'widget2': {
      'title': 'Overdue',
      'data': {
        'label': 'TASKS',
        'count': 4,
        'extra': {
          'label': 'Yesterday\'s overdue',
          'count': 2
        }
      },
      'detail': 'You can show some detailed information about this widget in here.'
    },
    'widget3': {
      'title': 'Issues',
      'data': {
        'label': 'OPEN',
        'count': 32,
        'extra': {
          'label': 'Closed today',
          'count': 0
        }
      },
      'detail': 'You can show some detailed information about this widget in here.'
    },
    'widget4': {
      'title': 'Features',
      'data': {
        'label': 'PROPOSALS',
        'count': 42,
        'extra': {
          'label': 'Implemented',
          'count': 8
        }
      },
      'detail': 'You can show some detailed information about this widget in here.'
    },
    'widget5': {
      'title': 'View As',
      'ranges': {
        'TW': 'Course',
        'LW': 'User',
        '2W': 'Trainer'
      },
      'mainChart': {
        '2W': [
          {
            'name': 'Mon',
            'series': [
              {
                'name': 'issues',
                'value': 37
              },
              {
                'name': 'closed issues',
                'value': 9
              }
            ]
          },
          {
            'name': 'Tue',
            'series': [
              {
                'name': 'issues',
                'value': 32
              },
              {
                'name': 'closed issues',
                'value': 12
              }
            ]
          },
          {
            'name': 'Wed',
            'series': [
              {
                'name': 'issues',
                'value': 39
              },
              {
                'name': 'closed issues',
                'value': 9
              }
            ]
          },
          {
            'name': 'Thu',
            'series': [
              {
                'name': 'issues',
                'value': 27
              },
              {
                'name': 'closed issues',
                'value': 12
              }
            ]
          },
          {
            'name': 'Fri',
            'series': [
              {
                'name': 'issues',
                'value': 18
              },
              {
                'name': 'closed issues',
                'value': 7
              }
            ]
          },
          {
            'name': 'Sat',
            'series': [
              {
                'name': 'issues',
                'value': 24
              },
              {
                'name': 'closed issues',
                'value': 8
              }
            ]
          },
          {
            'name': 'Sun',
            'series': [
              {
                'name': 'issues',
                'value': 20
              },
              {
                'name': 'closed issues',
                'value': 16
              }
            ]
          }
        ],
        'LW': [
          {
            'name': 'Mon',
            'series': [
              {
                'name': 'issues',
                'value': 37
              },
              {
                'name': 'closed issues',
                'value': 12
              }
            ]
          },
          {
            'name': 'Tue',
            'series': [
              {
                'name': 'issues',
                'value': 24
              },
              {
                'name': 'closed issues',
                'value': 8
              }
            ]
          },
          {
            'name': 'Wed',
            'series': [
              {
                'name': 'issues',
                'value': 51
              },
              {
                'name': 'closed issues',
                'value': 7
              }
            ]
          },
          {
            'name': 'Thu',
            'series': [
              {
                'name': 'issues',
                'value': 31
              },
              {
                'name': 'closed issues',
                'value': 13
              }
            ]
          },
          {
            'name': 'Fri',
            'series': [
              {
                'name': 'issues',
                'value': 29
              },
              {
                'name': 'closed issues',
                'value': 7
              }
            ]
          },
          {
            'name': 'Sat',
            'series': [
              {
                'name': 'issues',
                'value': 17
              },
              {
                'name': 'closed issues',
                'value': 6
              }
            ]
          },
          {
            'name': 'Sun',
            'series': [
              {
                'name': 'issues',
                'value': 31
              },
              {
                'name': 'closed issues',
                'value': 10
              }
            ]
          }
        ],
        'TW': [
          {
            'name': 'Mon',
            'series': [
              {
                'name': 'issues',
                'value': 42
              },
              {
                'name': 'closed issues',
                'value': 11
              }
            ]
          },
          {
            'name': 'Tue',
            'series': [
              {
                'name': 'issues',
                'value': 28
              },
              {
                'name': 'closed issues',
                'value': 10
              }
            ]
          },
          {
            'name': 'Wed',
            'series': [
              {
                'name': 'issues',
                'value': 43
              },
              {
                'name': 'closed issues',
                'value': 8
              }
            ]
          },
          {
            'name': 'Thu',
            'series': [
              {
                'name': 'issues',
                'value': 34
              },
              {
                'name': 'closed issues',
                'value': 11
              }
            ]
          },
          {
            'name': 'Fri',
            'series': [
              {
                'name': 'issues',
                'value': 20
              },
              {
                'name': 'closed issues',
                'value': 8
              }
            ]
          },
          {
            'name': 'Sat',
            'series': [
              {
                'name': 'issues',
                'value': 25
              },
              {
                'name': 'closed issues',
                'value': 10
              }
            ]
          },
          {
            'name': 'Sun',
            'series': [
              {
                'name': 'issues',
                'value': 22
              },
              {
                'name': 'closed issues',
                'value': 17
              }
            ]
          }
        ]
      },
      'supporting': {
        'created': {
          'label': 'Users',
          'count': {
            '2W': 48,
            'LW': 46,
            'TW': 54
          },
          'chart': {
            '2W': [
              {
                'name': 'CREATED',
                'series': [
                  {
                    'name': 'Mon',
                    'value': 5
                  },
                  {
                    'name': 'Tue',
                    'value': 8
                  },
                  {
                    'name': 'Wed',
                    'value': 5
                  },
                  {
                    'name': 'Thu',
                    'value': 6
                  },
                  {
                    'name': 'Fri',
                    'value': 7
                  },
                  {
                    'name': 'Sat',
                    'value': 8
                  },
                  {
                    'name': 'Sun',
                    'value': 7
                  }
                ]
              }
            ],
            'LW': [
              {
                'name': 'Created',
                'series': [
                  {
                    'name': 'Mon',
                    'value': 6
                  },
                  {
                    'name': 'Tue',
                    'value': 3
                  },
                  {
                    'name': 'Wed',
                    'value': 7
                  },
                  {
                    'name': 'Thu',
                    'value': 5
                  },
                  {
                    'name': 'Fri',
                    'value': 5
                  },
                  {
                    'name': 'Sat',
                    'value': 4
                  },
                  {
                    'name': 'Sun',
                    'value': 7
                  }
                ]
              }
            ],
            'TW': [
              {
                'name': 'Created',
                'series': [
                  {
                    'name': 'Mon',
                    'value': 3
                  },
                  {
                    'name': 'Tue',
                    'value': 2
                  },
                  {
                    'name': 'Wed',
                    'value': 1
                  },
                  {
                    'name': 'Thu',
                    'value': 4
                  },
                  {
                    'name': 'Fri',
                    'value': 8
                  },
                  {
                    'name': 'Sat',
                    'value': 8
                  },
                  {
                    'name': 'Sun',
                    'value': 4
                  }
                ]
              }
            ]
          }
        },
        'closed': {
          'label': 'Course Assign',
          'count': {
            '2W': 27,
            'LW': 31,
            'TW': 26
          },
          'chart': {
            '2W': [
              {
                'name': 'CLOSED',
                'series': [
                  {
                    'name': 'Mon',
                    'value': 3
                  },
                  {
                    'name': 'Tue',
                    'value': 2
                  },
                  {
                    'name': 'Wed',
                    'value': 1
                  },
                  {
                    'name': 'Thu',
                    'value': 4
                  },
                  {
                    'name': 'Fri',
                    'value': 8
                  },
                  {
                    'name': 'Sat',
                    'value': 8
                  },
                  {
                    'name': 'Sun',
                    'value': 4
                  }
                ]
              }
            ],
            'LW': [
              {
                'name': 'CLOSED',
                'series': [
                  {
                    'name': 'Mon',
                    'value': 6
                  },
                  {
                    'name': 'Tue',
                    'value': 5
                  },
                  {
                    'name': 'Wed',
                    'value': 4
                  },
                  {
                    'name': 'Thu',
                    'value': 5
                  },
                  {
                    'name': 'Fri',
                    'value': 7
                  },
                  {
                    'name': 'Sat',
                    'value': 4
                  },
                  {
                    'name': 'Sun',
                    'value': 7
                  }
                ]
              }
            ],
            'TW': [
              {
                'name': 'CLOSED',
                'series': [
                  {
                    'name': 'Mon',
                    'value': 6
                  },
                  {
                    'name': 'Tue',
                    'value': 3
                  },
                  {
                    'name': 'Wed',
                    'value': 7
                  },
                  {
                    'name': 'Thu',
                    'value': 5
                  },
                  {
                    'name': 'Fri',
                    'value': 5
                  },
                  {
                    'name': 'Sat',
                    'value': 4
                  },
                  {
                    'name': 'Sun',
                    'value': 7
                  }
                ]
              }
            ]
          }
        },
        'reOpened': {
          'label': 'Video Watched',
          'count': {
            '2W': 4,
            'LW': 5,
            'TW': 2
          },
          'chart': {
            '2W': [
              {
                'name': 'RE-OPENED',
                'series': [
                  {
                    'name': 'Mon',
                    'value': 6
                  },
                  {
                    'name': 'Tue',
                    'value': 3
                  },
                  {
                    'name': 'Wed',
                    'value': 7
                  },
                  {
                    'name': 'Thu',
                    'value': 5
                  },
                  {
                    'name': 'Fri',
                    'value': 5
                  },
                  {
                    'name': 'Sat',
                    'value': 4
                  },
                  {
                    'name': 'Sun',
                    'value': 7
                  }
                ]
              }
            ],
            'LW': [
              {
                'name': 'RE-OPENED',
                'series': [
                  {
                    'name': 'Mon',
                    'value': 5
                  },
                  {
                    'name': 'Tue',
                    'value': 7
                  },
                  {
                    'name': 'Wed',
                    'value': 8
                  },
                  {
                    'name': 'Thu',
                    'value': 8
                  },
                  {
                    'name': 'Fri',
                    'value': 6
                  },
                  {
                    'name': 'Sat',
                    'value': 4
                  },
                  {
                    'name': 'Sun',
                    'value': 1
                  }
                ]
              }
            ],
            'TW': [
              {
                'name': 'RE-OPENED',
                'series': [
                  {
                    'name': 'Mon',
                    'value': 3
                  },
                  {
                    'name': 'Tue',
                    'value': 2
                  },
                  {
                    'name': 'Wed',
                    'value': 1
                  },
                  {
                    'name': 'Thu',
                    'value': 4
                  },
                  {
                    'name': 'Fri',
                    'value': 8
                  },
                  {
                    'name': 'Sat',
                    'value': 8
                  },
                  {
                    'name': 'Sun',
                    'value': 4
                  }
                ]
              }
            ]
          }
        },
        'wontFix': {
          'label': 'Quizzes Taken\t',
          'count': {
            '2W': 6,
            'LW': 3,
            'TW': 4
          },
          'chart': {
            '2W': [
              {
                'name': 'WON\'T FIX',
                'series': [
                  {
                    'name': 'Mon',
                    'value': 5
                  },
                  {
                    'name': 'Tue',
                    'value': 7
                  },
                  {
                    'name': 'Wed',
                    'value': 4
                  },
                  {
                    'name': 'Thu',
                    'value': 6
                  },
                  {
                    'name': 'Fri',
                    'value': 5
                  },
                  {
                    'name': 'Sat',
                    'value': 3
                  },
                  {
                    'name': 'Sun',
                    'value': 2
                  }
                ]
              }
            ],
            'LW': [
              {
                'name': 'WON\'T FIX',
                'series': [
                  {
                    'name': 'Mon',
                    'value': 6
                  },
                  {
                    'name': 'Tue',
                    'value': 3
                  },
                  {
                    'name': 'Wed',
                    'value': 7
                  },
                  {
                    'name': 'Thu',
                    'value': 5
                  },
                  {
                    'name': 'Fri',
                    'value': 5
                  },
                  {
                    'name': 'Sat',
                    'value': 4
                  },
                  {
                    'name': 'Sun',
                    'value': 7
                  }
                ]
              }
            ],
            'TW': [
              {
                'name': 'WON\'T FIX',
                'series': [
                  {
                    'name': 'Mon',
                    'value': 6
                  },
                  {
                    'name': 'Tue',
                    'value': 5
                  },
                  {
                    'name': 'Wed',
                    'value': 4
                  },
                  {
                    'name': 'Thu',
                    'value': 5
                  },
                  {
                    'name': 'Fri',
                    'value': 7
                  },
                  {
                    'name': 'Sat',
                    'value': 4
                  },
                  {
                    'name': 'Sun',
                    'value': 7
                  }
                ]
              }
            ]
          }
        },
        'needsTest': {
          'label': 'Passed Courses\t',
          'count': {
            '2W': 10,
            'LW': 7,
            'TW': 8
          },
          'chart': {
            '2W': [
              {
                'name': 'NEEDS TEST',
                'series': [
                  {
                    'name': 'Mon',
                    'value': 6
                  },
                  {
                    'name': 'Tue',
                    'value': 5
                  },
                  {
                    'name': 'Wed',
                    'value': 4
                  },
                  {
                    'name': 'Thu',
                    'value': 5
                  },
                  {
                    'name': 'Fri',
                    'value': 7
                  },
                  {
                    'name': 'Sat',
                    'value': 4
                  },
                  {
                    'name': 'Sun',
                    'value': 7
                  }
                ]
              }
            ],
            'LW': [
              {
                'name': 'NEEDS TEST',
                'series': [
                  {
                    'name': 'Mon',
                    'value': 5
                  },
                  {
                    'name': 'Tue',
                    'value': 7
                  },
                  {
                    'name': 'Wed',
                    'value': 8
                  },
                  {
                    'name': 'Thu',
                    'value': 8
                  },
                  {
                    'name': 'Fri',
                    'value': 6
                  },
                  {
                    'name': 'Sat',
                    'value': 4
                  },
                  {
                    'name': 'Sun',
                    'value': 1
                  }
                ]
              }
            ],
            'TW': [
              {
                'name': 'NEEDS TEST',
                'series': [
                  {
                    'name': 'Mon',
                    'value': 6
                  },
                  {
                    'name': 'Tue',
                    'value': 3
                  },
                  {
                    'name': 'Wed',
                    'value': 7
                  },
                  {
                    'name': 'Thu',
                    'value': 5
                  },
                  {
                    'name': 'Fri',
                    'value': 5
                  },
                  {
                    'name': 'Sat',
                    'value': 4
                  },
                  {
                    'name': 'Sun',
                    'value': 7
                  }
                ]
              }
            ]
          }
        },
        'fixed': {
          'label': 'Completed',
          'count': {
            '2W': 21,
            'LW': 17,
            'TW': 14
          },
          'chart': {
            '2W': [
              {
                'name': 'FIXED',
                'series': [
                  {
                    'name': 'Mon',
                    'value': 5
                  },
                  {
                    'name': 'Tue',
                    'value': 7
                  },
                  {
                    'name': 'Wed',
                    'value': 8
                  },
                  {
                    'name': 'Thu',
                    'value': 8
                  },
                  {
                    'name': 'Fri',
                    'value': 6
                  },
                  {
                    'name': 'Sat',
                    'value': 4
                  },
                  {
                    'name': 'Sun',
                    'value': 1
                  }
                ]
              }
            ],
            'LW': [
              {
                'name': 'FIXED',
                'series': [
                  {
                    'name': 'Mon',
                    'value': 6
                  },
                  {
                    'name': 'Tue',
                    'value': 5
                  },
                  {
                    'name': 'Wed',
                    'value': 4
                  },
                  {
                    'name': 'Thu',
                    'value': 5
                  },
                  {
                    'name': 'Fri',
                    'value': 7
                  },
                  {
                    'name': 'Sat',
                    'value': 4
                  },
                  {
                    'name': 'Sun',
                    'value': 7
                  }
                ]
              }
            ],
            'TW': [
              {
                'name': 'FIXED',
                'series': [
                  {
                    'name': 'Mon',
                    'value': 5
                  },
                  {
                    'name': 'Tue',
                    'value': 7
                  },
                  {
                    'name': 'Wed',
                    'value': 4
                  },
                  {
                    'name': 'Thu',
                    'value': 6
                  },
                  {
                    'name': 'Fri',
                    'value': 5
                  },
                  {
                    'name': 'Sat',
                    'value': 3
                  },
                  {
                    'name': 'Sun',
                    'value': 2
                  }
                ]
              }
            ]
          }
        }
      }
    },
    'widget6': {
      'showLegend': true,
      'title': 'Title',
      'ranges': {
        'TW': 'This Week',
        'LW': 'Last Week',
        '2W': '2 Weeks Ago'
      },
      'mainChart': {
        'TW': [
          {
            'name': 'Label 1',
            'value': 15
          },
          {
            'name': ':Label 2',
            'value': 20
          },
          {
            'name': 'Label 3',
            'value': 38
          },
          {
            'name': 'Label 4',
            'value': 27
          }
        ],
        'LW': [
          {
            'name': 'Label 1',
            'value': 115
          },
          {
            'name': ':Label 2',
            'value': 320
          },
          {
            'name': 'Label 3',
            'value': 438
          },
          {
            'name': 'Label 4',
            'value': 127
          }
        ],
        '2W': [
          {
            'name': 'Label 1',
            'value': 15
          },
          {
            'name': ':Label 2',
            'value': 20
          },
          {
            'name': 'Label 3',
            'value': 38
          },
          {
            'name': 'Label 4',
            'value': 27
          }
        ]
      },
      'footerLeft': {
        'title': 'Label',
        'count': {
          '2W': 487,
          'LW': 526,
          'TW': 594
        }
      },
      'footerRight': {
        'title': 'Label',
        'count': {
          '2W': 193,
          'LW': 260,
          'TW': 287
        }
      }
    },
    'widget7': {
      'title': 'User Activity',
      'ranges': {
        'T': 'Today',
        'TM': 'Tomorrow'
      },
      'schedule': {
        'T': [
          {
            'title': 'Total Logins',
            'time': '123',
            'location': ''
          },
          {
            'title': 'communication',
            'time': '7'
          },
          {
            'title': 'Date Added',
            'time': 'January 27, 2019 @ 12:31 PM (PT)'
          },
          {
            'title': 'Added By',
            'time': 'McMasters, Stanley'
          },
          {
            'title': 'Last Login',
            'time': 'January 27, 2019 @ 4:00 PM (PT)'
          },
          {
            'title': 'Last Edit',
            'time': 'January 27, 2019 @ 4:00 PM (PT)'
          },
          {
            'title': 'Edited By',
            'time': 'McMasters, Stanley'
          },
          {
            'title': 'Expiration Date',
            'time': 'March 27, 2022 @ 4:00 PM (PT)'
          },
          {
            'title': 'Deactivated on',
            'time': 'July 27, 2019 @ 4:00 PM (PT)'
          },
          {
            'title': 'Deactivated by',
            'time': 'McMasters, Stanley'
          },
          {
            'title': 'Deactivation Method',
            'time': 'Manually vs Import'
          }
        ],
        'TM': [
          {
            'title': 'Total Logins',
            'time': '23',
            'location': ''
          },
          {
            'title': 'communication',
            'time': '1'
          },
          {
            'title': 'Date Added',
            'time': 'January 26, 2019 @ 2:31 AM (PT)'
          },
          {
            'title': 'Added By',
            'time': 'McMasters'
          },
          {
            'title': 'Last Login',
            'time': 'January 26, 2019 @ 6:00 PM (PT)'
          },
          {
            'title': 'Last Edit',
            'time': 'January 25, 2019 @ 4:00 PM (PT)'
          },
          {
            'title': 'Edited By',
            'time': 'Stanley'
          },
          {
            'title': 'Expiration Date',
            'time': 'March 27, 2028 @ 1:00 PM (PT)'
          },
          {
            'title': 'Deactivated on',
            'time': 'July 23, 2019 @ 3:00 PM (PT)'
          },
          {
            'title': 'Deactivated by',
            'time': 'McMasters'
          },
          {
            'title': 'Deactivation Method',
            'time': 'Manually vs Import'
          }
        ]
      }
    },
    'widget8': {
      'legend':true,
      'title': 'Pie Chart Title',
      'mainChart': [
        {
          'name': 'Label 1',
          'value': 12
        },
        {
          'name': 'Label 2',
          'value': 17
        },
        {
          'name': 'Label 3',
          'value': 28
        },
        {
          'name': 'Label 4',
          'value': 25
        },
        {
          'name': 'Label 5',
          'value': 15
        }
      ]
    },
    'widget9': {
      'title': 'Spent',
      'ranges': {
        'TW': 'This Week',
        'LW': 'Last Week',
        '2W': '2 Weeks Ago'
      },
      'weeklySpent': {
        'title': 'WEEKLY SPENT',
        'count': {
          '2W': '2,682.85',
          'LW': '1,445.34',
          'TW': '3,630.15'
        },
        'chart': {
          '2W': [
            {
              'name': 'CREATED',
              'series': [
                {
                  'name': 'Mon',
                  'value': 6
                },
                {
                  'name': 'Tue',
                  'value': 1
                },
                {
                  'name': 'Wed',
                  'value': 3
                },
                {
                  'name': 'Thu',
                  'value': 4
                },
                {
                  'name': 'Fri',
                  'value': 5
                },
                {
                  'name': 'Sat',
                  'value': 5
                },
                {
                  'name': 'Sun',
                  'value': 2
                }
              ]
            }
          ],
          'LW': [
            {
              'name': 'Created',
              'series': [
                {
                  'name': 'Mon',
                  'value': 4
                },
                {
                  'name': 'Tue',
                  'value': 6
                },
                {
                  'name': 'Wed',
                  'value': 2
                },
                {
                  'name': 'Thu',
                  'value': 2
                },
                {
                  'name': 'Fri',
                  'value': 1
                },
                {
                  'name': 'Sat',
                  'value': 3
                },
                {
                  'name': 'Sun',
                  'value': 4
                }
              ]
            }
          ],
          'TW': [
            {
              'name': 'Created',
              'series': [
                {
                  'name': 'Mon',
                  'value': 2
                },
                {
                  'name': 'Tue',
                  'value': 6
                },
                {
                  'name': 'Wed',
                  'value': 5
                },
                {
                  'name': 'Thu',
                  'value': 4
                },
                {
                  'name': 'Fri',
                  'value': 5
                },
                {
                  'name': 'Sat',
                  'value': 3
                },
                {
                  'name': 'Sun',
                  'value': 6
                }
              ]
            }
          ]
        }
      },
      'totalSpent': {
        'title': 'TOTAL SPENT',
        'count': {
          '2W': '29,682.85',
          'LW': '31,128.19',
          'TW': '34,758.34'
        },
        'chart': {
          '2W': [
            {
              'name': 'CREATED',
              'series': [
                {
                  'name': 'Mon',
                  'value': 3
                },
                {
                  'name': 'Tue',
                  'value': 2
                },
                {
                  'name': 'Wed',
                  'value': 2
                },
                {
                  'name': 'Thu',
                  'value': 4
                },
                {
                  'name': 'Fri',
                  'value': 7
                },
                {
                  'name': 'Sat',
                  'value': 7
                },
                {
                  'name': 'Sun',
                  'value': 4
                }
              ]
            }
          ],
          'LW': [
            {
              'name': 'Created',
              'series': [
                {
                  'name': 'Mon',
                  'value': 5
                },
                {
                  'name': 'Tue',
                  'value': 7
                },
                {
                  'name': 'Wed',
                  'value': 8
                },
                {
                  'name': 'Thu',
                  'value': 8
                },
                {
                  'name': 'Fri',
                  'value': 6
                },
                {
                  'name': 'Sat',
                  'value': 4
                },
                {
                  'name': 'Sun',
                  'value': 1
                }
              ]
            }
          ],
          'TW': [
            {
              'name': 'Created',
              'series': [
                {
                  'name': 'Mon',
                  'value': 6
                },
                {
                  'name': 'Tue',
                  'value': 4
                },
                {
                  'name': 'Wed',
                  'value': 7
                },
                {
                  'name': 'Thu',
                  'value': 5
                },
                {
                  'name': 'Fri',
                  'value': 5
                },
                {
                  'name': 'Sat',
                  'value': 4
                },
                {
                  'name': 'Sun',
                  'value': 7
                }
              ]
            }
          ]
        }
      },
      'remaining': {
        'title': 'REMAINING',
        'count': {
          '2W': '94.317,15',
          'LW': '92.871,81',
          'TW': '89.241,66'
        },
        'chart': {
          '2W': [
            {
              'name': 'CREATED',
              'series': [
                {
                  'name': 'Mon',
                  'value': 1
                },
                {
                  'name': 'Tue',
                  'value': 4
                },
                {
                  'name': 'Wed',
                  'value': 5
                },
                {
                  'name': 'Thu',
                  'value': 7
                },
                {
                  'name': 'Fri',
                  'value': 8
                },
                {
                  'name': 'Sat',
                  'value': 2
                },
                {
                  'name': 'Sun',
                  'value': 4
                }
              ]
            }
          ],
          'LW': [
            {
              'name': 'Created',
              'series': [
                {
                  'name': 'Mon',
                  'value': 3
                },
                {
                  'name': 'Tue',
                  'value': 2
                },
                {
                  'name': 'Wed',
                  'value': 1
                },
                {
                  'name': 'Thu',
                  'value': 4
                },
                {
                  'name': 'Fri',
                  'value': 8
                },
                {
                  'name': 'Sat',
                  'value': 8
                },
                {
                  'name': 'Sun',
                  'value': 4
                }
              ]
            }
          ],
          'TW': [
            {
              'name': 'Created',
              'series': [
                {
                  'name': 'Mon',
                  'value': 2
                },
                {
                  'name': 'Tue',
                  'value': 4
                },
                {
                  'name': 'Wed',
                  'value': 8
                },
                {
                  'name': 'Thu',
                  'value': 6
                },
                {
                  'name': 'Fri',
                  'value': 2
                },
                {
                  'name': 'Sat',
                  'value': 5
                },
                {
                  'name': 'Sun',
                  'value': 1
                }
              ]
            }
          ]
        }
      },
      'totalRemaining': {
        'title': 'TOTAL BUDGET',
        'count': '124.000,00'
      },
      'totalBudget': {
        'title': 'TOTAL BUDGET',
        'count': '124.000,00'
      }
    },
    'widget10': {
      'title': 'Saved Reports',
      'table': {
        'columns': [
          {
            'title': 'Report Name'
          },
          {
            'title': 'Created By'
          },
          {
            'title': 'Date Created'
          },
          {
            'title': 'Last Sent'
          },
          {
            'title': 'Sent'
          },
          {
            'title': 'Resources'
          }
        ],
        'rows': [
          [
            {
              'value': 'My Monthly Report',
              'classes': '',
              'icon': ''
            },
            {
              'value': 'James Adminton',
              'classes': 'text-bold',
              'icon': ''
            },
            {
              'value': 'May 03 2018, 8:37 PM',
              'classes': '',
              'icon': ''
            },
            {
              'value': 'May 31 2018',
              'classes': 'text-success',
              'icon': ''
            },
            {
              'value': '2',
              'classes': '',
              'icon': ''
            },
            {
              'value': 'Monthly',
              'classes': '',
              'icon': ''
            }
          ],
          [
            {
              'value': 'Previous Month Fails',
              'classes': '',
              'icon': ''
            },
            {
              'value': 'Chua James ',
              'classes': 'text-bold',
              'icon': ''
            },
            {
              'value': 'April 03 2018, 8:37 PM',
              'classes': '',
              'icon': ''
            },
            {
              'value': 'May 26 2019',
              'classes': 'text-success',
              'icon': ''
            },
            {
              'value': '2',
              'classes': '',
              'icon': ''
            },
            {
              'value': 'Monthly',
              'classes': '',
              'icon': ''
            }
          ],
          [
            {
              'value': 'My year to date',
              'classes': '',
              'icon': ''
            },
            {
              'value': 'James Adminton',
              'classes': 'text-bold',
              'icon': ''
            },
            {
              'value': 'March 20 2018, 1:37 PM',
              'classes': '',
              'icon': ''
            },
            {
              'value': 'December 31 2018',
              'classes': 'text-success',
              'icon': ''
            },
            {
              'value': '2',
              'classes': '',
              'icon': ''
            },
            {
              'value': 'Monthly',
              'classes': '',
              'icon': ''
            }
          ],
          [
            {
              'value': 'Mid Year Check In',
              'classes': '',
              'icon': ''
            },
            {
              'value': 'James Adminton',
              'classes': 'text-bold',
              'icon': ''
            },
            {
              'value': 'May 03 2018, 8:37 PM',
              'classes': '',
              'icon': ''
            },
            {
              'value': 'June 31 2018',
              'classes': 'text-success',
              'icon': ''
            },
            {
              'value': '2',
              'classes': '',
              'icon': ''
            },
            {
              'value': 'Monthly',
              'classes': '',
              'icon': ''
            }
          ]
        ]
      }
    },
    'widget11': {
      'title': 'Team Members',
      'table': {
        'columns': ['avatar', 'name', 'position', 'office', 'email', 'phone'],
        'rows': [
          {
            avatar: 'assets/images/avatars/james.jpg',
            name: 'Jack Gilbert',
            position: 'Design Manager',
            office: 'Johor Bahru',
            email: 'jgilbert48@mail.com',
            phone: '+16 298 032 7774'
          },
          {
            avatar: 'assets/images/avatars/katherine.jpg',
            name: 'Kathy Anderson',
            position: 'Recruiting Manager',
            office: 'Solţānābād',
            email: 'kanderson49@mail.com.br',
            phone: '+23 572 311 1136'
          },
          {
            avatar: 'assets/images/avatars/andrew.jpg',
            name: 'Mark Turner',
            position: 'Recruiting Manager',
            office: 'Neftegorsk',
            email: 'mturner4a@mail.com',
            phone: '+01 139 803 9263'
          },
          {
            avatar: 'assets/images/avatars/jane.jpg',
            name: 'Kathryn Martinez',
            position: 'Director of Sales',
            office: 'Palekastro',
            email: 'kmartinez4b@mail.com',
            phone: '+25 467 022 5393'
          },
          {
            avatar: 'assets/images/avatars/alice.jpg',
            name: 'Annie Gonzales',
            position: 'Actuary',
            office: 'Candon',
            email: 'agonzales4c@mail.edu',
            phone: '+99 891 619 7138'
          },
          {
            avatar: 'assets/images/avatars/vincent.jpg',
            name: 'Howard King',
            position: 'Human Resources',
            office: 'Bergen op Zoom',
            email: 'hking4d@mail.gov',
            phone: '+46 984 348 1409'
          },
          {
            avatar: 'assets/images/avatars/joyce.jpg',
            name: 'Elizabeth Dixon',
            position: 'Electrical Engineer',
            office: 'Písečná',
            email: 'edixon4e@mail.gov',
            phone: '+33 332 067 9063'
          },
          {
            avatar: 'assets/images/avatars/danielle.jpg',
            name: 'Dorothy Morris',
            position: 'Office Assistant',
            office: 'Magsaysay',
            email: 'dmorris4f@mail.com',
            phone: '+05 490 958 6120'
          },
          {
            avatar: 'assets/images/avatars/carl.jpg',
            name: 'Mark Gonzales',
            position: 'Quality Control',
            office: 'Matsue-shi',
            email: 'mgonzales4g@mail.com',
            phone: '+03 168 394 9935'
          },
          {
            avatar: 'assets/images/avatars/profile.jpg',
            name: 'Catherine Rogers',
            position: 'Programmer Analyst',
            office: 'Kangar',
            email: 'crogers4h@mail.com',
            phone: '+86 235 407 5373'
          },
          {
            avatar: 'assets/images/avatars/garry.jpg',
            name: 'Ruth Grant',
            position: 'Community Outreach',
            office: 'Beaune',
            email: 'rgrant4i@mail.pl',
            phone: '+36 288 083 8460'
          },
          {
            avatar: 'assets/images/avatars/james.jpg',
            name: 'Phyllis Gutierrez',
            position: 'Administrative Assistant',
            office: 'Shlissel’burg',
            email: 'pgutierrez4j@mail.net',
            phone: '+52 749 861 9304'
          }, {
            avatar: 'assets/images/avatars/alice.jpg',
            name: 'Lillian Morris',
            position: 'Media Planner',
            office: 'Berlin',
            email: 'lmorris4k@mail.de',
            phone: '+59 695 110 3856'
          }, {
            avatar: 'assets/images/avatars/vincent.jpg',
            name: 'Jeremy Anderson',
            position: 'Systems Engineer',
            office: 'Lũng Hồ',
            email: 'janderson4l@mail.uk',
            phone: '+40 384 115 1448'
          },
          {
            avatar: 'assets/images/avatars/carl.jpg',
            name: 'Arthur Lawrence',
            position: 'Nurse Practicioner',
            office: 'Sarkanjut',
            email: 'alawrence4m@mail.com',
            phone: '+36 631 599 7867'
          }, {
            avatar: 'assets/images/avatars/andrew.jpg',
            name: 'David Simmons',
            position: 'Social Worker',
            office: 'Ushumun',
            email: 'dsimmons4n@mail.com',
            phone: '+01 189 681 4417'
          }, {
            avatar: 'assets/images/avatars/danielle.jpg',
            name: 'Daniel Johnston',
            position: 'Help Desk',
            office: 'São Carlos',
            email: 'djohnston4o@mail.gov',
            phone: '+60 028 943 7919'
          },

          {
            avatar: 'assets/images/avatars/joyce.jpg',
            name: 'Ann King',
            position: 'Internal Auditor',
            office: 'Liren',
            email: 'aking4p@mail.com',
            phone: '+91 103 932 6545'
          },
          {
            avatar: 'assets/images/avatars/james.jpg',
            name: 'Phillip Franklin',
            position: 'VP Accounting',
            office: 'Soba',
            email: 'pfranklin4q@mail.com',
            phone: '+25 820 986 7626'
          },
          {
            avatar: 'assets/images/avatars/garry.jpg',
            name: 'Gary Gonzalez',
            position: 'Speech Pathologist',
            office: 'Gangkou',
            email: 'ggonzalez4r@mail.cc',
            phone: '+10 862 046 7916'
          }
        ]
      }
    },
    'weatherWidget': {
      'locations': {
        'NewYork': {
          'name': 'New York',
          'icon': 'icon-rainy2',
          'temp': {
            'C': '22',
            'F': '72'
          },
          'windSpeed': {
            'KMH': 12,
            'MPH': 7.5
          },
          'windDirection': 'NW',
          'rainProbability': '98%',
          'next3Days': [
            {
              'name': 'Sunday',
              'icon': 'icon-rainy',
              'temp': {
                'C': '21',
                'F': '70'
              }
            },
            {
              'name': 'Sunday',
              'icon': 'icon-cloudy',
              'temp': {
                'C': '19',
                'F': '66'
              }
            },
            {
              'name': 'Tuesday',
              'icon': 'icon-windy3',
              'temp': {
                'C': '24',
                'F': '75'
              }
            }
          ]
        }
      },
      'currentLocation': 'NewYork',
      'tempUnit': 'C',
      'speedUnit': 'KMH'
    }
  };
}
