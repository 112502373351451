import { QuizConfig } from './quiz-config';
import { Question } from './question';

export class Quiz {

    id: number;
    companyID: number;
    profileID: number;
    contentID: string;
    title: string;
    passingScore: number;
    config: QuizConfig;
    questions: Question[];

    constructor(data: any) {
        if (data) {
            this.id = data.id;
            this.title = data.title;
            this.passingScore = data.passingScore;
            this.companyID = data.companyID;
            this.profileID = data.profileID;
            this.contentID = data.contentID;
            this.config = new QuizConfig(data.config);
            this.questions = [];
            data.questions.forEach(q => {
                this.questions.push(new Question(q));
            });
        }
    }
}
