import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TrainingService } from '../../_services/training.service';

@Component({
  selector: 'app-wifi-info-dialog',
  templateUrl: './wifi-info-dialog.component.html',
  styleUrls: ['./wifi-info-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,

})
export class WifiInfoDialogComponent implements OnInit {

  location: any;

  constructor(public matDialogRef: MatDialogRef<WifiInfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private trainingService: TrainingService) {
    this.trainingService.getTrainingLocation(_data.classId).then(res => {
      this.location = res;
    })
  }

close(){
  this.matDialogRef.close();
}

  ngOnInit() {
  }

}
