import { Component, Input, OnInit, ViewEncapsulation, ViewChild, ElementRef, ChangeDetectionStrategy } from '@angular/core';
import { absAnimations } from '@abs/animations';
import { SwiperConfigInterface, SwiperScrollbarInterface } from 'ngx-swiper-wrapper';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PreviewComponent } from './preview/preview.component';
import { Content, Category, ContentType } from '../content-list.model';
import { ActivatedRoute } from '@angular/router';
import { ContentService } from '../content.service';
import { Observable, Subject } from 'rxjs';
import { of } from 'rxjs';
import { FormControl } from '@angular/forms';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { startWith, map, debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { MatChipInputEvent, MatChipList } from '@angular/material/chips';
import { AbsUtils } from '@abs/utils';
import { AbsProgressBarService } from '@abs/components/progress-bar/progress-bar.service';
import { environment } from 'environments/environment';
import { Tag } from '../tag.model';
import { ActivityLog, ActivityType, ActivityLogService } from '../../service/activity-log.service';
import { ActivityLogComponent } from '../../report/activity-log/activity-log.component';
import { SupplimentalPreviewComponent } from '../supplementals/preview/supplimental-preview.component';
import { AuthenticationService } from '../../authentication/_services';
import { TourService } from 'ngx-tour-md-menu';
import { ActionBarService } from '../../../layout/components/action-bar/action-bar.service';
import { MatMenuTrigger } from '@angular/material/menu';
import { SiteHelpDialogComponent } from '../../site-help-dialog/site-help-dialog.component';
import { FileUploader } from 'ng2-file-upload';
import { MyUplaodsFileDialogComponent } from '../my-uploads/dialogs/item/item.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { VideoLengthErrorDialogComponent } from '../content-wizard/video-length-error-dialog/video-length-error-dialog.component';
import { WrongFileTypeSelectedComponent } from '../content-wizard/wrong-file-type-selected/wrong-file-type-selected.component';

@Component({
  selector: 'app-videos',
  templateUrl: './videos.component.html',
  styleUrls: ['./videos.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: absAnimations
})
export class VideosComponent implements OnInit {


  allowedMimeType = ['image/png', 'image/gif', 'video/mp4', 'image/jpeg', 'video/3gpp', 'video/3gpp2', 'application/pdf', 'image/bmp', 'text/csv', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.presentationml.presentation', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/vnd.ms-powerpoint', 'application/mp4', 'application/vnd.apple.mpegurl', 'application/rtf', 'image/tiff', 'video/x-msvideo', 'video/x-sgi-movie'];


  @ViewChild('fileInput', { static: false })
  inputFile: ElementRef;

  @ViewChild('matMenuTriggerSearch') matMenuTrigger: MatMenuTrigger;
  @ViewChild('searchInput') searchEle: ElementRef;
  uploader: FileUploader;
  showCatalog = true;
  showHelp = false;
  fileName: any;
  selectedUploadType: any;

  showUploadContent = false;

  selectedContentType: any;
  selectedMainCategory: any;
  searchCtrl = new FormControl();
  searching = false;
  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  xpandStatus = true;
  environment: any;
  lastSearchedValue: any;

  private allowFreeTextAddCat = false;
  filteredCategories: Observable<String[]>;
  categories: Category[] = [];
  contentTypes: ContentType[] = [];

  allCategories: any[] = [];
  subCategories: Category[] = [];
  tagCtrl = new FormControl();
  filteredTags: Observable<String[]>;
  tags: Tag[];
  allTags: any[] = [];
  @ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto1') matAutocompleteTag: MatAutocomplete;
  private _unsubscribeAll: Subject<any>;


  selector: string = '.mat-expansion-panel';
  autoCompleteVideos: Observable<Content[]>;
  autoCompleteTags: Observable<Tag[]>;

  videos: Observable<Content[]>;
  contents: Content[];
  videosFilterByCategory: any[];
  similiarVideos: Array<Content>;
  page: number = 1;
  step = 0;
  dialogRef: any;
  myContentDialogRef: any;
  wrongFileRef: any;


  constructor(
    public warningDialog: MatDialog,
    public wrongFileDialog: MatDialog,

    public dialog: MatDialog,
    public helpDialog: MatDialog,
    public contentDialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    public contentService: ContentService,
    public progressBarService: AbsProgressBarService,
    public authService: AuthenticationService,
    public actionBarService: ActionBarService,
    public tourService: TourService,
    public activityLog: ActivityLogService,
    private _matSnackBar: MatSnackBar) {
    this.environment = environment;
    this._unsubscribeAll = new Subject();


    const headers = [{ name: 'Accept', value: 'application/json' }];

    this.uploader = new FileUploader({
      url: `${environment.apiUrl}/content/upload`,
      disableMultipart: false,
      autoUpload: false,
      method: 'post',
      itemAlias: 'file',
      allowedMimeType: this.allowedMimeType,
      headers: headers,
    });
    /*
        this.uploader.options.filters.push({
          name: 'imageFilter',
          fn: function(item , options) {
              var type = '|' + item.type.slice(item.type.lastIndexOf('/') + 1) + '|';
              return '|jpg|png|jpeg|bmp|gif|mp4'.indexOf(type) !== -1;
          }
      });*/




    this.uploader.onAfterAddingFile = (file) => {
      file.withCredentials = false;


      let latestFile = this.uploader.queue[this.uploader.queue.length - 1]
      this.uploader.queue = [];
      this.uploader.queue.push(latestFile);

      console.log("file", file);
      this.fileName = file.file.name;
      console.log("file", this.fileName);

      const uploadedFile = this.uploader.queue[0]._file;

      console.log("video/file",this.uploader.queue[0].file.type.indexOf("video"),this.selectedUploadType);

      if (this.uploader.queue[0].file.type.indexOf("video") != -1 && this.selectedUploadType != 'V') {
      this.wrongFileRef =  this.wrongFileDialog.open(WrongFileTypeSelectedComponent, {
          backdropClass: 'previewBackdrop',
          width: '400px',
          height:'450px',
          data: {
            type: 'VIDEO'
          }
        });

        this.wrongFileRef.afterClosed()
            .subscribe((result) => {
              console.log("result",result);
              if(result && result == 'true'){
              this.showCatalog = false;
              }
            });
        return;
      }

      if (this.uploader.queue[0].file.type.indexOf("video") == -1 && this.selectedUploadType != 'F') {
        this.wrongFileRef =   this.wrongFileDialog.open(WrongFileTypeSelectedComponent, {
          backdropClass: 'previewBackdrop',
          width: '400px',
          height:'450px',
          data: {
            type: 'NONVIDOE'
          }
        });

        this.wrongFileRef.afterClosed()
        .subscribe((result) => {
          console.log("result",result);
          if(result && result == 'true'){
            this.showCatalog = false;
          }
        });
        return;
      }

      
      if (this.uploader.queue[0].file.type.indexOf("video") != -1) {

        const url = URL.createObjectURL(uploadedFile);
        console.log("url", url);
        // Create a video element
        const video = document.createElement('video');

        // Set the video source to the file URL
        video.src = url;

        // Wait for the video to load
        video.addEventListener('loadeddata', () => {
          // Get the duration of the video
          const videoDuration = video.duration;
          console.log("asdasd", video);

        });

        video.addEventListener('loadedmetadata', () => {
          // Get the duration of the video
          console.log("video", video);
          console.log("video heigh", video.videoHeight);
          console.log("video width", video.videoWidth);
          console.log("video width", video.title);
          console.log("quality", video.getVideoPlaybackQuality())

          const videoDuration = video.duration / 60;
          if (videoDuration < 35) {
            this.showCatalog = false;
          } else {
            /* this._matSnackBar.open("Video length is greater then 30 minutes.", '', {
              panelClass: 'red-bg',
              duration: 2000,
              horizontalPosition: 'right',
              verticalPosition: 'top',
              politeness: 'polite',
            }); */

            this.warningDialog.open(VideoLengthErrorDialogComponent, {
              backdropClass: 'previewBackdrop',
              width: '400px'
            });
          }
          console.log(`Video duration loadedmetadata: ${videoDuration} seconds`);
        });


      } else {
        this.showCatalog = false;
      }





      console.log("input file", this.inputFile)
      //this.openMyContentUploadDialog();
      /*var video = document.createElement('video');
     video.preload = 'metadata';

     video.onloadedmetadata = function () {

       window.URL.revokeObjectURL(video.src);
       console.log("video", video);
       if (video.duration < 1) {

         console.log("Invalid Video! video is less than 1 second");
         return;
       }

     }
     video.src = URL.createObjectURL(file.file); */
    };


  }



  openDialog(videoEL) {
    this.searchCtrl.setValue(this.lastSearchedValue);
    this.getFirst5SimilarVideos(videoEL);
    this.dialogRef = this.dialog.open(PreviewComponent, {
      id: 'preview',
      backdropClass: 'previewBackdrop',
      width: '1165px',
      disableClose: true,
      data: {
        video: videoEL,
        similarVideos: this.similiarVideos,
        preview: true
      }
    });

  }

  openMyContentUploadDialog(): void {
    this.myContentDialogRef = this.contentDialog.open(MyUplaodsFileDialogComponent, {
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '90vw',
      data: {
        uploader: this.uploader
      }
    });
    this.myContentDialogRef.afterClosed()
      .subscribe(response => {
        this.uploader.clearQueue();
        this.inputFile.nativeElement.value = "";
        this.clearAllAndSearch();
      });
  }

  ngOnInit() {
    if (this.authService.currentUserValue.providerId == 10000) {
      this.showUploadContent = true;

      this.contentTypes = [{ value: 'all', name: 'All Content Types', selected: false },
      { value: 'video', name: 'Video', selected: true },
      { value: 'nonvideo', name: 'Non Video', selected: false },
      { value: 'mystuff', name: 'My Content', selected: false }]
    } else {
      this.contentTypes = [{ value: 'all', name: 'All Content Types', selected: false },
      { value: 'video', name: 'Video', selected: true },
      { value: 'nonvideo', name: 'Non Video', selected: false },
      { value: 'mystuff', name: 'My Content', selected: false }]

    }

    this.tourService.end$.subscribe(result => {
      this.showHelp = false;
    })

    /*    if (this.authService.currentUserValue.settings) {
         let globalHelp = this.getValue(this.authService.currentUserValue.settings, 'ON_SCREEN_HELP');
         if (globalHelp && globalHelp.length > 0 && globalHelp[0].value == 'true') {
           //this.showHelp = true;
           //this.tourService.start();
         }
       } */

    this.actionBarService.onHelpChaned
      .subscribe(value => {
        if (value) {
          this.showHelp = true;
          this.tourService.start();
        } else {
          this.showHelp = false;
          this.tourService.end();
        }
      })


    /* this.actionBarService.onHelpClicked.pipe(takeUntil(this._unsubscribeAll)).subscribe(value => {
      this.helpDialog.open(SiteHelpDialogComponent, {
        width: '95vw',
        height: '950vh',
        backdropClass: 'helpBackdrop',
        panelClass:'helpPanel',
        disableClose: true,
        data: {
          module: "CONTENT",
        }
      });
    }) */
    const keywords = this.activatedRoute.snapshot.paramMap.get("keywords");
    const productId = this.activatedRoute.snapshot.paramMap.get("id");
    const defaultTab = this.activatedRoute.snapshot.paramMap.get("default_tab");


    this.contentService.getVideos(1, 1500, JSON.parse(localStorage.getItem("currentUser")).companyId).subscribe(res => {
      this.contents = res;
    });
    if (keywords) {
      this.searching = true;
      this.contentService.searchProducts(keywords).subscribe(res => {
        this.videos = of(res);
        this.searching = false;
      });
    } else if (productId) {
      var product = this.contents.filter(p => p.id == productId);
      this.videos = of(product);
    } else if (defaultTab) {
    } else {
      this.videos = new Observable<Content[]>();
    }

    this.contentService.getProductCategories().subscribe(category => {
      this.categories = category;
      this.changeSelected(null, this.categories[0]);
    });

    this.searchCtrl.valueChanges.pipe(
      debounceTime(800),
      distinctUntilChanged()
    ).subscribe(value => {
      this.searching = true;
      if (value.length > 0) {
        this.lastSearchedValue = value;
        this.contentService.searchProducts(value).subscribe(res => {
          this.searching = false;
          this.videos = of(res);
        });
      } else {
        this.searchContent();
      }
    })
  }


  search(e) {

    this.videos = this.autoCompleteVideos;
    //tag.selected = true;
    //this.searchContent();
    this.searchCtrl.setValue("");
  }

  getFirst5SimilarVideos(video) {
    video.productCategories.map(category => {
      let videos = this.contents.filter(v => {
        if (v.productCategories && v.id != video.id) {
          return v.productCategories.some(x => x.id === category.id);
        }
      });

      if (videos.length < 5) {
        let mainCatVideos = this.contents.filter(v => {
          if (v.productCategories && v.id != video.id) {
            return v.productCategories.some(x => x.id === this.selectedMainCategory.id);
          }
        });
        videos.push.apply(videos, mainCatVideos);
      }
      this.similiarVideos = videos;
    });
  }

  generateFake(count: number): Array<number> {
    const indexes = [];
    for (let i = 0; i < count; i++) {
      indexes.push(i);
    }
    return indexes;
  }
  trackByFn(index, item) {
    return item.id;
  }


  setStep(index: number) {
    this.step = index;
  }

  contentTypeSelected($event, contentType): void {
    this.clearContentTypeSelection(contentType);
    contentType.selected = !contentType.selected;
    this.selectedContentType = contentType.value;
    this.searchContent();

  }

  changeSelected($event, category): void {
    this.selectedMainCategory = category;
    this.clearCategorySelection(category);
    if (category.subCategories) {
      this.subCategories = category.subCategories;
    } else {
      this.subCategories = [];

    }
    category.selected = !category.selected
    this.searchContent();
  }

  subCategorySelected($event, category): void {
    this.clearSubCategorySelection(category);
    category.selected = !category.selected

    this.searchContent();
  }

  tagChangeSelected($event, tag) {
    tag.selected = $event.selected;
    this.searchContent();

  }

  searchContent() {
    
    this.searching = true;
    this.progressBarService.show();

    var contentType = this.contentTypes.filter(x => x.selected == true).map(x => {
      return x.value;
    });

    var cats = this.categories.filter(x => x.selected == true).map(x => {
      return x.id;
    });

    var subCats = this.subCategories.filter(x => x.selected == true).map(x => {
      return x.id;
    });

    var tags = [];

    if (this.tags) {
      tags = this.tags.filter(x => x.selected == true).map(x => {
        return x.id;
      });
    }

    if (cats.length > 0 && subCats.length == 0) {
      var allSubCats = this.subCategories.map(x => {
        return x.id;
      });
      cats.push(...allSubCats);
    }

    if (subCats.length > 0) {
      cats = subCats;
    }

    var search = {
      searchType: contentType[0],
      categories: cats,
      tags: tags,
      providerId: this.authService.currentUserValue.providerId,
      companyId: this.authService.currentUserValue.companyId,
      profileId: this.authService.currentUserValue.profileId,
      departmentId: this.authService.currentUserValue.department

    };

    this.contentService.searchProductsByCateogories(search).subscribe(result => {
      this.progressBarService.hide();
      this.videos = of(result);
      this.searching = false;

      if (result && result.length > 0) {
        var productids = result.map(x => {
          return x.id;
        });
        this.contentService.getTags(productids).subscribe(tags => {
          this.tags = tags;
        });
      } else {
        //this.tags = [];
      }
      let activityLog = new ActivityLog();
      activityLog.profileID = JSON.parse(localStorage.getItem("currentUser")).profileId
      activityLog.type = "site";
      activityLog.source = "itrain";
      activityLog.activity = ActivityType.PRODUCT;
      this.activityLog.saveActivityLog(activityLog).subscribe(res => { });
    })

  }
  onImgError(event) {
    event.target.src = this.environment.cdnUrl + '/' + this.environment.thumbDir + '/T_.jpg';
  }

  onSearch(video) {
    this.videos = of([video]);
    this.searchCtrl.setValue("");
  }

  menuOpened() {
    this.searchEle.nativeElement.focus();
  }

  clearCategorySelection(category) {
    this.categories.forEach(x => {
      if (x.id != category.id) {
        x.selected = false
      }
    }
    );
  }

  clearAllCategorySelection() {
    this.categories.forEach(x => {
        x.selected = false
    }
    );
  }
  clearSubCategorySelection(category) {
    this.subCategories.forEach(x => {
      if (x.id != category.id) {
        x.selected = false
      }
    }
    );
  }

  clearContentTypeSelection(contentType) {
    this.contentTypes.forEach(x => {
      if (x.value != contentType.value) {
        x.selected = false
      }
    });
  }

  clearAllContentTypeSelection() {
    this.contentTypes.forEach(x => {
        x.selected = false
        if(x.value =='mystuff'){
          x.selected = true;
        }
      
    });
  }

  getValue(source, key) {
    return source.filter(s => s.name == key);
  }
  goToStep(board) {
    if (this.authService.currentUserValue.settings) {
      let globalHelp = this.getValue(this.authService.currentUserValue.settings, 'ON_SCREEN_HELP');
      if (globalHelp && globalHelp.length > 0 && globalHelp[0].value == 'true') {
        this.tourService.goto(board);
      }
    }
  }

  onFileChanged(event) {
    console.log(event.target);
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }


  clearAllAndSearch() {

    this.contentTypes.map(c => {
      if (c.value == 'mystuff') {
        c.selected = true;
      } else {
        c.selected = false;
      }
    })

    this.categories.map(c => {
      c.selected = false;
    })

    this.subCategories.map(c => {
      c.selected = false;
    })

    this.searchContent();
  }
  uploadContentDone(event) {
    this.clearAllCategorySelection();
    this.clearAllContentTypeSelection();
    this.selectedContentType = 'mystuff'
    this.showCatalog = true;
    this.searchContent();

  }

  getDuration(e) {
    console.log("duration video", e)
    const duration = e.target.duration;
    console.log("duration video", duration)
  }

  fileUpload(value) {
    this.selectedUploadType = value;
    console.log("sadasd",this.selectedUploadType)
  
  }
}