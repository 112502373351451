import {InMemoryDbService} from 'angular-in-memory-web-api';
import {AnalyticsDashboardDb} from 'app/fake-db/dashboard-analytics';
import {AcademyFakeDb} from 'app/fake-db/academy';
import {ScrumboardFakeDb} from 'app/fake-db/scrumboard';
import {QuickPanelFakeDb} from 'app/fake-db/quick-panel';
import {TilesFakeDb} from './tiles';
import {DevicesFakeDb} from './devices';
import {ProjectDashboardDb} from './dashboard-project';
import {GroupsFakeDb} from './groups';
import {PlaylistManagerFakeDb} from './playlist-manager';
import {LocationFakeDb} from './locations';
import {InsightsFakeDb} from './chat';
import {CalendarFakeDb} from './calendar';
import {SavedReportsFakeDb} from './savedReports';
import { FileManagerFakeDb } from './file-manager';
import { CollectionFakeDb } from './collection';
import { TrainingComponent } from '../main/training/training.component';
import { TrainingsFakeDb } from './trainings';

export class FakeDbService implements InMemoryDbService {
  createDb(): any {
    return {

      'sreports-sreports': SavedReportsFakeDb.savedReports,
      'sreports-users': SavedReportsFakeDb.user,
      'tilesabc': TilesFakeDb.tiles,

      // Calendar
      'calendar': CalendarFakeDb.data,

      // Insights
      // Chat
      'chat-contacts': InsightsFakeDb.contacts,
      'chat-chats'   : InsightsFakeDb.chats,
      'chat-user'    : InsightsFakeDb.user,

      // PlayList
      'playlists-playlists': PlaylistManagerFakeDb.playlists,
      'playlists-user': PlaylistManagerFakeDb.users,

      // Groups
      'groups-groups': GroupsFakeDb.groups,
      'groups-user': GroupsFakeDb.users,

      // Device Management
      'devices-devices': DevicesFakeDb.devices,
      'devices-user': DevicesFakeDb.user,

    /*   'locations-locations': LocationFakeDb.locations,
      'locations-user': LocationFakeDb.user, */

      // Dashboards
      'analytics-dashboard-widgets': AnalyticsDashboardDb.widgets,
      'project-dashboard-projects' : ProjectDashboardDb.projects,
      'project-dashboard-widgets'  : ProjectDashboardDb.widgets,
      // Academy
      'academy-categories': AcademyFakeDb.categories,
      'academy-courses': AcademyFakeDb.courses,
      'academy-course': AcademyFakeDb.course,

      'scrumboard-boards': ScrumboardFakeDb.boards,

      // Quick Panel
      'quick-panel-notes': QuickPanelFakeDb.notes,
      'quick-panel-events': QuickPanelFakeDb.events,

      'file-manager': FileManagerFakeDb.files,
      'file-manager-categories': FileManagerFakeDb.categories,
      'collection-tags': CollectionFakeDb.tags,
      'trainings-mytrainings': TrainingsFakeDb.mytrainings

    };
  }
}
