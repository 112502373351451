import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'environments/environment';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ActionBarService {

  onCartChanged: Subject<any>;
  onHelpChaned: Subject<any>;
  stepChanged: Subject<any>;
  onAddUserClick: Subject<any>;
  onPasswordResetClick: Subject<any>;
  onSendLoginInfoClick: Subject<any>;
  onDisableUserClick: Subject<any>;
  onChangePasswordClick: Subject<any>;
  onEnableUserClick: Subject<any>;
  onUserStatusChanged: Subject<any>;
  onUserSelected: Subject<any>;
  onGroupSelected: Subject<any>;

  onContentSelected: Subject<any>;

  onStartClassClick: Subject<any>;
  onClassLoaded: Subject<any>;

  onAddLocationClick: Subject<any>;

  onWifiClick: Subject<any>;
  onStartCourseClick: Subject<any>;
  onSendQuizLinkClick: Subject<any>;
  onViewQuizClick: Subject<any>;
  onCloseSessionClick: Subject<any>;
  onNextCourseClick: Subject<any>;
  onClassCourseChanged: Subject<any>;

  onResumeClassClick: Subject<any>;
  onScheduleItClick: Subject<any>;

  onResetReportClick: Subject<any>;
  onExportButtonClick: Subject<any>;


  onKioskModeClick: Subject<any>;
  onKioskCloseModeClick: Subject<any>;

  showKioskMode:Subject<any>;
  onKioskModeClosed: Subject<any>;

  showCloseModeButton:Subject<any>;
  
  viewsUpdated:Subject<any>;

  onRequireViewsChanged: Subject<any>;
  onRequireViewsClicked: Subject<any>;

  onHelpClicked:Subject<any>;

  onCreateUserClicked: Subject<any>;



  /**
   * Constructor
   * @param {HttpClient} _httpClient
   */
  constructor(
    private _httpClient: HttpClient
  ) {
      this.onCreateUserClicked = new Subject();
    this.onCartChanged = new Subject();
    this.onAddUserClick = new Subject();
    this.onPasswordResetClick = new Subject();
    this.onSendLoginInfoClick = new Subject();
    this.onDisableUserClick = new Subject();
    this.onChangePasswordClick = new Subject();
    this.onEnableUserClick = new Subject();
    this.onUserStatusChanged = new Subject();
    this.onStartClassClick = new Subject();
    this.onAddLocationClick = new Subject();
    this.onUserSelected = new Subject();
    this.onContentSelected = new Subject();
    this.onWifiClick = new Subject();
    this.onStartCourseClick = new Subject();
    this.onSendQuizLinkClick = new Subject();
    this.onViewQuizClick = new Subject();
    this.onCloseSessionClick = new Subject();
    this.onGroupSelected = new Subject();
    this.onResumeClassClick = new Subject();
    this.onClassLoaded = new Subject();
    this.onScheduleItClick = new Subject();
    this.onNextCourseClick = new Subject();
    this.onClassCourseChanged = new Subject();
    this.onResetReportClick = new Subject();
    this.onExportButtonClick = new Subject();
    this.onKioskModeClick = new Subject();
    this.showKioskMode = new Subject();
    this.onKioskCloseModeClick = new Subject();
    this.onKioskModeClosed =new Subject();
    this.onHelpChaned = new Subject();
    this.showCloseModeButton = new Subject();
    this.stepChanged = new Subject();
    this.viewsUpdated = new Subject();
    this.onRequireViewsChanged = new Subject();
    this.onRequireViewsClicked = new Subject();
    this.onHelpClicked = new Subject();
  }


  getModuleHelp(module) {
      return this._httpClient.get<any[]>(`${environment.apiUrl}/ui/help/${module}`).pipe(
          catchError(this.handleError)
      );;
  }

  getAllModuleHelp() {
    return this._httpClient.get<any[]>(`${environment.apiUrl}/ui/help`).pipe(
        catchError(this.handleError)
    );;
}

  handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
        // A client-side or network error occurred. Handle it accordingly.
        console.error('An error occurred:', error.error.message);
    } else {
        // The backend returned an unsuccessful response code.
        // The response body may contain clues as to what went wrong,
        console.error(
            `Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
        'Something bad happened; please try again later.');
};
}
