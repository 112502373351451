import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ContentService } from '../../content/content.service';
import { environment } from 'environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { UserInfoDialogComponent } from '../user-info-dialog/user-info-dialog.component';
import { KioskPlaylistSelectorComponent } from '../kiosk-playlist-selector/kiosk-playlist-selector.component';

export class KioskData {
  videos: any;
  profileId: any;
}


@Component({
  selector: 'app-kiosk',
  templateUrl: './kiosk.component.html',
  styleUrls: ['./kiosk.component.scss']
})
export class KioskComponent implements OnInit {
  playlists: any;
  environment: any;
  showPlayer = false;
  selectedPlaylistVidoes = [];
  @Output() onKioskUserCreated = new EventEmitter<KioskData>();
  @Output() triggerIdleTimer = new EventEmitter<boolean>();


  constructor(private contentService: ContentService,
    private matDialog: MatDialog,
    private matDialogPlaylistSelector: MatDialog) {
    this.environment = environment;
  }

  ngOnInit() {
    this.contentService.getKioskEnabledPlaylists().subscribe(res => {
      this.playlists = res;
      if (!this.playlists || this.playlists.length == 0) {
        this.launchPlaylistSelector();
      }
    })
  }


  launchPlaylistSelector() {
    var playlistDialog = this.matDialogPlaylistSelector.open(KioskPlaylistSelectorComponent, {
      id: 'playlist-preview',
      width: '550px',
      minWidth: '550px',
    });

    playlistDialog.afterClosed().subscribe(result => {
      if (result) {
        if (result == 'reload') {
          this.contentService.getKioskEnabledPlaylists().subscribe(res => {
            this.playlists = res;
          })
        }
      }
    })
  }


  startNow(playlist) {
    this.triggerIdleTimer.next(true);
    var userInfoDialogRef = this.matDialog.open(UserInfoDialogComponent, {
      id: 'quiz-preview',
      width: '500px',
    });

    userInfoDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.onKioskUserCreated.emit({ videos: playlist.contentList, profileId: result });
      }
    })
  }

  onImgError(event) {
    event.target.src = this.environment.cdnUrl + '/' + this.environment.thumbDir + '/T_.jpg';
  }

}
