import { NgModule } from '@angular/core';

import { AbsSidebarComponent } from './sidebar.component';

@NgModule({
  declarations: [
    AbsSidebarComponent
  ],
  exports : [
    AbsSidebarComponent
  ]
})
export class AbsSidebarModule { }
