import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../authentication/_helpers';
import { UserLevel } from '../models/user';

import { CheckoutViewsWidgetComponent } from '../widgets/checkout-views-widget/checkout-views-widget.component';
import { LandingPageComponent } from './landing-page.component';

const routes: Routes = [
  {
    path: '',
    component: LandingPageComponent
  }
];

  @NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})

  export class LandingPageRouting {
}