import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { SelectService } from '../../../service/select.service';
import { AuthenticationService } from '../../../authentication/_services';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AbsUtils } from '@abs/utils';
import { SelectionModel } from '@angular/cdk/collections';
import { GroupsService } from '../../../user/groups/groups.service';
import { absAnimations } from '@abs/animations';

@Component({
  selector: 'app-location-selector',
  templateUrl: './location-selector.component.html',
  styleUrls: ['./location-selector.component.scss'],
  animations:absAnimations
})
export class LocationSelectorComponent implements OnInit {
  flip:any;
  locationsGroups: any;
  allLocationGroups: any;
  searchCtrlLocation = new FormControl();
  selection = new SelectionModel<any>(false, []);
  @Output() onBackButton = new EventEmitter<any>();
  @Output() onSelection = new EventEmitter<any>();
  @Output() onDone = new EventEmitter<any>();

  constructor(private _groupService: GroupsService, private _authService: AuthenticationService) {

   }

  ngOnInit(): void {


    this._groupService.getLocationGroups().then(results => {
      this.locationsGroups = results;
      this.allLocationGroups = results;
    })

    this.searchCtrlLocation.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe(value => {
      if (value.length > 0) {
        var result = AbsUtils.filterArrayByString(this.allLocationGroups, value);
        this.locationsGroups = result;
      } else {
        this.locationsGroups = this.allLocationGroups;

      }
    });
  }

  onGroupSelected(group)
  {
    this.selection.toggle(group);
    this.onSelection.emit(this.selection.selected);

  }

  handleBack(){
    this.onBackButton.emit('location');
  }
  groupSelected(){
    this.onDone.emit(this.selection.selected);
  }
  descClicked(value) {
    this.flip = value;
  }
}
