import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { AuthenticationService } from '../../../authentication/_services';
import { absAnimations } from '@abs/animations';
import { SelectionModel } from '@angular/cdk/collections';
import { GroupsService } from '../../../user/groups/groups.service';

@Component({
  selector: 'app-group-selector',
  templateUrl: './group-selector.component.html',
  styleUrls: ['./group-selector.component.scss'],
  animations: absAnimations

})
export class GroupSelectorComponent implements OnInit {


  @Input()
  showUserList: boolean =false;

  @Input()
  default: any;

  flip:any;
  groupSelection: any = '';
  currentUser: any;

  @Input()
  groupType: any = 'none';
  
  showMainGroupSelector = true;
  showUserSelector = false;
  selectedGroups: any;
  selectedGroupsList = new SelectionModel<any>(true, []);
  userSelection = new SelectionModel<any>(true, []);

  @Output() onUserSelectionDone = new EventEmitter<any>();
  @Output() onBackButton = new EventEmitter<any>();
  @Output() onSelection = new EventEmitter<any>();



  constructor(private _authService: AuthenticationService, private _groupsService: GroupsService) {
    this.currentUser = this._authService.currentUserValue;
  }

  ngOnInit(): void {
    if(this.showUserList){
      this.showMainGroupSelector = false;
      this.showUserSelector = true;
      this.selectedGroups = this.default;
    }
  }

  seletctGroup(value) {
    this.groupSelection = value;
    this.onSelection.emit({value: value, type: 'group-type'})
    //this.onGroupSelect.emit(value);
    this.handleGroupType(value);
  }

  back() {
    this.onBackButton.emit("pressed");
  }

  handleGroupType(type) {
    this.groupType = type;

    if (type != 'DL' && type != 'OTHER') {
      this.showMainGroupSelector = false;
    } else {
      this.showMainGroupSelector = true;
    }
  }

  backButtonClicked(value) {
    if (value == 'my') {
      this.groupSelection = '';
    } else if (value == 'favorite') {
      this.groupSelection = '';
    } else if (value == 'department') {
      this.groupSelection = 'DL';
    } else if (value == 'location') {
      this.groupSelection = 'DL';
    } else if (value == 'other') {
      this.groupSelection = 'OTHER';
    }
    else if (value == 'system') {
      this.groupSelection = 'OTHER';
    }
    this.showMainGroupSelector = true;
  }

  userBackButtonClicked(value) {
    this.showUserSelector = false;
    this.showMainGroupSelector = false;
  }

  onGroupSelection(event) {
    console.log("on group selection", event);
    this.selectedGroupsList.clear();
    this.userSelection.clear();
    this._groupsService.toggleGroupSelection(this.selectedGroupsList);
    this._groupsService.toggleUserSelection(this.userSelection);
    
    event.forEach(element => {
        this.selectGroup(element);
      });
    
  }

  onGroupsFinalized(event) {
    console.log("group selected", event)
    this.selectedGroups = event;
    this.showUserSelector = true;
  }

  onUserSelection(value) {
    this.onUserSelectionDone.emit(value);
  }

  selectGroup(group) {
    this.selectedGroupsList.select(group);
    if (group.userList) {
      group.userList.map(user => {
        user.selectedGroup = group.groupID;
        this.userSelection.select(user);
      })
    }
    group.allUserSelected = true;
    if (group.userList) {
      group.selectedUserCount = group.userList.length;
    } else {
      group.selectedUserCount = 0;

    }
    this._groupsService.toggleGroupSelection(this.selectedGroupsList);
    this._groupsService.toggleUserSelection(this.userSelection);
    console.log("select Group", this.selectedGroupsList.selected);
    console.log("total users ", this.userSelection);
  }
  descClicked(value) {
    this.flip = value;
  }
}
