import { Component, OnInit, Inject, ViewChild, Input, ViewEncapsulation, SimpleChanges, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ShoppingCartService, CartItem } from 'app/main/auto-training/cart.service';
import { SelectionModel } from '@angular/cdk/collections';
import { SwiperComponent, SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { ActionBarService } from '../../../layout/components/action-bar/action-bar.service';
import { AuthenticationService } from '../../authentication/_services';
import { absAnimations } from '@abs/animations';
import { AbsProgressBarService } from '@abs/components/progress-bar/progress-bar.service';
import { map, timeout } from 'rxjs/operators';

@Component({
  selector: 'app-shopping-experience-dialog',
  templateUrl: './shopping-experience-dialog.component.html',
  styleUrls: ['./shopping-experience-dialog.component.scss'],
  animations: absAnimations,
  encapsulation: ViewEncapsulation.None,
})
export class ShoppingExperienceDialogComponent implements OnInit {
  ccMask = "0000 0000 0000 0000";
  @ViewChild('swiper') swiper: SwiperComponent;


  @Input()
  public mode: String = 'AUTH';

  refresh = false;

  public config: SwiperConfigInterface = {
    observer: true,
    observeParents: true,
    //width: 400,
    init: true,
    direction: 'horizontal',
    spaceBetween: 0,
    slidesPerView: 3,
    centeredSlides: true,
    noSwiping: true,
    allowTouchMove: false,
    navigation: true,
    /*  navigation: {
       nextEl: '.swiper-next',
       prevEl: '.swiper-prev',
     }, */
    breakpoints: {
      // when window width is <= 1440px
      1024: {
        slidesPerView: 1
      }
    }
  };

  public config2: SwiperConfigInterface = {
    observer: true,
    direction: 'horizontal',
    spaceBetween: 30,
    slidesPerView: 1,
    centeredSlides: true,
    noSwiping: true,
    allowTouchMove: false,
    navigation: {
      nextEl: '.swiper-next',
      prevEl: '.swiper-prev',
    }
  };
  customPattern = { '0': { pattern: new RegExp('\\d'), symbol: '*' } };
  shopping: any;
  paymentOption: any;
  orderPlaced = false;
  index = 0;
  cartState$ = this.shoppingCartService.state$;
  cartItems = new SelectionModel<CartItem>(true, []);
  step = 1;
  selectedViews: any;
  optionalItems: any;
  nameOnCard: any;
  zip: any;
  cardNumber: string;
  expiry: any;
  cvv: any;
  ccIcon: any;
  isValidFormInformation: boolean = false;
  constructor(@Optional() public matDialogRef: MatDialogRef<ShoppingExperienceDialogComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) private _data: any,
    public _matDialog: MatDialog,
    public shoppingCartService: ShoppingCartService,
    private progressBar: AbsProgressBarService,
    private snackBar: MatSnackBar, public authService: AuthenticationService,
    private actionBarService: ActionBarService) { }

  ngOnInit(): void {
    this.shoppingCartService.getItems();
    this.shoppingCartService.state$.subscribe(states => {
      console.log("state", states);
      for (const i of states.cart) {
        this.cartItems.select(i);
      }
    })
  }
  ngAfterViewInit(){
    if(this._data.STEP_VIEW !== 'ALL'){
      this.shopping = this._data.STEP_VIEW;
      this.swiper.directiveRef.update();
    }
  }
  setStep(step) {
    console.log(step);
    this.step = step;
  }


  onItemRemoved(item) {
    if (item.quantity > 0) {
      item.quantity = item.quantity - 1;
    }
     this.shoppingCartService.addCartItem(item);
  }


  processPaymentT(){
    this.orderPlaced = true;
  }
  processPayment() {

    var buckets = [];
    if (this.cartState$.pipe().forEach(state => {
      if (state.learnerS && state.learnerS.size > 0) {

        let bucket = { size: state.learnerS.size, ppuCost: state.learnerS.ppuCost, type: "LEARNER_VIEWS",unitCost: state.learnerS.unitCost }
        buckets.push(bucket);
      }
      if (state.classroomS && state.classroomS.size > 0) {
        let bucket = { size: state.classroomS.size, ppuCost: state.classroomS.ppuCost, type: "CLASSROOM_VIEWS",unitCost: state.classroomS.unitCost }
        buckets.push(bucket);
      }
      if (state.quizS && state.quizS.size > 0) {
        let bucket = { size: state.quizS.size, ppuCost: state.quizS.ppuCost, type: "QUIZ_VIEWS",unitCost: state.quizS.unitCost }
        buckets.push(bucket);
      }
      if (state.videoS && state.videoS.size > 0) {
        let bucket = { size: state.videoS.size, ppuCost: state.videoS.ppuCost, type: "VIDEO_VIEWS",unitCost: state.videoS.unitCost }
        buckets.push(bucket);
      }

      if (state.nonVideoS && state.nonVideoS.size > 0) {
        let bucket = { size: state.nonVideoS.size, ppuCost: state.nonVideoS.ppuCost, type: "NON_VIDEO_VIEWS",unitCost: state.nonVideoS.unitCost }
        buckets.push(bucket);
      }
    }))

      this.snackBar.open("Processing Payment...", '', {
        panelClass: '',
        horizontalPosition: 'center',
        verticalPosition: 'top',
        politeness: 'polite',
      });
    let processPayment = {
      cardnumber: this.cardNumber,
      expiredate: this.expiry,
      ccvnumber: this.cvv,
      nameOnCard: this.nameOnCard,
      companyId: this.authService.currentUserValue ? this.authService.currentUserValue.companyId : this.authService.currentPublicUserValue.companyId,
      profileId: this.authService.currentUserValue ? this.authService.currentUserValue.profileId : this.authService.currentPublicUserValue.profileId,
      email: this.authService.currentUserValue ? this.authService.currentUserValue.email : this.authService.currentPublicUserValue.email,
      orderTotal: this.shoppingCartService.viewLevelTotal$,
      items: buckets,
    }
    this.progressBar.show();
    this.shoppingCartService.processPayment(processPayment).then(res => {
      this.progressBar.hide();
      this.snackBar.dismiss();
      if (res)
        if (res.responseCode == "Approved") {
          this.actionBarService.viewsUpdated.next(true);
          this.shoppingCartService.clearAll();
          this.shoppingCartService.checkout();
          this.orderPlaced = true;
          this.snackBar.open("Payment has been process successfully.", 'Ok', {
            panelClass: 'green-bg',
            duration: 6000,
            horizontalPosition: 'right',
            verticalPosition: 'top',
            politeness: 'polite',
          });
        } else {
          this.snackBar.open(res.responseMessage, 'Ok', {
            panelClass: 'red-bg',
            duration: 6000,
            horizontalPosition: 'right',
            verticalPosition: 'top',
            politeness: 'polite',
          });
        }
    })
  }

  setPaymentOption(opt) {
    this.paymentOption = opt;
  }
  goToCheckOut() {
    this.step = 3;
  }

  addItemToCart(item: CartItem) {
   
    item.quantity++;
   // this.updateStoreQuantitiesByItem(item);
   // this.cartItems.select(item);
    this.shoppingCartService.addCartItem(item);
  }
  removeItem(type): void {
    this.shoppingCartService.removeCartItem(type);
    this.refresh = !this.refresh;
  }  

  ngOnChanges(changes: SimpleChanges) {

    if (this.cardNumber && this.zip && this.nameOnCard && this.expiry && this.cvv) {
      this.isValidFormInformation = true;
    }

  }

  setShopping(type) {
    this.shopping = type;
    this.swiper.directiveRef.update();
    this.index++;
  }

  cvvChanged(e) {
    this.step = 4;
    //this.index = this.index + 1;
  }
  creditCardChanged(e) {
    this.ccMask = "0000 0000 0000 0000";

    var value = e.target.value;

    if (this.cardNumber) {
      if (value.charAt(0) == '4') {
        this.ccIcon = "visa1.svg"
      }

      else if (value.charAt(0) == '5') {
        this.ccIcon = "mastercard.svg"
      }
      else if (value.charAt(0) == '3' && value.charAt(1) == '7') {
        this.ccMask = "0000 000000 00000";
        this.ccIcon = "american-express.svg"
      } else {
        this.ccIcon = false;
      }

    }
  }

}
