import { Component, OnInit, Input } from '@angular/core';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-user-avatar',
  templateUrl: './user-avatar.component.html',
  styleUrls: ['./user-avatar.component.scss']
})
export class UserAvatarComponent implements OnInit {
  environment: any;
  @Input() user: any;
  @Input() clazz: any;
  @Input() size = 30;
  customStyle = {
    //marginRight: "8px",
  };

  constructor() { 
    this.environment = environment;
  }

  ngOnInit() {
    //console.log("user avatar",this)
  }

}
