import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AbsProgressBarService } from '@abs/components/progress-bar/progress-bar.service';
import { ActionBarService } from '../../layout/components/action-bar/action-bar.service';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { Router } from '@angular/router';

@Component({
  selector: 'app-site-help-dialog',
  templateUrl: './site-help-dialog.component.html',
  styleUrls: ['./site-help-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SiteHelpDialogComponent implements OnInit {

  module: string;
  images: any;
  index: any;

  public config: SwiperConfigInterface = {
    init: true,
    a11y: true,
    observer: true,
    direction: 'horizontal',
    slidesPerView: 1,
    //slidesPerColumn: 1,
    keyboard: true,
    //centeredSlides: true,
    mousewheel: false,
    scrollbar: false,
    pagination: true,
    spaceBetween: 0,
    navigation: {
      nextEl: '.swiper-next',
      prevEl: '.swiper-prev',
    },
    // width: 1820,
    //centerInsufficientSlides: true
  };


  constructor(public matDialogRef: MatDialogRef<SiteHelpDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private _progressBarService: AbsProgressBarService,
    private _actionBarService: ActionBarService,
    public _router: Router) {
   // this.module = _data.module;
  }

  ngOnInit(): void {
    //let currentModule = localStorage.getItem("curren-module");
    this.index = 0;
    this._actionBarService.getAllModuleHelp().subscribe(images => {
      this.setSliderIndex();
      this.images = images;
    });

  }

  setSliderIndex() {

    if (this._router.url.includes("home")) {
      this.index = 0;
    } else if (this._router.url.includes("content/all")) {
      this.index = 3;
    }
    else if (this._router.url.includes("content/playlists")) {
      this.index = 5;
    }  else if (this._router.url.includes("user/admin")) {
      this.index = 1;
    } else if (this._router.url.includes("user/groups")) {
      this.index = 2;
    } else if (this._router.url.includes("training/assign")) {
      this.index = 11;
    }
    else if (this._router.url.includes("report/reports")) {
      this.index = 14;
    }else if (this._router.url.includes("streaming/favorite/playlists")){
      this.index = 7;
    }
    else if (this._router.url.includes("streaming/courses")){
      this.index = 8;
    }
    else if (this._router.url.includes("account/profile")){
      this.index = 15;
    }
  }
}
