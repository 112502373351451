import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TrainingComponent } from './training.component';
import { AuthGuard } from '../authentication/_helpers';
import { ClassroomComponent } from './classroom/classroom.component';
import { UserLevel } from '../models/user';
import { ClassQuizComponent } from './classroom/class-quiz/class-quiz.component';
import { PlayerComponent } from './classroom/player/player.component';
import { LocationsComponent } from './locations/locations.component';
import { LocationsService } from './locations/locations.service';
import { InsightsComponent } from './insights/insights.component';
import { InsightService } from './insights/insight.service';
import { AssignWizardComponent } from './assign-wizard/assign-wizard.component';
import { MyTrainingsComponent } from './my-trainings/my-trainings.component';
import { MyCalendarComponent } from './my-calendar/my-calendar.component';
import { MyCalendarService } from './_services/my-calendar.service';
import { CalendarComponent } from './calendar/calendar.component';
import { CalendarService } from './calendar/calendar.service';
import { CheckoutViewsWidgetComponent } from '../widgets/checkout-views-widget/checkout-views-widget.component';
import { TrainingAgendaComponent } from './training-agenda/training-agenda.component';

const routesTraining = [
    {
      path: '',
      component: TrainingComponent,
      children: [
        {
          path: '', 
          redirectTo: 'assign', 
          pathMatch: 'full'
       },
        {
          path: 'classroom',
          canActivate: [AuthGuard],
          component: ClassroomComponent,
          data: { roles: [UserLevel.TRAINER,UserLevel.ADMIN,UserLevel.MANAGER] },
        },
        {
          path: 'classroom-quiz',
          canActivate: [AuthGuard],
          component: ClassQuizComponent,
          data: { roles: [UserLevel.TRAINER,UserLevel.ADMIN,UserLevel.MANAGER,UserLevel.LEARNER] },
        },
        {
          path: 'projector',
          canActivate: [AuthGuard],
          component: PlayerComponent,
          data: { roles: [UserLevel.TRAINER,UserLevel.ADMIN,UserLevel.MANAGER] },
        },
        {
          path: 'locations',
          canActivate: [AuthGuard],
          data: { roles: [UserLevel.TRAINER,UserLevel.ADMIN,UserLevel.MANAGER] },
          component: LocationsComponent,
          resolve: {
            locations: LocationsService
          }
          
        },
        {
          path: 'insights',
          component: InsightsComponent,
          canActivate: [AuthGuard],
          data: { roles: [UserLevel.TRAINER,UserLevel.ADMIN,UserLevel.MANAGER] },
          resolve: {
            chat: InsightService
          }
        },
        /* {
          path: 'scheduled',
          component: ScheduledComponent
        }, */
        {
          path: 'assign',
          component: AssignWizardComponent,
          canActivate: [AuthGuard],
          data: { roles: [UserLevel.TRAINER,UserLevel.ADMIN,UserLevel.MANAGER] },
  
        },
        {
          path: 'my-trainings',
          canActivate: [AuthGuard],
          component: MyTrainingsComponent,
          data: { roles: [UserLevel.TRAINER,UserLevel.ADMIN,UserLevel.MANAGER,UserLevel.LEARNER] },
  
        },
        {
          path: 'my-calendar',
          component: TrainingAgendaComponent,
          canActivate: [AuthGuard],
          data: { roles: [UserLevel.TRAINER,UserLevel.ADMIN,UserLevel.MANAGER] }
        },
        {
          path: 'calendar',
          component: CalendarComponent,
          canActivate: [AuthGuard],
          data: { roles: [UserLevel.TRAINER,UserLevel.ADMIN,UserLevel.MANAGER] },
          resolve: {
            chat: CalendarService
          }
        },
        {
          path: 'checkout',
          component: CheckoutViewsWidgetComponent,
          canActivate: [AuthGuard],
          data: { roles: [UserLevel.TRAINER,UserLevel.ADMIN,UserLevel.MANAGER] }
        },
      ]
    }
  ];

  @NgModule({
    imports: [RouterModule.forChild(routesTraining)],
    exports: [RouterModule]
})

  export class TrainingRouting {
}