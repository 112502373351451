import { Component, OnInit, Output, EventEmitter, ViewEncapsulation, Input } from '@angular/core';
import { absAnimations } from '@abs/animations';
import { CompnayService } from '../../../authentication/_services';
import { AuthenticationService } from 'app/main/authentication/_services/authentication.service';
import { AbsUtils } from '@abs/utils';
@Component({
  selector: 'app-content-course-name',
  templateUrl: './content-course-name.component.html',
  styleUrls: ['./content-course-name.component.scss'], 
  animations: absAnimations,
  encapsulation: ViewEncapsulation.None,
})
export class ContentCourseNameComponent implements OnInit {

  flip:any;

  @Input()
  title: any;
  
  @Output() onBackButton = new EventEmitter<any>();

  @Output() onDone = new EventEmitter<any>();

  @Output() dataChanged = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }


  onContinue(){
    this.onDone.next({data: this.title, type: 'NAME'})
  }

  handleBack(){

  }
  descClicked(abc){
    
  }
}
