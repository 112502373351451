import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-classroom-start-countdown',
  templateUrl: './classroom-start-countdown.component.html',
  styleUrls: ['./classroom-start-countdown.component.css']
})
export class ClassroomStartCountdownComponent implements OnInit {

  constructor(public matDialogRef: MatDialogRef<ClassroomStartCountdownComponent>) { }

  ngOnInit(): void {
  }

}
