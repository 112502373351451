import { Role } from './role';
import { Device } from '../user/devices/devices.model';
import { Group } from '../user/groups/groups-all/group.model';

export class UserSetting {

    tokenLimit: number;
    phoneExt: number;
    noticeLevel: number;
    alertType: number;
    noticeType: number;
    findManager: number;
    viewCode: number;

}

export enum UserLevel{
    LEARNER = "LEARNER",
    ADMIN = "ADMIN",
    MANAGER= "MANAGER",
    TRAINER = "TRAINER"
}
export class Notification {
    newTrain: any[];
    newCourse: any[];
    passed: any[];
    failed: any[];
}

export class Toggle {
    profileID: number;
    name: string;
    value: boolean;
}
export class Setting {
    settingID: number;
    profileID: number;
    name: string;
    value: boolean;
}
export class User {

    location: number;
    avatar: string;
    role: Role;
    token?: string;
    loginId: string;
    userId: number;
    profileId: number;
    providerId: number;
    companyId: number;
    companyName: string;
    managerId: number;
    firstName: string;
    middleName: string;
    lastName: string;
    phone: string;
    smsNumber: string;
    email: string;
    altEmail: string;
    organization: string;
    position: number;
    department: number;
    employeeId: string;
    policyId: string;
    avatarImg: string;
    validEmail: boolean;
    validAltEmail: boolean;
    validSms: boolean;
    addedByID: number;
    modifiedByID: number;
    deactByID: number;
    deactMethod: string;
    active: boolean;
    dateAdded: string;
    dateModified: string;
    dateDeact: string;
    dateExpire: string;
    dateLastLogin: string;
    ticketDesign: string;
    shareFavorites: string;
    sendInvites: number;
    graceDays: number;
    trainReminders: number;
    passing: number;
    language: string;
    notifications: Notification;
    userSettings: UserSetting;
    settings: Setting[];
    notes: string;
    toggles: Toggle[];
    devices: Device[];
    groups: Group[];
    currentDevice: Device;
    tz: string;
    showGroupActionBtn: boolean;
    selected: false;
    userLevel: UserLevel;
    timeZone: string;
    dateDisplayFormat: string;
    longDateDisplayFormat: string;
    timeFormat: string;
    secondaryPass: string;
    signUpType : string;

    constructor(user) {
        this.location = user.location;
        this.avatar = user.avatar;
        this.loginId = user.loginId;
        this.role = user.role
        this.token = user.token;
        this.userId = user.userId;
        this.profileId = user.profileId;
        this.companyId = user.companyId;
        this.managerId = user.managerId;
        this.firstName = user.firstName;
        this.middleName = user.middleName;
        this.lastName = user.lastName;
        this.phone = user.phone;
        this.smsNumber = user.smsNumber;
        this.email = user.email;
        this.altEmail = user.altEmail
        this.organization = user.organization;
        this.position = user.position;
        this.department = user.department;
        this.employeeId = user.employeeId;;
        this.policyId = user.policyId;;
        this.avatarImg = user.avatarImg;
        this.validEmail = user.validEmail;
        this.validAltEmail = user.validAltEmail;
        this.validSms = user.validSms;
        this.addedByID = user.addedByID;
        this.modifiedByID = user.modifiedByID;
        this.deactByID = user.deactByID;
        this.deactMethod = user.deactMethod;
        this.active = user.active;
        this.dateAdded = user.dateAdded;
        this.dateModified = user.dateModified;
        this.dateDeact = user.dateDeact;
        this.dateExpire = user.dateExpire;
        this.dateLastLogin = user.dateLastLogin;
        this.ticketDesign = user.ticketDesign;
        this.shareFavorites = user.shareFavorites;
        this.sendInvites = user.sendInvites;
        this.graceDays = user.graceDays;
        this.trainReminders = user.trainReminders;
        this.passing = user.passing;
        this.language = user.language;
        this.notifications = user.notifications;
        this.userSettings = user.userSettings;
        this.toggles = user.toggles;
        this.devices = user.devices;
        this.settings = user.settings;
        this.currentDevice = user.currentDevice;
        this.tz = user.tz;
        this.groups = user.groups;
        this.showGroupActionBtn = user.showGroupActionBtn;
        this.selected = user.selected;
        this.userLevel = user.userLevel;
        this.timeZone = user.timeZone;
        this.timeFormat = user.timeFormat;
        this.dateDisplayFormat = user.dateDisplayFormat;
        this.longDateDisplayFormat = user.longDateDisplayFormat;
        this.notes = user.notes;
        this.secondaryPass = user.secondaryPass;
        this.signUpType = user.signUpType;
    }


}