import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-playlist-item-skeleton',
  templateUrl: './playlist-item-skeleton.component.html',
  styleUrls: ['./playlist-item-skeleton.component.scss']
})
export class PlaylistItemSkeletonComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
