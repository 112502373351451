import { Component, OnInit, Output, EventEmitter, Input, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { SelectionModel } from '@angular/cdk/collections';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AbsUtils } from '@abs/utils';
import { absAnimations } from '@abs/animations';
@Component({
  selector: 'app-send-invites-selector',
  templateUrl: './send-invites-selector.component.html',
  styleUrls: ['./send-invites-selector.component.scss'],
  animations: absAnimations,
  encapsulation: ViewEncapsulation.None
})
export class SendInvitesSelectorComponent implements OnInit {

  flip:any;
  value: any;
  @Input()
  default: any;
  @Output() onBackButton = new EventEmitter<any>();
  @Output() onSelection = new EventEmitter<any>();
  @Output() onDone = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
    this.value  = this.default;
  }

  onInputChange(event){
    this.onSelection.emit({value:event.value, type:'send-invites'});
  }

  handleBack(){
    this.onBackButton.emit('send-invites');
  }
  controlSelected(){
    this.onDone.emit({value:this.value, type:'send-invites'});
  }
  descClicked(value) {
    this.flip = value;
  }
}
