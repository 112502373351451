import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { absAnimations } from '@abs/animations';

@Component({
  selector: 'app-quiz-question-type-selector',
  templateUrl: './quiz-question-type-selector.component.html',
  styleUrls: ['./quiz-question-type-selector.component.scss'],
  animations: absAnimations
})
export class QuizQuestionTypeSelectorComponent implements OnInit {

 
  flip:any;
  @Output() onBackButton = new EventEmitter<any>();
  @Output() onSelection = new EventEmitter<any>();
  @Output() onDone = new EventEmitter<any>();
  constructor() { }

  ngOnInit(): void {
  }
  descClicked(abc){
    
  }
  selectTrainingMethod(abc){

  }
  handleBack(){
    this.onBackButton.next("question-type")
  }

  questionType(type){
    this.onDone.next(type)
  }
  
}