import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';

import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatMenuModule} from '@angular/material/menu';

import {AbsSearchBarComponent} from './search-bar.component';
import {AbsSharedModule} from '../../shared.module';
import {MatAutocompleteModule } from '@angular/material/autocomplete';
import { AbsDirectivesModule } from '../../directives/directives';
import { UserAvatarModule } from '../user-avatar/user-avatar.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    MatFormFieldModule,
    MatInputModule,
    MatMenuModule,
    AbsSharedModule,
    MatAutocompleteModule,
    AbsDirectivesModule,
    UserAvatarModule
  ],
  exports: [
    AbsSearchBarComponent
  ],
  declarations: [
    AbsSearchBarComponent
  ]
})
export class AbsSearchBarModule {
}
