import { Content } from '../content-list.model';

export class Playlist {
  id: number;
  name: string;
  profileID: number;
  kiosk: boolean;
  system: boolean;

  contentList: Content[];
  allContentSelected : false;
  selectedContentCount : number;
  playlistTime: number;
  constructor(list) {
    this.id = list.id;
    this.name = list.name;
    this.profileID = list.profileID;
    this.contentList = list.contentList || [];
    this.allContentSelected = list.allContentSelected;
    this.selectedContentCount = list.selectedContentCount;
    this.playlistTime = list.playlistTime;
    this.kiosk = list.kiosk;
    this.system = list.system;
  }
}
