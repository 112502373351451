import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActionBarComponent } from './action-bar.component';
import { RouterModule } from '@angular/router';
import { AbsSharedModule } from '@abs/shared.module';
import { MatButtonModule } from '@angular/material/button';
import { LearnerNextCourseDialogComponent } from './learner-next-course-dialog/learner-next-course-dialog.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';

@NgModule({
  declarations: [
    ActionBarComponent,
    LearnerNextCourseDialogComponent
  ],
  imports: [
    RouterModule,
    AbsSharedModule,
    MatButtonModule,
    MatDialogModule,
    MatToolbarModule,
    MatIconModule,
    MatMenuModule,
  ],
  exports: [
    ActionBarComponent,
    LearnerNextCourseDialogComponent
  ], entryComponents: [LearnerNextCourseDialogComponent]
})
export class ActionBarModule { }
