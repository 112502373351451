import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { absAnimations } from '@abs/animations';
import { AmazingTimePickerService } from 'amazing-time-picker';
import { MatDialog } from '@angular/material/dialog';
import { ScheduleAsDialogComponent } from './schedule-as-dialog/schedule-as-dialog.component';
import { ActivatedRoute } from '@angular/router';
import { TrainingService } from '../_services/training.service';
import { AbsProgressBarService } from '@abs/components/progress-bar/progress-bar.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { FlatTreeControl } from '@angular/cdk/tree';
import { environment } from 'environments/environment';
import { SelectService } from '../../service/select.service';
import { AbsUtils } from '@abs/utils';
import { Moment } from 'moment';
import * as moment from 'moment';


export class Node {
  name: string;
  children?: Node[];
  avatar: string;
  type: string;

  constructor(name, children, avatar, type) {
    this.name = name;
    this.children = children || [];
    this.avatar = avatar;
    this.type = type;
  }
}

export class FlatNode {
  expandable: boolean;
  name: string;
  avatar: string;
  type: string;
  level: number;
}


@Component({
  selector: 'app-scheduled',
  templateUrl: './scheduled.component.html',
  styleUrls: ['./scheduled.component.scss'],
  animations: absAnimations
})
export class ScheduledComponent implements OnInit {
  environment: any;
  locations: any;

  TREE_DATA: Node[] = [
    {
      name: 'Groups',
      children: [
      ],
      avatar: '',
      type: 'group'
    }, {
      name: 'Users',
      children: [
      ],
      avatar: '',
      type: 'user'

    }, {
      name: 'Playlists',
      children: [
      ],
      avatar: '',
      type: 'playlist'
    }, {
      name: 'Content',
      children: [
      ],
      avatar: '',
      type: 'content'
    },
  ];


  private _transformer = (node: Node, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      avatar: node.avatar,
      type: node.type,
      level: level,
    };
  }

  treeControl = new FlatTreeControl<FlatNode>(
    node => node.level, node => node.expandable);

  treeFlattener = new MatTreeFlattener(
    this._transformer, node => node.level, node => node.expandable, node => node.children);

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  hasChild = (_: number, node: FlatNode) => node.expandable;

  group = 'classroom';
  classroom: FormGroup;
  individual: FormGroup;
  dialogRef: any;
  users: any;
  contents: any;
  trainingType: string = 'none';
  trainers: any;
  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   * @param { FormBuilder} _formBuilder
   * @param { AmazingTimePickerService} _atp
   */
  constructor(
    private _formBuilder: FormBuilder,
    private _atp: AmazingTimePickerService,
    public _matDialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private trainingService: TrainingService,
    private _progressBarService: AbsProgressBarService,
    public _snackBar: MatSnackBar,
    public selectService: SelectService
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
    this.environment = environment;

    this.getLocations();
    this.getTrainers();

    this.trainingService.onSelectedUsers.subscribe(users => {
      let userList = users.map(user => {
        if (user.userList) {
          if (!this.TREE_DATA[0].children[user.name]) {
            this.TREE_DATA[0].children.push(new Node(user.name, [], null, 'group'));
          }
          return ({ userId: user.groupID, userType: 'group' })
        } else {
          if (!this.TREE_DATA[1].children[user.firstName + " " + user.lastName]) {
            this.TREE_DATA[1].children.push(new Node(user.firstName + " " + user.lastName, [], user.avatarImg, 'user'));
          }
          return ({ userId: user.profileId, userType: 'profile' })
        }
      })
      this.users = userList;
      this.dataSource.data = this.TREE_DATA;
      this.expandTree();
    })

    this.trainingService.onSelectedContents.subscribe(contents => {
      let contentList = contents.map(content => {
        if (content.contentList) {
          if (!this.TREE_DATA[2].children[content.name]) {
            this.TREE_DATA[2].children.push(new Node(content.name, [], null, 'playlist'));
          }
          return ({ trainId: content.id, trainType: 'playlist' })
        } else {
          if (!this.TREE_DATA[3].children[content.title]) {
            this.TREE_DATA[3].children.push(new Node(content.title, [], content.itemCode, 'content'));
          }
          return ({ trainId: content.id, trainType: 'product' })
        }
      })
      this.contents = contentList;
      this.dataSource.data = this.TREE_DATA;
      this.expandTree();
    })
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit() {







    // Class Room For
    this.classroom = this._formBuilder.group({
      title: ['', Validators.required],
      prerequisite: ['', Validators.required],
      trainerId: ['', Validators.required],
      locationID: ['', Validators.required],
      startDate: ['', Validators.required],
      time: ['', Validators.required],
      // timeZone: ['', Validators.required],
      sendInvites: [''],
      tickets: [''],
      notes: ['']
    });

    // Individual Form
    this.individual = this._formBuilder.group({
      title: ['', Validators.required],
      prerequisite: ['', Validators.required],
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
      sendInvites: ['', Validators.required],
      graceDays: ['', Validators.required],
      quizPrecentage: [''],
      fastForward: [''],
      beforeQuiz: [''],
      quizToPass: [''],
      quizToFail: [''],
      notes: ['']
    });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }


  onClassRoomSubmit() {

    let classroom = this.classroom.value;
    classroom.users = this.users;
    classroom.courses = this.contents;
    classroom.type = 'CLASSROOM';
    classroom.profileID = JSON.parse(localStorage.getItem("currentUser")).profileId; 
    var selectedLocation = this.getSelectedLocation(classroom.locationID);
    classroom.locationName = selectedLocation.name + " " + "(" + selectedLocation.label + ")";
    this.trainingService.scheduleData.next(classroom);

    /*  this.trainingService.scheduleTraining(classroom).then(res => {
       this._progressBarService.hide();
       if (res == 1) {
         this.classroom.reset();
         this._snackBar.open("Training has been scheduled for the selected users and content", 'Ok', {
           panelClass: 'green-bg',
           duration: 3000,
           horizontalPosition: 'right',
           verticalPosition: 'top',
           politeness: 'polite',
         });
       } else {
         this._snackBar.open("Something went wrong while scheduling training.", 'Ok', {
           panelClass: 'red-bg',
           duration: 3000,
           horizontalPosition: 'right',
           verticalPosition: 'top',
           politeness: 'polite',
         });
       }
     }) */
  }

  onindIvidualSubmit() {
    let individual = this.individual.value;
    individual.users = this.users;
    individual.courses = this.contents;
    individual.type = 'INDIVIDUAL';
    individual.profileID = JSON.parse(localStorage.getItem("currentUser")).profileId; 

    this.trainingService.scheduleData.next(individual);
  }

  openSendAsDialog(): void {
    this.dialogRef = this._matDialog.open(ScheduleAsDialogComponent, {
      panelClass: 'scrumboard-card-dialog',
      width: '500px',
      minHeight: '500px',
      data: {
      }
    });
    this.dialogRef.afterClosed()
      .subscribe(response => {

      });
  }

  expandTree() {
    this.treeControl.expandAll();
  }

  getTrainers() {
    let company = JSON.parse(localStorage.getItem('currentCompany'));
    if (company) {
      this.trainers = AbsUtils.filterArrayByString(company.adminAndManagers, 'TRAINER');
    }
  }
  getLocations() {
    this.selectService.getLocationsAll().subscribe(results => {
      this.locations = results;
    })
  }

  getSelectedLocation(id) {
    return this.locations.filter(location => location.id === id)[0];
  }

  myDateFilter = (m: Moment | null): boolean => {
    var currentYear = moment().year();
    const year = (m || moment()).year();
    return year >= currentYear -1;
  }
}

