export class DevicesFakeDb {
  public static devices = [
    {
      'id': 1,
      'name': 'My Laptop',
      'hashcode': '299b0f993fcdbac53171c2c6426e7f61',
      'lastIP': '72.134.76.1',
      'location': 'Lewisberry, PA',
      'userAgent': 'Mozilla/5.0 (Windows NT 6.1; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/30.0.1599.69 Safari/537.36',
      'lastSeen': '2019-11-13T16:55:51.000+0000',
      'addDate': '2018-04-09T09:50:24.000+0000',
      'deleted': false,
      'isp': 'Cox Communciation'
    },
    {
      'id': 2837,
      'name': 'Work Phone',
      'hashcode': 'cd9d0f993fcfbac53171c2c6426e7f61',
      'lastIP': '116.74.5.12',
      'location': 'Harrisburg, PA',
      'userAgent': 'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_8_5) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/30.0.1599.101 Safari/537.36',
      'lastSeen': '2019-11-07T16:55:47.000+0000',
      'addDate': '2019-09-10T11:34:24.000+0000',
      'deleted': false,
      'isp': 'Blue Ridge Communcations'
    },
    {
      'id': 2838,
      'name': 'Work Desktop',
      'hashcode': 'nhjy0f59d5cfbac53171c2c6426e7f61',
      'lastIP': '116.74.5.12',
      'location': 'Harrisburg, PA',
      'userAgent': 'Mozilla/5.0 (Windows NT 6.1; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/30.0.1599.69 Safari/537.36',
      'lastSeen': '2019-11-14T16:55:42.000+0000',
      'addDate': '2019-09-17T09:17:24.000+0000',
      'deleted': false,
      'isp': 'Blue Ridge Communcations'
    },
    {
      'id': 2839,
      'name': 'Tablet',
      'hashcode': '85df0f5j7g543ac53171c2c6426e4dd8',
      'lastIP': '72.134.76.1',
      'location': 'Lewisberry, PA',
      'userAgent': 'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_8_5) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/30.0.1599.101 Safari/537.36',
      'lastSeen': '2019-11-03T16:55:57.000+0000',
      'addDate': '2019-10-14T09:50:24.000+0000',
      'deleted': false,
      'isp': 'Cox Communciation'
    }
  ];

  public static user = [
    {
      'id': '5725a6802d10e277a0f35724',
      'name': 'John Doe',
      'avatar': 'assets/images/avatars/profile.jpg',
      'starred': [
        '5725a680ae1ae9a3c960d487',
        '5725a6801146cce777df2a08',
        '5725a680bbcec3cc32a8488a',
        '5725a680bc670af746c435e2',
        '5725a68009e20d0a9e9acf2a'
      ],
      'frequentDevices': [
        '5725a6809fdd915739187ed5',
        '5725a68031fdbb1db2c1af47',
        '5725a680606588342058356d',
        '5725a680e7eb988a58ddf303',
        '5725a6806acf030f9341e925',
        '5725a68034cb3968e1f79eac',
        '5725a6801146cce777df2a08',
        '5725a680653c265f5c79b5a9'
      ],
      'groups': [
        {
          'id': '5725a6802d10e277a0f35739',
          'name': 'Friends',
          'contactIds': [
            '5725a680bbcec3cc32a8488a',
            '5725a680e87cb319bd9bd673',
            '5725a6802d10e277a0f35775'
          ]
        },
        {
          'id': '5725a6802d10e277a0f35749',
          'name': 'Clients',
          'contactIds': [
            '5725a680cd7efa56a45aea5d',
            '5725a68018c663044be49cbf',
            '5725a6809413bf8a0a5272b1',
            '5725a6803d87f1b77e17b62b'
          ]
        },
        {
          'id': '5725a6802d10e277a0f35329',
          'name': 'Recent Workers',
          'contactIds': [
            '5725a680bbcec3cc32a8488a',
            '5725a680653c265f5c79b5a9',
            '5725a6808a178bfd034d6ecf',
            '5725a6801146cce777df2a08'
          ]
        }
      ]
    }
  ];
}
