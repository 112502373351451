import { Component, OnInit, ViewEncapsulation, Output, EventEmitter, Input } from '@angular/core';
import { absAnimations } from '@abs/animations';
import { FormControl } from '@angular/forms';
import { SelectionModel } from '@angular/cdk/collections';
import { AuthenticationService } from '../../../authentication/_services';
import { GroupsService } from '../../../user/groups/groups.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AbsUtils } from '@abs/utils';
import { ContentService } from '../../content.service';
@Component({
  selector: 'app-content-subcategory-selector',
  templateUrl: './content-subcategory-selector.component.html',
  styleUrls: ['./content-subcategory-selector.component.scss']
})
export class ContentSubcategorySelectorComponent implements OnInit {

  flip: any;

  @Input()
  categories: any;

  allCategories: any;

  search = new FormControl();
  selection = new SelectionModel<any>(true, []);

  @Output() onBackButton = new EventEmitter<any>();
  @Output() onSelection = new EventEmitter<any>();
  @Output() onDone = new EventEmitter<any>();


  constructor() {

  }

  ngOnInit(): void {
    this.search.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe(value => {
      if (value.length > 0) {
        var result = AbsUtils.filterArrayByString(this.categories, value);
        this.categories = result;
      } else {
        this.categories = this.allCategories;

      }
    });
  }

  onContinue() {
    this.onDone.next({ data: this.selection.selected, type: 'SUB_CATEGORIES' })
  }

  onChange(cat){
    this.selection.toggle(cat)
  }
  handleBack(){
    this.onBackButton.next("subcateogry")
  }
  descClicked(abc){
    
  }

}
