import { Component, OnInit, Output, EventEmitter, Input, ViewEncapsulation } from '@angular/core';
import { absAnimations } from '@abs/animations';

@Component({
  selector: 'app-passing-score-selector',
  templateUrl: './passing-score-selector.component.html',
  styleUrls: ['./passing-score-selector.component.scss'],
  animations: absAnimations,
  encapsulation: ViewEncapsulation.None,
})
export class PassingScoreSelectorComponent implements OnInit {

  value: any = 0;
  flip:any;
  @Input()
  default: any;

  @Output() onBackButton = new EventEmitter<any>();
  @Output() onSelection = new EventEmitter<any>();
  @Output() onDone = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
    this.value = this.default;
  }

  onInputChange(event) {
    this.onSelection.emit({ value: event.value, type: 'passing-score' });
  }

  handleBack() {
    this.onBackButton.emit('passing-score');
  }
  controlSelected() {
    this.onDone.emit({ value: this.value, type: 'passing-score' });
  }
  descClicked(value) {
    this.flip = value;
  }
}