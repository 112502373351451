export class TrainingsFakeDb
{
    public static mytrainings = [
        {
          'name': 'Onboarding',
          'date': new Date(),
          'location': 'Independant',
          'format': 'Online',
          'status': 'In Progress',
          'assignedBy': 'John Bosserton',
          'percentComplete': 80,
          'contents': [
            {
              'title': 'Distracted Driving: Real Accidents, Real Stories',
              'image': 'https://www.itrainstation.com/images/thumbs/T_DDRR.jpg',
              'type': 'video',
              'lastAttempt': new Date(),
              'percentage': 40,
              'isPass': true,
              'quizScore': 30,
              'percentViewed': 95,
              'attempts': 2,
              'percentile': 40,
              'certificate': true,
              'duration': 80,
              'dueDate': new Date(),
              'daysRemaining': -1,
              'prerequisite': ''
            },
            {
              'title': 'Accidents: It Wont Happen to Me- Part 1',
              'image': 'https://www.itrainstation.com/images/thumbs/T_FSTS.jpg',
              'type': 'document',
              'lastAttempt': new Date(),
              'percentage': 73,
              'isPass': true,
              'quizScore': 50,
              'percentViewed': 10,
              'attempts': 2,
              'percentile': 90,
              'certificate': true,
              'duration': 80,
              'duedate': new Date(),
              'daysRemaining': -1,
              'prerequisite': ''
            },
            {
              'title': 'Accidents: It Wont Happen to Me- Part 2',
              'image': 'https://www.itrainstation.com/images/thumbs/T_FSTS.jpg',
              'type': 'pdf',
              'lastAttempt': new Date(),
              'percentage': 73,
              'isPass': true,
              'quizScore': 90,
              'percentViewed': 70,
              'attempts': 2,
              'percentile': 90,
              'certificate': true,
              'duration': 80,
              'dueDate': new Date(),
              'daysRemaining': 15,
              'prerequisite': 'Accidents: It Wont Happen to Me- Part 1'
            },
            {
              'title': 'Distracted Driving: Real Accidents, Real Stories',
              'image': 'https://www.itrainstation.com/images/thumbs/T_DDRR.jpg',
              'type': 'video',
              'lastAttempt': new Date(),
              'percentage': 73,
              'isPass': true,
              'quizScore': 10,
              'percentViewed': 60,
              'attempts': 2,
              'percentile': 20,
              'certificate': true,
              'duration': 80,
              'dueDate': new Date(),
              'daysRemaining': -1,
              'prerequisite': ''
            },
            {
              'title': 'Accidents: Bobby Fell Into A Ditch',
              'image': 'https://www.itrainstation.com/images/thumbs/T_FSTS.jpg',
              'type': 'video',
              'lastAttempt': new Date(),
              'percentage': 73,
              'isPass': true,
              'quizScore': 10,
              'percentViewed': 60,
              'attempts': 2,
              'percentile': 90,
              'certificate': true,
              'duration': 80,
              'dueDate': new Date(),
              'daysRemaining': 55,
              'prerequisite': ''
            }
          ]
        }, {
          'name': 'Warehouse Safety',
          'date': new Date(),
          'location': 'Independant',
          'format': 'Online',
          'status': 'In Progress',
          'assignedBy': 'Diane Bosserton',
          'percentComplete': 35,
          contents:[]
        }, {
          'name': 'Janitorial Safety',
          'date': new Date(),
          'location': 'Independant',
          'format': 'Online',
          'status': 'In Progress',
          'assignedBy': 'Diane Bosserton',
          'percentComplete': 50,
          contents:[]
      
        }, {
          'name': 'Booking Parking and Intersections',
          'date': new Date(),
          'location': 'Independant',
          'format': 'Online',
          'status': 'In Progress',
          'assignedBy': 'Diane Bosserton',
          'percentComplete': 80,
          contents:[]
      
        }, {
          'name': 'Warehouse Safety',
          'date': new Date(),
          'location': 'Independant',
          'format': 'Online',
          'status': 'Scheduled',
          'assignedBy': 'Diane Bosserton',
          'percentComplete': 70,
          contents:[]
      
        }, {
          'name': 'Warehouse Safety',
          'date': new Date(),
          'location': 'Independant',
          'format': 'Online',
          'status': 'Scheduled',
          'assignedBy': 'Diane Bosserton',
          'percentComplete': 80,
          contents:[]
      
        }, {
          'name': 'Warehouse Safety',
          'date': new Date(),
          'location': 'Independant',
          'format': 'Online',
          'status': 'Scheduled',
          'assignedBy': 'Diane Bosserton',
          'percentComplete': 50,
          'contents':[]
      
        }, {
          'name': 'Warehouse Safety',
          'date': new Date(),
          'location': 'Independant',
          'format': 'Online',
          'status': 'Completed',
          'assignedBy': 'Dian Bosserton',
          'percentComplete': 60,
          contents:[]
      
        }, {
          'name': 'Warehouse Safety',
          'date': new Date(),
          'location': 'Independant',
          'format': 'Online',
          'status': 'Completed',
          'assignedBy': 'Diane Bosserton',
          'percentComplete': 10,
          contents:[]
      
        }, {
          'name': 'Warehouse Safety',
          'date': new Date(),
          'location': 'Independant',
          'format': 'Online',
          'status': 'Completed',
          'assignedBy': 'Diane Bosserton',
          'percentComplete': 20,
          contents:[]
      
        },
      ];
      
}
