import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-kiosk-player-next-video-dialog',
  templateUrl: './kiosk-player-next-video-dialog.component.html',
  styleUrls: ['./kiosk-player-next-video-dialog.component.scss']
})
export class KioskPlayerNextVideoDialogComponent implements OnInit {

  doneCourses = 0;
  totalCourses = 0;
  showExitTraining = false;

  constructor(public matDialogRef: MatDialogRef<KioskPlayerNextVideoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any) {
      this.doneCourses = _data.doneCourses;
      this.totalCourses = _data.totalCourses;
  }

  ngOnInit() {
    if(this.doneCourses == this.totalCourses){
      this.showExitTraining = true;
    }
  }

  onYesClick() {
    this.matDialogRef.close('yes');
  }

  onExistTrainingClick() {
    this.matDialogRef.close();
  }

}
