import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AbsUserComponent } from './user.component';

import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import {MatTabsModule} from '@angular/material/tabs';
import {MatListModule} from '@angular/material/list';

import {AbsSharedModule} from '@abs/shared.module';
import { MatBadgeModule } from '@angular/material/badge';
import { MatRippleModule } from '@angular/material/core';
import { UserAvatarModule } from '../user-avatar/user-avatar.module';
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatMenuModule,
    MatButtonModule,
    AbsSharedModule,
    MatIconModule,
    MatTabsModule,
    MatListModule,
    MatBadgeModule,
    MatRippleModule,
    UserAvatarModule
  ],
  declarations: [
    AbsUserComponent

  ],
  exports: [
    AbsUserComponent
  ]
})
export class AbsUserModule { }
