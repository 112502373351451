import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-hours-of-availability',
  templateUrl: './hours-of-availability.component.html',
  styleUrls: ['./hours-of-availability.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HoursOfAvailabilityComponent implements OnInit {

  workingHoursForm: FormGroup;
  workingHours: FormArray;
  days = [
    { name: 'S', selected: false },
    { name: 'M', selected: false },
    { name: 'T', selected: false },
    { name: 'W', selected: false },
    { name: 'T', selected: false },
    { name: 'F', selected: false },
    { name: 'S', selected: false }
  ];

  constructor(private formBuilder: FormBuilder, public matDialogRef: MatDialogRef<HoursOfAvailabilityComponent>) { }

  ngOnInit() {
    this.workingHoursForm = this.formBuilder.group({
      workingHours: this.formBuilder.array([this.createAvailibility()])
    });
  }
  createAvailibility(): FormGroup {
    return this.formBuilder.group({
      fromTime: '',
      toTime: '',
      weekDays: this.days
    });
  }

  onClick($event: Event, index) {
    $event.preventDefault();
    $event.stopPropagation();
    console.log(index);
  }

  changeSelected($event, day): void {
    console.log("hello",day);
    day.selected = $event.selected;
    console.log(this.days);
    console.log(this.workingHoursForm.controls);
  }
  addAvailibility(): void {
    this.workingHours = this.workingHoursForm.get('workingHours') as FormArray;
    this.workingHours.push(this.createAvailibility());
  }

  deleteAvailability(index): void {
    this.workingHours.removeAt(index);
  }

}
