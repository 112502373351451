import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { User } from '../models/user';
import { AccountProfile } from '../authentication/_models/accountprofile';


export class ActivityLog {

    profileID: number;
    videoID: string;
    docID: number;
    bucketID: number;
    source: string;
    type: string;
    activity: string;
    notes: string;
    ipAddress: string;
    userAgent: string;
}

export enum ActivityType {
    LOGIN_PUBLIC = 'login_public_checkout',
    LOGIN = 'login',
    SEARCH ='search',
    PRODUCT = 'product',
    REPORT= 'report', 
    PREVIEW= 'preview',
    WATCH= 'watch', 
    RE_WATCH= 'rewatch', 
    QUIZ = 'quiz',
    RETAKE_QUIZ = 'quiz_retake',
    LOGOUT = 'logout',
    PRINT_QUIZ = 'print_quiz',
    VIEW_QUIZ = 'view_quiz',
    CONSUME = 'consume',
    CONSUME_LEARNER = 'consume_learner',
    CONSUME_CLASSROOM = 'consume_classroom',
    CONTENT_CONTENT = 'consume_content',
    CERTIFICATE = 'certificate',
    SCHEDULE = 'schedule-done',
    SCHEDULE_ERROR = 'schedule-error',
    STARTED_TRAINING = 'training-start',
    USER_INVITE = 'invite-user',

 }

const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })
};

@Injectable({ providedIn: 'root' })
export class ActivityLogService {
    constructor(private http: HttpClient) { }

    getAllActivityLogs() {
        return this.http.get<ActivityLog[]>(`${environment.apiUrl}/activity`).pipe(
            catchError(this.handleError)
        );;
    }

    saveActivityLog(activityLog) {
        return this.http.post<any>(`${environment.apiUrl}/activity`, JSON.stringify(activityLog), httpOptions).pipe(
            catchError(this.handleError)
        );;

    }

    handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
                `Backend returned code ${error.status}, ` +
                `body was: ${error.error}`);
        }
        // return an observable with a user-facing error message
        return throwError(
            'Something bad happened; please try again later.');
    };
}
