import { Group } from '../../user/groups/groups-all/group.model';

export class AccountProfile {

    public constructor(init?: Partial<AccountProfile>) {
        Object.assign(this, init);
    }
    password: string;
    companyId: number;
    userId: number;
    loginId: string;
    managerId:number;
    profileId: number;
    avatar: string;
    firstName: string;
    middleName: string;
    lastName: string;
    phone: string;
    smsNumber: string;
    email: string;
    altEmail: string;
    organization: string;
    position: number;
    department?: number;
    location: number;
    departments: number[];
    managerEmails: string[];
    employeeId: string;
    policyId: string;
    avatarImg: string;
    validEmail: boolean;
    validAltEmail: boolean;
    validSms: boolean;
    updateLoginId: boolean;
    updateMigrationStatus: boolean;
    active: boolean;
    groups: Group[];
    userAddedLocations: any[];
    userLevel: string;
    timeZone: string;
    dateDisplayFormat: string;
    longDateDisplayFormat: string;
    timeFormat: string;
    notes: string;
    domain: string;

   companyDomain : string;
   companyDomainHandlized : string;
   companyName: string;
   companyWebsite: string;

    organizationName: string;
	locationName: string;
	address: string;
	address1: string;
	city: string;
	state: string;
	zip: string;

}