import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators, ValidatorFn, AbstractControl, ValidationErrors, FormControl, FormArray } from '@angular/forms';
import { RegistrationService } from '../../_services/registration.service';
import { AbsProgressBarService } from '@abs/components/progress-bar/progress-bar.service';
import { SelectService } from '../../../service/select.service';
import { AuthenticationService, CompnayService } from '../../_services';
import { Router } from '@angular/router';
import { debounceTime, distinctUntilChanged, first } from 'rxjs/operators';
import { absAnimations } from '@abs/animations';
import { UserService } from '../../../service/user.service';
import { AccountProfile } from '../../_models/accountprofile';
import { Manager, RegistrationForm } from '../../register-2/register-2.component';
import { SelectionModel } from '@angular/cdk/collections';
import { User, UserLevel } from '../../../models/user';
import { AbsUtils } from '@abs/utils';
import _ from 'lodash';

@Component({
  selector: 'app-migration-reborn',
  templateUrl: './migration-reborn.component.html',
  styleUrls: ['./migration-reborn.component.scss'],
  animations: absAnimations,
  encapsulation: ViewEncapsulation.None,

})
export class MigrationRebornComponent implements OnInit {

  userAddedLocations = [];
  addLocation = false;
  isFreeDomain = false;
  @Input()
  existingUser: any;

  @Input()
  profileId: any;

  @Input()
  isSedgwickFlow: any;

  @Input()
  userId: any;

  @Input()
  username: any;

  @Input()
  sso: boolean;

  @Input()
  providerHash: any

  currentUser: any;
  imagePath: any;
  userLevel: any;
  alreadyExistPhone = false;
  showPhone = false;
  departments: any;
  myDepartments = [];

  allLocations: any;

  allDepartments: any;
  allCompanyDepartments: any;

  selection = new SelectionModel<any>(false, []);
  selectionOrgDep = new SelectionModel<any>(true, []);
  newlyAddedOrgDep = new SelectionModel<any>(true, []);

  addDepartment = false;
  selectionDep = new SelectionModel<any>(false, []);
  searchCtrlDepartment = new FormControl();
  searchCtrlMy = new FormControl();
  searchCtrlOrg = new FormControl();
  searchCtrlLocation = new FormControl();
  handalizedOrganization: string;
  createOrganization = false;
  found = false;
  webaddressfound = false;
  show = false;
  showWebAdd = false;
  company: any;
  isCustomCompany = false;
  showCompanyLocaitons = false;
  selectLocations = false;
  useEmplyeeId = false;
  useEmailAddress = true;
  customCompanyAdmins: User[];
  customCompanyManagers: User[];
  registerForm: FormGroup;
  managerForm: FormGroup;
  verificationForm: FormGroup;
  passwordForm: FormGroup;
  organizationForm: FormGroup;
  organizationLocationForm: FormGroup;
  addManagerForm: FormGroup;

  alreadyExist = false;
  invalidDomain = false;
  newUser = false;
  isStudent = false;
  isVerificationStep = false;
  manager: Manager;
  verificationCode: string;
  passwordScreen = false;
  showAccountInput = true;
  verificationEmailSent = false;
  managerNotFound = false;
  managerPhonePanel = false;
  managerEmailPanel = false;
  hidePassword = true;
  registrationDone = false;
  selectDepartment = false;
  verifyViaSMS = false;
  verificationSmsSent = false;
  isSMSVerified = false;
  isEmailVerified = false;
  environment: any;
  searchManager = true;
  states: any;
  userType = 'M';
  locations: any;
  uploading = false;
  compDepartments: any;
  userCreated = false;
  imageFile: any;
  alreadyExistUser = false;
  alreadyExistEmail = false;
  showEmail = false;
  isValidUserInformation = false;
  managerList: any;
  step = 0;
  hasCompanyLocation = false;

  constructor(private _formBuilder: FormBuilder,
    private _registrationService: RegistrationService,
    private _userService: UserService,
    private _progressBarService: AbsProgressBarService,
    private _selectService: SelectService,
    private authenticationService: AuthenticationService,
    private _companyService: CompnayService,
    private router: Router, private _compService: CompnayService, ) {

    this._selectService.getDepartmentsAll().subscribe(res => {
      // this.myDepartments = res
      this.allCompanyDepartments = res;
      this.compDepartments = res;
      var defaults = JSON.parse(localStorage.getItem("system_defaults"));
      if (defaults) {
        let DEFAULT_DEPARTMENTS = AbsUtils.filterArrayByString(defaults, "DEFAULT_DEPARTMENTS");
        if (DEFAULT_DEPARTMENTS && DEFAULT_DEPARTMENTS.length > 0) {
          DEFAULT_DEPARTMENTS[0].value.split(",").forEach(element => {
            console.log(element + "");
            this.selectionOrgDep.select(element + "");
            this.myDepartments.push(this.compDepartments.find(d => d.key == element + ""));
          });
        } else {
          this.selectionOrgDep.select("83");
          this.selectionOrgDep.select("147");
        }
      }
      this.compDepartments.unshift(                      // add to the front of the array
        this.compDepartments.splice(                     // the result of deleting items
          this.compDepartments.findIndex(                // starting with the index where
            elt => elt.key === '83'), // the name is Sarah
          1)[0]                             // and continuing for one item
      )
      this.compDepartments.unshift(                      // add to the front of the array
        this.compDepartments.splice(                     // the result of deleting items
          this.compDepartments.findIndex(                // starting with the index where
            elt => elt.key === '147'), // the name is Sarah
          1)[0]                             // and continuing for one item
      )

    });

  }

  get f() {
    return this.registerForm.controls;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------


  get managers() {
    return this.addManagerForm.controls["managers"] as FormArray;
  }
  /**
   * On init
   */
  ngOnInit(): void {

    this.authenticationService.getUserByProfileId(this.profileId).subscribe(value => {
      console.log("value", value);
      this.currentUser = value;

      if (this.currentUser.userLevel == UserLevel.LEARNER) {
        this.userType = 'L';
      }
      this.organizationForm = this._formBuilder.group({
        organization: [this.currentUser.companyName, Validators.required],
        website: ['', [Validators.required, Validators.pattern('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?')]],
        organizationWebAdd: [''],

      });

      this.organizationForm.controls['website'].valueChanges
        .pipe(
          debounceTime(1000),
          distinctUntilChanged()
        )
        .subscribe(e => {
          if (!this.organizationForm.controls.website.invalid) {
            console.log("valid");
            this.checkOrganization(e);
          }
        });

      this.organizationForm.controls['organizationWebAdd'].valueChanges
        .pipe(
          debounceTime(1000),
          distinctUntilChanged()
        )
        .subscribe(e => {
          this.checkOrganization(e);
        });


      this.hasCompanyLocation = this.currentUser.companyLocationId != "";

      this.registerForm = this._formBuilder.group({

        firstName: [this.currentUser.firstName, [Validators.required]],
        lastName: [this.currentUser.lastName, Validators.required],

        location: [{ disabled: true, value: '' }, Validators.required],
        department: [{ disabled: true, value: '' }, Validators.required],

        email: [{ disabled: true, value: this.username }, [Validators.required, Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]],
        phone: [{ disabled: true, value: this.currentUser.phone }, [Validators.required, Validators.pattern("^[0-9]*$"), Validators.minLength(10), Validators.maxLength(10)]]

      });

      this.checkIfUserAlreadyExist();

      this.registerForm.controls['phone'].valueChanges
        .pipe(
          debounceTime(200),
          distinctUntilChanged()
        )
        .subscribe(phone => {
          console.log(this.f);
          if (phone && phone.length > 0) {
            {
              if (this.f.phone.valid) {
                this.checkIfPhoneAlreadyExist()
              }
            }
          } else {
            this.f.phone.setValue(null);
          }
          this.checkIfValidInformation();
        });

      this.registerForm.controls['email'].valueChanges
        .pipe(
          debounceTime(1000),
          distinctUntilChanged()
        )
        .subscribe(e => {
          this.showEmail = false;
          this.alreadyExistEmail = false;
          if (e && e.length > 0 && !this.f.email.invalid && this.f.email.value !== this.username) {
            this.checkEmailAlreadyExist()
          }

          this.checkIfValidInformation();
        });


    });

    this.addManagerForm = this._formBuilder.group({
      managers: this._formBuilder.array([])
    });
    this.addManger();
    this.addManger();
    this.addManger();

    let isnum = this.username.match(/^[0-9]+$/) != null;





    this.managerForm = this._formBuilder.group({
      managerEmail: ['', [Validators.required, Validators.email]],
      managerPhone: [{ disabled: true, value: '' }, [Validators.required]]
    });

    this.verificationForm = this._formBuilder.group({
      verificationCode: ['', [Validators.required]],
      verificationPhone: [{ disabled: true, value: '' }, [Validators.required]]
    });

    this.passwordForm = this._formBuilder.group({
      password: ['', Validators.required],
      passwordConfirm: ['', [Validators.required, confirmPasswordValidator]]
    });


    this.organizationLocationForm = this._formBuilder.group({

      location: ['', Validators.required],
      address: ['', Validators.required],
      address1: [''],
      city: ['', Validators.required],
      state: ['', Validators.required],
      zip: ['', Validators.required],

    });

    this._selectService.getStates().subscribe(result => {
      this.states = result;
    })

    // Update the validity of the 'passwordConfirm' field
    // when the 'password' field changes
    this.passwordForm.get('password').valueChanges
      .subscribe(() => {
        this.passwordForm.get('passwordConfirm').updateValueAndValidity();
      });



    /*  this.organizationForm.controls['website'].valueChanges
       .pipe(
         debounceTime(1000),
         distinctUntilChanged()
       )
       .subscribe(e => {
         this.checkIfWebsiteAlreadyExist(e);
       });
  */
    this.managerForm.controls['managerEmail'].valueChanges
      .pipe(
        debounceTime(1000),
        distinctUntilChanged()
      )
      .subscribe(e => {
        this.findManager("", 'email')
      });

    this.managerForm.controls['managerPhone'].valueChanges
      .pipe(
        debounceTime(1000),
        distinctUntilChanged()
      )
      .subscribe(e => {
        console.log("sms", e);
        if (this.managerForm.controls['managerPhone'].enabled) {
          this.findManager("", 'sms')
        }
      });









    this._compService.getDepartments().subscribe(result => {
      this.departments = result;
      this.allDepartments = result;
    })



    this.searchCtrlMy.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged()
    ).subscribe(value => {
      if (value.length > 0) {
        var result = AbsUtils.filterArrayByString(this.allDepartments, value);
        this.myDepartments = result;
      } else {
        this.myDepartments = this.allDepartments;

      }
    });

    this.searchCtrlLocation.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged()
    ).subscribe(value => {
      if (value.length > 0) {
        var result = AbsUtils.filterArrayByString(this.allLocations, value);
        this.locations = result;
      } else {
        this.locations = this.allLocations;

      }
    });



    this.searchCtrlOrg.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged()
    ).subscribe(value => {
      if (value.length > 0) {
        console.log(value);
        console.log(this.compDepartments);
        var result = AbsUtils.filterArrayByString(this.allCompanyDepartments, value);
        console.log(result);
        this.compDepartments = result;
      } else {
        this.compDepartments = this.allCompanyDepartments;

      }
    });

    this.searchCtrlDepartment.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged()
    ).subscribe(value => {
      if (value.length > 0) {
        var result = AbsUtils.filterArrayByString(this.allDepartments, value);
        console.log(result);
        this.departments = result;
      } else {
        this.departments = this.allDepartments;
      }
    });


  }


  setStep(step) {
    if (step == 10 && this.providerHash != '073b05') {
      if (this.currentUser.userLevel && this.currentUser.userLevel.length > 0) {
        if (UserLevel.MANAGER == this.currentUser.userLevel) {
          this.setUser('M')
        }
        if (UserLevel.ADMIN == this.currentUser.userLevel) {
          this.setUser('A')
        }
        if (UserLevel.LEARNER == this.currentUser.userLevel) {
          this.setUser('L')
        }
        return;
      }
    }

    this.step = step;
    if (step == 1) {

      this.f.firstName.enable();
      this.f.lastName.enable();

      this.f.email.disable();
      this.f.phone.disable();


    } else if (step == 2) {

      this.f.firstName.disable();
      this.f.lastName.disable();

      this.f.email.enable();
      this.f.phone.enable();

      this.verifyViaSMS = false;
      this.verificationEmailSent = false;
      this.verificationSmsSent = false;

    } else if (step == 3) {
      this._progressBarService.show();

      if (this.f.email.valid && this.f.email.value.length > 0) {
        this._registrationService.sendVerificationEmail(this.f.email.value, this.f.email.value, null).subscribe(resp => {
          if (resp) {
            this._progressBarService.hide();
            this.verificationCode = resp;
            this.verificationEmailSent = true;
            this.verificationForm.controls.verificationCode.enable();
          }
        });
      } else {
        this._registrationService.sendVerificationSms(this.f.phone.value, 0).subscribe(resp => {
          this._progressBarService.hide();
          if (resp) {
            this.verificationForm.controls.verificationPhone.setValue(this.f.phone.value);
            this.verificationSmsSent = true;
            this.verifyViaSMS = false;
            this.verificationForm.controls.verificationCode.enable();
            this.verificationForm.controls.verificationPhone.disable();
          } else {
            this.verificationForm.controls.verificationPhone.setErrors({ 'incorrect': true })
            this.verificationForm.controls.verificationPhone.markAsTouched();
          }
        });
      }





    } else if (step == 5) {
      this.managerEmailPanel = true;
      var domain = this.f.email.value.substring(this.f.email.value.lastIndexOf("@") + 1);
      this._companyService.checkIfFreeDomain(domain, "migrtion").subscribe(r => {
        if (r == 1) {
          this.searchManager = true;
          this.isFreeDomain = true;
        } else {
          this._registrationService.findManager(domain, 'domain').subscribe(resp => {
            if (resp && resp.length > 0) {
              this.managerList = resp;
              this.searchManager = false;
              console.log("managerList", this.managerList);
            } else {
              this.searchManager = true;;
            }
          });
        }
      })



    } else if (step == 6) {
      if (this.manager) {
        this._selectService.getLocationsByCompanyId(this.manager.companyId).subscribe(results => {
          this.locations = results;
          this.allLocations = results;
          if (this.userAddedLocations && this.userAddedLocations.length > 0) {
            this.locations.push(...this.userAddedLocations);
          }
        })
        this._compService.getCompanyDepartments(this.manager.companyId).subscribe(result => {
          this.myDepartments = result;
          if (this.myDepartments.length == 0) {
            this._compService.getDepartments().subscribe(result => {
              this.myDepartments = [];
              this.myDepartments.push({ key: '83', value: 'Human Resources' }, { key: '147', value: 'Safety' })
            })
          }
        })

      } else {
        if (this.company) {
          this._selectService.getLocationsByCompanyId(this.company.id).subscribe(results => {
            this.locations = results;
            if (this.userAddedLocations && this.userAddedLocations.length > 0) {
              this.locations.push(...this.userAddedLocations);
            }
          })
          this._compService.getCompanyDepartments(this.company.id).subscribe(result => {
            this.myDepartments = result;
            if (this.myDepartments.length == 0) {
              this._compService.getDepartments().subscribe(result => {
                this.myDepartments = result;
              })
            }
          })
        } else {
          this._compService.getDepartments().subscribe(result => {
            this.myDepartments = result;
          })
        }

      }
      if (this.locations.length == 0) {
        //this.setStep(7);
      }

    }
    else if (step == 10) {
      this.managerPhonePanel = false;
      this.toggleManagerSearch(null, 'email')
    }
    else if (step == 12) {

      this.checkAndSuggest();
      //this.organizationForm.controls.organizationWebAdd.setValue(domain);
    }
  }

  onDepartmentChange(department) {
    this.selectionDep.toggle(department.id);
  }

  onMyDepartmentChange(department) {
    this.selectionDep.toggle(department.id);
  }
  onDepartmentOrgChange(department) {
    this.selectionOrgDep.toggle(department.key);

    if(this.selectionOrgDep.isSelected(department.key)){
      this.newlyAddedOrgDep.select(department.key);
    }
    var selected = this.selectionOrgDep.selected.map(dept => {
      this.compDepartments.forEach(element => {
        if (element.key == dept) {
          if (!this.myDepartments || this.myDepartments.length == 0) {
            this.myDepartments = [];
          }
          var newDept = { id: element.key, department: element.value }
          this.myDepartments.push(newDept);
        }
      });
    })
  }

  addLocationDone() {

    var location = {
      id: AbsUtils.generateGUID(),
      name: this.organizationLocationForm.controls.location.value,
      address1: this.organizationLocationForm.controls.address.value,
      address2: this.organizationLocationForm.controls.address1.value,
      city: this.organizationLocationForm.controls.city.value,
      state: this.organizationLocationForm.controls.state.value,
      zip: this.organizationLocationForm.controls.zip.value
    }
    this.organizationLocationForm.reset();
    if (!this.locations) {
      this.locations = [];
    }
    this.locations.push(location)
    this.selection.select(location.id);
    this.addLocation = false;
    this.userAddedLocations.push(location);


  }

  onLocationChange(location) {
    this.selection.toggle(location.id);

  }

  /**
   * On destroy
   */


  checkIfUserAlreadyExist() {
    if (this.registerForm.valid) {
      this.newUser = true;

      if (this.f.email.value.indexOf("@") != -1 && this.f.email.value.indexOf(".") != -1) {
        var domain = this.f.email.value.substring(this.f.email.value.lastIndexOf("@") + 1);
        console.log(AbsUtils.handleize(domain.split(".")[0]))
        domain = AbsUtils.handleize(domain.split(".")[0]);
        this.organizationForm.controls.organization.setValue(domain);
        //this.handalizedOrganization = domain;
      }

      /*  this._progressBarService.show();
       this._registrationService.checkIfUserAlreadyExist(this.f.email.value).subscribe(resp => {
           if (resp) {
               this.alreadyExist = true;
               console.log("going to emit");
               this.registerForm.controls['email'].setErrors({ 'incorrect': true })
               this.registerForm.controls['email'].markAsTouched();
               this.newUser = false;
               this._progressBarService.hide();
           } else {
               this.alreadyExist = false;
               this.newUser = true;
               this.registerForm.controls['email'].setErrors(null)
               this.registerForm.controls['email'].markAsTouched();
               this._progressBarService.hide();
           }
       }); */

    }
  }

  toggleRegistrationNumber() {
    if (this.useEmailAddress) {
      this.useEmailAddress = false;
      this.registerForm.controls.email.disable();
      this.registerForm.controls.employeeNumber.enable();
    } else {
      this.useEmailAddress = true;
      this.registerForm.controls.email.enable();
      this.registerForm.controls.employeeNumber.disable();
    }
  }

  registrationFlow(e: any, isStudent) {
    this.step = 1;
    /* this.selectDepartment = false;
    this.selectLocations = false;
    this.isStudent = isStudent;
    if (this.isStudent) {
        this.managerEmailPanel = true;
        this.managerPhonePanel = false;
        this.managerForm.controls.managerEmail.enable();
        this.managerForm.controls.managerPhone.disable();
    } else {
        this.checkIfOrganizationAlreadyExist();
        this.managerPhonePanel = false;
        this.managerEmailPanel = false;
        this.managerForm.controls.managerEmail.disable();
        this.managerForm.controls.managerPhone.disable();
    }
    this.isVerificationStep = true;
    this.showAccountInput = false; */
  }

  validateEmail(e) {
    console.log(e);
    /*  this._registrationService.checkIfUserAlreadyExist(this.f.email.value).subscribe(res => {
         this._progressBarService.hide();
         this.showEmail = true;
         if (res && res.length > 0) {
             this.alreadyExistEmail = true;
         } else {
             this.alreadyExistEmail = false;
         }
         this.checkIfValidInformation();

     }) */

  }

  findManager(e: any, type: string) {
    console.log(this.managerForm);
    console.log(this.managerForm.valid);
    if (this.managerForm.valid) {
      this._progressBarService.show();
      let value = "";
      if (type == 'sms') {
        value = this.managerForm.controls.managerPhone.value;
      } else {
        value = this.managerForm.controls.managerEmail.value;

      }
      this._registrationService.findManager(value, type).subscribe(resp => {
        if (resp && resp.length > 0) {
          this.manager = resp[0];
          this.registerForm.controls.location.setValue(this.manager.location);
          this._progressBarService.hide();
          this.managerNotFound = false;
        } else {
          this.manager = null;
          this._progressBarService.hide();
          this.managerNotFound = true;
        }
      });
    }
  }

  toggleManagerSearch(e: any, type: string) {
    this.manager = null;
    this.managerForm.controls.managerEmail.setValue("");
    this.managerForm.controls.managerPhone.setValue("");
    if (type === 'email') {
      this.managerEmailPanel = true;
      this.managerPhonePanel = false;
      this.managerForm.controls.managerEmail.enable();
      this.managerForm.controls.managerPhone.disable();
    } else {
      this.managerEmailPanel = false;
      this.managerPhonePanel = true;
      this.managerForm.controls.managerEmail.disable();
      this.managerForm.controls.managerPhone.enable();
    }

  }

  sendVerificationEmail(e: any) {
    this.managerEmailPanel = false;
    this.managerPhonePanel = false;
    if (this.manager && this.manager.companyValidEmail && this.useEmailAddress) {
      this.isStudent = false;
      this._progressBarService.show();
      this._registrationService.sendVerificationEmail(this.f.email.value, this.f.email.value, this.manager.companyId).subscribe(resp => {
        if (resp) {
          this._progressBarService.hide();
          this.verificationCode = resp;
          this.step = 3;
        }
      });
    } else {
      this.selectLocations = false;
      this.passwordScreen = true;
    }
  }

  sendVerificationSms(e: any) {
    this.managerEmailPanel = false;
    this.managerPhonePanel = false;
    this.isStudent = false;
    this._progressBarService.show();
    this._registrationService.sendVerificationSms(this.verificationForm.controls.verificationPhone.value, 0).subscribe(resp => {
      this._progressBarService.hide();
      if (resp) {
        this.verificationSmsSent = true;
        this.verifyViaSMS = false;
        this.verificationForm.controls.verificationCode.enable();
        this.verificationForm.controls.verificationPhone.disable();
      } else {
        this.verificationForm.controls.verificationPhone.setErrors({ 'incorrect': true })
        this.verificationForm.controls.verificationPhone.markAsTouched();
      }
    });
  }

  validateVerificationCode() {
    this.isStudent = false;
    this._progressBarService.show();
    this._registrationService.validateVerificationCode(this.verificationForm.controls.verificationCode.value, this.registerForm.controls.email.value).subscribe(resp => {
      this._progressBarService.hide();
      if (resp) {
        if (this.sso) {
          if (this.isSedgwickFlow) {
            if (this.providerHash != '073b05') {
              this.setUser('M');
            } else {
              this.setStep(10)
            }
          } else {
            var domain = this.f.email.value.substring(this.f.email.value.lastIndexOf("@") + 1);
            this._companyService.checkIfFreeDomain(domain, "migrtion").subscribe(r => {
              if (r == 1) {
                this.setStep(11)
              } else {
                this._companyService.getCompanyByWebsite(domain).subscribe(result => {
                  this.company = result;
                  if (this.company) {
                    this.setStep(5);
                  } else {
                    this.setStep(11)
                  }
                })
              }
            })
            // this.setStep(11)
            this.userLevel = UserLevel.MANAGER;
          }
        } else {
          this.step = 4;
        }
        console.log("step", this.step);
        this.isEmailVerified = true;
      } else {
        this.verificationForm.controls.verificationCode.setErrors({ 'incorrect': true })
        this.verificationForm.controls.verificationCode.markAsTouched();
        this.isEmailVerified = false;

      }
    });
  }

  validateVerificationCodeForSMS() {
    this.isStudent = false;
    this._progressBarService.show();
    this._registrationService.validateVerificationCodeForSMS(this.verificationForm.controls.verificationCode.value, this.verificationForm.controls.verificationPhone.value).subscribe(resp => {
      this._progressBarService.hide();
      if (resp) {


        if (this.sso) {
          if (this.isSedgwickFlow) {
            if (this.providerHash != '073b05') {
              this.setUser('M');
            } else {
              this.setStep(10)
            }
          } else {
            var domain = this.f.email.value.substring(this.f.email.value.lastIndexOf("@") + 1);
            this._companyService.checkIfFreeDomain(domain, "migrtion").subscribe(r => {
              if (r == 1) {
                this.setStep(11)
              } else {
                this._companyService.getCompanyByWebsite(domain).subscribe(result => {
                  this.company = result;
                  if (this.company) {
                    this.setStep(5);
                  } else {
                    this.setStep(11)
                  }
                })
              }
            })
            // this.setStep(11)
            this.userLevel = UserLevel.MANAGER;
          }
        } else {
          this.step = 4;
        }

        this.selectDepartment = false;
        this.verificationEmailSent = false;
        this.verificationSmsSent = false;
        this.verifyViaSMS = false;
        this.isSMSVerified = true;
      } else {
        this.verificationForm.controls.verificationCode.setErrors({ 'incorrect': true })
        this.verificationForm.controls.verificationCode.markAsTouched();
        this.isSMSVerified = false;

      }
    });
  }

  showPasswordScreen(e: any) {
    this.selectDepartment = false;
    this.passwordScreen = true;
    this.verificationEmailSent = false;
  }

  toggleShowHidePassword(e: any) {
    if (this.hidePassword) {
      this.hidePassword = false;
    } else {
      this.hidePassword = true;
    }
  }
  toggleShowLocations() {
    this.managerEmailPanel = false;
    this.managerPhonePanel = false;
    this.selectLocations = true;
    this.registerForm.controls.email.disable();
    this.registerForm.controls.employeeNumber.disable();
    this.registerForm.controls.department.disable();
    this.registerForm.controls.location.enable();
    this.showAccountInput = false;
    this.registerForm.controls.location.updateValueAndValidity();
  }
  toggleShowDepartments() {
    this.selectLocations = false;
    this.registerForm.controls.email.disable();
    this.registerForm.controls.employeeNumber.disable();
    this.registerForm.controls.location.disable();
    this.registerForm.controls.department.enable();
    this.selectDepartment = true;
  }

  toggleVerificationChannel() {
    this.verificationSmsSent = false;
    if (this.verificationEmailSent) {
      this.verificationEmailSent = false;
      this.verifyViaSMS = true;
      this.verificationForm.controls.verificationCode.disable();
      this.verificationForm.controls.verificationPhone.enable();
    } else {
      this.verificationEmailSent = true;
      this.verifyViaSMS = false;
      this.verificationForm.controls.verificationCode.enable();
      this.verificationForm.controls.verificationPhone.disable();
    }
  }

  signIn(email, password, company) {
    this._progressBarService.show();
    this.authenticationService.login(email, password, company)
      .pipe(first())
      .subscribe(
        data => {
          this._progressBarService.hide();
          this.router.navigate(['home']);
        },
        error => {

        });
  }

  setUser(value) {
    this.userType = value
    var domain = this.f.email.value.substring(this.f.email.value.lastIndexOf("@") + 1);
    if (value == 'A') {
      this._companyService.checkIfFreeDomain(domain, "migrtion").subscribe(r => {
        if (r == 1) {
          this.setStep(11)
        } else {
          this._companyService.getCompanyByWebsite(domain).subscribe(result => {
            this.company = result;
            if (this.company) {
              this.setStep(6);
            } else {
              this.setStep(11)
            }
          })
        }
      })
      // this.setStep(11)
      this.userLevel = UserLevel.ADMIN;
    } else if (value == 'M') {
      this._companyService.checkIfFreeDomain(domain, "migrtion").subscribe(r => {
        if (r == 1) {
          this.setStep(11)
        } else {
          this._companyService.getCompanyByWebsite(domain).subscribe(result => {
            this.company = result;
            if (this.company) {
              this.setStep(5);
            } else {
              this.setStep(11)
            }
          })
        }
      })
      //this.setStep(5)
      this.userLevel = UserLevel.MANAGER;
    }
    else if (value == 'L') {
      this.setStep(5)
      this.userLevel = UserLevel.LEARNER;

    }
  }

  organizationSubmit(event) {
    this.createOrganization = false;
    if (this.found) {
      console.log("in");
      this.registrationFlow("", true);
    } else {
      console.log("in else");

      this._progressBarService.show();
      this._registrationService.sendVerificationEmail(this.f.email.value, this.f.email.value, 0).subscribe(resp => {
        if (resp) {
          this._progressBarService.hide();
          this.verificationEmailSent = true;
        }
      });
      this.isVerificationStep = true;

    }

  }

  nonOfAbove() {
    this.searchManager = true;
    this.manager = null;
  }

  checkAndSuggest() {
    if (this.organizationForm.valid) {
      var domain = this.organizationForm.controls.website.value.toString();
      var splited = AbsUtils.handleize(domain.split("."));
      if (domain.indexOf("www") != -1) {
        domain = AbsUtils.handleize(domain.split(".")[1]);
      } else {
        domain = AbsUtils.handleize(domain.split(".")[0]);
      }
      this._progressBarService.show();
      var secondOption = AbsUtils.handleize(this.organizationForm.controls.organization.value);
      this._companyService.getCompanyByDomainAndSuggest(domain, secondOption).subscribe(res => {
        console.log("res", res);
        this._progressBarService.hide();
        this.organizationForm.controls.organizationWebAdd.setValue(res.website);
      })
    }
  }


  checkIfOrganizationAlreadyExist(value) {
    this.createOrganization = true;
    var domain = AbsUtils.handleize(value);
    this.handalizedOrganization = domain;
    this.showWebAdd = false;
    this._companyService.getCompanyByDomain(domain).subscribe(res => {
      this._progressBarService.hide();
      this.showWebAdd = true;
      if (res) {
        this.webaddressfound = true;
        this.company = res;
      } else {
        this.webaddressfound = false;
      }
    })
  }

  checkIfWebsiteAlreadyExist(value) {
    this._progressBarService.show();
    this._companyService.getCompanyByWebsite(value).subscribe(res => {
      this._progressBarService.hide();
      if (res) {
        this.setStep(5);
      }
    })
  }

  checkOrganization(value) {

    console.log("organization value", value);
    var domain = AbsUtils.getDomain(value, false);
    console.log("domain", domain)
    this.handalizedOrganization = domain;
    this._companyService.getCompanyByDomain(domain).subscribe(res => {
      if (res) {
        this.company = res;
        if (this.userLevel == UserLevel.ADMIN) {
          this.setStep(6);
        } else {
          this.setStep(5);
        }
      }
    })
    //this.checkIfOrganizationAlreadyExist(this.organizationForm.controls.organizationWebAdd.value);
  }
  cancel() {
    this.company = null;
    this.createOrganization = false;
    this.showAccountInput = true;
    this.selectLocations = false;
    this.selectDepartment = false;
    this.passwordScreen = false;
    this.managerEmailPanel = false;
    this.managerPhonePanel = false;
    this.verificationEmailSent = false;
  }

  onFileChanged(event) {
    //this._progressBarService.show();
    this.imageFile = event;
    this.preview(event.target.files);
  }

  uploadPicture(profileId) {
    this.uploading = true;
    const uploadImageData = new FormData();
    uploadImageData.append('picture', this.imageFile.target.files[0], this.imageFile.target.files[0].name);
    uploadImageData.append('profileId', profileId);
    this._userService.updateProfilePicture(uploadImageData).subscribe(response => {
      this.userCreated = true;
      this.uploading = false;
    });
  }

  preview(files) {
    if (files.length === 0)
      return;


    var reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.imagePath = reader.result;
    }
  }


  profileDone(uploadPicture) {

    this._progressBarService.show();
    var formData = new RegistrationForm();
    formData.department = this.selectionDep.selected[0];
    formData.departments = this.selectionOrgDep.selected;
    formData.userLevel = this.userLevel;

    formData.location = this.selection.selected[0];
    formData.managerEmail = this.managerForm.controls.managerEmail.value;
    formData.managerPhone = this.managerForm.controls.managerPhone.value;
    //formData.smsPhone = this.verificationForm.controls.verificationPhone.value;
    formData.emailVerified = this.isEmailVerified;
    formData.smsVerified = this.isSMSVerified;
    formData.companyDomain = this.handalizedOrganization;
    formData.companyDomainHandlized = this.handalizedOrganization;
    formData.companyName = this.organizationForm.controls.organization.value;
    formData.companyWebsite = this.handalizedOrganization;
    //formData.providerId = this.providerId;
    //formData.providerHash = this.providerHash;
    formData.firstName = _.startCase(_.toLower(this.f.firstName.value));
    formData.lastName = _.startCase(_.toLower(this.f.lastName.value));
    formData.username = this.f.email.valid ? this.f.email.value : this.f.phone.value;
    formData.email = this.f.email.value;
    formData.smsPhone = this.f.phone.value;


    formData.locationName = this.organizationLocationForm.controls.location.value;
    formData.address = this.organizationLocationForm.controls.address.value;
    formData.address1 = this.organizationLocationForm.controls.address1.value;
    formData.city = this.organizationLocationForm.controls.city.value;
    formData.state = this.organizationLocationForm.controls.state.value;
    formData.zip = this.organizationLocationForm.controls.zip.value;

    if (this.company) {
      formData.companyId = this.company.id;
    }
    if (this.manager) {
      formData.managerProfileId = this.manager.profileId
    }
    formData.password = this.passwordForm.controls.passwordConfirm.value;
    if (this.useEmailAddress) {
      //formData.username = this.registerForm.controls.email.value;
    } else {
      //formData.username = this.registerForm.controls.employeeNumber.value;
    }
    this._registrationService.signUp(formData).subscribe(resp => {
      this._progressBarService.hide();
      if (resp) {
        this.profileId = resp;
        if (uploadPicture) {
          this.uploadPicture(resp);
        } else {
          this.userCreated = true;
          this.done();
        }
      } else {

      }
    });
  }

  saveInformation(uploadPicture) {
    this._progressBarService.show();

    var accountProfile = new AccountProfile({});

    accountProfile.department = this.selectionDep.selected[0];
    accountProfile.email = this.f.email.value;
    accountProfile.validEmail = true;
    accountProfile.firstName = _.startCase(_.toLower(this.f.firstName.value));
    accountProfile.lastName = _.startCase(_.toLower(this.f.lastName.value));
    accountProfile.phone = this.f.phone.value;
    accountProfile.updateMigrationStatus = true;

    if (this.isSedgwickFlow) {
      accountProfile.department = this.selectionDep.selected[0];
      accountProfile.userLevel = this.userLevel;
    } else {
      //accountProfile.updateLoginId = true;
    }

    if (this.userLevel != UserLevel.LEARNER) {
      accountProfile.departments = this.newlyAddedOrgDep.selected;
    }

    if (this.sso) {
      accountProfile.password = "temp/123";
    } else {
      accountProfile.password = this.passwordForm.controls.password.value;

    }
    accountProfile.profileId = this.profileId;
    accountProfile.userId = this.userId;
    if (this.manager) {
      accountProfile.managerId = this.manager.profileId;
    }

    if (this.company) {
      accountProfile.companyId = this.company.id;
    } else {
      accountProfile.companyId = this.currentUser.companyId;
    }
    accountProfile.companyDomain = this.handalizedOrganization;
    accountProfile.companyDomainHandlized = this.handalizedOrganization;
    accountProfile.companyName = this.organizationForm.controls.organization.value;
    accountProfile.companyWebsite = this.handalizedOrganization;

    if (this.userAddedLocations && this.userAddedLocations.length > 0) {
      this.selection.selected.forEach(location => {
        this.userAddedLocations.forEach(userLocation => {
          if (userLocation.id == location) {
            userLocation.selected = true;
          }
          userLocation.id = -1;
        })
      })

      accountProfile.userAddedLocations = this.userAddedLocations;
    } else {
      if (this.selection.selected && this.selection.selected.length > 0) {
        accountProfile.location = this.selection.selected[0];
      }
    }


    var managerEmails = [];
    this.managers.controls.forEach(c => {
      if (c.value.email != "") {
        managerEmails.push(c.value.email);
      }
    })
    accountProfile.managerEmails = managerEmails;


    this._userService.updateAccountProfileMigration(accountProfile).subscribe(response => {
      this._progressBarService.hide();
      if (response) {
        if (this.sso) {

          if (uploadPicture) {
            this.uploadPicture(this.profileId);
          } else {
            this.done();
          }

        } else {
          this.signIn(accountProfile.email, accountProfile.password, this.profileId);
        }
      }
    });;
  }

  done() {

    if (this.sso) {
      this.authenticationService.ssoLogin(this.profileId).subscribe(res => {
        if (res) {
          this.router.navigate(['/home']);
        }
      });
    } else {
      var username = this.f.email.valid ? this.f.email.value : this.f.phone.value;

      this.signIn(username, this.passwordForm.controls.passwordConfirm.value, this.profileId);
    }


  }

  checkUsernameAlreadyExist() {
    this.show = false;
    this._progressBarService.show();
    /* this._registrationService.checkIfUsernameAlreadyExist(this.f.username.value).subscribe(res => {
        this._progressBarService.hide();
        this.show = true;
        if (res) {
            this.alreadyExistUser = true;
        } else {
            this.alreadyExistUser = false;
        }
        this.checkIfValidInformation();
    }) */
  }

  checkEmailAlreadyExist() {
    this.alreadyExistEmail = false;
    this.showEmail = false;
    this._progressBarService.show();
    this._registrationService.checkIfUserAlreadyExist(this.f.email.value).subscribe(res => {
      this._progressBarService.hide();
      this.showEmail = true;
      if (res) {
        this.alreadyExistEmail = true;
      } else {
        this.alreadyExistEmail = false;
      }
      this.checkIfValidInformation();

    })
  }

  checkIfPhoneAlreadyExist() {
    this.showPhone = false;
    this._progressBarService.show();
    this._registrationService.checkIfUserAlreadyExist(this.f.phone.value).subscribe(res => {
      this._progressBarService.hide();
      this.showPhone = true;
      if (res) {
        this.alreadyExistPhone = true;
      } else {
        this.alreadyExistPhone = false;
      }

      this.checkIfValidInformation();
    })
  }

  checkIfValidInformation() {
    this.isValidUserInformation = true;
    if ((this.f.email.value && this.f.email.invalid) || (this.f.phone.value && this.f.phone.invalid) || this.alreadyExistEmail || this.alreadyExistPhone) {
      this.isValidUserInformation = false;
    }
  }

  cancelRegistration() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['/authentication/login']);
  }


  createAddManger(): FormGroup {
    return this._formBuilder.group({
      email: ['',
        {
          validators: [Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/), ValidateEmail.createValidator(this._registrationService)],
          updateOn: 'blur',
        }
      ],

    });
  }



  addManger(): void {
    this.managers.push(this.createAddManger());
  }

  setManager(manager) {
    this.manager = manager;
    this.setStep(6);
  }

  setOrgLocation(step) {
    var location = {
      id: AbsUtils.generateGUID(),
      name: this.organizationLocationForm.controls.location.value,
      address1: this.organizationLocationForm.controls.address.value,
      address2: this.organizationLocationForm.controls.address1.value,
      city: this.organizationLocationForm.controls.city.value,
      state: this.organizationLocationForm.controls.state.value,
      zip: this.organizationLocationForm.controls.zip.value
    }
    this.organizationLocationForm.reset();

    if (!this.locations) {
      this.locations = [];
    }
    this.locations.push(location);
    this.selection.select(location.id);
    this.userAddedLocations.push(location);
    this.setStep(step);
  }


}

/**
 * Confirm password validator
 *
 * @param {AbstractControl} control
 * @returns {ValidationErrors | null}
 */
export const confirmPasswordValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {

  if (!control.parent || !control) {
    return null;
  }

  const password = control.parent.get('password');
  const passwordConfirm = control.parent.get('passwordConfirm');

  if (!password || !passwordConfirm) {
    return null;
  }

  if (passwordConfirm.value === '') {
    return null;
  }

  if (password.value === passwordConfirm.value) {
    return null;
  }

  return { passwordsNotMatching: true };
};

export class ValidateEmail {
  static createValidator(registerService: RegistrationService) {
    return (control: AbstractControl) => {
      return registerService.checkIfUserAlreadyExist(control.value).subscribe(res => {
        if (res) {
          control.setErrors({
            emailTaken: true
          })
        } else {
          control.setErrors({
            emailTaken: false
          })
        }
      })
    }
  }
}