import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RegistrationService } from '../../authentication/_services/registration.service';
import { AuthenticationService } from '../../authentication/_services';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RegistrationForm } from '../../authentication/register-2/register-2.component';
import { AbsProgressBarComponent } from '@abs/components/progress-bar/progress-bar.component';
import { AbsProgressBarService } from '@abs/components/progress-bar/progress-bar.service';

@Component({
  selector: 'app-user-info-dialog',
  templateUrl: './user-info-dialog.component.html',
  styleUrls: ['./user-info-dialog.component.scss']
})
export class UserInfoDialogComponent implements OnInit {

  form: FormGroup;
  firstName: string;
  lastName: string;



  /**
   * Constructor
   *
   * @param {MatDialogRef<GroupFormComponent>} matDialogRef
   * @param _data
   * @param {FormBuilder} _formBuilder
   */
  constructor(public matDialogRef: MatDialogRef<UserInfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private _formBuilder: FormBuilder,
    private registrationService: RegistrationService,
    public authenticationService: AuthenticationService,
    private snackBar: MatSnackBar,
  private progressBarService: AbsProgressBarService) {

    this.form = this.createAddUserForm();

  }

  ngOnInit() { 
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Create add user form
   *
   * @returns {FormGroup}
   */
  createAddUserForm(): FormGroup {
    return this._formBuilder.group({
      firstName: [''],
      lastName: [''],
      userLevel: ['LEARNER'],
    });
  }

submit(){
  this.createUser();
}


  createUser() {
    this.progressBarService.show();
    var formData = new RegistrationForm();
    console.log("current user", this.authenticationService.currentUserValue);
    formData.managerEmail = this.authenticationService.currentUserValue.email;
    formData.managerPhone = this.authenticationService.currentUserValue.phone;
    formData.companyId = this.authenticationService.currentUserValue.companyId;
    formData.managerProfileId = this.authenticationService.currentUserValue.profileId;
    formData.department = this.authenticationService.currentUserValue.department;
    formData.firstName = this.form.controls.firstName.value;
    formData.lastName = this.form.controls.lastName.value;
    formData.providerId = this.authenticationService.currentUserValue.providerId;

    formData.password = "temp/123";
    this.registrationService.addKioskUser(formData).subscribe(res => {
      this.progressBarService.hide();
      this.matDialogRef.close(res);
    });
  }

}
