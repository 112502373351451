import { Component, OnDestroy, OnInit, ViewEncapsulation, ViewChildren, QueryList } from '@angular/core';
import { Subject, Subscription, timer } from 'rxjs';
import { takeUntil, map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

import { AbsConfigService } from '@abs/services/config.service';
import { AbsSidebarService } from '@abs/components/sidebar/sidebar.service';

import { navigation, learnerNavigation } from 'app/navigation/navigation';
import { AuthenticationService } from 'app/main/authentication/_services';
import { User, UserLevel, Setting } from '../../../main/models/user';
import { ActionBarService } from '../action-bar/action-bar.service';
import { UserService } from '../../../main/service/user.service';
import { AbsProgressBarService } from '@abs/components/progress-bar/progress-bar.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TrainingService } from '../../../main/training/_services/training.service';
import { SiteHelpDialogComponent } from '../../../main/site-help-dialog/site-help-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MatRipple } from '@angular/material/core';
import { ShoppingExperienceDialogComponent } from '../../../main/widgets/shopping-experience-dialog/shopping-experience-dialog.component';
import { StartCourseDialogComponent } from '../../../main/training/my-trainings/start-course-dialog/start-course-dialog.component';
import { Router } from '@angular/router';
import { ClassroomStartCountdownComponent } from '../../../main/training/classroom-start-countdown/classroom-start-countdown.component';

@Component({
  selector: 'toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class ToolbarComponent implements OnInit, OnDestroy {
  @ViewChildren(MatRipple)
  showStartclass = true;
  ripple: QueryList<MatRipple>;
  showFirst: boolean = true;
  showSecond: boolean = false;
  stopAnim: boolean = false;

  showCreateAccount = true;
  remaining: any;
  onScreenHelp: any;
  currentUser: User;
  horizontalNavbar: boolean;
  rightNavbar: boolean;
  hiddenNavbar: boolean;
  languages: any;
  navigation: any;
  selectedLanguage: any;
  userStatusOptions: any[];
  kioskEnabled = false;
  percentage = 100;
  requiredViews = false;
  travelers = false;
  classRoomView:boolean = true;
  isAdmin = true;
  startAnimationFlag = false;
  totalViewsData: any[]=[];
  cartCount = 0;
  currentIndex:number = undefined;
  showTokens = false;
  // Private
  private _unsubscribeAll: Subject<any>;
  disabled: boolean;
  /**
   * Constructor
   *
   * @param {AbsConfigService} _absConfigService
   * @param {AbsSidebarService} _absSidebarService
   * @param {TranslateService} _translateService
   * @param {AuthService} authService
   */
  constructor(
    public authService: AuthenticationService,
    private _absConfigService: AbsConfigService,
    private _absSidebarService: AbsSidebarService,
    private _translateService: TranslateService,
    private _actionBarService: ActionBarService,
    private _trainingService: TrainingService,
    private _userService: UserService,
    private progressBarServoce: AbsProgressBarService,
    private snackBar: MatSnackBar,
    private helpDialog: MatDialog,
    private shoppingDialog: MatDialog,
    private classRoomTimer: MatDialog,
    private router: Router,


  ) {
    this.authService.currentUser.subscribe(value => {
      console.log("value", value)
      this.showTokens = false;
      this.currentUser = value;
      if (this.currentUser && this.currentUser.userLevel != UserLevel.LEARNER) {
        console.log("inside", value)

        this.showTokens = true;
        if (this.currentUser.providerId == 10073 && this.currentUser.signUpType !== 'SSO') {
          this.classRoomView = false;
        } else { 
          this.classRoomView = true;
        }
        if (this.currentUser.providerId == 10073 && this.currentUser.signUpType == 'SSO') {
          this.travelers = true;
        } else {
          this.travelers = false;
          if (this.currentUser.providerId == 10085 || this.currentUser.providerId == 10074) {
            this.showTokens = false;
          }
        }
      }
      if (this.currentUser && this.currentUser.userLevel == UserLevel.LEARNER) {
        this.navigation = learnerNavigation;
        this.isAdmin = false;
      } else {
        this.isAdmin = true;
      }



      if (this.currentUser && this.currentUser.settings) {
        console.log("toggles", this.currentUser.settings);
        let globalHelp = this.getValue(this.currentUser.settings, 'ON_SCREEN_HELP');
        if (globalHelp && globalHelp.length > 0 && globalHelp[0].value == 'true') {
          this.onScreenHelp = true;
        } else if (globalHelp && globalHelp.length > 0 && globalHelp[0].value == 'false') {
          this.onScreenHelp = false;
        }
      } else {
        this.onScreenHelp = true;
      }

      if (this.currentUser) {
        this.refreshViews();
      }


    });

    // Set the defaults
    this.userStatusOptions = [
      {
        'title': 'Online',
        'icon': 'icon-checkbox-marked-circle',
        'color': '#4CAF50'
      },
      {
        'title': 'Away',
        'icon': 'icon-clock',
        'color': '#FFC107'
      },
      {
        'title': 'Do not Disturb',
        'icon': 'icon-minus-circle',
        'color': '#F44336'
      },
      {
        'title': 'Invisible',
        'icon': 'icon-checkbox-blank-circle-outline',
        'color': '#BDBDBD'
      },
      {
        'title': 'Offline',
        'icon': 'icon-checkbox-blank-circle-outline',
        'color': '#616161'
      }
    ];

    this.languages = [
      {
        id: 'en',
        title: 'English',
        flag: 'us'
      },
      {
        id: 'sp',
        title: 'Spanish',
        flag: 'sp'
      }
    ];

    this.navigation = navigation;

    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Subscribe to the config changes
    this._absConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((settings) => {
        this.horizontalNavbar = settings.layout.navbar.position === 'top';
        this.rightNavbar = settings.layout.navbar.position === 'right';
        this.hiddenNavbar = settings.layout.navbar.hidden === true;
        this.kioskEnabled = settings.layout.toolbar.kiosk === true;
        this.showCreateAccount = settings.layout.toolbar.createAccount === true;

        if (this.kioskEnabled) {
          this.showTokens = false;
        }
      });

    this.cardDetails();


    this._actionBarService.onRequireViewsChanged.subscribe(value => {
      this.requiredViews = value;
      this.showTokens = !value;
    })

    // Set the selected language from default languages
    this.selectedLanguage = _.find(this.languages, { 'id': this._translateService.currentLang });
    if (this.currentUser) {
      this._trainingService.getTotalClassroomConsumedViews().then(result => {
        this.remaining = 20 - result;
        this.percentage = 100 - result;
      })
      this.refreshViews();
      // setInterval(() => {
      //   this.refreshViews();
      // }, 15000); 
      this.startAnimationFlag = false;
      this._actionBarService.onHelpClicked.pipe(takeUntil(this._unsubscribeAll)).subscribe(value => {
        this.helpDialog.open(SiteHelpDialogComponent, {
          width: '95vw',
          height: '950vh',
          backdropClass: 'helpBackdrop',
          panelClass: 'helpPanel',
          disableClose: true
        });
      })

     /*  this.timerSubscription = timer(0, 10000).pipe(
        map(() => {
          this._trainingService.checkIfClassroomIn15Minx(15).then(result => {
            if (result > 0) {
              this.timerSubscription.unsubscribe();
              const ref = this.classRoomTimer.open(ClassroomStartCountdownComponent, {
                width: '300px',
                backdropClass: 'helpBackdrop',
                panelClass: 'helpPanel',
                disableClose: true
              });
              ref.afterClosed()
                .subscribe(() => {
                  this.router.navigate(['training/classroom'], { queryParams: { cid: result, name: 'Training' } });
              });
            }
          })
        })
      ).subscribe();  */
    }
   
  }
  cardDetails(){
    this._actionBarService.onCartChanged.subscribe(value => {
      this.cartCount = value;
      this.cardDetails();
      if (this.cartCount > 0) {
        this.triggerRipple();
      }
    })
  }
  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  closeKiosk() {
    this._actionBarService.onKioskCloseModeClick.next("click");
  }

  createUser() {
    this._actionBarService.onCreateUserClicked.next("click");
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle sidebar open
   *
   * @param key
   */
  toggleSidebarOpen(key): void {
    this._absSidebarService.getSidebar(key).toggleOpen();
  }

  onViewsClicked() {
    this._actionBarService.onRequireViewsClicked.next("clicked");
  }


  helpChanged(event) {
    this.saveSettings(event.checked);
    this._actionBarService.onHelpChaned.next(event.checked);
  }

  stepChanged(event) {
    console.log("event", event);
    this._actionBarService.stepChanged.next(event);
  }

  saveSettings(value) {
    let setting = new Setting();
    setting.profileID = this.authService.currentUserValue.profileId;
    setting.name = 'ON_SCREEN_HELP';
    setting.value = value;
    console.log(this.authService.currentUserValue.settings);
    console.log(this.authService.currentUserValue);
    if (this.authService.currentUserValue.settings) {
      let help = this.getValue(this.authService.currentUserValue.settings, 'ON_SCREEN_HELP');
      if (help && help.length > 0) {
        this.updateSetting(setting);
      } else {
        this.insertSetting(setting);
      }
    } else {
      this.insertSetting(setting);
    }
  }


  insertSetting(setting) {
    console.log("setting", setting);
    this.progressBarServoce.show();
    this._userService.insertUserSetting(setting).subscribe(res => {
      this.progressBarServoce.hide();
      this.authService.currentUserValue.settings = res;
      this.authService.currentUserSubject.next(this.authService.currentUserValue);
      localStorage.setItem('currentUser', JSON.stringify(this.authService.currentUserValue));
      this.snackBar.open("Setting has been updated.", '', {
        panelClass: 'green-bg',
        duration: 2000,
        horizontalPosition: 'right',
        verticalPosition: 'top',
        politeness: 'polite',
      });
    })
  }

  updateSetting(setting) {
    this.progressBarServoce.show();

    this._userService.updateUserSetting(setting).subscribe(res => {
      this.progressBarServoce.hide();
      this.authService.currentUserValue.settings = res;
      this.authService.currentUserSubject.next(this.authService.currentUserValue);
      localStorage.setItem('currentUser', JSON.stringify(this.authService.currentUserValue));
      this.snackBar.open("Setting has been updated.", '', {
        panelClass: 'green-bg',
        duration: 2000,
        horizontalPosition: 'right',
        verticalPosition: 'top',
        politeness: 'polite',
      });
    })
  }

  launchShoppingExperience(value) {
    const dialogRef = this.shoppingDialog.open(ShoppingExperienceDialogComponent, {
      width: '95vw',
      height: '950vh',
      backdropClass: 'previewBackdrop',
      panelClass: 'helpPanel',
      disableClose: true,
      data: {STEP_VIEW:value } 
    });
    dialogRef.afterClosed()
      .subscribe(() => {

        this.refreshViews();

      });
  }

  helpClicked() {
    this._actionBarService.onHelpClicked.next("clicked");
  }


  refreshViews() {
    console.log("currentuser refresh");
    this._trainingService.getTotalClassroomConsumedViews().then(result => {
      this.remaining = 20 - result;
      this.percentage = 100 - result;
    })

    this._trainingService.getTotalViewsData(this.currentUser.profileId).then(result => {
      console.log(result);
      if(this.travelers){
        this.totalViewsData = result.filter(view => view['viewName'] !== 'Classroom Views:');
      } else {
        this.totalViewsData = result;
      }
      this.totalViewsData.forEach(obj => {
        if(obj['viewName']=="Video Uploads:" || obj['viewName']=="File Uploads:"){
          obj['viewType'] = 'file';
        }else{
          obj['viewType'] = 'view';
        }
      });
      
      if(this.startAnimationFlag === false){
        setTimeout(() => {
          this.currentIndex= 0;
          this.applyDynamicCSS();
          this.startAnimation();
        }, 800);
        this.startAnimationFlag = true;
      }
    });
    
  }

  /**
   * Search
   *
   * @param value
   */
  search(value): void {
    // Do your search here...
    console.log(value);
  }

  /**
   * Set the language
   *
   * @param lang
   */
  setLanguage(lang): void {
    // Set the selected language for the toolbar
    this.selectedLanguage = lang;

    // Use the selected language for translations
    this._translateService.use(lang.id);
  }
  getValue(source, key) {
    return source.filter(s => s.name == key);
  }

  triggerRipple() {
    this.ripple.forEach(_ => _.launch({ centered: true }));
  }

  private applyDynamicCSS() {
    const slideItems = document.querySelectorAll("#slideset3 .mr-8");
    if(slideItems.length == 0){
      return;
    }
    setInterval(() => {
      if(!this.stopAnim){
        this.currentIndex = (this.currentIndex + 1) % slideItems.length;
      }
    }, 5000); 
  }
  startAnimation(): void {
    setInterval(() => {
      this.showFirst = !this.showFirst;
      this.showSecond = !this.showSecond;
    }, 5000); 
  }
}
