import {AbsNavigation, AbsNavigationItem} from '@abs/types';



const streamingSubNavigation: AbsNavigationItem[] = [
  {
   id: 'favorite-playlists',
   title: 'Playlists',
   translate: 'NAV.STREAMING_FAV_PLAYLIST',
   type: 'item',
   url: '/streaming/favorite/playlists',
   iconMdi: 'mdi mdi-play-circle',
   classes: 'primary-50-bg abs-navy-900-fg'
 },
 /*{
  id: 'my-content',
  title: 'My Content',
  translate: 'NAV.CONTENT_MY_CONTENT',
  type: 'item',
  url: 'my-content',
  iconMdi: 'mdi mdi-video-outline',
  classes: 'primary-50-bg abs-navy-900-fg'
},
 {
   id: 'supplementals',
   title: 'Supplementals',
   translate: 'NAV.APPLICATIONS',
   type: 'item',
   url: 'supplementals'
 }, */
 {
   id: 'course-catalog',
   title: 'Course Catalog',
   translate: 'NAV.STREAMING_COURSE_CATALOG',
   type: 'item',
   url: '/streaming/courses',
   iconMdi: 'mdi mdi-video-outline',
   classes: 'primary-50-bg abs-navy-900-fg'

 },
 /* {
   id: 'playlist-manager',
   title: 'Playlist Manager',
   translate: 'NAV.APPLICATIONS',
   type: 'item',
   url: 'playlist-manager'
 },  {
   id: 'my-uploads',
   title: 'My Uploads',
   translate: 'NAV.APPLICATIONS',
   type: 'item',
   url: 'my-uploads'
 }*/];

const contentSubNavigation: AbsNavigationItem[] = [
  {
   id: 'content',
   title: 'Course Catalog',
   translate: 'NAV.CONTENT_COURSES',
   type: 'item',
   url: '/content/all',
   iconMdi: 'mdi mdi-video-outline',
   classes: 'primary-50-bg abs-navy-900-fg'
 },
 /*{
  id: 'my-content',
  title: 'My Content',
  translate: 'NAV.CONTENT_MY_CONTENT',
  type: 'item',
  url: 'my-content',
  iconMdi: 'mdi mdi-video-outline',
  classes: 'primary-50-bg abs-navy-900-fg'
},
 {
   id: 'supplementals',
   title: 'Supplementals',
   translate: 'NAV.APPLICATIONS',
   type: 'item',
   url: 'supplementals'
 }, */
 {
   id: 'playlists',
   title: 'Playlist Manager',
   translate: 'NAV.CONTENT_PLAYLIST_MANAGER',
   type: 'item',
   url: '/content/playlists',
   iconMdi: 'mdi mdi-playlist-play',
   classes: 'primary-50-bg abs-navy-900-fg'

 },
 /* {
   id: 'playlist-manager',
   title: 'Playlist Manager',
   translate: 'NAV.APPLICATIONS',
   type: 'item',
   url: 'playlist-manager'
 },  {
   id: 'my-uploads',
   title: 'My Uploads',
   translate: 'NAV.APPLICATIONS',
   type: 'item',
   url: 'my-uploads'
 }*/];

const adminUserNavigation: AbsNavigationItem[]  = [
  {
    id: 'admin',
    title: 'User Management',
    translate: 'NAV.USERS_USER_MANAGEMENT',
    type: 'item',
    url: '/user/admin',
    classes: 'primary-50-bg abs-navy-900-fg',
    iconMdi: 'mdi mdi-account-tie-outline'
  },
  {
    id: 'groups',
    title: 'Group Management',
    translate: 'NAV.USERS_GROUP_MANAGEMENT',
    type: 'item',
    url: '/user/groups',
    classes: 'primary-50-bg abs-navy-900-fg',
    iconMdi: 'mdi mdi-account-group-outline'

    
  },  /*  {
    id: 'groups-setting',
    title: 'Access Management',
    translate: 'NAV.USERS_ACCESS_MANAGEMENT',
    type: 'item',
    url: '/user/setting'
  }  */ ];

  const reportsSubNavigation: AbsNavigation[]  = [
    {
      id: 'reports',
      title: 'Reports',
      translate: 'NAV.REPORTS_REPORTS',
      type: 'item',
      url: '/report/reports',
      classes: 'primary-50-bg abs-navy-900-fg',
      iconMdi: 'mdi mdi-account-group-outline'

    }/* ,
    {
      id: 'savedReports',
      title: 'Saved Reports',
      translate: 'NAV.APPLICATIONS',
      type: 'item',
      url: 'saved-reports'
    }, */
  ];

  const accountSubNavigation: AbsNavigation[] = [
    {
      id: 'profile',
      title: 'Profile',
      translate: 'NAV.PROFILE',
      type: 'item',
      url: '/account/profile',
      exactMatch: true,
      classes: 'primary-50-bg abs-navy-900-fg',
      iconMdi: 'mdi mdi-account-outline'

    },
    /* {
      id: 'password',
      title: 'Password',
      translate: 'NAV.PASSWORD',
      type: 'item',
      url: 'password',
      exactMatch: true
    }, */
    {
      id: 'notifications',
      title: 'Notification Settings',
      translate: 'NAV.NOTIFICATION_SETTINGS',
      type: 'item',
      url: '/account/notifications',
      exactMatch: true,
      classes: 'primary-50-bg abs-navy-900-fg',
      iconMdi: 'mdi mdi-bell-outline'

    } ,
    {
      id: 'settings',
      title: 'Settings',
      translate: 'NAV.SETTINGS',
      type: 'item',
      url: '/account/settings',
      exactMatch: true,
      classes: 'primary-50-bg abs-navy-900-fg',
      iconMdi: 'mdi mdi-account-cog-outline'

    } /*,
    {
      id: 'devices',
      title: 'Devices',
      translate: 'NAV.DEVICES',
      type: 'item',
      url: 'devices',
    } */];

const trainingNavigationChild: AbsNavigationItem[]  = [

    
  //Will be part of version 2
 /*  {
    id: 'insights',
    title: 'Insights',
    translate: 'NAV.APPLICATIONS',
    type: 'item',
    url: 'insights'
  },  */
  {
    id: 'assign',
    title: 'Assign Training',
    translate: 'NAV.TRAINING_ASSIGN',
    type: 'item',
    url: '/training/assign',
    classes: 'primary-50-bg abs-navy-900-fg',
    iconMdi: 'mdi mdi-account-arrow-right-outline'

  },
  {
    id: 'my-calendar',
    title: 'Training Agenda',
    translate: 'NAV.TRAINING_CALENDAR',
    type: 'item',
    url: '/training/my-calendar',
    classes: 'primary-50-bg abs-navy-900-fg',
    iconMdi : 'mdi mdi-teach'

  },
  /*
    Will be part of version 2 
  {
    id: 'calendar',
    title: 'Calendar',
    translate: 'NAV.APPLICATIONS',
    type: 'item',
    url: 'calendar'
  }, 
  {
    id: 'scheduled',
    title: 'Schedule',
    translate: 'NAV.APPLICATIONS',
    type: 'item',
    url: 'scheduled'
  },*/
  {
    id: 'my-trainings',
    title: 'Training to Complete',
    translate: 'NAV.TRAINING_ASSIGNED_TRAINING',
    type: 'item',
    url: '/training/my-trainings',
    classes: 'primary-50-bg abs-navy-900-fg',
    iconMdi: 'mdi mdi-teach'
  },
 
  /* {
    id: 'classroom',
    title: 'Classroom',
    translate: 'NAV.TRAINING_CLASSROOM',
    type: 'item',
    url: '/training/classroom',
    classes: 'grey-300-bg abs-navy-900-fg'
  }, */
  {
    id: 'locations',
    title: 'Location Management',
    translate: 'NAV.TRAINING_LOCATION_MANAGEMENT',
    type: 'item',
    url: '/training/locations',
    classes: 'primary-50-bg abs-navy-900-fg',
    iconMdi: 'mdi mdi-map-marker-radius-outline'

  }];

export const navigation: AbsNavigation[] = [
  /*{
    id       : 'courses',
    title    : 'Courses',
    translate: 'NAV.APPLICATIONS',
    type     : 'group',
    url: 'courses/',
    children : [
      {
        id       : 'favorites',
        title    : 'Favorites',
        translate: 'NAV.DASHBOARDS',
        type     : 'item',
        url: 'courses/favorites'
      },
      {
        id       : 'video-course',
        title    : 'Video Course',
        translate: 'NAV.ACADEMY',
        type     : 'item',
        url      : 'courses/video-course'
      },
      {
        id       : 'additional-resourses',
        title    : 'Additional Resourses',
        translate: 'NAV.SCRUMBOARD',
        type     : 'item',
        url      : 'courses/additional-resourses'
      },
      {
        id       : 'catalog',
        title    : 'Catalog',
        translate: 'NAV.SCRUMBOARD',
        type     : 'item',
        url      : 'courses/catalog'
      }
    ]
  },*/
  {
    id       : 'home',
    title    : 'Home',
    translate: 'NAV.CONTENT',
    type     : 'group',
    iconMdi     : 'mdi mdi-home-outline',
    url: '/home',
    rUrl: '/home'

  },
  {
    id       : 'user',
    title    : 'Users',
    translate: 'NAV.USERS',
    type     : 'group',
    iconMdi     : 'mdi mdi-account-group-outline',
    url      : '/user',
    rUrl      : '/user',
    children: adminUserNavigation
  },
  {
    id       : 'content',
    title    : 'Content',
    translate: 'NAV.CONTENT',
    type     : 'group',
    iconMdi     : 'mdi mdi-view-module',
    url: '/content',
    rUrl: '/content',
    children: contentSubNavigation
  },
  {
    id       : 'streaming',
    title    : 'Streaming',
    translate: 'NAV.STREAMING',
    type     : 'group',
    iconMdi     : 'mdi mdi-play-circle',
    url: '/streaming',
    rUrl: '/streaming',
    children: streamingSubNavigation
  },
  
  {
    id       : 'training',
    title    : 'LMS Training',
    translate: 'NAV.TRAINING',
    iconMdi     : 'mdi mdi-teach',
    type     : 'group',
    url: '/training',
    rUrl: '/training',
    children: trainingNavigationChild,
  },
  {
    id       : 'report',
    title    : 'Reporting',
    translate: 'NAV.REPORTING',
    iconMdi     : 'mdi mdi-chart-box-outline',
    type     : 'group',
    url: '/report',
    rUrl: '/report',
    children: reportsSubNavigation
  },
  {
    id       : 'account',
    title    : 'Account',
    translate: 'NAV.ACCOUNT',
    iconMdi     : 'mdi mdi-account-box-outline',
    type     : 'group',
    url: '/account',
    rUrl: '/account',
    children: accountSubNavigation
  }
];


export const learnerNavigation: AbsNavigation[] = [
  /*{
    id       : 'courses',
    title    : 'Courses',
    translate: 'NAV.APPLICATIONS',
    type     : 'group',
    url: 'courses/',
    children : [
      {
        id       : 'favorites',
        title    : 'Favorites',
        translate: 'NAV.DASHBOARDS',
        type     : 'item',
        url: 'courses/favorites'
      },
      {
        id       : 'video-course',
        title    : 'Video Course',
        translate: 'NAV.ACADEMY',
        type     : 'item',
        url      : 'courses/video-course'
      },
      {
        id       : 'additional-resourses',
        title    : 'Additional Resourses',
        translate: 'NAV.SCRUMBOARD',
        type     : 'item',
        url      : 'courses/additional-resourses'
      },
      {
        id       : 'catalog',
        title    : 'Catalog',
        translate: 'NAV.SCRUMBOARD',
        type     : 'item',
        url      : 'courses/catalog'
      }
    ]
  },*/
  {
    id       : 'home',
    title    : 'Home',
    translate: 'NAV.CONTENT',
    type     : 'group',
    iconMdi     : 'mdi mdi-home-outline',
    url: '/home',
    rUrl: '/home'

  },
   {
    id       : 'training',
    title    : 'Training',
    translate: 'NAV.TRAINING',
    iconMdi     : 'mdi mdi-teach',

    type     : 'group',
    url: '/training/my-trainings',
    rUrl: '/training',
    children: [
      {
        id: 'my-trainings',
        title: 'Training to Complete',
        translate: 'NAV.TRAINING_ASSIGNED_TRAINING',
        type: 'item',
        url: '/training/my-trainings',
        classes: 'primary-50-bg abs-navy-900-fg',
        iconMdi: 'mdi mdi-teach'
      }
    ]
  },
  {
    id       : 'account',
    title    : 'Account',
    translate: 'NAV.ACCOUNT',
    iconMdi     : 'mdi mdi-account-box-outline',
    type     : 'group',
    url: '/account',
    rUrl: '/account',
    children: [
      {
        id: 'profile',
        title: 'Profile',
        translate: 'NAV.PROFILE',
        type: 'item',
        url: '/account/profile',
        exactMatch: true,
        classes: 'primary-50-bg abs-navy-900-fg',
        iconMdi: 'mdi mdi-account-outline'
      },
      /* {
        id: 'password',
        title: 'Password',
        translate: 'NAV.PASSWORD',
        type: 'item',
        url: 'password',
        exactMatch: true
      }, */
      {
        id: 'settings',
        title: 'Settings',
        translate: 'NAV.SETTINGS',
        type: 'item',
        url: '/account/settings',
        exactMatch: true,
        classes: 'primary-50-bg abs-navy-900-fg',
        iconMdi: 'mdi mdi-account-cog-outline'
      }, /*{
        id: 'training-defaults',
        title: 'Training Defaults',
        translate: 'NAV.SETTINGS',
        type: 'item',
        url: 'training-defaults',
        exactMatch: true
      } ,
      {
        id: 'devices',
        title: 'Devices',
        translate: 'NAV.DEVICES',
        type: 'item',
        url: 'devices',
      } */]
  }
];

export const children: AbsNavigationItem[] = [];

