import { NgModule, ModuleWithProviders } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes, UrlSegment } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { InMemoryWebApiModule } from 'angular-in-memory-web-api';
import { TranslateModule } from '@ngx-translate/core';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { AbsModule } from '@abs/abs.module';
import { AbsSharedModule } from '@abs/shared.module';
import { AbsProgressBarModule, AbsSidebarModule, AbsThemeOptionsModule } from '@abs/components';

import { absConfig } from 'app/abs-config';

import { FakeDbService } from 'app/fake-db/fake-db.service';
import { AppComponent } from 'app/app.component';
import { AppStoreModule } from 'app/store/store.module';

import { LayoutModule } from 'app/layout/layout.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { AuthGuard, JwtInterceptor } from './main/authentication/_helpers';
import { MatGoogleMapsAutocompleteModule } from '@angular-material-extensions/google-maps-autocomplete';
import { AgmCoreModule } from '@agm/core';
import { TourMatMenuModule } from 'ngx-tour-md-menu';
import { AutoTrainingModule } from './main/auto-training/auto-training.module';
import { AutoTrainingComponent } from './main/auto-training/auto-training.component';
import { Location, LocationStrategy, PathLocationStrategy, HashLocationStrategy, APP_BASE_HREF } from '@angular/common';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ImagePreloadDirective } from '@abs/directives/image-preload.directive';
import { UserLevel } from './main/models/user';
import { PortalModule } from '@angular/cdk/portal';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { AbsSnackBarModule } from '@abs/components/abs-snack-bar/abs-snack-bar.module';
import { AvatarModule } from 'ngx-avatar';
import { UserAvatarModule } from '@abs/components/user-avatar/user-avatar.module';
import { MatCardModule } from '@angular/material/card';
import { SsoComponent } from './main/authentication/sso/sso.component';
import { AuthenticationModule } from './main/authentication/authentication.module';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { RsvpModule } from './main/rsvp/rsvp.module';


export function jspFiles(url: UrlSegment[]) {
  return url.length === 1 && url[0].path.indexOf('go.jsp')!=-1 ? ({consumed: url}) : null;
}


export function rsvp(url: UrlSegment[]) {
  console.log("url",url)
  return url.length === 1 && url[0].path.indexOf('rsvp.html')!=-1 ? ({consumed: url}) : null;
}

export function productPage(url: UrlSegment[]) {
  return url.length === 1 && url[0].path.indexOf('product.jsp')!=-1 ? ({consumed: url}) : null;
}

export function searchPage(url: UrlSegment[]) {
  return url.length === 1 && url[0].path.indexOf('zSearch.jsp')!=-1 ? ({consumed: url}) : null;
}

export function badgesPage(url: UrlSegment[]) {
  
  return url.length === 1 && url[0].path.indexOf('badges.jsp')!=-1 ? ({consumed: url}) : null;
}
export function launchPad(url: UrlSegment[]) {
  return url.length === 1 && url[0].path.indexOf('xLaunchPad')!=-1 ? ({consumed: url}) : null;
}


const appRoutes: Routes = [
  {
    path: 'user',
    loadChildren: () => import('app/main/user/user.module').then(m => m.UserModule),
    canActivate: [AuthGuard],
    data: { roles: [UserLevel.TRAINER,UserLevel.ADMIN,UserLevel.MANAGER] }
  },
  /* {
    path: 'courses',
    loadChildren: () => import('app/main/courses/courses.module').then(m => m.CoursesModule),
  }, */
 
  {
    path: 'report',
    loadChildren: () => import('app/main/report/report.module').then(m => m.ReportModule),
    canActivate: [AuthGuard],
    data: { roles: [UserLevel.TRAINER,UserLevel.ADMIN,UserLevel.MANAGER] }
  },
  {
    path: 'account',
    loadChildren: () => import('app/main/account/account.module').then(m => m.AccountModule),
  },
  {
    path: 'content',
    loadChildren: () => import('app/main/content/content.module').then(m => m.ContentModule),
    canActivate: [AuthGuard],
    data: { roles: [UserLevel.TRAINER,UserLevel.ADMIN,UserLevel.MANAGER] }
  },
  {
    path: 'streaming',
    loadChildren: () => import('app/main/streaming/streaming.module').then(m => m.StreamingModule),
    canActivate: [AuthGuard],
    data: { roles: [UserLevel.TRAINER,UserLevel.ADMIN,UserLevel.MANAGER] }
  },
  {
    path: 'home',
    loadChildren: () => import('app/main/landing-page/landing-page.module').then(m => m.LandingPageModule),
  },
  {
    path: 'authentication',
    loadChildren: () => import('app/main/authentication/authentication.module').then(m => m.AuthenticationModule)
  },
  {
    path: 'emails',
    loadChildren: () => import('app/main/emails/emails.module').then(m => m.EmailsModule),
    canActivate: [AuthGuard],
    data: { roles: [UserLevel.ADMIN] }
  },
  {
    path: 'training',
    loadChildren: () => import('app/main/training/training.module').then(m => m.TrainingModule),
    canActivate: [AuthGuard],
    data: { roles: [UserLevel.TRAINER,UserLevel.ADMIN,UserLevel.MANAGER,UserLevel.LEARNER] }
  },
  {
    path: 'shopping',
    loadChildren: () => import('app/main/public-checkout/public-checkout.module').then(m => m.PublicCheckoutModule),
  },
  {
    path: 'dashboard',
    loadChildren: () => import('app/main/project/project.module').then(m => m.ProjectDashboardModule),
  },
  {
    path: 'ui',
    loadChildren: () => import('app/main/colors/colors.module').then(m => m.UIColorsModule)
  },
  {
    path: 'rsvp',
    loadChildren: () => import('app/main/rsvp/rsvp.module').then(m => m.RsvpModule)
  },
  {
    matcher: jspFiles,
    component: SsoComponent,
  },
  {
    matcher: searchPage,
    component: SsoComponent,
  },
  {
    matcher: productPage,
    component: SsoComponent,
  },
  {
    matcher: badgesPage,
    component: SsoComponent,
  },
  {
    matcher: launchPad,
    component: SsoComponent,
  },
  {
    matcher: rsvp,
    component: SsoComponent,
  },
  {
    path: '**',
    redirectTo: '/authentication/login',
  }
];

export const routing: ModuleWithProviders = RouterModule.forRoot(appRoutes,{useHash: false});

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    routing,    
    HttpClientModule,
    FormsModule,
    TranslateModule.forRoot(),
    MatMomentDateModule,
    MatButtonModule,
    MatIconModule,
    InMemoryWebApiModule.forRoot(FakeDbService, {
      delay: 0,
      passThruUnknownUrl: true
    }),
    AbsModule.forRoot(absConfig),
    AbsSharedModule,
    AbsProgressBarModule,
    AbsSidebarModule,
    AbsThemeOptionsModule,
    InfiniteScrollModule,
    // App modules
    LayoutModule,
    AppStoreModule,
    //ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    MatGoogleMapsAutocompleteModule,
    AgmCoreModule.forRoot(),
    TourMatMenuModule.forRoot(),
    AutoTrainingModule,
    NgxSkeletonLoaderModule,
    MatSnackBarModule,
    AbsSnackBarModule,
    UserAvatarModule,
    MatCardModule,
    AuthenticationModule
  ],
  bootstrap: [
    AppComponent,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true,
    }, { provide: LocationStrategy, useClass: PathLocationStrategy },
    {provide: APP_BASE_HREF, useValue: '/itrain'}
  ]

})
export class AppModule {
}
