import {NgModule} from '@angular/core';
import { MatInputModule } from '@angular/material/input';
import { MatSidenavModule } from '@angular/material/sidenav';

import {AbsSidebarModule, AbsThemeOptionsModule} from '@abs/components';
import {AbsSharedModule} from '@abs/shared.module';

import {ContentModule} from 'app/layout/components/content/content.module';
import {FooterModule} from 'app/layout/components/footer/footer.module';
import {NavbarModule} from 'app/layout/components/navbar/navbar.module';
import {QuickPanelModule} from 'app/layout/components/quick-panel/quick-panel.module';
import {ToolbarModule} from 'app/layout/components/toolbar/toolbar.module';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';


import {HorizontalLayout1Component} from 'app/layout/horizontal/layout-1/layout-1.component';
import { ActionBarModule } from '../../components/action-bar/action-bar.module';

@NgModule({
  declarations: [
    HorizontalLayout1Component
  ],
  imports: [
    MatSidenavModule,

    AbsSharedModule,
    AbsSidebarModule,
    AbsThemeOptionsModule,

    ContentModule,
    FooterModule,
    NavbarModule,
    QuickPanelModule,
    ToolbarModule,
    MatButtonModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    ActionBarModule
  ],
  exports: [
    HorizontalLayout1Component
  ]
})
export class HorizontalLayout1Module {
}
