import { Component, OnInit, Inject } from '@angular/core';

import { absAnimations } from '@abs/animations';
import { IndexTiles } from './tile.model';
import { AbsConfigService } from '@abs/services/config.service';
import { LocationStrategy, PathLocationStrategy, PlatformLocation, DOCUMENT } from '@angular/common';
import { AuthenticationService, CompnayService } from '../authentication/_services';
import { UserLevel, Setting } from '../models/user';
import { Platform } from '@angular/cdk/platform';
import { ActionBarService } from '../../layout/components/action-bar/action-bar.service';
import { AbsConfig } from '@abs/types';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { MatDialog } from '@angular/material/dialog';
import { KioskQuizToggleDialogComponent } from './kiosk-quiz-toggle-dialog/kiosk-quiz-toggle-dialog.component';
import { AdminCredentialsDialogComponent } from './admin-credentials-dialog/admin-credentials-dialog.component';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TriggerDialogComponent } from './trigger-dialog/trigger-dialog.component';
import { TourService } from 'ngx-tour-md-menu';
import { MatTableDataSource } from '@angular/material/table';
import { AbsProgressBarService } from '@abs/components/progress-bar/progress-bar.service';
import { UserService } from '../service/user.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ContentService } from '../content/content.service';
import { AbsConfirmDialogComponent } from '@abs/components/confirm-dialog/confirm-dialog.component';
import { SecondaryPasswordDialogComponent } from './secondary-password-dialog/secondary-password-dialog.component';
import { SiteHelpDialogComponent } from '../site-help-dialog/site-help-dialog.component';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss'],
  animations: absAnimations,

})
export class LandingPageComponent implements OnInit {
  providerHash: any;
  confirmDialogRef: any;
  date: Date;
  coursesIsLoading = false;
  usersIsLoading = false;
  additionalIsLoading = false;
  kioskModeEnabled = false;
  showKioskModeButton = false;
  showHelp = false;
  learner = false;
  coursesDataSource: MatTableDataSource<any>;
  coursesDisplayedColumns = ['avatar', 'name', 'rating'];

  usersDataSource: MatTableDataSource<any>;
  usersDisplayedColumns = ['avatar', 'name'];

  latestAdditionDataSource: MatTableDataSource<any>;
  latestDisplayedColumns = ['avatar', 'name', 'rating'];

  absConfigWithToolBarKiosk: AbsConfig = {
    colorTheme: 'theme-default',
    customScrollbars: true,
    layout: {
      style: 'horizontal-layout-1',
      width: 'fullwidth',
      navbar: {
        primaryBackground: 'abs-navy-900',
        secondaryBackground: 'abs-navy-700',
        folded: false,
        hidden: true,
        position: 'left',
        variant: 'vertical-style-1'
      },
      toolbar: {
        background: 'abs-white-500',
        customBackgroundColor: true,
        hidden: false,
        kiosk: true,
        position: 'above'
      },
      footer: {
        customBackgroundColor: true,
        background: 'abs-navy-900',
        hidden: false,
        position: 'below-fixed'
      },
      sidepanel: {
        hidden: true,
        position: 'right'
      }
    }
  };

  absConfigWithOutToolBar: AbsConfig = {
    colorTheme: 'theme-default',
    customScrollbars: true,
    layout: {
      style: 'horizontal-layout-1',
      width: 'fullwidth',
      navbar: {
        primaryBackground: 'abs-navy-900',
        secondaryBackground: 'abs-navy-700',
        folded: false,
        hidden: true,
        position: 'left',
        variant: 'vertical-style-1'
      },
      toolbar: {
        background: 'abs-white-500',
        customBackgroundColor: true,
        hidden: true,
        position: 'above'
      },
      footer: {
        customBackgroundColor: true,
        background: 'abs-navy-900',
        hidden: false,
        position: 'below-fixed'
      },
      sidepanel: {
        hidden: true,
        position: 'right'
      }
    }
  };

  quizzingEnabled = false;
  defaultTimeOutInMins = 1;
  timedOutCloser;
  enteredButton = false;
  isMatMenuOpen = false;
  isMatMenu2Open = false;
  prevButtonTrigger;
  kioskMode = false;
  showKioskPlayer = false;
  profileId: any;
  selectedPlaylistVidoes = [];
  


  manageBoards = [
    {
      'id': 'courses',
      'name': 'Courses and Content',
      'uri': '/content/all',
      'color': 'abs-navy-900',
      'subMenuColor': 'primary-50-bg abs-white-fg',
      'icon': 'mdi mdi-view-module',
      'subMenus': [
        {
          'name': 'Course Catalog',
          'uri': '/content/all',
          'color': 'primary-200-bg',
          'icon': 'mdi mdi-video-outline'
        },
        {
          'name': 'Playlist Manager',
          'uri': '/content/playlists',
          'color': 'primary-200-bg',
          'icon': 'mdi mdi-playlist-play'
        }/* ,
        {
          'name': 'Playlist Manager',
          'uri': '/content/playlist-manager',
          'color': 'lime-300-bg',
          'icon':'mdi mdi-playlist-check'    
        } ,
        {
          'name': 'My Uploads',
          'uri': '/content/my-uploads',
          'color': 'primary-200-bg',
          'icon':'mdi mdi-file-document-multiple-outline'    
        }*/
      ]
    },
    {

      'id': 'users',
      'name': 'Users',
      'uri': '/user/admin',
      'color': 'abs-navy-900',
      'subMenuColor': 'primary-50-bg',
      'icon': 'mdi mdi-account-group-outline',
      'subMenus': [
        {
          'name': 'User Management',
          'uri': '/user/admin',
          'color': 'primary-50-bg',
          'icon': 'mdi mdi-account-tie-outline'
        },
        {
          'name': 'Group Management',
          'uri': '/user/groups',
          'color': 'primary-50-bg',
          'icon': 'mdi mdi-account-group-outline'
        }/* ,
        {
          'name': 'Settings',
          'uri': '/user/setting',
          'color': 'primary-200-bg',
          'icon':'mdi mdi-account-settings-outline'    
        } */
      ]
    }
    
  ];

  trainBoards = [
    
    {

      'id': 'streaming',
      'name': 'Streaming',
      'uri': '/streaming',
      'color': 'abs-navy-900',
      'subMenuColor': 'primary-50-bg',
      'icon': 'mdi mdi-play-circle',
      'subMenus': [
        {
          'name': 'Favorite Playlists',
          'uri': '/streaming/favorite/playlists',
          'color': 'primary-50-bg',
          'icon': 'mdi mdi-play-circle'
        },
        {
          'name': 'Courses',
          'uri': '/streaming/courses',
          'color': 'primary-50-bg',
          'icon': 'mdi mdi-video-outline'
        }/* ,
        {
          'name': 'Settings',
          'uri': '/user/setting',
          'color': 'primary-200-bg',
          'icon':'mdi mdi-account-settings-outline'    
        } */
      ]
    },

    {
      'id': 'training',
      'name': 'LMS Training',
      'uri': '/training/assign',
      'color': 'abs-navy-900',
      'subMenuColor': 'primary-50-bg',

      'icon': 'mdi mdi-teach',
      'subMenus': [
        {
          'name': 'Assign Training',
          'uri': '/training/assign',
          'color': 'primary-200-bg',
          'icon': 'mdi mdi-account-arrow-right-outline'
        },
        {
          'name': 'Training Agenda',
          'uri': '/training/my-calendar',
          'color': 'primary-200-bg',
          'icon': 'mdi mdi-teach'
        }/* ,
        {
          'name': 'Schedule',
          'uri': '/training/scheduled',
          'color': 'primary-200-bg',
          'icon':'mdi mdi-calendar-clock'    
        } */,

        {
          'name': 'Training to Complete',
          'uri': '/training/my-trainings',
          'color': 'primary-200-bg',
          'icon': 'mdi mdi-teach'
        },
        {
          'name': 'Location Management',
          'uri': '/training/locations',
          'color': 'primary-200-bg',
          'icon': 'mdi mdi-map-marker-radius-outline'
        }
      ]

    }
  ];


  analyzeBoards = [
    
    {
      'id': 'reporting',
      'name': 'Reporting',
      'uri': '/report/reports',
      'color': 'abs-navy-900',
      'subMenuColor': 'primary-50-bg',
      'icon': 'mdi mdi-chart-box-outline',
      'subMenus': [
        {
          'name': 'Reports',
          'uri': '/report/reports',
          'color': 'primary-200-bg',
          'icon': 'mdi mdi-chart-box-outline'
        }/* ,
        {
          'name': 'Saved Reports',
          'uri': '/report/reports',
          'color': 'primary-200-bg',
          'icon':'mdi mdi-content-save-outline'    
        } */
      ]

    },
    {
      'id': 'profile',
      'name': 'Profile and Settings',
      'uri': '/account/profile',
      'color': 'abs-navy-900',
      'subMenuColor': 'primary-50-bg',
      'icon': 'mdi mdi-account-box-outline',
      'subMenus': [
        {
          'name': 'Account Profile',
          'uri': '/account/profile',
          'color': 'primary-200-bg',
          'icon': 'mdi mdi-account-outline'
        },
        {
          'name': 'Notifications',
          'uri': '/account/notifications',
          'color': 'primary-200-bg',
          'icon': 'mdi mdi-bell-outline'
        },
        {
          'name': 'Settings',
          'uri': '/account/settings',
          'color': 'primary-200-bg',
          'icon': 'mdi mdi-account-cog-outline'
        },
      ]

    },
    /* {
      'id': 'menu6',
      'name': 'Email Templates',
      'uri': '/emails',
      'color': 'abs-navy-900',
      'subMenuColor': 'hidden',
      'icon':'mdi mdi-email-outline',
      'subMenus':[]
    } */
    /* {
      'id': 'menu6',
      'name': 'Colors',
      'uri': '/ui/colors',
      'color': 'abs-navy-900',
      'subMenuColor': 'primary-50-bg',
      'icon': 'mdi mdi-palette',
      'subMenus': [
      ]
    } */
  ];


  leanerBoards = [
    {
      'id': 'training',
      'name': 'Training',
      'uri': '/training/my-trainings',
      'color': 'abs-navy-900',
      'subMenuColor': 'primary-50-bg',
      'icon': 'mdi mdi-teach',
      'subMenus': [
        {
          'name': 'Training to Complete',
          'uri': '/training/my-trainings',
          'color': 'primary-200-bg',
          'icon': 'mdi mdi-teach'
        }
      ]

    },
    {
      'id': 'profile',
      'name': 'Profile and Settings',
      'uri': '/account/profile',
      'color': 'abs-navy-900',
      'subMenuColor': 'primary-50-bg',
      'icon': 'mdi mdi-account-box-outline',
      'subMenus': [
        {
          'name': 'Profile',
          'uri': '/account/profile',
          'color': 'primary-200-bg',
          'icon': 'mdi mdi-account-outline'
        },
        {
          'name': 'Settings',
          'uri': '/account/settings',
          'color': 'primary-200-bg',
          'icon': 'mdi mdi-account-settings-outline'
        }
      ]
    }
  ];
  private _unsubscribeAll: Subject<any>;



  constructor(private _absConfigService: AbsConfigService,
    private autService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
    private _userService: UserService,
    private progressBarServoce: AbsProgressBarService,
    private snackBar: MatSnackBar,
    private actionBarService: ActionBarService,
    private _platform: Platform,
    private _companyService: CompnayService,
    private _contentService: ContentService,
    private _quizToggleDialog: MatDialog,
    private _secondaryPassDialog: MatDialog,
    private idle: Idle,
    private allDialogRef: MatDialog,
    private dialog: MatDialog,
    private secPassDialog: MatDialog,
    private helpDialog: MatDialog,

    private confirmDialog: MatDialog,

    private adminCredentialDialog: MatDialog,
    private tourService: TourService

  ) {
    // Configure the layout
    this._unsubscribeAll = new Subject();
    this.date = new Date();
    this._absConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },
        toolbar: {
          hidden: false
        },
        footer: {
          hidden: true
        },
        sidepanel: {
          hidden: true
        }
      }
    };

    if (this.showKioskModeButton) {
      this.tourService.initialize(
        [{
          stepId: 'onscreenhelp',
          anchorId: 'onscreenhelp',
          content: '<ul><li>On Screen Help can be turned on and off by toggling this switch. </li><li>Clicking on any of the highlighted areas will provide help for that element.</li><li>Clicking the Next button will step through the help in sequence.</li></ul>',
          title: 'On Screen Help Toggle',
          enableBackdrop: true,
          prevBtnTitle: 'Back',
          nextBtnTitle: 'Next',

        },
        {
          stepId: 'courses',
          anchorId: 'courses',
          content: '<ul><li>Search through a vast catalog of courses for high quality pre loaded training content.</li><li>Add your own content for training.</li><li>Easily manage lists of selected content with the playlist Manager.</li></ul>',
          title: 'Courses and Content',
          enableBackdrop: true,
          prevBtnTitle: 'Back',
          nextBtnTitle: 'Next',

        },
        {
          stepId: 'users',
          anchorId: 'users',
          content: '<ul><li>Manage User profiles and settings, including adding users to the system.</li><li>Create and Manage User Groups to simplify assigning and scheduling training.</li></ul>',
          title: 'Users',
          enableBackdrop: true,
          prevBtnTitle: 'Back',
          nextBtnTitle: 'Next',
        },
        {
          stepId: 'training',
          anchorId: 'training',
          content: '<ul><li>Schedule training to be conducted in a Class or Assign training to individuals for independent learning.</li><li>Review the training calendar of past, present and future training activity. </li><li>Manage training locations including available hours, seating capacity and audio/video equipment.</li></ul>',
          title: 'Training',
          enableBackdrop: true,
          prevBtnTitle: 'Back',
          nextBtnTitle: 'Next',

        },
        {
          stepId: 'reporting',

          anchorId: 'reporting',
          content: '<ul><li>Create dynamic reports with instantaneous results.</li><li> Automate customized reports to be sent via email.</li></ul>',
          title: 'Reporting',
          enableBackdrop: true,
          prevBtnTitle: 'Back',
          nextBtnTitle: 'Next',
        },
        {
          stepId: 'profile',
          anchorId: 'profile',
          content: '<ul><li>Manage user information including your avatar, password reset and registered devices. </li><li>Setup your settings for date/time display, colors and defaults values. </li><li>Select from a wide assortment of Alerts and Notifications to be sent via email or SMS.</li></ul>',
          title: 'Profile and Settings',
          enableBackdrop: true,
          prevBtnTitle: 'Back',
          nextBtnTitle: 'Next',
        },
        {
          stepId: 'kioskmode',
          anchorId: 'kioskmode',
          content: '<ul><li>Turn on Kiosk Mode to enable automated training of new hires from this workstation.</li></ul>',
          title: 'Kiosk Mode',
          enableBackdrop: true,
          prevBtnTitle: 'Back',
          nextBtnTitle: 'Next',
        }
        ]
      );
    } else {
      this.tourService.initialize(
        [{
          stepId: 'onscreenhelp',
          anchorId: 'onscreenhelp',
          content: '<ul><li>On Screen Help can be turned on and off by toggling this switch. </li><li>Clicking on any of the highlighted areas will provide help for that element.</li><li>Clicking the Next button will step through the help in sequence.</li></ul>',
          title: 'On Screen Help Toggle',
          enableBackdrop: true,
          prevBtnTitle: 'Back',
          nextBtnTitle: 'Next',

        },
        {
          stepId: 'courses',
          anchorId: 'courses',
          content: '<ul><li>Search through a vast catalog of courses for high quality pre loaded training content.</li><li>Add your own content for training.</li><li>Easily manage lists of selected content with the playlist Manager.</li></ul>',
          title: 'Courses and Content',
          enableBackdrop: true,
          prevBtnTitle: 'Back',
          nextBtnTitle: 'Next',

        },
        {
          stepId: 'users',
          anchorId: 'users',
          content: '<ul><li>Manage User profiles and settings, including adding users to the system.</li><li>Create and Manage User Groups to simplify assigning and scheduling training.</li></ul>',
          title: 'Users',
          enableBackdrop: true,
          prevBtnTitle: 'Back',
          nextBtnTitle: 'Next',
        },
        {
          stepId: 'training',
          anchorId: 'training',
          content: '<ul><li>Schedule training to be conducted in a Class or Assign training to individuals for independent learning.</li><li>Review the training calendar of past, present and future training activity. </li><li>Manage training locations including available hours, seating capacity and audio/video equipment.</li></ul>',
          title: 'Training',
          enableBackdrop: true,
          prevBtnTitle: 'Back',
          nextBtnTitle: 'Next',

        },
        {
          stepId: 'reporting',

          anchorId: 'reporting',
          content: '<ul><li>Create dynamic reports with instantaneous results.</li><li> Automate customized reports to be sent via email.</li></ul>',
          title: 'Reporting',
          enableBackdrop: true,
          prevBtnTitle: 'Back',
          nextBtnTitle: 'Next',
        },
        {
          stepId: 'profile',
          anchorId: 'profile',
          content: '<ul><li>Manage user information including your avatar, password reset and registered devices. </li><li>Setup your settings for date/time display, colors and defaults values. </li><li>Select from a wide assortment of Alerts and Notifications to be sent via email or SMS.</li></ul>',
          title: 'Profile and Settings',
          enableBackdrop: true,
          prevBtnTitle: 'Back',
          nextBtnTitle: 'Next',
        }
        ]
      );
    }



    if (this.autService.currentUserValue.userLevel == UserLevel.LEARNER) {
      this.learner = true;
    } else {
      if (this._platform.ANDROID || this._platform.IOS) {
        this.learner = true;

        //this.boards = this.leanerBoards; // show only two tiles for admin and manager as well in mobile view
      }
    }
  }

  ngOnInit() {

   /*  this.actionBarService.onHelpClicked.pipe(takeUntil(this._unsubscribeAll)).subscribe(value => {
      this.helpDialog.open(SiteHelpDialogComponent, {
        width: '95vw',
        height: '950vh',
        backdropClass: 'helpBackdrop',
        panelClass:'helpPanel',
        disableClose: true,
        data: {
          module: "HOME",
        }
      });
    }) */

    this.tourService.end$.subscribe(result => {
      this.showHelp = false;
    })

    this._contentService.getRecentlyAddedContents(this.autService.currentUserValue.companyId).then(result => {
      this.latestAdditionDataSource = new MatTableDataSource(result);

    })

    this._contentService.getUsedContents(this.autService.currentUserValue.profileId).then(result => {
      this.coursesDataSource = new MatTableDataSource(result);

    })

    this._companyService.getRecentlyLoggedInUsersForCurrentMonth(this.autService.currentUserValue.companyId, this.autService.currentUserValue.profileId).subscribe(res => {
      this.usersDataSource = new MatTableDataSource(res);
    })

    if (this.autService.currentUserValue.settings && this.autService.currentUserValue.settings.length > 0) {
      let globalHelp = this.getValue(this.autService.currentUserValue.settings, 'ON_SCREEN_HELP');
      if (globalHelp && globalHelp.length > 0 && globalHelp[0].value == 'true') {
       // this.showHelp = true;
        //this.tourService.start();
      }
    }

    this.actionBarService.onHelpChaned
      .subscribe(value => {
        console.log(value);
        if (value) {
          this.showHelp = true;
          this.tourService.start();
        } else {
          this.showHelp = false;
          this.tourService.end();
        }
      })


    if (this.autService.currentUserValue && this.autService.currentUserValue.userLevel != UserLevel.LEARNER) {

      const request = { providerId: this.autService.currentUserValue.providerId, companyId: this.autService.currentUserValue.companyId }

      this._companyService.getKioskSettings(request).subscribe(value => {
        if (value) {
          if (value.kiosk) {
            this.providerHash = value.providerHash;
            localStorage.setItem("KIOSK_MODE_ENABLED", "Y");
            this.showKioskModeButton = true;
            if (value.kioskQuizEnabled) {
              localStorage.setItem("quizMode", "Y");
              this.quizzingEnabled = true;
            } else {
              localStorage.setItem("quizMode", "N");
              this.quizzingEnabled = false;
            }
            this.defaultTimeOutInMins = value.kioskTimeoutMins;

          } else {
            localStorage.setItem("KIOSK_MODE_ENABLED", "N");
            localStorage.setItem("quizMode", "N");
            this.quizzingEnabled = false;
          }
        }
        if (localStorage.getItem("kiosk") == "Y") {
          this.kioskMode = true;
          this.showKioskModeButton = false;
          this._absConfigService.setConfig(this.absConfigWithToolBarKiosk, { emitEvent: true });
          this.initIdleTime();
        }

      })


    }





    const absConfigToolBarWithKiosk: AbsConfig = {
      colorTheme: 'theme-default',
      customScrollbars: true,
      layout: {
        style: 'horizontal-layout-1',
        width: 'fullwidth',
        navbar: {
          primaryBackground: 'abs-navy-900',
          secondaryBackground: 'abs-navy-700',
          folded: false,
          hidden: true,
          position: 'left',
          variant: 'vertical-style-1'
        },
        toolbar: {
          background: 'abs-white-500',
          customBackgroundColor: true,
          hidden: false,
          kiosk: true,
          position: 'above'
        },
        footer: {
          customBackgroundColor: true,
          background: 'abs-navy-900',
          hidden: false,
          position: 'below-fixed'
        },
        sidepanel: {
          hidden: true,
          position: 'right'
        }
      }
    };

    const absConfigDefault: AbsConfig = {
      colorTheme: 'theme-default',
      customScrollbars: true,
      layout: {
        style: 'horizontal-layout-1',
        width: 'fullwidth',
        navbar: {
          primaryBackground: 'abs-navy-900',
          secondaryBackground: 'abs-navy-700',
          folded: false,
          hidden: true,
          position: 'left',
          variant: 'vertical-style-1'
        },
        toolbar: {
          background: 'abs-white-500',
          customBackgroundColor: true,
          hidden: false,
          kiosk: false,

          position: 'above'
        },
        footer: {
          customBackgroundColor: true,
          background: 'abs-navy-900',
          hidden: false,
          position: 'below-fixed'
        },
        sidepanel: {
          hidden: true,
          position: 'right'
        }
      }
    };


    this.actionBarService.onKioskCloseModeClick.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(value => {
        console.log("hello", value);
        if (value) {

          /* this.confirmDialogRef = this.confirmDialog.open(AbsConfirmDialogComponent, {
            panelClass: 'confirm-form-dialog',
            disableClose: false
          });

          this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to Close Kiosk?';

          this.confirmDialogRef.afterClosed().subscribe(result => {
            if (result) {
              localStorage.setItem("kiosk", "N");
              this.kioskMode = false;
              this.showKioskPlayer = false;
              this.allDialogRef.closeAll();
              this.showKioskModeButton = true;
              this.kioskModeEnabled = false;
              this._absConfigService.setConfig(absConfigDefault, { emitEvent: true });
            }
            this.confirmDialogRef = null;
          }); */



          let ref = this.adminCredentialDialog.open(AdminCredentialsDialogComponent, {
            width: '400px'
          })
          ref.afterClosed().subscribe(res => {
            if (res) {
              localStorage.setItem("kiosk", "N");
              this.kioskMode = false;
              this.showKioskPlayer = false;
              this.allDialogRef.closeAll();
              this.showKioskModeButton = true;
              this.kioskModeEnabled = false;
              this._absConfigService.setConfig(absConfigDefault, { emitEvent: true });
            }
          })
        }
      })
    this.actionBarService.stepChanged.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(value => {
        console.log("hello", value);
        this.goToStep(value);
      })


    this.actionBarService.onKioskModeClick.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(res => {
        this.initIdleTime();
        if (res) {

        }
      })

  }

  startKioskMode() {


    if (this.autService.currentUserValue.signUpType == 'SSO') {
      if (!this.autService.currentUserValue.secondaryPass || this.autService.currentUserValue.secondaryPass == null || this.autService.currentUserValue.secondaryPass == '') {
        var secRef = this._secondaryPassDialog.open(SecondaryPasswordDialogComponent, {
          width: '400px',
        });
        secRef.afterClosed().subscribe(result => {
          if (result) {
            var user = this.autService.currentUserValue;
            user.secondaryPass = 'abc';
            this.autService.currentUserSubject.next(user);
            this.initKiosk();
          }
        })
      } else {
        this.initKiosk();
      }
    } else {
      this.initKiosk();
    }
  }

  initIdleTime() {

    var time = this.defaultTimeOutInMins * 60;

    this.idle.setIdle(time);

    this.idle.setTimeout(1);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.idle.onTimeout.subscribe(() => {
      this.showKioskPlayer = false;
      this.allDialogRef.closeAll();
      this._absConfigService.setConfig(this.absConfigWithToolBarKiosk, { emitEvent: true });
    });
  }
  exitTraining() {
    this.showKioskPlayer = false;
    this.allDialogRef.closeAll();
    this._absConfigService.setConfig(this.absConfigWithToolBarKiosk, { emitEvent: true });
  }

  menuenter() {
    this.isMatMenuOpen = true;
    if (this.isMatMenu2Open) {
      this.isMatMenu2Open = false;
    }
  }

  menuLeave(trigger) {
    setTimeout(() => {
      if (!this.isMatMenu2Open && !this.enteredButton) {
        this.isMatMenuOpen = false;
        trigger.closeMenu();
      } else {
        this.isMatMenuOpen = false;
      }
    }, 100)
  }
  buttonEnter(trigger) {
    setTimeout(() => {
      if (this.prevButtonTrigger && this.prevButtonTrigger != trigger) {
        this.prevButtonTrigger.closeMenu();
        this.prevButtonTrigger = trigger;
        this.isMatMenuOpen = false;
        this.isMatMenu2Open = false;
        trigger.openMenu();
      }
      else if (!this.isMatMenuOpen) {
        this.enteredButton = true;
        this.prevButtonTrigger = trigger
        trigger.openMenu();
      }
      else {
        this.enteredButton = true;
        this.prevButtonTrigger = trigger
      }
    }, 80)
  }

  buttonLeave(trigger) {
    setTimeout(() => {
      if (this.enteredButton && !this.isMatMenuOpen) {
        trigger.closeMenu();
      } if (!this.isMatMenuOpen) {
        trigger.closeMenu();
      } else {
        this.enteredButton = false;
      }
    }, 100)

  }

  onKioskUserCreated(event) {
    console.log("event", event)
    this.showKioskPlayer = true;
    this.selectedPlaylistVidoes = event.videos;
    this.profileId = event.profileId;
    this._absConfigService.setConfig(this.absConfigWithOutToolBar, { emitEvent: true });
  }
  getValue(source, key) {
    return source.filter(s => s.name == key);
  }

  openDialog() {
    var abc = this.dialog.open(TriggerDialogComponent, {
      id: '1-preview',
      width: '500px',
    });
  }

  toggleIdleTime(event) {
    console.log(event);
    if (event) {
      this.idle.watch();
    } else {
      this.idle.stop();
    }
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  goToStep(board) {
    if (this.autService.currentUserValue.settings) {
      let globalHelp = this.getValue(this.autService.currentUserValue.settings, 'ON_SCREEN_HELP');
      if (globalHelp && globalHelp.length > 0 && globalHelp[0].value == 'true') {
        this.tourService.goto(board);
      }
    }
  }

  redirectUrl($event, url) {
    //$event.stopPropagation();
    //$event.preventDefault();
    console.log($event);
    if (!this.showHelp) {
      this.router.navigate([url]);
    }
  }


  initKiosk() {
    const absConfigToolBarWithKiosk: AbsConfig = {
      colorTheme: 'theme-default',
      customScrollbars: true,
      layout: {
        style: 'horizontal-layout-1',
        width: 'fullwidth',
        navbar: {
          primaryBackground: 'abs-navy-900',
          secondaryBackground: 'abs-navy-700',
          folded: false,
          hidden: true,
          position: 'left',
          variant: 'vertical-style-1'
        },
        toolbar: {
          background: 'abs-white-500',
          customBackgroundColor: true,
          hidden: false,
          kiosk: true,
          position: 'above'
        },
        footer: {
          customBackgroundColor: true,
          background: 'abs-navy-900',
          hidden: false,
          position: 'below-fixed'
        },
        sidepanel: {
          hidden: true,
          position: 'right'
        }
      }
    };

    const absConfigDefault: AbsConfig = {
      colorTheme: 'theme-default',
      customScrollbars: true,
      layout: {
        style: 'horizontal-layout-1',
        width: 'fullwidth',
        navbar: {
          primaryBackground: 'abs-navy-900',
          secondaryBackground: 'abs-navy-700',
          folded: false,
          hidden: true,
          position: 'left',
          variant: 'vertical-style-1'
        },
        toolbar: {
          background: 'abs-white-500',
          customBackgroundColor: true,
          hidden: false,
          kiosk: false,

          position: 'above'
        },
        footer: {
          customBackgroundColor: true,
          background: 'abs-navy-900',
          hidden: false,
          position: 'below-fixed'
        },
        sidepanel: {
          hidden: true,
          position: 'right'
        }
      }
    };

    this.kioskModeEnabled = true;
    this.initIdleTime();
    localStorage.setItem("kiosk", "Y");
    if (this.quizzingEnabled) {
      var dialogRef = this._quizToggleDialog.open(KioskQuizToggleDialogComponent, {
        width: '400px',
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          localStorage.setItem("quizMode", "Y");
        } else {
          localStorage.setItem("quizMode", "N");
        }
        this.kioskMode = true;
        this._absConfigService.setConfig(absConfigToolBarWithKiosk, { emitEvent: true });
        this.allDialogRef.closeAll();
      })
    } else {
      this.kioskMode = true;
      this._absConfigService.setConfig(absConfigToolBarWithKiosk, { emitEvent: true });
    }
  }


}
