import { Component, Input, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { absAnimations } from '@abs/animations';
import { SwiperConfigInterface, SwiperScrollbarInterface } from 'ngx-swiper-wrapper';
import { Content } from '../../content-list.model';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-supplimental-preview',
  templateUrl: './supplimental-preview.component.html',
  styleUrls: ['./supplimental-preview.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: absAnimations
})
export class SupplimentalPreviewComponent implements OnInit {

  public config: SwiperConfigInterface = {
    init: true,
    a11y: true,
    direction: 'horizontal',
    slidesPerView: 5,
    slidesPerColumn: 1,
    keyboard: true,
    mousewheel: false,
    scrollbar: false,
    navigation: false,
    pagination: false,
    spaceBetween: 12,
    width: 1130,
    centerInsufficientSlides: true
  };

  similiarContent: Content[] = [];
  enviorment: any;
  content: any;


  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    this.content = data.content;
    this.similiarContent = data.similiarContent;
    this.enviorment = environment;
    console.log(data);
  }
  ngOnInit() {
  }

}
