export class CollectionFakeDb {

    public static categories: [
        {
            'id'   : '26022e4129ad3a5sc28b36ccc',
            'name' : 'CDL',
            'color': 'red-500'
        },
        {
            'id'   : '26022e4129ad3a5sc28b36cbb',
            'name' : 'OSHA Compliance',
            'color': 'red-500'
        },
        {
            'id'   : '26022e4129ad3a5sc28b36cee',
            'name' : 'Everyday Driver',
            'color': 'red-500'
        },{
            'id'   : '26022e4129ad3a5sc28b36cdff',
            'name' : 'Professional Driver',
            'color': 'red-500'
        },{
            'id'   : '26022e4129ad3a5sc28b36cdgg',
            'name' : 'Video',
            'color': 'red-500'
        },{
            'id'   : '26022e4129ad3a5sc28b36cdhh',
            'name' : 'EAP',
            'color': 'red-500'
        },{
            'id'   : '26022e4129ad3a5sc28b36cdii',
            'name' : 'Contractor',
            'color': 'red-500'
        },{
            'id'   : '26022e4129ad3a5sc28b36cdjj',
            'name' : 'Behavioral',
            'color': 'red-500'
        },{
            'id'   : '26022e4129ad3a5sc28b36cdkk',
            'name' : 'Groudkeeping Safety',
            'color': 'red-500'
        }
        
    ];

    public static tags: [

        {
            'id'   : '26022e4129ad3a5sc28b36ca',
            'name' : '#semitrailer',
            'color': 'red-500'
        },
        {
            'id'   : '26022e4129ad3a5sc28b36cb',
            'name' : '#trailer',
            'color': 'red-500'
        },
        {
            'id'   : '26022e4129ad3a5sc28b36cc',
            'name' : '#backingandparking',
            'color': 'red-500'
        },{
            'id'   : '26022e4129ad3a5sc28b36cd',
            'name' : '#fleet',
            'color': 'red-500'
        },{
            'id'   : '26022e4129ad3a5sc28b36ce',
            'name' : '#maintainance',
            'color': 'red-500'
        },{
            'id'   : '26022e4129ad3a5sc28b36cf',
            'name' : '#truck',
            'color': 'red-500'
        },{
            'id'   : '26022e4129ad3a5sc28b36cg',
            'name' : '#towing',
            'color': 'red-500'
        },{
            'id'   : '26022e4129ad3a5sc28b36ch',
            'name' : '#heavyequipment',
            'color': 'red-500'
        },{
            'id'   : '26022e4129ad3a5sc28b36ci',
            'name' : '#cdl',
            'color': 'red-500'
        }
    ]

}
